import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { LicenseManager } from 'ag-grid-enterprise';
import store, { AppState, persistor } from './store';
import serviceContainer, { ServiceContainer } from 'src/ServiceContainer';
import { ToastContainer } from 'react-toastify';
import IdleTimer from 'react-idle-timer';

import 'react-toastify/dist/ReactToastify.css';
import 'react-table/react-table.css';
import 'src/common-ui/styles/index.css';
import NavigationShell from 'src/pages/NavigationShell/NavigationShell.container';
import PerspectiveSelection from 'src/pages/PerspectiveSelection/PerspectiveSelection.container';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { makeUpdateAppConfig, makeUpdateConfigFingerprint } from 'src/services/configuration/AppConfig.actions';
import { ASSORTMENT_ROUTE_PATH } from './pages/NavigationShell/NavigationShell';
import { updateFlowStatus, updateFlowStatusConfig } from './components/Subheader/Subheader.slice';
import { enableAllPlugins } from 'immer';
import { tenantTabHasPathSlot } from './services/configuration/bindings.types';
import { fetchFingerprintFactory } from 'src/services/configuration';
import { PersistGate } from 'redux-persist/integration/react';

require('./global.css');

enableAllPlugins();

LicenseManager.setLicenseKey(
  'S5_Stratos_MultiApp_2Devs11_July_2019__MTU2Mjc5OTYwMDAwMA==82681080271234c6108c212ac1a9f2c7'
);

const onIdle = () => 0; // window.location.pathname = '/logout';

const { printService, configService } = serviceContainer;

const root = document.getElementById('root');

if (root) {
  configService
    .whenDone()
    .then(async () => {
      const state = store.getState();
      const activeTab = state.perspective.activeTab;
      const idleTimeout = configService.getTimeout();
      printService.hydrate(store);
      printService.setPrintFromUrl();
      window.onpopstate = printService.setPrintFromUrl;
      const config = configService.getOrThrow();
      store.dispatch(makeUpdateAppConfig(configService.getOrThrow()));
      // Select all if no default selections set. Update starting flowstatus with default. All flows selected = []
      let flowStatusSelections = config.flowStatus.defaultSelection || [];
      let flowStatusConfig = config.flowStatus;
      // Get flow status override based on active tab
      if (config && activeTab) {
        const tab = config.tabs.find((tab) => tenantTabHasPathSlot(tab) && tab.pathSlot == activeTab);
        if (tab && tenantTabHasPathSlot(tab) && tab.flowStatusOverride && tab.flowStatusOverride.values) {
          flowStatusConfig = tab.flowStatusOverride;
          flowStatusSelections = tab.flowStatusOverride.defaultSelection;
        }
      }
      if (flowStatusSelections.length === config.flowStatus.values.length) {
        flowStatusSelections = [];
      }
      store.dispatch(updateFlowStatus(flowStatusSelections));
      store.dispatch(updateFlowStatusConfig(flowStatusConfig));
      await fetchFingerprintFactory()
        .then((fingerprint) => {
          store.dispatch(makeUpdateConfigFingerprint(fingerprint));
        })
        .catch(() => {
          console.info('Config has no fingerprint.');
        });
      // TODO manage this state better.
      window.__debug.bootState = store.getState();
      ReactDOM.render(
        <div>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>
                <IdleTimer element={document} idleAction={onIdle} timeout={idleTimeout}>
                  <Switch>
                    <Route exact={true} path="/" render={PerspectiveSelection} />
                    {/* TODO: When /:perspective exact, redirect to the default tab correctly */}
                    <Route path={ASSORTMENT_ROUTE_PATH} component={NavigationShell} />
                  </Switch>
                </IdleTimer>
              </Router>
            </PersistGate>
          </Provider>
          <ToastContainer autoClose={5000} />
        </div>,
        root
      );
    })
    .catch((e) => {
      console.error('unable to load configuration', e);
    });
} else {
  console.error('no root element to mount app in');
}

declare global {
  interface Window {
    __debug: {
      store: Store<AppState>;
      serviceContainer: ServiceContainer;
      bootState?: AppState;
    };
  }
}

window.__debug = {
  store,
  serviceContainer,
};
