import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction as BaseAction } from 'redux';
import type { WorklistInfo } from 'src/worker/pivotWorker.types';
import { AppDispatch } from 'src/store';
import service from 'src/ServiceContainer';


export type SharedDataSlice = {
  worklist: WorklistInfo[];
  isWorklistLoading: boolean;
};

const initialState: SharedDataSlice = {
  worklist: [],
  isWorklistLoading: false,
};

const SharedDataReducer = createSlice({
  name: 'StyleColorReview',
  initialState,
  reducers: {
    requestWorklist(state) {
      state.isWorklistLoading = true;
    },
    receiveWorklist(state, action: PayloadAction<WorklistInfo[]>) {
      state.isWorklistLoading = false;
      state.worklist = action.payload;
      return state;
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});
export const { requestWorklist, receiveWorklist, receiveError, cleanUp } = SharedDataReducer.actions;
export default SharedDataReducer.reducer;

export function getWorklist() {
  return async (dispatch: AppDispatch): Promise<BaseAction | void> => {
    dispatch(requestWorklist());
    try {
      const resp = await service.pivotService.getWorklistData();
      return dispatch(receiveWorklist(resp));
    } catch (_error) {
      return dispatch(receiveError());
    }
  };
}
