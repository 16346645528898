import React, { Component, Fragment } from 'react';
import { keys, isNil, isArray, mapKeys, invert, some } from 'lodash';
import { toast } from 'react-toastify';

import { CardActions } from '@material-ui/core';
import Axios from 'src/services/axios';
import ServiceContainer from 'src/ServiceContainer';
import { Overlay } from 'src/common-ui';
import {
  ConfigurableDataModalProps,
  ConfigurableDataModalState,
  RenderSectionType,
  ConfigurableDataModalCellEditParams,
  ConfigurableDataConfig,
  FormattedSizingData,
  ServerSizingData,
  ViewSizingData,
  ConfigurableDataModalDataHandler,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.types';
import { addEventListenerForEditors } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/Editors.utils';
import { TemplateModalTitle } from 'src/components/TemplateModalWithButton/TemplateModalWithButton';
import {
  formatDataForView,
  renderItemsInSection,
  calculateInitialPercentages,
  modifyDataPercentages,
  disaggregateDataByPercentages,
  formatDataForServer,
  handleGetValue,
  fetchEditables,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.utils';
import { ConfigurableDataModalTabs } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModalSections';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { ListDataOptions, Pivot } from 'src/worker/pivotWorker.types';
import styles from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.styles';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';

export default class ConfigurableDataModal extends Component<ConfigurableDataModalProps, ConfigurableDataModalState> {
  constructor(props: ConfigurableDataModalProps) {
    super(props);

    addEventListenerForEditors(props.eGridCell);

    this.state = {
      isLoading: true,
      editsSaved: false,
      config: null,
      data: {},
      activeTabIndex: 0,
    };
  }

  componentDidMount() {
    this.fetchData().catch((_err) => {
      this.props.stopEditing();
    });
  }

  async fetchData() {
    const { configApi, isEditable } = this.props;
    const configResponse = await Axios.get(configApi.url);
    const config: ConfigurableDataConfig = maybeReturnNestData(configResponse.data);
    const fetchHandler = config.dataHandlers.fetch;
    let formattedData: FormattedSizingData = {};

    switch (fetchHandler.type) {
      case ConfigurableDataModalDataHandler.receipts: {
        const params = this.getRequestParams(fetchHandler.defnId, fetchHandler.apiIdDataIndex);
        const dataResponse = await Axios.get(fetchHandler.url, { params });
        const deserializedData: ServerSizingData[] = (await ServiceContainer.pivotService.deserialize(
          dataResponse.data
        )) as ServerSizingData[];
        formattedData = formatDataForView(config, deserializedData);
        break;
      }
      case ConfigurableDataModalDataHandler.pivot: {
        const params = processApiParams(fetchHandler, this.props.node.data).params as ListDataOptions;
        if (isArray(params.topMembers)) {
          params.topMembers = params.topMembers.join(',');
        }
        const dataResponse = await ServiceContainer.pivotService
          .listData(fetchHandler.defnId, ASSORTMENT, params)
          .catch(
            (_r): Pivot => {
              toast.error('Failed to load edit modal.');
              ServiceContainer.loggingService.error(_r);
              this.props.stopEditing();
              return { flat: [], tree: [] };
            }
          );
        if (dataResponse.tree.length == 0) {
          this.props.stopEditing();
        }
        formattedData = formatDataForView(config, (dataResponse.tree as unknown) as ServerSizingData[]);
        break;
      }
      default:
        toast.error('Failed to load edit modal.');
        this.props.stopEditing();
    }

    formattedData = !isEditable ? formattedData : calculateInitialPercentages(config, formattedData);
    this.setState({
      isLoading: false,
      config,
      data: formattedData,
    });
  }

  getRequestParams = (defnId: string, idDataIndex: string) => {
    const { node, floorset } = this.props;
    return {
      appName: ASSORTMENT,
      productId: node.data[idDataIndex],
      timeId: floorset,
      defnId,
    };
  };

  getValue = () => {
    const { editsSaved, config, data } = this.state;
    const { value, floorset, node, cellDataIndex } = this.props;
    const gridData = {
      floorset,
      node,
      value,
      cellDataIndex,
    };

    if (isNil(config)) {
      return value;
    }

    return handleGetValue(config, editsSaved, data, gridData);
  };

  isPopup = () => true;

  handleTabChange = (activeTabIndex: number) => {
    this.setState({
      activeTabIndex,
    });
  };

  handleCellEdit = (rowDataIndexValue: string, colDataIndex: string, value: number) => {
    const { activeTabIndex, data, config } = this.state;
    if (isNil(config)) {
      return;
    }

    // can get NaN values returned if cell was "cleared" out
    const modifiedValue = !isNaN(value) ? value : null;
    const params: ConfigurableDataModalCellEditParams = {
      rowDataIndexValue,
      colDataIndex,
      value: modifiedValue,
      activeTabIndex,
      data,
      config,
    };

    const modifiedData =
      rowDataIndexValue !== 'Total' ? modifyDataPercentages(params) : disaggregateDataByPercentages(params);

    this.setState({
      data: modifiedData,
    });
  };

  handleCancel = () => {
    this.props.stopEditing(true);
  };

  handleSave = async () => {
    const { config } = this.state;
    if (isNil(config)) {
      return;
    }

    const { stopEditing } = this.props;
    const updateHandler = config.dataHandlers.update;
    switch (updateHandler.type) {
      case ConfigurableDataModalDataHandler.receipts: {
        const serverFormattedData = formatDataForServer(this.state.data);
        const params = this.getRequestParams(updateHandler.defnId, updateHandler.apiIdDataIndex);
        await Axios.post(updateHandler.url, serverFormattedData, { params })
          .catch((_e) => {
            toast.error('Failed to update data.');
          })
          .finally(() => {
            this.setState({ editsSaved: true }, () => stopEditing());
          });
        break;
      }
      case ConfigurableDataModalDataHandler.attribute: {
        const data = fetchEditables(this.state.data, config.columns, Object.values(updateHandler.keys));
        const invertedKeys = invert(updateHandler.keys);
        const processedData = data.map((d) => {
          return mapKeys(d, (_v, k) => {
            if (!isNil(invertedKeys[k])) {
              return invertedKeys[k]; //assume the configurer is not daft here
            } else {
              return k
                .replace('attribute:', '')
                .replace('member:', '')
                .replace(':id', '')
                .replace(':name', '');
            }
          });
        });
        await Axios.post('api/attribute/upsertAll?appName=Assortment', processedData).finally(() => {
          this.setState({ editsSaved: true }, () => stopEditing());
        });
        break;
      }
      default:
        toast.error('Failed to update data.');
    }
  };

  // for some reason certain key strokes still propagate (i.e. tab) so this properly handles that
  bindEventListeners = (element: HTMLDivElement) => {
    if (isNil(element)) {
      return;
    }

    element.addEventListener('keydown', function(event: KeyboardEvent) {
      event.stopPropagation();
    });
  };

  renderSection(sectionType: RenderSectionType) {
    const { config, data, activeTabIndex } = this.state;
    const { isEditable, renderTabs } = this.props;

    let tabData: ViewSizingData[] = [];
    const tabs = keys(data);

    if (renderTabs) {
      const activeTabKey = tabs[activeTabIndex];
      tabData = data[activeTabKey].items;
    } else {
      // for now if not rendering tabs, selecting first week item
      const firstTab = tabs.length > 0 ? tabs[0] : '';
      tabData = firstTab ? data[firstTab].items : [];
    }

    return renderItemsInSection(sectionType, isEditable, config, tabData, this.handleCellEdit);
  }

  render() {
    const { renderTabs, isEditable } = this.props;
    const { isLoading, config, data, activeTabIndex } = this.state;
    const tabs = keys(data);
    const cols = config != null ? config.columns : [];
    const showAccept = isEditable && some(cols, (c) => c.editable === true);
    return (
      <div className={styles.overlay}>
        <div data-qa="ag-popover" className={styles.modalContainer}>
          <div className={styles.header}>
            <TemplateModalTitle title={!isNil(config) ? config.title : ''} onCancel={this.handleCancel} />
          </div>
          {isLoading ? (
            <Overlay type="loading" visible={true} fitParent={true} />
          ) : (
            <Fragment>
              {renderTabs && (
                <ConfigurableDataModalTabs
                  tabs={tabs}
                  activeTabIndex={activeTabIndex}
                  onTabChange={this.handleTabChange}
                />
              )}
              <div className={styles.sectionsContainer} ref={this.bindEventListeners}>
                {this.renderSection(RenderSectionType.top)}
                {this.renderSection(RenderSectionType.bottom)}
              </div>
              <CardActions classes={styles.actionButtons} style={{ marginRight: 0, paddingRight: '0.75rem' }}>
                {showAccept && <AcceptButton onClick={this.handleSave} />}
                <RejectButton onClick={this.handleCancel} />
              </CardActions>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
