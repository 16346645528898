import { connect } from 'react-redux';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { ExceptionsSummary, ExceptionsSummaryDispatchProps, ExceptionList } from './ExceptionsSummary';
import {
  requestExceptionsSummaryConfig,
  receiveExceptionsSummaryConfig,
  requestExceptionsSummaryError,
  ExceptionsSummaryConfigs,
  receiveExceptionsSummaryGridData,
} from './ExceptionsSummary.slice';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { exceptionsSummaryLens } from 'src/services/lenses/lenses';
import { AppState, AppThunkDispatch } from 'src/store';

type LoadingExceptions = {
  dataLoading: true;
  configLoading: true;
};
type LoadedExceptions = {
  dataLoading: boolean;
  configLoading: boolean;
  exceptions: ExceptionList | undefined;
  viewDefns: ExceptionsSummaryConfigs | undefined;
};

const mapStateToProps = (state: AppState): LoadingExceptions | LoadedExceptions => {
  const exceptionsSummarySlice = exceptionsSummaryLens.get(state);
  return {
    exceptions: exceptionsSummarySlice.gridData,
    dataLoading: exceptionsSummarySlice.isGridDataLoading,
    configLoading: exceptionsSummarySlice.isConfigLoading,
    viewDefns: exceptionsSummarySlice.viewDefns,
  };
};

function dispatchToProps(dispatch: AppThunkDispatch): ExceptionsSummaryDispatchProps {
  return {
    onShowView() {
      const defnIds = ['HistoryExceptionsSummary'];
      dispatch(requestExceptionsSummaryConfig());
      container.tenantConfigClient
        .getTenantViewDefns({
          defnIds: defnIds,
          appName: ASSORTMENT,
        })
        .then((config) => {
          const maybeExcConfig = config[0];
          if (maybeExcConfig.columns && maybeExcConfig.columns !== undefined && maybeExcConfig.main) {
            dispatch(receiveExceptionsSummaryConfig((maybeExcConfig as unknown) as ExceptionsSummaryConfigs)); // ssshhhh
          }
          const gridDataPromise = container.pivotService.listData('HistoryExceptionsSummary', ASSORTMENT, {
            aggBy: 'custom:groupid,custom:id',
          });
          return (
            gridDataPromise
              .then((gridData) => {
                dispatch(receiveExceptionsSummaryGridData((gridData.tree as unknown) as ExceptionList));
              })
              // TODO log error and toast
              .catch(() => dispatch(requestExceptionsSummaryError()))
          );
        })
        .catch((_error) => {
          // TODO log error and toast
          dispatch(requestExceptionsSummaryError());
        });
    },
    onRefetchData() {
      // TODO actually refetch data
      dispatch(receiveExceptionsSummaryGridData([]));
    },
  };
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(makePrintSensitive(makeScopeAndFilterSensitive(ExceptionsSummary)));
