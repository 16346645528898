import { connect } from 'react-redux';
import { flow, isEmpty } from 'lodash';

import container from 'src/ServiceContainer';
import { ProductMix, ValueProps, FunctionProps } from './ProductMix';
import { requestViewDefn, receiveViewDefn, receiveError } from './ProductMix.slice';
import {
  getLocalConfig,
  updateGroupSortViaLocal,
} from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { ProductMixComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { AppState, AppThunkDispatch } from 'src/store';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { isDataLoaded, getDataFromCache } from 'src/services/pivotServiceCache';

type ProductMixOwnProps = z.infer<typeof ProductMixComponentProps>;

function mapStateToProps(state: AppState, ownProps: ProductMixOwnProps): ValueProps {
  const { title = 'Product Mix', defns } = ownProps;
  const viewState = state.pages.hindsighting.productMix;
  const { viewDefnState, viewDataState, viewDefn } = viewState;
  const loaded = isViewDefnLoaded(viewDefnState) && isDataLoaded(viewDataState);
  const treeData = getDataFromCache(viewState)?.tree || [];
  const data = !isEmpty(treeData) ? treeData[0].children : treeData;
  const totals = treeData[0];

  return {
    title,
    groupByViewDefnStr: defns.subheader.groupBy,
    loaded,
    pieChartView: viewDefn?.view[0],
    barChartView: viewDefn?.view[1],
    data,
    totals,
    groupBy: state.subheader.groupBy,
    flowStatus: state.subheader.flowStatus,
    viewDefn: viewDefn || ({} as TenantConfigViewData),
    viewDataState,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, { defns }: ProductMixOwnProps): FunctionProps {
  return {
    onShowView() {
      dispatch(requestViewDefn());
      container.tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: 'Assortment',
        })
        .then((resp) => {
          const localConfig = getLocalConfig(defns.view[0], (resp as any)[defns.view.length], dispatch);
          dispatch(receiveViewDefn(resp[0]));
        })
        .then(() => {
          updateGroupSortViaLocal(defns.view[0], dispatch);
        })
        .catch((_error) => dispatch(receiveError()));
    },
  };
}

const sensitiveView = flow(() => ProductMix)();

export default connect(mapStateToProps, dispatchToProps)(sensitiveView);
