import { ReportConfig } from 'src/dao/reportClient';
import { Action } from './Reporting.actions';

export type ReportingSlice =
  | {
      isLoading: false;
      reportConfig: ReportConfig;
    }
  | {
      isLoading: true;
    };

const initialState: ReportingSlice = {
  isLoading: true,
};

export function reportingReducer(state: ReportingSlice = initialState, action: Action): ReportingSlice {
  switch (action.type) {
    case 'REQUEST_REPORTING_CONFIG':
      return { isLoading: true };
    case 'RECEIVE_REPORTING_CONFIG':
      return { reportConfig: action.reportConfig, isLoading: false };
    case 'RECEIVE_REPORTING_ERROR':
    case 'CLEAN_UP_REPORTING':
      return initialState;
    default:
    // Unreachable
  }
  return state;
}
