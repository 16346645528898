import { isEmpty } from 'lodash';
import { createSelector, OutputSelector } from 'reselect';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { worklistLens } from 'src/services/lenses/lenses';
import { getDataToRender } from 'src/services/pivotServiceCache';
import { AppState } from 'src/store';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { filterData } from 'src/utils/Pivot/Filter';
import { Pivot } from 'src/worker/pivotWorker.types';

const getPivotData = (state: AppState) => {
  const worklist = worklistLens.get(state);
  return getDataToRender(worklist);
};

const getSearch = (state: AppState) => {
  const worklist = worklistLens.get(state);
  return worklist.worklistSearch;
};

const getFlowStatus = (state: AppState) => {
  const worklist = worklistLens.get(state);
  return worklist.worklistFlowStatus;
};

export function getStyleData(styles: Pivot, search: string, flowStatus: number[]) {
  // add flowstatus to here
  const filteredStyles = !isEmpty(styles.flat)
    ? filterData(styles.flat, search, externalGridSearchFields, flowStatus)
    : [];
  const treeStyles = !isEmpty(styles.flat) ? filterData(styles.flat, search, externalGridSearchFields, flowStatus) : [];

  return {
    filteredStyles,
    treeStyles,
  };
}
export type ProcessedDataSelector = OutputSelector<AppState, ReturnType<typeof getStyleData>, typeof getStyleData>;

export const getProcessedData: ProcessedDataSelector = createSelector(
  getPivotData,
  getSearch,
  getFlowStatus,
  getStyleData
);