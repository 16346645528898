import { TenantConfig } from './bindings.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type UPDATE_APP_CONFIG = 'UPDATE_APP_CONFIG';

export type UpdateAppConfig = {
  type: UPDATE_APP_CONFIG;
  tenantConfig: TenantConfig;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type UPDATE_CONFIG_FINGERPRINT = 'UPDATE_CONFIG_FINGERPRINT';

export type UpdateConfigFingerprint = {
  type: UPDATE_CONFIG_FINGERPRINT;
  fingerprint: string;
};

export type Action = UpdateAppConfig | UpdateConfigFingerprint;

export function makeUpdateAppConfig(tenantConfig: TenantConfig): Action {
  return {
    type: 'UPDATE_APP_CONFIG',
    tenantConfig,
  };
}

export function makeResetTheWorld() {
  return {
    type: 'RESET_THE_WORLD',
  };
}

export function makeUpdateConfigFingerprint(fingerprint: string): Action {
  return {
    type: 'UPDATE_CONFIG_FINGERPRINT',
    fingerprint,
  };
}
