import numbro from 'numbro';

function percent(value: number | string, format = '0.00'): string {
  if (isNaN(value as number)) {
    return '';
  }
  return numbro(value).format(`${format}%`);
}

function arrowPercentTwoDecimal(value: number | string) {
  return percent(value);
}

function thousand(value: number | string): string {
  return numbro(value).format('0,0');
}

function twoDecimal(value: number | string): string {
  return numbro(value).format('0.00');
}

function usMoney(value: number | string, format = '$0,0.00'): string {
  return numbro(value).format(format);
}

function usMoneyNoCents(value: number | string): string {
  return numbro(value).format('$0,0');
}

export const Renderer = {
  arrowPercentTwoDecimal,
  percent,
  thousand,
  twoDecimal,
  usMoney,
  usMoneyNoCents,
};
