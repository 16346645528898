/* eslint-disable @typescript-eslint/naming-convention */

import { TenantConfig } from 'src/services/configuration/bindings.types';
import { UNINITIALIZED } from 'src/utils/Domain/Constants';
import { QuickRecon, FlowStatus, PrintSize } from './general';
import { Perspectives } from './literals';
import { z } from 'zod';
import { SectionView } from './confdefnComponents';
import { filterInvalidItem, formatAndLogLocal, validateAtLevel } from './confdefnFilter';
import { concat, isEmpty, isNil, map } from 'lodash';
import service from 'src/ServiceContainer';

// Confdefn tab and section structure validators

const Section = z.object({
  id: z.string(),
  name: z.string(),
  icon: z.string(),
  pathSlot: z.string(),
  inPerspectives: z.array(Perspectives),
  /** Available views to display under section in LeftNav */
  views: z.array(SectionView),
});

export const LeftNavSection = Section.merge(
  z.object({
    defaultView: z.string().optional(),
    disabled: z.boolean().optional(),
    hidden: z.boolean().optional(),
  })
);

const DisabledTab = z.object({
  id: z.string(),
  disabled: z.literal(true),
});

const Context = z.object({
  tabs: z.array(z.string()),
  editStyleBtn: z
    .object({
      text: z.string().optional(),
      path: z.string(),
    })
    .optional(),
});

export const EnabledTab = z
  .object({
    id: z.string(),
    /** Determines if tab is enabled in the UI */
    disabled: z.literal(false),
    /** Selected section after tab loads */
    defaultSection: z.string(),
    /** Dash separated value used to generate the url of the selected tab  */
    pathSlot: z.string(),
    /** Sections to render in the LeftNav component */
    leftNavSections: z.array(LeftNavSection),
  })
  .merge(
    z.object({
      hidden: z.boolean().optional(),
      /** Allows the Tab to render the Quick Reconcile modal */
      quickReconConf: QuickRecon.optional(),
      flowStatusOverride: FlowStatus.optional(),
    })
  );
export const isEnabledTab = (tab: z.infer<typeof ConfDefnTab>): tab is z.infer<typeof EnabledTab> => {
  return !tab.disabled;
};

export const ConfDefnTab = z.union([EnabledTab, DisabledTab]);

export const UIConfDefn = z.object({
  /** The type of project the configs are setup for */
  id: z.union([z.literal('Assortment'), z.literal(UNINITIALIZED)]),
  /** The selected tab after login and perspective selection */
  defaultTab: z.object({
    BottomUp: z.string(),
    TopDown: z.string(),
  }),
  /** Amount of time before logging user out */
  idleTimeout: z.number(),
  /** Values shown for flowStatus and initial selections  */
  flowStatus: FlowStatus,
  /** i.e. YTD, QTD, MTD, etc. */
  lookBackPeriods: z.array(z.string()),
  /** Available Perspectives */
  perspectives: z.array(Perspectives),
  /** For Filters/Style Pane to map tabs to an assortment or hindsighting context */
  context: z.object({
    assortment: Context,
    hindsighting: Context,
    allocation: Context,
  }),
  /**
    Tabs to render in TopNavBar, containing Section and View configurations.
    Reporting Tab can actually be component itself so LeftNavSection is necessary in union
  */
  tabs: z.array(ConfDefnTab),
  /** Available print dimensions */
  printSizes: z.array(PrintSize),
});

/**
 * Validate each level of the confdefn tree, filtering out invalids items.
 * An invalid item at a higher level removes that level and its children.
 *
 * Current levels:
 * - UIConfDefn level (minus the tabs)
 * - Tabs level (minus the sections)
 * - Sections level (minus the views)
 * - Views level (all views)
 *
 * @param {UIConfDefn} input - the unvalidated confdefn
 *
 * @returns {TenantConfig} a validated/filtered confdefn
 */
export function validateConfDefn(input: z.infer<typeof UIConfDefn>): TenantConfig {
  let errors: string[] = [];

  // Require valid top level letting error propagate to catch if invalid
  const uiConfDefnLevel = validateAtLevel(UIConfDefn, input, { childLevel: 'tabs', throwOnInvalid: true });

  // Validate at each level (tab, section, view)
  const childLevels = map(input.tabs, (tab, tabIndex) => {
    // validate disabled tab
    if (tab.disabled) {
      const validatedDisabledTab = validateAtLevel(DisabledTab, tab);
      if (isNil(validatedDisabledTab)) {
        const error = `Invalid tab item [${tab.id}] detected at position [${tabIndex}] in 'tabs' array.`;
        errors = concat(errors, error);
        return;
      }

      return validatedDisabledTab;
    }

    // validate enabled tab and reinsert tab children after validation
    const validatedTabLevel = validateAtLevel(EnabledTab, tab, { childLevel: 'leftNavSections' });
    if (isNil(validatedTabLevel)) {
      const error = `Invalid tab item [${tab.id}] detected at position [${tabIndex}] in 'tabs' array.`;
      errors = concat(errors, error);
      return;
    }

    const validatedTabLevelWithChildren = {
      ...validatedTabLevel,
      leftNavSections: tab.leftNavSections,
    };

    // validate all sections in tab
    const validatedSectionLevels = map(validatedTabLevelWithChildren.leftNavSections, (section, sectionIndex) => {
      // validate section and reinsert section children after validation
      const validatedSectionLevel = validateAtLevel(LeftNavSection, section, { childLevel: 'views' });
      if (isNil(validatedSectionLevel)) {
        const error = `Invalid section item [${section.id}] detected in 'leftNavSections' array at tab position [${tabIndex}] -> leftNavSections position [${sectionIndex}].`;
        errors = concat(errors, error);
        return;
      }

      const validatedSectionLevelWithChildren = {
        ...validatedSectionLevel,
        views: section.views,
      };

      // validate all views in section
      const validatedViewLevels = map(validatedSectionLevelWithChildren.views, (view, viewIndex) => {
        const validatedViewLevel = validateAtLevel(SectionView, view);

        if (isNil(validatedViewLevel)) {
          const error = `Invalid view item [${view.id}] detected in 'views' array at tabs position [${tabIndex}] -> leftNavSections position [${sectionIndex}] -> views position [${viewIndex}].`;
          errors = concat(errors, error);
          return;
        }

        return validatedViewLevel;
      }).filter(filterInvalidItem);

      return {
        ...validatedSectionLevel,
        views: validatedViewLevels,
      };
    }).filter(filterInvalidItem); // filter out invalid (undefined) section(s)

    return {
      ...validatedTabLevel,
      leftNavSections: validatedSectionLevels,
    };
  }).filter((tab) => !isNil(tab)); // filter out invalid (undefined) tab(s)

  if (!isEmpty(errors)) {
    const errorMessage = 'INVALID UI CONFDEFN ITEMS DETECTED:';
    // true skips the logging to browser console
    service.loggingService.warn(errorMessage, errors.join('\n'), true);
    formatAndLogLocal(errorMessage, errors);
  }

  // validated/filtered result
  return {
    ...uiConfDefnLevel,
    tabs: childLevels,
  };
}
