import { connect } from 'react-redux';
import { isNil, flow } from 'lodash';
import container from 'src/ServiceContainer';
import { AppThunkDispatch } from 'src/store';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { fetchVisualizeData } from './Visualize.actions';
import Visualize, { VisualizeDispatchProps } from './Visualize';
import { StylePreviewData } from '../StylePreview/StylePreview.types';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { receivedVisualizeData, receiveVisualizeConfig } from './Visualize.slice';
import selectAndProjectState from './Visualize.selector';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { getWorklist } from 'src/pages/Hindsighting/StyleColorReview/SharedData.slice';

export type WrapperOwnProps = {
  selectedItemPreviewData: StylePreviewData;
  viewDefns: string[];
  subheaderSlice: SubheaderSlice;
  styles: BasicPivotItem[];
  dontFilterSwatches?: boolean;
  onSelectStyle: (id: string) => void;
  model?: string;
};

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: WrapperOwnProps): VisualizeDispatchProps {
  return {
    onShowView: async () => {
      const configResponse = await container.tenantConfigClient.getTenantViewDefns<TenantConfigViewData>({
        defnIds: ownProps.viewDefns,
        appName: ASSORTMENT,
      });

      // have to remap views to view property for access in grid data formatting function
      const { views, ...remainingObj } = configResponse[0];
      const summaryConfig = configResponse[1];
      const graphsConfig = configResponse[2];
      configResponse[0] = {
        ...remainingObj,
        view: !isNil(views) ? views : [],
      };
      const stub = {} as TenantConfigViewData;

      dispatch(
        receiveVisualizeConfig({
          grid: configResponse[0],
          graphs: graphsConfig,
          listSort: stub,
          subheaderRollUp: stub,
          list: stub,
          unmodifiedViewDefn: stub,
          summary: summaryConfig,
        })
      );
      if (ownProps.selectedItemPreviewData.id) {
        // this is bad
        dispatch(fetchVisualizeData(ownProps.selectedItemPreviewData.id, ownProps));
      }
      dispatch(getWorklist());
    },
    onCompanionItemChange(_prevId?: string) {
      if (ownProps.selectedItemPreviewData.id) {
        dispatch(receivedVisualizeData(undefined));
        dispatch(fetchVisualizeData(ownProps.selectedItemPreviewData.id, ownProps));
      }
    },
    refetchWorklist() {
      dispatch(getWorklist());
    },
    onItemClicked(_item: ContainerPayload) {
      // dispatch(setData(item));
    },
  };
}

const wrappedView = flow(() => Visualize, makePopoverSensitive)();
export const WrappedVisualize = connect(selectAndProjectState, dispatchToProps)(wrappedView);
