import { combineReducers } from 'redux';
import hindsighting, { HindsightingSlice } from './Hindsighting/Hindsighting.reducer';
import { ReportingSlice, reportingReducer } from 'src/pages/Reporting/Reporting.reducer';
import { TargetSettingReduxSlice } from './AssortmentStrategy/TargetSetting/TargetSetting/TargetSetting.types';
import { targetSettingReducer } from './AssortmentStrategy/TargetSetting/TargetSetting/TargetSetting.reducer';
import {
  TargetListReduxSlice,
  createEmptyTargetList,
} from './AssortmentStrategy/TargetSetting/TargetList/TargetList.types';
import { isNil } from 'lodash';
import assortmentBuild, { AssortmentBuildSlice } from './AssortmentBuild/AssortmentBuild.reducer';
import allocation, { AllocationSlice } from './Allocation/Allocation.reducer';

export type AssortmentStrategySlice = {
  targetSetting: TargetSettingReduxSlice;
  targetList: TargetListReduxSlice;
};
export type PagesSlice = {
  hindsighting: HindsightingSlice;
  reporting: ReportingSlice;
  assortmentStrategy: AssortmentStrategySlice;
  assortmentBuild: AssortmentBuildSlice;
  allocation: AllocationSlice;
};

export default combineReducers({
  hindsighting,
  reporting: reportingReducer,
  assortmentStrategy: combineReducers({
    targetSetting: targetSettingReducer,
    targetList: (tlr?: TargetListReduxSlice) => {
      if (isNil(tlr)) {
        return createEmptyTargetList();
      }
      return tlr;
    },
  }),
  assortmentBuild,
  allocation,
});
