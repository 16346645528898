import * as React from 'react';

export interface TopNavIconLinkProps {
  id?: string;
  iconClass: string;
  href: string;
  onClick?: () => void;
  target?: string;
}

export default function TopNavIconLink(props: TopNavIconLinkProps) {
  const { id, iconClass, href, onClick, target = '_self' } = props;
  return (
    <a id={id} className="navbar-brand" href={href} onClick={onClick} target={target} data-qa="TopNavIconLink">
      <i className={iconClass} aria-hidden="true" data-qa="TopNavIconLinkIcon" />
    </a>
  );
}
