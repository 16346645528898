import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Checkbox, MuiThemeProvider } from '@material-ui/core';
import { orderBy, cloneDeep, clone, without } from 'lodash';

import TemplateModalWithButton from 'src/components/TemplateModalWithButton/TemplateModalWithButton';
import viewConfiguratorStyle, { getItemStyle, getListStyle } from './ViewConfiguratorModal.styles';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { Option } from '../Configure/ConfigureModal';
import { FavoriteListItemStorage } from '../Subheader/Favorites/FavoritesMenu';
import { ColumnApi } from 'ag-grid-community';
import SubheaderQuickActionButton from '../Subheader/SubheaderQuickActionButton';
import { muiTheme } from 'src/utils/Style/Theme';

export type CompanionFavoriteData = {
  companionSortDirection: 'asc' | 'desc';
  companionCollapsed: boolean;
  companionSortField?: string;
};

export type ViewConfiguratorModalProps = {
  viewConfig: TenantConfigViewData; // | TenantConfigViewItem;
  getColumnApi?: () => ColumnApi | undefined; // Get an up to date grid data
  unmodifiedViewDefn: TenantConfigViewData | TenantConfigViewItem;
  companionData?: CompanionFavoriteData;
  defaultCompanionData?: CompanionFavoriteData;
  configurationSelections?: Option[];
  defaultConfigurationSelections?: Option[];
  isGrid?: boolean;
  subheaderTitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateConfig?: (config: any) => void;
  updateConfiguration?: (configurationSelections: Option[]) => void;
  onSubmit?: (config: TenantConfigViewData) => void;
};

export type ViewConfiguratorModalState = {
  showResetConfirmation: boolean;
  viewConfig: TenantConfigViewItem[];
  onOpenViewConfig: TenantConfigViewItem[];
  openOverride?: boolean;
};

export type LocalStorageConfig = FavoriteListItemStorage;

export default class ViewConfiguratorModal extends React.Component<
  ViewConfiguratorModalProps,
  ViewConfiguratorModalState
> {
  constructor(props: ViewConfiguratorModalProps) {
    super(props);
    this.state = {
      viewConfig: [],
      onOpenViewConfig: [],
      showResetConfirmation: false,
    };
  }

  onOpen = () => {
    const viewColumnsConfig = cloneDeep(this.props.viewConfig.view || this.props.viewConfig.columns);
    // Reorder the config based off the grid, so if the user makes changes via aggrid, they reflect here
    const reorderConfig: TenantConfigViewItem[] = [];
    if (this.props.getColumnApi) {
      const columnApi = this.props.getColumnApi();
      if (columnApi) {
        // If a config has nested columns, check if some were hidden in grid and keep them hidden/unhidden
        const agGridColumnsState = columnApi.getColumnState();
        viewColumnsConfig
          .filter((viewColumnConfig) => !viewColumnConfig.hideFromConfigurator)
          .map((viewColumnConfig, i) => {
            // Determines if the current viewColumnConfig is present in grid columns state
            // in case the user made column changes within the grid before opening modal.
            // Those changes need to be reflected in the configurator modal.
            // Multiple items may be returned if there are columns with the same id/dataIndex value.
            const agGridColumnMatches = agGridColumnsState.filter(
              (gridColumn) =>
                gridColumn.colId.replace(/_\d$/, '') == (viewColumnConfig.id || viewColumnConfig.dataIndex)
            );

            // Finds current viewColumnConfig in the viewColumnsConfig.
            // Multiple items may be returned if there are columns with the same id/dataIndex value.
            const viewColumnMatches = viewColumnsConfig.filter(
              (viewColumn) => viewColumn.dataIndex === viewColumnConfig.dataIndex
            );

            // Some view configs have a group property that shares the same dataIndex as another column in the config.
            // Finds correct config item in viewColumnConfigs that is not supposed to be hidden from the configurator.
            // This is most likely going to filter out the 'Group' column.
            const visibleViewColumnIndex =
              viewColumnMatches && viewColumnMatches.findIndex((a) => !a.hideFromConfigurator);

            // We found the correct agGrid column in state to potentially modify
            const agGridColumn = agGridColumnMatches[visibleViewColumnIndex || 0];

            // Reorder columns based on the grid
            // If the viewConfigItem is found in the gridColumn's state, place it in the order based on grid
            if (agGridColumn) {
              reorderConfig[agGridColumnsState.indexOf(agGridColumn)] = viewColumnConfig;
            }

            // If config item has columns has to be ordered differently
            const cols = viewColumnConfig.columns;
            if (!agGridColumn && cols && cols.length > 0) {
              // Find the first column
              // Order the entire config item based off of the position of that first col
              const gridColumn = agGridColumnsState.find(
                (a) => a.colId.replace(/_\d$/, '') == (cols[0].id || cols[0].dataIndex)
              );

              if (gridColumn) {
                reorderConfig[agGridColumnsState.indexOf(gridColumn)] = viewColumnConfig;
              } else {
                // Or else just move it to the end
                reorderConfig[i + 99] = viewColumnConfig;
              }
            }

            // Determine and set column state for configurator,
            // visible determines the checkbox checked state.
            // Checking for hidden and visible in case config is setup with either or (we weren't consistent in config)

            if (agGridColumn && agGridColumn.hide) {
              viewColumnConfig.visible = false;
              viewColumnConfig.hidden = true;
            } else if (agGridColumn && !agGridColumn.hide && !viewColumnConfig.visible) {
              viewColumnConfig.visible = true;
            }

            // Now that we get the actual state of the column per config property,
            // we can change things like pinned as well
            if (agGridColumn && !agGridColumn.pinned) {
              viewColumnConfig.pinned = undefined;
            } else if (agGridColumn && agGridColumn.pinned) {
              viewColumnConfig.pinned = 'left';
            }

            return viewColumnConfig;
          });

        // If an item wasn't found in the ag grid state, add it to the end of the config
        viewColumnsConfig.forEach((columnConfig) => {
          if (reorderConfig.indexOf(columnConfig) == -1) {
            reorderConfig.push(columnConfig);
          }
        });
      }
    }
    // If we have access to aggrid, use the newconfig. If not, order the items ourself
    this.setState({
      viewConfig: reorderConfig.length > 0 ? reorderConfig.filter((x) => x) : this.reorderItems(viewColumnsConfig),
    });
  };

  onClose = () => {
    this.setState({
      openOverride: undefined,
    });
  };

  onCancel = () => {
    this.onClose();
  };

  onSubmit = () => {
    let newConfig: TenantConfigViewData = {
      ...this.props.viewConfig,
      view: this.state.viewConfig,
      columns: this.state.viewConfig,
    };
    if (this.props.viewConfig.grid) {
      newConfig = {
        ...newConfig,
        grid: {
          ...this.props.viewConfig.grid,
          view: this.state.viewConfig,
        },
      };
    }
    if (this.props.updateConfig) {
      this.props.updateConfig(newConfig);
    }
    if (this.props.onSubmit) {
      this.props.onSubmit(newConfig);
    }

    this.onClose();
  };

  onClickPinned = (item: TenantConfigViewItem, index: number) => {
    if (!this.isPinned(item.pinned)) {
      item.pinned = 'left';
      if (item.columns) {
        item.columns = item.columns.map((x) => {
          return { ...x, pinned: 'left' };
        });
      }
    } else {
      item.pinned = undefined;
      if (item.columns) {
        item.columns = item.columns.map((x) => {
          return { ...x, pinned: undefined };
        });
      }
    }
    let updatedItems = clone(this.state.viewConfig); //.slice(0);
    updatedItems[index] = item;
    // updatedItems = this.reorderItems(updatedItems);
    // If it is newly pinned, add it to the front, if we removed pinning, move it to the first non pinned item
    // We don't need to reorder 1 or  fewer
    if (updatedItems.length > 2) {
      if (this.isPinned(item.pinned)) {
        updatedItems = without(updatedItems, item);
        updatedItems.unshift(item);
      } else {
        const firstItemNotPinnedIndex = updatedItems.findIndex((x) => item != x && !x.pinned) - 1;
        updatedItems = without(updatedItems, item);
        updatedItems.splice(firstItemNotPinnedIndex, 0, item);
      }
    }
    this.setState({
      viewConfig: updatedItems,
    });
  };

  isPinned = (pinned: string | undefined) => {
    return pinned === 'left' ? true : false;
  };

  onClickVisible = (item: TenantConfigViewItem, index: number) => {
    item.visible = !this.itemVisible(item.visible);
    const updatedItems = this.state.viewConfig.slice(0);
    updatedItems[index] = item;
    if (!item.visible) {
      updatedItems.push(updatedItems.splice(index, 1)[0]);
    }
    this.setState({
      viewConfig: updatedItems,
    });
  };

  reorderItems = (items: TenantConfigViewItem[]) => {
    return orderBy(orderBy(items, 'visible', 'desc'), 'pinned', 'asc');
  };

  onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorder = (list: any, startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    let newConfig = reorder(
      this.state.viewConfig,
      result.source.index,
      result.destination.index
    ) as TenantConfigViewItem[];
    newConfig = orderBy(newConfig, 'pinned', 'asc');
    newConfig = orderBy(
      newConfig.map((x) => {
        if (x.visible) {
          delete x.visible;
          delete x.hidden;
        }
        return x;
      }),
      'visible',
      'desc'
    );

    this.setState({
      viewConfig: newConfig,
    });
  };

  showResetConfirmation = () => {
    const modal = document.body.querySelector<HTMLDivElement>('[class^=MuiDialog]');
    modal ? (modal.style.zIndex = '51') : undefined;
    this.setState({
      showResetConfirmation: true,
    });
  };

  hideResetConfirmation = () => {
    this.setState({
      showResetConfirmation: false,
    });
  };

  resetViewConfigurator = () => {
    // Grid should not keep changes made from configurator unless a favorite is saved
    if (this.props.updateConfig) {
      this.props.updateConfig(this.props.unmodifiedViewDefn);
    }
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.unmodifiedViewDefn as TenantConfigViewData);
    }

    this.setState({
      showResetConfirmation: false,
      viewConfig:
        (this.props.unmodifiedViewDefn.view as TenantConfigViewItem[]) ||
        (this.props.unmodifiedViewDefn.columns as TenantConfigViewItem[]),
      openOverride: false,
    });
  };

  itemVisible = (visible?: boolean) => {
    return visible === false ? false : true;
  };

  render() {
    const { isGrid } = this.props;
    const { showResetConfirmation, openOverride } = this.state;
    return (
      <MuiThemeProvider theme={muiTheme}>
        {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
        <TemplateModalWithButton
          buttonComponent={<SubheaderQuickActionButton iconClass={'fas fa-wrench'} />}
          title={`Configure ${this.props.subheaderTitle} Metrics`}
          onOpen={this.onOpen}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          showSubmit={true}
          openOverride={openOverride}
        >
          <ConfirmationModal
            isOpen={showResetConfirmation}
            descriptionText="Are you sure you want to reset?"
            onConfirm={() => this.resetViewConfigurator()}
            onCancel={() => this.hideResetConfirmation()}
          />
          <div className={viewConfiguratorStyle}>
            <div className="modal-grid">
              <div className="columns-grid">
                {this.state.viewConfig && (
                  <React.Fragment>
                    <div className="columns-title-container">
                      <div className="metric-style item-text">
                        <p>Metric</p>
                      </div>
                      {isGrid && (
                        <div className="columns-checkbox">
                          <p>Pin</p>
                        </div>
                      )}
                      <div className="columns-checkbox">
                        <p>Show</p>
                      </div>
                    </div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                        {(provided: any, snapshot: any) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver, this.state.viewConfig.length)}
                          >
                            {this.state.viewConfig.map((item: TenantConfigViewItem, index: number) => {
                              if (item.hideFromConfigurator) {
                                return;
                              }
                              const id = (item.dataIndex || item.text) + index;
                              return (
                                <Draggable key={id} draggableId={id} index={index}>
                                  {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                                  {(provided: any, snapshot: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <div className="item-text">
                                        <p>{item.text}</p>
                                      </div>
                                      {isGrid && (
                                        <div className="columns-checkbox">
                                          <Checkbox
                                            title={'Pinned?'}
                                            checked={this.isPinned(item.pinned)}
                                            onClick={() => this.onClickPinned(item, index)}
                                          />
                                        </div>
                                      )}

                                      <div className="columns-checkbox">
                                        <Checkbox
                                          title={'Visible?'}
                                          checked={this.itemVisible(item.visible)}
                                          onClick={() => this.onClickVisible(item, index)}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="footer">
              <button className="reset-button primary" onClick={() => this.showResetConfirmation()}>
                RESET
              </button>
            </div>
          </div>
        </TemplateModalWithButton>
      </MuiThemeProvider>
    );
  }
}
