import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';

export type ViewDefns = {
  keyFinancials: TenantConfigViewData;
  choiceProductivity: TenantConfigViewData;
  productMixAndTrend: TenantConfigViewData;
  trendAnalysis: TenantConfigViewData;
  globalRegionVolumeAndTrend: GeoTrendsConfigData;
};
export type ChoiceProductivity = BasicPivotItem[];
export type ViewData = {
  keyFinancials: BasicPivotItem[];
  choiceProductivity: ChoiceProductivity;
  trendAnalysis: BasicPivotItem[];
  productMixAndTrend: BasicPivotItem[];
  globalRegionVolumeAndTrend: BasicPivotItem[];
};

export type Action =
  | { type: 'REQUEST_SUMMARY_CONFIGS' }
  | { type: 'RECEIVE_SUMMARY_CONFIGS'; configs: ViewDefns }
  | { type: 'REQUEST_CHOICE_PRODUCTIVITY_DATA' }
  | { type: 'RECEIVE_CHOICE_PRODUCTIVITY_DATA'; data: ChoiceProductivity }
  | { type: 'REQUEST_SUMMARY_DATA' }
  | { type: 'RECEIVE_SUMMARY_DATA'; data: ViewData }
  | { type: 'CLEAN_UP_SUMMARY' }
  | { type: 'RECEIVE_SUMMARY_ERROR'; message: string };

export const REQUEST_SUMMARY_CONFIGS = 'REQUEST_SUMMARY_CONFIGS';
export default function requestSummaryConfigs(): Action {
  return {
    type: REQUEST_SUMMARY_CONFIGS,
  };
}

export const RECEIVE_SUMMARY_CONFIGS = 'RECEIVE_SUMMARY_CONFIGS';
export function receiveSummaryConfigs(configs: ViewDefns): Action {
  return {
    type: RECEIVE_SUMMARY_CONFIGS,
    configs,
  };
}

export const REQUEST_SUMMARY_DATA = 'REQUEST_SUMMARY_DATA';
export function requestSummaryData(): Action {
  return {
    type: REQUEST_SUMMARY_DATA,
  };
}
export const REQUEST_CHOICE_PRODUCTIVITY_DATA = 'REQUEST_CHOICE_PRODUCTIVITY_DATA';
export function requstChoiceProductivityData(): Action {
  return {
    type: REQUEST_CHOICE_PRODUCTIVITY_DATA,
  };
}

export const RECEIVE_CHOICE_PRODUCTIVITY_DATA = 'RECEIVE_CHOICE_PRODUCTIVITY_DATA';
export function receiveChoiceProductivityData(data: ChoiceProductivity): Action {
  return {
    type: RECEIVE_CHOICE_PRODUCTIVITY_DATA,
    data,
  };
}

export const RECEIVE_SUMMARY_DATA = 'RECEIVE_SUMMARY_DATA';
export function receiveSummaryData(data: ViewData): Action {
  return {
    type: RECEIVE_SUMMARY_DATA,
    data,
  };
}

export const CLEAN_UP_SUMMARY = 'CLEAN_UP_SUMMARY';
export function cleanUp(): Action {
  return {
    type: CLEAN_UP_SUMMARY,
  };
}

export const RECEIVE_SUMMARY_ERROR = 'RECEIVE_SUMMARY_ERROR';
export function receiveSummaryError(message: string): Action {
  return {
    type: RECEIVE_SUMMARY_ERROR,
    message,
  };
}
