import { style } from 'typestyle';
import { px, rem, important, rgba } from 'csx';

export const button = style({
  $nest: {
    '& i': {
      marginRight: rem(0.75),
    },
    '& div[role="progressbar"]': {
      marginRight: rem(0.75),
    },
    '&:focus': {
      boxShadow: important('none'),
    },
  },
});

export const dropdownToggle = style({
  $nest: {
    '&.dropdown-toggle:after': {
      top: px(10),
      right: px(7),
      color: 'white',
    },
    '&.dropdown-toggle:focus': {
      boxShadow: important('none'),
    },
    '&.dropdown-toggle-split': {
      padding: `${px(6)} ${px(12)} ${px(6)} ${px(12)}`,
      borderLeft: `${px(1)} solid #888`,
    },
  },
});

export const dropdownMenu = style({
  $nest: {
    '&.dropdown-menu.show': {
      left: important(px(-10)),
      padding: rem(1),
      width: px(305),

      $nest: {
        '& div': {
          marginBottom: rem(0.25),
          cursor: 'pointer',
          minHeight: px(30),
        },
        '& div:last-of-type': {
          marginBottom: 0,
        },
        '& div:hover': {
          backgroundColor: rgba(0, 0, 0, 0.075).toString(),
        },
        '& i': {
          marginRight: rem(0.75),
        },
        '& span': {
          fontSize: px(14),
        },
      },
    },
  },
});
