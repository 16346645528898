import * as React from 'react';
import {
  ColDef,
  ColGroupDef,
  ValueGetterParams,
  IsColumnFuncParams,
  SuppressKeyboardEventParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { GridReadyEvent, BodyScrollEvent, CellValueChangedEvent } from 'ag-grid-community/dist/lib/events';
import { Checkbox, IconButton, Icon } from '@material-ui/core';
import { classes } from 'typestyle';
import * as _ from 'lodash';
import { CSSProperties } from 'react';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { ColumnApi } from 'ag-grid-community/dist/lib/columnController/columnApi';
import 'ag-grid-enterprise';
import styles, { MUIStyles } from './FlowSheetGrid.styles';
import { TimeEntry, PayloadObject } from 'src/worker/pivotWorker.types';
import Renderer from 'src/utils/Domain/Renderer';
import {
  EDITABLE_BG_COLOR,
  FlowSheetCellRendererParams,
  FlowSheetGridProps,
  FlowSheetGridState,
  getId,
  IS_PUBLISHABLE_INDEX,
  LOCKED_INDEX,
  OVERRIDE_VRP_INDEX,
  PUBLISH_INDEX,
  RowData,
  TEST_PO,
  FLOORSET_PO,
  DC_USERADJ,
  DC_FINREV,
  SYS_VRP_INDEX,
  DC_ONORDER,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/types';
import { default as ReceiptsAdjCalculator } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TabbedReceiptsAdjCalculator';
import AgGridHeaderBreakClass from 'src/utils/Style/AgGridThemeBreakHeaders';
import { isBoolean } from 'util';
import { isNil, isString, isEmpty, isEqual } from 'lodash';
import { GetMainMenuItemsParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { BLOCK_ENTER_EDITORS, IS_PUBLISHED, POPOVER_BLOCK_CODES } from 'src/utils/Domain/Constants';
import * as globalMath from 'mathjs';
import { executeCalculation } from 'src/utils/LibraryUtils/MathUtils';
import { ICellEditorParams, ICellRendererParams, ProcessCellForExportParams } from 'ag-grid-community';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';
import {
  TextValidationEditor,
  TVE_InputCharacterWhitelist,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationEditor';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { get } from 'lodash';
import TooltipRenderer from '../../StyleEdit/StyleEditSection/Renderers/TooltipRenderer';
import { listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import { Overlay } from 'src/common-ui/index';
import PublishGridRenderer from 'src/components/PublishGridRenderer/PublishGridRenderer';
import { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';

export const MAX_COLS_VISIBLE = 20; // this is slightly higher than necessary but ensures functionality is correct

function _convertBooleanToNumber(a: boolean | number): number {
  if (isBoolean(a)) {
    return a ? 1 : 0;
  } else {
    return a;
  }
}

const getValidTypedValue = (val: string | number | boolean | undefined): number | undefined => {
  if (isNil(val)) {
    return val;
  }

  if (isString(val)) {
    return parseInt(val);
  } else if (isBoolean(val)) {
    return val ? 1 : 0;
  }

  return val;
};

class FlowSheetCellRenderer extends React.Component<FlowSheetCellRendererParams> {
  constructor(props: FlowSheetCellRendererParams) {
    super(props);
  }

  render() {
    const colDef = this.props.column.getColDef();
    const data = this.props.data;
    const field = colDef.field || '';
    const groupId = data.groupId;
    const measureId = data.measureId;
    const component = this.props.context.componentParent;

    const isLockedNode = this.props.api.getRowNode(getId(data.groupId, LOCKED_INDEX));
    const isLockedData: RowData = isLockedNode.data;
    const isLocked = !!isLockedData.extraData[field]; // week/column isLocked
    const isPublishedNode = this.props.api.getRowNode(getId(data.groupId, PUBLISH_INDEX));
    const isPublishedData: RowData = isPublishedNode.data;
    const isPublished = !!isPublishedData.extraData[field];

    const isEditable = !!data.extraData[field + '_editable'] && !!data.editable && this.props.editable();
    const isVisiblyEditable = isEditable && !isLocked && !isPublished ? EDITABLE_BG_COLOR : undefined;

    const valueStyle: CSSProperties = {
      backgroundColor: isVisiblyEditable,
      width: '100%',
      height: '100%',
      whiteSpace: 'pre',

      // Fixes for centering columns
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const renderFn = Renderer[data.renderer || ''] || ((a: string | number | undefined) => a);

    if (data.extraData.calculation && this.props.value) {
      return <div style={valueStyle}>{renderFn(this.props.value)}</div>;
    }

    if (data.path.length === 2) {
      const value = data.extraData[field];

      if (measureId === TEST_PO || measureId === FLOORSET_PO) {
        const typedValue = getValidTypedValue(value);
        const iconClass = isNil(value) || typedValue === 0 ? 'far fa-square' : 'far fa-check-square';
        const isDisabled = isLocked || isPublished;
        const disabledClass = isDisabled ? 'icon-disabled' : '';
        const icon = <i className={classes(iconClass, disabledClass)} />;

        return (
          <IconButton
            classes={MUIStyles.IconButton}
            disabled={isDisabled}
            onClick={() => {
              const rowNode = this.props.api.getRowNode(getId(data.groupId, measureId));
              const oldValue = getValidTypedValue(rowNode.data.extraData[field]);
              const newValue = isNil(oldValue) || oldValue === 0 ? 1 : 0;
              component.updateCell(groupId, measureId, field, newValue);
              component.refreshField(field);
            }}
          >
            {icon}
          </IconButton>
        );
      }

      if (measureId === OVERRIDE_VRP_INDEX) {
        let isDisabled = isLocked || isPublished;
        let disabledClass = isDisabled ? 'icon-disabled' : '';
        if (!data.extraData[field + '_dependentData']) {
          return <div />;
        }

        // Simpler to change the 0 and 1s to false and true since how grid works already
        const userVrp = !!value;
        const sysVrp = !!data.extraData[field + '_dependentData'][SYS_VRP_INDEX];
        const onOrder = data.extraData[field + '_dependentData'][DC_ONORDER];
        let iconClass;

        if (!isNil(onOrder) && onOrder > 0) {
          // check and disable receipt week checkbox if onorders are present
          iconClass = 'far fa-check-square';
          disabledClass = 'icon-disabled';
          isDisabled = true;
        } else if (sysVrp === true) {
          if (userVrp === true) {
            iconClass = 'far fa-square';
          } else {
            iconClass = 'far fa-check-square';
          }
        } else {
          if (userVrp === true) {
            iconClass = `far fa-check-square ${styles.isOverride}`;
          } else {
            iconClass = 'far fa-square';
          }
        }

        const icon = <i className={`${iconClass} ${disabledClass}`} />;
        return (
          <IconButton
            classes={MUIStyles.IconButton}
            onClick={() => {
              const oldValue = this.props.api.getRowNode(getId(data.groupId, OVERRIDE_VRP_INDEX)).data.extraData[field];
              const newValue = !!oldValue ? 0 : 1;
              component.updateCell(groupId, OVERRIDE_VRP_INDEX, field, newValue);
              component.refreshField(OVERRIDE_VRP_INDEX);
            }}
            disabled={isDisabled}
          >
            {icon}
          </IconButton>
        );
      }

      if (measureId === LOCKED_INDEX) {
        let icon = <i className={`far fa-lock`} />;
        if (!value) {
          icon = <i className={`far fa-lock-open`} />;
        }
        return (
          <IconButton
            classes={MUIStyles.IconButton}
            disabled={isPublished}
            onClick={() => {
              component.updateCell(groupId, LOCKED_INDEX, field, _convertBooleanToNumber(!value));
            }}
          >
            {icon}
          </IconButton>
        );
      }

      if (measureId === PUBLISH_INDEX) {
        const isPublishableNode = this.props.api.getRowNode(getId(data.groupId, IS_PUBLISHABLE_INDEX));
        const isPublishableData: RowData = isPublishableNode.data;
        const isPublishable = !!isPublishableData.extraData[field];
        return (
          <Checkbox
            classes={MUIStyles.IconButton}
            icon={<Icon className={'far fa-circle'} />}
            checkedIcon={<Icon className={'far fa-check-circle'} />}
            onClick={() => {
              component.updateCell(groupId, PUBLISH_INDEX, field, _convertBooleanToNumber(!value));
              if (!value) {
                // lock follows publish per Chetan's request
                _.defer(() => {
                  component.updateCell(groupId, LOCKED_INDEX, field, _convertBooleanToNumber(!value));
                });
              } else {
                _.defer(() => {
                  component.updateCell(groupId, LOCKED_INDEX, field, 0.0);
                });
              }
              component.refreshField(field);
            }}
            checked={!!value}
            disabled={!isPublishable || !isEditable}
          />
        );
      }

      const isEmpty = value === null || typeof value === 'undefined' || String(value).trim() === '';
      const display = isEmpty ? '' : renderFn(value);
      return (
        <div className="flowsheet-cell-renderer" style={valueStyle}>
          {display}
        </div>
      );
    }

    return null;
  }
}

class HeaderCellRenderer extends React.Component<FlowSheetCellRendererParams> {
  constructor(props: FlowSheetCellRendererParams) {
    super(props);
  }

  onToggle = (_event: React.MouseEvent<HTMLSpanElement>) => {
    const { data, onHeaderClick } = this.props;

    if (onHeaderClick) {
      onHeaderClick({
        id: data.id,
        parentId: data.additionalId,
      });
    }
  };

  render() {
    const params = this.props;
    const data = params.data;
    let textName = data.name || data.measureId;
    const level = data.path.length;
    if (level === 1) {
      textName = data.name || data.groupId;
      return (
        <span className={`${styles.headerName} level-${level}`} onClick={this.onToggle}>
          {textName}
        </span>
      );
    }
    return <span className={`${styles.headerName} level-${level}`}>{textName}</span>;
  }
}

export class FlowSheetGrid extends React.Component<FlowSheetGridProps, FlowSheetGridState> {
  private _isUnMounted = false;
  protected gridApi!: GridApi;
  protected columnApi!: ColumnApi;
  protected GRID_EXPORT_NAME = 'Flowsheet-Export';
  math: globalMath.MathJsStatic = (globalMath as any).create();

  constructor(props: FlowSheetGridProps) {
    super(props);

    const anchorField = props.anchorField || '';
    const columnDefs = this.decorateWithStyleClass(this.props.timeEntries, 0, anchorField);
    this.state = {
      anchorField,
      columnDefs: columnDefs,
      context: { componentParent: this },
      rowData: [],
      rowDataOld: [],
      frameworkComponents: {
        flowSheetRenderer: FlowSheetCellRenderer,
        headerCellRenderer: HeaderCellRenderer,
        receiptsAdjCalculator: ReceiptsAdjCalculator,
        integerEditor: IntegerEditor,
        textValidationEditor: TextValidationEditor,
        tooltipRenderer: TooltipRenderer,
        publishRenderer: PublishGridRenderer,
      },
    };

    this.onGridReady = this.onGridReady.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
  }

  componentDidMount() {
    if (this.props.onRenderGrid) {
      this.props.onRenderGrid();
    }
  }

  componentDidUpdate(prevProps: FlowSheetGridProps) {
    if (
      this.props.onCompanionItemChange &&
      !isEmpty(prevProps.itemId) &&
      !isEqual(prevProps.itemId, this.props.itemId)
    ) {
      this.props.onCompanionItemChange();
    } else if (!isEqual(prevProps.timeEntries.length, this.props.timeEntries.length)) {
      const columnDefs = this.decorateWithStyleClass(this.props.timeEntries, 0, this.props.anchorField || '');
      this.setState({
        columnDefs,
      });
    }
    if (this.props.rowData && this.gridApi && !isEqual(prevProps.rowData, this.props.rowData)) {
      this.gridApi.setRowData(this.props.rowData);
      this.setState({
        rowData: this.props.rowData,
      });
    }
    if (!isEqual(prevProps.editable, this.props.editable) && this.gridApi) {
      this.gridApi.refreshCells({ force: true });
    }
  }

  componentWillUnmount() {
    this._isUnMounted = true;

    if (this.props.onCleanup) {
      this.props.onCleanup();
    }
  }

  getDiff(): PayloadObject[] {
    const before = this.state.rowDataOld;
    const after = this.state.rowData;
    if (before.length !== after.length) {
      throw new Error('Size Mismatch for diff');
    }
    const ln = before.length;
    const weeks = _.flatMap(this.props.timeEntries, (month) => month.children);

    const groups = {};
    weeks.forEach((week) => {
      for (let i = 0; i < ln; ++i) {
        const rowBefore = before[i];
        const rowAfter = after[i];

        // Skip things not at level 2
        if (rowBefore.path.length !== 2) {
          continue;
        }

        if (rowBefore.groupId !== rowAfter.groupId) {
          throw new Error('Data Key Mismatch');
        }
        const measureId = rowBefore.measureId;
        if (measureId !== rowAfter.measureId) {
          throw new Error('Data Key Mismatch');
        }

        // can't work with undefined measures
        if (!measureId) {
          continue;
        }

        const d1 = rowBefore.extraData[week.id];
        const d2 = rowAfter.extraData[week.id];

        // same data - do nothing
        if (d1 === d2) {
          continue;
        }

        let groupItem = groups[rowBefore.groupId];
        if (!groupItem) {
          groupItem = groups[rowBefore.groupId] = {};
        }

        let weekItem = groupItem[week.id];
        if (!weekItem) {
          weekItem = groupItem[week.id] = {};
        }

        let value: string | number | boolean | null = rowAfter.extraData[week.id];
        if (typeof value === 'undefined' || value === '') {
          value = null;
        }
        weekItem[measureId] = value;
      }
    });

    const items: PayloadObject[] = [];
    Object.keys(groups).forEach((groupId) => {
      const group = groups[groupId];
      Object.keys(group).forEach((weekId) => {
        const weekItem = group[weekId];
        weekItem['product'] = groupId;
        weekItem['time'] = weekId;
        items.push(weekItem);
      });
    });
    return items;
  }

  onGridReady(event: GridReadyEvent) {
    if (event.api && event.columnApi) {
      this.gridApi = event.api;
      this.columnApi = event.columnApi;

      if (this.props.rowData && !this._isUnMounted) {
        this.setState({ rowData: this.props.rowData });
      }
      event.api.setRowData(this.state.rowData);

      this.scrollAnchorIntoView(event);
    }
  }

  scrollAnchorIntoView(event: GridReadyEvent) {
    const anchorField =
      !isNil(event) && !isNil(event.columnApi) ? event.columnApi.getColumn(this.state.anchorField) : null;

    if (isNil(anchorField)) {
      return;
    }

    // find index of anchor field to determine next predetermined number of columns to ensureColumnVisible on
    const allColumns = this.columnApi.getAllColumns();
    const anchorCol = allColumns.find((column) => column.getColId() === this.state.anchorField);

    if (anchorCol) this.gridApi.ensureColumnVisible(allColumns[allColumns.length - 1]);
    if (anchorCol) this.gridApi.ensureColumnVisible(anchorCol);
  }

  publishItems = (columnId: string, rowNode: RowNode, isPublished: boolean) => {
    const update = {
      product: rowNode.data.groupId, // id has dataIndex appended, groupId is actual id value
      time: columnId,
      [IS_PUBLISHED]: isPublished ? 0 : 1, // if already published, send 0 to de-publish
    };

    const publishHandler = this.props.onValueChange || this.props.submitPayload;
    if (publishHandler) {
      publishHandler({
        keyField: ['product', 'time'],
        create: [],
        update: [update],
        delete: [],
      });
    }
  };

  decorateWithStyleClass(items: TimeEntry[], depth = 0, anchorField: string): ColDef[] {
    const firstIndex = 0;
    const lastIndex = items.length - 1;
    const newItems: ColDef[] = items.map((item, i) => {
      const classList = [`depth-${depth}-item-${i}`];

      if (item.id === anchorField) {
        classList.push('anchor-field');
      }

      if (firstIndex === i) {
        classList.push(`depth-${depth}-first`);
      }
      if (lastIndex === i) {
        classList.push(`depth-${depth}-last`);
      }

      return {
        headerName: item.description || item.id,
        field: item.id,
        cellClass: () => classList,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.data && params.data.inputType && BLOCK_ENTER_EDITORS.includes(params.data.inputType)) {
            if (params.editing && POPOVER_BLOCK_CODES.includes(params.event.code)) {
              return true;
            }
          }
          return false;
        },
        cellRendererSelector: (params: ICellRendererParams): ComponentSelectorResult => {
          const renderer: string = params.data.renderer;
          switch (renderer) {
            case 'tooltipRenderer':
              return {
                component: 'tooltipRenderer',
              };
            case 'publishRenderer':
              return {
                component: 'publishRenderer',
                params: {
                  onPublish: this.publishItems,
                },
              };
            default:
              return {
                component: 'flowSheetRenderer',
              };
          }
        },
        valueGetter: (params: ValueGetterParams) => {
          const rowData = this.state.rowData;
          const rowDataIndex = rowData.findIndex((data) => {
            if (data.id === params.data.id) {
              return true;
            }
            return false;
          });

          if (rowData[rowDataIndex].extraData && rowData[rowDataIndex].extraData.calculation) {
            const getDataFromKey = (key: string) => {
              const returnObj = {
                rowNodeFound: false,
                data: undefined,
              };
              const rowNode = params.api!.getRowNode(params.node.id.split('_')[0] + `_${key}`);
              if (rowNode) {
                returnObj.rowNodeFound = true;
                returnObj.data = rowNode.data.extraData[params.colDef!.field!];
              }
              return returnObj;
            };
            const newValue = executeCalculation(
              this.math,
              rowData[rowDataIndex].extraData.calculation as string,
              getDataFromKey
            );
            return newValue;
          } else {
            return params.data.extraData[params.colDef!.field!];
          }
        },
      };
    });

    items.forEach((item: TimeEntry, i) => {
      if (item.children && item.children.length) {
        const colDef = newItems[i] as ColGroupDef;
        colDef.marryChildren = true;
        colDef.children = this.decorateWithStyleClass(item.children, depth + 1, anchorField);
      }
    });

    return newItems;
  }

  cellEditorSelector = (params: ICellEditorParams): ComponentSelectorResult => {
    const colDef = params.colDef;
    if (params.data.measureId && colDef) {
      if (
        params.data.measureId === OVERRIDE_VRP_INDEX ||
        params.data.measureId === TEST_PO ||
        params.data.measureId === FLOORSET_PO
      ) {
        return (null as unknown) as ComponentSelectorResult;
      }

      const rowData = this.state.rowData;
      const rowDataIndex = rowData.findIndex((data) => {
        if (data.id === params.data.id) {
          return true;
        }
        return false;
      });
      const inputParams = rowData[rowDataIndex].inputParams;

      switch ((params.data as RowData).inputType) {
        case 'receiptsAdjCalculator':
          const field = colDef.field;
          const isLockedNode = params.api!.getRowNode(getId(params.data.groupId, LOCKED_INDEX));
          const isLockedData: RowData = isLockedNode.data;
          const isLocked = !isNil(field) ? !!isLockedData.extraData[field] : false;

          const isPublishedNode = params.api!.getRowNode(getId(params.data.groupId, PUBLISH_INDEX));
          const isPublishedData: RowData = isPublishedNode.data;
          const isPublished = !isNil(field) ? !!isPublishedData.extraData[field] : false;
          const isEditable = !isLocked && !isPublished; // published/locked items will disable edits within editor
          return {
            component: 'receiptsAdjCalculator',
            params: {
              isEditable,
              dataApi: {
                url: '/api/assortment/adjustments/receipts',
                params: {
                  appName: 'assortment',
                  productId: params.data.groupId,
                  timeId: colDef.field,
                  defnId: 'ReceiptAdjustments',
                },
              },
              floorset: colDef.field,
            },
          };
        case 'integer':
          const percent = params.data.renderer === 'percent';
          return {
            component: 'integerEditor',
            params: {
              passedInt: params.data.extraData[colDef.field!],
              inputParams: { ...inputParams, percent },
              regularPosition: true,
            },
          };
        case 'textValidator':
          const whitelist: typeof TVE_InputCharacterWhitelist = get(
            TVE_InputCharacterWhitelist,
            inputParams!.whitelist,
            TVE_InputCharacterWhitelist.alphaNumericSeparators
          );

          return {
            component: 'textValidationEditor',
            params: {
              validateAsync: false,
              ...inputParams,
              whitelist,
            },
          };
        default:
      }

      return {
        component: 'agTextCellEditor',
      };
    }

    return (null as unknown) as ComponentSelectorResult;
  };

  isGroupEditable(params: ICellEditorParams): boolean {
    const data = params.data;
    return data.path.length === 1;
  }

  isEditable = (params: IsColumnFuncParams): boolean => {
    const data = params.data;
    const colDef = params.colDef;

    if (isNil(data) || isNil(colDef)) {
      return false;
    }

    const field = colDef.field || '';
    const isLockedNode = params.api!.getRowNode(getId(data.groupId, LOCKED_INDEX));
    const isLockedData: RowData = isLockedNode.data;
    const isLocked = !!isLockedData.extraData[field] && !!data.extraData[field + '_ignoreLock']; // week/column isLocked

    const isPublishedNode = params.api!.getRowNode(getId(data.groupId, PUBLISH_INDEX));
    const isPublishedData: RowData = isPublishedNode.data;
    const isPublished = !!isPublishedData.extraData[field] && !!data.extraData[field + '_ignorePublished']; // week/column isLocked
    const isEditable = !!data.extraData[field + '_editable'] && !!data.editable && this.props.editable;
    const canEdit = data.path.length > 0 && !!data.measureId && isEditable;

    // allow user override editors to be editable for viewing when locked or published
    return data.inputType === 'receiptsAdjCalculator' ? canEdit : canEdit && !isLocked && !isPublished;
  };

  updateCell(groupId: string, measureId: string, field: string, newValue: string | number | boolean) {
    const rowData = this.state.rowData;
    if (measureId && groupId) {
      const comparator = (tmp: RowData) => tmp.groupId === groupId && tmp.measureId === measureId;
      const index = _.findIndex(rowData, comparator);
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = newValue;
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;
        this.setState({ rowData: newRowData, rowDataOld: this.state.rowData }, () => {
          this.gridApi.updateRowData({
            update: [measureData],
          });
          this.handleValueChange();
          const column = this.columnApi.getColumn(field);

          this.gridApi.refreshCells({
            force: true,
            columns: [column],
          });
        });
      }
    }
  }

  refreshField(field: string) {
    const column = this.columnApi.getColumn(field);

    this.gridApi.refreshCells({
      force: true,
      columns: [column],
    });
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    const data = event.data;
    const rowData = this.state.rowData;
    const field = event.colDef.field || '';

    const measureId = event.data.measureId;
    if ((data[field] && measureId === DC_USERADJ) || measureId === DC_FINREV) {
      if (rowData && data.groupId) {
        const { onOrderRevision, userAdjRevision } = data[field] || { onOrderRevision: 0, userAdjRevision: 0 };
        const newRowData = rowData.slice(0);
        const updates: RowData[] = [];
        if (onOrderRevision !== undefined) {
          const path = [data.groupId, DC_FINREV];
          const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, path));
          if (index >= 0) {
            const measureData = _.cloneDeep(rowData[index]);
            measureData.extraData[field] = onOrderRevision;
            newRowData[index] = measureData;
            updates.push(measureData);
          }
        }
        // updated userAdjRevision
        if (userAdjRevision !== undefined) {
          const path = [data.groupId, DC_USERADJ];
          const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, path));
          if (index >= 0) {
            const measureData = _.cloneDeep(rowData[index]);
            measureData.extraData[field] = userAdjRevision;
            newRowData[index] = measureData;
            updates.push(measureData);
            // Update Valid receipt week as well
            const rcptIndex = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, [data.groupId, 'dc_uservrp']));
            const validRcptData = _.cloneDeep(rowData[rcptIndex]);
            const sysvrp = _.get(validRcptData, ['extraData', `${field}_dependentData`, SYS_VRP_INDEX]);
            // FIXME: This weirdness is due to how the dc_uservrp is handled. It is a reverse toggle of whatever sys_vrp is.
            // No...I don't know why.
            if (sysvrp === 1) {
              // we only want to toggle *off* uservrp IFF revision is 0, null does not cause the override to invalid toggle
              // that check may be unnecessary as all zeroes results in null, but it's a failsafe.
              validRcptData.extraData[field] = userAdjRevision === 0 ? 1 : 0;
            } else {
              validRcptData.extraData[field] = userAdjRevision > 0 ? 1 : 0;
            }
            newRowData[rcptIndex] = validRcptData;
            updates.push(validRcptData);
          }
        }
        this.setState({ rowData: newRowData, rowDataOld: this.state.rowData }, () => {
          this.gridApi.updateRowData({
            update: updates,
          });
          this.handleValueChange();
          this.refreshField(field);
        });
      }
      return;
    }

    if (rowData && data.measureId && data.groupId) {
      const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, data.path));
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = data[field];
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;

        this.setState({ rowData: newRowData, rowDataOld: this.state.rowData }, () => {
          this.gridApi.updateRowData({
            update: [measureData],
          });
          this.handleValueChange();
          this.gridApi.refreshCells({
            force: true,
            columns: [event.column],
            rowNodes: [event.node],
          });
        });
      }
    }
  }

  handleValueChange = () => {
    const diff = this.getDiff();
    if (!diff.length) {
      return;
    }

    const valueChangeHandler = this.props.onValueChange || this.props.submitPayload;

    if (valueChangeHandler) {
      valueChangeHandler({
        keyField: ['product', 'time'],
        create: [],
        update: diff,
        delete: [],
      });
    }
  };

  doesExternalFilterPass(node: RowNode): boolean {
    return !node.data.hidden;
  }

  onBodyScroll = (event: BodyScrollEvent) => {
    const editingCells = this.gridApi.getEditingCells();
    if (editingCells.length > 0) {
      const editingCell = editingCells[0];
      const editingRowNode = this.gridApi.getDisplayedRowAtIndex(editingCell.rowIndex);

      const headerRow = document.querySelector('.ag-header') as HTMLElement;
      const headerRowHeight = parseFloat((headerRow.style.height || '112px').replace('px', ''));
      const popupEditor = document.querySelector('.ag-popup-editor') as HTMLElement;
      if (popupEditor) {
        const calculatedPopupTop = editingRowNode.rowTop + headerRowHeight - event.top;
        const calculatedPopupLeft = editingCell.column.getLeft() - event.left;

        popupEditor.style.left = `${calculatedPopupLeft}px`;
        popupEditor.style.top = `${calculatedPopupTop}px`;
      }
    }
  };

  getColumnMenuItems = (params: GetMainMenuItemsParams) => {
    return params.defaultItems.filter((item) => item !== 'autoSizeAll');
  };

  renderPopover = (params: { id: string | undefined; parentId: string | undefined }) => {
    const { onItemClicked } = this.props;
    if (onItemClicked) {
      onItemClicked(params);
    }
  };

  getEditable = (): boolean => {
    return this.props.editable;
  };

  render() {
    if (!isNil(this.props.loading) && this.props.loading) {
      return (
        <div className={listPairStyle}>
          <Overlay type="loading" visible={true} />
        </div>
      );
    }

    const frameworkComponents = this.state.frameworkComponents as {
      [renderer: string]: { new (): Record<string, any> };
    };
    return (
      <ExtendedDataGrid
        className={classes(styles.dataGridStyle, AgGridHeaderBreakClass)}
        loaded={true}
        data={[]}
        singleClickEdit={true}
        columnDefs={this.state.columnDefs!}
        frameworkComponents={frameworkComponents}
        onGridReady={this.onGridReady}
        onBodyScroll={this.onBodyScroll}
        exportOptions={{
          fileName: this.GRID_EXPORT_NAME,
          processCellOverride: ({ node, column }: ProcessCellForExportParams) => {
            if (node && node.parent) {
              // Special case because OVERRIDE_VRP_INDEX's value is stored in dependentData
              if (column.getId() !== 'ag-Grid-AutoColumn' && node.data.measureId === OVERRIDE_VRP_INDEX) {
                const colId = column.getColId();
                const dependentData = node.data.extraData[colId + '_dependentData'];
                if (dependentData) {
                  const sysvrp = dependentData[SYS_VRP_INDEX];
                  const uservrp = node.data.extraData[colId];
                  if ((sysvrp === 1 && uservrp === 1) || (sysvrp === 0 && uservrp === 0)) {
                    return '0';
                  } else if (sysvrp === 1 || uservrp === 1) {
                    return '1';
                  } else {
                    return '';
                  }
                }
              }

              if (node.parent.id === 'ROOT_NODE_ID' && column.getId() === 'ag-Grid-AutoColumn') {
                const returnName: string = node.data.name;
                const [description] = returnName.split(':');
                // verify description is available and if not display error in cell text since data is not present
                const descriptionFound = !isEmpty(description);
                return descriptionFound ? returnName : `[ERROR: NO DESCRIPTION DATA] - ${returnName}`;
              }

              if (column.isPinnedLeft()) {
                return node.data.name;
              }
            }
          },
        }}
        extraAgGridProps={{
          // 'excludeChildrenWhenTreeDataFiltering' makes children of groups have to go through the external filter call separately
          // see SUP-24
          excludeChildrenWhenTreeDataFiltering: true,
          defaultColDef: {
            editable: this.isEditable,
            cellEditorSelector: this.cellEditorSelector,
            cellStyle: {
              margin: 0,
            },
            cellRendererParams: {
              editable: this.getEditable,
            },
            width: 130,
            lockPosition: true,
            resizable: true,
          },
          rowHeight: this.props.rowHeight,
          onCellValueChanged: this.onCellValueChanged,
          getRowNodeId: (data: RowData) => data.id,
          deltaRowDataMode: true,
          context: this.state.context,
          treeData: true,
          groupDefaultExpanded: 1,
          animateRows: true,
          getDataPath: (data: RowData) => data.path,
          suppressScrollOnNewData: true,
          stopEditingWhenGridLosesFocus: true,
          isExternalFilterPresent: () => true,
          doesExternalFilterPass: this.doesExternalFilterPass,
          getMainMenuItems: this.getColumnMenuItems,
          autoGroupColumnDef: {
            headerName: 'Name',
            width: 300,
            pinned: 'left',
            lockPinned: true,
            cellRendererParams: {
              suppressCount: true,
              innerRenderer: 'headerCellRenderer',
              onHeaderClick: this.renderPopover,
            },
            resizable: true,
          },
        }}
      />
    );
  }
}
