import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/inputIsNotNullOrUndefined';
import { fetchCompanionListData, updateSelectedLevel, receiveWorklistConfig, cleanUp } from './Worklist.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { RECEIVE_SCOPE_REFRESH_TRIGGER } from 'src/components/ScopeSelector/ScopeSelector.actions';
import {
  ConfDefnComponentType,
  WorklistComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { isNil } from 'lodash';
import { isWorklistActive } from 'src/services/configuration/codecs/confdefnComponents';

export const worklistCleanUp: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    map(() => isWorklistActive(state$.value)),
    filter((v) => v),
    mergeMap(() => {
      const actSubPage = state$.value.perspective.activeSubPage;
      if (actSubPage !== '') {
        return of(setActiveSubPage(''), cleanUp());
      } else {
        return of(cleanUp());
      }
    })
  );
};

// Separate config loading from level selection. Config load triggers
// the selected level (which the the relevant part for data fetching).
export const worklistConfigLoaded: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(receiveWorklistConfig.type),
    map(() => maybeGetComponentProps<WorklistComponent>(state$.value, ConfDefnComponentType.worklist)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(() => {
      const { viewDefn } = state$.value.pages.assortmentBuild.worklist;
      if (viewDefn == null) return of();
      let levelField;
      const levelBy = viewDefn.companionView.levelBy;
      if (levelBy && levelBy.view) {
        const defaultLevelBy = levelBy.defaults.dataIndex;
        let defaultLevelSelection = levelBy.view.find((i) => i.dataIndex === defaultLevelBy);
        if (defaultLevelSelection == null) {
          defaultLevelSelection = levelBy.view[0];
        }
        levelField = defaultLevelSelection;
        return of(updateSelectedLevel(levelField));
      } else {
        return of(updateSelectedLevel(undefined));
      }
    })
  );
};

export const worklistCompanionLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveFilterStateAfterSubmission.type,
      RECEIVE_SCOPE_REFRESH_TRIGGER,
      updateSelectedLevel.type,
      'LENS_ASST_PLAN_REFRESHING_PAGE'
    ),
    map(() => maybeGetComponentProps<WorklistComponent>(state$.value, ConfDefnComponentType.worklist)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns }) => {
      const { selectedLevel, viewDefn } = state$.value.pages.assortmentBuild.worklist;

      if (isNil(viewDefn)) {
        return of();
      }
      const levelBy = viewDefn.companionView.levelBy;
      if (!isNil(levelBy) && isNil(selectedLevel)) {
        return of();
      } else {
        return of(fetchCompanionListData(defns.model, { aggBy: selectedLevel?.groupingKey }));
      }
    })
  );
};
