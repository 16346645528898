import * as React from 'react';
import { Metric, DirectionalMetric } from '../../CommonTypes';
import { choiceProductivityStyle } from './ChoiceProductivity.styles';
import { downArrowClass, upArrowClass, neutralArrowClass } from '../../../../theme';

export type Props = {
  metrics: [Metric, DirectionalMetric, Metric];
  extraClasses: string;
};

export default function ChoiceProductivity(props: Props) {
  if (!Object.keys(props).length) {
    return null;
  }
  const { metrics, extraClasses } = props;

  const containerClasses = 'choice-productivity ' + choiceProductivityStyle + (extraClasses ? ' ' + extraClasses : '');

  const [primary, diff, secondary] = metrics;

  let iconClasses = 'dir fa fas ' + (diff.direction === 'down' ? 'down ' + downArrowClass : 'up ' + upArrowClass);

  let middleClasses = 'middle-container ' + (diff.direction === 'down' ? 'negative' : 'positive');

  if (diff.direction === 'neutral') {
    iconClasses = 'dir fa fas neutral ' + neutralArrowClass;
    middleClasses = 'middle-container zero';
  }

  return (
    <div className={containerClasses}>
      <div className="left-container">{primary.label}</div>
      <div className={middleClasses}>{primary.rendered}</div>
      <div className="right-container">
        <div className="diff-container">
          <i className={iconClasses} />
          <span className="diff">{diff.rendered}</span>
        </div>
        <div className="secondary">
          {secondary.label} {secondary.rendered}
        </div>
      </div>
    </div>
  );
}
