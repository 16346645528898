import { style, classes, keyframes } from 'typestyle';
import { color, important, rem, px, rgba } from 'csx';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import acceptButton from 'src/components/AcceptButton/AcceptButton.styles';
import RejectButton from 'src/components/RejectButton/RejectButton.styles';
const gridTitleMargin = px(10);
const editableBackgroundColor = color('#edf7f6');
const disabledTextColor = color('#c1c1c1');
const colorHeaderRowHeight = 130;
const defaultRowHeight = 30;
const validSizesRowHeight = 150;

const editableColumn = style({
  $debugName: 'editable-column',
  backgroundColor: editableBackgroundColor.toHexString(),
});

const editableCell = style({
  $debugName: 'editable-cell',
  color: TEAL_PRIMARY,
  justifyContent: 'center',
});

const headerCell = style({
  justifyContent: 'center',
});

const editableRow = style({
  $debugName: 'editable-row',
  backgroundColor: important(rgba(0, 168, 155, 0.2).toString()), // TEAL_PRIMARY with more opacity, important needed
  fontWeight: 'bold',
});

const cellDisabled = style({
  $debugName: 'StyleEditCellDisabled',
  cursor: 'not-allowed',
  color: disabledTextColor.toHexString(),
});

const notInLifecycle = style({
  $debugName: 'not-in-lifecycle',
  fontWeight: 600,
  letterSpacing: '1.2px',
  textTransform: 'uppercase',
  backgroundColor: '#F9F9F9',
  textAlign: 'center',
});

const fadeInAnimation = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const sectionGrid = style({
  $debugName: 'StyleEditSectionContainer',
  display: 'flex',
  flex: 1,
  width: '100%',
  height: '100%',
  fontSize: '0.8rem',
  $nest: {
    '.ag-row-footer': {
      fontWeight: 'bold',
    },
    [`.ag-row-odd:not(.ag-row-footer) .${editableColumn}.ag-cell-value`]: {
      backgroundColor: editableBackgroundColor.darken(0.02).toHexString(),
    },
    [`.ag-row-footer .${editableColumn}.ag-cell-value`]: {
      backgroundColor: 'white',
    },
    [`.ag-row-footer.ag-row-odd .${editableColumn}.ag-cell-value`]: {
      backgroundColor: '#f9f9f9',
    },
    '.ag-cell-label-container': {
      position: important('absolute'),
      top: important('0'),
    },
    '.ag-cell [class*="MuiIconButton-root"]': {
      padding: 0,
    },
    [`.ag-row-focus:not(.ag-row-hover) .${editableCell}`]: {
      color: 'white',
    },
    '& > div:first-child': {
      flex: 'auto',
    },
    '.ag-header .ag-pinned-left-header': {
      border: 'none',
    },
    '.ag-header .ag-header-viewport': {
      border: 'none',
    },
    '.ag-header .ag-pinned-right-header': {
      border: 'none',
    },
    '.ag-root': {
      overflow: important('initial'),
    },
    '.ag-body-viewport': {
      overflow: important('initial'),
    },
    '.ag-pinned-left-cols-container': {
      position: important('sticky'),
      left: 0,
      background: 'white',
      zIndex: 5,
    },
    '.ag-cell': {
      justifyContent: 'center',
    },
    '.ag-pinned-left-cols-container .ag-cell': {
      justifyContent: 'left',
    },
    '.ag-react-container': {
      width: 'unset',
    },
    '.ag-cell-inline-editing': {
      height: '100%',
      $nest: {
        '& input[type="text"]': {
          textAlign: 'center',
          // There's a little graphical bug this fixes, but also it looks nice
          animation: fadeInAnimation,
          animationDuration: '.25s',
          animationIterationCount: 1,
          animationFillMode: 'forwards',
        },
      },
    },
  },
});

const sectionGridTitle = style({
  width: 'calc(100% + 24px)',
  marginRight: gridTitleMargin,
  position: 'sticky',
  top: 220,
  fontSize: rem(1.1),
  background: 'white',
  zIndex: 10,
  display: 'inline-block',
  $nest: {
    '.leftTitleContainer': {
      position: 'sticky',
      left: 0,
      width: 199,
      background: 'white',
      paddingTop: 10,
    },
  },
});

const checkboxContainer = style({
  height: 40,
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  padding: 8,
});
const iconButton = style({
  float: 'right',
  width: 22,
  height: 22,
  $nest: {
    '> span': {
      fontSize: 28,
    },
  },
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  cursor: 'pointer',
  borderRadius: 4,
  minWidth: 0,
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#f9f9f9',
});
const iconButtonCancel = classes(
  iconButton,
  RejectButton,
  style({
    background: '#f44336',
    marginLeft: 5,
  })
);
const iconButtonSave = classes(
  iconButton,
  acceptButton,
  style({
    background: '#4caf50',
  })
);

export {
  editableColumn,
  editableCell,
  editableRow,
  cellDisabled,
  notInLifecycle,
  sectionGrid,
  sectionGridTitle,
  checkboxContainer,
  iconButton,
  iconButtonCancel,
  iconButtonSave,
  colorHeaderRowHeight,
  defaultRowHeight,
  validSizesRowHeight,
  headerCell,
};
