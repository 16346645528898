import * as React from 'react';
import { modal } from 'src/components/ConfirmationModal/ConfirmationModal.styles';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FavoriteListItem } from './FavoritesMenu';

type FavoritesDeleteModalProps = {
  fav: FavoriteListItem;
  isOpen: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onDelete: (fav: FavoriteListItem) => void;
};

export const FavoritesDeleteModal = (props: FavoritesDeleteModalProps) => {
  const { fav, onDelete, onClose } = props;

  return (
    <Modal isOpen={props.isOpen} toggle={onClose} size={'md'} className={modal}>
      <ModalHeader>
        <span className="left-container">
          <i className="fas fa-trash icon fa-sm" />
          Delete Favorite
        </span>
        <span className="right-container">
          <i className="far fa-times" onClick={onClose} />
        </span>
      </ModalHeader>
      <ModalBody>
        <p className="instructions">Delete Favorite: {fav.name}?</p>
        <footer>
          <button onClick={() => onDelete(fav)} className="apply">
            <i className="far fa-check" />
            Delete
          </button>
          <button onClick={onClose} className="reset">
            <i className="fas fa-ban" />
            Cancel
          </button>
        </footer>
      </ModalBody>
    </Modal>
  );
};
