import { toast } from 'react-toastify';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_URL_PREFIX } from 'src/utils/Domain/Constants';

const message = `Your current session has expired. \
  You will be redirected briefly to restore it.`;

const outroMessage = encodeURIComponent(
  'Your current session has expired, please re-verify again to restore it by clicking this link: '
);

let hasSession = false;
let isLoggingOut = false;

export function handleUnauthorized() {
  if (hasSession && !isLoggingOut) {
    // This path is entered when at least one authorized api call has succeeded in the front end session,
    // but at least one 401 is then returned
    isLoggingOut = true;
    toast.info(message, {
      position: toast.POSITION.TOP_LEFT,
      onClose: () => (window.location.href = `${API_URL_PREFIX}/outro?msg=${outroMessage}`),
      closeOnClick: true,
    });
  } else if (!isLoggingOut) {
    // This path is only reached when multiple unauthorized requests come in rapid succession
    // the first one should be caught by the above code path
    isLoggingOut = true;
    window.location.href = `${API_URL_PREFIX}/logout`;
  }
}

function isApiCall(config: AxiosRequestConfig) {
  const apiPrefix = `${API_URL_PREFIX}/api`;
  return config.url && config.url.startsWith(apiPrefix);
}

function isUnauthorized(error: AxiosError) {
  return error.response && error.response.status === 401;
}

export function authorizedInterceptor(response: AxiosResponse) {
  if (isApiCall(response.config)) {
    hasSession = true;
  }
  return response;
}

export function unauthorizedErrorInterceptor(error: AxiosError): Promise<Record<string, any>> {
  if (error.config) {
    if (isApiCall(error.config) && isUnauthorized(error)) {
      handleUnauthorized();
    }
  }
  return Promise.reject(error);
}
