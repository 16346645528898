import serviceContainer from 'src/ServiceContainer';
import NavigationShell from './NavigationShell';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { AppState, AppThunkDispatch } from 'src/store';
import { BoundPerspective } from 'src/services/configuration/bindings.types';
import { setActivePerspective, setActivePage, setActiveTab } from 'src/pages/NavigationShell/NavigationShell.slice';
import { connect } from 'react-redux';
import { getFilters } from 'src/components/FilterPanel/FilterPanel.container';
import {
  setSessionPerspective,
  setSessionPage,
  setSessionTab,
} from 'src/components/RestoreSession/RestoreSession.utils';
import { updateFlowStatus, updateFlowStatusConfig } from 'src/components/Subheader/Subheader.slice';
import { TenantConfig, FlowStatus } from 'src/services/configuration/bindings.types';

export type StateProps = {
  validScope: boolean;
  activeTab: string;
  tenantConfig: TenantConfig;
};
export type DispatchProps = {
  setActivePerspective(perspective: BoundPerspective): void;
  setActivePage(data: string): void;
  setActiveTab(tab: string): void;
  updateFlowStatus(values: number[]): void;
  updateFlowStatusConfig(config: FlowStatus): void;
};

export type Props = StateProps & DispatchProps;

function mapStateToProps(state: AppState): StateProps {
  return {
    validScope: state.scope.scope.valid,
    activeTab: state.perspective.activeTab,
    tenantConfig: state.appConfig.tenantConfig,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    setActivePerspective(perspective: BoundPerspective) {
      dispatch(setActivePerspective(perspective));
      setSessionPerspective(perspective.id);
    },
    setActivePage(data: string) {
      dispatch(setActivePage(data));
      if (data !== '/') setSessionPage(data);
    },
    setActiveTab(tab: string) {
      dispatch(setActiveTab(tab));
      setSessionTab(tab);
      dispatch(getFilters());
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateFlowStatus(values));
    },
    updateFlowStatusConfig(config: FlowStatus) {
      dispatch(updateFlowStatusConfig(config));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(makePrintSensitive(NavigationShell(serviceContainer.configService)));
