import { Action, combineReducers } from 'redux';

import pages from 'src/pages/Pages.reducer';
import scope from 'src/components/ScopeSelector/ScopeSelector.reducer';
import subheader from 'src/components/Subheader/Subheader.slice';
import rightContainer from 'src/components/RightContainer/RightContainer.slice';
import filters from 'src/components/FilterPanel/FilterPanel.slice';
import sidenavReducer from 'src/components/Sidenav/Sidenav.reducer';
import printReducer from 'src/components/higherOrder/Print/Print.reducer';
import historyStylePane from 'src/components/HistoryStylePane/HistoryStylePane.reducer';
import planTrackerReducer from 'src/pages/AssortmentBuild/Planning.slice';
import appConfigReducer from 'src/services/configuration/AppConfig.reducer';
import quickReconcileReducer from 'src/components/Reconcilation/QuickRecapView/QRCategorySummary.reducer';
import NavigationShellReducer from 'src/pages/NavigationShell/NavigationShell.slice';
import bulkImport from 'src/pages/Reporting/BulkImport/BulkImport.slice';

import { AppState } from './store';
import { isStepAction, StepAction } from './services/lenses/Lenses.actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const sidenavPersistConfig = {
  key: 'persistSideNav',
  storage,
  whitelist: ['collapsed'],
};

const persistedSidenavReducer = persistReducer(sidenavPersistConfig, sidenavReducer);

export const appReducer = combineReducers({
  appConfig: appConfigReducer,
  scope,
  historyStylePane,
  rightContainer,
  pages,
  print: printReducer,
  subheader,
  filters,
  bulkImport,
  sidenav: persistedSidenavReducer,
  perspective: NavigationShellReducer,
  quickReconcile: quickReconcileReducer,
  planTracker: planTrackerReducer,
});

export const rootReducer = (state: AppState | undefined, action: Action): ReturnType<typeof appReducer> => {
  // this exists a fall back to resetting initial state
  // doesnt actually work though, because the scope is immediately refetched
  if (action.type === 'RESET_THE_WORLD') {
    return appReducer(window.__debug.bootState!, action);
  }
  if (isStepAction(action) && state) {
    return (action as StepAction<AppState>).step(state);
  }

  return appReducer(state, action);
};
