import React, { Fragment } from 'react';
import { noop, isNil } from 'lodash';
import StarRatings from 'react-star-ratings';

import { ColorSwatch, InputGeneric } from 'src/common-ui/index';
import { ColorSwatchItemProps } from 'src/common-ui/components/ColorSwatch/ColorSwatchItem';
import { BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import { getSwatchUrl, getWhitelistType } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { StylePreviewData, StylePreviewConfig } from 'src/components/StylePreview/StylePreview.types';
import { CC_COLOR, STYLE_SUBMITTED_ATTR, STYLE_COLOR_ID } from 'src/utils/Domain/Constants';

import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';
import { swatchStyles } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/StyleDetailsEditor';
import { starGoldColor } from 'src/utils/Style/Theme';
import { worklistStatusMap } from 'src/components/WorklistIcon/WorklistIcon';

function getSwatches(filteredStyleColors: BasicPivotItem[]): ColorSwatchItemProps[] {
  return filteredStyleColors.map((styleColor) => {
    const styleColorId = styleColor[CC_COLOR];
    return {
      swatchPath: '',
      id: styleColor.id || '',
      colorId: styleColorId || '',
      selected: false,
      noImageUrl: '',
    };
  });
}

function getSwatchIndex(stylec: string | undefined, filteredStyleColors: BasicPivotItem[]): number {
  if (!stylec) {
    return -1;
  }
  return filteredStyleColors.findIndex((styleColor) => {
    const styleColorId = styleColor[STYLE_COLOR_ID];
    return styleColorId === stylec;
  });
}

type StyleOverviewProps = {
  allStyleColors: BasicPivotItem[];
  filteredStyleColors: BasicPivotItem[];
  previewData: StylePreviewData;
  selectedStyleColorId?: string;
  onChangeColor?: (id: string, position: number) => void;
};

export const StyleOverview = ({
  allStyleColors,
  filteredStyleColors,
  previewData,
  selectedStyleColorId,
  onChangeColor,
}: StyleOverviewProps) => {
  return (
    <Fragment>
      <img src={previewData.imgUri} alt="" />
      <div className="overviewRatingsContainer">
        <StarRatings
          rating={previewData.starRating || undefined}
          className="stars"
          numberOfStars={5}
          starDimension={'1rem'}
          starSpacing={'0.1rem'}
          starRatedColor={starGoldColor}
        />
        <div className={styleEditStyles.styleEditFilterCount}>
          {`All: ${allStyleColors.length} / Filtered: ${filteredStyleColors.length}`}
        </div>
        <ColorSwatch
          noImageUrl={''}
          className={swatchStyles}
          onChangeColor={onChangeColor ? onChangeColor : noop}
          style={{ overflowX: 'auto', marginTop: 5, marginLeft: 5, width: '30vw', maxWidth: 1000 }}
          colors={getSwatches(filteredStyleColors)}
          selected={getSwatchIndex(selectedStyleColorId, filteredStyleColors)}
          getSwatchUrl={getSwatchUrl}
        />
      </div>
    </Fragment>
  );
};

type StyleOverviewInputsProps = {
  config: StylePreviewConfig | undefined;
  data: BasicPivotItem | undefined;
  previewData: StylePreviewData;
  validStyleName: boolean;
  ignoreOverviewBackground?: boolean;
  renderSections?: boolean;
  onValidateName: (name: string) => void;
  onUpdateDescription: (description: string) => void;
};

export const StyleOverviewInputs = ({
  config,
  data,
  previewData,
  validStyleName,
  ignoreOverviewBackground,
  renderSections,
  onValidateName,
  onUpdateDescription,
}: StyleOverviewInputsProps) => {
  const isEditable = !isNil(data) && isNil(data[STYLE_SUBMITTED_ATTR]);

  const inputs = !renderSections ? (
    <Fragment>
      <InputGeneric
        editable={false}
        autoFocus={false}
        value={previewData.title || previewData.id}
        valid={true}
        dataQa="StyleOverviewInputsPrimary"
      />
      <InputGeneric
        editable={false}
        autoFocus={false}
        value={previewData.description}
        valid={true}
        dataQa="StyleOverviewInputsSecondary"
      />
    </Fragment>
  ) : (
    <Fragment>
      <InputGeneric
        dataQa="StyleOverviewInputsPrimary"
        editable={isEditable}
        autoFocus={false}
        value={!isNil(data) && !isNil(config) ? data[config.header.primary.dataIndex] : previewData.id}
        valid={validStyleName}
        onBlur={onValidateName}
        whitelist={getWhitelistType(config?.header.primary.inputParams.whitelist || '')}
        maxLength={config?.header.primary.inputParams.maxLength}
      />
      <InputGeneric
        dataQa="StyleOverviewInputsSecondary"
        editable={isEditable}
        autoFocus={false}
        value={!isNil(data) && !isNil(config) ? data[config.header.secondary.dataIndex] : previewData.id}
        valid={true}
        onBlur={onUpdateDescription}
        whitelist={getWhitelistType(config?.header.secondary.inputParams.whitelist || '')}
        maxLength={config?.header.secondary.inputParams.maxLength}
      />
    </Fragment>
  );

  if (ignoreOverviewBackground) {
    return inputs;
  }

  return (
    <Fragment>
      <div className={styleEditStyles.styleEditOverviewBackground} />
      <div className={styleEditStyles.buildStyleEditOverview(validStyleName)}>{inputs}</div>
    </Fragment>
  );
};

export function getWorklistStatus(id: string, worklistItems: WorklistInfo[]) {
  const foundItem = worklistItems.find((item) => item.product === id);

  if (isNil(foundItem)) {
    return 'Not in Worklist';
  }

  return worklistStatusMap[foundItem.type] || 'Unknown';
}
