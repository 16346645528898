import { connect, Dispatch } from 'react-redux';
import HindsightingPeriodForm from './HindsightingPeriodForm';
import { AppState } from 'src/store';
import { changeScopeSelection, clearFloorsetAndQuickSelect } from '../ScopeSelector.actions';
import { BasicItem, DaysRangeListResponse, SelectionErrors } from 'src/types/Scope';

export type StateProps = {
  selectionErrors: SelectionErrors;
  selectedStartWeek: string | null;
  selectedEndWeek: string | null;
  rangeList: BasicItem[];
  daysRangeList: DaysRangeListResponse;
};

export type DispatchProps = {
  onChangeStartWeek(historyStart: string): void;
  onChangeEndWeek(historyEnd: string): void;
};

function mapStateToProps(state: AppState): StateProps {
  const { scope } = state;
  const { selections, selectionErrors } = scope;
  return {
    selectionErrors,
    selectedStartWeek: selections.historyStart,
    selectedEndWeek: selections.historyEnd,
    rangeList: scope.pastRangeList,
    daysRangeList: scope.daysPastRangeList,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AppState>): DispatchProps {
  return {
    onChangeStartWeek(historyStart: string) {
      dispatch(changeScopeSelection({ historyStart }));
      dispatch(clearFloorsetAndQuickSelect());
    },
    onChangeEndWeek(historyEnd: string) {
      dispatch(changeScopeSelection({ historyEnd }));
      dispatch(clearFloorsetAndQuickSelect());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HindsightingPeriodForm);
