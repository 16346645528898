import { style } from 'typestyle';
export const vvCheckboxStyles = 'vv-cell-checkbox';
export const styles = style({
  $debugName: 'valid-values-checkbox-renderer',
  display: 'flex',
  flexDirection: 'row',
  $nest: {
    '.ag-header-cell-text': {
      lineHeight: '36px',
      width: '42px',
      margin: 'auto',
      justifyContent: 'center',
      textAlign: 'center',
    },
    // TODO: This doesn't actually work...I have no idea why :/
    '.ag-header-cell-text:not(:last-child)': {
      borderRight: '1px black',
    },
    [`.${vvCheckboxStyles}`]: {
      width: '42px',
      justifyContent: 'center',
    },
  },
});
