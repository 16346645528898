import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import VisualizeSlice from './Visualize.slice';
import { Metric } from 'src/common-ui/components/Metrics/CommonTypes';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { STYLE_COLOR_ID } from 'src/utils/Domain/Constants';
import { macroConfigItemToMetric } from '../MacroGridPair/MacroGridPair.selectors';
import { WrapperOwnProps } from './Visualize.container';
import { Scope } from 'src/types/Scope';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem, Pivot, WorklistInfo } from 'src/worker/pivotWorker.types';
import { StylePreviewData } from '../StylePreview/StylePreview.types';
import { sharedDataLens } from 'src/services/lenses/lenses';

type StateSelection = ReturnType<typeof VisualizeSlice> &
  WrapperOwnProps & {
    scopeStart: Scope['start'];
    worklistItems?: WorklistInfo[];
  };

export type VisualizeStateProjection = {
  loading?: boolean;
  data?: Pivot;
  scopeStart: Scope['start'];
  selectedItemPreviewData: StylePreviewData;
  subheaderSlice: SubheaderSlice;
  allStylecolorIds: string[];
  onSelectStyle: (id: string) => void;
  summaryData: Metric[] | undefined;
  graphsViewDefn: TenantConfigViewData | undefined;
  primaryGraphData: number[][][] | undefined;
  primaryGraphCategories: string[] | undefined;
  worklistItems?: WorklistInfo[];
  dontFilterSwatches?: boolean;
};

export function selectState(state: AppState, ownProps: WrapperOwnProps): StateSelection {
  // be wary, the spreads here will create new objects and break object reference links
  const scopeStart = state.scope.scope.start;
  const worklistItems = sharedDataLens.get(state).worklist;

  return {
    scopeStart,
    worklistItems,
    ...state.pages.assortmentBuild.visualize,
    ...ownProps,
  };
}

const selectVisualizeData = (state: AppState) => {
  // TODO: this doesn't actually memoize correctly
  const { vizViewDefns, data } = state.pages.assortmentBuild.visualize;
  const primaryGraphViewDefn = vizViewDefns?.graphs?.graphs?.primary;

  const primaryGraphCategories = data ? data.tree.map((x) => x.week) : undefined;
  const primaryGraphData =
    data && primaryGraphViewDefn && primaryGraphCategories
      ? (primaryGraphViewDefn.map((v) =>
          ['left', 'right'].map((key) => {
            return data.tree.slice(0, primaryGraphCategories.length).map((d) => ({ ...d, y: d[v[key].dataIndex] }));
          })
        ) as BasicPivotItem[][][])
      : undefined;
  return {
    primaryGraphCategories,
    primaryGraphData,
  };
};

export default createSelector(selectState, selectVisualizeData, (stateSelection, visualizeData) => {
  const { vizViewDefns, summaryData, styles } = stateSelection;
  const graphsViewDefn = vizViewDefns?.graphs;
  // TODO: do this somewhere else
  let renderedSummaryData: Metric[] = [];
  if (summaryData && summaryData.length > 0 && vizViewDefns && vizViewDefns.summary) {
    renderedSummaryData = vizViewDefns.summary.view.map((macroConfigItem) => {
      return macroConfigItemToMetric(macroConfigItem, summaryData[0]);
    });
  }

  return {
    ...stateSelection,
    primaryGraphData: visualizeData.primaryGraphData,
    primaryGraphCategories: visualizeData.primaryGraphCategories,
    graphsViewDefn,
    allStylecolorIds: styles.map((x) => x[STYLE_COLOR_ID]),
    summaryData: renderedSummaryData,
  };
});
