import * as React from 'react';
import { classes } from 'typestyle';
import { Overlay } from 'src/common-ui';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import MacroSummary, {
  Props as MacroSummaryProps,
} from 'src/common-ui/components/Metrics/SimpleMetrics/MacroSummary/MacroSummary';
import {
  DataGridProps,
  ScrollTo as GridScrollTo,
  DefaultShownValues,
} from 'src/common-ui/components/DataGrid/DataGrid';
import Subheader from 'src/components/Subheader/Subheader.container';
import { PassedProps as SubheaderProps } from 'src/components/Subheader/Subheader';
import { ColDef, CellClassParams, GridApi, GridReadyEvent, ColumnApi } from 'ag-grid-community';

import ConfigureModal, { ConfigureModalProps, OptionGroup, Option } from 'src/components/Configure/ConfigureModal';
import { GridItem, GroupHeaderKey } from 'src/utils/Component/AgGrid/AgDataFormat';
import { macroGridStyle, containerPrintMode, dataContainerStyle } from 'src/components/MacroGridPair/MacroGrid.styles';
import { FrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import * as Lodash from 'lodash';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { CustomNoRowsOverlay } from 'src/components/ConfigurableGrid/ConfigurableGrid';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { multiHeaderDecorate } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/NestedHeader';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { omitColDefMutations } from '../ListGridPair/ListGridPair';
import { ReactElement } from 'react';
import { ViewDataState } from 'src/types/Domain';

export type LoadingProjection = {
  title: string;
  loaded: false;
  viewDataState?: ViewDataState | ViewDataState[];
};

export type LoadedProjection = {
  title: string;
  loaded: true;
  viewDataState?: ViewDataState | ViewDataState[];
  defaultShownValues: DefaultShownValues;
  rowHeight?: number;
  groupByDefn?: string;
  gridData: GridItem[];
  colDefs: ColDef[];
  frameworkComponents: FrameworkComponents;
  stylePaneTriggerSet: Set<string>;
  macroSummaries: MacroSummaryProps[];
  treeColumnDefinition: ColDef | undefined;
  defaultConfigureSelections: Option[];
  configureOptionGroups?: OptionGroup[];
  configureInstructions?: string;
  minimumSelections?: number;
  configureSelections?: Option[];
  originalDefaultConfigureSelections?: Option[];
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
};

export type StateProjection = LoadedProjection | LoadingProjection;

export type Props = StateProjection &
  PrintProps & {
    title?: string;
    showFlowStatus?: boolean;
    subheaderErrorText?: string;
    showConfigure?: boolean;
    fullHeight?: boolean;
    onShowView(): void;
    onConfigAggregate?(aggBys: string[]): void;
    onUpdate?(): void;
    onConfigUpdate(config: TenantConfigViewData): void;
    updateConfigureSelections?(selections: Option[]): void;
  };

export type State = {
  gridScrollTo?: GridScrollTo;
  isMacroCollapsed: boolean;
  configureIsOpen: boolean;
  configureLastSelected?: Option[];
};

export default class MacroGridPair extends React.Component<Props, State> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  filters: ColumnState[] | undefined;
  constructor(props: Props) {
    super(props);

    this.state = {
      isMacroCollapsed: false,
      configureIsOpen: false,
    };

    this.toggleSummary = this.toggleSummary.bind(this);
  }

  toggleSummary() {
    this.setState({
      isMacroCollapsed: !this.state.isMacroCollapsed,
    });
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.loaded && prevProps.loaded) {
      const prevColDefs = omitColDefMutations(prevProps.colDefs);
      const nextColDefs = omitColDefMutations(this.props.colDefs);
      if (
        !Lodash.isEqual(nextColDefs, prevColDefs) &&
        Lodash.isEqual(prevProps.configureSelections, this.props.configureSelections)
      ) {
        this.updateColDefs();
      }
    }
    if (this.filters) {
      this.columnApi.setColumnState(this.filters);
    }
  }

  updateColDefs() {
    if (this.props.loaded !== true || this.gridApi == null) return;
    this.gridApi.setColumnDefs(multiHeaderDecorate(this.props.colDefs));
  }

  updateConfigureSelections = (selections: Option[]) => {
    if (this.props.updateConfigureSelections != null) {
      this.props.updateConfigureSelections(selections);
    }
  };

  // TODO: this is currently not called in NestedAttribute on configure selections apply
  applyConfigureSelections = (selections: Option[]) => {
    if (this.props.updateConfigureSelections != null) {
      this.props.updateConfigureSelections(selections);
    }
    if (this.props.onConfigAggregate) {
      const aggBys = selections.map((sel) => sel.dataIndex);
      this.props.onConfigAggregate(aggBys);
    }
    this.setState(
      {
        configureLastSelected: selections,
      },
      () => this.updateColDefs() // this is where the coldefs are refreshed
    );
  };

  onConfigUpdate = (config: TenantConfigViewData) => {
    this.props.onConfigUpdate(config);
  };

  render() {
    let mainContent: ReactElement | null = null;
    const subheaderProps: SubheaderProps = {
      title: this.props.title,
      showFlowStatus: !Lodash.isNil(this.props.showFlowStatus) ? this.props.showFlowStatus : true,
      showSearch: true,
      errorCondition: this.props.subheaderErrorText,
      viewDataState: this.props.viewDataState,
    };
    let containerClasses = macroGridStyle;
    if (this.props.loaded) {
      const {
        loaded,
        gridData,
        colDefs,
        rowHeight,
        macroSummaries,
        frameworkComponents,
        treeColumnDefinition,
        defaultConfigureSelections = [],
        configureOptionGroups,
        configureInstructions,
        minimumSelections,
        onConfigAggregate,
        defaultShownValues,
        isPrintMode,
        showConfigure,
        viewDefn,
        unmodifiedViewDefn,
        originalDefaultConfigureSelections,
      } = this.props;
      const configureSelections =
        this.props.configureSelections != null ? this.props.configureSelections : defaultConfigureSelections;
      subheaderProps.viewConfigurator = {
        viewConfig: viewDefn,
        getColumnApi: () => {
          if (this.columnApi) {
            return this.columnApi;
          }
          return;
        },
        unmodifiedViewDefn,
        configurationSelections: configureSelections,
        defaultConfigurationSelections: originalDefaultConfigureSelections,
        updateConfig: this.onConfigUpdate,
        updateConfiguration: this.applyConfigureSelections,
        isGrid: true,
      };
      const { isMacroCollapsed, configureIsOpen, configureLastSelected = configureSelections } = this.state;

      const treeCol = treeColumnDefinition;
      if (treeCol) {
        treeCol.cellRendererParams = {
          suppressCount: true,
        };
        treeCol.valueGetter = undefined;
        treeCol.valueFormatter = (params) => {
          const getChildrenCount = (child: BasicPivotItem) => child.children.reduce((i, c) => c.children.length + i, 0);
          const getCount = (node1: BasicPivotItem): number => {
            const childrenCount = getChildrenCount(node1);
            if (childrenCount > 0) {
              const childrenChildrenCount = node1.children.reduce((i, n) => getCount(n) + i, 0);
              if (childrenChildrenCount > 0) {
                return childrenChildrenCount;
              }
              return childrenCount;
            }
            return node1.children.length;
          };
          const value = params.data ? params.data[params.colDef!.field!].slice(0) : params.value;
          const count = params.data ? getCount(params.data) : 0;
          if (count > 0) {
            if (Lodash.isArray(value)) {
              value[value.length - 1] = `${value[value.length - 1]} (${count})`;
              return value;
            } else {
              return `${value} (${count})`;
            }
          }
          return value;
        };
      }
      const gridOptions: DataGridProps = {
        isPrintMode,
        defaultShownValues,
        columnDefs: colDefs,
        data: gridData,
        frameworkComponents,
        autoSizeOnReady: true,
        rowHeight,
        loaded,
        rowClassRules: {
          'header-row': (params: CellClassParams) => params.data[GroupHeaderKey],
        },
        exportOptions: {
          fileName: this.props.title,
        },
        treeColumnDefinition: treeCol,
        onGridReady: (event: GridReadyEvent) => {
          this.gridApi = event.api!;
          this.columnApi = event.columnApi!;
        },
        extraAgGridProps: {
          autoGroupColumnDef: {
            headerName: 'Group',
            width: 250,
            field: 'name',
            pinned: true,
            cellRendererParams: {
              checkbox: false,
            },
          },
        },
      };

      let configureModalProps: ConfigureModalProps;

      if (showConfigure && configureOptionGroups) {
        subheaderProps.configureOptions = {
          type: 'enabled',
          onConfigureClick: () => {
            this.setState({
              configureIsOpen: true,
            });
          },
        };

        configureModalProps = {
          enabled: true,
          isOpen: configureIsOpen,
          optionGroups: configureOptionGroups,
          selections: configureSelections,
          instructions: configureInstructions,
          minimumSelections: minimumSelections,
          onReset: () => {
            this.updateConfigureSelections(defaultConfigureSelections);
            this.setState({
              configureLastSelected: defaultConfigureSelections,
            });
          },
          onToggleModal: (action) => {
            const nextState: Partial<State> = {
              configureIsOpen: !configureIsOpen,
            };

            switch (action) {
              case 'apply': {
                if (onConfigAggregate) {
                  const aggBys = configureSelections.map((sel) => sel.dataIndex);
                  onConfigAggregate(aggBys);
                }
                nextState.configureLastSelected = configureSelections;
                break;
              }
              default:
                this.updateConfigureSelections(configureLastSelected);
            }

            this.setState(nextState as any);
          },
          selectionUpdate: this.updateConfigureSelections,
        };
      } else {
        configureModalProps = { enabled: false };
      }

      let macroClasses = 'macro-summaries';
      let gridContainerClasses = 'grid-container';
      let expanderClass = 'far fa-fw macro-expander';

      if (isMacroCollapsed) {
        macroClasses += ' collapsed';
        gridContainerClasses += ' expanded';
        expanderClass += ' fa-chevron-down';
      } else {
        expanderClass += ' fa-chevron-up';
      }

      mainContent = (
        <React.Fragment>
          <div className={dataContainerStyle}>
            <div className="macro-summary-container" hidden={macroSummaries.length <= 0}>
              <div className="expander" onClick={this.toggleSummary}>
                <i className={expanderClass} />
                Summary
              </div>
              <div className={macroClasses}>
                {macroSummaries.map((macroSummary, index) => {
                  const options = {
                    ...macroSummary,
                    className: macroSummary.className,
                  };
                  return <MacroSummary key={index} {...options} />;
                })}
              </div>
            </div>
            <div className="expander">Details</div>
            <div className={gridContainerClasses} style={this.props.fullHeight ? { height: '100%' } : undefined}>
              {Lodash.isEmpty(gridData) ? <CustomNoRowsOverlay /> : <ExtendedDataGrid {...gridOptions} />}
            </div>
          </div>
          <ConfigureModal {...configureModalProps} />
        </React.Fragment>
      );
      if (isPrintMode) {
        containerClasses = classes(containerClasses, containerPrintMode);
      }
    }
    return (
      <div className={containerClasses}>
        <Overlay type="loading" visible={!this.props.loaded} />
        <Subheader {...subheaderProps} />
        {mainContent}
      </div>
    );
  }
}
