import { PerspectiveBinding } from './bindings.types';
import { TOP_DOWN_PERSPECTIVE, BOTTOM_UP_PERSPECTIVE } from 'src/utils/Domain/Constants';

export const perspectiveBindings: { [k: string]: PerspectiveBinding } = {
  [TOP_DOWN_PERSPECTIVE]: {
    display: 'Top Down Assortment',
    pathSlot: 'top-down',
    value: 'TDAnalysis',
    iconSrc: 'fal fa-stack-1x fa-chart-line',
  },
  [BOTTOM_UP_PERSPECTIVE]: {
    display: 'Bottom Up Assortment',
    pathSlot: 'bottom-up',
    value: 'Assortment',
    iconSrc: 'fal fa-stack-1x fa-shopping-cart',
  },
};
