import React from 'react';
import { componentToName } from 'src/utils/React/Component';
import { isNil } from 'lodash';
import { setRightContainerPayload } from '../RightContainer/RightContainer.slice';
import { connect } from 'react-redux';
import { wrappedDispatch, WrappedDispatch } from 'src/utils/Redux/Dispatch';
import { AppState } from 'src/store';
import { STYLE_ID, HINDSIGHTING_PATH } from 'src/utils/Domain/Constants';
import { BasicPivotItem, BasicItem } from 'src/worker/pivotWorker.types';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';

export interface StyleDetailsPopoverProps extends Object {
  onItemClicked?: (item: Record<string, any>, eventTarget?: HTMLElement) => void;
  showPopover?: boolean;
  activeTab?: string;
}

type HocProps = {
  showDataInRightContainer: (id: string, parentId: string, activeTab: string | undefined) => void;
};

type PopoverState = {
  popoverData?: {
    target: HTMLElement;
    data?: BasicItem[];
    isLoading?: boolean;
  };
  popoverOpen?: boolean;
};

export function makePopoverSensitive<P extends StyleDetailsPopoverProps>(WrappedComponent: React.ComponentType<P>) {
  function mergeProps(_state: AppState, wrapped: WrappedDispatch, parentProps: P) {
    const props: HocProps = {
      showDataInRightContainer: (id: string, parentId: string, activeTab?: string) => {
        if (activeTab && IS_HINDSIGHTING(activeTab)) {
          wrapped.dispatch(
            setRightContainerPayload({
              type: 'HistoryStylePane',
              id,
              parentId,
            })
          );
        } else {
          wrapped.dispatch(
            setRightContainerPayload({
              type: 'StyleDetails',
              id,
              parentId,
              isAssortmentBuild: true,
            })
          );
        }
      },
    };
    return {
      ...parentProps,
      ...props,
    };
  }

  class PopoverSensitive extends React.Component<StyleDetailsPopoverProps & P & HocProps, PopoverState> {
    displayName = `PopoverSensitive(${componentToName(WrappedComponent)})`;
    constructor(props: StyleDetailsPopoverProps & P & HocProps) {
      super(props);
    }
    handleItemClickEvent = (item: BasicPivotItem, eventTarget?: HTMLElement, activeTab?: string) => {
      const { showPopover } = this.props;

      if (isNil(item.id)) {
        return;
      }

      if (showPopover) {
        const parentId = item.parentId || item[STYLE_ID] || item.id;
        if (item.type && item.type === 'HistoryStylePane') {
          activeTab = HINDSIGHTING_PATH;
        }
        this.props.showDataInRightContainer(item.id, parentId, activeTab);
      }

      if (this.props.onItemClicked && !this.props.showPopover) {
        this.props.onItemClicked(item, eventTarget);
      }
    };

    render() {
      return <WrappedComponent {...this.props} onItemClicked={this.handleItemClickEvent} />;
    }
  }

  return connect(null, wrappedDispatch, mergeProps)(PopoverSensitive);
}
