import { AxiosRequestConfig } from 'axios';
import { API_URL_PREFIX } from 'src/utils/Domain/Constants';

export function prefixRequestInterceptor(request: AxiosRequestConfig) {
  const config = request;
  if (request.url) {
    if (request.url.charAt(0) === '/') {
      config.url = `${API_URL_PREFIX}${request.url}`;
    } else {
      config.url = `${API_URL_PREFIX}/${request.url}`;
    }
  }
  return config;
}
