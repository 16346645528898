import { BasicPivotItem, ListDataOptions } from 'src/worker/pivotWorker.types';
import { AssortmentPublishViewDefns } from './AssortmentPublish.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { FavoriteListItemStorage, FavoriteResponseItem } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunkDispatch } from 'src/store';
import container from 'src/ServiceContainer';
import { AnyAction as BaseAction } from 'redux';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { DefnProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { getAssortmentPublishData } from './AssortmentPublish.container';

export type AssortmentPublishSlice = {
  configsLoading: boolean;
  viewDefns: AssortmentPublishViewDefns;
  selectedItemsData: BasicPivotItem[];
  favoritesList: FavoriteResponseItem[];
  viewDataState: ViewDataState;
  cacheHash: string | null;
};

const initialState: AssortmentPublishSlice = {
  configsLoading: true,
  selectedItemsData: [],
  viewDefns: {
    grid: {} as TenantConfigViewData,
  },
  favoritesList: [],
  viewDataState: ViewDataState.idle,
  cacheHash: null,
};

const assortmentPublishReducer = createSlice({
  name: 'AssortmentPublish',
  initialState,
  reducers: {
    requestAssortmentPublishConfig(state) {
      state.configsLoading = true;
    },
    receivedAssortmentPublishConfig(state, action: PayloadAction<AssortmentPublishViewDefns>) {
      state.configsLoading = false;
      state.viewDefns = {
        ...state.viewDefns,
        ...action.payload,
      };
    },
    updateSelections(state, action: PayloadAction<BasicPivotItem[]>) {
      state.selectedItemsData = [...action.payload];
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    assortmentPublishCleanup() {
      return initialState;
    },
  },
});

export const {
  requestAssortmentPublishConfig,
  receivedAssortmentPublishConfig,
  updateSelections,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  assortmentPublishCleanup,
} = assortmentPublishReducer.actions;

export function fetchAssortmentPublishData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    container.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData,
    false
  );
}

export function fetchConfigs(defns: DefnProps) {
  return (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    dispatch(requestAssortmentPublishConfig());
    return container.tenantConfigClient
      .getTenantViewDefnsWithFavorites({
        defnIds: defns.view,
        appName: ASSORTMENT,
      })
      .then((resp) => {
        const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
          defns.view[0],
          (resp as any)[defns.view.length],
          dispatch
        );
        resp[defns.view.length] = resp[0];
        if (localConfig && localConfig.config) {
          resp[0] = localConfig.config;
        }
        return resp;
      })
      .then((resp) => {
        dispatch(getAssortmentPublishData(defns));
        dispatch(
          receivedAssortmentPublishConfig({
            grid: resp[0],
            groupBy: resp[1],
            rollup: resp[2],
            unmodifiedViewDefn: resp[3],
          })
        );
      })
      .catch(() => console.error('Error retrieving configs'));
  };
}

export default assortmentPublishReducer.reducer;
