import * as React from 'react';
import Axios from 'src/services/axios';
import { isEmpty, forEach, isArray, isNil } from 'lodash';
import CardActions from '@material-ui/core/CardActions';

import { ViewApiConfig } from '../../StyleEdit.types';
import { styles, MUIStyles } from './LifecycleParametersEditor.styles';
import Lifecycle from 'src/pages/AssortmentBuild/LinePlan/Lifecycle/Lifecycle';
import StoreEligibility, { StoreData } from 'src/pages/AssortmentBuild/LinePlan/StoreEligibility/StoreEligibility';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';

import { ICellEditorParams } from 'ag-grid-community';
import { SPECIAL_STORE_GROUP } from 'src/utils/Domain/Constants';
import { classes } from 'typestyle';
import {
  MultiRangeEditors,
  StyleEditConfigColumn,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import { LifecycleData } from 'src/components/LifecycleStoreModal/LifecycleStoreModal';
import { Overlay } from 'src/common-ui/index';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';

type DependentsData = {
  [key: string]: string[];
};

// tslint:disable: no-any
type PlanningColumn = any;
type WeekRangeColumn = any;
// tslint:enable: no-any

type LifecycleConfig = {
  text: string;
  multiRangeEditors: MultiRangeEditors;
  planningColumns: PlanningColumn[];
  weekRangeColumns: WeekRangeColumn[];
};

export type LifecycleParametersProps = {
  dataApiLifecycle: ViewApiConfig;
  dataApiStore: ViewApiConfig;
  lifecycleConfig: ViewApiConfig;
  storeConfig: ViewApiConfig;
  dependentsApi: ViewApiConfig;
  floorset: string;
  product: string;
  headerSubtext: string;
} & ICellEditorParams;

export type LifecycleParametersState = {
  activeTabIndex: number;
  lifecycleConfig: LifecycleConfig | null;
  lifecycleData: LifecycleData | null;
  savedLifecycleData: LifecycleData | null;
  storeData: StoreData | null;
  storeConfig: StyleEditConfigColumn[];
  dependentsData: DependentsData;
  saved: boolean;
  overlayWidth: number;
  overlayHeight: number;
  loading: boolean;
};

class LifecycleParametersEditor extends React.Component<LifecycleParametersProps, LifecycleParametersState> {
  lifecycleRef: React.RefObject<Lifecycle> = React.createRef();
  storeRef: React.RefObject<StoreEligibility> = React.createRef();
  constructor(props: LifecycleParametersProps) {
    super(props);
    this.state = {
      lifecycleConfig: null,
      activeTabIndex: 0,
      lifecycleData: null,
      savedLifecycleData: null,
      storeData: {},
      storeConfig: [],
      dependentsData: {},
      saved: false,
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
      loading: true,
    };

    window.addEventListener('resize', this.resizeOverlay.bind(this));
  }

  resizeOverlay() {
    this.setState({
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    });
  }

  fetchLifecycleData = async () => {
    const { dataApiLifecycle } = this.props;
    const lifecycleData = (
      await Axios.get(dataApiLifecycle.url, {
        params: {
          ...dataApiLifecycle.params,
        },
      })
    ).data.data;
    this.setState({
      lifecycleData,
    });
  };

  componentDidMount() {
    const { dataApiLifecycle, dataApiStore, lifecycleConfig, storeConfig, dependentsApi } = this.props;

    const lifecycleConfigGet = Axios.get(lifecycleConfig.url, {
      params: { ...lifecycleConfig.params },
    });
    const lifecycleData = Axios.get(dataApiLifecycle.url, {
      params: {
        ...dataApiLifecycle.params,
      },
    });
    const storeData = Axios.get(dataApiStore.url, {
      params: {
        ...dataApiStore.params,
      },
    });
    const storeConfigGet = Axios.get(storeConfig.url, {
      params: { ...storeConfig.params },
    });
    const dependentsData = Axios.get(dependentsApi.url);

    const reqs = [lifecycleConfigGet, lifecycleData, storeData, dependentsData, storeConfigGet];

    Promise.all(reqs).then((responses) => {
      const respData = responses[2].data.data;

      // fix ssg data if necessary, replace 'empty' [""] with real empty []
      forEach(respData, (value) => {
        forEach(value, (data) => {
          const ssgValue = data[SPECIAL_STORE_GROUP];
          if (isArray(ssgValue) && !isEmpty(ssgValue) && ssgValue[0] === '') {
            data[SPECIAL_STORE_GROUP] = [];
          }
        });
      });

      this.setState({
        lifecycleConfig: maybeReturnNestData(responses[0].data),
        lifecycleData: responses[1].data.data,
        storeData: respData,
        dependentsData: responses[3].data.data,
        storeConfig: maybeReturnNestData(responses[4].data),
        loading: false,
      });
    });
  }

  onClickTab = async (index: number) => {
    const { dataApiStore } = this.props;
    const { activeTabIndex } = this.state;

    if (index !== activeTabIndex) {
      this.setState({
        loading: true,
      });

      if (index === 1) {
        // case for grid actually loaded into screen
        if (
          this.lifecycleRef.current != null &&
          this.lifecycleRef.current.getLifecyclePostPromise != null &&
          this.lifecycleRef.current.gridApi != null
        ) {
          this.lifecycleRef.current.getLifecyclePostPromise().then(() => {
            Axios.get(dataApiStore.url, {
              params: {
                ...dataApiStore.params,
              },
            }).then((resp2) => {
              const storeData = resp2.data.data;
              let modifiedData = storeData;
              const storeDataKeys = Object.keys(modifiedData);
              const keyToModify = !isEmpty(storeDataKeys) ? storeDataKeys[0] : null;

              if (!isNil(keyToModify)) {
                // setup data to disable initial funded floorset
                const existingData = [...modifiedData[keyToModify]];

                modifiedData = {
                  ...modifiedData,
                  [keyToModify]: existingData,
                };
              }

              this.setState({
                activeTabIndex: index,
                storeData: modifiedData,
                loading: false,
              });
            });
          });
        } else {
          // If grid not actually loaded, no way to edit data, just pass through.
          this.setState({
            activeTabIndex: index,
            loading: false,
          });
        }
      } else if (index === 0) {
        await this.fetchLifecycleData();
        this.setState({
          activeTabIndex: index,
          loading: false,
        });
      }
    }
  };

  isPopup = () => {
    return true;
  };

  getValue = () => {
    window.removeEventListener('resize', this.resizeOverlay);

    const { saved, storeData, savedLifecycleData } = this.state;
    if (saved) {
      const lifecycleData: LifecycleData | null = this.lifecycleRef.current
        ? this.lifecycleRef.current.getLifecycleData()
        : savedLifecycleData;

      return {
        lifecycleData,
        storeData,
      };
    }

    return this.props.value;
  };

  getDataStore = (storeData: any) => {
    this.setState({
      storeData: storeData as StoreData,
    });
  };

  submit = () => {
    // TECHDEBT: Added single cycle wait to submit. This allows active field blur events to fire.
    setTimeout(() => {
      this.setState({
        loading: true,
      });

      // Save lifecycle
      if (
        this.state.activeTabIndex === 0 &&
        !isNil(this.lifecycleRef.current) &&
        this.lifecycleRef.current.postLifecycleData
      ) {
        this.lifecycleRef.current.postLifecycleData();

        // store lifecycle data before component is unmounted
        this.setState({
          savedLifecycleData: this.lifecycleRef.current.getLifecycleData(),
        });
      }

      // Save store data
      if (this.storeRef.current) {
        const updatedAttrs = this.storeRef.current.saveAfterEdit();
        const storeData = {
          product: this.props.product,
          attributes: updatedAttrs,
        };
        Axios.post(this.props.dataApiStore.url, storeData, {
          params: {
            appName: 'Assortment',
          },
        });
      }

      this.setState(
        {
          saved: true,
        },
        () => {
          this.props.stopEditing();
        }
      );
    });
  };

  cancel = () => {
    this.props.stopEditing();
  };

  render() {
    const { dataApiLifecycle, floorset, product } = this.props;
    const {
      activeTabIndex,
      lifecycleConfig,
      lifecycleData,
      storeData,
      dependentsData,
      storeConfig,
      loading,
    } = this.state;

    let content = <div />;
    if (lifecycleData && !isEmpty(lifecycleData)) {
      content = activeTabIndex ? (
        <StoreEligibility
          data={storeData}
          config={storeConfig}
          floorset={floorset}
          getDataStore={this.getDataStore}
          product={product}
          ref={this.storeRef}
        />
      ) : (
        <Lifecycle
          weekRangeData={lifecycleData}
          weekRangeConfig={!isNil(lifecycleConfig) ? lifecycleConfig.weekRangeColumns : null}
          weekRangeFieldEditConfig={!isNil(lifecycleConfig) ? lifecycleConfig.multiRangeEditors : null}
          planningConfig={!isNil(lifecycleConfig) ? lifecycleConfig.planningColumns : null}
          dependentsData={dependentsData}
          product={dataApiLifecycle.params!.product}
          ref={this.lifecycleRef}
        />
      );
    }

    return (
      <MuiThemeProvider theme={muiTheme}>
        {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
        <div className={styles.overlay} style={{ width: this.state.overlayWidth, height: this.state.overlayHeight }}>
          <div data-qa="ag-popover" className={classes(styles.modalContainer, 'ag-theme-material')}>
            <div className={styles.cardHeader}>Style Color Parameters</div>
            {loading && <Overlay type="loading" visible={true} fitParent={true} />}
            <React.Fragment>
              <div className={styles.container}>
                <div className={styles.tabContainer}>
                  {['Lifecycle and Planning', 'Store Ranging'].map((name, index) => {
                    const tabClass = activeTabIndex === index ? styles.tabButtonActive : styles.tabButton;
                    return (
                      <button className={tabClass} onClick={() => this.onClickTab(index)} key={name}>
                        {name}
                      </button>
                    );
                  })}
                </div>
                <div className={styles.receiptContainer}>
                  <div className={styles.subheaderTextContainer}>
                    <span>{this.props.headerSubtext}</span>
                  </div>
                  <div className={styles.contentContainer}>{content}</div>
                </div>
              </div>
              <div className={styles.actionsContainer}>
                <CardActions classes={MUIStyles.cardActions}>
                  <AcceptButton onClick={() => this.submit()} />
                </CardActions>
              </div>
            </React.Fragment>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default LifecycleParametersEditor;
