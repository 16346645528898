import Axios from 'axios';
import { authorizedInterceptor, unauthorizedErrorInterceptor } from 'src/services/axios/authState';
import { serverSuccessInterceptor, serverErrorInterceptor } from 'src/services/axios/serverError';
import { prefixRequestInterceptor } from 'src/services/axios/prefixRequests';

const api = Axios.create({});
api.interceptors.response.use(serverSuccessInterceptor, serverErrorInterceptor);
api.interceptors.response.use(authorizedInterceptor, unauthorizedErrorInterceptor);
api.interceptors.request.use(prefixRequestInterceptor);

export default api;
