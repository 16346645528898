import * as React from 'react';
import moment from 'moment';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
  GridApi,
  GridReadyEvent,
  RowNode,
  CellClickedEvent,
  CellEditingStoppedEvent,
  BodyScrollEvent,
  CellEditingStartedEvent,
  SideBarDef,
  ColGroupDef,
  ProcessCellForExportParams,
  ExcelNumberFormat,
  CsvExportParams,
  ExcelExportParams,
  ExcelCell,
  ColDef,
  ColumnResizedEvent,
  NewColumnsLoadedEvent,
  DisplayedColumnsChangedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  ColumnApi,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

import { ExportableGrid } from './DataGrid.interfaces';
import { KeyVal } from '../../common/object';
import { Renderer } from './Renderer';
import { multiHeaderDecorate } from './NestedHeader';
import styles from './DataGrid.styles';
import { isNil, isNumber, isEqual } from 'lodash';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';

LicenseManager.setLicenseKey(
  'S5_Stratos_MultiApp_2Devs11_July_2019__MTU2Mjc5OTYwMDAwMA==82681080271234c6108c212ac1a9f2c7'
);

export type GetDataPath = ((x: any) => any) | undefined;

export type ScrollTo = {
  eventId: number;
  where: KeyVal<string, any>;
};

export type Gridable = {
  id: string;
  [key: string]: any;
};

export type RendererExcelFormat = {
  dataType: string;
  numberFormat: string | ExcelNumberFormat;
  id?: string;
};

export type RendererExcelFormatObject = {
  [s: string]: RendererExcelFormat;
};

export type DefaultShownValues = { [dataIndex: string]: string[] };

export type ExportOptions = {
  customHeader?: string;
  excelRendererObj?: RendererExcelFormatObject;
  fileName?: string;
  processCellOverride?: (params: ProcessCellForExportParams) => string | undefined;
};

export type DataGridProps = {
  data: Gridable[] | any[];
  columnDefs: (ColDef | ColGroupDef)[];
  treeColumnDefinition?: ColDef | undefined;
  className?: string;
  rowHeight?: number;
  autoSizeOnReady?: boolean;
  isPrintMode?: boolean;
  frameworkComponents?: any;
  nonFrameworkComponents?: any;
  loaded: boolean;
  scrollTo?: ScrollTo;
  rowClassRules?: any;
  defaultShownValues?: DefaultShownValues;
  singleClickEdit?: boolean;
  sideBar?: SideBarDef | boolean | string;
  exportOptions?: ExportOptions;
  extraAgGridProps?: AgGridReactProps;
  onCellClicked?(event: CellClickedEvent): void;
  onGridReady?(event: GridReadyEvent): void;
  onNewColumnsLoaded?(event: NewColumnsLoadedEvent): void;
  onDisplayedColumnsChanged?(event: DisplayedColumnsChangedEvent): void;
  onCellEditingStopped?(event: CellEditingStoppedEvent): void;
  onCellEditingStarted?(event: CellEditingStartedEvent): void;
  onBodyScroll?(event: BodyScrollEvent): void;
  getAlternateContextMenu?: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[];
};

export type NoRenderState = {
  gridApi?: GridApi;
  columnApi?: ColumnApi;
  lastScrolledTo?: number;
  defaultShownValues?: DefaultShownValues;
  // used for rendering an alternate context menu on left click
  leftOrRightClick: 'left' | 'right';
};

export default class DataGrid extends React.Component<DataGridProps> implements ExportableGrid {
  private noRenderState: NoRenderState;

  constructor(props: DataGridProps) {
    super(props);

    this.noRenderState = {
      defaultShownValues: props.defaultShownValues,
      leftOrRightClick: 'right',
    };

    this.onGridReady = this.onGridReady.bind(this);
    this.onCsvExport = this.onCsvExport.bind(this);
    this.onExcelExport = this.onExcelExport.bind(this);
  }

  componentDidUpdate(prevProps: DataGridProps) {
    if (this.noRenderState.gridApi) {
      if (this.props.isPrintMode) {
        this.noRenderState.gridApi.setDomLayout('print');
      } else if (prevProps.isPrintMode && !this.props.isPrintMode) {
        this.noRenderState.gridApi.setDomLayout('normal');
      }
    }
    if (!isEqual(prevProps.data, this.props.data) && this.noRenderState.gridApi) {
      this.noRenderState.gridApi.setRowData(this.props.data);
    }
  }

  onExcelExport() {
    const exportOptions = this.props.exportOptions;
    const processCellOverride = this.props.exportOptions && this.props.exportOptions.processCellOverride;
    let excelOptions: ExcelExportParams = {
      columnGroups: true,
      sheetName: 'S5 Assortment Export',
      processCellCallback: function(params: ProcessCellForExportParams) {
        const { column, value, node } = params;
        const colId = column.getColId();
        let cellRenderer = column.getColDef()['renderer'] || column.getColDef()['cellRenderer'];

        if (isNil(cellRenderer) && isNumber(value)) {
          cellRenderer = node ? node.data.formatter : undefined;
        }

        const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
        const renderFn = renderer ? Renderer[renderer] : undefined;
        const isColorColumn = colId.indexOf('band_color') >= 0;
        const isFirstColumn = colId === 'ag-Grid-AutoColumn';

        // Change HEX color codes to N/A.
        if (isColorColumn) {
          return 'N/A';
        }

        if (processCellOverride) {
          const possibleNewValue = processCellOverride(params);
          if (possibleNewValue) {
            return possibleNewValue;
          }
        }
        if (exportOptions && exportOptions.excelRendererObj && renderer !== '') {
          const excelFormat = exportOptions.excelRendererObj[renderer];
          if (excelFormat) {
            return value;
          }
        }
        return !isFirstColumn && renderFn ? renderFn(value) : value;
      },
    };

    if (this.props.exportOptions) {
      const { customHeader, fileName } = this.props.exportOptions;
      const header: ExcelCell[][] = new Array(1);
      const excelCell: ExcelCell = {
        data: {
          type: 'String',
          value: customHeader as string | null,
        },
        mergeAcross: 5,
      };
      header.push([excelCell]);
      excelOptions = {
        ...excelOptions,
        customHeader: customHeader ? header : undefined,
        fileName: fileName && fileName + ' at ' + moment().format('YYYY-MM-DD hh|mmA'),
      };
    }

    if (this.noRenderState.gridApi) {
      this.noRenderState.gridApi.exportDataAsExcel(excelOptions);
    }
  }

  onCsvExport() {
    const processCellOverride = this.props.exportOptions && this.props.exportOptions.processCellOverride;
    let csvOptions: CsvExportParams = {
      columnGroups: true,
      processCellCallback: function(params: ProcessCellForExportParams) {
        const { column, value } = params;
        const colId = column.getColId();
        const cellRenderer = column.getColDef()['renderer'] || column.getColDef()['cellRenderer'];
        const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
        const renderFn = renderer ? Renderer[renderer] : undefined;
        const isColorColumn = colId.indexOf('band_color') >= 0;

        // Change HEX color codes to N/A.
        if (isColorColumn) {
          return 'N/A';
        }

        if (processCellOverride) {
          const possibleNewValue = processCellOverride(params);
          if (possibleNewValue) {
            return possibleNewValue;
          }
        }
        return renderFn ? renderFn(value) : value;
      },
    };

    if (this.props.exportOptions) {
      const { customHeader, fileName } = this.props.exportOptions;
      csvOptions = {
        ...csvOptions,
        customHeader: customHeader && customHeader + '\n',
        fileName: fileName && fileName + ' at ' + moment().format('YYYY-MM-DD hh|mmA'),
      };
    }

    if (this.noRenderState.gridApi) {
      this.noRenderState.gridApi.exportDataAsCsv(csvOptions);
    }
  }

  onGridReady(event: GridReadyEvent) {
    if (event.api) {
      this.noRenderState.gridApi = event.api;
      this.noRenderState.columnApi = event.columnApi;
      const { defaultShownValues } = this.noRenderState;

      if (defaultShownValues) {
        event.api.setFilterModel(defaultShownValues);
      }

      if (this.props.isPrintMode) {
        event.api.setDomLayout('print');
      }

      if (this.props.onGridReady) {
        this.props.onGridReady(event);
      }
    }
  }

  onNewColumnsLoaded = (event: NewColumnsLoadedEvent) => {
    if (this.props.onNewColumnsLoaded) {
      this.props.onNewColumnsLoaded(event);
    }
  };

  onDisplayedColumnsChanged = (event: DisplayedColumnsChangedEvent) => {
    if (this.props.onDisplayedColumnsChanged) {
      this.props.onDisplayedColumnsChanged(event);
    }
  };

  injectColumnDef = (colDef: any) => {
    const { exportOptions } = this.props;
    if (colDef && exportOptions && exportOptions.excelRendererObj) {
      const cellClassFunction = (params: any, renderer: string) => {
        return (
          params.colDef.renderer === renderer ||
          params.colDef.cellRenderer === renderer ||
          (params.node.data && params.node.data.renderer === renderer) ||
          (params.node.data && params.node.data.formatter === renderer)
        );
      };
      const excelRendererArr: RendererExcelFormat[] = Object.keys(exportOptions.excelRendererObj).map((key) => {
        return {
          ...exportOptions!.excelRendererObj![key],
          numberFormat: {
            format: (exportOptions!.excelRendererObj![key] as any).numberFormat.replace(/"/g, '&quot;'),
          },
        };
      });
      const injectableClassRules = excelRendererArr.reduce((accumeObj, style) => {
        accumeObj[style.id!] = (params: any) => cellClassFunction(params, style.id!);
        return accumeObj;
      }, {});
      return { ...colDef, cellClassRules: { ...colDef.cellClassRules, ...injectableClassRules } };
    }
    return colDef;
  };

  decorateColDefsForExcel = (colDefs: (ColDef | ColGroupDef)[]) => {
    if (colDefs && this.props.exportOptions) {
      return colDefs.map((colDef: any) => {
        const returnObj = this.injectColumnDef(colDef);
        if (colDef.children && colDef.children.length > 0) {
          returnObj.children = colDef.children.map((childDef: any) => this.injectColumnDef(childDef));
        }
        return returnObj;
      });
    }
    return colDefs;
  };

  showWorklist = (event: CellClickedEvent) => {
    // there appears to be no public interface that can accomplish this, so we're using the private interface instead
    // we take the event details from the left click, and manually fire it at the right click event
    // Warning: this could break in future releases of ag-grid
    if (!this.noRenderState.gridApi) {
      return; // no clicky too quicky
    }
    // @ts-ignore
    this.noRenderState.gridApi.contextMenuFactory.showMenu(event.node, event.column, event.value, event.event);
  };

  public getContextMenuItems = (params: GetContextMenuItemsParams) => {
    // We 'jiggle' the context menu when we detect a left click from onCellClicked
    // in order to re-use ag-grid's context menu functionality for other purposes
    // I attempted to use onCellContextMenu and some other approaches,
    // but that fires after getContextMenuItems, and everything else was more work

    if (this.props.getAlternateContextMenu && this.noRenderState.leftOrRightClick === 'left') {
      this.noRenderState.leftOrRightClick = 'right'; // reset back to default, then complete the event
      return this.props.getAlternateContextMenu(params);
    }
    // this is the normal context menu, invoked from right click
    // TODO: fix the icons here
    return [
      'expandAll',
      'contractAll',
      'copy',
      'resetColumns',
      {
        name: 'CSV Export',
        action: this.onCsvExport,
      },
      {
        name: 'Excel Export',
        action: this.onExcelExport,
      },
    ];
  };

  render() {
    const {
      columnDefs,
      data,
      className,
      frameworkComponents,
      rowHeight,
      treeColumnDefinition,
      scrollTo,
      onCellClicked,
      onBodyScroll,
      rowClassRules,
      defaultShownValues,
      nonFrameworkComponents,
      onCellEditingStopped,
      onCellEditingStarted,
      sideBar,
      extraAgGridProps,
      exportOptions,
      getAlternateContextMenu,
    } = this.props;

    if (defaultShownValues) {
      this.noRenderState.defaultShownValues = defaultShownValues;
    }

    const { gridApi, lastScrolledTo, columnApi } = this.noRenderState;

    if (scrollTo !== undefined && gridApi && columnApi && scrollTo.eventId !== lastScrolledTo) {
      const setExpandedAll = (node: RowNode) => {
        if (node.parent) {
          node.setExpanded(true);
          setExpandedAll(node.parent);
        }
      };
      let foundIndex = -1;
      gridApi.ensureNodeVisible((node: RowNode) => {
        const firstCol = columnApi.getAllDisplayedColumns()[0];
        if (node.data && node.data[scrollTo.where.key] === scrollTo.where.value) {
          gridApi.setFocusedCell(node.rowIndex, firstCol);
          gridApi.ensureIndexVisible(node.rowIndex, 'top');
          return true;
        } else {
          if (node && node.allChildrenCount && node.allChildrenCount > 0) {
            foundIndex = -1;
            node.allLeafChildren.forEach((nod) => {
              if (nod.data && nod.data[scrollTo.where.key] === scrollTo.where.value) {
                setExpandedAll(nod);
                gridApi.setFocusedCell(nod.rowIndex, firstCol);
                foundIndex = nod.rowIndex;
              }
            });
            // Don't select more than one item
            if (foundIndex > -1) return true;
          }
        }
        return false;
      });
      // If a node that is a child of multiple parents is selected, use this to ensure scrolling works
      if (foundIndex > -1) {
        gridApi.ensureIndexVisible(foundIndex, 'top');
      }
      this.noRenderState.lastScrolledTo = scrollTo.eventId;
    }
    let colDefs = columnDefs;
    const decoratedFrameworkComponents = frameworkComponents;
    const isMultiHeader = columnDefs.filter(
      (colDef: ColGroupDef | ColDef) => 'children' in colDef && colDef.children.length
    ).length;

    if (isMultiHeader) {
      colDefs = multiHeaderDecorate(columnDefs as any);
    }

    const finalColDefs = this.decorateColDefsForExcel(colDefs);

    const baseGridOptions: AgGridReactProps = {
      sideBar: sideBar,
      columnDefs: finalColDefs,
      onGridReady: this.onGridReady,
      onNewColumnsLoaded: this.onNewColumnsLoaded,
      onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
      rowDataChangeDetectionStrategy: ChangeDetectionStrategyType.NoCheck,
      deltaColumnMode: true,
      suppressSetColumnStateEvents: true,
      rowHeight,
      headerHeight: rowHeight,
      animateRows: false,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
      },
      singleClickEdit: true,
      frameworkComponents: decoratedFrameworkComponents,
      components: nonFrameworkComponents,
      rowBuffer: 0,
      icons: {
        sortAscending: '<i class="fas fa-lg fa-sort-down"></i>',
        sortDescending: '<i class="fas fa-lg fa-sort-up"></i>',
        filter: '<i class="fas fa-filter"></i>',
      },
      getMainMenuItems() {
        return [
          'pinSubMenu', // Submenu for pinning. Always shown.
          'autoSizeThis', // Auto-size the current column. Always shown.
          'resetColumns', // Reset column details. Always shown.
          'expandAll', // Expand all groups. Only shown if grouping by at least one column.
          'contractAll', // Contract all groups. Only shown if grouping by at least one column.
        ];
      },
      getContextMenuItems: this.getContextMenuItems,
      onCellClicked: (event: CellClickedEvent) => {
        if (extraAgGridProps?.suppressRowClickSelection != true) {
          event.node.setSelected(true);
        }

        if (onCellClicked && !this.props.getAlternateContextMenu) {
          onCellClicked(event);
        } else if (onCellClicked && getAlternateContextMenu) {
          onCellClicked(event);
          this.noRenderState.leftOrRightClick = 'left';
          this.showWorklist(event);
        }
      },
      onCellEditingStopped: (event: CellEditingStoppedEvent) => {
        if (onCellEditingStopped) {
          onCellEditingStopped(event);
        }
      },
      onCellEditingStarted: (event: CellEditingStartedEvent) => {
        if (onCellEditingStarted) {
          onCellEditingStarted(event);
        }
      },
      onBodyScroll: (event: BodyScrollEvent) => {
        if (onBodyScroll) {
          onBodyScroll(event);
        }
      },
      onColumnResized: (event: ColumnResizedEvent) => {
        if (event.finished && event.api) {
          event.api.redrawRows();
        }
      },
      ...extraAgGridProps,
    };

    if (rowClassRules) {
      baseGridOptions.rowClassRules = rowClassRules;
    }

    if (baseGridOptions.defaultColDef) {
      baseGridOptions.defaultColDef = this.injectColumnDef(baseGridOptions.defaultColDef);
    }
    if (baseGridOptions.autoGroupColumnDef) {
      baseGridOptions.autoGroupColumnDef = this.injectColumnDef(baseGridOptions.autoGroupColumnDef);
    }

    if (exportOptions) {
      if (exportOptions.excelRendererObj) {
        const excelRendererArr: RendererExcelFormat[] = Object.keys(exportOptions.excelRendererObj).map((key) => {
          return {
            ...exportOptions!.excelRendererObj![key],
            numberFormat: {
              format: (exportOptions!.excelRendererObj![key] as any).numberFormat.replace(/"/g, '&quot;'),
            },
          };
        });
        baseGridOptions.excelStyles = excelRendererArr.map((s) => {
          return { numberFormat: s.numberFormat, id: s.id };
        });
      }
    }

    let treeGrid: any = null;
    let flatGrid: any = null;

    if (treeColumnDefinition) {
      const treeOptions: AgGridReactProps = {
        autoGroupColumnDef: treeColumnDefinition,
        groupDefaultExpanded: -1,
        treeData: true,
        getDataPath(item: any) {
          return item[treeColumnDefinition!.field!];
        },
        ...baseGridOptions,
      };
      treeGrid = (
        <AgGridReact rowData={data && data.length > 0 ? data : undefined} gridOptions={treeOptions} {...treeOptions} />
      );
      flatGrid = null;
    } else {
      treeGrid = null;
      flatGrid = (
        <AgGridReact
          rowData={data && data.length > 0 ? data : undefined}
          gridOptions={baseGridOptions}
          {...baseGridOptions}
        />
      );
    }

    const classes =
      `ag-theme-material data-grid ${styles.dataGridStyles}` +
      (isMultiHeader ? ' double-header' : '') +
      (className ? ` ${className}` : '');

    return (
      <div className={classes}>
        {treeGrid}
        {flatGrid}
      </div>
    );
  }
}
