import fp from 'lodash/fp';
import { connect, Dispatch } from 'react-redux';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { overloadSubheader } from 'src/components/Subheader/Subheader.slice';
import {
  cleanUp,
  receiveError,
  requestPricingOverTimeConfig,
  receivePricingOverTimeConfig,
  submitPayload,
  receivePricingEvents,
  fetchMassEditData,
  fetchOverTimeData,
  fetchCompanionData,
} from './PricingOverTime.slice';
import { AppState, AppThunkDispatch } from 'src/store';
import { projectState } from 'src/components/ListGridPair/ListGridPair.selectors';
import { SubmitPayload } from 'src/worker/pivotWorker.types';
import { PricingOverTime } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime';
import Axios from 'src/services/axios';
import { get, isEmpty, isNil } from 'lodash/fp';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { MassEditConfig } from 'src/components/MassEdit/MassEdit';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { noop } from 'lodash';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { dispatchUpdateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import {
  OwnProps,
  PricingEventResponse,
  PricingOverTimeDispatchProps,
  PricingOverTimeValueProps,
} from './PricingOverTime.types';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps) {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;
  const [modelDefn, massEditModelDefn, gridModelDefn] = defns.models;

  return {
    submitPayload(payload: SubmitPayload) {
      return dispatch(submitPayload(payload));
    },
    onCompanionItemClick(memberId: string) {
      return dispatch(fetchOverTimeData(memberId, gridModelDefn));
    },
    onShowView() {
      Promise.all([
        Axios.get<PricingEventResponse>(`/api/v2/events?appName=${ASSORTMENT}`),
        tenantConfigClient.getTenantViewDefns({ defnIds: defns.view, appName: ASSORTMENT }),
        dispatch(requestPricingOverTimeConfig()),
      ])
        .then((results) => {
          const [eResp, resp] = results;
          dispatch(receivePricingEvents(eResp.data.data));
          // Hack Fix - remove when possible
          resp[0].view = resp[0]['views']!;
          dispatch(
            receivePricingOverTimeConfig({
              grid: resp[0],
              listSort: resp[1],
              subheaderRollUp: resp[3],
              list: resp[4],
              listLevelBy: resp[6],
              // FIXME: revisit this type issue
              massEdit: !isNil(resp[5]) ? ((resp[5] as unknown) as MassEditConfig) : undefined,
              unmodifiedViewDefn: { $id: '', view: [] },
            })
          );
          dispatch(
            overloadSubheader({
              groupByOptions: resp[2],
              showFlowStatus: true,
              showSearch: true,
              title: 'Grid View',
            })
          );
          return resp[4];
        })
        .then((listConfig: TenantConfigViewData) => {
          let model = modelDefn;
          if (listConfig.model) {
            model = listConfig.model;
          }
          dispatch(fetchCompanionData(model));
        })
        .catch(() => dispatch(receiveError()));

      // fetch massEdit data separately
      dispatch(fetchMassEditData(massEditModelDefn));
    },
    onRefetchData(memberIdSelection: string) {
      dispatch((_dispatch: Dispatch<AppState>, getState: () => AppState): any => {
        const { viewDefns } = getState().pages.assortmentBuild.pricing;
        let model = modelDefn;
        if (viewDefns != null && viewDefns.list.model != null) {
          model = viewDefns.list.model;
        }
        dispatch(fetchCompanionData(model));
      });
      dispatch(fetchMassEditData(massEditModelDefn));

      if (memberIdSelection.length > 0) {
        dispatch(fetchOverTimeData(memberIdSelection, gridModelDefn));
      }
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    onUpdate() {
      noop();
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
  };
}

export function mapStateToProps(state: AppState, ownProps: OwnProps) {
  const viewState = state.pages.assortmentBuild.pricing;
  const { title = 'Pricing View', defns, keys, showPopover, fabType } = ownProps;
  const { rangeList, daysRangeList, timeInfo } = state.scope;
  const allowFrom = !isEmpty(rangeList) ? rangeList[0].id : '';
  const allowTo = !isEmpty(rangeList) ? rangeList[rangeList.length - 1].id : '';

  const companionData = getUniqueDataFromCache(viewState, HashType.pricingCompanion)?.tree || [];
  const overTimeData = getUniqueDataFromCache(viewState, HashType.pricingOverTime)?.tree || [];
  const isCompanionDataLoaded = isDataLoaded(viewState.viewDataStateCompanion);
  const isOverTimeDataLoaded = isDataLoaded(viewState.viewDataStateOverTime);
  const isMassEditDataLoaded = isDataLoaded(viewState.viewDataStateMassEdit);
  const isLiveDataReady = viewState.viewDataStateOverTime === ViewDataState.regularDataReady;
  const originalState = {
    title,
    identityField: keys.idProp,
    treeData: companionData,
    subheaderState: state.subheader,
    subheaderViewDefns: defns.subheader,
    shouldFilterFlowStatus: true,
    allowFrom,
    allowTo,
    rangeList: daysRangeList,
    showPopover,
    activeTab: state.perspective.activeTab,
    ...viewState,
    loaded: isCompanionDataLoaded,
    timeInfo,
    fabType: fabType || FabType.none,
  };
  const stateProps = {
    ...originalState,
    ...projectState(originalState),
    ...viewState,
    scopeStart: get('scope.scope.start', state),
    isOverTimeDataLoaded,
    isMassEditDataLoaded,
    isLiveDataReady,
    overTimeData,
  };
  return stateProps;
}

function mergeProps(stateProps: PricingOverTimeValueProps, dispatchProps: PricingOverTimeDispatchProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    onRefetchData: (_selection: string) => {
      dispatchProps.onRefetchData(stateProps.selection);
    },
  };
}

const sensitiveView = fp.flow(
  () => PricingOverTime,
  withFab,
  makeScopeAndFilterSensitive,
  makePrintSensitive,
  makePopoverSensitive
)();

export default connect(mapStateToProps, dispatchToProps, mergeProps)(sensitiveView);
