import { calc, em, important, percent } from 'csx';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { style } from 'typestyle';

const visualizeContainer = style({
  $debugName: 'visualize-container',
});

const visualizeButtonWidth = 10;
const vivisualizeButtonContainerMargin = 0.5;
const lineGraphClassName = 'visualize-line-chart';
const visualizeGraphsContainer = style({
  $debugName: 'visualize-graph',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  $nest: {
    [`.${lineGraphClassName}`]: {
      width: calc(`${percent(100)} - ${em(visualizeButtonWidth + 2 * vivisualizeButtonContainerMargin)}`),
      flexGrow: 1,
    },
    section: {
      display: 'flex',
    },
  },
});

const visualizeButtonContainer = style({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 'auto',
  marginTop: 'auto',
  width: em(visualizeButtonWidth + 2 * vivisualizeButtonContainerMargin),
  paddingLeft: 50,
  paddingBottom: '4rem',
});

const getVisualizeChartItem = (selected: boolean) => {
  const color = selected ? TEAL_PRIMARY : '#cccccc';
  return style({
    color,
    fontSize: 12,
    fontWeight: 'bold',
    cursor: 'pointer',
    height: 30,
    $nest: {
      '&::before': {
        height: 12,
        width: 12,
        marginRight: 5,
        backgroundColor: color,
        display: 'inline-block',
        borderRadius: '50%',
        content: '" "',
        top: 1,
        position: 'relative',
      },
    },
  });
};

const barGraphContainer = style({
  height: 120,
  width: '100%',
  flexGrow: 1,
  $nest: {
    ['* > svg']: {
      overflow: 'visible',
    },
    ['* > .highcharts-container']: {
      overflow: important('visible'),
    },
  },
});

export {
  visualizeContainer,
  visualizeGraphsContainer,
  visualizeButtonContainer,
  lineGraphClassName,
  barGraphContainer,
  getVisualizeChartItem,
};
