import { ViewDefnState } from 'src/dao/tenantConfigClient';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { TimeChartConfig } from '../TimeChart/TimeChart';
import { fetchEnhancedOvertimeConfigs } from './EnhancedOvertime.actions';
import { ConfigureOvertimeConfig } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView.types';
import { Option } from 'src/components/Configure/ConfigureModal';

export type EnhancedOvertimeSlice = {
  viewDefnState: ViewDefnState;
  chartDataState: ViewDataState;
  gridDataState: ViewDataState;
  chartCacheHash: string | null;
  gridCacheHash: string | null;
  chartViewDefn: TimeChartConfig | null;
  gridViewDefn: ConfigureOvertimeConfig | null;
  aggBys: Option[];
};

const initialState: EnhancedOvertimeSlice = {
  viewDefnState: ViewDefnState.idle,
  chartDataState: ViewDataState.idle,
  gridDataState: ViewDataState.idle,
  chartCacheHash: null,
  gridCacheHash: null,
  chartViewDefn: null,
  gridViewDefn: null,
  aggBys: [],
};

const enhancedOvertimeReducer = createSlice({
  name: 'EnhancedOvertime',
  initialState,
  reducers: {
    requestChartData(state) {
      state.chartDataState = ViewDataState.regularDataLoading;
    },
    receiveChartCacheHash(state, action: PayloadAction<string>) {
      state.chartCacheHash = action.payload;
    },
    receiveChartCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.chartCacheHash) {
        state.chartDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveChartLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.chartCacheHash) {
        const cacheInUse = state.chartDataState === ViewDataState.cacheBackgroundDataLoading;
        state.chartDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    requestGridData(state) {
      state.gridDataState = ViewDataState.regularDataLoading;
    },
    receiveGridCacheHash(state, action: PayloadAction<string>) {
      state.gridCacheHash = action.payload;
    },
    receiveGridCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.gridCacheHash) {
        state.gridDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveGridLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.gridCacheHash) {
        const cacheInUse = state.gridDataState === ViewDataState.cacheBackgroundDataLoading;
        state.gridDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    updateAggBys(state, action: PayloadAction<Option[]>) {
      state.aggBys = action.payload;
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnhancedOvertimeConfigs.pending, (state) => {
        state.viewDefnState = ViewDefnState.loading;
      })
      .addCase(fetchEnhancedOvertimeConfigs.fulfilled, (state, action) => {
        state.viewDefnState = ViewDefnState.loaded;
        const [chartViewDefn, gridViewDefn] = action.payload;
        state.chartViewDefn = chartViewDefn as TimeChartConfig;
        state.gridViewDefn = gridViewDefn as ConfigureOvertimeConfig;
      })
      .addCase(fetchEnhancedOvertimeConfigs.rejected, (state) => {
        state.viewDefnState = ViewDefnState.loading;
      });
  },
});

export const {
  requestChartData,
  receiveChartCacheHash,
  receiveChartCachedData,
  receiveChartLiveData,
  requestGridData,
  receiveGridCacheHash,
  receiveGridCachedData,
  receiveGridLiveData,
  updateAggBys,
  receiveError,
  cleanUp,
} = enhancedOvertimeReducer.actions;

export function fetchEnhancedOvertimeChartData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestChartData,
    receiveChartCacheHash,
    receiveChartCachedData,
    receiveChartLiveData
  );
}

export function fetchEnhancedOvertimeGridData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestGridData,
    receiveGridCacheHash,
    receiveGridCachedData,
    receiveGridLiveData
  );
}

export default enhancedOvertimeReducer.reducer;
