import { connect } from 'react-redux';
import FloorsetSelectorForm from './FloorsetSelectorForm';
import { FloorsetConfigResponse } from 'src/types/Scope';
import { AppState, AppThunkDispatch } from 'src/store';
import { asyncGetFloorsetConfig, setSelectedPeriod } from '../ScopeSelector.actions';
import { QuickSelect, QuickSelectItem, QuickSelectRegion } from 'src/dao/scopeClient';

//TODO: MAKE A DAMN SUB-STATE FOR THIS COMPONENT
export interface StateProps {
  floorsetConfig: FloorsetConfigResponse;
  selectedFloorset: string | null;
  selectedProductMember: string | null;
  invalid?: string;
  isLoading: boolean;
  locked: boolean;
  quickSelects: QuickSelect[];
  region: QuickSelectRegion;
}

export type DispatchProps = {
  onChangeFloorset(floorset: QuickSelectItem): void;
  onClickGetFloorset(productMemberId: string): void;
};

export type OwnProps = {
  region: QuickSelectRegion;
};

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const { productMember, floorSet, historyFloorset } = state.scope.selections;

  const { selectionErrors, currentFloorSet, floorsetConfig, floorsetLoading, quickSelects } = state.scope;

  const selectedFloorset = ownProps.region === 'Assortment' ? currentFloorSet || floorSet : historyFloorset;
  return {
    floorsetConfig: floorsetConfig,
    selectedFloorset: selectedFloorset,
    selectedProductMember: productMember,
    invalid: selectionErrors.floorSet,
    isLoading: floorsetLoading,
    locked: false,
    quickSelects,
    region: ownProps.region,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): DispatchProps {
  return {
    onChangeFloorset(floorSet: QuickSelectItem): void {
      dispatch(setSelectedPeriod(floorSet, ownProps.region));
      // dispatch(asyncGetFloorsetAttributes(floorSet));
    },
    onClickGetFloorset(productMember: string): void {
      dispatch(asyncGetFloorsetConfig(productMember, ownProps.region));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorsetSelectorForm);
