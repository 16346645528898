import * as React from 'react';
import { Checkbox, MuiThemeProvider } from '@material-ui/core';
import { ICellRendererParams } from 'ag-grid-community';
import { muiTheme } from 'src/utils/Style/Theme';
import { concat, includes, without } from 'lodash';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
type Props = ICellRendererParams & {
  isEditable: boolean;
  availableSelections: unknown[];
};

type State = {
  availableOptions: unknown[];
};
export default class ValidValuesCheckBoxEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  toggleSelection(sel: unknown) {
    const currentSelections = this.props.getValue();
    if (includes(currentSelections, sel)) {
      this.props.setValue(without(currentSelections, sel));
    } else {
      this.props.setValue(concat(currentSelections, sel));
    }
  }

  selectionActive(sel: unknown) {
    return includes(this.props.getValue(), sel);
  }

  render() {
    const checkboxes = this.props.availableSelections.map((sel) => {
      return (
        <span key={'' + sel} className="vv-cell-checkbox">
          <Checkbox
            checked={this.selectionActive(sel)}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleOutlineIcon />}
            onChange={() => this.toggleSelection(sel)}
            disabled={!this.props.isEditable}
            disableRipple={!this.props.isEditable}
          />
        </span>
      );
    });
    return <MuiThemeProvider theme={muiTheme}>{checkboxes}</MuiThemeProvider>;
  }
}
