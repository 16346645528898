import { style } from 'typestyle';
import { ORANGE, borderHighlightColor, ORANGE_LIGHT, ORANGE_DARK } from 'src/utils/Style/Theme';

const BORDER = '2px solid';
const DISABLED_BORDER = `2px solid ${ORANGE_LIGHT.toHexString()}`;

export const containerStyle = style({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 70px)',
  borderColor: ORANGE.toHexString(),
  $nest: {
    a: {
      textAlign: 'center',
      margin: '1rem',
      color: ORANGE.toHexString(),
    },
    'a:hover': {
      borderColor: ORANGE_DARK.toHexString(),
      color: ORANGE_DARK.toHexString(),
      $nest: {
        label: {
          textDecoration: 'underline',
        },
      },
    },
    span: {
      border: BORDER,
      borderRadius: '100%',
      margin: '1rem',
    },
    label: {
      color: 'black',
    },
  },
});

export const disabledStyle = style({
  color: 'gray',
  textAlign: 'center',
  cursor: 'not-allowed',
  margin: '1rem',
  $nest: {
    'span.disabled': {
      border: DISABLED_BORDER,
      borderRadius: '100%',
      color: borderHighlightColor,
      borderColor: ORANGE_LIGHT.toString(),
    },
    label: {
      color: 'grey',
      cursor: 'not-allowed',
    },
    i: {
      color: ORANGE_LIGHT.toString(),
    },
    'i:hover': {
      color: ORANGE_LIGHT.toString(),
    },
  },
});

export const iconContainer = style({
  height: '5rem',
  width: '5rem',
  border: `1px solid ${ORANGE.toString()}`,
  borderRadius: '100%',
});

export const brandLogoCentered = style({
  width: 200,
  transform: 'translateX(-38%)',
  position: 'absolute',
  top: '26%',
});
