import { TenantConfig } from './bindings.types';
import { Action } from './AppConfig.actions';
import { UNINITIALIZED } from 'src/utils/Domain/Constants';

export type AppConfigSlice = {
  tenantConfig: TenantConfig;
  configFingerprint?: string;
};

export const initialState: AppConfigSlice = {
  tenantConfig: {
    id: UNINITIALIZED,
    defaultTab: {
      /* eslint-disable @typescript-eslint/naming-convention */
      BottomUp: UNINITIALIZED,
      TopDown: UNINITIALIZED,
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    idleTimeout: 300,
    flowStatus: {
      values: [],
      defaultSelection: [],
    },
    lookBackPeriods: [],
    perspectives: [],
    context: {
      assortment: { tabs: [] },
      hindsighting: { tabs: [] },
      allocation: { tabs: [] },
    },
    tabs: [],
    printSizes: [],
  },
};
// The update is fired before react mounts so should update before any other app actions

export default function appConfigReducer(state: AppConfigSlice = initialState, action: Action): AppConfigSlice {
  if (action.type === 'UPDATE_APP_CONFIG') {
    return {
      tenantConfig: action.tenantConfig,
    };
  } else if (action.type === 'UPDATE_CONFIG_FINGERPRINT') {
    return {
      tenantConfig: state.tenantConfig,
      configFingerprint: action.fingerprint,
    };
  }
  return state;
}
