import { Dispatch } from 'react-redux';
import { Action as ReduxAction } from 'redux';
import { AppDispatch, AppState, AppThunkDispatch, clearCachedData } from 'src/store';
import container from 'src/ServiceContainer';
import { QuickSelect, QuickSelectItem, QuickSelectRegion, ScopeConfigResponse } from 'src/dao/scopeClient';
import {
  DimensionConfigResponse,
  FloorsetConfigResponse,
  ScopeSelections,
  Scope,
  FloorsetAttrResponse,
  PlanDatesResponse,
} from 'src/types/Scope';
import {
  receiveFilterState,
  makeUpdateLastSelection,
  flushSelectionOverridesStarted,
} from 'src/components/FilterPanel/FilterPanel.slice';
import { isHistoryTabContext, perspectiveToSmallName } from 'src/utils/Domain/Perspective';
import { getServerFilterSelections } from 'src/utils/Filter/Filters';
import { find, isEqual, assign } from 'lodash';
import { setSessionScope, removeSessionScope } from '../RestoreSession/RestoreSession.utils';
import { refreshFilterSelections } from '../Headerbar/Headerbar.container';
import { anyNull } from 'src/utils/Functions/AnyNull';
import { initialScope } from './ScopeSelector.reducer';

export type Action =
  | { type: 'CHANGE_SCOPE' }
  | { type: 'CHANGE_SCOPE_SELECTION'; selections: Partial<ScopeSelections> }
  | { type: 'CLEAN_UP_SCOPE_SELECTOR' }
  | { type: 'CLOSE_SCOPE' }
  | { type: 'RECEIVE_DIMENSION_CONFIG'; dimensionConfig: DimensionConfigResponse }
  | { type: 'RECEIVE_FLOORSET_ATTRIBUTES'; floorsetAttributes: FloorsetAttrResponse }
  | { type: 'RECEIVE_FLOORSET_CONFIG'; floorsetConfig: FloorsetConfigResponse }
  | { type: 'RECEIVE_QUICKSELECTS'; quickSelects: QuickSelect[] }
  | { type: 'RECEIVE_SCOPE'; scope: Scope; updatedAt: number }
  | { type: 'RECEIVE_SCOPE_AFTER_SET'; scope: Scope; updatedAt: number; scopeSelected: true }
  | { type: 'RECEIVE_SCOPE_REFRESH_TRIGGER'; scope: Scope; updatedAt: number }
  | { type: 'RECEIVE_SCOPE_CONFIG'; scopeConfig: ScopeConfigResponse }
  | { type: 'RECEIVE_SCOPE_ERROR'; error: string }
  | { type: 'REQUEST_DIMENSION_CONFIG' }
  | { type: 'REQUEST_FLOORSET_ATTRIBUTES' }
  | { type: 'REQUEST_FLOORSET_CONFIG'; product: string }
  | { type: 'REQUEST_SCOPE' }
  | { type: 'REQUEST_SCOPE_CONFIG' }
  | { type: 'SEND_SCOPE' }
  | { type: 'CLEAR_FLOORSET' }
  | { type: 'VALIDATE_ALL_SELECTIONS' }
  | { type: 'GET_PLAN_DATES'; planDates: PlanDatesResponse }
  | { type: typeof RESET_SCOPE };

export const CHANGE_SCOPE = 'CHANGE_SCOPE';
export function changeScope(): Action {
  return {
    type: 'CHANGE_SCOPE',
  };
}
export const CLOSE_SCOPE = 'CLOSE_SCOPE';
export function closeScope(): Action {
  return {
    type: 'CLOSE_SCOPE',
  };
}

export const REQUEST_SCOPE_CONFIG = 'REQUEST_SCOPE_CONFIG';
export function requestScopeConfig(): Action {
  return {
    type: REQUEST_SCOPE_CONFIG,
  };
}

export const RECEIVE_SCOPE_CONFIG = 'RECEIVE_SCOPE_CONFIG';
export function receiveScopeConfig(scopeConfig: ScopeConfigResponse): Action {
  return {
    type: RECEIVE_SCOPE_CONFIG,
    scopeConfig,
  };
}

export const RECEIVE_SCOPE_ERROR = 'RECEIVE_SCOPE_ERROR';
export function receiveScopeError(error: string): Action {
  return {
    type: RECEIVE_SCOPE_ERROR,
    error,
  };
}

export const REQUEST_FLOORSET_CONFIG = 'REQUEST_FLOORSET_CONFIG';
export function requestFloorsetConfig(product: string): Action {
  return {
    type: REQUEST_FLOORSET_CONFIG,
    product,
  };
}

export const GET_PLAN_DATES = 'GET_PLAN_DATES';
export function getPlanDates(planDates: PlanDatesResponse): Action {
  return {
    type: GET_PLAN_DATES,
    planDates,
  };
}

export const CLEAR_FLOORSET = 'CLEAR_FLOORSET';
export function clearFloorsetAndQuickSelect(): Action {
  return {
    type: CLEAR_FLOORSET,
  };
}

export const REQUEST_FLOORSET_ATTRIBUTES = 'REQUEST_FLOORSET_ATTRIBUTES';
export function requestFloorsetAttributes(): Action {
  return {
    type: REQUEST_FLOORSET_ATTRIBUTES,
  };
}

export const RECEIVE_FLOORSET_ATTRIBUTES = 'RECEIVE_FLOORSET_ATTRIBUTES';
export function receiveFloorsetAttributes(floorsetAttributes: FloorsetAttrResponse): Action {
  return {
    type: RECEIVE_FLOORSET_ATTRIBUTES,
    floorsetAttributes,
  };
}

export const RECEIVE_FLOORSET_CONFIG = 'RECEIVE_FLOORSET_CONFIG';
export function receiveFloorsetConfig(floorsetConfig: FloorsetConfigResponse): Action {
  return {
    type: RECEIVE_FLOORSET_CONFIG,
    floorsetConfig,
  };
}

export const RECEIVE_QUICKSELECTS = 'RECEIVE_QUICKSELECTS';
export function receiveQuickSelects(quickSelects: QuickSelect[]): Action {
  return {
    type: RECEIVE_QUICKSELECTS,
    quickSelects,
  };
}

export const CHANGE_SCOPE_SELECTION = 'CHANGE_SCOPE_SELECTION';
export function changeScopeSelection(selections: Partial<ScopeSelections>): Action {
  return {
    type: CHANGE_SCOPE_SELECTION,
    selections,
  };
}

export const REQUEST_SCOPE = 'REQUEST_SCOPE';
export function requestScope(): Action {
  return {
    type: REQUEST_SCOPE,
  };
}

export const SEND_SCOPE = 'SEND_SCOPE';
export function sendScope(): Action {
  return {
    type: SEND_SCOPE,
  };
}

export const RECEIVE_SCOPE = 'RECEIVE_SCOPE';
export function receiveScope(scope: Scope, updatedAt: number): Action {
  return {
    type: RECEIVE_SCOPE,
    scope,
    updatedAt,
  };
}

export const RECEIVE_SCOPE_REFRESH_TRIGGER = 'RECEIVE_SCOPE_REFRESH_TRIGGER';
export function receiveScopeRefreshTrigger(scope: Scope, updatedAt: number): Action {
  return {
    type: RECEIVE_SCOPE_REFRESH_TRIGGER,
    scope,
    updatedAt,
  };
}

export const RESET_SCOPE = 'RESET_SCOPE';
export const resetScope = () => {
  return { type: RESET_SCOPE };
};

export const VALIDATE_ALL_SELECTIONS = 'VALIDATE_ALL_SELECTIONS';
export function validateAllSelections(): Action {
  return {
    type: VALIDATE_ALL_SELECTIONS,
  };
}

export function asyncGetPlanDates() {
  return (dispatch: AppDispatch): Promise<Action> | void => {
    return container.scopeClient.getPlanDates().then((response: PlanDatesResponse) => {
      return dispatch(
        getPlanDates({
          planCurrent: response.planCurrent,
          planStart: response.planStart,
          planEnd: response.planEnd,
        })
      );
    });
  };
}

export function asyncGetScopeConfig() {
  return (dispatch: AppDispatch): Promise<Action> => {
    dispatch(requestScopeConfig());
    return container.scopeClient
      .getScopeConfig()
      .then((response) => {
        return dispatch(receiveScopeConfig(response));
      })
      .catch((error) => {
        return dispatch(receiveScopeError(error));
      });
  };
}

export function asyncGetScope() {
  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<Action | void> => {
    dispatch(requestScope());
    const perspective = getState().perspective.selected!.id;
    return container.scopeClient
      .getScope()
      .then((response) => {
        if (response.scope.valid) {
          const now = Date.now();
          dispatch(receiveScope(response.scope, now));
          setSessionScope(response.scope);
          if (response.filterData) {
            const filterKey = perspectiveToSmallName(perspective) + 'history';
            const filterGroups = response.filterData.filters[filterKey];
            const lastSelection = getServerFilterSelections(filterGroups);
            dispatch(receiveFilterState({ filters: filterGroups, updatedAt: now }));
            dispatch(makeUpdateLastSelection(lastSelection));
            dispatch(asyncGetPlanDates());
          }
          dispatch(refreshFilterSelections());
        }
        return dispatch(asyncGetScopeConfig());
      })
      .catch((error) => {
        removeSessionScope();
        return dispatch(receiveScopeError(error));
      });
  };
}

export function setSelectedPeriod<S extends AppState>(selection: QuickSelectItem, region: QuickSelectRegion) {
  return (dispatch: Dispatch<S>, getState: () => S): Action | void => {
    if (selection != null) {
      if (region === 'Assortment') {
        // TODO: We need to solve the "ly" problem
        return dispatch(
          changeScopeSelection({
            end: selection.slsend,
            start: selection.slsstart,
            floorSet: selection.id,
            historyStart: selection.lySlsstart,
            historyEnd: selection.lySlsend,
          })
        );
      } else {
        return dispatch(
          changeScopeSelection({
            historyEnd: selection.slsend,
            historyStart: selection.slsstart,
            historyFloorset: selection.id,
          })
        );
      }
    }
  };
}

export function asyncGetFloorsetAttributes<S extends AppState>(floorSet: string) {
  return (dispatch: Dispatch<S>, getState: () => S): Promise<Action | void> => {
    const productId = getState().scope.selections.productMember;
    const rangeList = getState().scope.pastRangeList;

    if (productId) {
      return container.scopeClient.getFloorsetAttributes(floorSet, productId).then((response) => {
        return dispatch(
          changeScopeSelection({
            end: response.slsend,
            start: response.slsstart,
            // endSales: response.slsend,
            // startSales: response.slsstart,
            ...(function historyGuard() {
              const histStartDateExists = find(rangeList, { id: response.lyslsstart });
              const histEndDateExists = find(rangeList, { id: response.lyslsend });
              if (!histStartDateExists || !histEndDateExists) {
                return {
                  historyStart: 'invalid',
                  historyEnd: 'invalid',
                };
              }
              return {
                historyEnd: response.lyslsend,
                historyStart: response.lyslsstart,
              };
            })(),
            floorSet,
          })
        );
      });
    }
    return Promise.resolve();
  };
}

export function asyncGetFloorsetConfig<S extends AppState>(productId: string, region?: string) {
  return async (dispatch: Dispatch<S>, _getState: () => S): Promise<ReduxAction[]> => {
    dispatch(requestFloorsetConfig(productId));
    const floorsetsFetch = await container.scopeClient
      .getFloorsets(productId)
      .then((response) => {
        return dispatch(receiveFloorsetConfig(response));
      })
      .catch((error) => {
        return dispatch(receiveScopeError(error));
      });
    if (region != null) {
      await container.scopeClient.getQuickSelects(productId, region).then((response) => {
        return dispatch(receiveQuickSelects(response));
      });
    }
    return Promise.all([floorsetsFetch]);
  };
}

export function asyncSetScope(oldScope: Scope) {
  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<Action | void> => {
    dispatch(validateAllSelections());
    if (getState().scope.selectionsValid) {
      const { selections, rangeList } = getState().scope;
      // Don't submit scope unless it has changed
      if (isEqual(oldScope, selections)) {
        dispatch(closeScope());
        return Promise.resolve();
      }
      dispatch(sendScope());
      // TODO: Determine why the names are causing issues as they are not required to set scope.
      const requiredSelections = {
        ...selections,
        locationMemberName: null,
        productMemberName: null,
      };
      const planCurrent = rangeList[0].id;
      // if assortment plan not selected, select first week as default
      if (anyNull(selections, ['end', 'start'])) {
        assign(requiredSelections, { end: planCurrent, start: planCurrent });
      }
      clearCachedData();
      return container.scopeClient
        .setScope(requiredSelections)
        .then((response) => {
          const now = Date.now();

          response.scope.valid ? setSessionScope(selections) : removeSessionScope();
          dispatch(flushSelectionOverridesStarted());
          if (response.filterData) {
            const perspective = getState().perspective.selected!.id;
            const activeTab = getState().perspective.activeTab;
            const isHistory = isHistoryTabContext();
            const filterAppendKey = !activeTab || isHistory ? 'history' : 'assortment';
            const filterKey = perspectiveToSmallName(perspective) + filterAppendKey;
            const filterGroups = response.filterData.filters[filterKey];
            dispatch(receiveFilterState({ filters: filterGroups, updatedAt: now }));
          }
          dispatch(refreshFilterSelections());
          dispatch(asyncGetPlanDates());
          // refresh if the submitted oldScope is referentially equal to the initial scope (indicating we're in the initial redux state)
          // or if the scopes aren't equal, indicating a new scope is being submitted
          if (oldScope === initialScope || !isEqual(oldScope, initialScope)) {
            return dispatch(receiveScopeRefreshTrigger(response.scope, Date.now()));
          }
          return;
        })
        .catch((error) => {
          removeSessionScope();
          return dispatch(receiveScopeError(error));
        });
    }
    return Promise.resolve();
  };
}
