import { createSlice } from '@reduxjs/toolkit';

export type PlanTracker = {
  assortmentPlanInProgress: boolean;
  shouldCheckAssortment: boolean;
  askForRefresh: boolean;
  refreshRequestedAt?: number;
};

const initPlanTrackerState: PlanTracker = {
  assortmentPlanInProgress: false,
  shouldCheckAssortment: true,
  askForRefresh: false,
};
const planTrackerSlice = createSlice({
  name: 'planTracker',
  initialState: initPlanTrackerState,
  reducers: {
    init: () => initPlanTrackerState,
  },
});

export default planTrackerSlice.reducer;
