import React from 'react';
import { isNil, isEmpty } from 'lodash';

import styles from './RightContainer.styles';
import { DispatchProps, StateProps } from 'src/components/RightContainer/RightContainer.container';
import HistoryStylePane from 'src/components/HistoryStylePane/HistoryStylePane.container';
import FilterPanel from 'src/components/FilterPanel/FilterPanel.container';
import { processApiParams, getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { NavigationButton } from 'src/components/NavigationButton/NavigationButton';

type ExitProps = {
  onClick: () => void;
};

type EditStyleButton = {
  path: string;
  text?: string;
};

const ExitButton = (props: ExitProps) => {
  const { onClick } = props;
  return (
    <div className={styles.rightContainerCloseIcon} onClick={onClick} data-qa="rightContainerCloseIcon">
      <i className="far fa-times-circle" />
    </div>
  );
};

export type RightContainerProps = StateProps & {
  editStyleBtn?: EditStyleButton;
};

type Props = StateProps & DispatchProps & RightContainerProps;

type State = {
  description: string;
  title: string;
  hideEditStyleButton: boolean;
};

export default class RightContainer extends React.Component<Props, State> {
  private hidePane: Record<string, any> | undefined;

  constructor(props: Props) {
    super(props);

    this.hidePane = { display: 'none' };
    this.state = {
      description: '',
      title: '',
      hideEditStyleButton: false,
    };
  }

  handleClickOnCloseIcon = () => {
    const { payload, clearAndCloseWindow } = this.props;
    clearAndCloseWindow(payload);
  };

  updateTitle = (title: string) => {
    if (this.state.title !== title) {
      this.setState({
        title,
      });
    }
  };

  updateDescription = (desc: string) => {
    const { description } = this.state;
    if (desc !== description) {
      this.setState({
        description: desc,
      });
    }
  };

  handleDataLoaded = (data: BasicPivotItem[]) => {
    this.setState({
      hideEditStyleButton: isEmpty(data),
    });
  };

  handleEditStyle = (): void => {
    const { payload, editStyleBtn } = this.props;

    const dataApi = {
      url: editStyleBtn!.path,
      params: {
        member: 'id',
      },
    };
    const id =
      !isNil(payload) && (payload.type === 'HistoryStylePane' || payload.type === 'StyleDetails')
        ? payload.parentId
        : '';
    const processedDataApi = processApiParams(dataApi, { id });
    const url = getUrl(processedDataApi);
    window.location.hash = url;
  };

  render() {
    const { payload, editStyleBtn } = this.props;
    const { description, title, hideEditStyleButton } = this.state;
    let innerItem: JSX.Element | Element | null = null;

    let pane: JSX.Element | null = null;
    let actions: JSX.Element = (
      <div className={styles.styleHeaderContainer(false)} data-qa="StylePaneActionsContainer">
        <ExitButton onClick={this.handleClickOnCloseIcon} />
      </div>
    );
    if (payload) {
      this.hidePane = undefined;
      if (payload.type === 'HistoryStylePane' || payload.type === 'StyleDetails') {
        innerItem = (
          <HistoryStylePane
            styleColorId={payload.id}
            dataId={payload.parentId}
            styleColorName={payload.styleColorName}
            isAssortmentBuild={!isNil(payload.isAssortmentBuild) ? payload.isAssortmentBuild : false}
            onDescriptionReady={this.updateDescription}
            onTitleReady={this.updateTitle}
            onDataReady={this.handleDataLoaded}
          />
        );
        actions = (
          <div className={styles.styleHeaderContainer(true)} data-qa="StylePaneActionsContainer">
            <React.Fragment>
              <div className={styles.headerText}>
                <div className="title" style={{ color: TEAL_PRIMARY }} data-qa="StylePaneItemTitle">
                  {title}
                </div>
                <div className="description">{description}</div>
              </div>
              <div className={styles.headerActions}>
                {editStyleBtn && !hideEditStyleButton && payload.type === 'StyleDetails' && (
                  <NavigationButton
                    dataQa={'StylePaneEditStyleNavButton'}
                    buttonText={editStyleBtn.text || 'Edit Style'}
                    onClick={this.handleEditStyle}
                  />
                )}
                <ExitButton onClick={this.handleClickOnCloseIcon} />
              </div>
            </React.Fragment>
          </div>
        );
      } else if (payload.type === 'FilterPanel') {
        innerItem = <FilterPanel />;
      } else {
        innerItem = <span>Unknown payload type</span>;
      }

      const isStyleInfo = payload.type === 'HistoryStylePane' || payload.type === 'StyleDetails';
      pane = (
        <React.Fragment>
          <div className={styles.stylePane} data-qa="StylePaneContainer">
            {actions}
            {<div className={styles.styleHeaderSpacer(isStyleInfo)} />}
            {innerItem}
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className={`${styles.rightContainer(!!payload)}`} style={this.hidePane}>
        {pane}
      </div>
    );
  }
}
