import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewData, ViewDefnState } from 'src/dao/tenantConfigClient';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ViewDataState } from 'src/types/Domain';
import { cacheCheckFetchPivotData } from '../../StyleColorReview/StyleColorReview.slice';
import serviceContainer from 'src/ServiceContainer';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { isNil } from 'lodash';

type TenantResponse = {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn?: TenantConfigViewData;
  favoritesList?: FavoriteResponseItem[];
};

export type ProductivitySlice = {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  cacheHash: string | null;
  viewDataState: ViewDataState;
  viewDefnState: ViewDefnState;
};

const initialState: ProductivitySlice = {
  viewDefn: {} as TenantConfigViewData,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  cacheHash: null,
  viewDataState: ViewDataState.idle,
  viewDefnState: ViewDefnState.idle,
};

const productivityReducer = createSlice({
  name: 'Productivity',
  initialState,
  reducers: {
    requestViewDefn(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveViewDefn(state, action: PayloadAction<TenantResponse>) {
      state.viewDefnState = ViewDefnState.loaded;

      // need to only modify items in state if they exist in action payload
      state.viewDefn = !isNil(action.payload.viewDefn) ? action.payload.viewDefn : state.viewDefn;
      state.unmodifiedViewDefn = !isNil(action.payload.unmodifiedViewDefn)
        ? action.payload.unmodifiedViewDefn
        : state.unmodifiedViewDefn;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    cleanUp() {
      return initialState;
    },
    receiveError() {
      return initialState;
    },
  },
});

export const {
  requestViewDefn,
  receiveViewDefn,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  cleanUp,
  receiveError,
} = productivityReducer.actions;

export function fetchProductivityData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData,
    true
  );
}

export default productivityReducer.reducer;
