import { AppState } from 'src/store';
import { allocationSizeEligibilityLens } from 'src/services/lenses/lenses';
import {
  LoadingSizeEligibilityList,
  LoadedSizeEligibilityList,
  ProjectedState,
  OwnProps,
} from './SizeEligibilityListGrid.container';
import { cloneDeep, defaultTo, memoize, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ValueLabelPair } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import {
  generateOrderedGroups,
  groupedToAgFlatTree,
  listDataTreeToAgFlatTree,
} from 'src/utils/Component/AgGrid/AgDataFormat';
import { flattenAndLabelGroup } from 'src/components/StandardCardView/SortAndGroup';
import { filterAndSortPivotItems } from 'src/utils/Pivot/Filter';
import { flattenToLeaves } from 'src/utils/Pivot/Flatten';

const selectState = (state: AppState, ownProps: OwnProps): LoadingSizeEligibilityList | LoadedSizeEligibilityList => {
  const sizeEligibilityListGridSlice = allocationSizeEligibilityLens.get(state);
  return {
    selectedSizeRange: sizeEligibilityListGridSlice.selectedSizeRange,
    topProduct: state.scope.scope.productMember ? state.scope.scope.productMember : '', //shamefule
    sizeRanges: sizeEligibilityListGridSlice.sizeRanges,
    gridData: sizeEligibilityListGridSlice.gridData,
    dataLoading: sizeEligibilityListGridSlice.areGridDataLoading,
    configLoading: sizeEligibilityListGridSlice.areConfigLoading,
    companionLoading: sizeEligibilityListGridSlice.areCompanionViewLoading,
    viewDefns: sizeEligibilityListGridSlice.viewDefns,
    validSizes: sizeEligibilityListGridSlice.validSizes,
    floorsetData: sizeEligibilityListGridSlice.floorsetData,
    companionData: sizeEligibilityListGridSlice.companionData,
    companionApi: ownProps.companionApi,
    downloadLink: ownProps.subheader?.downloadLink,
    subheader: state.subheader,
    subheaderDefns: ownProps.defns.subheader,
    dataApi: ownProps.dataApi,
  };
};

const projectState = (
  selectedState: LoadingSizeEligibilityList | LoadedSizeEligibilityList
): LoadingSizeEligibilityList | ProjectedState => {
  if (!('gridData' in selectedState) && !('sizeRange' in selectedState)) {
    return selectedState;
  }
  if (!selectedState.gridData || !selectedState.sizeRanges) {
    return {
      ...selectedState,
      topProduct: selectedState.topProduct,
      dataLoading: true,
      configLoading: true,
    };
  }
  // presume that all items contain all sizes
  const { gridData, subheader, validSizes, sizeRanges, viewDefns, dataApi } = selectedState;
  const { groupBy, search: searchAny, sortBy } = subheader;
  const search = searchAny.toLowerCase();
  const sizes = validSizes || [];
  const locations = gridData.map((row) => row.grade_cap);

  const sizeRangeToCompanion = (sizeRanges: ValueLabelPair[]) =>
    sizeRanges.map((sizeRange) => {
      return {
        '@path': sizeRange.label,
        attributevaluerank: 0,
        children: [],
        image: '',
        id: sizeRange.label,
        name: sizeRange.label,
        description: sizeRange.label,
      };
    });

  const companionData: BasicPivotItem[] | undefined = selectedState.companionApi
    ? selectedState.companionData
    : sizeRangeToCompanion(sizeRanges);
  const groupBySelection = groupBy.selection || groupBy.defaultSelection;

  let gridItems = cloneDeep(gridData),
    treeColumnDefinition;
  if (selectedState.subheaderDefns && !isEmpty(selectedState.subheaderDefns) && groupBySelection >= 0 && viewDefns) {
    const groupDataIndex = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
    const groupDimension = getGroupBySelectedOptionProperty(groupBy, 'dimension');
    const result = groupedToAgFlatTree(
      generateOrderedGroups(flattenAndLabelGroup({ items: gridItems, groupDataIndex, groupDimension }), groupBy),
      groupDataIndex,
      search,
      sortBy,
      [],
      viewDefns.columns,
      defaultTo(viewDefns.main.leafKey, 'stylecolor') //identityField
    );
    treeColumnDefinition = result.treeColumnDefinition;
    gridItems = result.agFlatTree;
  } else if (dataApi.params && dataApi.params.aggBy && dataApi.params.aggBy.length) {
    const result = listDataTreeToAgFlatTree(dataApi.params.aggBy.split(','), search, [], gridItems, false);
    gridItems = result.agFlatTree;
  } else {
    // No Group By
    const getFlat = memoize(() => flattenToLeaves(gridItems));
    gridItems = filterAndSortPivotItems(search, sortBy, [searchAny], [], getFlat());
  }
  return {
    ...selectedState,
    gridData: gridItems,
    treeColumnDefinition,
    companionData,
    locations,
    sizeRanges: selectedState.sizeRanges,
    sizeNames: sizes,
  };
};
export const selectAndProjectState = createSelector(selectState, projectState);
