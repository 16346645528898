import React from 'react';
import styles from './StoreEligibility.styles';

import { StyleEditConfigColumn } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import * as AgGrid from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, BaseWithValueColDefParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { AxiosPromise } from 'axios';
import { mapValues, isPlainObject, isEqual, forEach, isUndefined, isEmpty } from 'lodash';
import { get, isNil, map, partial, without, values } from 'lodash/fp';

import { StyleEditAction, ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { ColorHeaderRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ColorHeaderRenderer';
import { RangePickerRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RangePickerRenderer';
import RangePickerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/RangePickerEditor.container';
import { ImageCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ImageCellRenderer';
import { BasicItem } from 'src/types/Scope';

import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import { ValidSizesRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidSizesRenderer';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { IconCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/IconCellRenderer';
import CheckboxCellRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/Checkbox';
import { PopoverSelect } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/PopoverSelect';
import ValidValuesRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidValuesRenderer';

import AgGridThemeClass from 'src/utils/Style/AgGridTheme2';
import AgGridMaterialThemeNoBorders from 'src/utils/Style/AgGridMaterialThemeNoBorders';
import { STORE_COUNT, SPECIAL_STORE_GROUP, FUNDED, TIME } from 'src/utils/Domain/Constants';
import { AssortmentClient } from '../../Assortment.client';
import { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';
import { removeColons } from 'src/utils/Pivot/RemoveColons';

type FrameworkComponents = any;
type NonFrameworkComponents = any;
type LocalAxiosResponse = any;
type StoreDataTypes = {
  [key: string]: number | string | string[] | null;
};

type UnformattedStoreDataTypes = {
  [key: string]: string | string[];
};

export type StoreData = {
  [key: string]: StoreDataTypes[];
};

export type UnformattedStoreData = {
  [key: string]: UnformattedStoreDataTypes;
};

export interface Props {
  data: StoreData | null;
  getDataStore: (sdata: StoreData) => void;
  storeDataApi?: ViewApiConfig;
  config: any;
  floorset?: string;
  product?: string;
}

const attributesIgnore = [FUNDED, SPECIAL_STORE_GROUP, TIME, STORE_COUNT];

export interface State {
  config: any;
  rowData: any;
  transposedData: any;
  colDefs: any;
  transposedDefs: any;
  actions?: { [s: string]: StyleEditAction[] };
  disabledIds: string[]; // section specific locking, such as for funded/unfunded
  storeAttributes: string[];
}

export default class Lifecycle extends React.Component<Props, State> {
  nonFrameworkComponents: NonFrameworkComponents;
  frameworkComponents: FrameworkComponents;
  dataQueue!: AxiosPromise<LocalAxiosResponse>[];
  gridApi!: AgGrid.GridApi;
  columnApi!: AgGrid.ColumnApi;
  observers: {
    [key: string]: string; // key: observed dataIndex, value: observer's dataIndex
  };

  constructor(props: Props) {
    super(props);
    this.nonFrameworkComponents = {
      colorHeaderRenderer: ColorHeaderRenderer,
    };
    this.frameworkComponents = {
      imageCellRenderer: ImageCellRenderer,
      iconCellRenderer: IconCellRenderer,
      validValuesEditor: ValidValuesEditor,
      rangePickerRenderer: RangePickerRenderer,
      rangePickerEditor: RangePickerEditor,
      checkboxCellRenderer: CheckboxCellRenderer,
      popoverSelectEditor: PopoverSelect,
      validSizesRenderer: ValidSizesRenderer,
      validValuesRenderer: ValidValuesRenderer,
    };
    this.observers = {};

    this.state = {
      config: null,
      transposedDefs: null,
      colDefs: null,
      rowData: null,
      transposedData: null,
      disabledIds: [],
      storeAttributes: [],
    };
  }

  componentDidMount() {
    this.transposeRowsAndColumns(this.props.data, this.props.config);
  }

  private toggleIdDisable = (id: string): void => {
    // this is for when a column is disabled by some rule, such as funded/unfunded status
    const refreshCols = () => {
      if (this.gridApi) {
        this.gridApi.refreshCells({
          columns: [id],
          force: true,
        });
      }
    };

    if (this.state.disabledIds.indexOf(id) >= 0) {
      this.setState(
        {
          disabledIds: without([id], this.state.disabledIds),
        },
        refreshCols
      );
    } else {
      this.setState(
        {
          disabledIds: this.state.disabledIds.concat([id]),
        },
        refreshCols
      );
    }
  };

  private columnDisabled = (id: string): boolean => {
    return this.state.disabledIds.indexOf(id) >= 0;
  };

  checkDisabledColumnValue(disableValue: boolean | number, value: boolean | number) {
    return disableValue === value;
  }

  createColumn = (colDefs: StyleEditConfigColumn[], styleColor: BasicItem): ColDef => {
    const isInitialFundedFloorset = (colInfo: StyleEditConfigColumn): boolean => {
      const { transposedData } = this.state;
      if (colInfo.dataIndex !== FUNDED || isNil(transposedData)) {
        return false;
      }

      const fundedData = transposedData.find((dataItem: any) => dataItem.dataKey === FUNDED);
      return fundedData[styleColor.id]['isFirstFloorset'] === true;
    };

    const isEditable = (params: { node?: AgGrid.RowNode }) => {
      const rowIndex = params.node!.rowIndex;
      const colInfo = get(rowIndex, colDefs);
      colInfo.controlsColumnDisable = !!colInfo.controlsColumnDisable;

      if (colInfo) {
        return !isInitialFundedFloorset(colInfo) ? colInfo.editable : false;
      } else {
        return false;
      }
    };

    // check for disabled columns on load
    colDefs.forEach((col: StyleEditConfigColumn) => {
      if (col.controlsColumnDisable) {
        const disable = this.checkDisabledColumnValue(col.controlColumnDisableValue, styleColor[col.dataIndex]);
        if (disable) {
          this.toggleIdDisable(styleColor.id);
        }
      }
    });
    const disabledColumn = (colInfo: StyleEditConfigColumn) => {
      return (
        this.columnDisabled(styleColor.id) && !(colInfo.controlsColumnDisable || colInfo.renderer !== 'color_header')
      );
    };
    return {
      headerName: styleColor.id,
      field: styleColor.id,
      valueGetter: (params: AgGrid.ValueGetterParams) => {
        const { data, colDef } = params;
        const floorset = colDef.field;
        const dataIndex = data.id;

        if (isNil(floorset)) {
          return data;
        }

        if (!isNil(dataIndex) && data[floorset]) {
          const dataValue = data[floorset][dataIndex];
          return !isNil(dataValue) ? dataValue : undefined;
        } else {
          return data;
        }
      },
      valueSetter: (params: AgGrid.ValueSetterParams) => {
        if (isNil(params.colDef.field)) {
          return false;
        }

        if (params.data.id && params.data[params.colDef.field] && params.newValue !== undefined) {
          if (isEqual(params.newValue, params.oldValue)) {
            return false;
          }

          const updatedValue = isNil(params.newValue.store_count) ? params.newValue : [params.newValue.id];
          params.data[params.colDef.field][params.data.id] = updatedValue;
          const listOfFloorsetsPropagate = this.getFloorsetsToPropagate(params.colDef.field);

          if (params.data.id === SPECIAL_STORE_GROUP && params.newValue) {
            const { storeAttributes } = this.state;
            listOfFloorsetsPropagate.forEach((floorset) => {
              // reset store attrs on current floorset
              storeAttributes.forEach((attribute) => {
                const rowNode = this.gridApi.getRowNode(attribute);
                const newData = rowNode.data;
                newData[floorset][attribute] = undefined;
                rowNode.setData(newData);
              });

              // Set ssg value for current floorset
              params.data[floorset][params.data.id] = [params.newValue.id];

              // Set store count to selected SSG store count for current floorset
              const rowNodeStoreCount = this.gridApi.getRowNode(STORE_COUNT);
              if (rowNodeStoreCount) {
                rowNodeStoreCount.setDataValue(floorset, params.newValue.store_count);
              }
            });
          } else if (params.data.id === FUNDED && !isNil(params.newValue)) {
            const newVal = params.newValue ? 1.0 : 0.0;
            // Set value to all forward floorsets
            listOfFloorsetsPropagate.forEach((floorset: string) => {
              params.data[floorset][params.data.id] = newVal;
            });
          } else if (params.newValue !== undefined && params.newValue !== null) {
            // Set value to current and forward floorsets
            let skipStoreCountCalc = false;

            if (params.data.id === STORE_COUNT) {
              skipStoreCountCalc = true;
              listOfFloorsetsPropagate.forEach((floorset: string) => {
                params.data[floorset][params.data.id] = params.newValue;
              });
            } else {
              listOfFloorsetsPropagate.forEach((floorset: string) => {
                params.data[floorset][params.data.id] = params.newValue;
                const ssgRowNode = this.gridApi.getRowNode(SPECIAL_STORE_GROUP);
                ssgRowNode.data[floorset][SPECIAL_STORE_GROUP] = [];

                // if there is empty Store attribute, set ALL
                this.state.storeAttributes.forEach((attribute) => {
                  const rowNode = this.gridApi.getRowNode(attribute);
                  const newData = rowNode.data;
                  const attributeData = newData[floorset][attribute];
                  if (isNil(attributeData) || isEmpty(attributeData)) {
                    const colInfo = colDefs.find((item) => item.dataIndex === attribute) as StyleEditConfigColumn;
                    const url = colInfo.dataApi.url;
                    const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
                    getValidValues(url, allowEmptyOption)
                      .then((validValues) => {
                        newData[floorset][attribute] = validValues.map((value: any) => value.value);
                        rowNode.setData(newData);
                      })
                      .catch((err) => console.log(err));
                  }
                });
              });
            }

            // On change of grid, resend store count request
            if (!skipStoreCountCalc && this.props.product) {
              const { storeAttributes } = this.state;
              const postData = storeAttributes.map((attribute) => {
                const rowNode = this.gridApi.getRowNode(attribute);
                const rowNodeData = rowNode.data[params.colDef.field!];
                return rowNodeData;
              });
              Promise.all(
                listOfFloorsetsPropagate.map(async (floorset: string) => {
                  return AssortmentClient.calculateStoreCount(this.props.product!, floorset, postData).then(
                    (calculatedStoreCount) => {
                      const rowNode = this.gridApi.getRowNode(STORE_COUNT);
                      rowNode.data[floorset][STORE_COUNT] = calculatedStoreCount;
                    }
                  );
                })
              ).then(() => {
                this.gridApi.refreshCells();
              });
            }
          }
        }
        return true;
      },
      valueFormatter: (params: AgGrid.ValueFormatterParams) => {
        if (isNil(params.data.id) || isNil(params.colDef.field)) {
          return params.data;
        }

        if (params.data.id && params.data[params.colDef.field]) {
          if (params.data[params.colDef.field][params.data.id]) {
            if (typeof params.data[params.colDef.field][params.data.id] === 'object') {
              return params.data[params.colDef.field][params.data.id].join(', ');
            } else {
              return params.data[params.colDef.field][params.data.id];
            }
          } else {
            return undefined;
          }
        } else {
          return params.data;
        }
      },
      cellStyle: (params) => {
        if (this.props.floorset) {
          if (params.colDef.field === this.props.floorset) {
            if (params.node.id !== TIME) {
              return {
                background: 'rgba(0, 153, 140, .1)',
                color: '#00a89b', // TEAL_PRIMARY
              };
            } else {
              return { background: 'rgba(0, 153, 140, .1)' };
            }
          }
        }
        if (params.node.id !== TIME) {
          return {
            color: '#00a89b', // TEAL_PRIMARY
          };
        }
        return null;
      },
      editable: (params) => {
        const inlineEditors = ['validSizes', 'checkbox'];
        const rowIndex = params.node!.rowIndex;
        const colInfo = get(rowIndex, colDefs);
        colInfo.controlsColumnDisable = colInfo.controlsColumnDisable ? colInfo.controlsColumnDisable : false;
        let editable = false;

        if (colInfo) {
          editable = colInfo.editable && inlineEditors.indexOf(colInfo.renderer!) < 0 && !disabledColumn(colInfo);
        }

        return editable;
      },
      cellEditorSelector: (params) => {
        const rowIndex = params.node!.rowIndex;
        const colInfo = get(rowIndex, colDefs);
        switch (colInfo.inputType) {
          case 'select':
            return {
              component: 'agRichSelect',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'multiRangeEditors':
            return {
              component: 'rangePickerEditor',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'popoverSelect':
            const { text: title, inputComponent } = colInfo;
            const processedDataApi = processApiParams(colInfo.dataApi, styleColor);

            return {
              component: 'popoverSelectEditor',
              params: {
                title,
                inputComponent,
                dataApi: processedDataApi,
              },
            };
          case 'validValues':
          case 'validValuesMulti': {
            const multiSelect = colInfo.inputType === 'validValuesMulti' ? true : undefined;
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            const processedConfigApi = processApiParams(colInfo.dataApi, params.node!);
            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: processedConfigApi,
                dataQa: 'select-store-eligibility',
                multiSelect,
                asCsv: colInfo.asCsv,
                ignoreCache: colInfo.ignoreCache,
                allowEmptyOption,
              },
            };
          }
          case 'receiptsAdjCalculator':
            return {
              component: 'receiptsAdjCalculator',
              params: {
                isEditable: isEditable(params),
                dataApi: {
                  url: colInfo.dataApi.url,
                  params: mapValues(colInfo.dataApi.params, (_v, k) => {
                    return styleColor[k];
                  }),
                  headers: colInfo.dataApi.headers,
                },
              },
            };
          default:
            return {
              component: 'agTextCellEditor',
            };
        }
      },
      cellRendererSelector: (params): ComponentSelectorResult => {
        const rowIndex = params.node!.rowIndex;
        const colInfo = get(rowIndex, colDefs);
        switch (colInfo.renderer) {
          case 'image':
            return {
              component: 'imageCellRenderer',
            };
          case 'icon':
            let value = (params as BaseWithValueColDefParams).value;
            value = value && value[0] && value[0].value ? value[0].value.id : value;
            return {
              component: 'iconCellRenderer',
              params: {
                icon: colInfo.rendererIcon,
                value: value,
              },
            };
          case 'checkbox':
            const checkboxValue = params.data[params.colDef!.field!]
              ? params.data[params.colDef!.field!][FUNDED]
              : false;
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
                value: checkboxValue,
              },
            };
          case 'range_picker':
            return {
              component: 'rangePickerRenderer',
              params: colInfo,
            };
          case 'validValuesRenderer':
            const dataConfig = processApiParams(colInfo.dataApi, params.node!);
            return {
              component: 'validValuesRenderer',
              params: {
                dataConfig,
                asCsv: !isNil(colInfo.asCsv) && colInfo.asCsv,
              },
            };
          case 'validSizes':
            return {
              component: 'validSizesRenderer',
              params: {
                dataConfig: {
                  url: colInfo.dataApi.url,
                  params: mapValues(colInfo.dataApi.params, (v) => {
                    return styleColor[v];
                  }),
                  headers: colInfo.dataApi.headers,
                },
              },
            };
          default:
            return (null as unknown) as ComponentSelectorResult;
        }
      },
    };
  };
  createRow = (rowData: any, colDef: any) => {
    // setup observers if applicable
    const observed = get('observes', colDef); // get observed props from current colDef
    const observer = get('dataIndex', colDef);
    if (!isNil(observer) && !isNil(observed)) {
      this.observers[observed] = observer;
    }

    const returnData = this.state.config.reduce({
      headerText: colDef.text,
      dataKey: colDef.dataIndex,
      label: colDef.dataIndex,
      id: colDef.id ? colDef.id : colDef.dataIndex,
    });
    rowData.forEach((data: any) => {
      returnData[data.dataIndex] = rowData[data.dataIndex];
    });
    return returnData;
  };

  transposeRowsAndColumns(rowData: StoreData | null, colDefs: any) {
    if (isNil(rowData)) {
      return;
    }

    const firstIndex = Object.keys(rowData)[0];
    const newTransposedRowData: ColDef[] = colDefs.map((colDef: any) => {
      let returnObject: Record<string, any> = {};
      if (rowData[firstIndex] && rowData[firstIndex][0]) {
        // Normal parsing
        values(rowData).forEach((data: any) => {
          data = data[0];
          const dataReturn: Record<string, any> = {
            [colDef.dataIndex]: data[colDef.dataIndex],
            isFirstFloorset: colDef.dataIndex === FUNDED ? data.isFirstFloorset : undefined,
          };

          returnObject[data.time] = dataReturn;
        });
      } else {
        // Defaults parsing
        const dataReturn: Record<string, any> = {
          [colDef.dataIndex]: rowData[colDef.dataIndex],
        };
        returnObject = dataReturn;
      }
      return {
        headerText: colDef.text,
        dataKey: colDef.dataIndex,
        id: colDef.id ? colDef.id : colDef.dataIndex,
        label: colDef.dataIndex,
        ...returnObject,
      };
    });

    const tempTransposedData = Object.keys(rowData).map((key: string) => {
      return {
        id: key,
        name: key,
      };
    });

    const newData: any = tempTransposedData.map(partial(this.createColumn, [colDefs]));
    const newColData: ColDef[] = [
      {
        headerName: '',
        field: 'headerText',
        cellStyle: { 'font-size': 'large' },
        pinned: 'left',
      },
    ].concat(newData);
    const newStoreAttributes: string[] = [];
    colDefs.forEach((col: TenantConfigViewItem) => {
      if (attributesIgnore.indexOf(col.dataIndex) < 0) {
        newStoreAttributes.push(col.dataIndex);
      }
    });
    this.setState({
      config: colDefs,
      transposedData: newTransposedRowData,
      transposedDefs: newColData,
      storeAttributes: newStoreAttributes,
    });
  }

  handleCellValueChange = (params: AgGrid.CellValueChangedEvent) => {
    const { colDef, data, api: gridApi } = params;
    const { field } = colDef; // store field (dataKey) of modified column to know which data field to modify
    const { dataKey: observed } = data;
    const observerDataKey = this.observers[observed]; // get dataKey of row to clear

    if (!isNil(gridApi) && !isNil(observerDataKey)) {
      gridApi.forEachNode((node) => {
        if (node.data.dataKey === observerDataKey) {
          node.setDataValue(field as string, '');
        }
      });
    }
  };

  getFloorsetsToPropagate = (currentFloorset: string): string[] => {
    const { data } = this.props;

    if (isNil(data)) {
      return [];
    }

    const dataKeys = Object.keys(data);
    const indexOfCur = dataKeys.indexOf(currentFloorset);

    const listOfFloorsets: string[] = [];
    dataKeys.forEach((key: string, index: number) => {
      if (index >= indexOfCur) {
        listOfFloorsets.push(key);
      }
    });
    return listOfFloorsets;
  };

  saveAfterEdit = () => {
    this.forceUpdate();

    const rowData: any[] = [];
    this.gridApi.forEachLeafNode((node) => {
      rowData.push(node.data);
    });

    const newData = {};
    rowData.forEach((data) => {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        const newDataKeys = Object.keys(newData);
        if (isPlainObject(data[key])) {
          if (newDataKeys.indexOf(key) === -1) {
            newData[key] = data[key];
          } else {
            if (data[key][SPECIAL_STORE_GROUP]) {
              newData[key] = {
                ...newData[key],
                ssg: data[key][SPECIAL_STORE_GROUP],
              };
            } else {
              newData[key] = {
                ...newData[key],
                ...data[key],
              };
            }

            // replace 'undefined' with 'null' for submission to server
            forEach(newData[key], (value, subkey) => {
              if (isUndefined(value)) {
                newData[key][subkey] = null;
              }
            });
          }
        }
      });
    });

    this.props.getDataStore(mapValues(newData, removeColons));
    return newData;
  };

  render() {
    const { config } = this.state;
    const { floorset } = this.props;
    if (!config || !this.state.transposedData || isEmpty(this.state.transposedData)) {
      return <div className={styles.container} style={{ minHeight: '260px' }} />;
    }

    return (
      <div className={`${AgGridThemeClass} ${AgGridMaterialThemeNoBorders} ${styles.container}`}>
        <AgGridReact
          domLayout="autoHeight"
          rowHeight={35}
          rowData={this.state.transposedData}
          columnDefs={this.state.transposedDefs}
          singleClickEdit={true}
          components={this.nonFrameworkComponents}
          frameworkComponents={this.frameworkComponents}
          headerHeight={0}
          getRowNodeId={(data) => {
            return data.id;
          }}
          onGridReady={(params: AgGrid.GridReadyEvent) => {
            if (params.api && params.columnApi) {
              this.gridApi = params.api;
              this.columnApi = params.columnApi;
              const firstRow = params.api.getRowNode(this.state.config[0].dataIndex);
              firstRow.setRowHeight(50);
              params.api.onRowHeightChanged();

              // Scroll to selected floorset column
              params.columnApi.getAllColumns().forEach((col) => {
                if (floorset) {
                  const colId = col.getColId();
                  if (colId === floorset) {
                    this.gridApi.ensureColumnVisible(colId);
                  }
                }
              });

              // Set data in state of parent
              this.saveAfterEdit();
            }
          }}
          onCellEditingStopped={() => this.saveAfterEdit()}
          onCellValueChanged={this.handleCellValueChange}
        />
      </div>
    );
  }
}
