import React, { useCallback, useEffect, useRef, useState } from 'react';
import { pick } from 'lodash';
import HighchartsReact from 'highcharts-react-official';
import { Overlay } from 'src/common-ui/index';
import { Pivot, BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import {
  generateLineGraphConfig,
  generateBarGraphConfig,
  generateSecondaryGraphConfig,
} from 'src/components/Visualize/Visualize.utils';
import StylePreview from 'src/components/StylePreview/StylePreview';
import { StylePreviewData } from 'src/components/StylePreview/StylePreview.types';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { MacroSummaryHighlights } from 'src/components/MacroSummaryHighlights/MacroSummaryHighlights';
import {
  visualizeButtonContainer,
  visualizeContainer,
  visualizeGraphsContainer,
  lineGraphClassName,
  barGraphContainer,
  getVisualizeChartItem,
} from 'src/components/Visualize/Visualize.styles';
import { externalGridSearchFields, STYLE_COLOR_ID } from 'src/utils/Domain/Constants';
import { Metric } from 'src/common-ui/components/Metrics/CommonTypes';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { VisualizeStateProjection } from './Visualize.selector';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { SeriesLineOptions } from 'highcharts';

export type VisualizeStateProps = {
  loading?: boolean;
  data?: Pivot;
  scopeStart?: string;
  selectedItemPreviewData: StylePreviewData;
  subheaderSlice: SubheaderSlice;
  allStylecolorIds: string[];
  onSelectStyle: (id: string) => void;
  summaryData: Metric[] | undefined;
  bigGraphViewDefn: TenantConfigViewData | undefined;
  worklistItems: WorklistInfo[];
};

export type VisualizeProps = VisualizeStateProjection & VisualizeDispatchProps;

export type VisualizeDispatchProps = {
  onCompanionItemChange?: () => void;
  onShowView: () => void;
  refetchWorklist: () => void;
  onItemClicked: (item: ContainerPayload) => void;
};

export const viewDefnToButton = (
  clickHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  currentSelectedIndex: number,
  viewItem: TenantConfigViewItem,
  index: number
): JSX.Element => {
  return (
    <span
      className={getVisualizeChartItem(index === currentSelectedIndex)}
      key={index}
      id={viewItem.text}
      data-index={index}
      onClick={clickHandler}
    >
      {viewItem.text}
    </span>
  );
};

const barGraphToButton = (
  clickHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  currentSelectedIndex: number,
  seriesItem: any,
  index: number
): JSX.Element => {
  return (
    <span
      className={getVisualizeChartItem(index === currentSelectedIndex)}
      key={index}
      id={seriesItem.name}
      data-index={index}
      onClick={clickHandler}
    >
      {seriesItem.name}
    </span>
  );
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Visualize = (props: VisualizeProps) => {
  const {
    allStylecolorIds,
    selectedItemPreviewData,
    onCompanionItemChange,
    loading,
    data,
    onShowView,
    graphsViewDefn,
    primaryGraphData,
    primaryGraphCategories,
    dontFilterSwatches,
  } = props;

  const [selectedBigGraphItem, setSelectedBigGraphItem] = useState(0);
  const [selectedBarGraphItem, setSelectedBarGraphItem] = useState(0);

  useEffect(() => {
    onShowView();
  }, []);

  const prevSelected = usePrevious(selectedItemPreviewData);
  useEffect(() => {
    const selectedDifferent = prevSelected?.id !== selectedItemPreviewData.id;

    // this just copies selectedItemPreviewData to local state so changes to the id can be detected
    // this essentially replaces prevProps.id !== nextProps.id
    if (selectedDifferent && selectedItemPreviewData && onCompanionItemChange) {
      onCompanionItemChange();
    }
  }, [prevSelected, selectedItemPreviewData, onCompanionItemChange]);

  // memoized filter swatch callback
  const filterSwatches = useCallback(
    (items: BasicPivotItem[]) => {
      return items.filter((x) => allStylecolorIds.indexOf(x[STYLE_COLOR_ID]) != -1);
    },
    [allStylecolorIds]
  );

  const onLineGraphButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event.currentTarget.dataset.index) setSelectedBigGraphItem(parseFloat(event.currentTarget.dataset.index));
    },
    [setSelectedBigGraphItem]
  );
  const onBarGraphButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event.currentTarget.dataset.index) setSelectedBarGraphItem(parseFloat(event.currentTarget.dataset.index));
    },
    [setSelectedBarGraphItem]
  );
  const viewDefnToButtonApplied = viewDefnToButton.bind(null, onLineGraphButtonClick, selectedBigGraphItem);
  const barGraphToButtonApplied = barGraphToButton.bind(null, onBarGraphButtonClick, selectedBarGraphItem);

  const { subheaderSlice, summaryData } = props;
  let vizContent = <div />;

  if (!loading && data && data.tree && summaryData && graphsViewDefn && primaryGraphData && primaryGraphCategories) {
    const lineGraphConfig = generateLineGraphConfig(
      primaryGraphCategories,
      primaryGraphData,
      graphsViewDefn,
      selectedBigGraphItem
    );

    const barGraphSeries: SeriesLineOptions[] = graphsViewDefn.graphs!.secondary.map((series) =>
      generateSecondaryGraphConfig(series, data)
    );

    const barGraphConfig = generateBarGraphConfig(selectedBarGraphItem, barGraphSeries);
    vizContent = (
      <React.Fragment>
        <div className={visualizeGraphsContainer}>
          <section>
            <div className={visualizeButtonContainer}>
              {graphsViewDefn.graphs?.primary.map(viewDefnToButtonApplied)}
            </div>
            <HighchartsReact
              options={lineGraphConfig}
              containerProps={{ className: lineGraphClassName }}
              immutable={true}
            />
          </section>
          <section>
            <div style={{ paddingTop: 12, marginRight: 35 }} className={visualizeButtonContainer}>
              {barGraphSeries.map(barGraphToButtonApplied)}
            </div>
            <div className={barGraphContainer}>
              <HighchartsReact options={{ ...barGraphConfig, chart: { ...barGraphConfig.chart } }} />
            </div>
          </section>
        </div>
        <MacroSummaryHighlights metrics={summaryData} />
      </React.Fragment>
    );
  }

  const allContent = (
    <React.Fragment>
      <StylePreview
        readOnlyView={true}
        filters={pick(subheaderSlice, 'altSearch', 'altFlowStatus', 'sortBy')}
        worklistItems={props.worklistItems}
        searchKeys={externalGridSearchFields}
        previewData={selectedItemPreviewData}
        renderSections={false}
        sectionsViewDefns={['VisualizeStylePreview']}
        ignoreOverviewBackground={true}
        selectedStyleColorId={selectedItemPreviewData.id}
        filterSwatches={dontFilterSwatches ? undefined : filterSwatches}
        onRefetchWorklist={props.refetchWorklist}
        onShowStylePane={props.onItemClicked}
      />
      <div className={visualizeContainer}>{vizContent}</div>
    </React.Fragment>
  );
  return (
    <div>
      <Overlay type="loading" visible={loading || !data || !data.tree} />
      {selectedItemPreviewData && selectedItemPreviewData.id.length > 0 && allContent}
    </div>
  );
};

export default Visualize;
