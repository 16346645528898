import { CROPPED_IMG_MAX_WIDTH, CROPPED_IMG_MAX_HEIGHT } from 'src/utils/Domain/Constants';
import { CropperSelection } from './ImageCropper';
import { resolvePath } from 'src/cdn';

import noImagePath from 'src/common-ui/images/noimage.jpg';
const noImage = resolvePath(noImagePath);

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => {
      reject(error);
    });
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
export async function getCroppedImg(imageSrc: string, pixelCrop: CropperSelection) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = Math.min(pixelCrop.width, CROPPED_IMG_MAX_WIDTH);
  canvas.height = Math.min(pixelCrop.height, CROPPED_IMG_MAX_HEIGHT);
  const ctx = canvas.getContext('2d');

  ctx!.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, canvas.width, canvas.height);

  return b64toBlob(canvas.toDataURL('image/jpeg').split(',')[1], 'image/jpeg');
}

export async function getCroppedImgFromString(imageSrc: string) {
  const image = await createImage(imageSrc).catch(() => createImage(noImage));

  const canvas = document.createElement('canvas');
  const width = image.width;
  const height = image.height;
  canvas.width = Math.min(width, CROPPED_IMG_MAX_WIDTH);
  canvas.height = Math.min(height, CROPPED_IMG_MAX_HEIGHT);
  const ctx = canvas.getContext('2d');

  ctx!.drawImage(image, 0, 0, width, height, 0, 0, canvas.width, canvas.height);

  return b64toBlob(canvas.toDataURL('image/jpeg').split(',')[1], 'image/jpeg');
}
