import * as React from 'react';
import { List, ListProps, ListRowRenderer, AutoSizer, Size } from 'react-virtualized';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import FlatCard, { FlatCardProps } from './FlatCard/FlatCard';
import { companionListViewStyle, flatCardStyle, cardHeight, dropDownStyle } from './CompanionListView.styles';
import { KeyVal } from '../../common/object';
import { isNil, isEqual } from 'lodash';
import { CompanionDataLookup } from 'src/utils/Component/ListView';

export type SortOption = {
  text: string;
  dataIndex: string;
};

export type ScrollTo = {
  eventId: number;
  where: KeyVal<string, any>;
};

export type SortDirection = 'asc' | 'desc';

export type ListViewable = {
  id: string;
  name: string;
  stars: number | null;
  imageUri?: string;
  title?: string;
  [key: string]: any | undefined;
};

export type Props = {
  defaultSelection: number;
  sortOptions: SortOption[];
  data: ListViewable[];
  label: string;
  className: string;
  scrollTo?: ScrollTo;
  noImageUrl?: string;
  selectedIndex: number;
  searchComponent?: JSX.Element;
  filterComponent?: JSX.Element;
  initialSortDirection?: SortDirection;
  levelOptions?: SortOption[];
  defaultLevelSelection?: number;
  hoverListItemElement?: JSX.Element;
  isCollapsed?: boolean;
  dataLookup?: CompanionDataLookup;
  onListItemClicked?(id: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onSortSelection?(sortOption: SortOption): void;
  onChangeDirection?(direction: 'asc' | 'desc'): void;
  onToggleCollapse?(isCollapsed: boolean): void;
  onLevelSelection?(levelOption: SortOption): void;
};

export type State = {
  direction: SortDirection;
  dropdownOpen: boolean;
  isCollapsed: boolean;
  selection: number;
  levelSelection: number;
  dropdownLevelOpen: boolean;
};

export type NoRenderState = {
  lastScrolledTo?: number;
};

export default class CompanionListView extends React.Component<Props, State> {
  private noRenderState: NoRenderState;
  constructor(props: Props) {
    super(props);

    this.state = {
      direction: isNil(props.initialSortDirection) ? 'desc' : props.initialSortDirection,
      dropdownOpen: false,
      isCollapsed: props.isCollapsed || false,
      selection: props.defaultSelection,
      levelSelection: props.defaultLevelSelection || 0,
      dropdownLevelOpen: false,
    };
    this.noRenderState = {};
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.selectDropdown = this.selectDropdown.bind(this);
    this.toggleDirection = this.toggleDirection.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.defaultSelection != prevProps.defaultSelection) {
      this.setState({
        selection: this.props.defaultSelection,
      });
    }
    if (this.props.initialSortDirection != prevProps.initialSortDirection && this.props.initialSortDirection) {
      this.setState({
        direction: this.props.initialSortDirection,
      });
    }
    if (this.props.isCollapsed != prevProps.isCollapsed && !isNil(this.props.isCollapsed)) {
      this.setState({
        isCollapsed: this.props.isCollapsed,
      });
    }
    if (!isEqual(this.props.levelOptions, prevProps.levelOptions)) {
      this.setState({
        levelSelection: 0,
      });
    }
  }

  selectDropdown(selection: number) {
    this.setState({
      dropdownOpen: false,
      selection,
    });

    if (this.props.onSortSelection && this.props.sortOptions != null && selection > -1) {
      this.props.onSortSelection(this.props.sortOptions[selection]);
    }
  }

  selectLevelDropdown(selection: number) {
    this.setState({
      dropdownLevelOpen: false,
      levelSelection: selection,
    });

    if (this.props.onLevelSelection && this.props.levelOptions) {
      this.props.onLevelSelection(this.props.levelOptions[selection]);
    }
  }

  toggleCollapse() {
    const isCollapsed = !this.state.isCollapsed;
    this.setState({ isCollapsed });

    if (this.props.onToggleCollapse) {
      this.props.onToggleCollapse(isCollapsed);
    }
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  toggleDirection() {
    const direction = this.state.direction === 'asc' ? 'desc' : 'asc';
    this.setState({ direction });

    if (this.props.onChangeDirection) {
      this.props.onChangeDirection(direction);
    }
  }

  render() {
    const {
      sortOptions,
      levelOptions,
      data,
      className,
      onListItemClicked,
      selectedIndex,
      scrollTo,
      label = 'Count',
      searchComponent,
      filterComponent,
      dataLookup,
    } = this.props;
    const { levelSelection } = this.state;

    let scrollToIndex;

    if (scrollTo !== undefined && scrollTo.eventId !== this.noRenderState.lastScrolledTo) {
      const index = data.findIndex((datum) => datum[scrollTo.where.key] === scrollTo.where.value);

      if (index !== -1) {
        scrollToIndex = index;
      }
      this.noRenderState.lastScrolledTo = scrollTo.eventId;
    }

    const hideStars = dataLookup && dataLookup.starsId === null,
      noImageUrl = dataLookup && dataLookup.imageUrlId ? this.props.noImageUrl : '';

    const rowRenderer: ListRowRenderer = (props) => {
      const datum = data[props.index];
      if (hideStars) datum.stars = null;

      const cardClasses =
        flatCardStyle +
        (selectedIndex === props.index ? ' scroll-target' : '') +
        (this.state.isCollapsed ? ' collapsed' : '');

      const flatCardProps: FlatCardProps = {
        ...datum,
        noImageUrl,
        index: props.index,
        className: cardClasses,
        hoverElement: this.props.hoverListItemElement,
        cardHeight,
        onClick: onListItemClicked,
      };

      return (
        <div style={props.style} key={props.index}>
          <FlatCard {...flatCardProps} />
        </div>
      );
    };

    const listOptions: ListProps = {
      overscanRowCount: 10,
      rowHeight: cardHeight,
      rowRenderer,
      rowCount: data.length,
      tabIndex: -1,
      scrollToAlignment: 'start',
      scrollToIndex,
      // Must be overwritten by autosizer, this is just type error avoidance
      height: NaN,
      width: NaN,
    };

    const classes =
      companionListViewStyle +
      ' companion-list-view' +
      (className ? ` ${className}` : '') +
      (this.state.isCollapsed ? ' collapsed' : '');

    const directionClasses = 'sort-dir far ' + (this.state.direction === 'asc' ? ' fa-arrow-down' : ' fa-arrow-up');

    function renderList(size: Size) {
      const options = {
        ...listOptions,
        ...size,
      };
      return <List {...options} />;
    }

    const { isCollapsed } = this.state;
    const showSelectionText = !isCollapsed && this.props.sortOptions != null && this.state.selection >= 0;
    const controls = (
      <React.Fragment>
        <Dropdown className={dropDownStyle} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle caret={true} tag="div">
            {showSelectionText ? this.props.sortOptions[this.state.selection].text : ''}
          </DropdownToggle>
          <DropdownMenu>
            {sortOptions.map((option, index) => (
              <DropdownItem key={index} onClick={() => this.selectDropdown(index)}>
                {option.text}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        <span className={directionClasses} onClick={this.toggleDirection} />
      </React.Fragment>
    );

    const collapsedControls = isCollapsed ? controls : <span className="spacer" />;
    const expandControls = isCollapsed ? null : controls;
    let expandLevelControls;
    if (!isCollapsed && levelOptions) {
      expandLevelControls = (
        <div className="control-bar level-bar">
          <label>Level:</label>
          <Dropdown
            className={dropDownStyle}
            isOpen={this.state.dropdownLevelOpen}
            toggle={() => this.setState({ dropdownLevelOpen: !this.state.dropdownLevelOpen })}
          >
            <DropdownToggle caret={true} tag="div">
              {isCollapsed ? '' : levelOptions[levelSelection].text}
            </DropdownToggle>
            <DropdownMenu>
              {levelOptions.map((option, index) => (
                <DropdownItem key={index} onClick={() => this.selectLevelDropdown(index)}>
                  {option.text}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <span style={{ width: 17 }} />
        </div>
      );
    }

    return (
      <div className={classes}>
        <header>
          <div className="expander-container">
            {collapsedControls}
            <span className="expander" onClick={this.toggleCollapse}>
              &#60;
            </span>
          </div>
          <div>
            <span className="label">
              {label}: {data.length}
            </span>
          </div>
          <div className="extra-controls">
            {!isNil(searchComponent) && <div className="search">{searchComponent}</div>}
            {!isNil(filterComponent) && <div className="filter">{filterComponent}</div>}
          </div>
          <div className="control-bar">
            <label>Order By:</label>
            {expandControls}
          </div>
          {expandLevelControls}
        </header>
        <div className="list-container">
          <AutoSizer>{renderList}</AutoSizer>
        </div>
      </div>
    );
  }
}
