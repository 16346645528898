import { compact } from 'fp-ts/lib/Array';
import {
  BoundSection,
  BoundView,
  BoundTab,
  BoundTenant,
  PathSlotted,
  CanBeDisabled,
} from 'src/services/configuration/bindings.types';
import { Location } from 'history';
import { RouteLink } from 'src/types/RouteLink';
import { flatMap } from 'lodash/fp';
import { Option, fromNullable, chain, some, map, none, getOrElse, isSome } from 'fp-ts/lib/Option';
import { BoundViewZipper, BoundSectionZipper, BoundTabZipper } from 'src/pages/NavigationShell/NavigationZipper';
import { pipe } from 'fp-ts/lib/function';
import { isNil } from 'lodash';

export function lookupDefaultView(section: BoundSection): Option<BoundView> {
  return fromNullable(section.boundViews.find((view) => view.id === section.defaultViewId));
}

export function lookupDefaultSection(tab: BoundTab): Option<BoundSection> {
  return !isNil(tab.boundSections)
    ? fromNullable(tab.boundSections.find((section) => section.id === tab.defaultSectionId))
    : none; // disabled tabs will not have any bound sections
}

export function computeTabPath(tab: BoundTab): string {
  const tabSlot = tab.pathSlot;
  const section = lookupDefaultSection(tab);
  const view = pipe(section, chain(lookupDefaultView));
  return compact([
    some(tabSlot),
    pipe(
      section,
      map((s) => s.pathSlot)
    ),
    pipe(
      view,
      map((v) => v.pathSlot)
    ),
  ]).join('/');
}

export function getSlot(s: PathSlotted): string {
  return s.pathSlot;
}

export function getDefaultView(section: BoundSection): Option<BoundView> {
  return fromNullable(section.boundViews.find((view) => view.id === section.defaultViewId));
}

export function getDefaultSection(tab: BoundTab): Option<BoundSection> {
  return fromNullable(tab.boundSections!.find((section) => section.id === tab.defaultSectionId));
}

export function getDefaultTab(perspectiveUi: BoundTenant): Option<BoundTab> {
  return fromNullable(perspectiveUi.boundTabs.find((tab) => tab.pathSlot === perspectiveUi.defaultTabSlot));
}

export function computePerspectiveUiRouteSuffix(perspectiveUi: BoundTenant): string {
  const prefix = '/' + getSlot(perspectiveUi.perspective);
  const tab = getDefaultTab(perspectiveUi);
  const rest = pipe(
    tab,
    map(computeTabPath),
    map((x) => '/' + x),
    getOrElse(() => '')
  );
  return prefix + rest;
}

export function computePerspectiveRoute(perspectiveUi: BoundTenant): string {
  return computePerspectiveUiRouteSuffix(perspectiveUi);
}

export function computeTabRouteLinks(defn: BoundTenant): RouteLink[] {
  return defn.boundTabs.map((tab) => ({
    name: tab.name,
    link: `/${getSlot(defn.perspective)}/${computeTabPath(tab)}`,
    highlightPrefix: `/${getSlot(defn.perspective)}/${tab.pathSlot}`,
    disabled: tab.disabled,
    pathSlot: tab.pathSlot,
  }));
}

export function computeViewRoutesForSection(defn: BoundTenant, tab: BoundTab, section: BoundSection): RouteLink[] {
  return section.boundViews.map((view) => ({
    name: view.name,
    disabled: view.disabled,
    link: '/' + [getSlot(defn.perspective), getSlot(tab), getSlot(section), getSlot(view)].join('/'),
    highlightPrefix: '/' + [getSlot(defn.perspective), getSlot(tab), getSlot(section), getSlot(view)].join('/'),
  }));
}

export function isNotDisabled(v: CanBeDisabled) {
  return !v.disabled;
}

export function computeViewRoutesForTab(defn: BoundTenant, tab: BoundTab): RouteLink[] {
  return flatMap(
    (section) => computeViewRoutesForSection(defn, tab, section),
    tab.boundSections?.filter(isNotDisabled)
  );
}

export function computeViewRouteLinks(defn: BoundTenant): RouteLink[] {
  return flatMap((tab) => computeViewRoutesForTab(defn, tab), defn.boundTabs.filter(isNotDisabled));
}

export type NavPath = Readonly<[string, string, string, string]>;

export const extractNavPathFromString = (path: string): Option<NavPath> => {
  const parts = path.split('/').slice(1); // slice drops initial '' (or # when searching hashpath) produced by the leading slash
  if (parts.length < 4) {
    // TargetSetting does 'custom' handling of window.location
    // which adds an extra element to the array when it comes here
    // Let elements beyond 4 pass through and parse, but drop them in the return below
    return none;
  }
  return some([parts[0], parts[1], parts[2], parts[3]] as NavPath);
};
export function extractNavPath(location: Location, searchKey: 'pathname' | 'hash' = 'pathname'): Option<NavPath> {
  // hash key searching only used for initial state hydration
  return extractNavPathFromString(location[searchKey]);
}

export function maybeAppendTrailingSlash(newUrl: string) {
  if (newUrl.substr(newUrl.length - 1) !== '/') {
    return newUrl + '/';
  }
  return newUrl;
}

export function getTabForPath(tenant: Pick<BoundTenant, 'boundTabs'>, navPath: NavPath): Option<BoundTab> {
  const pathSlot = navPath[1];
  return fromNullable(tenant.boundTabs.find((tab) => tab.pathSlot === pathSlot));
}

export function getSectionForPath(tab: Pick<BoundTab, 'boundSections'>, navPath: NavPath): Option<BoundSection> {
  const pathSlot = navPath[2];
  return fromNullable(tab.boundSections!.find((section) => section.pathSlot === pathSlot));
}

export function getViewForPath(tenant: BoundTenant, navPath: NavPath): Option<BoundView> {
  const pathSlot = navPath[3];
  const tab = getTabForPath(tenant, navPath);
  if (isSome(tab)) {
    const section = getSectionForPath(tab.value, navPath);
    if (isSome(section)) {
      return fromNullable(section.value.boundViews.find((v) => v.pathSlot === pathSlot));
    }
  }
  return none;
}

export function computeTabZipRoute(tabZip: Readonly<BoundTabZipper>): string {
  return '/' + [tabZip.getParent().getTenant().perspective.pathSlot, tabZip.getTab().pathSlot].join('/');
}

export function computeSectionZipRoute(sectionZip: Readonly<BoundSectionZipper>): string {
  return [computeTabZipRoute(sectionZip.getParent()), sectionZip.getSection().pathSlot].join('/');
}

export function computeViewZipRoute(viewZip: Readonly<BoundViewZipper>): string {
  return [computeSectionZipRoute(viewZip.getParent()), viewZip.getView().pathSlot].join('/');
}
