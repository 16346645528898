import { style, media } from 'typestyle';
import { smallFont, veryFaintNeutral, mediumLargeFont, mediumBreakpoint } from 'src/utils/Style/Theme';

const cardHolder = 'carousel-card-holder';
const card = 'carousel-card';

const DEFAULT_PRODUCTIVITY_MARGIN = 200;
export const footerHeight = 250;

export const styles = {
  cardsContainer: style({
    flex: 1,
    $nest: {
      [`.${cardHolder}`]: {
        width: '20%',
        height: '33%',
        float: 'left',
        border: '1px solid white',
        $nest: {
          [`.${card}`]: {
            float: 'left',
            border: 'none',
            backgroundColor: veryFaintNeutral,
            height: '100%',
            width: '100%',
            boxShadow: 'none',
            transform: 'none',
            transition: 'none',
            background: 'none',
            padding: '5px 10px',
            $nest: {
              '.left-pane': {
                padding: 0,
                $nest: {
                  '.title': {
                    fontSize: smallFont,
                  },
                  '.primary': {
                    fontSize: mediumLargeFont,
                  },
                },
              },
              '.right-pane': {
                padding: 0,
                fontSize: smallFont,
              },
            },
          },
        },
      },
    },
  }),
  graphContainer: style(
    media(
      { minWidth: mediumBreakpoint },
      {
        marginLeft: DEFAULT_PRODUCTIVITY_MARGIN,
        marginRight: DEFAULT_PRODUCTIVITY_MARGIN,
      }
    ),
    {
      height: `calc(100% - ${footerHeight}px)`,
      marginLeft: 0,
      marginRight: 0,
    }
  ),
  down: style({
    marginTop: -10,
    fontSize: '1rem',
  }),
  up: style({
    marginBottom: -10,
    fontSize: '1rem',
  }),
  cardHolder,
  card,
};
