import { TenantConfigViewItem, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { Pivot, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';

export type Action =
  | { type: 'GEOTRENDS_REQUEST_TENANT_CONFIG' }
  | { type: 'GEOTRENDS_RECEIVE_TENANT_CONFIG'; config: TenantResponse }
  | { type: 'GEOTRENDS_RECEIVE_ERROR'; message: string }
  | { type: 'GEOTRENDS_REQUEST_GEO_DATA' }
  | { type: 'GEOTRENDS_RECEIVE_GEO_DATA'; data: BasicPivotItem[]; selectedGeoLevel: TenantConfigViewItem }
  | { type: 'GEOTRENDS_REQUEST_CHART_DATA' }
  | { type: 'GEOTRENDS_RECEIVE_CHART_DATA'; data: BasicPivotItem[] }
  | { type: 'SELECT_POINT'; selectedItem?: ExtendedPointObject }
  | { type: 'SELECT_GEO_LEVEL'; selectedGeoLevel: TenantConfigViewItem }
  | { type: 'GEOTRENDS_RECEIVE_GEO_DATA_ERROR'; message: string }
  | { type: 'GEOTRENDS_CLEAN_UP' };

export const REQUEST_TENANT_CONFIG = 'GEOTRENDS_REQUEST_TENANT_CONFIG';
export function requestTenantConfig(): Action {
  return {
    type: REQUEST_TENANT_CONFIG,
  };
}

export const RECEIVE_TENANT_CONFIG = 'GEOTRENDS_RECEIVE_TENANT_CONFIG';
export type TenantResponse = {
  levelSelectConfig?: GeoTrendsConfigData;
  topChartConfig?: TenantConfigViewItem;
  bottomChartConfig?: TenantConfigViewItem;
};
export function receiveTenantConfig(config: TenantResponse): Action {
  return {
    type: RECEIVE_TENANT_CONFIG,
    config,
  };
}

export const REQUEST_GEO_DATA = 'GEOTRENDS_REQUEST_GEO_DATA';
export function requestGeoData(): Action {
  return {
    type: REQUEST_GEO_DATA,
  };
}

export const RECEIVE_GEO_DATA = 'GEOTRENDS_RECEIVE_GEO_DATA';
export function receiveGeoData(data: Pivot, selectedGeoLevel: TenantConfigViewItem): Action {
  return {
    type: RECEIVE_GEO_DATA,
    data: data.tree,
    selectedGeoLevel: selectedGeoLevel,
  };
}

export const RECEIVE_ERROR = 'GEOTRENDS_RECEIVE_ERROR';
export function receiveError(message: string): Action {
  return {
    type: RECEIVE_ERROR,
    message,
  };
}

export const GEOTRENDS_REQUEST_CHART_DATA = 'GEOTRENDS_REQUEST_CHART_DATA';
export function requestChartData(): Action {
  return {
    type: GEOTRENDS_REQUEST_CHART_DATA,
  };
}

export const GEOTRENDS_RECEIVE_CHART_DATA = 'GEOTRENDS_RECEIVE_CHART_DATA';
export function receiveChartData(data: BasicPivotItem[]): Action {
  return {
    type: GEOTRENDS_RECEIVE_CHART_DATA,
    data: data,
  };
}

export const SELECT_POINT = 'SELECT_POINT';
export function selectPoint(selectedItem?: ExtendedPointObject): Action {
  return {
    type: SELECT_POINT,
    selectedItem,
  };
}

export const SELECT_GEO_LEVEL = 'SELECT_GEO_LEVEL';
export function selectGeoLevel(selectedGeoLevel: TenantConfigViewItem): Action {
  return {
    type: SELECT_GEO_LEVEL,
    selectedGeoLevel,
  };
}

export const CLEAN_UP = 'GEOTRENDS_CLEAN_UP';
export function cleanUp(): Action {
  return {
    type: CLEAN_UP,
  };
}

export const RECEIVE_GEO_DATA_ERROR = 'GEOTRENDS_RECEIVE_GEO_DATA_ERROR';
export function receiveGeoDataError(message: string): Action {
  return {
    type: RECEIVE_GEO_DATA_ERROR,
    message,
  };
}
