import React, { ReactElement } from 'react';
import cn from 'classnames';
import { ColDef, ProcessCellForExportParams, GridApi, GridReadyEvent, ColumnApi } from 'ag-grid-community';

import ChoiceProductivity, {
  Props as ChoiceProductivityProps,
} from 'src/common-ui/components/Metrics/SimpleMetrics/ChoiceProductivity/ChoiceProductivity';
import KeyFinancial, {
  Props as KeyFinancialProps,
} from 'src/common-ui/components/Metrics/SimpleMetrics/KeyFinancial/KeyFinancial';
import { Overlay } from 'src/common-ui';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { DataGridProps, Gridable } from 'src/common-ui/components/DataGrid/DataGrid';

import { PassedProps as SubheaderProps } from 'src/components/Subheader/Subheader';
import Subheader from 'src/components/Subheader/Subheader.container';

import { categorySummaryStyle, choiceProductivityStyle, keyFinancialStyle } from './CategorySummary.styles';
import { StateProjection } from './CategorySummary.selectors';
import { CustomNoRowsOverlay } from 'src/components/ConfigurableGrid/ConfigurableGrid';
import { isNumber, differenceWith, isEqual } from 'lodash';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { multiHeaderDecorate } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/NestedHeader';

export type PerformanceDetails = {
  columnDefs: ColDef[];
  data: Gridable[];
};

type CategorySummaryProps = {
  groupByDefn: string;
};

export type FunctionProps = {
  onShowView(): void;
  onConfigUpdate(config: TenantConfigViewData): void;
};

export type Props = StateProjection & CategorySummaryProps & FunctionProps;

export type State = {
  isKeyFinancialsOpen: boolean;
  isChoiceProductivityOpen: boolean;
};

export default class CategorySummary extends React.Component<Props, State> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  constructor(props: Props) {
    super(props);

    this.state = {
      isKeyFinancialsOpen: true,
      isChoiceProductivityOpen: true,
    };

    this.toggleKeyFinancials = this.toggleKeyFinancials.bind(this);
    this.toggleChoiceProductivity = this.toggleChoiceProductivity.bind(this);
    this.togglePerformanceDetails = this.togglePerformanceDetails.bind(this);
  }
  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.loaded &&
      !prevProps.noData &&
      this.props.loaded &&
      !this.props.noData &&
      this.gridApi &&
      differenceWith(prevProps.colDefs, this.props.colDefs, isEqual)
    ) {
      this.gridApi.setColumnDefs(multiHeaderDecorate(this.props.colDefs));
    }
  }

  toggleKeyFinancials() {
    this.setState({
      isKeyFinancialsOpen: !this.state.isKeyFinancialsOpen,
    });
  }

  toggleChoiceProductivity() {
    this.setState({
      isChoiceProductivityOpen: !this.state.isChoiceProductivityOpen,
    });
  }

  togglePerformanceDetails() {
    const isExpand = this.state.isChoiceProductivityOpen || this.state.isKeyFinancialsOpen;

    this.setState({
      isChoiceProductivityOpen: !isExpand,
      isKeyFinancialsOpen: !isExpand,
    });
  }

  render() {
    let mainContent: ReactElement | null = null;

    const { isKeyFinancialsOpen, isChoiceProductivityOpen } = this.state;
    const { title, groupByDefn, viewDataState } = this.props;

    const subheaderProps: SubheaderProps = {
      title,
      showFlowStatus: true,
      showSearch: true,
      groupByDefn,
      viewDataState,
    };

    if (this.props.loaded && !this.props.noData) {
      const {
        keyFinancials,
        gridData,
        colDefs,
        frameworkComponents,
        isPrintMode,
        choiceProductivity,
        unmodifiedViewDefn,
        viewDefn,
        onConfigUpdate,
      } = this.props;
      subheaderProps.viewConfigurator = {
        viewConfig: viewDefn,
        unmodifiedViewDefn,
        updateConfig: onConfigUpdate,
        isGrid: true,
        getColumnApi: () => {
          if (this.columnApi) {
            return this.columnApi;
          }
          return;
        },
      };

      const gridOptions: DataGridProps = {
        isPrintMode,
        columnDefs: colDefs,
        frameworkComponents,
        data: gridData,
        loaded: true,
        rowHeight: 30,
        treeColumnDefinition: undefined,
        onGridReady: (event: GridReadyEvent) => {
          if (event.api && event.columnApi) {
            this.gridApi = event.api;
            this.columnApi = event.columnApi;
          }
        },
        exportOptions: {
          fileName: title,
          processCellOverride: (params: ProcessCellForExportParams) => {
            // NaN values are presented as strings 'NaN'
            const override = (isNaN(params.value) && isNumber(params.value)) || params.value === 'NaN';
            return !override ? params.value : '0.00';
          },
        },
      };

      const keyFinancialsClasses = cn('items', { collapsed: !isKeyFinancialsOpen });
      const keyFinancialsExpanderClasses = `far fa-fw fa-chevron-${isKeyFinancialsOpen ? 'up' : 'down'}`;

      const choiceProductivityItemsClasses = cn('items', { collapsed: !isChoiceProductivityOpen });
      const choiceProductivityExpanderClasses = `far fa-fw fa-chevron-${isChoiceProductivityOpen ? 'up' : 'down'}`;

      const [firstChoice, ...choiceProdItems] = choiceProductivity;

      mainContent = (
        <React.Fragment>
          <section className="key-financials">
            <header className="toggle" onClick={this.toggleKeyFinancials}>
              <i className={keyFinancialsExpanderClasses} />
              Key Financials
            </header>
            <div className={keyFinancialsClasses}>
              {keyFinancials.map((item: KeyFinancialProps, i) => {
                item.extraClasses = keyFinancialStyle;
                return <KeyFinancial {...item} key={i} />;
              })}
            </div>
          </section>
          <section className={cn('choice-productivity-group', { print: isPrintMode })}>
            <header className="toggle" onClick={this.toggleChoiceProductivity}>
              <i className={choiceProductivityExpanderClasses} />
              Choice Productivity
            </header>
            <div className={choiceProductivityItemsClasses}>
              <div className="fin-wrapper">
                <KeyFinancial {...firstChoice} />
              </div>
              <div className="choice-wrapper">
                {choiceProdItems.map((item: ChoiceProductivityProps, i) => {
                  item.extraClasses = choiceProductivityStyle;
                  return <ChoiceProductivity key={i} {...item} />;
                })}
              </div>
            </div>
          </section>
          <section className={cn('grid-container', { print: isPrintMode })}>
            <header className="toggle no-select">
              <i className={'far fa-fw'} />
              Performance Details
            </header>
            <ExtendedDataGrid {...gridOptions} />
          </section>
        </React.Fragment>
      );
    } else if (this.props.noData) {
      mainContent = <CustomNoRowsOverlay />;
    }

    const classes = 'category-summary ' + categorySummaryStyle(isKeyFinancialsOpen, isChoiceProductivityOpen);

    return (
      <div className={classes}>
        <Overlay type="loading" visible={!this.props.loaded} />
        <Subheader {...subheaderProps} />
        {mainContent}
      </div>
    );
  }
}
