import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction as BaseAction } from 'redux';
import { TenantConfigViewItem, ViewDefnState } from 'src/dao/tenantConfigClient';
import { AppType, ViewDataState } from 'src/types/Domain';
import { AppState, AppThunkDispatch } from 'src/store';
import service from 'src/ServiceContainer';
import Axios from 'src/services/axios';
import { findIndex, get, isEmpty, isNil, isString, reject } from 'lodash';
import { DataApiConfig } from 'src/services/configuration/codecs/confdefnView';
import { getLocalConfig } from '../ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { ConfigurableGridOwnProps } from './ConfigurableGrid.types';
import { BasicItem, BasicPivotItem, Pivot } from 'src/worker/pivotWorker.types';
import { parseFloorsetDropdownData } from './ConfigurableGrid.utils';

export type ConfigurableGridGroupBySelection = {
  selectedIndex: number;
  option: TenantConfigViewItem;
};

export type ConfigurableGridSlice = {
  viewDefnState: ViewDefnState;
  floorsetDataState: ViewDataState;
  gridDataState: ViewDataState;
  unmodifiedViewDefn: any;
  viewDefn: any;
  gridData: BasicPivotItem[];
  topAttributesData: BasicPivotItem | undefined;
  floorsetData: BasicItem[];
  groupBySelection: ConfigurableGridGroupBySelection | undefined;
  floorsetSelection: TenantConfigViewItem | undefined;
};

const initialState: ConfigurableGridSlice = {
  viewDefnState: ViewDefnState.idle,
  floorsetDataState: ViewDataState.idle,
  gridDataState: ViewDataState.idle,
  unmodifiedViewDefn: undefined,
  viewDefn: undefined,
  gridData: [],
  topAttributesData: undefined,
  floorsetData: [],
  groupBySelection: undefined,
  floorsetSelection: undefined,
};

const configurableGridSlice = createSlice({
  name: 'ConfigurableGrid',
  initialState,
  reducers: {
    requestConfigurableGridConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveConfigurableGridConfig(state, action: PayloadAction<{ viewDefn: any; unmodifiedViewDefn: any }>) {
      state.viewDefnState = ViewDefnState.loaded;
      state.unmodifiedViewDefn = action.payload.unmodifiedViewDefn;
      state.viewDefn = action.payload.viewDefn;
    },
    updateConfigurableGridConfig(state, action: PayloadAction<any>) {
      state.viewDefn = action.payload;
    },
    requestGridData(state) {
      state.gridDataState = ViewDataState.regularDataLoading;
    },
    receiveGridData(
      state,
      action: PayloadAction<{ gridData: BasicPivotItem[]; topAttributesData: BasicPivotItem | undefined }>
    ) {
      state.gridDataState = ViewDataState.regularDataReady;
      state.gridData = action.payload.gridData;
      state.topAttributesData = action.payload.topAttributesData;
    },
    requestFloorsetData(state) {
      state.floorsetDataState = ViewDataState.regularDataLoading;
    },
    receiveFloorsetData(state, action: PayloadAction<BasicItem[]>) {
      state.floorsetDataState = ViewDataState.regularDataReady;
      state.floorsetData = action.payload;
    },
    setGroupBySelection: (state, action: PayloadAction<ConfigurableGridGroupBySelection>) => {
      state.groupBySelection = action.payload;
    },
    resetGroupBySelection: (state) => {
      state.groupBySelection = undefined;
    },
    setFloorsetSelection: (state, action: PayloadAction<TenantConfigViewItem>) => {
      state.floorsetSelection = action.payload;
    },
    refreshConfigurableGridData: () => {
      // nothing to do here, this action triggers refetch in epic
    },
    receiveError: () => {
      return initialState;
    },
    cleanUp: () => {
      return initialState;
    },
  },
});

export const {
  requestConfigurableGridConfig,
  receiveConfigurableGridConfig,
  updateConfigurableGridConfig,
  requestGridData,
  receiveGridData,
  requestFloorsetData,
  receiveFloorsetData,
  setGroupBySelection,
  resetGroupBySelection,
  setFloorsetSelection,
  refreshConfigurableGridData,
  receiveError,
  cleanUp,
} = configurableGridSlice.actions;

export function fetchConfigurableGridConfigs(configApi: DataApiConfig) {
  return async (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    const defnId = get(configApi.params, 'defnId', '_CONFIGGRID_MISSINGDEFNID_');
    const appName = get(configApi.params, 'appName', 'Assortment') as AppType;
    const configResponse = await service.tenantConfigClient.getTenantViewDefnsWithFavorites({
      defnIds: [defnId],
      appName,
    });
    // TODO: type response, then fix slice type as well
    let config: any = configResponse[0];
    const localConfig = getLocalConfig(defnId, (configResponse as any)[1], dispatch);
    if (localConfig && localConfig.config && (localConfig.config.columns || localConfig.config.view)) {
      config = localConfig.config;
    }

    if (isNil(config.columns)) {
      // FIXME: dispatch receiveError with an invalid config error
      // config.columns is no longer valid and should be fixed when detected
      console.log('Error: Invalid config detected.');
      return;
    }

    dispatch(receiveConfigurableGridConfig({ unmodifiedViewDefn: configResponse[0], viewDefn: config }));
  };
}

export function fetchFloorsetData(floorsetApi: DataApiConfig | undefined) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    const subheaderDropdownConfig = getState().pages.assortmentBuild.configurableGrid.viewDefn.subheaderDropdowns;

    if (isNil(subheaderDropdownConfig)) {
      service.loggingService.error('Config needs to be fetched before dropdown data');
      return;
    } else if (isNil(floorsetApi)) {
      // if there is no magic floorset dropdown, skip the rest of this and let the function go to
      // fetchConfigurableGridData(), where we will attempt to make a listData call without
      // sending in the floorset information
      dispatch(refreshConfigurableGridData());
      return;
    }

    dispatch(requestFloorsetData());

    const { url: floorsetUrl, headers: floorsetHeaders, params: floorsetParams } = floorsetApi;
    const floorsetOptions = {
      headers: floorsetHeaders,
      params: floorsetParams,
    };

    const floorsets = await Axios.get(floorsetUrl, floorsetOptions).then((resp) => resp.data.data as BasicItem[]);
    dispatch(receiveFloorsetData(floorsets));
  };
}

export function fetchConfigurableGridData(ownProps: ConfigurableGridOwnProps) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    const scope = getState().scope;
    const { floorsetData, floorsetSelection } = getState().pages.assortmentBuild.configurableGrid;
    const { dataApi, topAttributesApi, topMembers = undefined } = ownProps;
    let floorsetId: string | undefined;
    if (!isEmpty(floorsetData)) {
      const options = parseFloorsetDropdownData(floorsetData);
      const floorsetIndex = isNil(floorsetSelection)
        ? 0
        : findIndex(options, (option) => option.text === floorsetSelection.text);
      floorsetId = options[floorsetIndex].id;
    }

    dispatch(requestGridData());

    const aggBy = dataApi.params?.aggBy;

    let formattedDataProm: Promise<BasicPivotItem[]>;

    // ConfigGrid supports toplevel api calls in both /floorset and /listData mode
    // /floorset requires the floorset list be called first and sent in as a topMember
    // while listData works differently
    if (dataApi.isListData === true) {
      const listDataTopMembers = reject([topMembers, floorsetId], isNil).join(',');
      formattedDataProm = service.pivotService
        .listData(dataApi.defnId, 'Assortment', {
          ...dataApi.params,
          aggBy,
          nestData: false, // we require flat responses for ConfigurableGrid
          topMembers: listDataTopMembers,
        })
        .then((resp) => resp.tree);
    } else {
      const { url: dataUrl, headers: dataHeaders, params: dataParams = {} } = dataApi;
      const dataOptions = {
        headers: dataHeaders,
        params: {
          ...dataParams,
          aggBy,
          floorsetId,
          topMembers,
        },
      };
      formattedDataProm = Axios.get(dataUrl, dataOptions)
        .then(async (resp) => {
          const dataResponse = resp.data;
          return isString(dataResponse) ? await service.pivotService.deserialize(dataResponse) : dataResponse.list;
        })
        .then((formattedData: BasicPivotItem[]) => {
          // If we are dealing with a nested set, first level must be single item, gets second level
          // (/floorset returns [{channel: ..., children: [<row_records>]}])
          if (formattedData && formattedData[0] && formattedData[0].children && formattedData[0].children.length > 0) {
            return formattedData[0].children;
          } else {
            return formattedData;
          }
        });
    }

    // Add in topAttributes call for floorset
    let topAttributesProm: Promise<BasicPivotItem | undefined> = Promise.resolve(undefined);
    if (topAttributesApi && topAttributesApi.params && topAttributesApi.params.defnId) {
      const { appName } = topAttributesApi.params;
      const topMembers = [floorsetId];
      scope.selections.productMember != null ? topMembers.push(scope.selections.productMember) : null;
      scope.selections.locationMember != null ? topMembers.push(scope.selections.locationMember) : null;
      topAttributesProm = service.pivotService
        .listData(topAttributesApi.params.defnId, appName as AppType, {
          topMembers: topMembers.join(','),
        })
        .then((topAttributesInformation: Pivot) => {
          return topAttributesInformation.tree[0];
        });
    }
    const [formattedData, topAttributes] = await Promise.all([formattedDataProm, topAttributesProm]);

    // Trim whitespace from the values within formattedData
    formattedData.forEach((attr: BasicPivotItem) => {
      Object.keys(attr).map((k) => (isString(attr[k]) ? (attr[k] = attr[k].trim()) : (attr[k] = attr[k])));
    });

    dispatch(receiveGridData({ gridData: formattedData, topAttributesData: topAttributes }));
  };
}

export default configurableGridSlice.reducer;
