import { TenantConfigViewData } from '../../../../dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export const REQUEST_TENANT_CONFIG = 'STOREGROUPCHART_REQUEST_TENANT_CONFIG';
export function requestTenantConfig(): Action {
  return {
    type: REQUEST_TENANT_CONFIG,
  };
}

export const RECEIVE_TENANT_CONFIG = 'STOREGROUPCHART_RECEIVE_TENANT_CONFIG';
export function receiveTenantConfig(config: TenantConfigViewData): Action {
  return {
    type: RECEIVE_TENANT_CONFIG,
    config,
  };
}

export const SET_SELECTED_ITEM = 'STOREGROUPCHART_SET_SELECTED_ITEM';
export function setSelectedItem(item: BasicPivotItem): Action {
  return {
    type: SET_SELECTED_ITEM,
    item,
  };
}

export const REQUEST_ALL_DATA = 'STOREGROUPCHART_REQUEST_DATA';
export function requestAllData(): Action {
  return {
    type: REQUEST_ALL_DATA,
  };
}

export const RECEIVE_ALL_DATA = 'STOREGROUPCHART_RECEIVE_DATA';
export type TenantResponse = {
  title: string;
  data: BasicPivotItem[];
};
export function receiveAllData(allData: TenantResponse[]): Action {
  return {
    type: RECEIVE_ALL_DATA,
    allData,
  };
}

export const RECEIVE_DATA_ERROR = 'STORGROUPCHART_RECEIVE_DATA_ERROR';
export function receiveDataError(error: string): Action {
  return {
    type: RECEIVE_DATA_ERROR,
    error,
  };
}

export const CLEAN_UP = 'STORGROUPCHART_CLEAN_UP';
export function cleanUp(): Action {
  return {
    type: CLEAN_UP,
  };
}

export type Action =
  | { type: typeof REQUEST_TENANT_CONFIG }
  | { type: typeof SET_SELECTED_ITEM; item: BasicPivotItem }
  | { type: typeof RECEIVE_TENANT_CONFIG; config: TenantConfigViewData }
  | { type: typeof RECEIVE_ALL_DATA; allData: TenantResponse[] }
  | { type: typeof REQUEST_ALL_DATA }
  | { type: typeof CLEAN_UP }
  | { type: typeof RECEIVE_DATA_ERROR; error: string };
