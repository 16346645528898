import { style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';

const hoverElementStyles: NestedCSSProperties = {
  position: 'absolute',
  background: 'rgba(221, 221, 221, 0.9)',
  display: 'none',
};

const IMG_WIDTH = 110;
const IMG_HEIGHT = '100%';
const flatCard = style({
  position: 'relative',
  fontSize: '0.5rem',
  display: 'flex',
  flexDirection: 'row',
  $nest: {
    '&:hover': {
      $nest: {
        '.hoverElement': {
          ...hoverElementStyles,
          display: 'flex',
          height: 'inherit',
          width: 'inherit',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '1.5rem',
          fontVariant: 'all-petite-caps',
          zIndex: 1,
        },
      },
    },
    '&': {
      $nest: {
        '.hoverElement': {
          ...hoverElementStyles,
        },
      },
    },
    '.id': {
      fontSize: '0.8rem',
    },
    '.name': {
      fontSize: '0.9rem',
    },
    '.left-pane': {
      display: 'flex',
      padding: '1rem',
      flexGrow: 1,
      justifyContent: 'space-around',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    img: {
      padding: '0 1rem .2rem',
    },
    '&.collapsed': {
      $nest: {
        '.left-pane': {
          display: 'none',
        },
      },
    },
  },
});
const imgContainer = style({
  flexShrink: 0,
});
export { IMG_HEIGHT, IMG_WIDTH, flatCard, imgContainer };
