import { classes, style } from 'typestyle';
// Do not change from relative path until fixed.
// Issues with Jest prevent absolute paths from working.
import { TEAL_PRIMARY } from '../../utils/Style/Theme';

const validOptions = style({
  $debugName: 'validOptions',
  padding: 5,
  display: 'flex',
  flexWrap: 'wrap',
});
const validOption = style({
  $debugName: 'validOption',
  padding: 5,
  borderRadius: 5,
  border: 'solid 1px #dbdbdb',
  margin: '3px',
  textAlign: 'center',
  fontSize: '12px',
  $nest: {
    '&:focus': {
      borderColor: TEAL_PRIMARY,
    },
  },
});

const disabledOption = style({
  backgroundColor: '#dddddd',
  color: '#b7b7b7',
});

const interactiveOption = style({
  cursor: 'pointer',
});

const buildValidOptionStyle = (selected: boolean, editable: boolean) => {
  let possibleDisabled, possibleInteractive;
  if (!selected) {
    possibleDisabled = disabledOption;
  }
  if (editable) {
    possibleInteractive = interactiveOption;
  }
  return classes(validOption, possibleDisabled, possibleInteractive);
};

export default {
  validOption,
  validOptions,
  disabledOption,
  buildValidOptionStyle,
};
