import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import RightContainer, { RightContainerProps } from './RightContainer';
import { RightContainerSlice, ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import {
  setRightContainerPayload,
  cleanUpRightContainer,
  closeRightContainer,
  openRightContainer,
  clearAndCloseRightContainer,
} from 'src/components/RightContainer/RightContainer.slice';
import { getFilterSelections } from '../FilterPanel/FilterPanel.container';
import { getSectionContextObj } from 'src/utils/Domain/Perspective';

export type StateProps = RightContainerSlice;

export type DispatchProps = {
  setRightContainerPayload(payload: ContainerPayload): void;
  openRightContainer(): void;
  closeRightContainer(): void;
  clearAndCloseWindow(payload?: ContainerPayload): void;
  onDestroy(): void;
};

function mapStateToProps(state: AppState): RightContainerProps {
  const context = getSectionContextObj();
  return {
    ...state.rightContainer,
    editStyleBtn: context ? context.editStyleBtn : undefined,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    setRightContainerPayload(payload: ContainerPayload) {
      dispatch(setRightContainerPayload(payload));
    },
    openRightContainer() {
      dispatch(openRightContainer());
    },
    closeRightContainer() {
      dispatch(closeRightContainer());
    },
    clearAndCloseWindow(payload?: ContainerPayload) {
      dispatch(clearAndCloseRightContainer());
      if (payload && payload.type === 'FilterPanel') {
        dispatch(getFilterSelections());
      }
    },
    onDestroy() {
      dispatch(cleanUpRightContainer());
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(RightContainer);
