import React from 'react';
import { findIndex, isEmpty, isNil } from 'lodash';
import { ICellRendererParams } from 'ag-grid-community';

type HeatmapRendererProps = ICellRendererParams & {
  sizes: string[];
  dataIndex: string;
  sizeArrayIndex: number;
  valueAsCssColor: boolean;
};

const HeatmapRenderer = ({ value, node, colDef, sizes, dataIndex }: HeatmapRendererProps) => {
  // TODO: how this renderer expects to work with real data:
  // get available sizes (i.e. ['S', 'M', 'L', 'XL', ...]), these may need to be passed in at colDef creation time
  // get heatmap values for row (i.e. [10, 20, 30, 40, ...])
  // get current cell field (i.e. 'L')
  // lookup field value ('L') index in available sizes and use that index to get value to render in heatmap cell

  const sizeValues = node.data[dataIndex];
  const sizeIdx = colDef.cellRendererParams.sizeArrayIndex;
  const colorToRender = sizeValues[sizeIdx] ? sizeValues[sizeIdx] : '#fff';

  return <div style={{ height: 30, backgroundColor: colorToRender }} />;
};

export default HeatmapRenderer;
