import { TimeEntry, Pivot, TimeSheetInfo, SubmitPayload, OverTimeType } from 'src/worker/pivotWorker.types';
import { GridViewConfig } from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import serviceContainer from 'src/ServiceContainer';
import { Dispatch } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  cacheCheckFetchPivotData,
  fetchListData,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import { IS_PUBLISHED } from 'src/utils/Domain/Constants';
import { has } from 'lodash';
import { BaseAction } from 'redux-actions';

export type FlowSheetSlice = {
  viewDefns?: GridViewConfig;
  isConfigLoading: boolean;
  selectedFlowDataId?: string;
  massEditData?: Pivot;
  cacheHashCompanion: string | null;
  cacheHashOverTime: string | null;
  viewDataStateCompanion: ViewDataState;
  viewDataStateOverTime: ViewDataState;
  viewDataStateMassEdit: ViewDataState;
};

const initialState: FlowSheetSlice = {
  isConfigLoading: false,
  viewDataStateCompanion: ViewDataState.idle,
  viewDataStateOverTime: ViewDataState.idle,
  viewDataStateMassEdit: ViewDataState.idle,
  cacheHashCompanion: null,
  cacheHashOverTime: null,
};

const flowSheetByStyleReducer = createSlice({
  name: 'FlowSheetByStyle',
  initialState,
  reducers: {
    requestFlowSheetByStyleConfig(state) {
      state.isConfigLoading = true;
    },
    receiveFlowSheetByStyleConfig(state, action: PayloadAction<GridViewConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    requestOverTimeData(state, action: PayloadAction<string>) {
      state.viewDataStateOverTime = ViewDataState.regularDataLoading;
      state.selectedFlowDataId = action.payload;
    },
    receiveOverTimeCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashOverTime = action.payload;
    },
    receiveOverTimeCachedData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.cacheBackgroundDataLoading;
        state.selectedFlowDataId = action.payload.memberId;
      }
    },
    receiveOverTimeLiveData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.regularDataReady;
        state.selectedFlowDataId = action.payload.memberId;
      }
    },
    requestMassEditData(state) {
      state.viewDataStateMassEdit = ViewDataState.regularDataLoading;
    },
    receiveMassEditData(state, action: PayloadAction<Pivot>) {
      state.viewDataStateMassEdit = ViewDataState.regularDataReady;
      state.massEditData = action.payload;
    },
    requestCompanionData(state) {
      state.viewDataStateCompanion = ViewDataState.regularDataLoading;
    },
    receiveCompanionCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashCompanion = action.payload;
    },
    receiveCompanionCachedData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        state.viewDataStateCompanion = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveCompanionLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        const cacheInUse = state.viewDataStateCompanion === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataStateCompanion = cacheInUse
          ? ViewDataState.cacheBackgroundDataReady
          : ViewDataState.regularDataReady;
      }
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestFlowSheetByStyleConfig,
  receiveFlowSheetByStyleConfig,
  requestOverTimeData,
  receiveOverTimeCacheHash,
  receiveOverTimeCachedData,
  receiveOverTimeLiveData,
  requestMassEditData,
  receiveMassEditData,
  requestCompanionData,
  receiveCompanionCacheHash,
  receiveCompanionCachedData,
  receiveCompanionLiveData,
  receiveError,
  cleanUp,
} = flowSheetByStyleReducer.actions;

export function fetchCompanionData([modelDefn, massEditModelDefn]: string[]) {
  return async (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    cacheCheckFetchPivotData(
      serviceContainer.pivotService.fitViewCacheCheck(modelDefn),
      requestCompanionData,
      receiveCompanionCacheHash,
      receiveCompanionCachedData,
      receiveCompanionLiveData,
      false
    )(dispatch);

    return dispatch(fetchListData(massEditModelDefn, requestMassEditData, receiveMassEditData));
  };
}

export function fetchOverTimeData(memberId: string, defnId?: string) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.getOverTimeData(memberId, OverTimeType.flowsheet, defnId),
    () => requestOverTimeData(memberId),
    receiveOverTimeCacheHash,
    (cacheHash: string) => receiveOverTimeCachedData({ memberId, cacheHash }),
    (cacheHash: string) => receiveOverTimeLiveData({ memberId, cacheHash }),
    false
  );
}

export function submitPayload(payload: SubmitPayload, updateAssortmentPlan?: () => void) {
  return async () => {
    await serviceContainer.pivotService.submitFlowSheetPayload(payload);
    if (updateAssortmentPlan && payload.update.find((item) => has(item, IS_PUBLISHED))) {
      return updateAssortmentPlan();
    }
  };
}

export default flowSheetByStyleReducer.reducer;
