import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HistoryPayload = {
  type: 'HistoryStylePane';
  id: string;
  parentId: string;
  styleColorName?: string;
  isAssortmentBuild?: boolean;
};
export type AssortmentPayload = {
  type: 'StyleDetails';
  id: string;
  parentId: string;
  styleColorName?: string;
  isAssortmentBuild: boolean;
};
export type FilterPayload = { type: 'FilterPanel' };
export type ContainerPayload = HistoryPayload | AssortmentPayload | FilterPayload;

export type RightContainerSlice = {
  payload?: ContainerPayload;
  open: boolean;
};

const initialRightContainerState: RightContainerSlice = {
  open: false,
};

const rightContainerReducer = createSlice({
  name: 'RightContainer',
  initialState: initialRightContainerState,
  reducers: {
    setRightContainerPayload: (state, action: PayloadAction<ContainerPayload>) => {
      state.payload = action.payload;
      state.open = true;
    },
    closeRightContainer: (state) => {
      state.open = false;
    },
    openRightContainer: (state) => {
      state.open = true;
    },
    clearAndCloseRightContainer: (state) => {
      state.open = false;
      state.payload = undefined;
    },
    cleanUpRightContainer: () => {
      return initialRightContainerState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActivePage, (state) => {
      state.open = false;
      state.payload = undefined;
    });
  },
});

export const {
  setRightContainerPayload,
  closeRightContainer,
  openRightContainer,
  clearAndCloseRightContainer,
  cleanUpRightContainer,
} = rightContainerReducer.actions;

export default rightContainerReducer.reducer;
