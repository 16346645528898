import { style } from 'typestyle';

import {
  mediumGutterWidth,
  mediumBorderColor,
  smallMediumFont,
  smallFont,
  borderHighlightColor,
} from 'src/utils/Style/Theme';
import { rem } from 'csx';

export const companionExpandedWidth = 300;
export const companionWidthMin = 100;

export const listPairStyle = style({
  $debugName: 'list-grid-pair-container',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.header-row': {
      fontWeight: 600,
    },
    '.data-container': {
      overflow: 'auto',
      display: 'flex',
      flex: 1,
      minHeight: 1,
    },
    '.data-grid': {
      fontSize: '0.8rem',
      height: '100%',
    },
    '.ag-header': {
      zIndex: 1,
      background: 'white',
    },
    '.ag-header-cell.selectAllCheckbox[role="presentation"]': {
      paddingLeft: rem(0.6),

      $nest: {
        '& .ag-cell-label-container': {
          display: 'none',
        },
      },
    },
  },
});

export const gridActionsContainer = style({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: rem(0.5),
  marginRight: rem(1),
});

export const companionBorder = '1px solid ' + mediumBorderColor;

export const companionStyles = style({
  borderRight: companionBorder,
  width: companionExpandedWidth,
  $nest: {
    '&.scroll-target': {
      borderColor: borderHighlightColor,
    },
    '.control-bar > label': {
      fontSize: smallMediumFont,
    },
    '.control-bar .dropdown': {
      fontSize: smallMediumFont,
      alignItems: 'center',
      display: 'flex',
    },
    '.control-bar .dropdown-item': {
      fontSize: smallFont,
    },
    '&.collapsed': {
      maxWidth: 115,
    },
    '> header': {
      borderBottom: companionBorder,
    },
    '.id': {
      fontSize: '0.7rem',
    },
    '.name': {
      height: 40,
      display: 'flex',
      overflow: 'auto',
      fontSize: '0.8rem',
    },
  },
});

export function makeListPairStyle(isCollapsed: boolean) {
  const companionWidth = isCollapsed ? companionWidthMin : companionExpandedWidth;

  return style({
    width: `calc(100% - ${companionWidth}px - ${mediumGutterWidth}px)`,
    marginLeft: mediumGutterWidth,
    overflowX: 'hidden',
  });
}
