import React from 'react';
import { IconButton, Menu, MenuItem, Divider, Tooltip } from '@material-ui/core';
import { BasicItem } from 'src/types/Scope';
import { PopoverProps } from '@material-ui/core/Popover';
import { favoritesList, deleteButtonStyle, favoritesNameStyle } from './Favorites.styles';
import { DEFAULT_FAVORITE, DEFAULT_FAVORITE_ID } from './Favorites.constants';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { Option } from 'src/components/Configure/ConfigureModal';
import { CompanionFavoriteData } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import SubheaderQuickActionButton from '../SubheaderQuickActionButton';

const propToIcon = (isFavoriteActive: Pick<FavoritesButtonProps, 'isFavoriteActive'>) => {
  return isFavoriteActive ? 'fa fa-heart' : 'far fa-heart';
};

type FavoritesButtonProps = {
  /** Determines if a user's favorite is currently selected */
  isFavoriteActive: boolean;
  onClick: (elem: EventTarget & HTMLElement) => void;
};

export const FavoritesButton = (props: FavoritesButtonProps) => {
  return (
    <SubheaderQuickActionButton
      defaultsPresent={!props.isFavoriteActive}
      iconClass={propToIcon(props)}
      iconDataQa="subheader-favorites-button"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        props.onClick(event.currentTarget);
      }}
    />
  );
};

export type FavoriteListItemStorage = {
  config?: TenantConfigViewData;
  icon?: string;
  groupBySelection?: number;
  sortBySelection?: number;
  sortByDirection?: string;
  groupByDataIndexSelection?: string;
  sortByDataIndexSelection?: string;
  flowStatus?: number[];
  configurationSelections?: Option[];
  pareDownSelections?: TenantConfigViewItem[];
  limitSelection?: number;
  companionData?: CompanionFavoriteData;
};
export type FavoriteListItem = BasicItem & FavoriteListItemStorage;
type FavoritesListProps = Pick<PopoverProps, 'anchorEl'> & {
  favItems: FavoriteResponseItem[];
  open: boolean;
  unmodifiedViewDefn: TenantConfigViewData;
  defaultCompanionData?: CompanionFavoriteData;
  onClose: (event: React.SyntheticEvent<Record<string, any>>) => void;
  onClickSave: (event: React.SyntheticEvent<Record<string, any>>) => void;
  onClickDelete: (favItem: FavoriteListItem) => void;
  onApplyFavorite: (favItem: FavoriteListItem, key: string) => void;
};

/* eslint-disable @typescript-eslint/naming-convention */
export type FavoriteResponseItem = {
  favorite_name: string;
  jsonBlob: FavoriteListItem | null;
  key: string;
  module: string;
  user_id: string;
  active: boolean;
};

const DEFAULT_FAVORITE_RESPONSE: FavoriteResponseItem = {
  favorite_name: '',
  jsonBlob: null,
  key: '',
  module: '',
  user_id: '',
  active: false,
};
/* eslint-enable @typescript-eslint/naming-convention */

const FavoriteMenuItem = (
  favorite: FavoriteResponseItem,
  favIdx: number,
  onApplyFavorite: (favItem: FavoriteListItem, key: string) => void,
  onClickDelete: (favItem: FavoriteListItem) => void
) => {
  const fav = favorite.jsonBlob;
  if (!fav) return;
  const showTooltip = fav.name.length > 15;
  const name = showTooltip ? (
    <Tooltip title={fav.name} placement="top">
      <span className={favoritesNameStyle}>{fav.name}</span>
    </Tooltip>
  ) : (
    fav.name
  );
  return (
    <MenuItem
      id={fav.id}
      key={favIdx}
      data-qa={`subheader-favorites-button-${favIdx}`}
      className={'favorite-menu-item'}
      onClick={() => onApplyFavorite(fav, favorite.key)}
      selected={favorite.active}
    >
      {fav.icon ? <i className={fav.icon} /> : <i className={'fab fa-gratipay'} />}
      {name}
      {fav.id !== DEFAULT_FAVORITE_ID && (
        <IconButton
          aria-label="favorite-delete"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            onClickDelete(fav);
          }}
          style={deleteButtonStyle}
          className={'sizeSmall'}
        >
          <i className={'far fa-trash'} data-qa="favorite-trash-button" />
        </IconButton>
      )}
    </MenuItem>
  );
};

export const FavoritesList = (props: FavoritesListProps) => {
  return (
    <Menu
      id="favorites-list"
      className={favoritesList}
      data-qa={'subheader-favorites-list'}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted={true}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
    >
      {props.favItems
        .concat([
          {
            ...DEFAULT_FAVORITE_RESPONSE,
            jsonBlob: DEFAULT_FAVORITE(props.unmodifiedViewDefn, props.defaultCompanionData),
          },
        ])
        .map((item, i) => FavoriteMenuItem(item, i, props.onApplyFavorite, props.onClickDelete))}
      {props.favItems.length < 5 && ( // Cap favorites to 5 (+1 for default view)
        <div>
          <Divider />
          <MenuItem
            key={'save-fav'}
            data-qa="subheader-favorites-button-save-new"
            className={'favorite-menu-item'}
            onClick={props.onClickSave}
          >
            <i className={'fa fa-save'} />
            Save new Favorite
          </MenuItem>
        </div>
      )}
    </Menu>
  );
};
