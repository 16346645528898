import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Lens } from 'monocle-ts';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cacheCheckFetchPivotData } from '../StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';

export type TenantResponse = {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn?: TenantConfigViewData;
  styleDetailsDefn?: { left: TenantConfigViewData; right: TenantConfigViewData };
  calcViewDefn?: TenantConfigViewData;
  identityPropsConfig?: IdentityPropsConfig;
};

export type SelectedWeekRange = {
  to: string;
  from: string;
};

export type AllowedWeekRange = {
  allowTo: string;
  allowFrom: string;
};

export type CanvasViewSlice = {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  calcViewDefn: TenantConfigViewData;
  identityPropsConfig: IdentityPropsConfig;
  tenantConfigLoading: boolean;
  requiredViewDefns: string[];
  isSelectable?: boolean;
  popoverTitle: string;
  modelDefn?: string;
  scopeRange?: SelectedWeekRange;
  assortmentRange?: boolean;
  cacheHash: string | null; // do we need a map<hash,dataState>?
  viewDataState: ViewDataState;
};

const initialState: CanvasViewSlice = {
  viewDefn: {} as TenantConfigViewData,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  calcViewDefn: {} as TenantConfigViewData,
  identityPropsConfig: {} as IdentityPropsConfig,
  requiredViewDefns: [],
  tenantConfigLoading: false,
  popoverTitle: '',
  viewDataState: ViewDataState.idle,
  cacheHash: null,
};

export const setCanvasViewTenantConfig = Lens.fromProps<CanvasViewSlice>()([
  'viewDefn',
  'calcViewDefn',
  'modelDefn',
  'tenantConfigLoading',
  'unmodifiedViewDefn',
  'identityPropsConfig',
]).asSetter();

const canvasViewReducer = createSlice({
  name: 'CanvasView',
  initialState: initialState,
  reducers: {
    requestTenantConfig(state) {
      state.tenantConfigLoading = true;
    },
    receiveTenantConfig(state, action: PayloadAction<TenantResponse>) {
      state.tenantConfigLoading = false;
      state.viewDefn = action.payload.viewDefn;
      state.unmodifiedViewDefn = action.payload.unmodifiedViewDefn || ({} as TenantConfigViewData);
      state.calcViewDefn = action.payload.calcViewDefn || ({} as TenantConfigViewData);
      state.identityPropsConfig = action.payload.identityPropsConfig || ({} as IdentityPropsConfig);
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    setPopoverTitle(state, action: PayloadAction<string>) {
      state.popoverTitle = action.payload;
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  setPopoverTitle,
  receiveError,
  cleanUp,
} = canvasViewReducer.actions;

export function fetchCanvasViewData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData,
    true
  );
}

export default canvasViewReducer.reducer;
