import { px } from 'csx';
import { calcTrendCardWidth } from 'src/components/TrendCard/TrendCard';
import { media, style } from 'typestyle';

export const quickTrendsStyles = {
  root: style({
    height: '100%',
  }),
  container: style({
    $debugName: 'quicktrends-container',
    display: 'flex',
    height: 'calc(100% - 109px)',
  }),
  summary: style(
    // 1 card width for small screens, 2 for larger screens
    media(
      { maxWidth: 1672 },
      {
        $debugName: 'quicktrends-summary-container',
        width: `calc(${px(calcTrendCardWidth() * 1)} + 2rem)`,
      }
    ),
    {
      $debugName: 'quicktrends-summary-container',
      width: `calc(${px(calcTrendCardWidth() * 2)} + 2rem)`,
    }
  ),
  details: style({
    $debugName: 'quicktrends-details-container',
    height: '100%',
    padding: '0 1rem 1rem 1rem',
    flexGrow: 1,
  }),
};
