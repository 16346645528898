import React from 'react';
import { find, pick, assign } from 'lodash';
import { toast } from 'react-toastify';

import { FilterSelection } from 'src/common-ui/components/Filters/Filters';
import { Overlay } from 'src/common-ui/index';

import { HINDSIGHTING, TOP_DOWN_PERSPECTIVE, TOP_DOWN, ASSORTMENT } from 'src/utils/Domain/Constants';
import { makeFilterClient } from 'src/dao/filterClient';
import { getSectionContext, perspectiveToSmallName } from 'src/utils/Domain/Perspective';
import { FilterGroup, Filter, Scope } from 'src/types/Scope';
import { perspectiveBindings } from 'src/services/configuration/perspectiveBindings';
import { makeScopeClient } from 'src/dao/scopeClient';
import { RestoreSessionState, RestoreSessionProps } from './RestoreSession.container';
import { getSessionAll, isSessionScopeValid } from './RestoreSession.utils';
import { Perspective } from 'src/services/configuration/bindings.types';
import { AppType } from 'src/types/Domain';
import service from 'src/ServiceContainer';

class RestoreSession extends React.Component<RestoreSessionProps, RestoreSessionState> {
  state = {
    loading: false,
  };

  applyNav = async (perspective: Perspective, activeTab: string) => {
    const perspectiveBinding = {
      id: perspective,
      ...perspectiveBindings[perspective],
    };
    this.props.setPerspective(perspectiveBinding);
    this.props.setActiveTab(activeTab);
  };

  applyScope = async (sessionScope: Partial<Scope>) => {
    const scopeClient = makeScopeClient();
    // Request scope to set perspective
    this.props.requestScope();
    const response = await scopeClient.getScope();
    // Only set scope if the received scope is different than the stored scope
    const needSetScope = Object.keys(sessionScope).reduce((accum, key) => {
      return sessionScope[key] !== response.scope[key] ? true : accum;
    }, false);
    if (needSetScope) {
      const requiredScopeSelections = {
        ...response.scope,
        ...sessionScope,
        locationMemberName: null,
        productMemberName: null,
      };
      const response2 = await scopeClient.setScope(requiredScopeSelections);
      this.props.receiveScope(response2.scope);
    } else {
      this.props.receiveScope(response.scope);
    }
  };

  applyFilters = async (perspective: string, tab: string | null, filterSelections: FilterSelection[]) => {
    const filterClient = makeFilterClient();
    const appName: AppType = perspective === TOP_DOWN_PERSPECTIVE ? TOP_DOWN : ASSORTMENT;
    const section = getSectionContext();
    let data = null;
    // Try to retrieve filters, fails without blocking
    try {
      data = await filterClient.getFullFilters({ appName, section });
    } catch (error) {
      service.loggingService.info('An error occurred trying to retrieve filters during restore session.');
    }
    const filterGroups = data?.filters || [];
    const filterSelectionsInfo: FilterSelection[] = [];

    this.props.receiveFilters(filterGroups);

    // From returned filters, apply all filters in storage that exist
    filterSelections.forEach((selection) => {
      filterGroups.forEach((filterGroup: FilterGroup) => {
        const filterSection: Filter | undefined = find(filterGroup.filters, { id: selection.filterDefnId });
        if (filterSection) {
          const filterSelection = find(filterSection.filterValues, { id: selection.id });
          if (filterSelection) filterSelectionsInfo.push(pick(filterSelection, ['id', 'filterDefnId']));
        }
      });
    });

    // Try to apply filters, fails without blocking
    try {
      await filterClient.setFilterSelections(filterSelectionsInfo, {
        appName,
        section,
      });
    } catch (error) {
      service.loggingService.info('An error occurred trying to set filters during restore session.');
    }
    const selectionOverrides = filterSelectionsInfo.map((override) => assign({}, override, { value: true }));
    this.props.setFilterSelections(selectionOverrides);
  };

  applySession = async (
    perspective: Perspective,
    tab: string | null,
    scope: Partial<Scope>,
    filterSelections: FilterSelection[]
  ) => {
    await this.applyNav(perspective, tab || HINDSIGHTING);
    if (isSessionScopeValid()) {
      await this.applyScope(scope);
      await this.applyFilters(perspective, tab, filterSelections);
    }
  };

  onClick = () => {
    const {
      productMember,
      productLevel,
      locationLevel,
      locationMember,
      historyEnd,
      historyStart,
      asstStart,
      asstEnd,
      floorset,
      filters,
      tab,
      perspective,
      page,
    } = getSessionAll();

    if (!perspective) return;

    this.setState({
      loading: true,
    });
    toast.dismiss('restoreSession');

    const scope = {
      productLevel,
      productMember,
      locationLevel,
      locationMember,
      floorSet: floorset,
      start: asstStart,
      end: asstEnd,
      historyStart,
      historyEnd,
    };

    this.applySession(perspective, tab, scope, filters)
      .then(() => {
        if (page) {
          this.props.history.push(page);
        }
      })
      .catch((e) => {
        console.error(e);
        this.destroy();
        toast.error('Error: Unable to restore previous session.', {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  destroy() {
    toast.dismiss('restoreSession');
    this.setState({ loading: false });
  }

  componentDidMount() {
    toast(<div onClick={this.onClick}>Click here to restore your previous session!</div>, {
      position: toast.POSITION.TOP_LEFT,
      closeOnClick: false,
      autoClose: false,
      draggable: false,
      type: 'info',
      toastId: 'restoreSession',
    });
  }

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    if (this.props.hideLoading) {
      return <div />;
    }
    return <Overlay type="loading" visible={this.state.loading} fitParent={true} />;
  }
}
export default RestoreSession;
