import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import { fetchProductDetailsGridData, fetchProductDetailsMacroData } from './ProductDetails.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/inputIsNotNullOrUndefined';
import {
  ConfDefnComponentType,
  maybeGetComponentProps,
  ProductDetailsComponent,
} from 'src/services/configuration/codecs/confdefnComponents';
import { DataApi } from 'src/services/configuration/codecs/confdefnView';
import {
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { LEVEL_PROD_ROOT } from 'src/utils/Domain/Constants';
import { RECEIVE_SCOPE_REFRESH_TRIGGER } from 'src/components/ScopeSelector/ScopeSelector.actions';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';

export const productDetailsLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      RECEIVE_SCOPE_REFRESH_TRIGGER,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      updateFlowStatus.type
    ),
    map(() => maybeGetComponentProps<ProductDetailsComponent>(state$.value, ConfDefnComponentType.productDetails)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns }) => {
      const [gridModelDefn, macroModelDefn] = defns.models;
      const flowStatus = state$.value.subheader.flowStatus.join(',');

      // TODO: move this to config when ready
      const macroDataApi: DataApi = {
        defnId: macroModelDefn,
        isListData: true,
        params: {
          aggBy: LEVEL_PROD_ROOT,
        },
      };

      const options: ExtraPivotOptions = { flowStatus };
      const finalMacroOptions = organizeListDataOptions(options, macroDataApi);

      return of(
        fetchProductDetailsMacroData(macroModelDefn, finalMacroOptions),
        fetchProductDetailsGridData(gridModelDefn, options)
      );
    })
  );
};
