import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateFlowStatus, updateGroupBy, receiveViewDefns } from 'src/components/Subheader/Subheader.slice';
import { fetchProductivityData } from './Productivity.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/inputIsNotNullOrUndefined';
import {
  ConfDefnComponentType,
  ProductivityComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { getAggBys } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { RECEIVE_SCOPE_REFRESH_TRIGGER } from 'src/components/ScopeSelector/ScopeSelector.actions';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';

export const productivityLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      RECEIVE_SCOPE_REFRESH_TRIGGER,
      receiveFilterStateAfterSubmission.type,
      receiveViewDefns.type,
      updateFlowStatus.type,
      updateGroupBy.type
    ),
    map(() => maybeGetComponentProps<ProductivityComponent>(state$.value, ConfDefnComponentType.productivity)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns }) => {
      const flowStatus = state$.value.subheader.flowStatus.join(',');
      const aggBy = getAggBys(state$.value.subheader, undefined, {
        includeBottomLevel: false,
        forceReload: true,
        groupingKeyOverride: 'dataIndex',
      });
      const options: ListDataOptions = { flowStatus, aggBy };

      return of(fetchProductivityData(defns.model, options));
    })
  );
};
