import { ICellRendererParams, ICellRendererComp } from 'ag-grid-community';
import { Renderer } from 'src/utils/Domain/Renderer';
import { isNil, isNaN } from 'lodash';

export type frameworkS5Renderer = {
  renderer: keyof typeof Renderer;
};

type frameworkS5RendererParams = frameworkS5Renderer & ICellRendererParams;

export default class NonFrameWorkToS5Renderer implements ICellRendererComp {
  eGui!: HTMLElement;

  renderValue(params: frameworkS5RendererParams) {
    // yeah I know, inputType = renderer here
    if (params.renderer != null && !isNil(params.value)) {
      const rndrr = Renderer[params.renderer] as (value: any) => any;
      if (rndrr) {
        // If we can't render with thousand, we can't render with any of them.
        // FIXME: STOP COMBINING RENDERERS AND FORMATTERS IN THE SAME OBJECT. PLEASE. PLS.
        // -LOVE Mark. (I have no way of handling non-number renderer at this time. Don't use them).
        const baseRenderCase = Renderer.thousand(params.value);
        return baseRenderCase.toLowerCase() !== 'nan' ? rndrr(params.value) : '';
      }
    }
    return params.value;
  }

  init(params: frameworkS5RendererParams) {
    this.eGui = document.createElement('div');
    this.eGui.innerText = this.renderValue(params);
  }
  refresh(params: frameworkS5RendererParams) {
    this.init(params);
    return false;
  }
  getGui() {
    return this.eGui;
  }
}
