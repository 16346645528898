export type AppType = 'Assortment' | 'TDAnalysis';

// TODO: should probably live next to ViewDataState loading check methods in pivotService cache
export enum ViewDataState {
  idle = 'idle',
  regularDataLoading = 'regularDataLoading',
  regularDataReady = 'regularDataReady',
  // cache in use, currently loading background data
  cacheBackgroundDataLoading = 'cacheBackgroundDataLoading',
  // cache in use, background data ready but waiting user to trigger refresh
  cacheBackgroundDataReady = 'cacheBackgroundDataReady',
}
