import React from 'react';
import _ from 'lodash';
import Subheader from 'src/components/Subheader/Subheader.container';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { styles, chartContainer } from './ProductMix.styles';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { Overlay } from 'src/common-ui';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { ViewDataState } from 'src/types/Domain';
import { Options, Point, PointLegendItemClickEventObject } from 'highcharts';
import { PieRender } from './PieRender';
import { ChartRender } from './ChartRender';

export const baseConfig: Options = {
  chart: {
    plotBackgroundColor: undefined,
    plotBorderWidth: 0,
    plotShadow: false,
    marginTop: 10,
  },
  title: {
    text: '',
    margin: 4,
    align: 'center',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
        distance: -70,
        style: {
          fontWeight: 'bold',
          color: 'white',
        },
      },
      startAngle: 0,
      endAngle: 360,
      size: '93%',
      center: ['50%', '50%'],
      point: {
        events: {
          legendItemClick: function(this: Point, event: PointLegendItemClickEventObject) {
            event.preventDefault();
          },
        },
      },
    },
  },
  credits: {
    enabled: false,
  },
  series: [],
};

type SubheaderPicks = 'groupBy' | 'flowStatus';

export type ValueProps = {
  title: string;
  loaded: boolean;
  pieChartView?: TenantConfigViewItem;
  barChartView?: TenantConfigViewItem;
  data: BasicPivotItem[] | null;
  totals: BasicPivotItem | null;
  groupByViewDefnStr?: string;
  viewDefn: TenantConfigViewData;
  viewDataState: ViewDataState;
} & Pick<SubheaderSlice, SubheaderPicks>;

export type FunctionProps = {
  onShowView(): void;
};

type Props = FunctionProps & ValueProps;

export class ProductMix extends React.Component<Props> {
  reloadData: () => void = () => null;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  render() {
    const {
      title,
      data,
      totals,
      pieChartView,
      barChartView,
      groupByViewDefnStr,
      loaded,
      viewDefn,
      viewDataState,
    } = this.props;

    let notLoaded = false;
    let pieJsx, barJsx;

    if (!_.isNil(pieChartView) && !_.isNil(barChartView) && !_.isNil(data) && !_.isNil(totals)) {
      pieJsx = (
        <React.Fragment>
          <header className={styles.sectionHeader}>{pieChartView.text}</header>
          <div className={styles.sectionBody}>
            <PieRender {...{ data, totals, view: pieChartView, config: baseConfig }} />
          </div>
        </React.Fragment>
      );
      barJsx = (
        <React.Fragment>
          <header className={styles.sectionHeader}>{barChartView.text}</header>
          <div className={styles.sectionBody} style={{ display: 'flex' }}>
            <ChartRender {...{ data, totals, view: barChartView, config: baseConfig }} />
          </div>
        </React.Fragment>
      );
    } else {
      notLoaded = true;
    }
    const viewConfigurator: ViewConfiguratorModalProps = {
      viewConfig: viewDefn,
      unmodifiedViewDefn: viewDefn,
    };

    return (
      <div style={{ height: '100%', width: '100%', zIndex: 'auto' }}>
        <Overlay type={'loading'} visible={!loaded} />
        <Subheader
          title={title}
          groupByDefn={groupByViewDefnStr}
          showFlowStatus={true}
          showSearch={false}
          viewConfigurator={viewConfigurator}
          viewDataState={viewDataState}
        />
        <div className={chartContainer}>
          {notLoaded ? (
            <div />
          ) : (
            <React.Fragment>
              <div className={styles.section}>{pieJsx}</div>
              <div className={styles.section}>{barJsx}</div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
