import {
  Action,
  RECEIVE_QRCATEGORY_SUMMARY_CONFIGS,
  RECEIVE_QRCATEGORY_SUMMARY_DATA,
  REQUEST_QRCATEGORY_SUMMARY_CONFIGS,
  REQUEST_QRCATEGORY_SUMMARY_DATA,
  RECEIVE_QRCATEGORY_SUMMARY_ERROR,
  RECEIVE_QRCATEGORY_VIEW_DEFNS,
  CLEAN_UP_QRCATEGORY_SUMMARY,
  UPDATE_QRGROUP_BY,
  UPDATE_QRFLOORSET_BY,
  ViewDefns,
  ViewData,
  DropdownConfigViewData,
} from './QRCategorySummary.actions';
import { isEqual, get, cloneDeep } from 'lodash';
import { GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';

const emptyItem = {
  dataIndex: '',
  text: '',
  dimension: 'product',
};

export const initialGroupBy = {
  defaultSelection: 0,
  selection: 0,
  options: [emptyItem],
};

export type SubheaderDropdownSlice = {
  defaultSelection: number;
  selection: number | null;
  options: TenantConfigViewItem[];
};

export type QRCategorySummarySlice = {
  floorset: string;
  areViewDefnsLoading: boolean;
  isViewDataLoading?: boolean;
  viewData?: ViewData;
  viewDefns?: ViewDefns;
  groupByUpdated: boolean;
  floorsetUpdated: boolean;
  groupBy: GroupBySlice;
};

const initialState: QRCategorySummarySlice = {
  areViewDefnsLoading: true,
  isViewDataLoading: false,
  groupByUpdated: false,
  floorsetUpdated: false,
  groupBy: initialGroupBy,
  floorset: '',
};

function _handleDropdownExtras(
  ddSlice: SubheaderDropdownSlice,
  viewDefn: DropdownConfigViewData,
  currentSelection: TenantConfigViewItem | null
) {
  ddSlice.options = viewDefn.view;
  if (viewDefn.hideEmptyRow !== true) {
    ddSlice.options.unshift(emptyItem);
  }
  if (viewDefn.default) {
    const defaultSelection = ddSlice.options.findIndex((opt) => opt.dataIndex === viewDefn.default);
    ddSlice.defaultSelection = defaultSelection;
  }
  const newSelection = ddSlice.options.findIndex((opt) => isEqual(opt, currentSelection));
  if (newSelection >= 0) {
    ddSlice.selection = newSelection;
  } else {
    ddSlice.selection = 0;
  }
  return ddSlice;
}

type MergeAction = Action;

export default function categorySummaryReducer(
  state: QRCategorySummarySlice = initialState,
  action: MergeAction
): QRCategorySummarySlice {
  switch (action.type) {
    case REQUEST_QRCATEGORY_SUMMARY_CONFIGS: {
      return {
        ...state,
        areViewDefnsLoading: true,
      };
    }
    case RECEIVE_QRCATEGORY_SUMMARY_CONFIGS: {
      return {
        ...state,
        areViewDefnsLoading: false,
        viewDefns: action.configs,
      };
    }
    case RECEIVE_QRCATEGORY_VIEW_DEFNS: {
      let groupBy: GroupBySlice = cloneDeep(initialGroupBy);

      const groupByDefn = action.defns.groupBy;

      if (groupByDefn) {
        const curSel = get(state.groupBy.options, `[${state.groupBy.selection}]`);
        groupBy = {
          ...state.groupBy,
          ..._handleDropdownExtras(groupBy, groupByDefn, curSel),
        };
      }

      return {
        ...state,
        groupBy,
      };
    }
    case REQUEST_QRCATEGORY_SUMMARY_DATA: {
      return {
        ...state,
        groupByUpdated: false,
        floorsetUpdated: false,
        isViewDataLoading: true,
      };
    }
    case RECEIVE_QRCATEGORY_SUMMARY_DATA: {
      return {
        ...state,
        isViewDataLoading: false,
        viewData: action.data,
      };
    }
    case RECEIVE_QRCATEGORY_SUMMARY_ERROR: {
      return {
        ...state,
        groupByUpdated: false,
        floorsetUpdated: false,
      };
    }
    case CLEAN_UP_QRCATEGORY_SUMMARY: {
      return initialState;
    }
    case UPDATE_QRGROUP_BY: {
      return {
        ...state,
        groupByUpdated: true,
        groupBy: {
          ...state.groupBy,
          selection: action.groupBy,
        },
      };
    }
    case UPDATE_QRFLOORSET_BY: {
      return {
        ...state,
        floorsetUpdated: true,
        floorset: action.floorset,
      };
    }
    default:
      return state;
  }
}
