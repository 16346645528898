import * as Actions from './MacroMix.actions';
import MacroMix, { ValueProps, FunctionProps } from './MacroMix';
import { find } from 'lodash/fp';
import { Dispatch, connect } from 'react-redux';
import serviceContainer from 'src/ServiceContainer';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import * as fp from 'lodash/fp';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { TOP_DOWN, lookBackPeriodProp } from 'src/utils/Domain/Constants';
import { receiveMixChartData, requestMixChartData, selectBox, clearChartData } from './MacroMix.actions';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import { getTopMembers } from 'src/pages/Hindsighting/MacroTrends/Summary/Summary.container';
import { AppState, AppThunkDispatch, AppObserver } from 'src/store';

const observers: (() => void)[] = [];
const DEFNS = {
  viewDefn: 'HistoryMacroMix',
  listData: 'HistoryMacroMix',
  chartListData: 'HistoryMacroMixByMonth',
};

function mapStateToProps(state: AppState): ValueProps {
  const { macroMix } = state.pages.hindsighting;
  const { lookBackPeriod } = state.subheader;

  const initialRender: ValueProps = {
    // first render
    loaded: false,
    title: 'Macro Mix',
  };

  if (!macroMix.confLoaded) {
    return initialRender;
  }

  // get confs
  const heatDropdownConf = find((view) => view.xtype === 'heatmap-selections', macroMix.allConf!.view);
  const heatmapConf = find((view) => view.xtype === 'heatmap-conf', macroMix.allConf!.view);
  const leftChartConf = find((view) => view.xtype === 'left-chart', macroMix.allConf!.view);
  const rightChartConf = find((view) => view.xtype === 'right-chart', macroMix.allConf!.view);

  // the type checker doesn't like it if all of these aren't in the if statement
  if (
    !fp.isUndefined(heatDropdownConf) &&
    !fp.isUndefined(heatmapConf) &&
    !fp.isUndefined(leftChartConf) &&
    !fp.isUndefined(rightChartConf) &&
    macroMix.data &&
    macroMix.regions
  ) {
    let data = macroMix.data;
    if (lookBackPeriod) {
      // Filter by lookBackPeriod
      data = cascadingFilter(data, makelookBackPredicate(lookBackPeriod));
    }
    return {
      loaded: true,
      chartDataLoaded: macroMix.chartDataLoaded,
      dataLoaded: macroMix.dataLoaded,
      selectedBox: macroMix.selectedBox,
      selectedLevels: macroMix.selectedLevels!,
      data: data!,
      regions: macroMix.regions!,
      chartData: macroMix.chartData!,
      dropdownConf: heatDropdownConf,
      heatmapConf: heatmapConf,
      leftChartConf: leftChartConf,
      rightChartConf: rightChartConf,
      title: 'Macro Mix',
    };
  }
  // this shouldn't be hit
  return initialRender;
}

function asyncGetData<S extends AppState>() {
  const service = serviceContainer.pivotService;
  return (dispatch: Dispatch<S>, getState: () => S): Promise<Actions.Action | void> => {
    const state = getState();
    const { macroMix } = state.pages.hindsighting;
    const { flowStatus, lookBackPeriod } = getState().subheader;
    const selectedLevels = macroMix.selectedLevels;

    const modelId = selectedLevels ? DEFNS.listData + selectedLevels.modelId : DEFNS.listData;

    if (!fp.isNil(selectedLevels)) {
      const listDataPromise = service.listData(modelId, TOP_DOWN, {
        aggBy: selectedLevels.view!.map((it) => `${it.dimension}:${it.dataIndex}`).join(','),
        flowStatus: flowStatus.join(','),
        [lookBackPeriodProp]: lookBackPeriod,
        topMembers: getTopMembers(getState().scope.scope),
        nestData: false,
      });
      const regionLevel = selectedLevels.view![1].dataIndex;
      const regionMembersPromise = service.getValidMembers(regionLevel);

      dispatch(Actions.requestMixData());
      return Promise.all([listDataPromise, regionMembersPromise])
        .then(([listData, regions]) => {
          return dispatch(
            Actions.receiveMixData({
              listData: listData.tree,
              regions: regions,
            })
          );
        })
        .catch((_error) => dispatch(Actions.receiveMixDataError()));
    }
    return Promise.resolve();
  };
}

function asyncGetChartData() {
  const service = serviceContainer.pivotService;

  return (dispatch: Dispatch<AppState>, getState: () => AppState): Promise<void> | void => {
    const { selectedBox } = getState().pages.hindsighting.macroMix;

    if (selectedBox) {
      dispatch(requestMixChartData());
      service
        .listData(DEFNS.chartListData, TOP_DOWN, {
          aggBy: 'level:month',
          topMembers: [selectedBox.id, selectedBox.mId].join(','),
        })
        .then((resp) => {
          dispatch(receiveMixChartData(resp.tree));
        });
    }
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch): FunctionProps {
  const { tenantConfigClient } = serviceContainer;
  return {
    onShowView() {
      dispatch(Actions.requestViewConfig());
      tenantConfigClient
        .getTenantViewDefns({
          defnIds: [DEFNS.viewDefn],
          appName: 'Assortment',
        })
        .then((resp) => {
          const conf = resp[0];
          dispatch(Actions.receiveViewConfig(conf));

          // pull off the first viewdata item to load the default metric
          return conf.view[0];
        })
        .then((drowndownConf) => {
          // TODO: add default selection to viewDefn?
          const defaultItem = drowndownConf.view![0];

          dispatch(Actions.updateLevels(defaultItem));
          dispatch(asyncGetData());
          observers.push(
            AppObserver.on('subheader.flowStatus', () => {
              dispatch(asyncGetData());
            })
          );
        })
        .catch((_error) => dispatch(Actions.receiveViewConfigError()));
    },
    onUpdateLevel(newLevels: TenantConfigViewItem) {
      dispatch(clearChartData());
      dispatch(Actions.updateLevels(newLevels));
      dispatch(Actions.requestMixData());
      dispatch(asyncGetData());
    },
    // @ts-ignore
    onSelectBox(selectedBox: ExtendedPointObject) {
      dispatch(selectBox(selectedBox));
      dispatch(requestMixChartData());
      dispatch(asyncGetChartData());
    },
    onDestroy() {
      observers.forEach((e) => e());
      observers.length = 0;
    },
    onRefetchData() {
      dispatch(clearChartData());
      dispatch(asyncGetData());
    },
  };
}

// @ts-ignore
const scopeSensitiveComponent = makeScopeSensitive(MacroMix);
export default connect(mapStateToProps, mapDispatchToProps)(scopeSensitiveComponent);
