import * as React from 'react';
import * as _ from 'lodash';
import { AxiosResponse } from 'axios';
import Axios from 'src/services/axios';
import { isEmpty, cloneDeep, isArray, mapValues, isNil, isString } from 'lodash';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import { CancelTokenSource, default as AxiosStatic } from 'axios';

import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { styles, MUIStyles } from './LifecycleStoreModal.styles';
import Lifecycle from 'src/pages/AssortmentBuild/LinePlan/Lifecycle/Lifecycle';
import StoreEligibility, { StoreData } from 'src/pages/AssortmentBuild/LinePlan/StoreEligibility/StoreEligibility';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import {
  DependentData,
  MultiRangeEditors,
  StyleEditConfigColumn,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import Overlay from 'src/common-ui/components/Overlay/Overlay';

type LifecycleConfig = {
  multiRangeEditors: MultiRangeEditors;
  planningColumns: StyleEditConfigColumn[];
  title: string;
  weekRangeColumns: StyleEditConfigColumn[];
};

type StoreConfig = {
  data: StyleEditConfigColumn[];
};

export type LifecycleData = {
  [key: string]: number | string | string[] | null;
};

export type LifecycleStoreData = {
  lifecycleData: LifecycleData;
  storeData: StoreData;
};

export type LifecycleParametersProps = {
  textToDisplay: string;
  lifecycleDataApi: ViewApiConfig;
  storeDataApi: ViewApiConfig;
  lifecycleConfig: ViewApiConfig;
  storeConfig: ViewApiConfig;
  dependentsApi: ViewApiConfig;
  onSubmit: (data: LifecycleStoreData) => void;
};

export type LifecycleParametersState = {
  activeTabIndex: number;
  lifecycleConfig: LifecycleConfig | null;
  storeConfig: StoreConfig | null;
  dependentsData: DependentData | null;
  open: boolean;
  lifecycleData: LifecycleData | null;
  storeData: StoreData | null;
  loading: boolean;
};

class LifecycleParametersEditor extends React.Component<LifecycleParametersProps, LifecycleParametersState> {
  lifecycleRef: React.RefObject<Lifecycle> = React.createRef();
  storeRef: React.RefObject<StoreEligibility> = React.createRef();
  canceler: CancelTokenSource = AxiosStatic.CancelToken.source();
  updateCanceler: CancelTokenSource = AxiosStatic.CancelToken.source();
  constructor(props: LifecycleParametersProps) {
    super(props);
    this.state = {
      lifecycleConfig: null,
      activeTabIndex: 0,
      storeConfig: null,
      dependentsData: null,
      open: false,
      lifecycleData: null,
      storeData: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.getConfigsAndData();
  }

  componentWillUnmount() {
    this.canceler.cancel();
  }

  getConfigsAndData() {
    const { lifecycleDataApi, storeDataApi, lifecycleConfig, storeConfig, dependentsApi } = this.props;

    const lifecycleConfigGet = Axios.get(lifecycleConfig.url, {
      params: { ...lifecycleConfig.params },
      cancelToken: this.canceler.token,
    });
    const storeConfigGet = Axios.get(storeConfig.url, {
      params: { ...storeConfig.params },
      cancelToken: this.canceler.token,
    });
    const dependentsData = Axios.get(dependentsApi.url, {
      cancelToken: this.canceler.token,
    });
    const lifecycleData = Axios.get(lifecycleDataApi.url, {
      params: {
        ...lifecycleDataApi.params,
      },
      cancelToken: this.canceler.token,
    });
    const storeData = Axios.get(storeDataApi.url, {
      params: {
        ...storeDataApi.params,
      },
      cancelToken: this.canceler.token,
    });

    const reqs = [lifecycleConfigGet, dependentsData, storeConfigGet, lifecycleData, storeData];

    Promise.all(reqs).then((responses: AxiosResponse[]) => {
      // Parse so it works in store eligibility
      let data = responses[4].data.data;
      data = mapValues(data, (value) => {
        const layer = value[0];
        return [
          mapValues(layer, (propertyValue) => {
            if (!isString(propertyValue)) {
              return propertyValue;
            }
            return arrayStringToArray(propertyValue, true);
          }),
        ];
      });
      this.setState({
        lifecycleConfig: maybeReturnNestData(responses[0].data),
        dependentsData: responses[1].data.data,
        storeConfig: maybeReturnNestData(responses[2].data),
        lifecycleData: responses[3].data.data,
        storeData: data,
        loading: false,
      });
    });
  }

  reloadStoreData = async () => {
    const { storeDataApi } = this.props;
    const storeDataResp = await Axios.get(storeDataApi.url, {
      params: {
        ...storeDataApi.params,
      },
      cancelToken: this.canceler.token,
    });
    let storeData = storeDataResp.data.data;
    storeData = mapValues(storeData, (value) => {
      const layer = value[0];
      return [
        mapValues(layer, (propertyValue) => {
          if (!isString(propertyValue)) {
            return propertyValue;
          }
          return arrayStringToArray(propertyValue, true);
        }),
      ];
    });
    this.setState({
      storeData,
      loading: false,
    });
  };
  reloadLifecycleData = async () => {
    const { lifecycleDataApi } = this.props;
    const lifecycleDataResp = await Axios.get(lifecycleDataApi.url, {
      params: {
        ...lifecycleDataApi.params,
      },
      cancelToken: this.canceler.token,
    });
    const lifecycleData = lifecycleDataResp.data.data;
    this.setState({
      lifecycleData,
      loading: false,
    });
  };

  uploadLifecycleData = async (lifecycleData: LifecycleData | null) => {
    const { lifecycleDataApi } = this.props;
    const attributes = !isNil(lifecycleData) ? lifecycleData : {};
    this.updateCanceler.cancel();
    this.updateCanceler = AxiosStatic.CancelToken.source();

    await Axios.post(lifecycleDataApi.url, { attributes }, { cancelToken: this.updateCanceler.token });
  };

  onRefetchData = () => {
    this.reloadStoreData();
    this.reloadLifecycleData();
  };

  uploadStoreData = async (storeData: StoreData | null) => {
    const { storeDataApi } = this.props;

    this.updateCanceler.cancel();
    this.updateCanceler = AxiosStatic.CancelToken.source();
    await Axios.post(
      storeDataApi.url,
      {
        attributes: storeData,
      },
      {
        cancelToken: this.updateCanceler.token,
      }
    );
  };

  onClickTab = (index: number) => {
    const { activeTabIndex } = this.state;
    const lifecycleData = this.getLifecycleData();
    const storeData = this.getStoreData();

    if (index !== activeTabIndex) {
      this.setState(
        {
          activeTabIndex: index,
          loading: true,
        },
        async () => {
          if (activeTabIndex === 0) {
            await this.uploadLifecycleData(lifecycleData);
            await this.reloadStoreData();
          } else {
            await this.uploadStoreData(storeData);
            await this.reloadLifecycleData();
          }
        }
      );
    }
  };

  getDataStore = (storeData: any) => {
    const { storeData: origStoreData } = this.state;
    const origStoreDataCopy = cloneDeep(origStoreData);

    Object.keys(storeData).forEach((key) => {
      if (!isNil(origStoreDataCopy) && !isArray(storeData[key])) {
        origStoreDataCopy[key] = [storeData[key]];
      }
    });

    this.setState({
      storeData: origStoreDataCopy,
    });
  };

  getLifecycleData = (): LifecycleData | null => {
    const ref = this.lifecycleRef;
    return !isNil(ref) && !isNil(ref.current) ? ref.current.getLifecycleData() : null;
  };

  getStoreData = (): StoreData | null => {
    const ref = this.storeRef;
    return !isNil(ref) && !isNil(ref.current) ? ref.current.saveAfterEdit() : null;
  };

  submit = async () => {
    let lifecycleData = this.state.lifecycleData;
    let storeData = this.state.storeData;

    if (this.state.activeTabIndex === 0) {
      lifecycleData = this.getLifecycleData();
      await this.uploadLifecycleData(lifecycleData);
    } else {
      storeData = this.getStoreData();
      await this.uploadStoreData(storeData);
    }

    this.setState({
      open: false,
      activeTabIndex: 0,
      lifecycleData,
      storeData,
    });

    lifecycleData = !isNil(lifecycleData) ? lifecycleData : {};
    storeData = !isNil(storeData) ? storeData : {};

    this.props.onSubmit({
      lifecycleData,
      storeData,
    });
  };

  cancel = () => {
    this.setState({ open: false, activeTabIndex: 0 });
  };

  render() {
    const {
      loading,
      activeTabIndex,
      lifecycleConfig,
      dependentsData,
      storeConfig,
      lifecycleData,
      storeData,
    } = this.state;

    let content = <div />;
    if (loading) {
      content = (
        <div style={{ minHeight: '260px' }}>
          <Overlay visible={true} type={'loading'} fitParent={true} />
        </div>
      );
    } else if (lifecycleData && !isEmpty(lifecycleData)) {
      content = activeTabIndex ? (
        <StoreEligibility data={storeData} config={storeConfig} getDataStore={this.getDataStore} ref={this.storeRef} />
      ) : (
        <Lifecycle
          weekRangeData={lifecycleData}
          weekRangeConfig={!isNil(lifecycleConfig) ? lifecycleConfig.weekRangeColumns : null}
          weekRangeFieldEditConfig={!isNil(lifecycleConfig) ? lifecycleConfig.multiRangeEditors : null}
          planningConfig={!isNil(lifecycleConfig) ? lifecycleConfig.planningColumns : null}
          dependentsData={!isNil(dependentsData) ? dependentsData : null}
          ref={this.lifecycleRef}
        />
      );
    }
    return (
      <React.Fragment>
        <MuiThemeProvider theme={muiTheme}>
          {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
          <span onClick={() => this.setState({ open: true })}>{this.props.textToDisplay}</span>
          <Dialog open={this.state.open} maxWidth={'xl'} PaperProps={{ style: { overflow: 'visible' } }}>
            <div className={styles.container}>
              <div className={styles.tabContainer}>
                {['Lifecycle and Planning', 'Store Ranging'].map((name, index) => {
                  const tabClass = activeTabIndex === index ? styles.tabButtonActive : styles.tabButton;
                  return (
                    <button className={tabClass} onClick={() => this.onClickTab(index)} key={name}>
                      {name}
                    </button>
                  );
                })}
              </div>
              <div className={styles.receiptContainer}>
                <div className={'ag-theme-material'}>{content}</div>
                <CardActions classes={MUIStyles.cardActions}>
                  <AcceptButton onClick={this.submit} />
                  <RejectButton onClick={this.cancel} />
                </CardActions>
              </div>
            </div>
          </Dialog>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default LifecycleParametersEditor;
