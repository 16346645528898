import { TabBinding } from './bindings.types';
import {
  ASSORTMENT_BUILD,
  ASSORTMENT_BUILD_PATH,
  ASSORTMENT_STRATEGY,
  ASSORTMENT_STRATEGY_PATH,
  BOTTOM_UP_PERSPECTIVE,
  HINDSIGHTING,
  ASSORTMENT_ANALYSIS,
  ASSORTMENT_ANALYSIS_PATH,
  RECONCILIATION,
  RECONCILIATION_PATH,
  ALL_PERSPECTIVES,
  HINDSIGHTING_PATH,
  REPORTING,
  REPORTING_PATH,
  ALLOCATION,
  ALLOCATION_PATH,
} from 'src/utils/Domain/Constants';

// FIXME: prefer to use these values from config instead of hardcoded values
export const interfaceBinding: TabBinding[] = [
  {
    id: HINDSIGHTING,
    name: 'Hindsighting',
    pathSlot: HINDSIGHTING_PATH,
    inPerspectives: ALL_PERSPECTIVES,
  },
  {
    id: ASSORTMENT_STRATEGY,
    name: 'Assortment Strategy',
    pathSlot: ASSORTMENT_STRATEGY_PATH,
    inPerspectives: [BOTTOM_UP_PERSPECTIVE],
  },
  {
    id: ASSORTMENT_BUILD,
    name: 'Assortment Build',
    pathSlot: ASSORTMENT_BUILD_PATH,
    inPerspectives: [BOTTOM_UP_PERSPECTIVE],
  },
  {
    id: ASSORTMENT_ANALYSIS,
    name: 'Store Clustering',
    pathSlot: ASSORTMENT_ANALYSIS_PATH,
    inPerspectives: ALL_PERSPECTIVES,
  },
  {
    id: RECONCILIATION,
    name: 'Analysis & Reconciliation',
    pathSlot: RECONCILIATION_PATH,
    inPerspectives: ALL_PERSPECTIVES,
  },
  {
    id: REPORTING,
    name: 'Reporting',
    pathSlot: REPORTING_PATH,
    inPerspectives: ALL_PERSPECTIVES,
  },
  {
    id: ALLOCATION,
    name: 'Allocation',
    pathSlot: ALLOCATION_PATH,
    inPerspectives: [BOTTOM_UP_PERSPECTIVE],
  },
];
