import fp from 'lodash/fp';
import { connect } from 'react-redux';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { overloadSubheader } from 'src/components/Subheader/Subheader.slice';
import {
  cleanUp,
  receiveError,
  requestFlowSheetByStyleConfig,
  receiveFlowSheetByStyleConfig,
  fetchOverTimeData,
  submitPayload,
  fetchCompanionData,
} from './FlowSheetByStyle.slice';
import { AppState, AppThunkDispatch } from 'src/store';
import { projectState } from 'src/components/ListGridPair/ListGridPair.selectors';
import { SubmitPayload } from 'src/worker/pivotWorker.types';
import { FlowSheetByStyle } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/FlowSheetByStyle';
import { get, isNil, isEmpty } from 'lodash/fp';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { MassEditConfig } from 'src/components/MassEdit/MassEdit';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { dispatchUpdateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { OwnProps } from './types';
import { Action } from 'redux';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps) {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;
  const [_, __, gridModelDefn] = defns.models;

  const factory = {
    submitPayload(payload: SubmitPayload) {
      return dispatch(submitPayload(payload, factory.updateAssortmentPlan));
    },
    getMassEditData() {
      // do nothing
    },
    onCompanionItemClick(memberId: string) {
      dispatch(fetchOverTimeData(memberId, gridModelDefn));
    },
    onShowView() {
      dispatch(requestFlowSheetByStyleConfig());
      tenantConfigClient
        .getTenantViewDefns({
          defnIds: defns.view,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          resp[0].view = resp[0]['views']!;
          dispatch(
            receiveFlowSheetByStyleConfig({
              grid: resp[0],
              listSort: resp[1],
              subheaderRollUp: resp[3],
              list: resp[4],
              // FIXME: revisit this type issue
              massEdit: !isNil(resp[5]) ? ((resp[5] as unknown) as MassEditConfig) : undefined,
              unmodifiedViewDefn: {} as any, // FIXME:
            })
          );
          dispatch(
            overloadSubheader({
              groupByOptions: resp[2],
              showFlowStatus: true,
              showSearch: true,
              title: 'Grid View',
            })
          );

          dispatch(fetchCompanionData(defns.models));
        })
        .catch(() => dispatch(receiveError()));
    },
    onRefetchData() {
      dispatch(
        (_dispatch: AppThunkDispatch, getState: () => AppState): Promise<Action | void> => {
          const { selectedFlowDataId } = getState().pages.assortmentBuild.flowSheet;
          dispatch(fetchCompanionData(defns.models));

          if (!isNil(selectedFlowDataId)) {
            return dispatch(fetchOverTimeData(selectedFlowDataId, gridModelDefn));
          }
          return Promise.resolve();
        }
      );
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
  };

  return factory;
}

export function mapStateToProps(state: AppState, ownProps: OwnProps) {
  const viewState = state.pages.assortmentBuild.flowSheet;
  const { title = 'Flow Sheet', defns, keys, showPopover, fabType } = ownProps;
  const { rangeList, daysRangeList, timeInfo } = state.scope;
  const allowFrom = !isEmpty(rangeList) ? rangeList[0].id : '';
  const allowTo = !isEmpty(rangeList) ? rangeList[rangeList.length - 1].id : '';

  const companionData = getUniqueDataFromCache(viewState, HashType.flowsheetCompanion)?.tree || [];
  const overTimeData = getUniqueDataFromCache(viewState, HashType.flowsheetOverTime)?.tree || [];
  const isCompanionDataLoaded = isDataLoaded(viewState.viewDataStateCompanion);
  const isOverTimeDataLoaded = isDataLoaded(viewState.viewDataStateOverTime);
  const isMassEditDataLoaded = isDataLoaded(viewState.viewDataStateMassEdit);
  const isLiveDataReady = viewState.viewDataStateOverTime === ViewDataState.regularDataReady;

  const originalState = {
    title,
    identityField: keys.idProp,
    treeData: companionData,
    subheaderState: state.subheader,
    subheaderViewDefns: defns.subheader,
    shouldFilterFlowStatus: true,
    showPopover,
    allowFrom,
    allowTo,
    rangeList: daysRangeList,
    ...viewState,
    loaded: isCompanionDataLoaded,
    activeTab: state.perspective.activeTab,
    timeInfo,
    fabType: fabType || FabType.none,
  };

  const stateProps = {
    ...originalState,
    ...projectState(originalState),
    ...viewState,
    scopeStart: get('scope.scope.start', state),
    isOverTimeDataLoaded,
    isMassEditDataLoaded,
    isLiveDataReady,
    overTimeData,
  };
  return stateProps;
}

const sensitiveView = fp.flow(
  () => FlowSheetByStyle,
  withFab,
  makeScopeAndFilterSensitive,
  makePrintSensitive,
  makePopoverSensitive
)();

export default connect(mapStateToProps, dispatchToProps)(sensitiveView);
