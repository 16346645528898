import * as React from 'react';
import { find, fromPairs, compact, flatMap, isNil } from 'lodash/fp';
import { get } from 'lodash';
import { option } from 'fp-ts';
import { map, getOrElse } from 'fp-ts/lib/Option';
import { StylesProvider } from '@material-ui/core';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { TopNavbar } from 'src/components/TopNavbar/TopNavbar';
import ScopeSelector from 'src/components/ScopeSelector/ScopeSelector.container';
import Headerbar from 'src/components/Headerbar/Headerbar.container';
import RightContainer from 'src/components/RightContainer/RightContainer.container';
import { Route, Switch, Redirect } from 'react-router-dom';
import styles, { viewPortStyle } from './NavigationShell.styles';

import {
  BoundTenant,
  ViewComponent,
  BoundPerspective,
  BoundTab,
  BoundSection,
  BoundView,
  TenantTab,
} from 'src/services/configuration/bindings.types';
import { HasPerspectives, HasBindings } from 'src/services/configuration/service';

import { match as Match } from 'react-router-dom';
import { RouteLink } from 'src/types/RouteLink';
import { Sidenav } from 'src/components/Sidenav/Sidenav.container';
import {
  computeTabRouteLinks,
  computeViewRouteLinks,
  computeTabZipRoute,
  computeViewZipRoute,
  computePerspectiveUiRouteSuffix,
} from 'src/pages/NavigationShell/navigationUtils';
import { PrintProps } from 'src/components/higherOrder/Print/Print';

import { Props as ConnectProps } from './NavigationShell.container';
import { RouterProps, RouteComponentProps } from 'react-router';
import { Location } from 'history';

import {
  zipper,
  BoundTenantZipper,
  BoundTabZipper,
  BoundSectionZipper,
  BoundViewZipper,
} from 'src/pages/NavigationShell/NavigationZipper';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import { ErrorBoundary } from './ErrorBoundary';
import { pipe } from 'fp-ts/lib/function';
import { create } from '@material-ui/styles/node_modules/jss';

const jss = create({
  ...jssPreset(),
});

const generateClassName = createGenerateClassName();

type Perceptionable = {
  perspective: string;
  tab: string;
};

export const ASSORTMENT_ROUTE_PATH = '/:perspective/:tab?/:section?/:page?';

export type Props = PrintProps &
  RouterProps & {
    match: Match<Perceptionable>;
    location: any;
  } & ConnectProps;

function componentToRoute(
  id: string,
  path: string,
  viewComponent: ViewComponent,
  viewProps?: { [s: string]: unknown },
  allowNested = false
) {
  const Component = viewComponent;
  /* eslint-disable react/no-children-prop */
  return (
    <Route
      key={id}
      exact={!allowNested}
      path={path}
      children={(routerProps: RouteComponentProps<any>) => {
        if (routerProps.match) {
          return (
            <ErrorBoundary>
              <Component key={routerProps.match.path} {...routerProps} {...viewProps} />
            </ErrorBoundary>
          );
        }
        return;
      }}
    />
  );
}

export interface Bindings {
  [perspective: string]: BoundTenant;
}
interface State {
  currentTab?: string;
}

export default (config: HasPerspectives & HasBindings) => {
  return class NavigationShell extends React.Component<Props, State> {
    unsubscribe!: () => void;
    readonly perspectives: BoundPerspective[];
    readonly bindings: Bindings;
    readonly tabRouteLinks: { [perspective: string]: RouteLink[] };
    constructor(props: Props) {
      super(props);
      // These must be done in the constructor, because at factory invocation time
      // The config probably hasn't been request
      this.perspectives = config.getPerspectives();
      this.bindings = fromPairs(
        this.perspectives.map((perspective) => [perspective.id, config.getBindings(perspective)])
      );
      // Compute all of the route links for each tab
      this.tabRouteLinks = fromPairs(
        compact(
          this.perspectives.map((perspective) =>
            this.bindings[perspective.id]
              ? [perspective.id, computeTabRouteLinks(this.bindings[perspective.id])]
              : undefined
          )
        )
      );
      this.setPerspective = this.setPerspective.bind(this);
      this.isPerspectiveDefined() && this.setPerspective();
      const currentTab = get(props, 'match.params.tab');

      // If first tab has flow status config, apply it
      if (props.tenantConfig && currentTab) {
        const tab = (this.props.tenantConfig.tabs as TenantTab[]).find((x) => x.pathSlot == currentTab);
        if (tab && tab.flowStatusOverride && tab.flowStatusOverride.values) {
          props.updateFlowStatusConfig(tab.flowStatusOverride);
          props.updateFlowStatus(tab.flowStatusOverride.defaultSelection);
        }
      }

      this.state = {
        currentTab,
      };
    }

    componentDidMount() {
      if (this.props.location) {
        this.props.setActivePage(this.props.location.pathname);
        const currentTab = get(this, 'props.match.params.tab');
        if (!isNil(currentTab)) {
          this.props.setActiveTab(currentTab);
        }
      }
      this.unsubscribe = this.props.history.listen((location: Location) => {
        if (this.props.location.pathname !== location.pathname) {
          this.props.setActivePage(location.pathname);
        }
      });
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    setPerspective() {
      const selectedPerspective: BoundPerspective | null =
        this.perspectives.filter((p) => p.pathSlot === this.props.match.params.perspective)[0] || null;
      this.props.setActivePerspective(selectedPerspective);
    }

    isPerspectiveDefined() {
      // If perspective is not defined or improper, send them back to perspective selection
      const id = pipe(
        this.getActivePerspectiveId(),
        getOrElse(() => '')
      );
      if (!this.bindings[id]) {
        window.location.hash = '/';
        return false;
      }
      return true;
    }

    componentDidUpdate(_prevProps: Readonly<Props>, _prevState: Readonly<Record<string, any>>) {
      const currentTab = get(this, 'props.match.params.tab');

      if (this.state.currentTab !== currentTab || (this.props.activeTab === '' && this.state.currentTab !== '')) {
        this.props.setActiveTab(currentTab);
        // Check if old tab had a flowstatus config, and if new one has one
        const oldTab = (this.props.tenantConfig.tabs as TenantTab[]).find((x) => x.pathSlot == this.state.currentTab);
        const tab = (this.props.tenantConfig.tabs as TenantTab[]).find((x) => x.pathSlot == currentTab);
        if (tab && tab.flowStatusOverride && tab.flowStatusOverride.values) {
          this.props.updateFlowStatusConfig(tab.flowStatusOverride);
          this.props.updateFlowStatus(tab.flowStatusOverride.defaultSelection);
        } else if (oldTab && oldTab.flowStatusOverride && oldTab.flowStatusOverride.values) {
          this.props.updateFlowStatusConfig(this.props.tenantConfig.flowStatus);
          this.props.updateFlowStatus(this.props.tenantConfig.flowStatus.defaultSelection);
        }
        this.setState({
          currentTab,
        });
      }

      // If Url is incomplete, autocomplete it
      if (this.isPerspectiveDefined()) {
        const [, tab, section, view] = window.location.hash.split('/').slice(1);
        const viewWithSearch = view && view.split('?')[0];
        const id = pipe(
          this.getActivePerspectiveId(),
          getOrElse(() => '')
        );
        const boundTenant = this.bindings[id];

        const maybeTab = boundTenant.boundTabs.find((x: BoundTab) => x.pathSlot === tab);
        const maybeSection =
          maybeTab &&
          maybeTab.boundSections &&
          maybeTab.boundSections.find((x: BoundSection) => x.pathSlot === section);
        const maybeView =
          maybeSection && maybeSection?.boundViews.find((x: BoundView) => x.pathSlot === viewWithSearch);

        if (!maybeTab || !maybeSection || !maybeView) {
          window.location.hash = computePerspectiveUiRouteSuffix(boundTenant);
        }
      }
    }

    getActivePerspective(): option.Option<BoundPerspective> {
      return option.fromNullable(find((x) => x.pathSlot === this.props.match.params.perspective, this.perspectives));
    }

    getActivePerspectiveId(): option.Option<string> {
      return pipe(
        this.getActivePerspective(),
        map((p) => p.id)
      );
    }

    getViewContentArea(view: BoundViewZipper): JSX.Element {
      // Figure out a good way of allowing the component to access the bound view
      return componentToRoute(
        view.getView().id,
        computeViewZipRoute(view),
        view.getView().component,
        view.getView().componentProps,
        view.getView().allowNesting
      );
    }

    getSectionContentArea(section: BoundSectionZipper): JSX.Element[] {
      return section.getViews().map((view) => this.getViewContentArea(view));
    }

    getTabContentArea(tab: BoundTabZipper): JSX.Element[] {
      const component = tab.getTab().component;
      if (component) {
        return [componentToRoute(tab.getTab().id, computeTabZipRoute(tab), component)];
      }
      return flatMap((section) => this.getSectionContentArea(section), tab.getSections());
    }

    getContentArea(tenantZip: BoundTenantZipper): JSX.Element {
      const components = flatMap((tabZip) => this.getTabContentArea(tabZip), tenantZip.getTabs());
      return <Switch>{components}</Switch>;
    }

    getActiveComponentOverflow(tenantZip: BoundTenantZipper): string {
      let overflow = 'hidden';
      tenantZip.getTabs().forEach((tabZip) =>
        tabZip.getSections().forEach((section) => {
          section.getViews().forEach((view) => {
            const path = computeViewZipRoute(view);
            if (this.props.location.pathname === path) {
              const defaultOverflow = view.getView().overflow;
              if (defaultOverflow) {
                overflow = defaultOverflow;
              }
            }
          });
        })
      );
      return overflow;
    }

    render() {
      const perspective = pipe(
        this.getActivePerspective(),
        getOrElse(() => ({} as BoundPerspective))
      );
      if (isNil(perspective.id) && isNil(perspective.value)) {
        return <Redirect to="/" />;
      }

      const view = this.bindings[perspective.id];
      const tabLinks = this.tabRouteLinks[perspective.id];
      let overflow = this.getActiveComponentOverflow(zipper(view));
      let topNavBar, clearfix, printStyle;
      let mainClass = styles.mainClass;
      let viewPortClass = viewPortStyle;

      if (!this.props.isPrintMode) {
        topNavBar = (
          <TopNavbar
            routeLinks={tabLinks}
            viewLinks={computeViewRouteLinks(view)}
            bindings={this.bindings}
            perspective={perspective}
            location={this.props.location}
          />
        );
      } else {
        const width = this.props.printWidth || 'initial';
        const height = this.props.printHeight || 'initial';
        overflow = 'unset';
        viewPortClass = '';
        printStyle = { display: 'block', width, height };
        clearfix = <div className={'clearfix'} />;
        mainClass = styles.mainClassPrintable;
      }

      let content = <div />;
      if (this.props.validScope) {
        content = (
          <div key={'content'} className={mainClass} style={{ overflow: overflow }}>
            {this.getContentArea(zipper(view))}
          </div>
        );
      }
      return (
        <StylesProvider jss={jss} generateClassName={generateClassName} key={perspective.id}>
          <MuiThemeProvider key={perspective.id} theme={muiTheme}>
            <div key="router-container" data-qa="router-container" className={viewPortClass} style={printStyle}>
              <Route path={ASSORTMENT_ROUTE_PATH} component={ScopeSelector} />
              {topNavBar}
              <Headerbar />
              <div className={styles.belowHeaderBarsDiv}>
                {this.bindings[perspective.id].boundTabs
                  .filter((tab) => tab.boundSections && tab.boundSections.length)
                  .map((tab) => {
                    return (
                      <Route
                        key={`${perspective.id}-${tab.id}`}
                        path={`/${perspective.pathSlot}/${tab.pathSlot}`}
                        component={Sidenav({
                          className: styles.sidenavClass,
                          sectionName: tab.name,
                          perspective,
                          tab,
                        })}
                      />
                    );
                  })}
                {content}
                {this.props.isPrintMode ? undefined : <RightContainer />}
              </div>
              {clearfix}
            </div>
          </MuiThemeProvider>
        </StylesProvider>
      );
    }
  };
};
