import {
  AxisLabelsFormatterContextObject,
  AxisOptions,
  Point,
  Series,
  SeriesBarOptions,
  SeriesLineOptions,
  SeriesOptions,
} from 'highcharts';
import { flatMap, get } from 'lodash';
import { Renderer } from 'src/common-ui/components/DataGrid/Renderer';
import { TEAL_PRIMARY } from 'src/common-ui/theme';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicPivotItem, Pivot } from 'src/worker/pivotWorker.types';

export const axesDefaults: AxisOptions = {
  title: undefined,
  minorGridLineWidth: 0,
  lineColor: 'transparent',
  minorTickLength: 0,
  tickLength: 0,
  labels: {
    style: {
      fontSize: '14px',
    },
  },
};

type ColorsOnTrue = {
  foreground?: string;
  background?: string;
  left?: string;
  right?: string;
};

export type DilineateOption = {
  key: string;
  value: string;
  colorsOnTrue: ColorsOnTrue;
  colorsOnFalse: ColorsOnTrue;
};

export function generateSecondaryGraphConfig(
  { text, dataIndex, renderer }: TenantConfigViewItem,
  data: Pivot
): SeriesLineOptions {
  return {
    type: 'line',
    name: text,
    tooltip: {
      headerFormat: '',
      pointFormatter: function(this: Point) {
        const renderedPointValue = renderer ? Renderer[renderer](this.y) : this.y;
        return `<b>${renderedPointValue}</b>`;
      },
    },
    data: data.tree.map((x) => x[dataIndex]),
  };
}

export function generateLineGraphConfig(
  xAxisLabels: string[],
  dataUnfiltered: number[][][],
  config: TenantConfigViewData,
  selectedBigGraphItem: number
): Highcharts.Options {
  // TODO: remove all projection from here and make it so that it just takes finished objects
  // this sets up metric pairs as axes, defaulting all but the first to visible: false
  const data = dataUnfiltered[selectedBigGraphItem];
  const graphs = config.graphs!;
  const dilineate = config.dilineate!;

  const viewDefn = graphs.primary.filter((_opt, idx) => idx === selectedBigGraphItem);
  const yAxes = flatMap(
    viewDefn.filter((v) => v.left && v.right),
    (view) => {
      // TODO: maybe do something with the tick lines?
      const axesPair: AxisOptions[] = [
        {
          ...axesDefaults,
          title: {
            text: view.left?.text || null,
          },
          opposite: false,
        },
        {
          ...axesDefaults,
          title: {
            text: view.right?.text || null,
          },
          opposite: true,
        },
      ];
      // special axes stuff for percents
      if (view.left?.renderer === 'percent') {
        axesPair[0].max = 100;
        axesPair[0].min = 0;
      }
      if (view.right?.renderer === 'percent') {
        axesPair[1].max = 100;
        axesPair[1].min = 0;
      }
      return axesPair;
    }
  );

  const getZones = (dilineate: DilineateOption | undefined, data: any[], dir: string) => {
    if (dilineate && dilineate.key && data) {
      const indexesTrue: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] === dilineate.value ? a.concat(i) : a),
        []
      );
      const indexesFalse: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] !== dilineate.value ? a.concat(i) : a),
        []
      );
      let zones = [
        {
          value: indexesFalse[0] + 1,
          color: dilineate.colorsOnFalse
            ? dilineate.colorsOnFalse[dir] || dilineate.colorsOnFalse.foreground
            : undefined,
        },
        {
          value: indexesFalse[indexesFalse.length - 1],
          color: dilineate.colorsOnFalse
            ? dilineate.colorsOnFalse[dir] || dilineate.colorsOnFalse.foreground
            : undefined,
        },
      ];
      if (indexesTrue.length > 0) {
        zones = zones
          .concat([
            {
              value: indexesTrue[0],
              color: dilineate.colorsOnTrue
                ? dilineate.colorsOnTrue[dir] || dilineate.colorsOnTrue.foreground
                : undefined,
            },
            {
              value: indexesTrue[indexesTrue.length - 1] + 1,
              color: dilineate.colorsOnTrue
                ? dilineate.colorsOnTrue[dir] || dilineate.colorsOnTrue.foreground
                : undefined,
            },
          ])
          .sort((x, y) => x.value - y.value);
        return zones;
      }
    }
    return undefined;
  };
  const getPlotbands = (dilineate: DilineateOption | undefined, data: any[]) => {
    if (dilineate && dilineate.key && data) {
      const indexesTrue: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] === dilineate.value ? a.concat(i) : a),
        []
      );
      const indexesFalse: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] !== dilineate.value ? a.concat(i) : a),
        []
      );
      let plotbands = [
        {
          color: get(dilineate, 'colorsOnFalse.background'),
          from: indexesFalse[0],
          to: indexesFalse[indexesFalse.length - 1],
        },
      ];
      if (indexesTrue.length > 0) {
        plotbands = plotbands.concat([
          {
            color: get(dilineate, 'colorsOnTrue.background'),
            from: indexesTrue[0],
            to: indexesTrue[indexesTrue.length - 1] + 1,
          },
        ]);
      }
      return plotbands;
    }
    return undefined;
  };
  const series: (SeriesLineOptions | SeriesBarOptions)[] = [
    {
      // @ts-ignore
      type: viewDefn[0].left?.chartType || 'line',
      name: viewDefn[0].left!.text || viewDefn[0].left!.dataIndex,
      description: viewDefn[0].left!.text, // used for screenreaders only
      data: data[0],
      color: viewDefn[0].left?.color || undefined,
      tooltip: {
        pointFormatter: function(this: Point) {
          const seriesName = this.series.name;
          const category = this.category;
          const renderedPointValue = viewDefn[0].left?.renderer ? Renderer[viewDefn[0].left.renderer](this.y) : this.y;
          return `${category} <br /> ${seriesName} : <b>${renderedPointValue}</b>`;
        },
      },
      zones: getZones(dilineate, data[0], 'left'),
      zoneAxis: 'x',
    },
    {
      // @ts-ignore
      type: viewDefn[0].right?.chartType || 'column',
      name: viewDefn[0].right!.text || viewDefn[0].right!.dataIndex,
      description: viewDefn[0].left!.text, // used for screenreaders only
      data: data[1],
      color: viewDefn[0].right?.color || undefined,
      tooltip: {
        pointFormatter: function(this: Point) {
          const seriesName = this.series.name;
          const category = this.category;
          const renderedPointValue = viewDefn[0].right?.renderer
            ? Renderer[viewDefn[0].right.renderer](this.y)
            : this.y;
          return `${category} <br /> ${seriesName} : <b>${renderedPointValue}</b>`;
        },
      },
      zones: getZones(dilineate, data[1], 'right'),
      zoneAxis: 'x',
    },
  ];

  return {
    credits: { enabled: false },
    chart: {
      height: '420px',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: {
      text: '',
    },
    yAxis: yAxes,

    xAxis: {
      categories: xAxisLabels,
      title: {
        text: '',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        formatter: function(this: AxisLabelsFormatterContextObject) {
          // TODO: map week ids to names here?
          return `<span style="font-style: italic;">${this.value}</span>`;
        },
      },
      plotBands: getPlotbands(dilineate, data[1]),
    },
    series: series,
  };
}

export function generateBarGraphConfig(selectedBarGraphItem: number, series: SeriesOptions[]): Highcharts.Options {
  return {
    credits: { enabled: false },
    title: {
      text: '',
    },
    chart: {
      type: 'line',
      height: '120px',
    },
    xAxis: {
      title: {
        text: '',
      },
      minorGridLineWidth: 0,
      minorTickLength: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      gridLineWidth: 0,
      min: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        //pointPadding: 20,
        //borderWidth: 0,
        //pointWidth: 10,
        color: TEAL_PRIMARY,
      },
      series: {
        color: 'black',
        events: {
          legendItemClick: function(this: Series): boolean | void {
            this.chart.series.forEach((seri) => {
              if (seri !== this && seri.visible) {
                seri.hide();
              }
            });
            return !this.visible ? true : false;
          },
        },
      },
    },
    // @ts-ignore
    series: series.filter((x, i) => i == selectedBarGraphItem),
  };
}

export function formatHighlightMetricsData(): BasicPivotItem[] {
  return ([
    {
      __weeks: 18,
      __grossmargin: 0.52,
      __slsr: 85000,
    },
  ] as any[]) as BasicPivotItem[];
}
