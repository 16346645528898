import * as React from 'react';
import { Indexable } from 'src/types/Primitive';
import { AppState } from 'src/store';
import { WrappedDispatch, wrappedDispatch } from 'src/utils/Redux/Dispatch';
import { identity } from 'src/utils/Primitive/Function';
import { connect } from 'react-redux';
import { DataGrid } from 'src/common-ui/index';
import { getServerFilterSelections } from 'src/utils/Filter/Filters';
import { FilterSelection } from 'src/common-ui/components/Filters/Filters';
import { DataGridProps } from 'src/common-ui/components/DataGrid/DataGrid';
import Renderer from 'src/utils/Domain/Renderer';
import getWeekRangeText from 'src/utils/Functions/Description';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import container from 'src/ServiceContainer';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';

type FilterSensitiveProps = any;
type InternalFilterSensitiveProps = {
  customHeader: string;
  parentProps: Indexable;
};

export function getHeaderTextFromState(state: AppState, skipFilters = false): string {
  const filterSelections: FilterSelection[] = getServerFilterSelections(state.filters.state || []);
  const selectedFilters = filterSelections.map((filterSelection) => filterSelection.id).toString();
  const filterText = skipFilters ? '' : `Filters: ${selectedFilters} \r\n`;

  const view = state.perspective.activePage;
  const currentTab = state.perspective.activeTab;

  const { productMemberName, locationMemberName, historyStart, historyEnd, start, end } = state.scope.scope;
  const { pastRangeList, rangeList } = state.scope;

  const currentScope = IS_HINDSIGHTING(currentTab)
    ? getWeekRangeText(historyStart, historyEnd, pastRangeList)
    : getWeekRangeText(start, end, rangeList);

  return `${productMemberName} | Location - ${locationMemberName} | ${currentScope} \r\n${filterText}Exported from: ${view}`;
}

export function makeDataGridFilterSensitive<T extends React.ComponentClass>(WrappedComponent: T) {
  function mergeProps(state: AppState, _wrapped: WrappedDispatch, parentProps: DataGridProps) {
    const customHeader = getHeaderTextFromState(state);
    const props: InternalFilterSensitiveProps = {
      customHeader,
      parentProps,
    };
    return {
      ...props,
      parentProps,
    };
  }

  class DataGridFilterSensitive extends React.Component<FilterSensitiveProps> {
    render() {
      const additionalExcelExports = {
        customHeader: this.props.customHeader,
        excelRendererObj: Renderer.getAllExcelRenderers(),
      };
      return (
        <React.Fragment>
          <ErrorBoundary errorTitle={'Something has gone wrong with the grid'} serviceEnv={container}>
            <WrappedComponent
              {...this.props.parentProps}
              exportOptions={{
                ...additionalExcelExports,
                ...this.props.parentProps.exportOptions,
              }}
            />
          </ErrorBoundary>
        </React.Fragment>
      );
    }
  }
  // @ts-ignore
  return connect(identity, wrappedDispatch, mergeProps)(DataGridFilterSensitive);
}

// @ts-ignore
export default makeDataGridFilterSensitive(DataGrid);
