import * as Actions from './MacroMix.actions';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem, RegionItem } from 'src/worker/pivotWorker.types';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';

export type MacroMixReduxSlice = ReduxSlice;
type ReduxSlice = {
  allConf?: TenantConfigViewData;
  chartData?: BasicPivotItem[];
  data?: BasicPivotItem[];
  dataLoaded: boolean;
  chartDataLoaded: boolean;
  confLoaded: boolean;
  dataOutOfData: boolean;
  selectedLevels?: TenantConfigViewItem;
  selectedBox?: ExtendedPointObject;
  regions?: RegionItem[];
};

const initialState: ReduxSlice = {
  dataLoaded: false,
  chartDataLoaded: false,
  dataOutOfData: true,
  confLoaded: false,
};

export default function macroMixReducer(state: ReduxSlice = initialState, action: Actions.Action): ReduxSlice {
  switch (action.type) {
    case Actions.REQUEST_VIEW_CONFIG: {
      return {
        ...state,
        confLoaded: false,
      };
    }
    case Actions.RECEIVE_VIEW_CONFIG: {
      return {
        ...state,
        allConf: action.payload,
        confLoaded: true,
      };
    }
    case Actions.REQUEST_MIX_DATA: {
      return {
        ...state,
        dataLoaded: false,
      };
    }
    case Actions.RECEIVE_MIX_DATA: {
      return {
        ...state,
        dataLoaded: true,
        data: action.payload.listData,
        regions: action.payload.regions,
      };
    }
    case Actions.REQUEST_CHART_DATA: {
      return {
        ...state,
        chartDataLoaded: false,
      };
    }
    case Actions.RECEIVE_CHART_DATA: {
      return {
        ...state,
        chartDataLoaded: true,
        chartData: action.payload,
      };
    }
    case Actions.SELECT_BOX: {
      return {
        ...state,
        selectedBox: action.payload,
      };
    }
    case Actions.UPDATE_LEVELS: {
      return {
        ...state,
        selectedLevels: action.payload,
      };
    }
    case Actions.CLEAR_CHART_DATA: {
      return {
        ...state,
        chartDataLoaded: false,
        chartData: undefined,
        selectedBox: undefined,
      };
    }
    case Actions.RECEIVE_MIX_DATA_ERROR:
    case Actions.RECEIVE_VIEW_CONFIG_ERROR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
