import { flow } from 'lodash';
import { capitalize, find } from 'lodash/fp';
import { Lens } from 'monocle-ts';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { update } from 'src/services/lenses/Lenses.actions';
import { createEmptySlice } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/TargetSetting.reducer';
import container from 'src/ServiceContainer';
import { AppDispatch, AppState, AppThunkDispatch } from 'src/store';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { Renderer } from 'src/utils/Domain/Renderer';

import {
  getLYData,
  getMFPData,
  getTargetInitialInfo,
  getTargetItem,
  TargetType,
  TargetVersion,
  upsertTargetItem,
} from '../Criteria/Criteria.client';
import { default as TargetSetting } from './TargetSetting';
import { CriteriaOption, LYTargetData } from '../Criteria/Criteria.types';
import { SeedPageSlice, TargetSettingNewSlice } from '../TargetCreation/TargetCreation.types';
import {
  FunctionProps,
  OwnProps,
  TargetRequest,
  TargetSettingConfiguration,
  TargetSettingReduxSlice,
  ValueProps,
} from './TargetSetting.types';

function versionToName(version: TargetVersion) {
  switch (version) {
    case 'WP':
      return 'Working Plan';
    case 'OP':
      return 'Original Plan';
    case 'RP':
      return 'Revised Plan';
    default:
      return 'Working Plan';
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps<AppState>): ValueProps {
  const { version } = ownProps.match.params;
  const targetSetting = ownProps.lens.get(state);
  let summary;
  if (targetSetting.targetSetup != null) {
    const { salesStart, salesEnd, topCriteriaName, topCriteriaValueName, criteriaName } = targetSetting.targetSetup;
    summary = `Version: ${versionToName(version)} \
    | Target Time Period: ${salesStart} to ${salesEnd} \
    | Target Top Level: ${topCriteriaName} - ${topCriteriaValueName} \
    | Target Criteria: ${criteriaName} \
    | Seed: Total Sales - ${Renderer.usMoney(targetSetting.seedValue)}`;
  }
  const returnObj: ValueProps = {
    rowData: targetSetting.rowData,
    config: targetSetting.viewConf,
    seedValue: targetSetting.seedValue,
    seedDataIndex: targetSetting.seedDataIndex,
    isLoading: targetSetting.isLoading,
    summary,
    reconcileConfig: targetSetting.reconcileViewConf,
  };
  if (targetSetting.targetSetup) {
    returnObj.floorset = targetSetting.targetSetup.floorSet;
    returnObj.topCriteria = targetSetting.targetSetup.topCriteria;
  }
  return returnObj;
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps<AppState>): FunctionProps {
  function updateSeedOptions(setup: TargetRequest, amount?: number) {
    const seedOptionsLens = ownProps.targetCreationLens
      .compose(Lens.fromProp<TargetSettingNewSlice>()('seedPage'))
      .compose(Lens.fromProps<SeedPageSlice>()(['criteria', 'weeks', 'amount']));
    const { seedStart, seedEnd, seedName = '', seedBasisName = '' } = setup;
    dispatch(
      update(
        seedOptionsLens.set({
          criteria: {
            options: [],
            selections: [
              { text: seedName, dataIndex: seedName },
              { text: seedBasisName, dataIndex: seedName },
            ],
          },
          weeks: {
            loaded: true,
            rangeList: [],
            daysRangeList: { end_date: {}, start_date: {} },
            selectedStartWeek: seedStart,
            selectedEndWeek: seedEnd,
          },
          amount,
        }),
        'Updating Seed Options from Target.'
      )
    );
  }
  const lens = ownProps.lens;
  const client = container.tenantConfigClient;
  const targetType = capitalize(ownProps.match.params.type) as TargetType;
  return {
    onShowView: () => {
      dispatch(async (_innerDispatch: AppDispatch) => {
        const { type, targetKey, version } = ownProps.match.params;
        const confName = targetType === 'Product' ? 'StrategyProductTargetSetting' : 'StrategyLocationTargetSetting';
        const viewConfs = await client
          .getTenantViewDefns<TargetSettingConfiguration>({
            defnIds: [confName, 'TargetSettingReconcile'],
            appName: ASSORTMENT,
          })
          .then((resp) => {
            return resp;
          });
        const viewConf = viewConfs[0];
        const reconcileViewConf = viewConfs[1];

        if (targetKey) {
          return getTargetItem({
            id: targetKey,
            version,
            type,
          }).then((resp) => {
            if (resp == null) {
              toast.error('Target does not exist.', {
                position: toast.POSITION.TOP_LEFT,
              });
              return;
            }
            dispatch(
              update(
                ownProps.lens
                  .compose(
                    Lens.fromProps<TargetSettingReduxSlice>()([
                      'rowData',
                      'targetSetup',
                      'viewConf',
                      'shouldInitialize',
                      'seedValue',
                      'reconcileViewConf',
                    ])
                  )
                  .set({
                    rowData: resp.data.rowData,
                    targetSetup: resp.data.targetSetup,
                    viewConf,
                    shouldInitialize: resp.data.shouldInitialize,
                    seedValue: resp.data.seedValue,
                    reconcileViewConf: reconcileViewConf,
                  }),
                'Loaded TargetItem from DB'
              )
            );
            updateSeedOptions(resp.data.targetSetup, resp.data.seedValue);
          });
        }
        return null;
      });
    },
    onDestroy: () => {
      dispatch(update(lens.set(createEmptySlice()), 'Destroy Target Setting'));
      dispatch(async (_innerDispatch: AppDispatch, getState: () => AppState) => {
        const seedLens = ownProps.targetCreationLens.compose(Lens.fromPath<TargetSettingNewSlice>()(['seedPage']));
        const { rangeList, daysRangeList } = seedLens.compose(Lens.fromProp<SeedPageSlice>()('weeks')).get(getState());
        update(
          seedLens.set({
            criteria: {
              options: [],
              selections: [],
            },
            weeks: {
              loaded: false,
              rangeList,
              daysRangeList,
            },
          }),
          'Clearing Seed Options on Target Destroy'
        );
      });
    },
    publishTarget: (rowData: LYTargetData[]) => {
      dispatch((_innerDispatch: AppDispatch, getState: () => AppState) => {
        const { type, targetKey } = ownProps.match.params;
        return getTargetItem({
          id: targetKey,
          version: 'OP',
          type,
        })
          .then<TargetVersion>((data: unknown) => {
            if (data == null) {
              return 'OP';
            } else {
              return 'RP';
            }
          })
          .then((version) => {
            const { seedValue, targetSetup } = ownProps.lens.get(getState());
            return upsertTargetItem({
              type,
              id: targetKey,
              version: version,
              data: {
                rowData,
                seedValue,
                targetSetup,
                shouldInitialize: false,
              },
            }).then(() => {
              toast.info(`Successfully published ${version}.`, {
                position: toast.POSITION.TOP_LEFT,
              });
              updateSeedOptions(targetSetup!, seedValue);
            });
          });
      });
    },
    saveTarget: (rowData: LYTargetData[]) => {
      dispatch((_innerDispatch: AppDispatch, getState: () => AppState) => {
        const { type, targetKey } = ownProps.match.params;
        const { seedValue, targetSetup } = ownProps.lens.get(getState());
        return upsertTargetItem({
          type,
          id: targetKey,
          version: 'WP',
          data: {
            rowData,
            targetSetup,
            seedValue,
            shouldInitialize: false,
          },
        }).then(() => {
          toast.info(`Successfully saved working copy.`, {
            position: toast.POSITION.TOP_LEFT,
          });
          updateSeedOptions(targetSetup!, seedValue);
        });
      });
    },
    setSeed: () => {
      dispatch(async (innerDispatch: AppDispatch, getState: () => AppState) => {
        const seedPage = ownProps.targetCreationLens
          .compose(Lens.fromProp<TargetSettingNewSlice>()('seedPage'))
          .get(getState());
        const curr: TargetSettingReduxSlice = ownProps.lens.get(getState());
        let newSeed;
        if (curr.targetSetup == null || seedPage.weeks.loaded === false) {
          newSeed = seedPage.amount;
          dispatch(
            update(
              lens
                .compose(Lens.fromProp<TargetSettingReduxSlice>()('targetSetup'))
                .compose(Lens.fromProps<TargetRequest>()(['seedName']))
                .set({
                  seedName: seedPage.criteria.selections[0].text,
                }),
              'Updating Seed Info'
            )
          );
        } else {
          const setupReq = {
            ...curr.targetSetup!,
            seedStart: seedPage.weeks.selectedStartWeek,
            seedEnd: seedPage.weeks.selectedEndWeek,
            seedName: seedPage.criteria.selections[0].text,
          };
          const seedUpdateLens = lens
            .compose(Lens.fromProp<TargetSettingReduxSlice>()('targetSetup'))
            .compose(Lens.fromProps<TargetRequest>()(['seedStart', 'seedEnd', 'seedName']));
          dispatch(
            update(
              seedUpdateLens.set({
                seedStart: seedPage.weeks.selectedStartWeek,
                seedEnd: seedPage.weeks.selectedEndWeek,
                seedName: setupReq.seedName,
              }),
              'Updating Seed Info'
            )
          );
          if (seedPage.criteria.selections[0].dataIndex === 'mfp') {
            newSeed = await getMFPData(setupReq, targetType);
          } else if (seedPage.criteria.selections[0].dataIndex === 'ly') {
            setupReq.lySalesStart = setupReq.seedStart;
            setupReq.lySalesEnd = setupReq.seedEnd;
            newSeed = await getLYData(setupReq, targetType);
          }
        }
        if (newSeed != null) {
          dispatch(
            update(
              lens.compose(Lens.fromProp<TargetSettingReduxSlice>()('seedValue')).set(newSeed),
              'Update seed value'
            )
          );
        }
      });
    },
    import: (version) => {
      const { type, targetKey } = ownProps.match.params;
      return getTargetItem({
        id: targetKey,
        version,
        type,
      }).then((resp) => {
        if (resp == null) {
          return;
        }
        dispatch(
          update(
            ownProps.lens
              .compose(
                Lens.fromProps<TargetSettingReduxSlice>()(['rowData', 'targetSetup', 'shouldInitialize', 'seedValue'])
              )
              .set({
                rowData: resp.data.rowData,
                targetSetup: resp.data.targetSetup,
                shouldInitialize: resp.data.shouldInitialize,
                seedValue: resp.data.seedValue,
              }),
            'Loaded TargetItem from DB'
          )
        );
      });
    },
    reinitTarget: () => {
      dispatch(async (innerDispatch: AppDispatch, getState: () => AppState) => {
        const viewDefns = targetType === 'Product' ? ['StrategyProductCriteria'] : ['StrategyLocationCriteria'];
        const conf = (
          await client.getTenantViewDefns<any>({
            defnIds: viewDefns,
            appName: ASSORTMENT,
          })
        )[0];
        const { targetSetup } = lens.get(getState());
        if (targetSetup == null) {
          return;
        }
        const targetApi = flow(
          find((option: CriteriaOption) => {
            return option.dataIndex === targetSetup!.criteria;
          }),
          (res) => (res != null ? `${res.api}:${res.dataIndex}` : 'SHOULDBREAKUNDEF')
        )(conf.targetLevelOptions);
        const aggBy = `${conf.apiRoot},${targetApi}`;
        innerDispatch(
          update(
            lens.compose(Lens.fromProp<TargetSettingReduxSlice>()('isLoading')).set(true),
            'Target Setting is reinitializing'
          )
        );
        const newData = await getTargetInitialInfo(targetSetup, targetType, aggBy);
        innerDispatch(
          update(
            lens.compose(Lens.fromProps<TargetSettingReduxSlice>()(['rowData', 'isLoading'])).set({
              rowData: newData.rowData,
              isLoading: false,
            }),
            'Target Setting is done reinitializing'
          )
        );
      });
    },
  };
}

// @ts-ignore
export const TargetSettingContainer = connect(mapStateToProps, mapDispatchToProps)(TargetSetting);
