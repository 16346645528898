import * as React from 'react';
import { modal } from './ConfirmationModal.styles';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export type ConfirmationModalProps = {
  isOpen: boolean;
  descriptionText: string;
  onConfirm?(): void;
  onCancel?(): void;
};

export default class ConfirmationModal extends React.Component<ConfirmationModalProps> {
  constructor(props: ConfirmationModalProps) {
    super(props);
  }

  onModalClose(confirmed: boolean) {
    if (confirmed) {
      if (this.props.onConfirm) {
        this.props.onConfirm();
      }
    } else {
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    }
  }

  render() {
    const { isOpen, descriptionText } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this.onModalClose(false)}
        modalClassName={modal + ' configure-modal'}
        size={'lg'}
      >
        <ModalHeader>
          <span className="left-container">
            <i className="fal fa-question-circle icon" />
            {'Confirm'}
          </span>
          <span className="right-container">
            <i className="far fa-times" onClick={() => this.onModalClose(false)} />
          </span>
        </ModalHeader>
        <ModalBody>
          <p className="instructions">{descriptionText}</p>
          <footer>
            <button onClick={() => this.onModalClose(true)} className="apply">
              <i className="far fa-check" />
              Apply
            </button>
            <button onClick={() => this.onModalClose(false)} className="reset">
              <i className="fas fa-ban" />
              Cancel
            </button>
          </footer>
        </ModalBody>
      </Modal>
    );
  }
}
