import { style } from 'typestyle';
import { TEAL_PRIMARY } from '../../../theme';

const buildInputStyle = (valid: boolean | undefined) => {
  const validShadow = 'rgba(0, 168, 155, 0.25)';
  const invalidShadow = 'rgba(255, 0, 0, 0.25)';
  const shadowColor = valid ? validShadow : invalidShadow;

  return style({
    color: TEAL_PRIMARY,
    border: 'none',
    paddingLeft: 0,
    margin: '5px 3px',
    $nest: {
      '&:focus': {
        boxShadow: `0 0 0 0.2rem ${shadowColor}`,
      },
    },
  });
};

const inputWrapper = style({
  position: 'relative',
});

const invalidWrapper = style({
  $nest: {
    '&::before': {
      color: '#ff0000',
      content: `"\\f12a"`, // exclamation point
      fontFamily: `"Font Awesome 5 Free"`,
      fontWeight: 900,
      position: 'absolute',
      top: '6px',
      left: '-7px',
    },
  },
});

export default {
  buildInputStyle,
  inputWrapper,
  invalidWrapper,
};
