import {
  Action,
  CLEAN_UP_SUMMARY,
  RECEIVE_SUMMARY_CONFIGS,
  RECEIVE_SUMMARY_DATA,
  RECEIVE_SUMMARY_ERROR,
  REQUEST_CHOICE_PRODUCTIVITY_DATA,
  RECEIVE_CHOICE_PRODUCTIVITY_DATA,
  REQUEST_SUMMARY_CONFIGS,
  REQUEST_SUMMARY_DATA,
  ViewDefns,
  ViewData,
} from './Summary.actions';

export type SummarySlice = {
  areViewDefnsLoading?: boolean;
  flowStatusUpdated?: boolean;
  isViewDataLoading?: boolean;
  viewDefns?: ViewDefns;
  viewData?: ViewData;
};

const initialState: SummarySlice = {
  areViewDefnsLoading: true,
  flowStatusUpdated: true,
  isViewDataLoading: true,
};

export default function summaryReducer(state: SummarySlice = initialState, action: Action): SummarySlice {
  switch (action.type) {
    case REQUEST_SUMMARY_CONFIGS:
      return {
        ...state,
      };
    case RECEIVE_SUMMARY_CONFIGS:
      return {
        ...state,
        viewDefns: action.configs,
        areViewDefnsLoading: false,
      };
    case REQUEST_SUMMARY_DATA:
    case REQUEST_CHOICE_PRODUCTIVITY_DATA:
      return {
        ...state,
        isViewDataLoading: true,
      };
    case RECEIVE_SUMMARY_DATA:
      return {
        ...state,
        viewData: action.data,
        isViewDataLoading: false,
      };
    case RECEIVE_CHOICE_PRODUCTIVITY_DATA:
      return {
        ...state,
        viewData: {
          ...state.viewData!,
          choiceProductivity: action.data,
        },
        isViewDataLoading: false,
      };
    case RECEIVE_SUMMARY_ERROR:
    case CLEAN_UP_SUMMARY:
      return initialState;
    default:
      return state;
  }
}
