import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { CHANGE_SCOPE_SELECTION, RECEIVE_SCOPE } from 'src/components/ScopeSelector/ScopeSelector.actions';
import { resetAssortmentCart } from './AssortmentAdd/AssortmentCart/AssortmentCart.slice';

export const resetCartEpic: AppEpic = (action$) => {
  return action$.pipe(
    ofType(CHANGE_SCOPE_SELECTION, RECEIVE_SCOPE),
    mergeMap(() => {
      // SPIKE: this fires when the user selects, but does not submit, new weeks in scope selection
      // should we clear cart at that time?
      // whenever scope changes, dump the cart
      return of(resetAssortmentCart());
    })
  );
};
