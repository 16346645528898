import * as React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { classes } from 'typestyle';

import { FilterSelection } from 'src/common-ui/components/Filters/Filters';

import serviceContainer from 'src/ServiceContainer';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { RangeItem } from 'src/types/Scope';
import styles, { buildFilterIconClass } from './Headerbar.styles';
import { default as HeaderbarItem } from './HeaderbarItem';
import { DispatchProps } from './Headerbar.container';
import { StateProjection } from './Headerbar.selector';
import getWeekRangeText from 'src/utils/Functions/Description';
import { makeHeaderbarPlanSensitive, PlanSensitiveProps } from './AssortmentPlanSensitive';
import { RED_PRIMARY } from 'src/utils/Style/Theme';
import { TEAL_PRIMARY } from 'src/common-ui/theme';

import ReconcileModal from 'src/components/Reconcilation/ReconcileModal';
import { noop, debounce, isEmpty, omit, isNil, values, get } from 'lodash';
import RestoreSession from 'src/components/RestoreSession/RestoreSession.container';
import { getSessionPerspective, getSessionPage, getSessionScope } from '../RestoreSession/RestoreSession.utils';

type HeaderbarProps = StateProjection & DispatchProps & PrintProps & PlanSensitiveProps;
type HeaderbarState = {
  filter: {
    popoverOpen: boolean;
  };
  printSizeOpen?: boolean;
  reconcileOpen?: boolean;
  hoverFilterPopover: boolean;
};

export class Headerbar extends React.Component<HeaderbarProps, HeaderbarState> {
  checkPlanIntervalId: NodeJS.Timer | null;
  private delayClosingFilterPopup: any;
  constructor(props: HeaderbarProps) {
    super(props);
    this.state = {
      filter: {
        popoverOpen: false,
      },
      reconcileOpen: false,
      hoverFilterPopover: false,
    };
    this.handleClickOnFilters = this.handleClickOnFilters.bind(this);
    this.handleClickOnScope = this.handleClickOnScope.bind(this);
    this.checkPlanIntervalId = null;

    const closeFilterPopup = () => {
      if (!this.state.hoverFilterPopover && this.state.filter.popoverOpen) {
        this.setState({
          filter: {
            popoverOpen: false,
          },
          hoverFilterPopover: false,
        });
      }
    };
    this.delayClosingFilterPopup = debounce(closeFilterPopup, 300);
  }

  handleClickOnPrint(size: { width: number; height: number }) {
    serviceContainer.printService.openPrint(size.width, size.height);
    this.togglePrintPopover();
  }

  togglePrintPopover = () => {
    this.setState({
      printSizeOpen: !this.state.printSizeOpen,
    });
  };

  toggleReconcileModal = () => {
    this.setState({
      reconcileOpen: !this.state.reconcileOpen,
    });
  };

  handleClickOnScope() {
    this.props.onToggleScopeSelector();
  }

  handleClickOnFilters() {
    const { filters, onOpenFilters } = this.props;
    if (filters.isFilterSensitive) {
      onOpenFilters();
    }
    this.handleMouseLeaveFilterPopover();
  }

  handleMouseOverFilter() {
    this.setState({
      filter: {
        popoverOpen: true,
      },
    });
  }

  handleMouseOutFilter() {
    this.delayClosingFilterPopup();
  }

  handleMouseOverFilterPopover() {
    this.setState({
      filter: {
        popoverOpen: true,
      },
      hoverFilterPopover: true,
    });
  }

  handleMouseLeaveFilterPopover() {
    this.delayClosingFilterPopup.cancel();
    this.setState({
      filter: {
        popoverOpen: false,
      },
      hoverFilterPopover: false,
    });
  }

  getDescriptionFromId(id: string, rangeItems: RangeItem[]) {
    const foundRangeItem = rangeItems.length && rangeItems.find((item) => item.id === id);
    return (foundRangeItem && foundRangeItem.description) || '';
  }

  renderAvailablePrintActions() {
    const { isViewPrintable, isPrintMode } = this.props;

    if (!isViewPrintable) {
      return null;
    }

    const printSizes = this.props.printSizes.map((size) => {
      const printLabel = size.name ? size.name : `${size.width}in x ${size.height}in`;
      return (
        <li onClick={() => this.handleClickOnPrint(size)} key={size.width} data-qa-key={size}>
          <a>{printLabel}</a>
        </li>
      );
    });

    return (
      <li>
        {isPrintMode && <HeaderbarItem iconClass="far fa-print" onClick={() => window.print()} text="Print" />}
        <a
          id="headerbar-print"
          className={classes(styles.headerbarRightLink)}
          href="javascript:void(null)"
          onClick={this.togglePrintPopover}
          data-qa="headerbar-print"
        >
          <i className={isPrintMode ? 'fal fa-expand-arrows' : 'far fa-print'} data-qa="headerbar-print-icon" />
          <span>{isPrintMode ? 'Configure Page Size' : 'Print'}</span>
        </a>
        <Popover
          toggle={this.togglePrintPopover}
          placement="bottom"
          isOpen={this.state.printSizeOpen}
          target="headerbar-print"
        >
          <PopoverHeader>Paper Size</PopoverHeader>
          <PopoverBody style={{ padding: 0 }}>
            <ul className={styles.selectorDropDown}>{printSizes}</ul>
          </PopoverBody>
        </Popover>
      </li>
    );
  }

  render() {
    const { filter } = this.state;
    const {
      scope,
      filters,
      scopeRangesKey,
      scopeStartKey,
      scopeEndKey,
      isTopDownView,
      hideAssortmentCart,
      quickReconConf,
    } = this.props;
    const { productMemberName, locationMemberName } = scope;

    const begin = scope[scopeStartKey];
    const end = scope[scopeEndKey];
    const ranges = this.props[scopeRangesKey];

    let scopeText = '';
    if (productMemberName && locationMemberName && scopeStartKey && scopeEndKey) {
      scopeText = `Product - ${productMemberName} | Location - ${locationMemberName} | ${getWeekRangeText(
        begin,
        end,
        ranges
      )}`;
    }

    const filterSelections: FilterSelection[] = filters.lastSelections;
    const selectedFilters = filterSelections.map((filterSelection) => {
      return <li key={filterSelection.id}>{filterSelection.id}</li>;
    });

    const tabRequiresReconcile = !isNil(quickReconConf);
    const showQuickReconcile = tabRequiresReconcile && !isTopDownView;

    let actionItems = [
      {
        text: 'Scope',
        iconClass: 'far fa-crosshairs',
        onClick: () => this.handleClickOnScope(),
      },
    ];

    let returnFromScopeButton;
    if (this.props.isPrintMode) {
      actionItems = [];
      returnFromScopeButton = (
        <HeaderbarItem
          text={'Exit Print Mode'}
          iconClass={'fal fa-step-backward'}
          onClick={() => serviceContainer.printService.setPrintMode(false)}
          style={{ marginRight: 25, fontWeight: 'bold' }}
        />
      );
    }

    const items = actionItems.map(({ text, iconClass, onClick }, index) => (
      <HeaderbarItem key={index} iconClass={iconClass} onClick={onClick} text={text} />
    ));

    const shouldFiltersBeEnabled = Boolean(filters.isFilterSensitive && filterSelections.length > 0);

    let planSensitive: JSX.Element | undefined;
    if (this.props.assortmentPlanInProgress) {
      planSensitive = (
        <li>
          <a className={classes(styles.headerbarRightLink)}>
            <span>
              <span className={styles.smallSpinner} />
              <span style={{ color: RED_PRIMARY }}>Planning in progress</span>
            </span>
          </a>
        </li>
      );
    } else if (this.props.askForRefresh) {
      planSensitive = (
        <li className={styles.marqueLi}>
          <a className={classes(styles.headerbarRightLink, 'clickable')} onClick={this.props.refreshPage}>
            <span style={{ color: TEAL_PRIMARY }}>Plan Updated. Click to refresh.</span>
          </a>
        </li>
      );
    }
    const hasLocalStorage = !isNil(getSessionPage()) && !isNil(getSessionPerspective());
    const scopeDefined = !values(omit(getSessionScope(), ['floorset'])).every(isEmpty);
    const currentScopeUndefined = values(omit(scope, ['floorset'])).every(isEmpty) && this.props.rangeList.length > 0;
    const qrTitle = get(quickReconConf, 'title', 'Quick Reconcile');
    return (
      <div className={`navbar navbar-expand-lg ${styles.headerbarStyle}`}>
        <span className="nav navbar-nav navbar-left" data-qa="HeaderbarLeftSide">
          {returnFromScopeButton}
          <span>{scopeText}</span>
        </span>
        <ul className="nav navbar-nav ml-auto navbar-right" data-qa="HeaderbarRightSide">
          {planSensitive}
          {items}
          {showQuickReconcile && (
            <li>
              <a
                id="headerbar-reconcile"
                className={classes(styles.headerbarRightLink)}
                href="javascript:void(null)"
                onClick={this.toggleReconcileModal}
              >
                <i className="fa fa-dollar-sign" data-qa="headerbar-reconcile-icon" />
                <span>{qrTitle}</span>
              </a>
            </li>
          )}
          {this.renderAvailablePrintActions()}
          <li>
            <a
              id="headerbar-filter"
              className={classes(styles.headerbarRightLink, `${filters.isFilterSensitive ? '' : 'disabled'}`)}
              href="javascript:void(null)"
              onClick={() => this.handleClickOnFilters()}
              onMouseOver={() => this.handleMouseOverFilter()}
              onMouseLeave={() => this.handleMouseOutFilter()}
              data-qa="headerbar-filter"
            >
              <i className={buildFilterIconClass(shouldFiltersBeEnabled)} data-qa="headerbar-filter-icon" />
              <span>Filters</span>
            </a>
            {filters.isFilterSensitive && (
              <Popover
                placement="bottom-end"
                isOpen={!!selectedFilters.length && filter.popoverOpen}
                target="headerbar-filter"
                className={styles.filterPopover}
                modifiers={{
                  flip: { behavior: 'clockwise' },
                }}
                onMouseOver={() => this.handleMouseOverFilterPopover()}
                onMouseLeave={() => this.handleMouseLeaveFilterPopover()}
              >
                <PopoverHeader>Selected Filters ({selectedFilters.length}):</PopoverHeader>
                <PopoverBody>
                  <ul>{selectedFilters}</ul>
                </PopoverBody>
              </Popover>
            )}
          </li>
          {!isTopDownView && (
            <li>
              <a
                className={classes(styles.headerbarRightLink, 'clickable', `${!hideAssortmentCart ? '' : 'disabled'}`)}
                onClick={() => {
                  if (!hideAssortmentCart) {
                    const perspective = window.location.hash.split('/')[1];
                    window.location.hash = `${perspective}/assortment-build/select-styles/cart`;
                  }
                }}
              >
                <span>
                  <span className={classes('far fa-cart-arrow-down')} />
                  &nbsp; Assortment Cart {this.props.cartCount > 0 ? `(${this.props.cartCount})` : ''}
                </span>
              </a>
            </li>
          )}
        </ul>
        <ReconcileModal
          onToggleModal={() => {
            this.setState({ reconcileOpen: false });
          }}
          onReset={noop}
          isOpen={this.state.reconcileOpen}
          enabled={true}
          quickReconConf={quickReconConf}
        />
        {hasLocalStorage && scopeDefined && currentScopeUndefined && <RestoreSession hideLoading={true} />}
      </div>
    );
  }
}

// @ts-ignore
export default makeHeaderbarPlanSensitive(Headerbar);
