import { GridViewConfig } from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.slice';
import { TimeEntry, Pivot, TimeSheetInfo, SubmitPayload, OverTimeType } from 'src/worker/pivotWorker.types';
import serviceContainer from 'src/ServiceContainer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  cacheCheckFetchPivotData,
  fetchListData,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { AppDispatch } from 'src/store';
import { ViewDataState } from 'src/types/Domain';

export type PricingSlice = {
  viewDefns?: GridViewConfig;
  isConfigLoading: boolean;
  massEditData?: Pivot;
  events: PriceEvent[];
  selection: string;
  cacheHashCompanion: string | null;
  cacheHashOverTime: string | null;
  viewDataStateCompanion: ViewDataState;
  viewDataStateOverTime: ViewDataState;
  viewDataStateMassEdit: ViewDataState;
};

export type PriceEvent = {
  ccpriceevent: string;
  expression: string;
};

const initialState: PricingSlice = {
  isConfigLoading: false,
  events: [],
  selection: '',
  viewDataStateCompanion: ViewDataState.idle,
  viewDataStateOverTime: ViewDataState.idle,
  viewDataStateMassEdit: ViewDataState.idle,
  cacheHashCompanion: null,
  cacheHashOverTime: null,
};

const pricingOverTimeReducer = createSlice({
  name: 'PricingOverTime',
  initialState: initialState,
  reducers: {
    requestPricingOverTimeConfig(state) {
      state.isConfigLoading = true;
    },
    receivePricingOverTimeConfig(state, action: PayloadAction<GridViewConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    requestOverTimeData(state) {
      state.viewDataStateOverTime = ViewDataState.regularDataLoading;
    },
    receiveOverTimeCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashOverTime = action.payload;
    },
    receiveOverTimeCachedData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.cacheBackgroundDataLoading;
        state.selection = action.payload.memberId;
      }
    },
    receiveOverTimeLiveData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.regularDataReady;
        state.selection = action.payload.memberId;
      }
    },
    receivePricingEvents(state, action: PayloadAction<PriceEvent[]>) {
      state.events = action.payload;
    },
    requestMassEditData(state) {
      state.viewDataStateMassEdit = ViewDataState.regularDataLoading;
    },
    receiveMassEditData(state, action: PayloadAction<Pivot>) {
      state.viewDataStateMassEdit = ViewDataState.regularDataReady;
      state.massEditData = action.payload;
    },
    requestCompanionData(state) {
      state.viewDataStateCompanion = ViewDataState.regularDataLoading;
    },
    receiveCompanionCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashCompanion = action.payload;
    },
    receiveCompanionCachedData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        state.viewDataStateCompanion = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveCompanionLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        const cacheInUse = state.viewDataStateCompanion === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataStateCompanion = cacheInUse
          ? ViewDataState.cacheBackgroundDataReady
          : ViewDataState.regularDataReady;
      }
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestPricingOverTimeConfig,
  receivePricingOverTimeConfig,
  requestOverTimeData,
  receiveOverTimeCacheHash,
  receiveOverTimeCachedData,
  receiveOverTimeLiveData,
  receivePricingEvents,
  requestMassEditData,
  receiveMassEditData,
  requestCompanionData,
  receiveCompanionCacheHash,
  receiveCompanionCachedData,
  receiveCompanionLiveData,
  receiveError,
  cleanUp,
} = pricingOverTimeReducer.actions;

export function fetchOverTimeData(memberId: string, modelDefn?: string) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.getOverTimeData(memberId, OverTimeType.pricing, modelDefn),
    requestOverTimeData,
    receiveOverTimeCacheHash,
    (cacheHash: string) => receiveOverTimeCachedData({ memberId, cacheHash }),
    (cacheHash: string) => receiveOverTimeLiveData({ memberId, cacheHash }),
    false
  );
}

export function fetchCompanionData(modelDefn: string) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.fitViewCacheCheck(modelDefn),
    requestCompanionData,
    receiveCompanionCacheHash,
    receiveCompanionCachedData,
    receiveCompanionLiveData,
    false
  );
}

export function fetchMassEditData(modelDefn: string) {
  return fetchListData(modelDefn, requestMassEditData, receiveMassEditData);
}

export function submitPayload(payload: SubmitPayload) {
  return () => {
    return serviceContainer.pivotService.submitPricingPayload(payload);
  };
}

export default pricingOverTimeReducer.reducer;
