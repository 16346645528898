import * as React from 'react';
import { identity } from 'lodash';
import { connect } from 'react-redux';
import { wrappedDispatch, WrappedDispatch } from 'src/utils/Redux/Dispatch';
import { AppState } from 'src/store';
import { componentToName } from 'src/utils/React/Component';
import { PrintProps } from 'src/components/higherOrder/Print/Print';

export function makePrintSensitive<P>(WrappedComponent: React.ComponentType<P>) {
  function mergeProps(state: AppState, _wrapped: WrappedDispatch, parentProps: P) {
    const printProps: PrintProps = {
      isPrintMode: state.print.isPrintMode,
      printWidth: state.print.width,
      printHeight: state.print.height,
    };
    return {
      ...printProps,
      ...parentProps,
    };
  }

  class PrintSensitive extends React.Component<P> {
    displayName = `PrintSensitive(${componentToName(WrappedComponent)})`;
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(identity, wrappedDispatch, mergeProps)(PrintSensitive);
}
