import React from 'react';
import {
  ColDef,
  ColGroupDef,
  ValueGetterParams,
  SuppressKeyboardEventParams,
  IsColumnFuncParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { AgGridReactProps } from 'ag-grid-react';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { classes } from 'typestyle';
import _, { isEqual, get } from 'lodash';

import { CSSProperties } from 'react';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { ColumnApi } from 'ag-grid-community/dist/lib/columnController/columnApi';
import 'ag-grid-enterprise';
import styles from '../FlowSheet/FlowSheetByStyle/FlowSheetGrid.styles';
import { TimeEntry, PayloadObject, SubmitPayload } from 'src/worker/pivotWorker.types';
import Renderer from 'src/utils/Domain/Renderer';
import {
  EDITABLE_BG_COLOR,
  FlowSheetCellRendererParams,
  FlowSheetCellValueChangedEvent,
  FrameworkComponents,
  getId,
  RowData,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/types';
import { PriceEvent } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';

import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import AgGridHeaderBreakClass from 'src/utils/Style/AgGridThemeBreakHeaders';
import { isEmpty, isNil } from 'lodash';
import { GetMainMenuItemsParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { BLOCK_ENTER_EDITORS, POPOVER_BLOCK_CODES } from 'src/utils/Domain/Constants';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';

import globalMath from 'mathjs';
import { BigNumber } from 'mathjs';
import { executeCalculation, importDefaultFunctions } from 'src/utils/LibraryUtils/MathUtils';
import { ICellEditorParams, ProcessCellForExportParams } from 'ag-grid-community';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import { Overlay } from 'src/common-ui/index';
import { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';

type BaseOrNull = string | number | boolean | null;
type UpdateCalculatedFieldsArgs = {
  groupId: string;
  field: string;
  rowData: RowData[];
  path: string[];
};
type CellRendererProps = FlowSheetCellRendererParams & { editable: () => boolean };
class FlowSheetCellRenderer extends React.Component<CellRendererProps> {
  constructor(props: CellRendererProps) {
    super(props);
  }
  render() {
    const colDef = this.props.column.getColDef();
    const data = this.props.data;
    const field = colDef.field || '';
    const measureId = data.measureId;

    const isEditable = !!data.extraData[field + '_editable'] && !!data.editable && this.props.editable();
    const isVisiblyEditable = isEditable ? EDITABLE_BG_COLOR : undefined;
    const valueStyle: CSSProperties = {
      backgroundColor: isVisiblyEditable,
      width: '100%',
      height: '100%',
      whiteSpace: 'pre',

      // Fixes for centering columns
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    const renderFn = Renderer[data.renderer || ''] || ((a: string | number | undefined) => a);

    if (data.extraData.calculation && this.props.value) {
      return <div style={valueStyle}>{renderFn(this.props.value)}</div>;
    }

    if (data.path.length === 2 && measureId) {
      const value = this.props.api!.getValue(this.props.column, this.props.node);

      const valueIsEmpty = value === null || typeof value === 'undefined' || String(value).trim() === '';
      const display = valueIsEmpty ? '' : renderFn(value);
      return <div style={valueStyle}>{display}</div>;
    }

    return null;
  }
}

interface HeaderHoverState {
  hover: boolean;
}

class HeaderCellRenderer extends React.Component<FlowSheetCellRendererParams, HeaderHoverState> {
  constructor(props: FlowSheetCellRendererParams) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  onToggle = () => {
    const { data, onHeaderClick } = this.props;

    if (onHeaderClick) {
      onHeaderClick({
        id: data.id,
        parentId: data.additionalId,
      });
    }
  };

  render() {
    const params = this.props;
    const data = params.data;
    let textName = data.name || data.measureId;
    const level = data.path.length;
    if (level === 1) {
      textName = data.name || data.groupId;
      return (
        <span className={`${styles.headerName} level-${level}`} onClick={this.onToggle}>
          {textName}
        </span>
      );
    }
    return <span className={`${styles.headerName} level-${level}`}>{textName}</span>;
  }
}

// these props are used when PricingGrid component is directly accessed (WorklistViewContainer)
export type ComponentTypeGridDispatchProps = {
  onRenderGrid?: () => void;
  onCompanionItemChange?: () => void;
  submitPayload?: (payload: SubmitPayload) => void;
  updateAssortmentPlan?: () => void;
  onCleanup?: () => void;
};

export type PricingGridProps = {
  loading?: boolean;
  itemId?: string;
  rowData?: RowData[];
  rowHeight?: number;
  timeEntries: TimeEntry[];
  anchorField?: string;
  events: PriceEvent[];
  editable: boolean;
  onValueChange?: (payload: SubmitPayload) => void;
  onItemClicked?: (item: Record<string, any>, eventTarget?: HTMLElement) => void;
} & ComponentTypeGridDispatchProps;

export interface PricingGridState {
  columnDefs?: (ColDef | ColGroupDef)[];
  rowData: RowData[];
  anchorField?: string;
  context: {
    componentParent: PricingGrid;
  };
  frameworkComponents: FrameworkComponents;
}
export class PricingGrid extends React.Component<PricingGridProps, PricingGridState> {
  private _isUnMounted = false;
  protected gridApi!: GridApi;
  protected columnApi!: ColumnApi;
  protected GRID_EXPORT_NAME = 'PricingGrid-Export';
  math: globalMath.MathJsStatic = (globalMath as any).create();
  bigNumMath: globalMath.MathJsStatic;

  constructor(props: PricingGridProps) {
    super(props);
    importDefaultFunctions(this.math);
    this.bigNumMath = (this.math as any).create();
    this.bigNumMath.config({
      number: 'BigNumber',
    });

    const anchorField = props.anchorField || '';
    const columnDefs = this.decorateWithStyleClass(this.props.timeEntries, 0, anchorField);
    this.state = {
      anchorField,
      columnDefs: columnDefs,
      context: { componentParent: this },
      rowData: [],
      frameworkComponents: {
        flowSheetRenderer: FlowSheetCellRenderer,
        headerCellRenderer: HeaderCellRenderer,
        validValuesEditor: ValidValuesEditor,
        integerEditor: IntegerEditor,
      },
    };
  }

  componentDidMount() {
    if (this.props.onRenderGrid) {
      this.props.onRenderGrid();
    }
  }

  componentDidUpdate(prevProps: PricingGridProps) {
    if (
      this.props.onCompanionItemChange &&
      !isEmpty(prevProps.itemId) &&
      !isEqual(prevProps.itemId, this.props.itemId)
    ) {
      this.props.onCompanionItemChange();
    } else if (!isEqual(prevProps.timeEntries.length, this.props.timeEntries.length)) {
      const columnDefs = this.decorateWithStyleClass(this.props.timeEntries, 0, this.props.anchorField || '');
      this.setState({
        columnDefs,
      });
    }
    if (this.props.rowData && this.gridApi && !isEqual(prevProps.rowData, this.props.rowData)) {
      this.gridApi.setRowData(this.props.rowData);
      this.setState({
        rowData: this.props.rowData,
      });
    }
    if (!isEqual(prevProps.editable, this.props.editable) && this.gridApi) {
      this.gridApi.refreshCells({ force: true });
    }
  }

  componentWillUnmount() {
    this._isUnMounted = true;

    if (this.props.onCleanup) {
      this.props.onCleanup();
    }
  }

  getDiff(): PayloadObject[] {
    const before = this.props.rowData || [];
    const after = this.state.rowData;
    if (before.length !== after.length) {
      throw new Error('Size Mismatch for diff');
    }
    const ln = before.length;
    const weeks = _.flatMap(this.props.timeEntries, (month) => month.children);

    const groups = {};
    weeks.forEach((week) => {
      for (let i = 0; i < ln; ++i) {
        const rowBefore = before[i];
        const rowAfter = after[i];

        // Skip things not at level 2
        if (rowBefore.path.length !== 2) {
          continue;
        }

        if (rowBefore.groupId !== rowAfter.groupId) {
          throw new Error('Data Key Mismatch');
        }
        const measureId = rowBefore.measureId;
        if (measureId !== rowAfter.measureId) {
          throw new Error('Data Key Mismatch');
        }

        // can't work with undefined measures
        if (!measureId) {
          continue;
        }

        const d1 = rowBefore.extraData[week.id];
        const d2 = rowAfter.extraData[week.id];

        // same data - do nothing
        if (d1 === d2) {
          continue;
        }

        let groupItem = groups[rowBefore.groupId];
        if (!groupItem) {
          groupItem = groups[rowBefore.groupId] = {};
        }

        let weekItem = groupItem[week.id];
        if (!weekItem) {
          weekItem = groupItem[week.id] = {};
        }

        let value: string | number | boolean | null = rowAfter.extraData[week.id];
        if (typeof value === 'undefined' || value === '') {
          value = null;
        }
        weekItem[measureId] = value;
      }
    });

    const items: PayloadObject[] = [];
    Object.keys(groups).forEach((groupId) => {
      const group = groups[groupId];
      Object.keys(group).forEach((weekId) => {
        const weekItem = group[weekId];
        weekItem['product'] = groupId;
        weekItem['time'] = weekId;
        items.push(weekItem);
      });
    });
    return items;
  }

  onGridReady = (event: GridReadyEvent) => {
    if (event.api && event.columnApi) {
      this.gridApi = event.api;
      this.columnApi = event.columnApi;

      if (this.props.rowData && !this._isUnMounted) {
        this.setState({ rowData: this.props.rowData });
      }
      event.api.setRowData(this.state.rowData);

      this.scrollAnchorIntoView(event);
    }
  };

  scrollAnchorIntoView(event: GridReadyEvent) {
    const anchorField =
      !isNil(event) && !isNil(event.columnApi) ? event.columnApi.getColumn(this.state.anchorField) : null;

    if (isNil(anchorField)) {
      return;
    }

    // find index of anchor field to determine next predetermined number of columns to ensureColumnVisible on
    const allColumns = this.columnApi.getAllColumns();
    const anchorCol = allColumns.find((column) => column.getColId() === this.state.anchorField);

    if (anchorCol) this.gridApi.ensureColumnVisible(allColumns[allColumns.length - 1]);
    if (anchorCol) this.gridApi.ensureColumnVisible(anchorCol);
  }

  decorateWithStyleClass(items: TimeEntry[], depth = 0, anchorField: string): ColDef[] {
    const firstIndex = 0;
    const lastIndex = items.length - 1;
    const newItems: ColDef[] = items.map((item, i) => {
      const classList = [`depth-${depth}-item-${i}`];

      if (item.id === anchorField) {
        classList.push('anchor-field');
      }

      if (firstIndex === i) {
        classList.push(`depth-${depth}-first`);
      }
      if (lastIndex === i) {
        classList.push(`depth-${depth}-last`);
      }

      return {
        headerName: item.description || item.id,
        field: item.id,
        cellClass: () => classList,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.data && params.data.type && BLOCK_ENTER_EDITORS.includes(params.data.type)) {
            if (params.editing && POPOVER_BLOCK_CODES.includes(params.event.code)) {
              return true;
            }
          }
          return false;
        },
        valueGetter: (params: ValueGetterParams) => {
          const measureId = params.data.measureId;
          const groupId = params.data.groupId;
          const field = params.colDef.field || '';
          let value = params.data.extraData[field];
          const rowData = this.state.rowData;
          const rowDataIndex = rowData.findIndex((data) => {
            if (data.id === params.data.id) {
              return true;
            }
            return false;
          });

          // Hardcoded eventprice reserved calculation
          if (measureId === 'eventprice_reserved') {
            const allMeasures = rowData.filter((rowItem: RowData) => {
              return rowItem.groupId === groupId && (rowItem.measureId || '').length > 1;
            });
            const valueObject = allMeasures.reduce((a: Record<string, any>, b: RowData) => {
              a[b.measureId || ''] = b.extraData[field];
              return a;
            }, {});

            const event = this.props.events.find((e: PriceEvent) => {
              const valueObjEvent = isEmpty(valueObject['event']) ? 'none' : valueObject['event'];
              return e.ccpriceevent === valueObjEvent;
            });
            if (event) {
              try {
                // We use Big Number here to avoid some floating point errors as a result of the event calculation.
                // It's a dollar amount, so we're rounding so exports make sense
                value = this.bigNumMath.round(this.bigNumMath.eval(event.expression, valueObject), 2);
                if (this.bigNumMath.typeof(value) === 'BigNumber') {
                  value = (value as BigNumber).toNumber();
                }
              } catch (e) {
                // tslint:disable-next-line:no-console
                console.error(event.expression);
              }
            }
          }

          if (rowData[rowDataIndex] && rowData[rowDataIndex].extraData && rowData[rowDataIndex].extraData.calculation) {
            const getDataFromKey = (parmKey: string) => {
              const returnObj = {
                rowNodeFound: false,
                data: undefined,
              };
              const key = get(rowData[rowDataIndex].extraData.calcParamMap, parmKey) || parmKey;
              const rowNode = params.api!.getRowNode(params.node.id.split('_')[0] + `_${key}`);
              if (rowNode) {
                returnObj.rowNodeFound = true;
                returnObj.data = params.api!.getValue(params.column, rowNode);
              }
              return returnObj;
            };
            if (rowData[rowDataIndex].extraData.calculation === '$DB_EVENT_CALC') {
              const event = this.props.events.find((e: PriceEvent) => {
                const event = getDataFromKey('event').data;
                const valueObjEvent = isEmpty(event) ? 'none' : event;
                return e.ccpriceevent === valueObjEvent;
              });
              if (isNil(event)) {
                return null;
              } else {
                return executeCalculation(this.math, event.expression as string, getDataFromKey);
              }
            }
            return executeCalculation(this.math, rowData[rowDataIndex].extraData.calculation as string, getDataFromKey);
          } else {
            return value;
          }
        },
      };
    });

    items.forEach((item: TimeEntry, i) => {
      if (item.children && item.children.length) {
        const colDef = newItems[i] as ColGroupDef;
        colDef.marryChildren = true;
        colDef.children = this.decorateWithStyleClass(item.children, depth + 1, anchorField);
      }
    });

    return newItems;
  }

  cellEditorSelector = (params: ICellEditorParams): ComponentSelectorResult => {
    const colDef = params.colDef;
    if (params.data.measureId && colDef) {
      if (params.data.measureId === 'event' || params.data.inputType === 'event_dropdown') {
        return {
          component: 'validValuesEditor',
          params: {
            dataConfig: {
              url: '/api/attribute/validValues/one?appName=Assortment&ids=ccpriceevent',
              params: [],
            },
            dataQa: 'select-price-grid',
          },
        };
      }

      switch ((params.data as RowData).inputType) {
        case 'integer':
          const rowData = this.state.rowData;
          const rowDataIndex = rowData.findIndex((data) => {
            if (data.id === params.data.id) {
              return true;
            }
            return false;
          });
          const percent = params.data.renderer === 'percent';
          const inputParams = rowData[rowDataIndex].inputParams;
          return {
            component: 'integerEditor',
            params: {
              passedInt: params.data.extraData[colDef.field!],
              inputParams: { ...inputParams, percent },
              regularPosition: true,
            },
          };
        default:
      }

      return {
        component: 'agTextCellEditor',
      };
    }

    return (null as unknown) as ComponentSelectorResult;
  };

  isGroupEditable(params: ICellEditorParams): boolean {
    const data = params.data;
    return data.path.length === 1;
  }

  isEditable = (params: IsColumnFuncParams): boolean => {
    const data = params.data;
    const colDef = params.colDef;
    if (data && colDef) {
      const field = colDef.field || '';
      const isEditable = !!data.extraData[field + '_editable'] && !!data.editable && this.props.editable;
      return data.path.length > 0 && !!data.measureId && isEditable;
    }
    return false;
  };

  updateCell(groupId: string, measureId: string, field: string, newValue: string | number | boolean) {
    const rowData = this.state.rowData;
    if (measureId && groupId) {
      const comparator = (tmp: RowData) => tmp.groupId === groupId && tmp.measureId === measureId;
      const index = _.findIndex(rowData, comparator);
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = newValue;
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;
        this.setState({ rowData: newRowData }, () => {
          this.gridApi.updateRowData({
            update: [measureData],
          });
          this.handleValueChange();

          const rowNode = this.gridApi.getRowNode(getId(groupId, measureId));
          const column = this.columnApi.getColumn(field);
          this.gridApi.refreshCells({
            force: true,
            columns: [column],
            rowNodes: [rowNode],
          });
        });
      }
    }
  }

  refreshField(field: string) {
    const column = this.columnApi.getColumn(field);

    this.gridApi.refreshCells({
      force: true,
      columns: [column],
    });
  }

  updateCalculatedField = (
    measureId: string,
    extraData: { [s: string]: BaseOrNull },
    { groupId, field, rowData }: UpdateCalculatedFieldsArgs
  ) => {
    function getMeasure(m: string): number {
      const rowEntry = rowData.find((item: RowData) => item.groupId === groupId && item.measureId === m);
      if (!rowEntry) {
        return NaN;
      }
      return parseFloat(rowEntry.extraData[field] as string);
    }

    let value = extraData[field];
    if (measureId === 'eff_aur') {
      const allMeasures = rowData.filter((item: RowData) => {
        return item.groupId === groupId && (item.measureId || '').length > 1;
      });
      const valueObject = allMeasures.reduce((a: Record<string, any>, b: RowData) => {
        a[b.measureId || ''] = b.extraData[field];
        return a;
      }, {});
      const event = this.props.events.find((e: PriceEvent) => e.ccpriceevent === valueObject['event']);

      if (event) {
        try {
          value = this.math.eval(event.expression, valueObject);
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.error(event.expression);
        }
      }
      const corpexcl = getMeasure('corpexcl');
      const cccurp = getMeasure('cccurp');
      const corpaddoff = getMeasure('corpaddoff');
      const eo = getMeasure('eo');
      const addoff = getMeasure('addoff');
      const ccdiscountpct = getMeasure('ccdiscountpct');

      const CEX = isNaN(corpexcl) ? 0 : corpexcl;
      const A = cccurp * (1 - CEX);
      const AEO = isNaN(addoff) ? 0 : addoff;
      const CEAO = isNaN(corpaddoff) ? 0 : corpaddoff;
      const EventPrice = _.isNaN(value) ? cccurp : value;
      const FinalEventPrice = isNaN(eo) ? EventPrice : eo;
      const B = (FinalEventPrice as number) * (1 - AEO) * (1 - CEAO);
      const washpct = isNaN(ccdiscountpct) ? 0 : ccdiscountpct;

      value = Math.min(A, B) * (1 - washpct);
    }
    return value;
  };

  updateCalculatedFields = (args: UpdateCalculatedFieldsArgs) => {
    const calcMeasures = ['eff_aur'];
    // TODO: get index from measure + modifed row?
    return _.map(calcMeasures, (measureId) => {
      const index = _.findIndex(args.rowData, (tmp: RowData) => _.isEqual(tmp.path, args.path.concat(measureId)));
      const measureData = _.cloneDeep(args.rowData[index]);
      measureData.extraData[args.field] =
        this.updateCalculatedField(measureId, measureData.extraData, args) || measureData.extraData[args.field];
      args.rowData[index] = measureData;
      return measureData;
    });
  };

  onCellValueChanged = (event: FlowSheetCellValueChangedEvent) => {
    const data = event.data;
    const rowData = this.state.rowData;
    const field = event.colDef.field || '';
    if (rowData && data.measureId && data.groupId) {
      const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, data.path));
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = data[field];
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;
        const calcedUpdates = this.updateCalculatedFields({
          groupId: data.groupId,
          field,
          rowData: newRowData,
          path: data.path.slice(0, -1),
        });
        this.setState({ rowData: newRowData }, () => {
          this.gridApi.updateRowData({
            update: [measureData].concat(calcedUpdates),
          });

          this.handleValueChange();
          this.refreshField(field);
        });
      }
    }
  };

  handleValueChange = () => {
    const diff = this.getDiff();
    if (!diff.length) {
      return;
    }

    const valueChangeHandler = this.props.onValueChange || this.props.submitPayload;

    if (valueChangeHandler) {
      valueChangeHandler({
        keyField: ['product', 'time'],
        create: [],
        update: diff,
        delete: [],
      });
    }
  };

  doesExternalFilterPass(node: RowNode): boolean {
    return !node.data.hidden;
  }

  getColumnMenuItems = (params: GetMainMenuItemsParams) => {
    return params.defaultItems.filter((item) => item !== 'autoSizeAll');
  };

  renderPopover = (params: { id: string | undefined; parentId: string | undefined }) => {
    const { onItemClicked } = this.props;
    if (onItemClicked) {
      onItemClicked(params);
    }
  };

  render() {
    if (!isNil(this.props.loading) && this.props.loading) {
      return (
        <div className={listPairStyle}>
          <Overlay type="loading" visible={true} />
        </div>
      );
    }

    const frameworkComponents = this.state.frameworkComponents;
    return (
      <ExtendedDataGrid
        className={classes(styles.dataGridStyle, AgGridHeaderBreakClass)}
        loaded={true}
        data={[]}
        singleClickEdit={true}
        columnDefs={this.state.columnDefs!}
        frameworkComponents={frameworkComponents}
        onGridReady={this.onGridReady}
        exportOptions={{
          fileName: this.GRID_EXPORT_NAME,
          processCellOverride: ({ node, column }: ProcessCellForExportParams) => {
            if (node && node.parent) {
              if (node.parent.id === 'ROOT_NODE_ID' && column.getId() === 'ag-Grid-AutoColumn') {
                const returnName: string = node.data.name;
                const [description] = returnName.split(':');
                // verify description is available and if not display error in cell text since data is not present
                const descriptionFound = !isEmpty(description);
                return descriptionFound ? returnName : `[ERROR: NO DESCRIPTION DATA] - ${returnName}`;
              }

              if (column.isPinnedLeft()) {
                return node.data.name;
              }
            }
          },
        }}
        extraAgGridProps={{
          // 'excludeChildrenWhenTreeDataFiltering' makes children of groups have to go through the external filter call separately
          // see SUP-24
          excludeChildrenWhenTreeDataFiltering: true,
          defaultColDef: {
            editable: this.isEditable,
            cellEditorSelector: this.cellEditorSelector,
            cellStyle: {
              padding: 0,
              margin: 0,
              height: 'inherit',
            },
            cellRenderer: 'flowSheetRenderer',
            cellRendererParams: {
              editable: () => this.props.editable,
            },
            width: 130,
            lockPosition: true,
            resizable: true,
          },
          rowHeight: this.props.rowHeight,
          onCellValueChanged: this.onCellValueChanged,
          getRowNodeId: (data: RowData) => data.id,
          deltaRowDataMode: true,
          context: this.state.context,
          treeData: true,
          groupDefaultExpanded: 1,
          animateRows: true,
          getDataPath: (data: RowData) => data.path,
          suppressScrollOnNewData: true,
          stopEditingWhenGridLosesFocus: true,
          isExternalFilterPresent: () => true,
          doesExternalFilterPass: this.doesExternalFilterPass,
          getMainMenuItems: this.getColumnMenuItems,
          onCellEditingStopped: (params: AgGridReactProps) => {
            params.api!.redrawRows();
          },
          autoGroupColumnDef: {
            headerName: 'Name',
            width: 300,
            pinned: 'left',
            lockPinned: true,
            cellRendererParams: {
              suppressCount: true,
              innerRenderer: 'headerCellRenderer',
              onHeaderClick: this.renderPopover,
            },
            resizable: true,
          },
        }}
      />
    );
  }
}
