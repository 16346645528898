import { connect } from 'react-redux';
import { flow } from 'lodash';

import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { AppState } from 'src/store';
import { Option } from 'src/components/Configure/ConfigureModal';
import { Props as ListGridPairProps, default as ListGridPair } from 'src/components/ListGridPair/ListGridPair';
import { StateProjection } from 'src/components/ListGridPair/ListGridPair.selectors';
import { wrappedDispatch, WrappedThunkDispatch } from 'src/utils/Redux/Dispatch';
import { defaultsToAggBys } from 'src/utils/Component/Configure';
import { getConfigureSelections } from 'src/components/Subheader/Subheader.selectors';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { updateConfigureSelections } from 'src/components/Subheader/Subheader.slice';
import { selectAndProjectState } from './NestedView.selectors';
import {
  receivePageError,
  requestConfig,
  receiveConfig,
  updateAggBys,
  updateAggBysNoOverwriteExisting,
  receiveSomeNestedViewConfig,
  cleanUp,
} from './NestedView.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { z } from 'zod';
import { HistoryGridComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';

export type NestedViewOwnProps = z.infer<typeof HistoryGridComponentProps>;

function mapStateToProps(state: AppState, ownProps: NestedViewOwnProps): StateProjection {
  const projection = selectAndProjectState(state, ownProps);
  if (projection.loaded === true) {
    return {
      ...projection,
      configureSelections: getConfigureSelections(state),
    };
  } else {
    return projection;
  }
}

function mergeProps(
  mappedState: StateProjection,
  dispatchProps: WrappedThunkDispatch,
  ownProps: NestedViewOwnProps
): ListGridPairProps {
  const { dispatch } = dispatchProps;
  const { defns } = ownProps;

  // TODO: once remaining ListGridPair methods no longer rely on onUpdate/onRefetch/onDestroy
  // in DispatchProps type, remove from here
  const mergedProps = {
    ...mappedState,
    onShowView() {
      dispatch(requestConfig());
      container.tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
        })
        .then((config) => {
          const unmodifiedViewDefn = config[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (config as any)[defns.view.length],
            dispatch
          );
          if (localConfig && localConfig.config) {
            config[0] = localConfig.config;
            if (localConfig.configurationSelections) {
              config[2].defaultsOverride = localConfig.configurationSelections;
            }
          }
          dispatch(
            receiveConfig({
              grid: config[0],
              listSort: config[1],
              configure: config[2],
              list: config[3],
              subheaderRollUp: config[4],
              unmodifiedViewDefn,
            })
          );

          if (localConfig && localConfig.configurationSelections) {
            const aggBys = localConfig.configurationSelections.map((x) => x.dataIndex);
            dispatch(updateAggBys(aggBys));
          } else {
            const aggBysConfig = config[2];
            const aggBys = defaultsToAggBys(aggBysConfig);
            dispatch(updateAggBysNoOverwriteExisting(aggBys));
          }
        })
        .catch((_error) => {
          dispatch(receivePageError());
        });
    },
    onDestroy() {
      // do nothing
    },
    onConfigAggregate(aggBys: string[]) {
      dispatch(updateAggBys(aggBys));
    },
    updateConfigureSelections(selections: Option[]) {
      dispatch(updateConfigureSelections(selections));
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(receiveSomeNestedViewConfig(config));
    },
    onRefetchData() {
      // data refetch handled in epic
    },
    onUpdate() {
      // data refetch handled in epic
    },
    openStylePane() {
      // do nothing
    },
    onItemClicked() {
      // do nothing
    },
    onFabClick() {
      // do nothing
    },
  };

  return mergedProps;
}

const wrappedView = flow(() => ListGridPair, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, wrappedDispatch, mergeProps)(wrappedView);
