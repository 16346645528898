import React from 'react';

import Overlay from 'src/common-ui/components/Overlay/Overlay';
import CompanionListView, {
  Props as CompanionProps,
  SortOption,
} from 'src/common-ui/components/CompanionListView/CompanionListView';

import { Option } from 'src/components/Configure/ConfigureModal';
import Subheader from 'src/components/Subheader/Subheader.container';
import { resolvePath } from 'src/cdn';

import { companionStyles, listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import { FlowSheetGrid } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/FlowSheetGrid';
import { GridMeasureDefn, FlowSheetProps, State, FlowSheetGridProps } from './types';
import {
  companionDataParse,
  convertPivotItemsToRowData,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/util';
import { ASSORTMENT_BUILD_FILTER_WARNING, CACHED_DATA_EDITING_DISABLED_WARNING } from 'src/utils/Domain/Constants';
import { findKey, isNil, isEmpty, isEqual, filter, findIndex, map, indexOf, uniqBy } from 'lodash';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { MassEdit, MassEditSubmissionData, MassEditSelectItemData } from 'src/components/MassEdit/MassEdit';
import { SubmitPayload } from 'src/worker/pivotWorker.types';
import serviceContainer from 'src/ServiceContainer';
import { FabType } from 'src/components/higherOrder/withFab';

const noImage = resolvePath(noImagePath);

export class FlowSheetByStyle extends React.Component<FlowSheetProps, State> {
  private _isUnMounted = false;
  protected fsGrid!: FlowSheetGrid | null;

  constructor(props: FlowSheetProps) {
    super(props);

    this.state = {
      companionCollapsed: false,
      companionSortDirection: 'desc',
      configureIsOpen: false,
      companionData: [],
      uniqueItems: props.uniqueItems,
    };

    this.onCompanionItemClick = this.onCompanionItemClick.bind(this);
    this.onCompanionDirectionChange = this.onCompanionDirectionChange.bind(this);
    this.onCompanionSortChange = this.onCompanionSortChange.bind(this);
    this.onCompanionCollapseToggle = this.onCompanionCollapseToggle.bind(this);
    this.onGridValueChange = this.onGridValueChange.bind(this);
  }

  static getDerivedStateFromProps(newProps: FlowSheetProps, currentState: State): State {
    const { uniqueItems, companionDataLookup, defaultCompanionSortField } = newProps;

    const {
      companionSortDirection,
      companionSortField = defaultCompanionSortField && defaultCompanionSortField.dataIndex,
    } = currentState;

    if (!isEqual(uniqueItems, currentState.uniqueItems)) {
      const lookup = companionDataLookup;
      const items = uniqueItems;
      const direction = companionSortDirection;
      // noinspection UnnecessaryLocalVariableJS
      const field = companionSortField;
      const companionData = companionDataParse(items, lookup, direction, field);

      // Get mass edit data based on styles in companion view
      let getMassEditData = false;
      if (
        (uniqueItems && !currentState.uniqueItems) ||
        (uniqueItems && currentState.uniqueItems && !isEqual(uniqueItems.sort(), currentState.uniqueItems.sort()))
      ) {
        getMassEditData = true;
      }
      if (getMassEditData && newProps.getMassEditData && companionData) {
        newProps.getMassEditData();
      }

      return {
        ...currentState,
        companionData,
        companionSortField,
        uniqueItems,
      };
    }
    return {
      ...currentState,
      companionSortField,
      uniqueItems,
    };
  }

  componentDidMount() {
    if (this._isUnMounted) {
      return;
    }
    this.props.onShowView();
  }

  componentDidUpdate() {
    if (
      this.state.companionData.length &&
      (!this.state.selectedItem || isNil(findKey(this.state.companionData, { id: this.state.selectedItem })))
    ) {
      const selectedItem = this.state.companionData[0].id;

      if (this.props.onCompanionItemClick) {
        this.props.onCompanionItemClick(selectedItem);
      }
      this.setState({
        selectedItem: selectedItem,
      });
    }
  }

  updateConfigureSelections(selections: Option[]) {
    if (this.props.updateConfigureSelections) {
      this.props.updateConfigureSelections(selections);
    }
  }

  componentWillUnmount() {
    this._isUnMounted = true;
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }

  rebuildCompanionData() {
    const companionData = companionDataParse(
      this.state.uniqueItems,
      this.props.companionDataLookup,
      this.state.companionSortDirection,
      this.state.companionSortField
    );
    this.setState({
      companionData,
    });
  }

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  /*  Start Companion List Events */

  onCompanionItemClick(itemId: string) {
    if (this._isUnMounted) {
      return;
    }
    if (this.props.onCompanionItemClick) {
      this.props.onCompanionItemClick(itemId);
    }
    this.setState({ selectedItem: itemId });
  }

  onCompanionDirectionChange(newDir: 'asc' | 'desc') {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionSortDirection: newDir }, () => {
      this.rebuildCompanionData();
    });
  }

  onCompanionSortChange(option: SortOption) {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionSortField: option.dataIndex }, () => {
      this.rebuildCompanionData();
    });
  }

  onCompanionCollapseToggle(collapse: boolean) {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionCollapsed: collapse });
  }

  /* End Companion List Events */

  getGridRowData() {
    const { isOverTimeDataLoaded, overTimeData, viewDefns } = this.props;

    if (isOverTimeDataLoaded && overTimeData && viewDefns && viewDefns.grid) {
      if (isEmpty(this.state.companionData)) return [];
      return convertPivotItemsToRowData(overTimeData, viewDefns.grid.view as GridMeasureDefn[]);
    }

    return [];
  }

  onGridValueChange(payload: SubmitPayload) {
    this.props.submitPayload(payload);
  }

  handleMassEditSubmission = async (submission: MassEditSubmissionData) => {
    const { selectedModifier, selectedItems, selectedWeekList, modifierValue } = submission;

    if (!isNil(selectedModifier) && !isNil(selectedWeekList)) {
      // no null submission data expected, except for modifier value

      const dataPiv = await serviceContainer.pivotService.getFlowSheetByIndex(
        selectedItems.map((i) => i.value),
        selectedWeekList[0],
        selectedWeekList[selectedWeekList.length - 1]
      );
      const data = dataPiv.tree;
      const filteredItems = filter(data, (item) => {
        return findIndex(selectedItems, ['value', item.id]) >= 0 && indexOf(selectedWeekList, item.time) >= 0;
      });
      const updatedWeekData = map(filteredItems, (item) => {
        const toModify = item['weekadjslsu'] != null ? item['weekadjslsu'] : item['a_unc_sls_u'];
        const weekadjslsu: any = Math.round(toModify * Number(modifierValue));
        return {
          product: item.id,
          time: item.time,
          weekadjslsu,
        };
      });

      if (this.props.submitPayload) {
        this.props.submitPayload({
          keyField: ['product', 'time'],
          create: [],
          update: updatedWeekData,
          delete: [],
        });
      }

      // update grid data
      if (this.props.onRefetchData) {
        this.props.onRefetchData();
      }
    }
  };

  getEditableItems = (): (MassEditSelectItemData & { time: string; weekadjslsu: number })[] | undefined => {
    const { viewDefns, massEditData } = this.props;
    const { massEdit: massEditConfig } = viewDefns!;
    const itemsDataIndex = !isNil(massEditConfig) ? massEditConfig.views[1].dataIndex : '';
    const editableItems =
      massEditData &&
      massEditData.tree.map((dataItem) => {
        const label: any = `${dataItem['name']}-${dataItem[itemsDataIndex]}`;
        const weekadjslsu = dataItem['a_unc_sls_u'];
        return {
          value: dataItem['id'],
          label,
          weekadjslsu,
          time: dataItem['time'],
        };
      });

    return editableItems;
  };

  render() {
    if (!this.props.loaded) {
      return (
        <div className={listPairStyle}>
          <Overlay type="loading" visible={true} />
        </div>
      );
    }
    const {
      title,
      isPrintMode,
      sortOptions,
      allowFrom,
      allowTo,
      rangeList,
      scopeStart,
      viewDefns,
      isOverTimeDataLoaded,
      isMassEditDataLoaded,
      isLiveDataReady,
      viewDataStateCompanion,
      viewDataStateMassEdit,
      viewDataStateOverTime,
      onItemClicked,
    } = this.props;

    const companionData = this.state.companionData;
    const selectedItem = this.state.selectedItem;
    const defaultSort = this.state.companionSortField;

    const defaultSelection = sortOptions.findIndex((option) => option.dataIndex === defaultSort);
    const selectedIndex = companionData.findIndex((datum) => datum.id === selectedItem);

    const companionProps: CompanionProps = {
      defaultSelection: defaultSelection,
      sortOptions,
      label: 'Count',
      selectedIndex: selectedIndex,
      className: companionStyles,
      data: companionData,
      noImageUrl: noImage,
      dataLookup: this.props.companionDataLookup,
      initialSortDirection: this.state.companionSortDirection,
      onListItemClicked: this.onCompanionItemClick,
      onChangeDirection: this.onCompanionDirectionChange,
      onSortSelection: this.onCompanionSortChange,
      onToggleCollapse: this.onCompanionCollapseToggle,
    };

    const flowSheetGridProps: FlowSheetGridProps = {
      timeEntries: this.props.timeInfo.entries,
      rowData: this.getGridRowData(),
      rowHeight: this.props.rowHeight,
      anchorField: this.props.scopeStart || this.props.timeInfo.planCurrent,
      editable: isLiveDataReady,
      onValueChange: this.onGridValueChange,
      onItemClicked,
      updateAssortmentPlan: this.props.updateAssortmentPlan,
    };

    const { massEdit: massEditConfig } = viewDefns!;

    const errorCondition = [ASSORTMENT_BUILD_FILTER_WARNING];
    if (!isLiveDataReady) {
      errorCondition.push(CACHED_DATA_EDITING_DISABLED_WARNING);
    }
    return (
      <div className={listPairStyle}>
        <Subheader
          title={title}
          errorCondition={errorCondition}
          showSearch={true}
          showFlowStatus={true}
          summary={this.props.subheaderSummary}
          viewDataState={[viewDataStateCompanion, viewDataStateOverTime, viewDataStateMassEdit]}
        />
        <div className="data-container">
          {!isPrintMode && <CompanionListView {...companionProps} />}
          {!isOverTimeDataLoaded ? (
            <Overlay type="loading" visible={true} />
          ) : (
            <React.Fragment>
              <MassEdit
                config={massEditConfig}
                allowFrom={allowFrom}
                allowTo={allowTo}
                scopeStart={scopeStart || ''}
                rangeList={rangeList}
                editableItems={uniqBy(this.getEditableItems(), 'value')}
                title={'Mass Copy Flowsheet Data'}
                handleSubmit={this.handleMassEditSubmission}
                dataLoading={!isMassEditDataLoaded}
              />
              <FlowSheetGrid ref={(el) => (this.fsGrid = el)} {...flowSheetGridProps} />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
