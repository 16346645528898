import { get } from 'lodash';

import { GroupBySlice, _handleDropdownExtras, SortBySlice } from 'src/components/Subheader/Subheader.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';

export function formatGroupBySelection(groupByDefn: TenantConfigViewData, groupByValue: GroupBySlice): GroupBySlice {
  const { selection, options } = groupByValue;
  const currentSelection = get(options, `[${selection}]`);
  return {
    ...groupByValue,
    ..._handleDropdownExtras(groupByValue, groupByDefn, currentSelection),
  };
}

export function formatSortBySelection(sortByDefn: TenantConfigViewData, sortByValue: SortBySlice): SortBySlice {
  const { selection, options } = sortByValue;
  const currentSelection = get(options, `[${selection}]`);
  return {
    ...sortByValue,
    ..._handleDropdownExtras(sortByValue, sortByDefn, currentSelection),
    direction: 'desc' as const,
  };
}
