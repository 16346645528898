import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { style, classes } from 'typestyle';
import * as StyleEditSectionStyles from '../StyleEditSection.styles';
import { isEqual, intersection } from 'lodash';
import { isString } from 'lodash';
import { arrayStringToArray } from 'src/utils/Primitive/String';

const styles = {
  validsizesContainer: style({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: `${StyleEditSectionStyles.validSizesRowHeight - 10}px`,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '5px 0',
  }),
  validsize: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px lightgrey solid',
    margin: '3px 1px',
    padding: '0 2px',
    height: '22px',
  }),
  checked: style({}),
  unchecked: style({
    border: '1px lightgrey solid',
    background: 'lightgrey',
    color: 'grey',
  }),
  disabled: style({
    cursor: 'not-allowed',
    color: '#555',
  }),
};

interface ValidSizesProps extends ICellRendererParams {
  options: string[];
  selectedOptions: string[];
  editable: boolean;
}

interface ValidSizesState {
  selections: string[];
}

export class ValidSizesRenderer extends React.Component<ValidSizesProps, ValidSizesState> {
  constructor(props: ValidSizesProps) {
    super(props);
    const selections = this.processSelections(props.selectedOptions) as string[];
    this.state = {
      selections,
    };
  }

  componentDidMount() {
    if (
      this.props.selectedOptions.length === 0 ||
      intersection(this.props.selectedOptions, this.props.options).length < this.props.selectedOptions.length
    ) {
      this.selectAllOptions();
    }
  }

  componentDidUpdate(oldProps: ValidSizesProps) {
    if (!isEqual(oldProps.options, this.props.options)) {
      this.selectAllOptions();
    }
  }

  processSelections(selections: string | string[]) {
    if (isString(selections)) {
      return arrayStringToArray(selections);
    } else {
      return selections;
    }
  }
  selectAllOptions() {
    this.setState({
      selections: this.props.options,
    });
  }

  onClickOption = (optionClicked: string) => {
    if (!this.props.editable) {
      return;
    }
    const { selections } = this.state;
    let newSelections = selections;
    if (this.props.options && this.props.options.length > 0) {
      newSelections = newSelections.filter((selection) => {
        return this.props.options.indexOf(selection) !== -1;
      });
    }
    if (newSelections.indexOf(optionClicked) === -1) {
      newSelections.push(optionClicked);
    } else {
      newSelections = newSelections.filter((selection) => selection !== optionClicked);
    }

    this.props.setValue(newSelections);
    this.setState({
      selections: newSelections,
    });
  };

  render() {
    const { options, editable } = this.props;
    const { selections } = this.state;

    return (
      <div className={styles.validsizesContainer}>
        {options.map((option) => {
          const className =
            selections.indexOf(option) !== -1 ? `${styles.validsize}` : `${styles.validsize} ${styles.unchecked}`;
          return (
            <div
              className={classes(className, editable ? '' : styles.disabled)}
              key={option}
              onClick={() => this.onClickOption(option)}
            >
              {option}
            </div>
          );
        })}
      </div>
    );
  }
}
