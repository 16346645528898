import { style } from 'typestyle';
import { faintBorderColor, TEAL_PRIMARY } from 'src/utils/Style/Theme';

export default {
  outerContainer: style({
    width: '100%',
    height: '100%',
    lineHeight: '35px',
  }),
  container: style({
    display: 'flex',
    position: 'relative',
    marginBottom: 20,
  }),
  titleColumn: style({
    width: '20%',
  }),
  dataColumnWrapper: style({
    width: '80%',
    display: 'flex',
    flexWrap: 'wrap',
  }),
  dataColumn: style({
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.9rem',
    width: 410,
  }),
  textColumn: style({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '10%',
    width: '50%',
  }),
  valueColumn: style({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginTop: -8,
  }),
  dataItem: style({
    height: 55,
  }),
  dataText: style({
    display: 'flex',
    alignItems: 'center',
    marginTop: -1,
  }),
  dataTextAmongEditors: style({
    display: 'flex',
    alignItems: 'flex-end',
  }),
  dataTextInput: style({
    padding: 0,
    margin: 0,
    position: 'relative',
    top: -2,
  }),
  divider: style({
    borderBottom: `1px solid ${faintBorderColor}`,
    width: '100%',
    bottom: -10,
    position: 'absolute',
  }),
  editableValue: style({
    color: TEAL_PRIMARY,
    cursor: 'pointer',
  }),
};
