import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StyleEditConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Pivot } from 'src/worker/pivotWorker.types';
import { cacheCheckFitViewData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';

export type StyleEditSlice = {
  isConfigLoading: boolean;
  viewDefn: StyleEditConfig | Record<string, never>;
  rollupViewDefn: TenantConfigViewData | Record<string, never>;
  isDataLoading: boolean;
  liveData: Pivot;
  cachedData: Pivot;
  viewDataState: ViewDataState;
};

const initialState: StyleEditSlice = {
  isConfigLoading: false,
  viewDefn: {},
  rollupViewDefn: {},
  isDataLoading: false,
  viewDataState: ViewDataState.idle,
  cachedData: {
    tree: [],
    flat: [],
  },
  liveData: {
    tree: [],
    flat: [],
  },
};

const styleEditSliceReducer = createSlice({
  name: 'StyleEdit',
  initialState,
  reducers: {
    requestStyleEditConfigs(state) {
      state.isConfigLoading = true;
    },
    receiveStyleEditConfigs(state, action: PayloadAction<{ viewDefn: StyleEditConfig; rollup: TenantConfigViewData }>) {
      state.isConfigLoading = false;
      // @ts-ignore
      state.viewDefn = action.payload.viewDefn;
      // @ts-ignore
      state.rollupViewDefn = action.payload.rollup;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
      state.cachedData = initialState.cachedData;
      state.liveData = initialState.liveData;
    },
    receiveCachedData(state, action: PayloadAction<Pivot>) {
      state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      state.cachedData = action.payload;
    },
    receiveLiveData(state, action: PayloadAction<Pivot>) {
      const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
      state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      state.liveData = action.payload;
    },
    cleanupStyleEdit() {
      return initialState;
    },
  },
});

export const {
  requestStyleEditConfigs,
  receiveStyleEditConfigs,
  requestData,
  receiveCachedData,
  receiveLiveData,
  cleanupStyleEdit,
} = styleEditSliceReducer.actions;

export function fetchStyleEditData(modelDefn: string) {
  return cacheCheckFitViewData(
    modelDefn,
    'pages.assortmentBuild.styleEdit.isDataLoading',
    requestData,
    receiveCachedData,
    receiveLiveData,
    false
  );
}

export default styleEditSliceReducer.reducer;
