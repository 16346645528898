import { AnyAction, CombinedState } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { default as createObserver } from 'src/utils/Redux/Observer';

import { Location } from 'history';
import { extractNavPath } from './pages/NavigationShell/navigationUtils';
import { isSome } from 'fp-ts/lib/Option';
import service from 'src/ServiceContainer';
import { setAutoFreeze } from 'immer';

import { createEpicMiddleware, Epic } from 'redux-observable';
import { configureStore, Action } from '@reduxjs/toolkit';
import { rootEpic } from './epics';
import { rootReducer } from './reducers';
import type { appReducer } from './reducers';
import { persistStore } from 'redux-persist';
import serviceContainer from 'src/ServiceContainer';
import type ServiceContainer from 'src/ServiceContainer';

export type AppState = ReturnType<typeof appReducer>;

export function clearCachedData() {
  service.pivotService.clearPivotCache();
}

// this is here in order to initialize state with the activeTab
// initializing state with this value avoids an extra call to setActiveTab which can trigger extraneous filter flushing
const maybeMatchHash = extractNavPath((window.location as unknown) as Location, 'hash'); // slice is to remove the leading #
const initState = {
  perspective: {
    activeTab: isSome(maybeMatchHash) ? maybeMatchHash.value[1] : '',
  },
} as ReturnType<typeof appReducer>;

setAutoFreeze(false); // this disables the object freezing applied by immer, which can cause rare issues when frozen objects are used downstream

// this injects the serviceEnv into all thunks and epics
const epicMiddleware = createEpicMiddleware<AnyAction, Action<string>, CombinedState<AppState>, typeof ServiceContainer>({
  dependencies: serviceContainer,
});
const thunkMiddlewareWithDependencies = thunk.withExtraArgument({
  dependencies: serviceContainer 
});

const isDev = process.env.NODE_ENV !== 'production';
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: false,
    }).concat(epicMiddleware).concat(thunkMiddlewareWithDependencies),
  devTools: isDev,
  preloadedState: initState,
});

export const persistor = persistStore(store);
epicMiddleware.run(rootEpic);

export type AppDispatch = ReturnType<typeof configureStore>['dispatch'];
export type AppThunk = ThunkAction<void, AppState, null, AnyAction>;
export type AppThunkDispatch = ThunkDispatch<AppState, typeof ServiceContainer, Action<string>>;
export type AppEpic = Epic<any, any, AppState, typeof ServiceContainer>;
export interface ThunkApi {
  dispatch: AppDispatch,
  state: AppState,
  extra: typeof ServiceContainer
}

export const AppObserver = createObserver(store);
export default store;
