import * as fp from 'lodash/fp';
import { connect } from 'react-redux';
import CategorySummaryView from './CategorySummary';
import container from 'src/ServiceContainer';
import selectAndProjectState from './CategorySummary.selectors';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import {
  receiveCategorySummaryViewDefns,
  requestCategorySummaryViewDefns,
  receiveGridViewDefn,
  receivePageError,
} from './CategorySummary.slice';
import { StateProjection } from './CategorySummary.selectors';
import { wrappedDispatch, WrappedThunkDispatch } from 'src/utils/Redux/Dispatch';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { TenantConfigViewData, TenantViewDefnQuery } from 'src/dao/tenantConfigClient';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { CategorySummaryComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';

export type CategorySummaryOwnProps = z.infer<typeof CategorySummaryComponentProps>;

function mergeProps(
  mappedState: StateProjection,
  dispatchProps: WrappedThunkDispatch,
  ownProps: CategorySummaryOwnProps
) {
  const { dispatch } = dispatchProps;
  const { defns } = ownProps;

  return {
    ...mappedState,
    groupByDefn: defns.subheader.groupBy,
    onShowView() {
      const tenantParams: TenantViewDefnQuery = {
        defnIds: defns.view,
        appName: ASSORTMENT,
        favoritesDefn: defns.view[3],
      };

      dispatch(requestCategorySummaryViewDefns());

      container.tenantConfigClient
        .getTenantViewDefnsWithFavorites(tenantParams)
        .then((configs) => {
          const defaultGroupByConf = configs[0];
          const unmodifiedViewDefn = configs[3];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[3],
            (configs as any)[defns.view.length],
            dispatch
          );
          if (localConfig && localConfig.config) {
            configs[3] = localConfig.config;
          }

          dispatch(
            receiveCategorySummaryViewDefns({
              groupByDefn: defaultGroupByConf,
              keyFinancials: configs[1],
              choiceProductivity: configs[2],
              gridDefn: configs[3],
              unmodifiedViewDefn,
            })
          );
        })
        .catch(() => dispatch(receivePageError()));
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(receiveGridViewDefn(config));
    },
  };
}

const sensitiveView = fp.flow(() => CategorySummaryView, makePrintSensitive)();

export default connect(selectAndProjectState, wrappedDispatch, mergeProps)(sensitiveView);
