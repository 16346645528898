import * as React from 'react';
import { Indexable } from 'src/types/Primitive';
import { AppState } from 'src/store';

import { WrappedDispatch, wrappedDispatch } from 'src/utils/Redux/Dispatch';
import { update } from 'src/services/lenses/Lenses.actions';
import { planTrackerLens } from 'src/services/lenses/lenses';
import { isNil } from 'lodash';
import { Lens } from 'monocle-ts';
import { checkAssortmentInProg } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { identity } from 'src/utils/Primitive/Function';
import { connect } from 'react-redux';
import { PlanTracker } from 'src/pages/AssortmentBuild/Planning.slice';

type InternalPlanSensitiveProps = {
  assortmentPlanInProgress: boolean;
  updateAssortmentPlan: (planInProgress: boolean) => void;
  refreshPage: () => void;
  shouldCheckAssortment: boolean;
  parentProps: Indexable;
};
export type PlanSensitiveProps = {
  assortmentPlanInProgress: boolean;
  askForRefresh: boolean;
  refreshPage: () => void;
};

export function makeHeaderbarPlanSensitive<T extends React.ComponentClass>(WrappedComponent: T) {
  function mergeProps(state: AppState, wrapped: WrappedDispatch, parentProps: any) {
    const planTrackerState = planTrackerLens.get(state);
    const props: InternalPlanSensitiveProps = {
      ...planTrackerState,
      parentProps,
      refreshPage: () => {
        wrapped.dispatch(
          update(
            planTrackerLens.modify((pt) => {
              return {
                ...pt,
                askForRefresh: false,
                refreshRequestedAt: Date.now(),
              };
            }),
            'ASST_PLAN_REFRESHING_PAGE'
          )
        );
      },
      // FIXME: I believe the following function can be removed as the actual planning dispatch updates are handled elsewhere
      updateAssortmentPlan: (planInProgress: boolean) => {
        if (planInProgress) {
          wrapped.dispatch(
            update(
              planTrackerLens.compose(Lens.fromProp<PlanTracker>()('assortmentPlanInProgress')).set(planInProgress),
              'ASST_PLAN_IN_PROGRESS'
            )
          );
        } else {
          wrapped.dispatch(
            update(
              planTrackerLens.set({
                assortmentPlanInProgress: false,
                shouldCheckAssortment: false,
                askForRefresh: true,
              }),
              'ASST_PLAN_PROGRESS_SUSPENDED'
            )
          );
        }
      },
    };
    return {
      ...props,
      parentProps,
    };
  }

  class HeaderbarPlanSensitive extends React.Component<any, { askForRefresh: boolean }> {
    checkPlanIntervalId: NodeJS.Timer | null = null;
    constructor(props: any) {
      super(props);
      this.state = {
        askForRefresh: false,
      };
    }
    componentDidUpdate() {
      if (this.props.shouldCheckAssortment && this.props.assortmentPlanInProgress && isNil(this.checkPlanIntervalId)) {
        this.checkPlanIntervalId = setInterval(async () => {
          const isProgress = await checkAssortmentInProg();
          if (!isProgress && this.checkPlanIntervalId) {
            clearInterval(this.checkPlanIntervalId);
            this.setState({
              askForRefresh: true,
            });
            this.checkPlanIntervalId = null;
          }
          this.props.updateAssortmentPlan(isProgress);
        }, 1000);
      }
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props.parentProps}
            assortmentPlanInProgress={this.props.assortmentPlanInProgress}
            askForRefresh={this.props.askForRefresh}
            refreshPage={this.props.refreshPage}
          />
        </React.Fragment>
      );
    }
  }

  // @ts-ignore
  return connect(identity, wrappedDispatch, mergeProps)(HeaderbarPlanSensitive);
}
