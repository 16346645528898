import { ColDef, ColGroupDef, GridApi } from 'ag-grid-community';
import { isNil, noop } from 'lodash';

import * as StyleEditSectionStyles from './StyleEditSection.styles';

export function setGridRowHeights(columnDefs: (ColDef | ColGroupDef)[], gridApi: GridApi) {
  if (isNil(columnDefs) || isNil(gridApi)) {
    return;
  }

  columnDefs.forEach((colInf: any) => {
    const node = gridApi.getRowNode(colInf.dataIndex);
    if (isNil(node)) {
      return;
    }

    switch (colInf.renderer) {
      case 'image': {
        node.setRowHeight(150);
        break;
      }
      case 'validSizes': {
        node.setRowHeight(StyleEditSectionStyles.validSizesRowHeight);
        break;
      }
      case 'color_header': {
        node.setRowHeight(StyleEditSectionStyles.colorHeaderRowHeight);
        break;
      }
      default: {
        noop();
      }
    }
  });

  gridApi.onRowHeightChanged();
}
