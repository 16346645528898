import * as React from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker/DayPicker';
import { Fab, Icon } from '@material-ui/core';
import { cloneDeep, isNil, values } from 'lodash';
import 'react-day-picker/lib/style.css';
import { classes } from 'typestyle';
import { WeekRangeProps, NumberedWeek } from './WeekRangePicker.interface';

import {
  getDateFromWeek,
  getSelectedIndex,
  getWeekLabel,
  getWeekRange,
  isDayAfter,
  isDayBefore,
  isDayInRange,
  getSunday,
} from './WeekRangePicker.utils';

import {
  default as styles,
  buildDayPickerContainer,
  buildWeekRangeContainer,
  buttonContainerStyles,
  wrappingContainerStyles,
} from './WeekRangePicker.styles';
import RadioOptions from './RadioOptions';
import { DayPickerType } from './RadioOptions.styles';
import { TEAL_PRIMARY } from './WeekRangePicker.styles';
import { style } from 'typestyle';
import { DayPickerProps } from 'react-day-picker/types/Props';

export type Option = {
  selected: boolean;
  dateSelection?: Date;
  hidden?: boolean;
  label?: string;
  selectedDateLabel?: string;
  disabled?: boolean;
};

interface State {
  buttonGroupHeight: number;
  hoverRange: { from: Date; to: Date } | undefined;
  options: Option[];
  optionsCache: Option[];
  showCalendar: boolean;
  visibleMonth?: Date;
  selectionsSelected?: boolean;
}

type SelectedRange = {
  selectedFrom: NumberedWeek | Date | undefined;
  selectedTo: NumberedWeek | Date | undefined;
};

const START_OF_YEAR = moment()
  .startOf('year')
  .toDate();
const END_OF_YEAR = moment()
  .endOf('year')
  .toDate();

export default class WeekRangePicker extends React.Component<WeekRangeProps, State> {
  static defaultProps = {
    numberOfMonths: 2,
    selectionOptions: [],
  };

  divContainer: React.RefObject<HTMLDivElement>;
  dayPicker: React.RefObject<DayPicker>;

  constructor(props: WeekRangeProps) {
    super(props);
    this.dayPicker = React.createRef();
    this.divContainer = React.createRef();
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayEnter = this.handleDayEnter.bind(this);
    this.handleDayLeave = this.handleDayLeave.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);

    const optionsObject = this.createOptionsObject();

    // Set first month in initialSelectedArray as visible month
    let visibleMonth;
    const { initialSelectedArray, dateToNameMap } = props;

    if (initialSelectedArray && initialSelectedArray[0]) {
      if (initialSelectedArray[0].date) {
        visibleMonth = initialSelectedArray[0].date;
      } else if (initialSelectedArray[0].weekNo) {
        visibleMonth = getDateFromWeek(initialSelectedArray[0].weekNo, dateToNameMap.start_date);
      }
    } else if (!this.initialSelectionProvided()) {
      // set visible month to beginning of selected scope if no selections are given
      visibleMonth = getDateFromWeek(props.startMonth!, props.dateToNameMap.start_date);
    }

    this.state = {
      buttonGroupHeight: 10,
      hoverRange: undefined,
      options: optionsObject.options || [],
      optionsCache: optionsObject.optionsCache || [],
      showCalendar: false,
      selectionsSelected: false,
      visibleMonth: visibleMonth,
    };
  }

  componentDidMount = () => {
    this.setInitialSelections();
    // TODO: UPDATE WITHOUT USE OF SETTIMEOUT
    setTimeout(this.forceUpdate.bind(this), 100);
  };

  componentDidUpdate(prevProps: WeekRangeProps, prevState: State) {
    const { initialSelectedFrom, initialSelectedTo, allowFrom, allowTo } = this.props;
    const { showCalendar } = this.state;
    const isCalenderOpen = prevState.showCalendar !== showCalendar && showCalendar;

    if (prevProps.initialSelectedFrom !== initialSelectedFrom || prevProps.initialSelectedTo !== initialSelectedTo) {
      this.setInitialSelections();
    }

    if (prevProps.allowFrom !== allowFrom || prevProps.allowTo !== allowTo || isCalenderOpen) {
      this.setMonthToShow();
    }
  }

  handleDayEnter(date: Date) {
    const { allowFromDate, allowToDate } = this.calculateAllowedRange();
    if (isDayInRange(date, allowFromDate, allowToDate)) {
      this.setState({
        hoverRange: getWeekRange(date),
      });
    }
  }

  handleDayLeave() {
    this.setState({
      hoverRange: undefined,
    });
  }

  handleDayClick(daySelected: Date) {
    if (!this.isValidDate(daySelected)) {
      return;
    }

    const nextOptions = this.calculateNextOptionsState(daySelected);
    this.setState({
      options: nextOptions,
    });
  }

  handleSelectionChange(selected: number) {
    const { options } = this.state;
    const nextOptions = options.map((option, idx) => ({
      ...option,
      selected: !!(idx === selected),
    }));
    this.setState(() => ({
      options: nextOptions,
    }));
  }

  handleCancel() {
    this.revertSelections();
    this.toggleCalendar();
    if (this.props.onCalendarCancel) {
      this.props.onCalendarCancel();
    }
  }

  handleSubmit() {
    const { onNewWeeksSelectedMulti, dateToNameMap } = this.props;
    const { options } = this.state;
    const hasSelectedAll = options.every((option: Option) => {
      if (option.hidden) {
        // date selection on hidden values aren't required
        return true;
      }

      return !!option.dateSelection;
    });
    if (hasSelectedAll) {
      if (onNewWeeksSelectedMulti) {
        const finalSelections: NumberedWeek[] = options.map((option) => {
          const label = getWeekLabel(option.dateSelection!, dateToNameMap);
          return {
            date: option.dateSelection,
            weekNo: label,
          };
        });
        onNewWeeksSelectedMulti(finalSelections);
      }
      this.saveSelections();
      this.toggleCalendar();
    }
  }

  handleDismiss() {
    const { options } = this.state;
    const hasSelectedAll = options.every((option: Option) => {
      return !!option.dateSelection;
    });
    if (hasSelectedAll) {
      this.saveSelections();
    }
    if (!hasSelectedAll) {
      this.revertSelections();
    }
    this.toggleCalendar();
  }

  isValidDate(day: Date) {
    const { options } = this.state;
    const { allowFromDate, allowToDate } = this.calculateAllowedRange();
    const selectedIndex = getSelectedIndex(options);
    const previousOption = options[selectedIndex - 1];
    const previousOptionDate = (previousOption && previousOption.dateSelection) || day;

    // Selection constraints.
    if (!isDayInRange(day, allowFromDate, allowToDate) || isDayBefore(day, previousOptionDate)) {
      return false;
    }
    return true;
  }

  calculateNextOptionsState(daySelected: Date) {
    const { options } = this.state;
    const selectedOptionIndex = getSelectedIndex(options);
    return options.map(
      (option, currentOptionIdx): Option => {
        const isSelectedOption = currentOptionIdx === selectedOptionIndex;
        const currentOptionDate = option.dateSelection;
        let dateSelection;

        if (isSelectedOption) {
          dateSelection = moment(getSunday(daySelected)).toDate();
        }

        if (!isSelectedOption) {
          let currentWeek: string | undefined;
          if (currentOptionDate) {
            dateSelection = currentOptionDate;
            currentWeek = getWeekLabel(currentOptionDate, this.props.dateToNameMap);
          }
          const selectedWeek = getWeekLabel(daySelected, this.props.dateToNameMap);
          if (
            // Invalidate dates that surpass following options' selected date.
            (selectedOptionIndex < currentOptionIdx && currentWeek != null && selectedWeek >= currentWeek) ||
            // Does the autoSelect for single click scope selection.
            (!currentOptionDate && this.props.autoSelect)
          ) {
            if (this.props.disableOverlap) {
              dateSelection = undefined;
            } else {
              dateSelection = moment(daySelected).toDate();
            }
          }
        }

        return {
          ...option,
          dateSelection,
          selected: currentOptionIdx === this.getNextSelectionOption(),
        };
      }
    );
  }

  createOptionsObject() {
    const {
      selectionOptions,
      initialSelectedFrom,
      initialSelectedTo,
      dateToNameMap,
      initialSelectedArray,
      allowFrom,
    } = this.props;
    const { start_date, end_date } = dateToNameMap;
    const hasOptions = selectionOptions!.length > 0;

    let options: Option[] = [];
    if (!hasOptions) {
      // Uses default state's options.
      options = [
        {
          dateSelection: undefined,
          hidden: true,
          label: undefined,
          selected: true,
          selectedDateLabel: undefined,
        } as Option,
      ];
      if (initialSelectedFrom) {
        options[0].dateSelection = getDateFromWeek(initialSelectedFrom, start_date);
      }
    }

    if (hasOptions) {
      options = selectionOptions!.map(
        ({ label }) =>
          ({
            dateSelection: undefined,
            label,
            selected: false,
            selectedDateLabel: undefined,
            disabled: false,
          } as Option)
      );

      options[0].selected = true; // First options is selected by default.

      if (initialSelectedFrom) {
        // determine if start should be disabled
        const selectedFromDate = getDateFromWeek(initialSelectedFrom, start_date);
        const allowedFromDate = getDateFromWeek(allowFrom, start_date);
        const selectionBeforeAllowed = moment(selectedFromDate).isBefore(moment(allowedFromDate));
        options[0].dateSelection = selectedFromDate;

        if (selectionBeforeAllowed) {
          options[0].disabled = true;
          options[0].selected = false;

          if (options.length >= 2) {
            // set selection to next item
            options[1].selected = true;
          }
        }
      }

      if (initialSelectedTo) {
        // determine if end should be disabled
        const selectedToDate = getDateFromWeek(initialSelectedTo, end_date);
        // Since overlapping weeks are not allowed, make sure there are enough date options to select
        const allowedToDate = getDateFromWeek(this.getSelectedAllowedTo(end_date, options), end_date);
        const selectionAfterAllowed = moment(selectedToDate).isAfter(moment(allowedToDate));
        options[options.length - 1].dateSelection = selectedToDate;

        if (selectionAfterAllowed) {
          options[1].disabled = true;
          options[1].selected = false;

          if (options.length >= 3) {
            // set selection to previous item if available
            options[0].selected = true;
          } else {
            // or to last item that will need to be added and hidden
            options.push({
              selected: true,
              hidden: true,
            });
          }
        }
      }
    }

    if (initialSelectedArray) {
      // determine which options in range should be disabled
      let pushHidden = false;
      for (let index = 0; index < initialSelectedArray.length; index++) {
        options[index].dateSelection = initialSelectedArray[index].date;

        // for all options (except last) check if out of range and set disabled true
        // for last option if out of range set disabled and set pushHidden true

        const selection = initialSelectedArray[index].weekNo!;
        if (!this.selectionWithinAllowedRange(selection)) {
          options[index].disabled = true;
          options[index].selected = false;
          if (index === initialSelectedArray.length - 1) {
            pushHidden = true;
          }
        }
      }

      if (pushHidden) {
        options.push({ hidden: true, selected: false });
      }

      const nextAvailableIndex = options.findIndex((option) => !option.disabled && !option.hidden);
      if (nextAvailableIndex >= 0) {
        options[nextAvailableIndex].selected = true;
      } else {
        // no selections in range, select hidden option
        options[options.length - 1].selected = true;
      }
    }

    return {
      options: cloneDeep(options),
      optionsCache: cloneDeep(options),
    };
  }

  getSelectedAllowedTo = (end_date: Record<string, string>, options: Option[]) => {
    const allowToDateIndex = values(end_date)
      .sort()
      .indexOf(this.props.allowTo);
    const allowTo: string =
      allowToDateIndex != -1 && this.props.disableOverlap
        ? Object.values(end_date).sort()[
            allowToDateIndex -
              options
                .slice()
                .reverse()
                .findIndex((x) => x.selected)
          ]
        : this.props.allowTo;
    return allowTo;
  };

  selectionWithinAllowedRange(selection: string) {
    const { dateToNameMap, allowFrom, allowTo } = this.props;
    const { start_date, end_date } = dateToNameMap;
    const selectedDate = getDateFromWeek(selection, start_date) || getDateFromWeek(selection, end_date);
    const allowedFromDate = getDateFromWeek(allowFrom, start_date);
    let allowedToDate = getDateFromWeek(allowTo, end_date);
    if (this.state && this.state.options) {
      allowedToDate = getDateFromWeek(this.getSelectedAllowedTo(end_date, this.state.options), end_date);
    }

    if (moment(selectedDate).isBefore(moment(allowedFromDate)) || moment(selectedDate).isAfter(moment(allowedToDate))) {
      return false;
    }

    return true;
  }

  setInitialSelections() {
    const { options } = this.state;
    const optionsDeepCopy = cloneDeep(options);

    const { dateToNameMap, initialSelectedFrom, initialSelectedTo, initialSelectedArray } = this.props;
    const { start_date, end_date } = dateToNameMap;

    if (initialSelectedFrom) {
      optionsDeepCopy[0].dateSelection = getDateFromWeek(initialSelectedFrom, start_date);
    }
    if (initialSelectedTo) {
      optionsDeepCopy[optionsDeepCopy.length - 1].dateSelection = getDateFromWeek(initialSelectedTo, end_date);
    }

    if (initialSelectedArray) {
      initialSelectedArray.forEach((numberedWeek, index) => {
        if (numberedWeek && numberedWeek.weekNo) {
          optionsDeepCopy[index].dateSelection = getDateFromWeek(numberedWeek.weekNo, start_date)
            ? getDateFromWeek(numberedWeek.weekNo, start_date)
            : getDateFromWeek(numberedWeek.weekNo, end_date);
        }
      });
    }

    this.setState({
      options: optionsDeepCopy,
    });
  }

  calculateAllowedRange() {
    const { allowFrom, dateToNameMap, disableOverlap } = this.props;
    const { start_date, end_date } = dateToNameMap;
    const { options } = this.state;

    // Since overlapping weeks are not allowed, make sure there are enough date options to select
    let allowFromDate = getDateFromWeek(allowFrom || start_date[0], start_date) || START_OF_YEAR;
    const allowToDate = getDateFromWeek(this.getSelectedAllowedTo(end_date, options), end_date) || END_OF_YEAR;
    const selectedIndex = getSelectedIndex(options);

    if (selectedIndex > 0 && !isNil(disableOverlap) && disableOverlap) {
      const prevOption = options[selectedIndex - 1];

      // set next available dateSelection to next week after prevOption's dateSelection
      // unless that selection is in disabled section
      const prevPlusSeven = moment(prevOption.dateSelection)
        .add(7, 'days')
        .toDate();
      if (prevOption && prevOption.dateSelection && prevPlusSeven > allowFromDate) {
        allowFromDate = prevPlusSeven;
      }
    } else {
      const firstOption = options[0];
      const firstOptionSelection = firstOption && firstOption.dateSelection!;
      // Disable dates that exist before the first option selection.
      if (!firstOption.selected && firstOptionSelection && isDayAfter(firstOptionSelection!, allowFromDate!)) {
        allowFromDate = firstOptionSelection;
      }
    }

    return {
      allowFromDate,
      allowToDate,
    };
  }

  calculateSelectedRange(options: Option[]): SelectedRange {
    const hasDateSelections = options.some((option) => !!option.dateSelection);

    let selectedRange: SelectedRange = {
      selectedFrom: undefined,
      selectedTo: undefined,
    };
    if (!hasDateSelections) {
      selectedRange = {
        selectedFrom: undefined,
        selectedTo: undefined,
      };
    }

    if (hasDateSelections) {
      selectedRange = options.reduce(
        (acc, option) => {
          if (!acc.selectedFrom && option.dateSelection) {
            acc.selectedFrom = getWeekRange(option.dateSelection).from;
          }
          if (option.dateSelection) {
            acc.selectedTo = getWeekRange(option.dateSelection).to;
          }
          return acc;
        },
        /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
        {} as { selectedFrom: Date; selectedTo: Date }
        /**
         * TODO: Need to return the ranges for each pair of options.
         * [{ form , to }, { from , to }]
         */
      );
    }
    return selectedRange;
  }

  dateUpdated(nextOptions: Option[]) {
    const { onNewWeeksSelected, onNewWeeksSelectedMulti } = this.props;
    const { selectedFrom, selectedTo } = this.calculateSelectedRange(nextOptions);
    if (onNewWeeksSelected) {
      onNewWeeksSelected({ from: selectedFrom as Date, to: selectedTo as Date });
    }
    if (onNewWeeksSelectedMulti) {
      onNewWeeksSelectedMulti([selectedFrom as NumberedWeek, selectedTo as NumberedWeek]);
    }
  }

  toggleCalendar() {
    this.setState({
      showCalendar: !this.state.showCalendar,
    });
  }

  getNextSelectionOption() {
    const { options } = this.state;
    const currentSelectedIndex = getSelectedIndex(options);
    if (currentSelectedIndex === options.length - 1) {
      return currentSelectedIndex;
    }
    const nextAvailableIndex = options.findIndex((option, index) => {
      // return first item after current selection that is not hidden/disabled
      return index > currentSelectedIndex && !option.disabled && !option.hidden;
    });
    const nextIndex = nextAvailableIndex >= 0 ? nextAvailableIndex : currentSelectedIndex;

    return nextIndex;
  }

  initialSelectionProvided(): boolean {
    const { initialSelectedArray, initialSelectedFrom, initialSelectedTo, startMonth } = this.props;

    if (isNil(initialSelectedArray) && isNil(initialSelectedFrom) && isNil(initialSelectedTo) && !isNil(startMonth)) {
      return false;
    }

    return true;
  }

  revertSelections() {
    const { optionsCache } = this.state;
    this.setState({
      options: cloneDeep(optionsCache),
    });
  }

  saveSelections() {
    const { options } = this.state;
    this.setState({
      optionsCache: cloneDeep(options),
    });
    this.dateUpdated(options);
  }

  setMonthToShow() {
    // when no selection provided, set visibleMonth to initial startMonth
    if (!this.initialSelectionProvided()) {
      const { visibleMonth } = this.state;
      const { startMonth, dateToNameMap } = this.props;
      let month = visibleMonth;

      if (isNil(visibleMonth)) {
        month = getDateFromWeek(startMonth!, dateToNameMap.start_date);
      }

      this.setState({
        visibleMonth: month,
      });
      return;
    }

    const { allowFromDate, allowToDate } = this.calculateAllowedRange();
    const { options } = this.state;
    const { selectedFrom } = this.calculateSelectedRange(options);
    const today = new Date();

    let initialMonth = today;
    // Checks if "today" is outside of valid slection range.
    if (allowFromDate && allowToDate) {
      if (isDayAfter(today, allowToDate)) {
        initialMonth = allowToDate;
      } else if (isDayBefore(today, allowFromDate)) {
        initialMonth = allowFromDate;
      }
    }

    if (selectedFrom) {
      /**
       * Because of the way this component is implemented, (Updating state and
       * communicating with other WeekRangePickers) the month to show needs to be
       * set programatically. If not, the month will not be updated when needed.
       * The DatePicker updates the visible month once. If the user navigates
       * through months but the actual date remains the same, when you open the
       * DatePicker again, it will show the month were it was left.
       */
      this.dayPicker.current!.showMonth(selectedFrom as Date);
      initialMonth = selectedFrom as Date;
    }

    this.setState({
      visibleMonth: initialMonth,
    });
  }

  render() {
    const { hoverRange, options, visibleMonth } = this.state;
    const { dateToNameMap, colors, numberOfMonths, hideInput } = this.props;
    const { selectedFrom, selectedTo } = this.calculateSelectedRange(options);
    let showCalendar = this.state.showCalendar;

    // Input display.
    let selectedRangeDisplay = '';
    if (selectedFrom && selectedTo) {
      const fromLabel = getWeekLabel(selectedFrom as Date, dateToNameMap);
      const toLabel = getWeekLabel(selectedTo as Date, dateToNameMap);
      selectedRangeDisplay = `${fromLabel} to ${toLabel}`;
    }

    // Disabled dates.
    const { allowFromDate, allowToDate } = this.calculateAllowedRange();
    const disabledDays = {
      before: getWeekRange(allowFromDate).from,
      after: getWeekRange(allowToDate).to,
    };

    // Modifiers: Object Keys are mapped to specific styles.
    const selectedAsOptions = options
      .filter((option) => !!option.dateSelection)
      .map((option) => getWeekRange(option.dateSelection));
    const selectedRange = { from: selectedFrom as Date, to: selectedTo as Date };

    const modifiers = {
      hoverRange,
      selectedAsOptions,
      selectedRange,
    };

    const dayPickerOptions: DayPickerProps = {
      disabledDays,
      modifiers,
      numberOfMonths,
      month: visibleMonth,
      showWeekNumbers: true,
      renderWeek: (_weekNumber, week) => {
        const firstDayInWeek = week[0];
        const weekLabel: string = getWeekLabel(firstDayInWeek, this.props.dateToNameMap);

        // This just looks for the "W" or "w" characters, and takes that character and all the numbers after it
        // as the week label
        // This will break if the week ids don't have a "w" and "W" in it
        // Expected inputs are, for example (from multiple clients):
        // 2020-W30
        // 2017_W21
        // 2022-W04
        // 2022_W01
        const match = weekLabel.match(/([wW]\d+)/g);
        return `${!isNil(match) ? match[0] : '---'}`;
      },
    };
    const dayPicker = this.dayPicker.current as DayPickerType;

    // WeekRangePicker w/ Input defaults
    let overrides = colors;
    let wrappingContainerStyle = wrappingContainerStyles;
    let buttonContainerStyle = buttonContainerStyles(dayPicker);
    let radioOptionsOverrides;
    let input: JSX.Element | undefined = (
      <React.Fragment>
        <i className="fa fa-calendar" />
        <input
          data-qa="DatePickerInput"
          type="text"
          className={styles.DayInputContainer}
          value={selectedRangeDisplay}
          onClick={this.toggleCalendar}
          readOnly={true}
          tabIndex={-1}
        />
      </React.Fragment>
    );
    let weekRangeOverrides = colors;

    if (hideInput) {
      // All style overrides for hideInput and styleOverride
      overrides = {
        ...colors,
        border: '1px solid ' + TEAL_PRIMARY,
        borderRadius: 20,
        minWidth: 780,
        top: 0,
      };

      const wrappingContainerOverrides =
        this.props.styleOverrides && this.props.styleOverrides.top ? this.props.styleOverrides : { top: 50 };

      wrappingContainerStyle = classes(wrappingContainerStyles, style(wrappingContainerOverrides));
      buttonContainerStyle = classes(
        buttonContainerStyles(dayPicker),
        style({
          top: 0,
        })
      );
      radioOptionsOverrides = { minWidth: 120, top: 0 };
      input = undefined;
      weekRangeOverrides = {
        ...colors,
        padding: '0px',
      };
      showCalendar = true;
    }

    return (
      <div className={buildWeekRangeContainer(weekRangeOverrides)} ref={this.divContainer}>
        {input}
        <div hidden={!showCalendar} className="week-range-picker-box">
          <div onClick={this.handleDismiss} className={styles.backdrop} />
          <div className={wrappingContainerStyle}>
            {this.state.options.length > 1 && (
              <RadioOptions
                dateToNameMap={dateToNameMap}
                onOptionChange={this.handleSelectionChange}
                parentContainer={dayPicker}
                selectionOptions={this.state.options}
                overrides={radioOptionsOverrides}
              />
            )}
            <div className={buttonContainerStyle}>
              <Fab
                className="button button__submit"
                data-qa-action="submit"
                aria-label="Submit"
                onClick={this.handleSubmit}
              >
                <Icon className="fas fa-check icon" />
              </Fab>
              <Fab
                className="button button__cancel"
                data-qa-action="cancel"
                aria-label="Cancel"
                onClick={this.handleCancel}
              >
                <Icon className="fas fa-times icon" />
              </Fab>
            </div>
            <DayPicker
              {...dayPickerOptions}
              ref={this.dayPicker}
              className={buildDayPickerContainer(dayPicker, overrides, numberOfMonths!)}
              onDayClick={this.handleDayClick}
              onDayMouseEnter={this.handleDayEnter}
              onDayMouseLeave={this.handleDayLeave}
            />
          </div>
        </div>
      </div>
    );
  }
}
