import * as React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { styles } from './ValidValuesCheckboxEditor.styles';

export type ValidValuesCheckBoxEditorHeaderProps = {
  availableHeaders: string[];
};

type HeaderProps = IHeaderParams & ValidValuesCheckBoxEditorHeaderProps;
export class ValidValuesCheckBoxEditorHeader extends React.Component<HeaderProps, {}> {
  constructor(props: HeaderProps) {
    super(props);
  }

  render() {
    const columns = this.props.availableHeaders.map((header) => {
      return (
        <div key={header} style={{ width: 42 }} className="ag-header-cell-text">
          {header}
        </div>
      );
    });
    return (
      <div className={styles} style={{ paddingLeft: '10px' }}>
        {columns}
      </div>
    );
  }
}
