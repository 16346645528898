import { OptionGroup, Option } from 'src/components/Configure/ConfigureModal';
import { ColDef } from 'ag-grid-community';
import { remove, uniqBy, memoize, isEmpty } from 'lodash';

import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { FrameworkComponents, parseGridConfig, MasterDetailConfig } from 'src/utils/Component/AgGrid/AgConfigParse';
import { BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import {
  GroupBySlice,
  ReduxSlice as SubheaderSlice,
  SortBySlice,
  SubheaderViewDefns,
} from 'src/components/Subheader/Subheader.slice';

import {
  GridItem,
  groupedToAgFlatTree,
  listDataTreeToAgFlatTree,
  GroupHeaderKey,
} from 'src/utils/Component/AgGrid/AgDataFormat';

import { formatSummaries, processSummaries } from 'src/utils/Pivot/RollUp';
import { filterAndSortPivotItems, filterData, filterPareDown, mergeWorklistForSort } from 'src/utils/Pivot/Filter';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { parseConfigureConfig } from 'src/components/Configure/Configure';
import { parseSortConfig } from 'src/utils/Config/Sort';
import { DefaultShownValues } from 'src/common-ui/components/DataGrid/DataGrid';
import { buildGroupsFromFlatData } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import { parseCompanionListViewConfig, CompanionDataLookup } from 'src/utils/Component/ListView';
import { flattenToLeaves } from 'src/utils/Pivot/Flatten';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { MassEditConfig } from '../MassEdit/MassEdit';
import { flattenAndLabelGroup } from '../StandardCardView/SortAndGroup';
import { FabType } from '../higherOrder/withFab';
import { RouteComponentProps } from 'react-router-dom';
import { FavoriteResponseItem } from '../Subheader/Favorites/FavoritesMenu';
import { ViewDataState } from 'src/types/Domain';

export type OwnProps = {
  showPopover?: boolean;
  allowCartFunctionality?: boolean;
  allowWorklistFunctionality?: boolean;
  allowExceptionMultiSelect?: boolean;
  fabType?: FabType;
  subheader?: {
    downloadLink: string;
  };
} & RouteComponentProps;

export type ViewDefns = {
  grid: TenantConfigViewData;
  listSort: TenantConfigViewData;
  subheaderRollUp?: TenantConfigViewData;
  subheaderGroup?: TenantConfigViewData;
  configure?: TenantConfigViewData;
  list: TenantConfigViewData;
  massEdit?: MassEditConfig;
  unmodifiedViewDefn?: TenantConfigViewData;
  favoritesList?: FavoriteResponseItem[];
};

export type LoadingProjection = {
  loaded: false;
  subheaderViewDefns: SubheaderViewDefns;
  fabType: FabType;
  isFabDisabled: false;
};

export type LoadedProjection = {
  identityField: string;
  loaded: true;
  defaultConfigureSelections: Option[];
  data: GridItem[];
  uniqueItems: BasicPivotItem[];
  colDefs: ColDef[];
  masterDetailConfig: MasterDetailConfig | null;
  defaultCompanionSortField: SortOption;
  companionDataLookup: CompanionDataLookup;
  sortOptions: SortOption[];
  frameworkComponents: FrameworkComponents;
  treeColumnDefinition: ColDef | undefined;
  stylePaneTriggerSet: Set<string>;
  subheaderViewDefns: SubheaderViewDefns;
  fabType: FabType;
  fabTooltip?: string;
  fabDefn?: string;
  isFabDisabled: boolean;
  allowCartFunctionality?: boolean;
  allowWorklistFunctionality?: boolean;
  allowExceptionMultiSelect?: boolean;
  configureInstructions?: string;
  configureOptionGroups?: OptionGroup[];
  configureSelections?: Option[];
  originalDefaultSelections?: Option[];
  defaultShownValues?: DefaultShownValues;
  isPrintMode?: boolean;
  rowHeight?: number;
  subheaderSummary?: string;
  configuratorViewDefn?: TenantConfigViewData;
  unmodifiedViewDefn?: TenantConfigViewData;
  favoritesList: FavoriteResponseItem[];
  sortBy: SortBySlice;
  groupBy: GroupBySlice;
  flowStatus: number[];
  rollupConfig?: TenantConfigViewItem[];
  downloadLink?: string;
  shouldFilterFlowStatus: boolean;
};

export type StateProjection = (LoadingProjection | LoadedProjection) & {
  subheaderViewDefns: SubheaderViewDefns;
  title: string;
  hideTitle?: boolean;
  hideCompanion?: boolean;
  activeTab: string;
  viewDataState?: ViewDataState;
  topMembers?: string;
};

export type StateSelection = {
  fabType: FabType;
  fabTooltip?: string;
  isPrintMode?: boolean;
  identityField: string;
  loaded: boolean;
  shouldFilterFlowStatus: boolean;
  aggBys?: string[];
  treeData: BasicPivotItem[];
  worklistData?: WorklistInfo[];
  subheaderViewDefns: SubheaderViewDefns;
  viewDefns?: ViewDefns;
  isConfigLoading: boolean;
  subheaderState: SubheaderSlice;
  title: string;
  hideTitle?: boolean;
  hideCompanion?: boolean;
  activeTab: string;
  allowWorklistFunctionality?: boolean;
  allowExceptionMultiSelect?: boolean;
  downloadLink?: string;
  viewDataState?: ViewDataState;
  fabDefn?: string;
};

function generateOrderedGroups(flatData: BasicPivotItem[], groupBy: GroupBySlice) {
  const groups = buildGroupsFromFlatData(flatData, groupBy);
  const newGroupsObj = {};
  groups.forEach((group) => (newGroupsObj[group.header] = group.items));
  return newGroupsObj;
}

export function projectState(stateSelection: StateSelection): StateProjection {
  const {
    fabType = FabType.none,
    fabTooltip,
    loaded,
    identityField,
    treeData,
    aggBys,
    viewDefns,
    isConfigLoading,
    subheaderState,
    title,
    subheaderViewDefns,
    shouldFilterFlowStatus = true,
    allowWorklistFunctionality = false,
    allowExceptionMultiSelect,
    activeTab,
    worklistData,
    downloadLink,
    viewDataState,
    hideTitle,
    hideCompanion,
    fabDefn
  } = stateSelection;

  const { search, sortBy, flowStatus, groupBy, favoritesList } = subheaderState;

  if (loaded && !isConfigLoading && viewDefns && !isEmpty(viewDefns.grid) && !isEmpty(viewDefns.list)) {
    // Config
    const {
      rowHeight,
      colDefs,
      treeColumnDefinition,
      frameworkComponents,
      stylePaneTriggerSet,
      defaultShownValues,
      masterDetailConfig,
    } = parseGridConfig(viewDefns.grid);

    let actualTreeColumnDefinition = treeColumnDefinition;
    const actualColDefs = [...colDefs];
    let gridItems: GridItem[];
    let configureOptionGroups;
    let defaultConfigureSelections: Option[] = [],
      originalDefaultSelections;
    let configureInstructions;

    if (viewDefns.configure) {
      const parseResult = parseConfigureConfig(viewDefns.configure);
      configureOptionGroups = parseResult.optionGroups;
      defaultConfigureSelections = parseResult.defaultSelections;
      configureInstructions = parseResult.instructions;
      originalDefaultSelections = parseResult.originalDefaultSelections;
    }
    const flowStatusFilter = (shouldFilterFlowStatus && flowStatus) || [];

    // Parse companion view config
    const companionDataLookup = parseCompanionListViewConfig(viewDefns.list);
    const getFlat = memoize(() => flattenToLeaves(treeData));

    let searchFields = externalGridSearchFields;
    if (viewDefns && viewDefns.grid && viewDefns.grid.searchIndexes) {
      searchFields = viewDefns.grid.searchIndexes;
    }

    // Subheader Group By
    if (subheaderState.groupByDefn && subheaderState.groupBy.selection) {
      const key = getGroupBySelectedOptionProperty(subheaderState.groupBy, 'dataIndex');
      remove(actualColDefs, (colDef) => colDef.field === key && colDef.headerName !== 'Group');

      // GroupBy supercedes config passed treeColumns
      if (treeColumnDefinition) {
        actualColDefs.push(treeColumnDefinition);
      }

      const groupDataIndex = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
      const groupDimension = getGroupBySelectedOptionProperty(groupBy, 'dimension');
      const result = groupedToAgFlatTree(
        generateOrderedGroups(
          flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension }),
          subheaderState.groupBy
        ),
        key,
        search,
        sortBy,
        flowStatusFilter,
        viewDefns.grid.view,
        identityField,
        key == companionDataLookup.displayTitle,
        allowExceptionMultiSelect
      );
      actualTreeColumnDefinition = result.treeColumnDefinition;
      gridItems = worklistData ? mergeWorklistForSort(result.agFlatTree, worklistData) : result.agFlatTree;

      // FIXME: is this still needed
      // filter out duplicate checkbox coldef from config since it will be rendered in group column
      // if (allowExceptionMultiSelect) {
      //   actualColDefs = actualColDefs.filter((colDef) => colDef.field !== 'checkbox');
      // }
    } else if (aggBys && aggBys.length) {
      // GroupBy supercedes config passed treeColumns
      if (treeColumnDefinition) {
        actualColDefs.push(treeColumnDefinition);
      }
      // TODO: make nameOverride configurable, was here to fix INT-573
      const nameOverride = title === 'Nested View';
      const result = listDataTreeToAgFlatTree(aggBys, search, flowStatusFilter, treeData, nameOverride);
      actualTreeColumnDefinition = result.treeColumnDefinition;
      gridItems = result.agFlatTree;
    } else {
      // No Group By
      const flatData = getFlat();
      const mergedData = worklistData ? mergeWorklistForSort(flatData, worklistData) : flatData;
      gridItems = filterAndSortPivotItems(search, sortBy, searchFields, flowStatusFilter, mergedData);
    }

    if (gridItems && subheaderState.pareDownDefn && subheaderState.pareDown) {
      gridItems = filterPareDown(gridItems, subheaderState.pareDown.selections, subheaderState.groupBy);
    }

    let subheaderSummary;

    const getUniqFlatLeafs = memoize(() => {
      const leaves = gridItems.filter((item) => item[GroupHeaderKey] === undefined);
      return uniqBy(leaves, identityField);
    });

    if (viewDefns.subheaderRollUp && viewDefns.subheaderRollUp.view) {
      const filtered = filterData(gridItems, search, searchFields, flowStatusFilter);
      subheaderSummary = formatSummaries(processSummaries(filtered, viewDefns.subheaderRollUp.view, identityField));
    }
    const sortParseResult = parseSortConfig(viewDefns.listSort);

    const projection: StateProjection = {
      loaded: true,
      defaultShownValues,
      identityField,
      // grid
      rowHeight,
      data: gridItems,
      colDefs: actualColDefs,
      masterDetailConfig,
      treeColumnDefinition: actualTreeColumnDefinition,
      frameworkComponents,
      stylePaneTriggerSet,
      // configure
      configureInstructions,
      defaultConfigureSelections,
      configureOptionGroups,
      originalDefaultSelections,
      // subheader
      title,
      hideTitle,
      subheaderViewDefns,
      unmodifiedViewDefn: viewDefns.unmodifiedViewDefn,
      sortBy,
      groupBy,
      flowStatus,
      activeTab,
      rollupConfig: viewDefns.subheaderRollUp && viewDefns.subheaderRollUp.view,
      configuratorViewDefn: viewDefns.grid,
      subheaderSummary,
      shouldFilterFlowStatus,
      // companion list
      hideCompanion,
      defaultCompanionSortField: sortParseResult.defaultSort,
      sortOptions: sortParseResult.sortOptions,
      companionDataLookup,
      uniqueItems: getUniqFlatLeafs(),
      allowWorklistFunctionality,
      allowExceptionMultiSelect,
      fabType,
      fabTooltip,
      fabDefn,
      isFabDisabled: false,
      downloadLink,
      favoritesList: favoritesList || [],
      viewDataState

    };
    return projection;
  }
  return {
    loaded: false,
    title,
    hideTitle,
    hideCompanion,
    subheaderViewDefns,
    activeTab,
    fabType,
    isFabDisabled: false,
    viewDataState,
  };
}
