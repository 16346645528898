import { TenantConfigViewData } from 'src/dao/tenantConfigClient';

export function defaultsToAggBys(viewData: TenantConfigViewData) {
  if (viewData.defaults) {
    // Derp
    const { first, second, third, fourth } = viewData.defaults;
    return [first, second, third, fourth].filter((i) => i);
  }
  return [];
}
