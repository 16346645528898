import { connect } from 'react-redux';

import CardView from 'src/components/StandardCardView/StandardCardView';
import container from 'src/ServiceContainer';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { DispatchProps, IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { getWorklist } from 'src/pages/Hindsighting/StyleColorReview/SharedData.slice';

import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { getProcessedData, getSummary, getFilteredFlatData } from './SummaryView.selectors';
import { AppState, AppThunkDispatch } from 'src/store';
import { receiveTenantConfig, receiveError, requestTenantConfig } from './SummaryView.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { retrieveIdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.utils';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { flow } from 'lodash';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { SummaryViewComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { isDataLoaded } from 'src/services/pivotServiceCache';

export type SummaryViewOwnProps = z.infer<typeof SummaryViewComponentProps>;

function mapStateToProps(state: AppState, ownProps: SummaryViewOwnProps) {
  const { summaryView } = state.pages.hindsighting.styleColorReview;
  const { defns, allowWorklistFunctionality = false, title = 'Summary View', hideTitle } = ownProps;
  const loaded = !summaryView.tenantConfigLoading && isDataLoaded(summaryView.viewDataState);

  return {
    title,
    hideTitle,
    loaded,
    config: summaryView.viewDefn,
    sortBy: state.subheader.sortBy,
    groupBy: state.subheader.groupBy,
    subheaderViewDefns: defns.subheader,
    filteredStyles: getFilteredFlatData(state),
    groupedStyles: getProcessedData(state),
    summary: getSummary(state),
    identityPropsConfig: summaryView.identityPropsConfig,
    currentTab: state.perspective.activeTab,
    unmodifiedViewDefn: summaryView.unmodifiedViewDefn,
    allowWorklistFunctionality,
    fabType: ownProps.fabType || FabType.none,
    fabToolip: ownProps.fabTooltip,
    downloadLink: ownProps.subheader?.downloadLink,
    viewDataState: summaryView.viewDataState,
    fabDefn: defns.fab,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: SummaryViewOwnProps): DispatchProps {
  const { defns } = ownProps;

  return {
    onShowView() {
      const { tenantConfigClient } = container;
      dispatch(requestTenantConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: 'Assortment',
        })
        .then((resp: (TenantConfigViewData & { identityProps?: IdentityPropsConfig })[]) => {
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch
          );
          const storedConfig = localConfig && localConfig.config ? localConfig.config : resp[0];
          const identityPropsConfig = retrieveIdentityPropsConfig(resp[0]);

          dispatch(
            receiveTenantConfig({
              viewDefn: storedConfig,
              calcViewDefn: resp[1],
              unmodifiedViewDefn: unmodifiedViewDefn,
              identityPropsConfig,
            })
          );
        })
        .catch(() => dispatch(receiveError()));
    },
    showStylePane(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onItemClicked(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        receiveTenantConfig({
          viewDefn: config,
        })
      );
    },
    refetchWorklist() {
      dispatch(getWorklist());
    },
  };
}

const wrappedView = flow(() => CardView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
