import {
  REQUEST_TENANT_CONFIG,
  RECEIVE_TENANT_CONFIG,
  REQUEST_GEO_DATA,
  RECEIVE_GEO_DATA,
  RECEIVE_ERROR,
  SELECT_GEO_LEVEL,
  CLEAN_UP,
  Action,
  RECEIVE_GEO_DATA_ERROR,
  GEOTRENDS_REQUEST_CHART_DATA,
  GEOTRENDS_RECEIVE_CHART_DATA,
  SELECT_POINT,
} from './GeoTrends.actions';
import { TenantConfigViewItem, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export type ReduxSlice = {
  loaded: boolean;
  mapUri: string;
  geoData: BasicPivotItem[];
  geoDataLoaded: boolean;
  chartData: BasicPivotItem[];
  chartDataLoaded: boolean;
  tenantConfigLoaded: boolean;
  levelSelectConfig?: GeoTrendsConfigData;
  topChartConfig?: TenantConfigViewItem;
  bottomChartConfig?: TenantConfigViewItem;
  selectedItem?: ExtendedPointObject;
  selectedGeoLevel?: TenantConfigViewItem;
};
const initialState: ReduxSlice = {
  loaded: false,
  mapUri: '',
  levelSelectConfig: undefined,
  topChartConfig: undefined,
  bottomChartConfig: undefined,
  geoData: [],
  geoDataLoaded: false,
  chartData: [],
  chartDataLoaded: false,
  tenantConfigLoaded: false,
  selectedItem: undefined,
  selectedGeoLevel: undefined,
};

export default function canvasViewReducer(state: ReduxSlice = initialState, action: Action): ReduxSlice {
  switch (action.type) {
    case REQUEST_TENANT_CONFIG: {
      return {
        ...state,
        tenantConfigLoaded: false,
      };
    }
    case RECEIVE_TENANT_CONFIG: {
      return {
        ...state,
        ...action.config,
        tenantConfigLoaded: true,
      };
    }
    case REQUEST_GEO_DATA: {
      return {
        ...state,
        loaded: false,
        geoDataLoaded: false,
      };
    }
    case RECEIVE_GEO_DATA: {
      return {
        ...state,
        loaded: true,
        geoDataLoaded: true,
        geoData: action.data,
        selectedGeoLevel: action.selectedGeoLevel,
      };
    }
    case RECEIVE_ERROR: {
      return state;
    }
    case SELECT_GEO_LEVEL: {
      return {
        ...state,
        selectedGeoLevel: action.selectedGeoLevel,
      };
    }
    case GEOTRENDS_REQUEST_CHART_DATA: {
      return {
        ...state,
        chartDataLoaded: false,
      };
    }
    case GEOTRENDS_RECEIVE_CHART_DATA: {
      return {
        ...state,
        chartData: action.data,
        chartDataLoaded: true,
      };
    }
    case SELECT_POINT: {
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    }
    case CLEAN_UP: {
      return initialState;
    }
    case RECEIVE_GEO_DATA_ERROR: {
      return {
        ...state,
        geoDataLoaded: true,
      };
    }
    default:
      return state;
  }
}
