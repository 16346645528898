import * as React from 'react';
import { Dialog, Tooltip } from '@material-ui/core';
import styles from './ButtonModal.styles';
import { getDefaultTitle } from './ButtonModal.default';
import { Link } from 'react-router-dom';
import { noop, isNil } from 'lodash';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';

export type ModalButton = {
  onClick: (click: React.MouseEvent<HTMLDivElement>) => void;
  path: null | string;
  label: string;
  disabled?: boolean;
};

type Props = {
  title?: string;
  buttons?: ModalButton[];
  tooltipString?: string;
  defn?: string;
};
type DefaultButtons = {
  defaultButtons: {
    label: string;
    path: string;
    disabled: boolean;
  }[];
};

type State = {
  open: boolean;
  default: DefaultButtons['defaultButtons'];
};

export default class ButtonModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      default: [],
    };
  }
  componentDidMount() {
    // ButtonModal need to have viewDefn value set up in confdefns
    if (isNil(this.props.defn)) {
      container.loggingService.warn('ButtonModal config missing');
      return;
    }
    container.tenantConfigClient
      .getTenantViewDefn<DefaultButtons>({
        defnId: this.props.defn,
        appName: ASSORTMENT,
      })
      .then((data) => {
        this.setState({
          default: data.defaultButtons,
        });
      });
  }
  handleCancel = () => {
    this.setState({
      open: false,
    });
  };

  handleSave = () => {
    noop();
  };

  fabClick = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { open } = this.state;
    const buttons = this.state.default;
    const title = this.props.title ? this.props.title : getDefaultTitle();
    let fabButton = (
      <button className={styles.fab} onClick={() => this.fabClick()}>
        <i className="fas fa-plus" aria-hidden="true" />
      </button>
    );
    if (this.props.tooltipString) {
      fabButton = (
        <Tooltip title={this.props.tooltipString} arrow>
          {fabButton}
        </Tooltip>
      );
    }
    return (
      <React.Fragment>
        {fabButton}
        <Dialog open={open}>
          <div className={styles.container}>
            <div className={styles.titleContainer}>
              <span className={styles.title}>{title}</span>
              <span className={styles.buttonCancel} onClick={() => this.handleCancel()}>
                X
              </span>
            </div>
            <div className={styles.body}>
              {buttons.map((button) => {
                const className = button.disabled ? styles.buttonDisabled : styles.button;
                const ret = (
                  <Link to={button.path}>
                    <div key={button.label} className={className}>
                      <span>{button.label}</span>
                    </div>
                  </Link>
                );

                return ret;
              })}
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
