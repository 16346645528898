import { createSelector } from 'reselect';
import { identity } from 'fp-ts/lib/function';
import { isEmpty, isUndefined } from 'lodash';

import Renderer from 'src/utils/Domain/Renderer';
import { AppState } from 'src/store';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { Indexable } from 'src/types/Primitive';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { filterAndSortPivotItems } from 'src/utils/Pivot/Filter';
import { getUniqueDataFromCache, HashType } from 'src/services/pivotServiceCache';
import { ParetoSummaryPivot } from 'src/components/views/ParetoAnalysis/ParetoSummary';

const TY = 'TY';
const LY = 'LY';
const VAR = 'Var';

const getParetoAnalysis = (state: AppState) => {
  return state.pages.hindsighting.paretoAnalysis;
};
const getSummaryData = (state: AppState) => {
  const viewState = getParetoAnalysis(state);
  const summaryData = getUniqueDataFromCache(viewState, HashType.paretoSummaryData)?.tree || [];
  summaryData.forEach((section) => {
    section.title = section.key;
  });

  const summarySectionData: ParetoSummaryPivot = {
    total: summaryData[0] || [],
    top: summaryData[1] || [],
    quintiles: !isEmpty(summaryData) ? summaryData.slice(2) : [],
  };
  return summarySectionData;
};
const getFlatData = (state: AppState) => {
  const viewState = getParetoAnalysis(state);
  const analysisData = getUniqueDataFromCache(viewState, HashType.paretoAnalysisData)?.flat || [];
  return analysisData;
};
const getViewDefn = (state: AppState) => {
  return getParetoAnalysis(state).viewDefns[0];
};
const getRollUpViewDefn = (state: AppState) => {
  return getParetoAnalysis(state).viewDefns[3];
};
export const getSubheader = (state: AppState) => {
  return state.subheader;
};

function defnToRenderer(defn: TenantConfigViewItem) {
  return defn.renderer ? Renderer[defn.renderer] : identity;
}

function findView(targetView: string, view: TenantConfigViewItem[]) {
  const found = view.find(
    (item: TenantConfigViewItem) => Boolean(item.xtype) && item.xtype!.toLowerCase() === targetView.toLowerCase()
  );
  return found as TenantConfigViewItem;
}

function findFrom(view: TenantConfigViewItem[]) {
  return (targetView: string) => findView(targetView, view);
}
function viewItemToProps(viewItem: TenantConfigViewItem, listData: Indexable) {
  if (!listData) {
    return [];
  }

  const secondaryMetricsView = viewItem.view as TenantConfigViewItem[];
  const findSection = findFrom(secondaryMetricsView);

  const primaryRenderer = defnToRenderer(viewItem);
  const primaryValue = listData && listData[viewItem.dataIndex];

  const thisYearMetric = findSection(TY);
  const thisYearRenderer = thisYearMetric && defnToRenderer(thisYearMetric);
  const thisYearValue = thisYearMetric && listData && listData[thisYearMetric.dataIndex];

  const lastYearMetric = findSection(LY);
  const lastYearRenderer = lastYearMetric && defnToRenderer(lastYearMetric);
  const lastYearValue = lastYearMetric && listData && listData[lastYearMetric.dataIndex];

  const varianceMetric = findSection(VAR);
  const varianceRenderer = varianceMetric && defnToRenderer(varianceMetric);
  const varianceValue = varianceMetric && listData && listData[varianceMetric.dataIndex];

  return [
    {
      renderer: primaryRenderer(primaryValue, viewItem.mask),
      label: viewItem.text,
      variance: varianceValue,
    },
    thisYearMetric && {
      renderer: thisYearRenderer(thisYearValue, thisYearMetric.mask),
      label: thisYearMetric.text,
    },
    lastYearMetric && {
      renderer: lastYearRenderer(lastYearValue, lastYearMetric.mask),
      label: lastYearMetric.text,
    },
    varianceMetric && {
      renderer: varianceRenderer(varianceValue, varianceMetric.mask),
      label: varianceMetric.text,
    },
  ];
}

function transformToProps(viewItem: TenantConfigViewItem[], quintile: Indexable) {
  return viewItem.map((view: TenantConfigViewItem) => viewItemToProps(view, quintile));
}

function transformWith(listData: Indexable) {
  return (section: TenantConfigViewItem[]) => transformToProps(section, listData);
}

export const buildRenderedSummary = createSelector(
  getSummaryData,
  getViewDefn,
  getSubheader,
  (summaryData, viewDefn, subheader) => {
    if (!summaryData || !viewDefn || !subheader) {
      return summaryData;
    }

    const findSection = findFrom(viewDefn.view);
    const topLeftView = findSection('top-left').view;
    const topRightView = findSection('top-right').view;
    const columnItems = findSection('column-items').view;
    const graphConfig = findSection('graph').view;
    const columnItemsFindSection = findFrom(columnItems as TenantConfigViewItem[]);
    const secondColumnTitle = columnItemsFindSection('second-column').text;
    const thirdColumnTitle = columnItemsFindSection('third-column').text;
    const columnNames = [secondColumnTitle, thirdColumnTitle];

    const transformViewDefn = transformWith(summaryData.total);

    const renderedSummaryData = {
      columnNames,
      topLeft: transformViewDefn(topLeftView as TenantConfigViewItem[]),
      topRight: transformViewDefn(topRightView as TenantConfigViewItem[]),
      columnItems: summaryData.quintiles.map((quintile: Indexable) =>
        transformToProps(columnItems as TenantConfigViewItem[], quintile)
      ),
      graphConfig,
    };
    return renderedSummaryData;
  }
);

export const reorderWithSortBy = createSelector(getFlatData, getSubheader, (flat, subheader) =>
  filterAndSortPivotItems(
    subheader.search,
    subheader.sortBy,
    externalGridSearchFields,
    subheader.flowStatus,
    flat as BasicPivotItem[]
  )
);

export const getAllProductsSummary = createSelector(
  getSummaryData,
  getRollUpViewDefn,
  getSubheader,
  (summaryData, collectionViewConfig, subheader) => {
    if (
      isUndefined(summaryData) ||
      isUndefined(summaryData.total) ||
      isUndefined(collectionViewConfig) ||
      isUndefined(subheader)
    ) {
      return [];
    }
    const views = collectionViewConfig.view;
    return views.map((configItem) => {
      let rendered = '';
      const raw = summaryData.total && summaryData.total[configItem.dataIndex];
      if (configItem.renderer && Renderer[configItem.renderer]) {
        rendered = Renderer[configItem.renderer](raw);
      }

      return {
        rendered,
        raw,
        label: configItem.text,
      };
    });
  }
);
