import React, { Component } from 'react';
import { isNil, isEqual, get } from 'lodash';

import { Overlay } from 'src/common-ui/index';
import { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { WorklistProps, WorklistState } from 'src/pages/AssortmentBuild/Worklist/Worklist.types';
import CompanionList from 'src/components/CompanionList/CompanionList';
import { listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import WorklistViewContainer from 'src/pages/AssortmentBuild/Worklist/WorklistViewContainer';
import { parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { classes } from 'typestyle';
import { worklistCompanionViewContainer, worklistViewContainer } from './Worklist.styles';
import { CompanionLevelByConfig } from 'src/components/CompanionList/CompanionList.types';
import { FabType } from 'src/components/higherOrder/withFab';

export default class Worklist extends Component<WorklistProps, WorklistState> {
  constructor(props: WorklistProps) {
    super(props);

    this.state = {
      selectedCompanionItem: undefined,
      useStyleColors: true,
      viewMounted: false,
      sortDirection: 'desc',
    };
  }

  componentDidMount() {
    this.props.onShowView();
    this.setState({
      viewMounted: true,
    });
  }

  getLevelField = () => {
    return this.props.selectedLevel?.dataIndex;
  };

  componentDidUpdate(prevProps: WorklistProps) {
    // We set the companion item to undefined when changing levels, as we don't know the companion styles we will receive
    // After receiving the new companion styles, reselect the first one in the list
    if (this.props.styles.length > 0 && !this.state.selectedCompanionItem && this.props.selectedLevel) {
      this.handleStyleSelectionById();
    }
  }

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  handleStyleSelection = (style: ListViewable | undefined, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const levelField = this.props.selectedLevel?.dataIndex;
    if (levelField == null && style == null) {
      return;
    } else if (style == null) {
      return;
    }

    // if the selected item is the same, and isn't from a true mouse click, ignore it
    // as the companion list can trigger reselections when the listdata call refires
    if (this.state.selectedCompanionItem && isEqual(style.id, this.state.selectedCompanionItem.id) && !event) {
      return;
    }

    this.props.selectItem(style.id);
    // if we get the same item id selected, and it's from a true click event
    // select undef, the reselect the clicked item
    // this breaks memoization of the selection without having to mangle the
    // underlying reselection protections
    if (this.state.selectedCompanionItem && isEqual(style.id, this.state.selectedCompanionItem.id) && event) {
      this.setState(
        {
          selectedCompanionItem: undefined,
        },
        () => {
          this.setState({
            selectedCompanionItem: style,
          });
        }
      );
    } else {
      // the normal codepath
      this.setState({
        selectedCompanionItem: style,
      });
    }
  };

  // If no Id is passed, it get's the first item by default
  handleStyleSelectionById = (id?: string) => {
    const { config, styles, treeStyles, selectedLevel } = this.props;
    const { useStyleColors, selectedCompanionItem, sortDirection, sortField } = this.state;
    const levelField = selectedLevel?.dataIndex;

    if (!config || (selectedCompanionItem && selectedCompanionItem.title == id)) {
      return;
    }

    // TODO: useStyleColors state never changes
    const style = useStyleColors ? styles : treeStyles;
    const mappings = !useStyleColors ? config.companionView.itemMappingsStyleColor : config.companionView.itemMappings;
    const dataLookup = parseCompanionListViewConfig((mappings as unknown) as TenantConfigViewData);
    const sort = sortField || config.companionView.sortBy.defaults.dataIndex;
    const data = companionDataParse(style, dataLookup, sortDirection, sort);
    const index = id ? data.find((datum) => datum.id === id) : data[0];
    const finalId = id || data[0].id;
    this.setState({
      companionScrollTo: {
        eventId: Date.now(),
        where: {
          key: levelField ? `${levelField}:id` : 'id',
          value: finalId,
        },
      },
      selectedCompanionItem: index,
    });
  };

  handleSortChange = (sortField: string) => {
    this.setState({ sortField });
  };

  handleSortDirectionChange = (sortDirection: 'asc' | 'desc') => {
    this.setState({ sortDirection });
  };

  handleLevelChange = (levelField: string) => {
    const levelBy = get(this.props.config, 'companionView.levelBy') as CompanionLevelByConfig;
    const selectedLevel = levelBy.view.find((i) => i.dataIndex === levelField);
    if (selectedLevel == null) return; // this is error state.
    if (selectedLevel.dataIndex !== this.props.selectedLevel?.dataIndex) {
      this.props.selectLevel(selectedLevel);
    }
    this.setState({
      selectedCompanionItem: undefined,
    });
  };

  render() {
    const {
      isLoading,
      config,
      subheaderSlice,
      subheaderViewDefns,
      styles,
      treeStyles,
      flowStatus,
      flowStatusConfig,
      showFlowStatus,
      onShowView,
      updateCompanionSearchString,
      updateStyleItem,
      updateFlowStatus,
    } = this.props;
    const { selectedCompanionItem, useStyleColors } = this.state;
    const levelField = this.props.selectedLevel?.dataIndex;

    if (isLoading || isNil(config)) {
      return <Overlay type="loading" visible={true} />;
    }
    const defaultTabIndex = config.tabs.findIndex((tab) => tab.componentType === config.defaultTab);

    if (!this.state.viewMounted) {
      return <div />;
    }

    return (
      <div className={classes(listPairStyle, worklistViewContainer)}>
        <div className="data-container">
          <div className={worklistCompanionViewContainer}>
            <CompanionList
              config={config.companionView}
              styles={styles}
              renderSearchComponent={true}
              search={subheaderSlice.altSearch}
              levelField={levelField}
              passedItemMappings={
                useStyleColors ? config.companionView.itemMappingsStyleColor : config.companionView.itemMappings
              }
              scrollTo={this.state.companionScrollTo}
              onSearchChange={updateCompanionSearchString}
              onSelectStyle={this.handleStyleSelection}
              onLevelChange={this.handleLevelChange}
              onSortChange={this.handleSortChange}
              onSortDirectionChange={this.handleSortDirectionChange}
              flowStatus={flowStatus}
              flowStatusOptions={flowStatusConfig}
              onFilterChange={updateFlowStatus}
              renderFilterComponent={showFlowStatus}
            />
          </div>
          <WorklistViewContainer
            defaultTabIndex={defaultTabIndex >= 0 ? defaultTabIndex : 0}
            tabsConfig={config.tabs}
            selectedCompanionItem={selectedCompanionItem}
            subheaderSlice={subheaderSlice}
            subheaderViewDefns={subheaderViewDefns}
            styles={useStyleColors ? styles : treeStyles}
            onViewRefresh={onShowView}
            onStyleItemUpdate={updateStyleItem}
            onSelectStyle={this.handleStyleSelectionById}
          />
        </div>
      </div>
    );
  }
}
