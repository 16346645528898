import { Action } from './Sidenav.actions';

export type ReduxSlice = {
  groupId: string | null;
  collapsed: boolean;
};

export const initialSlice: ReduxSlice = {
  groupId: null,
  collapsed: false,
};

export default function reducer(state: ReduxSlice = initialSlice, action: Action): ReduxSlice {
  if (action.type === 'SET_FOCUSED_GROUP') {
    return { ...state, groupId: action.groupId };
  }
  if (action.type === 'TOGGLE_COLLAPSE') {
    return { ...state, collapsed: action.collapse };
  }
  return state;
}
