import { CanvasViewSlice, TenantResponse } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { has } from 'lodash';
import { BasicPivotItem, ListDataOptions } from 'src/worker/pivotWorker.types';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import service from 'src/ServiceContainer';

/** Reducer shape for 'Add Existing/Similar Style/StyleColor' views. */
export type AssortmentAddSlice = Omit<CanvasViewSlice, 'data' | 'isDataLoading'> & {
  /** Items selected in current view that are to be added to the cart. */
  selectedItemsForCart: BasicPivotItem[];
  isSelectable: true;
  reload?: boolean;
  viewDataState: ViewDataState;
  level: string;
};

export const initialState: AssortmentAddSlice = {
  viewDefn: {} as TenantConfigViewData,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  tenantConfigLoading: false,
  selectedItemsForCart: [],
  calcViewDefn: {} as TenantConfigViewData,
  requiredViewDefns: [],
  isSelectable: true,
  reload: true,
  popoverTitle: '',
  identityPropsConfig: {} as IdentityPropsConfig,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
  level: '',
};

export function isAssortmentAddSlice(slice: CanvasViewSlice | AssortmentAddSlice): slice is AssortmentAddSlice {
  return has(slice, 'selectedItemsForCart');
}

const assortmentAddReducer = createSlice({
  name: 'AssortmentAdd',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.tenantConfigLoading = true;
    },
    receiveTenantConfig(state, action: PayloadAction<TenantResponse & { modelDefn: string | undefined }>) {
      state.tenantConfigLoading = false;
      state.viewDefn = action.payload.viewDefn;
      state.unmodifiedViewDefn = action.payload.unmodifiedViewDefn || ({} as TenantConfigViewData);
      state.calcViewDefn = action.payload.calcViewDefn || ({} as TenantConfigViewData);
      state.identityPropsConfig = action.payload.identityPropsConfig || ({} as IdentityPropsConfig);
      state.modelDefn = action.payload.modelDefn;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
  },
});

// As of right now this is one of two places where some actions are handled via lenses.
// AddStyles is built in a similar way.

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
} = assortmentAddReducer.actions;

export function fetchAssortmentAddData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData,
    true
  );
}

export default assortmentAddReducer.reducer;
