import * as React from 'react';
import Axios from 'src/services/axios';
import { isNil, cloneDeep, isEmpty } from 'lodash';

import { Renderer } from 'src/utils/Domain/Renderer';
import { ViewApiConfig } from '../../StyleEdit.types';
import { BasicItem } from 'src/types/Scope';
import { InputInteger, Overlay } from 'src/common-ui/index';
import { ReceiptStyles as receiptStyles, MUIStyles } from './ReceiptsAdjCalculator.styles';
import { addEventListenerForEditors } from './Editors.utils';
import ServiceContainer from 'src/ServiceContainer';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';

import { ICellEditorParams } from 'ag-grid-community';
import { IS_PUBLISHED, IS_LOCKED } from 'src/utils/Domain/Constants';
import { CardActions, MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';

export type SalesAdjustmentProps = {
  dataApi: ViewApiConfig;
  configData: SalesAdjustmentConfig;
  floorset: string;
  isEditable: boolean;
} & ICellEditorParams;

export type SalesAdjustmentState = {
  activeWeekIndex: number;
  weekData: WeekData[] | undefined;
  loaded: boolean;
  submitted: boolean;
  overlayWidth: number;
  overlayHeight: number;
  editorsModified: { [s: string]: boolean };
};

export type SalesAdjustmentConfig = {
  keys: any[];
};

export type WeekData = {
  weekId: string;
  data: BasicItem;
};

export default class SalesAdjustmentEditor extends React.Component<SalesAdjustmentProps, SalesAdjustmentState> {
  iconSaveRef: React.Ref<any> = React.createRef();
  constructor(props: SalesAdjustmentProps) {
    super(props);
    this.state = {
      activeWeekIndex: 0,
      weekData: undefined,
      loaded: false,
      submitted: false,
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
      editorsModified: {},
    };

    addEventListenerForEditors(this.props.eGridCell);

    window.addEventListener('resize', this.resizeOverlay.bind(this));
  }

  resizeOverlay() {
    this.setState({
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    Axios.get(this.props.dataApi.url, {
      params: {
        appName: 'assortment',
        productId: this.props.node.data['stylecolor'],
        timeId: this.props.floorset, // floorset
        defnId: 'SalesAdjustments',
      },
    }).then(async (resp: { data: any }) => {
      const data = resp.data && resp.data.data ? resp.data.data : resp.data;
      if (!isEmpty(data)) {
        const editorsModified = {};
        const parseddata: any = await ServiceContainer.pivotService.deserialize(data);
        const weekData: WeekData[] = parseddata.map((week: any) => {
          editorsModified[week.week] = false; // set up initial editor status for each week
          return {
            weekId: week.week,
            data: week,
          };
        });

        this.setState({
          weekData: weekData,
          loaded: true,
          editorsModified,
        });
      } else {
        this.setState({ loaded: true });
      }
    });
  }

  getValue = () => {
    window.removeEventListener('resize', this.resizeOverlay);
    const { submitted, editorsModified } = this.state;
    const { value } = this.props;
    const noEditsDetected = Object.keys(editorsModified).every((key) => editorsModified[key] === false);
    if (noEditsDetected) {
      // regardless of submission type return original value
      return value;
    }

    if (submitted && !noEditsDetected) {
      const adj = this.getTotalAdj();
      // Bizarrely ag-grid event does not send 0 or null back properly. Using this a temporary workaround.
      if (adj === 0 || adj === null) {
        return '0';
      }
      return adj;
    }

    return value; // user canceled with edits detected
  };

  isPopup = () => {
    return true;
  };

  onClickWeekTab = (weekIndex: number) => {
    this.setState({
      activeWeekIndex: weekIndex,
    });
  };

  getValueFromData = (dataIndex: string, stylecolorData: any) => {
    return stylecolorData[dataIndex];
  };

  changeValue = (value: number, dataIndex: string) => {
    const { activeWeekIndex, editorsModified } = this.state;
    const weekData = cloneDeep(this.state.weekData);
    if (weekData) {
      const valueToSet = isNil(value) ? null : value; // If undefined, make null for server
      weekData[activeWeekIndex].data[dataIndex] = valueToSet;
      const activeWeek = weekData[activeWeekIndex].weekId;
      const updatedEditors = {
        ...editorsModified,
        [activeWeek]: true,
      };

      this.setState({
        weekData: weekData,
        editorsModified: updatedEditors,
      });
    }
  };

  getTotalAdj = () => {
    const { weekData } = this.state;

    if (isNil(weekData)) {
      return 0;
    }
    if (weekData.filter((week) => !isNil(week.data['weekadjslsu'])).length === 0) {
      return null;
    }
    const totalAdj = weekData.reduce((accumulator, week) => {
      const value = week.data['weekadjslsu'] || week.data['a_slsu'] || 0;
      return accumulator + value;
    }, 0);

    return totalAdj;
  };

  bindEventListeners = (element: HTMLDivElement) => {
    if (element) {
      element.addEventListener('keydown', function(event: KeyboardEvent) {
        event.stopPropagation();
      });
    }
  };

  bindEnterKeydown = (element: HTMLDivElement) => {
    const iconSaveRef: any = this.iconSaveRef;
    if (element) {
      element.addEventListener('keydown', function(event: KeyboardEvent) {
        const enterCode = 13;
        if (event.keyCode === enterCode && iconSaveRef.current) {
          iconSaveRef.current.focus();
          event.preventDefault();
        }
      });
    }
  };

  save = () => {
    const returnData = this.state.weekData
      ? this.state.weekData.map((weekdata) => {
          return weekdata.data;
        })
      : undefined;
    this.setState(
      {
        submitted: true,
      },
      () => {
        Axios.post(this.props.dataApi.url, returnData, {
          params: {
            appName: 'assortment',
            memberId: this.props.node.data['stylecolor'],
            timeId: this.props.floorset, // floorset
            defnId: 'SalesAdjustments',
          },
        }).then((resp: { data: WeekData[] }) => {
          const weekData = resp.data;

          this.setState({
            weekData: weekData,
          });
        });
        this.props.stopEditing();
      }
    );
  };

  cancel = () => {
    this.props.stopEditing();
  };

  render() {
    const { configData } = this.props;
    const { weekData, activeWeekIndex, loaded, overlayWidth, overlayHeight } = this.state;

    if (!weekData || !weekData[0]) {
      return (
        <div className={receiptStyles.overlay} style={{ width: overlayWidth, height: overlayHeight }}>
          <div data-qa="ag-popover" className={receiptStyles.modalContainer}>
            <div className={receiptStyles.cardHeader}>Sales Adjustments</div>
            <div className={receiptStyles.tabContainer}>
              {weekData
                ? weekData.map((week, index) => {
                    const tabClass =
                      activeWeekIndex === index ? receiptStyles.tabButtonActive : receiptStyles.tabButton;
                    return (
                      <button className={tabClass} onClick={() => this.onClickWeekTab(index)} key={week.weekId}>
                        {week.weekId}
                      </button>
                    );
                  })
                : undefined}
            </div>
            <div className={receiptStyles.containerNoData} ref={this.bindEventListeners}>
              {!loaded && <Overlay type="loading" visible={true} fitParent={true} />}
              {loaded && (
                <div className={receiptStyles.receiptContainer}>
                  <div className={receiptStyles.headerSection}>
                    <p className={receiptStyles.sectionText}>No Data</p>
                  </div>
                </div>
              )}
            </div>
            <div className={receiptStyles.actionsContainer}>
              {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
              <MuiThemeProvider theme={muiTheme}>
                <CardActions classes={MUIStyles.cardActions}>
                  <RejectButton onClick={() => this.cancel()} />
                </CardActions>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={receiptStyles.overlay} style={{ width: overlayWidth, height: overlayHeight }}>
        <div data-qa="ag-popover" className={receiptStyles.modalContainer}>
          <div className={receiptStyles.cardHeader}>Sales Adjustments</div>
          <div className={receiptStyles.tabContainer}>
            {weekData
              ? weekData.map((week, index) => {
                  const tabClass = activeWeekIndex === index ? receiptStyles.tabButtonActive : receiptStyles.tabButton;
                  return (
                    <button className={tabClass} onClick={() => this.onClickWeekTab(index)} key={week.weekId}>
                      {week.weekId}
                    </button>
                  );
                })
              : undefined}
          </div>
          <div className={receiptStyles.container} ref={this.bindEventListeners}>
            <div className={receiptStyles.receiptContainer}>
              <div className={receiptStyles.headerSection}>
                <span className={receiptStyles.sectionText}>Color Details</span>
              </div>
              <div className={receiptStyles.tableSection}>
                <div className={receiptStyles.tableHeader}>
                  <div className={receiptStyles.tableRow}>
                    {configData.keys.map((key) => {
                      return (
                        <div className={receiptStyles.tableCellHeader} key={key.dataIndex}>
                          {key.text}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div className={receiptStyles.tableRow}>
                    {configData.keys.map((key) => {
                      const defaultFormatter = (a: any) => a;
                      const formatter = (key.renderer && Renderer[key.renderer]) || defaultFormatter;
                      const value = this.getValueFromData(key.dataIndex, weekData[activeWeekIndex].data);
                      let content = <div className={receiptStyles.tableCellBody}>{formatter(value)}</div>;
                      switch (key.text) {
                        case 'Sales U Override':
                          const isPublished = this.getValueFromData(IS_PUBLISHED, weekData[activeWeekIndex].data);
                          const isLocked = this.getValueFromData(IS_LOCKED, weekData[activeWeekIndex].data);
                          const isEditable = this.props.isEditable && !isPublished && !isLocked;
                          content = (
                            <div
                              className={
                                isEditable ? receiptStyles.tableCellInput : receiptStyles.tableCellInputNotEditable
                              }
                              ref={this.bindEnterKeydown}
                            >
                              <InputInteger
                                // @ts-ignore
                                onChange={(val: number) => this.changeValue(val, key.dataIndex)}
                                editable={isEditable}
                                valid={true}
                                value={value}
                                thousandSeparator={','}
                              />
                            </div>
                          );
                          break;
                        default:
                      }
                      return content;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={receiptStyles.actionsContainer}>
            {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
            <MuiThemeProvider theme={muiTheme}>
              <CardActions classes={MUIStyles.cardActions}>
                {this.props.isEditable && <AcceptButton onClick={() => this.save()} />}
                <RejectButton onClick={() => this.cancel()} />
              </CardActions>
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    );
  }
}
