import * as QueryString from 'query-string';
import Axios from 'src/services/axios';
import { AppType } from 'src/types/Domain';
import { Indexable } from 'src/types/Primitive';
import { provideAppContext, provideAppName } from 'src/utils/Domain/Perspective';
import { TOP_DOWN, TOP_DOWN_IDENTIFIER } from 'src/utils/Domain/Constants';
import { isObject, cloneDeepWith, merge, get, omit, reject, isNil } from 'lodash';
import { Option } from 'src/components/Configure/ConfigureModal';
import { DilineateOption } from 'src/components/Visualize/Visualize.utils';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/FavoritesMenu';

const C_REFS = '$configRefs';
const C_REF = '$configRef';

const modelPath = '/api/uidefn/model';
const viewPath = '/api/uidefn/config';

export enum ViewDefnState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

export function isViewDefnLoaded(viewDefnState: ViewDefnState) {
  return viewDefnState === ViewDefnState.loaded;
}

export type BasicTenantConfigViewItemComparator = {
  type: string;
  options?: {
    format?: string;
  };
};

export type BasicTenantConfigViewItem = {
  chartType?: string;
  dataIndex: string;
  id?: string;
  comparator?: BasicTenantConfigViewItemComparator;
  defaultShownValues?: string[];
  text: string;
  dimension?: string;
  groupingKey?: string;
  formula?: string;
  atGroupLevel?: boolean;
  renderer?: string;
  style?: string;
  styleColor?: string;
  geoLocationKeys?: string[];
  modelId?: string;
  xtype?: string;
  imgHeight?: number;
  mask?: string;
  includePercentage?: boolean;
  visible?: boolean;
  pinned?: string;
  highlightText?: boolean;
  searchIndexes?: string[];
  menuDisabled?: boolean;
  aggFunc?: any;
  lockable?: boolean;
  draggable?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  locked?: boolean;
  hidden?: boolean;
  isFooter?: boolean;
  isMainMetric?: boolean;
  editable?: boolean;
  suppressMovable?: boolean;
  stylePaneOpenOnClick?: boolean;
  atSummaryLevel?: boolean;
  treeColumn?: boolean;
  defaultsOverride?: Option[];
  value?: any;
  left?: BasicTenantConfigViewItem; // used for visualize graph
  right?: BasicTenantConfigViewItem; // used for visualize graph
  color?: string; // used for visualize graph
  hideFromConfigurator?: boolean; // Don't show item in configurator
};
export type TenantConfigViewItem = BasicTenantConfigViewItem & {
  collapsed?: boolean;
  columns?: TenantConfigViewItem[];
  view?: TenantConfigViewItem[];
};

export type MainViewConfig = TenantConfigViewItem & {
  info?: string;
  rowHeight?: number;
  stars?: string;
  image?: string;
  body?: string;
  title?: string;
  displayTitle?: string;
  minimumSelections?: number;
};

export type GraphsOptions = {
  primary: TenantConfigViewItem[];
  secondary: TenantConfigViewItem[];
};

export type GroupByDefnColumn = {
  groupingKey: string;
  dataIndex: string;
  text: string;
  xtype?: string;
  dimension: string;
};

export type TenantConfigViewData = {
  id?: string;
  $id: string;
  model?: string;
  main?: MainViewConfig;
  default?: string | number;
  defaults?: Indexable;
  view: TenantConfigViewItem[];
  type?: string;
  views?: TenantConfigViewItem[];
  columns?: TenantConfigViewItem[];
  options?: number[];
  geoLocationKeys?: string[];
  assortmentModel?: string;
  title?: TenantConfigViewItem;
  searchIndexes?: string[];
  defaultsOverride?: Option[];
  enabled?: boolean;
  dilineate?: DilineateOption;
  graphs?: GraphsOptions;
  isDefault?: boolean;
  grid?: TenantConfigViewData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [C_REFS]?: { [s: string]: Record<string, any> };
};

export type GeoTrendsConfigData = {
  mapUri?: string;
  bubble?: {
    dataIndex: string;
    renderer: string;
  };
} & TenantConfigViewData;

export type TenantConfigModelItem = {
  name: string;
  type: string;
};
export type TenantConfigModelData = TenantConfigModelItem[];

export const defaultViewDefnData = {
  viewDefn: {
    id: '',
    $id: '',
    view: [],
  },
};
export const defaultModelDefnData = {
  modelDefn: [],
};

export type TenantConfigQuery = {
  defnId: string;
  appName?: AppType;
};

export type TenantViewDefnQuery = {
  defnIds: (string | null)[];
  appName: AppType;
  favoritesDefn?: string;
};

export type TenantConfigClient = {
  getTenantModelDefns(_query: TenantConfigQuery): Promise<TenantConfigModelData>;
  getTenantViewDefns<T = TenantConfigViewData>(_query: TenantViewDefnQuery): Promise<T[]>;
  getTenantViewDefnsWithFavorites<T = TenantConfigViewData>(_query: TenantViewDefnQuery): Promise<T[]>;
  getTenantViewDefn<T>(_query: TenantConfigQuery): Promise<T>;
};

async function getTenantModelConfig(query: TenantConfigQuery) {
  return provideAppName((appName) => {
    let maybeChangeDefnId = query.defnId;
    if (appName === TOP_DOWN) {
      maybeChangeDefnId = TOP_DOWN_IDENTIFIER + maybeChangeDefnId;
    }
    const queryString = QueryString.stringify({
      ...query,
      defnId: maybeChangeDefnId,
      appName,
    });
    return Axios.get(`${modelPath}?${queryString}`).then((resp) => {
      return resp.data.data;
    });
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeConfigRefs = (vDefn: Record<string, any>) => {
  const refObj = get(vDefn, C_REFS);
  const viewDefn = omit(vDefn, [C_REFS]);
  // Goes through every key/value pair in an object, including arrays deeply
  return cloneDeepWith(viewDefn, (val: unknown) => {
    const objRefString = get(val, C_REF);
    const refObjMergable = get(refObj, objRefString);
    if (isObject(refObjMergable)) {
      return merge(val, refObjMergable);
    }
    return;
  });
};

async function getFavoritesList(favId: string): Promise<FavoriteResponseItem[]> {
  return provideAppContext(({ appName }) => {
    return Axios.get(`/api/favorites/by-path/${favId}?appName=${appName}`).then((resp) => {
      return resp.data.data;
    });
  });
}

async function getTenantViewConfig<T>(query: TenantConfigQuery) {
  return provideAppContext(({ appName, fingerprint }) => {
    const url: string = reject([viewPath, fingerprint, appName, query.defnId], isNil).join('/');
    return Axios.get(url).then((resp) => {
      return {
        ...mergeConfigRefs(resp.data),
        $id: query.defnId,
      };
    });
  });
}

async function getTenantViewDefns<T>(query: TenantViewDefnQuery): Promise<any[]> {
  const queries = query.defnIds.map((defnId) => {
    if (defnId == null) {
      return null;
    }
    return getTenantViewConfig<T>({
      defnId: defnId,
      appName: query.appName,
    });
  });

  return Promise.all(queries);
}

async function getTenantViewDefnsWithFavorites<T>(query: TenantViewDefnQuery, favoriteDefn: string): Promise<any[]> {
  const queries = query.defnIds.map((defnId) => {
    if (defnId == null) {
      return null;
    }
    return getTenantViewConfig({
      defnId: defnId,
      appName: query.appName,
    });
  });

  queries.push(getFavoritesList(favoriteDefn) as Promise<any>);
  return Promise.all(queries);
}

async function getTenantViewDefn<T>(query: TenantConfigQuery): Promise<T> {
  return getTenantViewConfig({
    defnId: query.defnId,
    appName: query.appName,
  });
}

export function makeTenantConfigClient(): TenantConfigClient {
  return {
    getTenantModelDefns(query: TenantConfigQuery) {
      return getTenantModelConfig(query);
    },
    getTenantViewDefns(query: TenantViewDefnQuery) {
      return getTenantViewDefns(query);
    },
    getTenantViewDefnsWithFavorites(query: TenantViewDefnQuery) {
      return getTenantViewDefnsWithFavorites(query, query.favoritesDefn || query.defnIds[0]!);
    },
    getTenantViewDefn(query: TenantConfigQuery) {
      return getTenantViewDefn(query);
    },
  };
}
