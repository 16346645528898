import * as React from 'react';
import cuid from 'cuid';
import { isNil } from 'lodash/fp';
import { HORPADDING, BORDERCLASS, BORDERWIDTH, default as styles } from '../StandardCard/StandardCard.styles';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { classes, style } from 'typestyle';
import { default as topCardStyles, IMGWIDTH, TOPHEIGHT, COLWIDTH } from './TopCard.styles';
import Renderer from 'src/utils/Domain/Renderer';
import { CardViewCardColumn } from '../UIData.types';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';

export type Props = {
  isBlankCard?: boolean;
  popoverTitle?: string;
  imgSrc: string;
  stars: number;
  id: string;
  name: string;
  styleName: string;
  styleId: string;
  description: string;
  styleDescription: string;
  value: number | string;
  columns?: CardViewCardColumn[];
  departmentId?: string;
  swatchIds?: string[];
  onItemClick?: (item: ContainerPayload) => void;
  onMoreInfoClick?: (item: ContainerPayload) => void;
};
type State = {
  popoverOpen: boolean;
  loadingImages: boolean;
  targetId: string;
};

export const calcCardWidth = (_colItemCount?: number, _includePadding?: boolean) => {
  return IMGWIDTH + 10 + COLWIDTH + HORPADDING;
};

export class TopCard extends React.Component<Props, State> {
  inCleanup: boolean;
  static calcCardWidth = (_colItemCount?: number, _includePadding?: boolean) => {
    return IMGWIDTH + 10 + COLWIDTH + HORPADDING;
  };
  static calcCardHeight = () => {
    return TOPHEIGHT + BORDERWIDTH * 2;
  };
  constructor(props: Props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.render = this.render.bind(this);
    this.inCleanup = false;
    this.state = {
      popoverOpen: false,
      loadingImages: false,
      targetId: cuid(),
    };
  }

  onItemClick(event: React.ChangeEvent<HTMLSelectElement>) {
    if (!isNil(this.props.onItemClick)) {
      this.props.onItemClick({
        type: 'HistoryStylePane',
        id: this.props.id,
        parentId: this.props.styleId,
      });
    }
  }

  handleClick() {
    if (this.props.onItemClick) {
      this.props.onItemClick({
        type: 'HistoryStylePane',
        id: this.props.id,
        parentId: this.props.styleId,
      });
    }
  }

  onMoreInfoClick = (item: ContainerPayload) => {
    if (this.props.onMoreInfoClick) {
      this.props.onMoreInfoClick(item);
    }
  };

  componentWillUnmount() {
    this.inCleanup = true;
  }

  render() {
    const props = this.props;
    const { imgSrc, description, id } = props;
    const columns = props.columns
      ? props.columns.map((column, index) => {
          const rendererFn = Renderer[column.renderer];
          const renderedValue = rendererFn ? rendererFn(column.value) : column.value;
          return (
            <div
              key={index}
              className={classes(styles.colItem, topCardStyles.colItem)}
              data-qa={column.title.toLocaleLowerCase()}
            >
              <div className={BORDERCLASS} />
              <span className="columnTitle">{column.title}</span>
              <span className="columnValue">{renderedValue}</span>
            </div>
          );
        })
      : [];
    const cardDisplay = (
      <div id={this.state.targetId} data-qa-action="CardClick" className={classes(styles.infoContainer)}>
        <CenteredImage
          src={imgSrc}
          width={IMGWIDTH}
          height={TOPHEIGHT}
          extraImgProps={{ isBlankCard: props.isBlankCard }}
          extraContainerClass={topCardStyles.image}
        />
        <article className={style({ display: 'block' })}>
          <header className={classes(styles.description, topCardStyles.header)}>
            <h5 className={topCardStyles.headerText}>{`${id} ${description}`}</h5>
            <hr className={topCardStyles.headerRuler} />
          </header>
          {columns.length > 0 ? (
            <section data-qa-component="CardColumns" className={classes(styles.column, topCardStyles.column)}>
              {columns}
            </section>
          ) : null}
        </article>
      </div>
    );
    return (
      <div
        className={`${styles.card} ${topCardStyles.cardContainer}`}
        data-qa-component="TopCard"
        data-qa-action="OpenPopover"
        onClick={this.handleClick}
        data-qa-key={id}
      >
        {cardDisplay}
      </div>
    );
  }
}
