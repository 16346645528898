import { SET_PRINT_MODE, Action, SET_IS_VIEW_PRINTABLE } from 'src/components/higherOrder/Print/Print.actions';

export type PrintSlice = {
  isViewPrintable: boolean;
  isPrintMode: boolean;
  width: string;
  height: string;
};

export const initialSlice: PrintSlice = {
  isViewPrintable: true, // right now more views are printable than not, so default to true
  isPrintMode: false,
  width: '11in',
  height: '8.5in',
};

export default function reducer(state: PrintSlice = initialSlice, action: Action): PrintSlice {
  switch (action.type) {
    case SET_PRINT_MODE:
    case SET_IS_VIEW_PRINTABLE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
