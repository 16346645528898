import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ParameterTogglesConfig } from 'src/pages/Allocation/ParameterToggles/ParameterToggles.types';

export type ParameterTogglesSlice = {
  isConfigLoading: boolean;
  isDataLoading: boolean;
  viewDefn: ParameterTogglesConfig | undefined;
};

const initialSlice: ParameterTogglesSlice = {
  isConfigLoading: false,
  isDataLoading: false,
  viewDefn: undefined,
};

const parameterTogglesSliceReducer = createSlice({
  name: 'ParameterToggles',
  initialState: initialSlice,
  reducers: {
    requestParameterTogglesConfig(state) {
      state.isConfigLoading = true;
    },
    receiveParameterTogglesConfig(state, action: PayloadAction<ParameterTogglesConfig>) {
      state.isConfigLoading = false;
      state.viewDefn = action.payload;
    },
    cleanupParameterToggles() {
      return initialSlice;
    },
  },
});

export const {
  requestParameterTogglesConfig,
  receiveParameterTogglesConfig,
  cleanupParameterToggles,
} = parameterTogglesSliceReducer.actions;
export default parameterTogglesSliceReducer.reducer;
