import { combineReducers } from 'redux';
import {
  default as flowSheetReducer,
  FlowSheetSlice,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/FlowSheetByStyle.slice';
import {
  default as cartReducer,
  AssortmentCartSlice,
} from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentCart/AssortmentCart.slice';
import { default as pricingReducer, PricingSlice } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';
import {
  default as assortmentPublishReducer,
  AssortmentPublishSlice,
} from './AssortmentPublish/AssortmentPublish.slice';
import worklistReducer, { WorklistSlice } from './Worklist/Worklist.slice';
import visualizeReducer, { VisualizeSlice } from 'src/components/Visualize/Visualize.slice';
import styleEditReducer, { StyleEditSlice } from './StyleEdit/StyleEdit.slice';
import { default as assortmentAddReducer, AssortmentAddSlice } from './AssortmentAdd/AssortmentAdd.slice';
import configurableGridReducer, { ConfigurableGridSlice } from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { default as floorsetComparisonReducer, FloorsetCmpSlice } from './FloorsetComparison/FloorsetComparison.slice';
import enhancedOvertimeReducer, { EnhancedOvertimeSlice } from 'src/components/EnhancedOvertime/EnhancedOvertime.slice';

export type AssortmentBuildSlice = {
  flowSheet: FlowSheetSlice;
  pricing: PricingSlice;
  cart: AssortmentCartSlice;
  addStyles: AssortmentAddSlice;
  styleEdit: StyleEditSlice;
  floorsetComparison: FloorsetCmpSlice;
  assortmentPublish: AssortmentPublishSlice;
  worklist: WorklistSlice;
  visualize: VisualizeSlice;
  enhancedOvertime: EnhancedOvertimeSlice;
  configurableGrid: ConfigurableGridSlice;
};

export default combineReducers({
  flowSheet: flowSheetReducer,
  pricing: pricingReducer,
  cart: cartReducer,
  addStyles: assortmentAddReducer,
  styleEdit: styleEditReducer,
  floorsetComparison: floorsetComparisonReducer,
  assortmentPublish: assortmentPublishReducer,
  worklist: worklistReducer,
  visualize: visualizeReducer,
  enhancedOvertime: enhancedOvertimeReducer,
  configurableGrid: configurableGridReducer,
});
