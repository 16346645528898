import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CollectionViewSlice } from 'src/components/views/CollectionView/CollectionView.types';
import { defaultViewDefnData, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { cacheCheckFetchPivotData } from '../StyleColorReview.slice';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';

type TenantResponse = {
  viewDefn: TenantConfigViewData;
  identityProps: {
    idProp: string;
  };
};

const initialState: CollectionViewSlice = {
  ...defaultViewDefnData,
  isConfigLoading: false,
  searchProps: externalGridSearchFields,
  showFlowStatus: true,
  viewDataState: ViewDataState.idle,
  identityProps: {
    idProp: '',
  },
  cacheHash: null,
};

const collectionViewReducer = createSlice({
  name: 'CollectionView',
  initialState: initialState,
  reducers: {
    requestCollectionViewTenantConfig(state) {
      state.isConfigLoading = true;
    },
    receiveCollectionViewTenantConfig(state, action: PayloadAction<TenantResponse>) {
      state.isConfigLoading = false;
      state.viewDefn = action.payload.viewDefn;
      state.identityProps = action.payload.identityProps;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    receiveCollectionViewError() {
      return initialState;
    },
    cleanCollectionViewUp() {
      return initialState;
    },
  },
});

export const {
  requestCollectionViewTenantConfig,
  receiveCollectionViewTenantConfig,
  requestData,
  receiveCachedData,
  receiveCacheHash,
  receiveLiveData,
  receiveCollectionViewError,
  cleanCollectionViewUp,
} = collectionViewReducer.actions;

export function fetchCollectionViewData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData,
    true
  );
}

export default collectionViewReducer.reducer;
