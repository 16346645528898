import { style, classes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

export let styles: { [key: string]: string } = {
  tableHeader: style({
    width: '100%',
  }),
  container: style({
    background: 'white',
    padding: 20,
    width: 720,
  }),
  receiptContainer: style({
    padding: 10,
  }),
  tabContainer: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: 50,
  }),
  tabButton: style({
    textAlign: 'center',
    height: '100%',
    flexGrow: 1,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 0,
    color: 'grey',
    fontWeight: 600,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    backgroundColor: 'white',
    $nest: {
      '&:focus': {
        outline: 0,
      },
    },
  }),
  checkboxContainer: style({
    height: 56,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    padding: 8,
  }),
  iconButton: style({
    float: 'right',
    width: 35,
    height: 35,
    minWidth: 0,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#f9f9f9',
  }),
  sectionText: style({
    fontSize: '1.05rem',
    fontWeight: 600,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
  }),
  tableRow: style({
    $debugName: 'tableRow',
    height: 44,
    display: 'table-row',
  }),
  tableCell: style({
    $debugName: 'tableCell',
    width: 150,
    textAlign: 'center',
    fontSize: '0.9rem',
    padding: 0,
    display: 'table-cell',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'middle',
  }),
  headerSection: style({
    $debugName: 'headerSection',
    paddingBottom: 10,
  }),
  tableSection: style({
    $debugName: 'tableSection',
    width: '90%',
    marginLeft: '5%',
    display: 'table',
  }),
};

styles = {
  ...styles,
  tableCellBody: classes(
    styles.tableCell,
    style({
      fontWeight: 400,
      background: 'rgba(224, 224, 224, .3)',
    })
  ),
  tableCellTotal: classes(
    styles.tableCell,
    style({
      fontWeight: 600,
    })
  ),
  tableCellHeader: classes(
    styles.tableCell,
    style({
      color: 'black',
      fontSize: '1rem',
      fontWeight: 600,
    })
  ),
  tableCellInput: classes(
    styles.tableCell,
    style({
      $debugName: 'inputCenter',
      background: 'rgb(220, 243, 241)',
      $nest: {
        '& *': {
          textAlign: 'center',
          verticalAlign: 'middle',
        },
      },
    })
  ),
  tabButtonActive: classes(
    styles.tabButton,
    style({
      borderBottom: 'none',
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      color: TEAL_PRIMARY,
    })
  ),
  iconButtonSave: classes(
    styles.iconButton,
    style({
      background: '#4caf50',
    })
  ),
  iconButtonCancel: classes(
    styles.iconButton,
    style({
      background: '#f44336',
      marginLeft: 5,
    })
  ),
};
styles = {
  ...styles,
  tableCellBodyFirst: classes(
    styles.tableCellBody,
    style({
      borderBottom: 0,
      textAlign: 'right',
      paddingRight: 10,
    })
  ),
  tableCellTotalFirst: classes(
    styles.tableCellTotal,
    style({
      borderBottom: 0,
      textAlign: 'right',
      paddingRight: 10,
    })
  ),
  tableCellHeaderFirst: classes(
    styles.tableCellHeader,
    style({
      borderBottom: 0,
      color: 'white',
    })
  ),
};

export const MUIStyles = {
  card: style({
    maxWidth: 700,
  }),
  cardContent: {
    root: style({
      minHeight: 305,
      fontSize: '13px',
    }),
  },
  cardHeader: {
    root: style({
      backgroundColor: '#F0F0F0',
      height: '50px',
    }),

    content: style({
      $nest: {
        '& span': {
          fontSize: '15px',
        },
      },
    }),
  },
  cardActions: {
    root: style({
      justifyContent: 'flex-end',
      $nest: {
        '& button:first-child': {
          marginRight: '0.5rem',
        },
      },
    }),
  },
};
