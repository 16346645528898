import { style } from 'typestyle';
import { negativeTrend, positiveTrend, neutralTrend, fadedNeutral, primaryTextColor } from '../../../../theme';

export const choiceProductivityStyle = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 300,
  color: primaryTextColor,
  $nest: {
    '.left-container': {
      color: fadedNeutral,
      marginRight: 20,
      fontSize: '0.8rem',
    },
    '.middle-container': {
      width: 65,
      height: 65,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.9rem',
      borderRadius: '50%',
      $nest: {
        '&.positive': {
          border: '3px solid ' + positiveTrend,
        },
        '&.negative': {
          border: '3px solid ' + negativeTrend,
        },
        '&.zero': {
          border: '3px solid ' + neutralTrend,
        },
      },
    },
    '.right-container': {
      marginLeft: 15,
      width: 80,
      whiteSpace: 'nowrap',
    },
    '.primary': {
      flex: 1,
      fontSize: '1.5rem',
      paddingLeft: 20,
    },
    '.dir': {
      lineHeight: '2rem',
      fontSize: '1.2rem',
      margin: '0 0.3rem 0 0',
    },
    '.up': {
      color: positiveTrend,
    },
    '.down': {
      color: negativeTrend,
    },
    '.neutral': {
      color: neutralTrend,
    },
    '.diff-container': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '.diff': {
      fontSize: '1.2rem',
    },
    '.secondary': {
      fontSize: '0.6rem',
      textAlign: 'end',
      color: fadedNeutral,
      fontWeight: 500,
    },
  },
});
