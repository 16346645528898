import React from 'react';
import { connect } from 'react-redux';
import { isNil, flow } from 'lodash';

import { Overlay } from 'src/common-ui/index';

import { listPairStyle } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import SubheaderContainer from 'src/components/Subheader/Subheader.container';
import { OvertimeView } from './OvertimeView';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import ServiceContainer from 'src/ServiceContainer';
import { ASSORTMENT, ASSORTMENT_BUILD_FILTER_ALL_WARNING } from 'src/utils/Domain/Constants';
import ConfigureModal, { ConfigureModalProps, Option, OptionGroup } from 'src/components/Configure/ConfigureModal';
import { ConfigureOvertimeConfig } from './OvertimeView.types';
import container from 'src/ServiceContainer';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { AppState } from 'src/store';
import { getHeaderTextFromState } from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { NestedOvertimeComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';

type NestedOvertimeOwnProps = z.infer<typeof NestedOvertimeComponentProps>;

export type NestedOvertimeContainerProps = NestedOvertimeOwnProps & {
  customHeader: string;
};

type AllStates = {
  aggBys: string[];
  loaded: boolean;
  data?: BasicPivotItem[];
  viewConfig?: ConfigureOvertimeConfig;
  configureIsOpen: boolean;
  configureSelections?: Option[];
  uncommitedConfigureSelections?: Option[];
  configureOptions?: OptionGroup[];
  defaultConfigureSelections?: Option[];
  search?: string;
  rowHeight?: number;
};
type State = AllStates;

class NestedOvertimeContainer extends React.Component<NestedOvertimeContainerProps, State> {
  constructor(props: NestedOvertimeContainerProps) {
    super(props);
    this.state = {
      aggBys: ['class', 'subclass'],
      loaded: false,
      configureIsOpen: false,
    };
  }

  componentDidMount() {
    this.loadConfig().then(() => {
      this.loadData();
    });
  }

  onRefetchData() {
    this.setState({
      loaded: false,
    });
    this.loadData();
  }

  loadConfig = async () => {
    const client = container.tenantConfigClient;
    const configResponse = await client.getTenantViewDefn<ConfigureOvertimeConfig>({
      defnId: this.props.defns.view[0],
      appName: 'Assortment',
    });
    this.setState({
      defaultConfigureSelections: configResponse.configure.defaults.map((key, ind) => {
        const options = configResponse.configure.view[ind].options;
        return options.find((i) => i.dataIndex === key) || options[0];
      }),
      configureOptions: configResponse.configure.view,
      configureSelections: configResponse.configure.defaults.map((key, ind) => {
        const options = configResponse.configure.view[ind].options;
        return options.find((i) => i.dataIndex === key) || options[0];
      }),
      viewConfig: configResponse,
      rowHeight: configResponse.main && configResponse.main.rowHeight ? configResponse.main.rowHeight : 30,
    });
  };

  loadData = async () => {
    const { configureSelections, viewConfig } = this.state;
    if (isNil(configureSelections) || isNil(viewConfig)) {
      this.setState({
        ...this.state,
        loaded: true,
        data: [],
      });
      return;
    }
    const data = (
      await ServiceContainer.pivotService.listData(this.props.dataApi.params.defnId, ASSORTMENT, {
        ...this.props.dataApi.params,
        aggBy: [this.props.dataApi.params.aggBy]
          .concat(configureSelections.map((i) => `${i.type}:${i.dataIndex}`))
          .join(','),
      })
    ).tree;

    this.setState({
      ...this.state,
      loaded: true,
      data,
    });
  };

  renderConfigureModal = () => {
    const configureModalProps: ConfigureModalProps = {
      enabled: true,
      isOpen: this.state.configureIsOpen,
      optionGroups: this.state.configureOptions || [],
      selections: this.state.uncommitedConfigureSelections || [],
      instructions: 'Select Aggregation Levels',
      onReset: () => {
        this.setState({
          uncommitedConfigureSelections: this.state.defaultConfigureSelections,
        });
      },
      onToggleModal: (action) => {
        const nextState: Partial<State> = {
          configureIsOpen: !this.state.configureIsOpen,
        };
        switch (action) {
          case 'apply': {
            this.setState(
              {
                configureIsOpen: false,
                configureSelections: this.state.uncommitedConfigureSelections,
                loaded: false,
              },
              this.loadData
            );
            break;
          }
          default: {
            this.setState({
              configureIsOpen: false,
              uncommitedConfigureSelections: this.state.configureSelections,
            });
          }
        }
        // TODO: There has to be a better way... :/
        this.setState(nextState as any);
      },
      selectionUpdate: (selections: Option[]) => {
        this.setState({
          uncommitedConfigureSelections: selections,
        });
      },
    };
    return <ConfigureModal {...configureModalProps} />;
  };

  render() {
    const { title = 'Nested Over Time' } = this.props;
    return (
      <div className={listPairStyle}>
        <SubheaderContainer
          title={title}
          showSearch={true}
          showFlowStatus={false}
          errorCondition={ASSORTMENT_BUILD_FILTER_ALL_WARNING}
          configureOptions={{
            type: 'enabled',
            onConfigureClick: () => {
              this.setState({
                configureIsOpen: true,
                uncommitedConfigureSelections: this.state.configureSelections,
              });
            },
          }}
          searchReturn={(newVal: string) => {
            this.setState({
              search: newVal,
            });
          }}
        />
        <div className={'data-container'}>
          <React.Fragment>
            {!isNil(this.state.data) && !isNil(this.state.viewConfig) ? (
              <OvertimeView
                viewConfig={this.state.viewConfig}
                rowHeight={this.state.rowHeight}
                aggBys={(this.state.configureSelections || []).map((sel) => sel.dataIndex)}
                treeData={this.state.data || []}
                search={this.state.search}
                exportOptions={{
                  fileName: title,
                  customHeader: this.props.customHeader,
                }}
              />
            ) : (
              <div />
            )}
          </React.Fragment>
          {this.renderConfigureModal()}
        </div>
        <Overlay visible={!this.state.loaded} type={'loading'} qaKey={'overtime-loading'} />
      </div>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: NestedOvertimeOwnProps): NestedOvertimeContainerProps {
  const customHeader = getHeaderTextFromState(state, true);
  return {
    ...ownProps,
    customHeader,
  };
}

const sensitiveView = flow(() => NestedOvertimeContainer, makeScopeAndFilterSensitive)();

export default connect(mapStateToProps)(sensitiveView);
