import React from 'react';
import { Link } from 'react-router-dom';
import { RouteLink } from 'src/types/RouteLink';
import { TopNavLink } from './TopNavLink';
import styles from './TopNavbar.styles';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import TopNavIconLink from './TopNavIconLink';
import TopNavSearch from './TopNavSearch';
import Popover from 'reactstrap/lib/Popover';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import { resolvePath } from 'src/cdn';
import { makeResetTheWorld } from 'src/services/configuration/AppConfig.actions';

import brandLogoPath from 'src/images/S5Logo-White-NoInnovation-Centered.png';
import { API_URL_PREFIX } from 'src/utils/Domain/Constants';
import { isEmpty, isNil } from 'lodash';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { FastNav, BlankFastNav } from 'src/components/FastNav/FastNav';
import { BoundPerspective } from 'src/services/configuration/bindings.types';
import { Bindings } from 'src/pages/NavigationShell/NavigationShell';
import { Backdrop } from '@material-ui/core';

const BrandLogo = resolvePath(brandLogoPath);

export type TopNavbarProps = {
  routeLinks: RouteLink[];
  viewLinks: RouteLink[];
  bindings: Bindings;
  perspective: BoundPerspective;
  location: unknown;
};
export interface TopNavbarState {
  dropdownOpen: boolean;
  popoverOpen: boolean;
  fastNavOpen: boolean;
  backdropOpen: boolean;
  activeFastNavTab: string;
}

export class TopNavbar extends React.Component<TopNavbarProps, TopNavbarState> {
  fastNavAnchor: HTMLElement | null = null;
  searchContainer: HTMLElement | null = null;

  constructor(props: TopNavbarProps) {
    super(props);
    this.state = {
      dropdownOpen: false,
      popoverOpen: false,
      fastNavOpen: false,
      backdropOpen: false,
      activeFastNavTab: '',
    };

    this.handleTouchDevices();
    this.handleLogout = this.handleLogout.bind(this);
    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
    this.handleTogglePopover = this.handleTogglePopover.bind(this);
  }

  componentDidUpdate(prevProps: TopNavbarProps) {
    if (prevProps.location !== this.props.location) {
      this.resetFastNavToggle();
    }
  }

  handleLogout() {
    window.location.href = `${API_URL_PREFIX}/logout`;
  }

  handleToggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  handleTogglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  // TODO: Move this possibly
  handleTouchDevices() {
    window.addEventListener('touchstart', function onFirstTouch() {
      document.cookie = 'TOUCH=TRUE';
      window.removeEventListener('touchstart', onFirstTouch, false);
    });
  }

  // TODO: Move this to the proper location.
  cheatTheSystem() {
    window.__debug.store.dispatch(makeResetTheWorld());
  }

  /**
   * Toggles the state for the desired TopNavLink and FastNav
   *
   * @param tabName determines the current tab to take action against, an empty value will reset the fast nav state.
   */
  onFastNavToggle = (tabName: string) => {
    if (tabName === this.state.activeFastNavTab || tabName === '') {
      this.setState({
        fastNavOpen: false,
        activeFastNavTab: '',
      });
    } else {
      this.setState({
        fastNavOpen: true,
        activeFastNavTab: tabName,
      });
    }
  };

  resetFastNavToggle = () => {
    this.onFastNavToggle('');
  };

  onEnterSearch = () => {
    this.setState({
      fastNavOpen: false,
      backdropOpen: true,
    });
  };

  render() {
    const { dropdownOpen, popoverOpen, fastNavOpen, activeFastNavTab, backdropOpen } = this.state;
    const { routeLinks, viewLinks, bindings, perspective } = this.props;
    const links = routeLinks.map(({ name, link, highlightPrefix, disabled }) => {
      return (
        <TopNavLink
          key={name}
          name={name}
          link={link}
          highlightPrefix={highlightPrefix}
          disabled={disabled}
          data-qa="TopNavRouteLink"
          fastNavOpen={fastNavOpen && activeFastNavTab === name}
          onFastNavToggle={this.onFastNavToggle}
        />
      );
    });

    const tab = bindings[perspective.id].boundTabs.find(
      (tab) => !isEmpty(tab.boundSections) && tab.name === activeFastNavTab
    );

    // TODO: Reactivate S5 Apps in Nav when ready
    return (
      <ClickAwayListener onClickAway={this.resetFastNavToggle}>
        <nav className={`bg-dark ${styles.mainNavBar}`} data-qa="TopNav">
          <Backdrop open={fastNavOpen || backdropOpen} style={{ zIndex: 102 }} onClick={this.resetFastNavToggle} />
          <span className="logo-container">
            <Link to="/" onClick={() => this.cheatTheSystem()}>
              <img className="logo" src={BrandLogo} alt="" data-qa="TopNavHomeIcon" />
            </Link>
          </span>
          <span className="nav-links" ref={(elem) => (this.fastNavAnchor = elem)}>
            {links}
          </span>
          <span className="nav-controls" data-qa="TopNavRightSide">
            <span className="search-container" ref={(elem) => (this.searchContainer = elem)}>
              <TopNavSearch
                routeLinks={routeLinks.concat(viewLinks)}
                onEnter={this.onEnterSearch}
                onLeave={() => this.setState({ backdropOpen: false })}
              />
            </span>
            <span className="buttons">
              <TopNavIconLink
                iconClass="fas fa-question-circle"
                href="http://s5stratoshelp.helpdocsonline.com/home/"
                target="_blank"
                data-qa="TopNavHelp"
              />
              <div style={{ display: 'none' }}>
                <TopNavIconLink
                  id="topNavApps"
                  iconClass="fas fa-th"
                  href="javascript:void(null)"
                  onClick={this.handleTogglePopover}
                  data-qa="TopNavApps"
                />
              </div>
              <Popover placement="bottom" isOpen={popoverOpen} target="topNavApps" toggle={this.handleTogglePopover}>
                <PopoverHeader>S5 Apps</PopoverHeader>
                <PopoverBody>
                  This is the app selection widget, you currently don&apos;t have any other apps.
                </PopoverBody>
              </Popover>
              <Dropdown isOpen={dropdownOpen} toggle={this.handleToggleDropdown} data-qa="TopNavUserToggle">
                <DropdownToggle
                  tag="i"
                  data-toggle="dropdown"
                  aria-expanded={false}
                  className="fas fa-user user"
                  data-qa="TopNavUser"
                />
                <DropdownMenu right={true}>
                  <DropdownItem onClick={this.handleLogout} data-qa="TopNavLogout" className="logout">
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </span>
          </span>
          {fastNavOpen && (
            <Popper
              style={{ zIndex: 105 }}
              open={fastNavOpen}
              anchorEl={this.fastNavAnchor}
              placement={'bottom-start'}
              transition={true}
            >
              <Paper
                style={{
                  // style taken directly from popover styles (.MuiPaper-elevation8)
                  boxShadow:
                    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                }}
                classes={{ root: styles.getFastNavPopperStyles(this.fastNavAnchor, this.searchContainer) }}
              >
                {!isNil(tab) ? (
                  <FastNav
                    key={`${tab.name}`}
                    perspective={perspective}
                    tab={tab}
                    sections={tab.boundSections}
                    onItemClick={this.resetFastNavToggle}
                  />
                ) : (
                  <BlankFastNav />
                )}
              </Paper>
            </Popper>
          )}
        </nav>
      </ClickAwayListener>
    );
  }
}
