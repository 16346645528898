import React from 'react';
import { HasPerspectives, HasBindings } from 'src/services/configuration/service';
import { Link, Redirect } from 'react-router-dom';
import styles from 'src/components/TopNavbar/TopNavbar.styles';
import { BoundPerspective } from 'src/services/configuration/bindings.types';
import { computePerspectiveRoute } from '../NavigationShell/navigationUtils';
import { resolvePath } from 'src/cdn';
import {
  containerStyle,
  disabledStyle,
  brandLogoCentered,
} from 'src/pages/PerspectiveSelection/PerspectiveSelection.styles';
import { isNil } from 'lodash';
import RestoreSession from 'src/components/RestoreSession/RestoreSession.container';
import {
  getSessionPage,
  getSessionPerspective,
  removeSessionAll,
} from 'src/components/RestoreSession/RestoreSession.utils';

import blackTextLogo from 'src/images/S5Logo-Black-Inovation-Centered.png';
import brandLogoPath from 'src/images/S5Logo-White-NoInnovation-Centered.png';
const BrandLogo = resolvePath(brandLogoPath);

function linkPerspective(config: HasBindings, p: BoundPerspective, index: number) {
  const ui = config.getBindings(p);
  return (
    <Link onClick={removeSessionAll} key={index} data-id={p.id} to={computePerspectiveRoute(ui)}>
      <div>
        <span className="fa-stack fa-3x">
          <i className={p.iconSrc} />
        </span>
      </div>
      <label>{p.display}</label>
    </Link>
  );
}

// eslint-disable-next-line react/display-name
export default (config: HasPerspectives & HasBindings) => () => {
  const sessionPage = getSessionPage();
  const sessionPerspective = getSessionPerspective();
  const hasLocalStorage = !isNil(sessionPage) && !isNil(sessionPerspective);
  const perspectives = config.getPerspectives();
  if (perspectives.length === 1) {
    const ui = config.getBindings(perspectives[0]);
    return <Redirect to={computePerspectiveRoute(ui)} />;
  }
  const links = perspectives.map((p, index) => linkPerspective(config, p, index));
  return (
    <div style={{ textAlign: 'center' }}>
      <nav className={`bg-dark ${styles.mainNavBar}`} data-qa="TopNav">
        <span className="logo-container">
          <Link to="/">
            <img className="logo" src={BrandLogo} alt="" data-qa="TopNavHomeIcon" />
          </Link>
        </span>
      </nav>
      <img className={brandLogoCentered} src={blackTextLogo} alt="" />
      <div className={containerStyle}>
        {links}
        <div className={disabledStyle}>
          <div>
            <span className="fa-stack fa-3x disabled">
              <i className={'fal fa-stack-1x fa-dollar-sign'} />
            </span>
          </div>
          <label>Financial Planning</label>
        </div>
        <div className={disabledStyle}>
          <div>
            <span className="fa-stack fa-3x disabled">
              <i className={'fal fa-stack-1x fa-truck'} />
            </span>
          </div>
          <label>Allocation Planning</label>
        </div>
      </div>
      {hasLocalStorage && <RestoreSession />}
    </div>
  );
};
