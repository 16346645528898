import { ASSORTMENT, TOP_DOWN } from 'src/utils/Domain/Constants';
import { z } from 'zod';
import {
  ConfigApi,
  DataApiConfigZod,
  DataApiZod,
  ListDataApiZod,
  GroupingInfo,
  HasCart,
  HasExceptionsMultiSelect,
  HasFab,
  HasTitle,
  HasPopover,
  HasRangeSelector,
  HasSubheaderDownloadLink,
  HasTopMembers,
  HasWorklist,
  IdentifierProps,
  OptionalIdentifierProps,
  HasHideableCompanion,
  HasFlowStatus,
  HasSubheaderErrorText,
  HasConfigure,
  HasFullHeight,
  HasSummaries,
} from './confdefnView';
import { CartItemType } from './literals';

export const SubheaderDefnProps = z.object({
  groupBy: z.string().optional(),
  sortBy: z.string().optional(),
  rollup: z.string().optional(),
  pareDown: z.string().optional(),
  countLimit: z.string().optional(),
});

const DefnProps = z.object({
  model: z.string(),
  dataApi: DataApiZod.optional(),
  assortmentModel: z.string().optional(),
  subheader: SubheaderDefnProps,
  view: z.array(z.string()),
  fab: z.string().optional(),
});
export type DefnProps = z.infer<typeof DefnProps>;

const MultiDefnProps = z.object({
  models: z.array(z.string()),
  assortmentModel: z.string().optional(),
  subheader: SubheaderDefnProps,
  view: z.array(z.string()),
});
export type MultiDefnProps = z.infer<typeof MultiDefnProps>;

export const BaseDefnsComponentProps = z.object({ defns: DefnProps }).merge(HasTitle);
export const BaseMultiDefnsComponentProps = z.object({ defns: MultiDefnProps }).merge(HasTitle);

export const CollectionViewComponentProps = BaseDefnsComponentProps.merge(IdentifierProps)
  .merge(HasCart)
  .merge(HasPopover)
  .merge(HasFab)
  .merge(HasSubheaderDownloadLink)
  .merge(HasTopMembers);

export const CanvasViewComponentProps = BaseDefnsComponentProps.merge(OptionalIdentifierProps)
  .merge(HasCart)
  .merge(HasPopover)
  .merge(HasFab)
  .merge(HasSubheaderDownloadLink);

export const SummaryViewComponentProps = BaseDefnsComponentProps.merge(OptionalIdentifierProps)
  .merge(HasCart)
  .merge(HasPopover)
  .merge(HasFab)
  .merge(HasWorklist)
  .merge(HasSubheaderDownloadLink)
  .merge(HasTopMembers);

export const GridViewComponentProps = BaseDefnsComponentProps.merge(OptionalIdentifierProps)
  .merge(HasCart)
  .merge(HasPopover)
  .merge(HasFab)
  .merge(HasWorklist)
  .merge(HasExceptionsMultiSelect)
  .merge(HasSubheaderDownloadLink)
  .merge(HasTopMembers)
  .merge(HasHideableCompanion);

export const ColumnGroupedViewComponentProps = BaseDefnsComponentProps.merge(IdentifierProps)
  .merge(GroupingInfo)
  .merge(HasCart)
  .merge(HasPopover)
  .merge(HasFab)
  .merge(HasTopMembers)
  .and(HasSubheaderDownloadLink.optional());

export const QuickTrendsComponentProps = BaseMultiDefnsComponentProps.merge(HasTopMembers);

// TODO: config should be setup to be models since there are two.
// TODO: we may want to set this up like ListDataConfig (DataApi) but for FitView since that is what the params.aggBy field is really for.
// GroupingInfo is needed so pareto aggBy isnt hardcoded
export const PerformanceComponentProps = BaseDefnsComponentProps.merge(GroupingInfo);

export const NestedAttributeComponentProps = BaseDefnsComponentProps.merge(HasFlowStatus)
  .merge(HasSubheaderErrorText)
  .merge(HasConfigure)
  .merge(HasFullHeight)
  .merge(HasSummaries);

export const PricingAndFlowSheetComponentProps = BaseMultiDefnsComponentProps.merge(IdentifierProps)
  .merge(HasPopover)
  .merge(HasFab);

export const AssortmentMatrixDetailProps = z.object({
  dataApi: DataApiConfigZod.or(ListDataApiZod),
  configApi: DataApiConfigZod,
  floorsetApi: DataApiConfigZod.optional(),
  planningApi: DataApiConfigZod,
  topAttributesApi: DataApiConfigZod.optional(),
  isStyleColorEdit: z.boolean().optional(),
});

// TODO: determine better name for this to be more clear what type of component this is representing.
// Maybe ConfigurableGridComponentProps?
export const AssortmentMatrixComponentProps = AssortmentMatrixDetailProps.merge(HasSubheaderErrorText)
  .merge(HasPopover)
  .merge(HasTitle)
  .merge(HasTopMembers)
  .merge(HasHideableCompanion)
  .merge(HasFab)
  .merge(HasFlowStatus)
  .merge(HasSubheaderDownloadLink)
  .merge(
    z.object({
      showPublishText: z.boolean().optional(),
    })
  );

const AssortmentAddDetailProps = z.object({
  level: z.string(),
  cartItemType: CartItemType,
});

export const AssortmentAddViewComponentProps = BaseDefnsComponentProps.merge(AssortmentAddDetailProps)
  .merge(HasFab)
  .merge(HasRangeSelector);

// FIXME: the above ConfigApi structure has different a shape and need to match the one in confdefnView.ts
const OvertimeConfigApi = z.object({
  params: z.object({
    appName: z.union([z.literal(ASSORTMENT), z.literal(TOP_DOWN)]).optional(),
    defnId: z.string(),
    aggBy: z.string(),
    ignoreAncestors: z.boolean().optional(),
  }),
});

export const NestedOvertimeComponentProps = BaseDefnsComponentProps.merge(
  z.object({
    dataApi: OvertimeConfigApi,
    companionDataApi: OvertimeConfigApi.optional(),
  })
);

export const AssortmentPublishComponentProps = BaseDefnsComponentProps.merge(IdentifierProps)
  .merge(HasSubheaderErrorText)
  .merge(z.object({ dataApi: ListDataApiZod.optional() }));

export const TargetListComponentProps = z.object({ type: z.union([z.literal('Product'), z.literal('Location')]) });

const SizeEligibilityListGridDetailProps = z.object({
  viewProperty: z.string(),
  floorsetApi: ConfigApi.optional(),
  dataApi: ListDataApiZod,
  companionApi: ListDataApiZod.optional(),
});

export const SizeEligibilityListGridComponentProps = BaseDefnsComponentProps.merge(
  SizeEligibilityListGridDetailProps
).merge(HasSubheaderDownloadLink);

export const WorklistComponentProps = BaseDefnsComponentProps.merge(HasFab)
  .merge(HasFlowStatus)
  .merge(z.object({ showLevel: z.boolean().optional() }));

export const HistoryGridComponentProps = BaseDefnsComponentProps.merge(OptionalIdentifierProps)
  .merge(HasTopMembers)
  .merge(HasHideableCompanion)
  .merge(HasFab);

export const StyleEditComponentProps = BaseDefnsComponentProps.merge(HasFab);
export const EnhancedOvertimeComponentProps = BaseMultiDefnsComponentProps.merge(HasTopMembers);

export const TopPerformersComponentProps = BaseDefnsComponentProps;
export const ProductivityComponentProps = BaseDefnsComponentProps;
export const CategorySummaryComponentProps = BaseDefnsComponentProps;
export const ProductMixComponentProps = BaseDefnsComponentProps;
export const ParameterTogglesComponentProps = BaseDefnsComponentProps;
export const ProductDetailsComponentProps = BaseMultiDefnsComponentProps;
