import { z } from 'zod';
import { BOTTOM_UP_PERSPECTIVE, TOP_DOWN_PERSPECTIVE } from 'src/utils/Domain/Constants';

export const Perspectives = z.union([z.literal(BOTTOM_UP_PERSPECTIVE), z.literal(TOP_DOWN_PERSPECTIVE)]);

// Typing all components available for use in confdefn

// This is no longer used in code but is here as a reference just in case
export const AvailableComponents = z.union([
  z.literal('Summary'),
  z.literal('GeoTrends'),
  z.literal('MacroMix'),
  z.literal('TopPerformers'),
  z.literal('ParetoSummary'),
  z.literal('ParetoDetails'),
  z.literal('NestedView'),
  z.literal('ListView'),
  z.literal('Reporting'),
  z.literal('BulkImport'),
  z.literal('CollectionView'),
  z.literal('CanvasView'),
  z.literal('SummaryView'),
  z.literal('GridView'),
  z.literal('FlowType'),
  z.literal('TopTYvsLY'),
  z.literal('CategorySummary'),
  z.literal('ProductMix'),
  z.literal('Productivity'),
  z.literal('NestedAttribute'),
  z.literal('ProductDetails'),
  z.literal('Worklist'),
  z.literal('FloorsetComparison'),
  z.literal('StyleEdit'),
  z.literal('PricingOverTime'),
  z.literal('StyleAttributes'),
  z.literal('StyleColorAttributes'),
  z.literal('LinePlanByFloorset'),
  z.literal('FlowSheetByStyle'),
  z.literal('AssortmentAddView'),
  z.literal('AssortmentCart'),
  z.literal('NestedOvertime'),
  z.literal('NestedStyleOvertime'),
  z.literal('AssortmentPublish'),
  z.literal('TargetList'),
  z.literal('SizeEligibilityListGrid'),
  z.literal('ParameterToggles'),
  z.literal('ExceptionsSummary'),
]);

export const ProplessComponents = z.union([
  z.literal('Summary'),
  z.literal('GeoTrends'),
  z.literal('MacroMix'),
  z.literal('Reporting'),
  z.literal('BulkImport'),
  z.literal('AssortmentCart'),
  z.literal('ExceptionsSummary'),
]);

export const CartItemType = z.union([z.literal('similar'), z.literal('existing')]);
