import React from 'react';
import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  receiveParetoAnalysisTenantConfig,
  requestParetoAnalysisTenantConfig,
  updateItemStripConfig,
} from './ParetoAnalysis.slice';
import { ReduxSlice as SubheaderSlice, SubheaderViewDefns } from 'src/components/Subheader/Subheader.slice';

import ParetoAnalysis from './ParetoAnalysis';
import container from 'src/ServiceContainer';
import { ParetoAnalysisSlice, ParetoPage } from './ParetoAnalysis.slice';
import {
  buildRenderedSummary,
  reorderWithSortBy,
  getAllProductsSummary,
} from 'src/pages/Hindsighting/Performance/ParetoAnalysis/ParetoAnalysis.selectors';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { GetSummaryOutput as GetAllProductsSummaryOutput } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import { DataItem } from 'src/common-ui/components/Charts/PerformanceChart';
import { setSelectedItem } from '../StoreGroupAnalysis/StoreGroupChart.actions';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { Option } from 'src/components/Configure/ConfigureModal';
import { parseConfigureConfig } from 'src/components/Configure/Configure';
import { updateConfigureSelections } from 'src/components/Subheader/Subheader.slice';
import { ParetoSummaryPivot } from 'src/components/views/ParetoAnalysis/ParetoSummary';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { PerformanceComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { flow } from 'lodash';
import StoreGroupChart from '../StoreGroupAnalysis/StoreGroupChart.container';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { isDataLoaded } from 'src/services/pivotServiceCache';

type ParetoAnalysisOwnProps = z.infer<typeof PerformanceComponentProps>;

export type ParetoAnalysisStateProps = ParetoAnalysisSlice & {
  defaultConfigureSelections: Option[];
  originalDefaultSelections: Option[];
  configureSelections?: Option[];
  allProductsSummary: GetAllProductsSummaryOutput[];
  subheader: SubheaderSlice;
  title: string;
  subheaderViewDefns: SubheaderViewDefns;
  page: ParetoPage;
  storeModal?: React.ReactChild;
  flat: BasicPivotItem[];
  loaded: boolean;
};

export type ParetoAnalysisDispatchProps = {
  onShowView: () => void;
  openStore?: (item: DataItem) => void;
  updateConfigureSelections?: (selections: Option[]) => void;
  onUpdateConfig: (config: TenantConfigViewData) => void;
};

function mapStateWithPage(page: ParetoPage) {
  return function mapStateToProps(state: AppState, ownProps: ParetoAnalysisOwnProps): ParetoAnalysisStateProps {
    const { title = 'Pareto Analysis', defns } = ownProps;
    let defaultConfigureSelections: Option[] = [],
      originalDefaultSelections: Option[] = [];
    const subheader = state.subheader;

    const { paretoAnalysis } = state.pages.hindsighting;
    const configurationViewDefn = paretoAnalysis.viewDefns[2];

    if (configurationViewDefn) {
      const parseResult = parseConfigureConfig(configurationViewDefn.view[0], configurationViewDefn.defaults);
      defaultConfigureSelections = parseResult.defaultSelections;
      originalDefaultSelections = parseResult.originalDefaultSelections;
    }

    const summary = buildRenderedSummary(state) as ParetoSummaryPivot;
    const loaded =
      summary &&
      isViewDefnLoaded(paretoAnalysis.viewDefnState) &&
      isDataLoaded(paretoAnalysis.summaryDataState) &&
      isDataLoaded(paretoAnalysis.analysisDataState);

    return {
      ...paretoAnalysis,
      loaded,
      page,
      allProductsSummary: getAllProductsSummary(state),
      configureSelections: state.subheader.configureSelections,
      defaultConfigureSelections,
      originalDefaultSelections,
      flat: reorderWithSortBy(state),
      summary,
      subheader,
      subheaderViewDefns: defns.subheader,
      title,
      storeModal: <StoreGroupChart />,
    };
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: ParetoAnalysisOwnProps): ParetoAnalysisDispatchProps {
  const client = container.tenantConfigClient;
  const { defns } = ownProps;
  return {
    onShowView() {
      dispatch(requestParetoAnalysisTenantConfig());
      client
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          favoritesDefn: defns.view[1],
        })
        .then((resp) => {
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[1],
            (resp as any)[4],
            dispatch
          );
          // Remove favorites list from resp
          resp.splice(4, 1);
          // Add unmodified view defn to end of view defn responses, as it consumes the entire resp below
          resp[4] = resp[1];
          if (localConfig && localConfig.config) {
            resp[1] = localConfig.config;
            if (localConfig.configurationSelections) {
              resp[2].view[0].defaultsOverride = localConfig.configurationSelections;
            }
          }
          return resp;
        })
        .then((resp) => {
          if (resp) {
            dispatch(receiveParetoAnalysisTenantConfig(resp));
          }
        });
    },
    openStore(item: DataItem) {
      dispatch(setSelectedItem(item as any));
    },
    updateConfigureSelections(selections: Option[]) {
      dispatch(updateConfigureSelections(selections));
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(updateItemStripConfig(config));
    },
  };
}

// TODO: Turns out Typescript is always using the "any" flow definition (the fallback)
// instead of using the appropriate argument inference. What do?
const component = flow(() => ParetoAnalysis, makePrintSensitive)();

export const ParetoSummary = connect(mapStateWithPage('summary'), mapDispatchToProps)(component);

export const ParetoDetails = connect(mapStateWithPage('details'), mapDispatchToProps)(component);
