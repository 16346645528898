import React, { Component } from 'react';
import { isNil, pick } from 'lodash';
import { Tab, Tabs } from '@material-ui/core';

import {
  WorklistTabsProps,
  WorklistViewContainerProps,
  WorklistViewContainerState,
} from 'src/pages/AssortmentBuild/Worklist/WorklistViewContainer.types';
import StylePreview from 'src/components/StylePreview/StylePreview';
import {
  TabbedPricingGrid,
  TabbedFlowSheetGrid,
} from 'src/pages/AssortmentBuild/Worklist/ComponentTypeWrapper.container';
import stylesWorklist from 'src/pages/AssortmentBuild/Worklist/Worklist.styles';
import { WrappedVisualize } from 'src/components/Visualize/Visualize.container';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { connect, Dispatch } from 'react-redux';
import { setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { AppState } from 'src/store';
import CollectionView from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.container';
import GridViewContainer from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.container';
import FlowTypeContainer from 'src/pages/Hindsighting/StyleColorReview/FlowType/FlowType.container';
import SummaryViewContainer from 'src/pages/Hindsighting/StyleColorReview/SummaryView/SummaryView.container';
import Subheader from 'src/components/Subheader/Subheader.container';
import NestedViewContainer from 'src/pages/Hindsighting/HistoryGrid/NestedView/NestedView.container';
import EnhancedOvertime from 'src/components/EnhancedOvertime/EnhancedOvertime';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { LegacyWorklistComponentType } from './Worklist.types';
import QuickTrends from 'src/pages/Hindsighting/MacroTrends/QuickTrends/QuickTrends';
import LinePlanByFloorset from '../LinePlan/LinePlanByFloorset/LinePlanByFloorset';

const WorklistTabs = ({ tabsConfig, selectedIndex, onChange }: WorklistTabsProps) => {
  return (
    <div className={stylesWorklist.tabsContainer}>
      <Tabs value={selectedIndex} onChange={onChange as () => void} variant={'fullWidth'}>
        {tabsConfig.map(({ title }, index) => (
          <Tab key={`${title}-${index}`} label={title} />
        ))}
      </Tabs>
    </div>
  );
};

class WorklistViewContainer extends Component<WorklistViewContainerProps, WorklistViewContainerState> {
  constructor(props: WorklistViewContainerProps) {
    super(props);

    const { activeSubPage, tabsConfig } = this.props;
    // we keep the activeSubPage in global store
    // so that we can re-mount this component on the previously opened tabs
    if (activeSubPage) {
      const maybeActiveTabConfig = tabsConfig.map((t) => t.componentType).findIndex((t) => t === activeSubPage);
      if (maybeActiveTabConfig > -1) {
        this.state = {
          selectedTabIndex: maybeActiveTabConfig,
        };
      } else {
        this.state = {
          selectedTabIndex: props.defaultTabIndex,
        };
      }
    } else {
      // if we don't find one, open with the default
      this.props.setActiveSubPage(this.props.tabsConfig[props.defaultTabIndex].componentType);
      this.state = {
        selectedTabIndex: props.defaultTabIndex,
      };
    }
  }

  componentDidUpdate() {
    if (this.props.activeSubPage == '' || this.props.activeSubPage == null) {
      this.props.setActiveSubPage(this.props.tabsConfig[this.props.defaultTabIndex].componentType);
      this.setState({
        selectedTabIndex: this.props.defaultTabIndex,
      });
    }
  }

  createTabContent = () => {
    if (isNil(this.props.selectedCompanionItem)) {
      return <div>No item selected, unable to generate view.</div>;
    }

    const { tabsConfig, selectedCompanionItem, subheaderSlice, styles, onSelectStyle } = this.props;
    const { selectedTabIndex } = this.state;
    const selectedTabConfig = tabsConfig[selectedTabIndex];

    const { id, name, stars, imageUri, title, style } = selectedCompanionItem;
    const stylePreviewData = {
      id,
      description: name,
      starRating: stars || 0,
      imgUri: imageUri || '',
      title,
      style,
    };

    switch (selectedTabConfig.componentType) {
      case ConfDefnComponentType.visualize:
      case LegacyWorklistComponentType.visualize:
        return (
          <WrappedVisualize
            viewDefns={selectedTabConfig.viewDefns}
            selectedItemPreviewData={stylePreviewData}
            subheaderSlice={subheaderSlice}
            styles={styles}
            dontFilterSwatches={true}
            onSelectStyle={onSelectStyle}
            model={selectedTabConfig.model}
          />
        );
      case ConfDefnComponentType.pricing:
      case LegacyWorklistComponentType.pricing:
        // 52px is the height of the tab currently. Need to wrap like this as
        // makeScopeAndFilterSensitive wraps a 100% height div around the component
        // making the scrollbar show up before you could change the height in the component to not overflow
        return (
          <div style={{ height: `calc(100% - ${stylesWorklist.WORKLIST_TAB_HEIGHT}px)`, width: '100%' }}>
            <TabbedPricingGrid
              selectedItemId={id}
              viewDefns={selectedTabConfig.viewDefns}
              model={selectedTabConfig.model}
            />
          </div>
        );
      case ConfDefnComponentType.flowSheet:
      case LegacyWorklistComponentType.flowsheet:
        return (
          <div style={{ height: `calc(100% - ${stylesWorklist.WORKLIST_TAB_HEIGHT}px)`, width: '100%' }}>
            <TabbedFlowSheetGrid
              selectedItemId={id}
              viewDefns={selectedTabConfig.viewDefns}
              model={selectedTabConfig.model}
            />
          </div>
        );
      case ConfDefnComponentType.styleEdit:
      case LegacyWorklistComponentType.styleEdit:
        const subheaderProps = {
          sortByDefn: 'AssortmentBuildStyleEditSortBy',
          showAlternateFlowStatus: true,
          showAlternateSearch: true,
        };
        return (
          <React.Fragment>
            <Subheader {...subheaderProps} />
            <StylePreview
              filters={pick(subheaderSlice, 'altSearch', 'altFlowStatus', 'sortBy')}
              searchKeys={externalGridSearchFields}
              previewData={stylePreviewData}
              renderSections={true}
              sectionsViewDefns={selectedTabConfig.viewDefns}
              onStyleItemEdit={this.handleStyleItemUpdate}
              onRefreshView={this.handleRefreshView}
            />
          </React.Fragment>
        );
      case ConfDefnComponentType.collectionView:
      case LegacyWorklistComponentType.collectionView:
        return <CollectionView {...selectedTabConfig.componentProps} topMembers={id} hideTitle={true} />;
      case ConfDefnComponentType.gridView:
      case LegacyWorklistComponentType.gridView:
        return (
          <GridViewContainer
            {...selectedTabConfig.componentProps}
            topMembers={id}
            hideTitle={true}
            hideCompanion={true}
          />
        );
      case ConfDefnComponentType.configurableGrid:
        return <LinePlanByFloorset {...selectedTabConfig.componentProps} hideCompanion={true} topMembers={id} />;
      case ConfDefnComponentType.flowType:
      case LegacyWorklistComponentType.flowType:
        return <FlowTypeContainer {...selectedTabConfig.componentProps} topMembers={id} hideTitle={true} />;
      case ConfDefnComponentType.summaryView:
      case LegacyWorklistComponentType.summaryView:
        return <SummaryViewContainer {...selectedTabConfig.componentProps} topMembers={id} hideTitle={true} />;
      case ConfDefnComponentType.quickTrends:
        return <QuickTrends {...selectedTabConfig.componentProps} topMembers={id} />;
      case ConfDefnComponentType.nestedView:
      case LegacyWorklistComponentType.nestedView:
        return (
          <NestedViewContainer
            {...selectedTabConfig.componentProps}
            topMembers={id}
            hideTitle={true}
            hideCompanion={true}
          />
        );
      case ConfDefnComponentType.enhancedOvertime:
        return (
          <EnhancedOvertime
            {...selectedTabConfig.componentProps}
            topMembers={this.props.selectedCompanionItem.id}
            hideTitle={true}
          />
        );
      default:
        return <div>Invalid Component Type has been configured</div>;
    }
  };

  handleTabChange = (_event: React.ChangeEvent | null, value: number) => {
    this.setState({
      selectedTabIndex: value,
    });
    this.props.setActiveSubPage(this.props.tabsConfig[value].componentType);
  };

  handleRefreshView = () => {
    this.props.onViewRefresh();
  };

  handleStyleItemUpdate = (styleId: string, propToUpdate: string, propValue: string) => {
    this.props.onStyleItemUpdate(styleId, propToUpdate, propValue);
  };

  render() {
    const { tabsConfig } = this.props;
    const { selectedTabIndex } = this.state;

    return (
      <div className={stylesWorklist.worklistViewContainer}>
        <WorklistTabs tabsConfig={tabsConfig} selectedIndex={selectedTabIndex} onChange={this.handleTabChange} />
        <div style={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}>
          {this.props.styles && this.props.styles.length > 0 && this.createTabContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return { activeSubPage: state.perspective.activeSubPage };
};

const mapDispatchToProps = (dispatch: Dispatch<AppState>) => {
  return {
    setActiveSubPage(subPage: string) {
      dispatch(setActiveSubPage(subPage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorklistViewContainer);
