import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ExceptionList } from './ExceptionsSummary';

export type ExceptionsSummaryConfigs = {
  columns: TenantConfigViewItem[];
  main: {
    title: string;
    goodColorRange: string[];
    badColorRange: string[];
  };
};

export type ExceptionsSummarySlice = {
  viewDefns: undefined | ExceptionsSummaryConfigs;
  isConfigLoading: boolean;
  isGridDataLoading: boolean;
  gridData: undefined | ExceptionList;
};

const initExceptionsSummarySlice = {
  viewDefns: undefined,
  isConfigLoading: false,
  isGridDataLoading: false,
  gridData: undefined,
};

const exceptionsSummarySliceReducer = createSlice({
  name: 'exceptionsSummary',
  initialState: initExceptionsSummarySlice as ExceptionsSummarySlice, // this needs to be asserted
  reducers: {
    requestExceptionsSummaryConfig(state) {
      state.isConfigLoading = true;
    },
    requestExceptionsSummaryGridData(state) {
      state.isGridDataLoading = true;
    },
    receiveExceptionsSummaryConfig(state, action: PayloadAction<ExceptionsSummaryConfigs>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveExceptionsSummaryGridData(state, action: PayloadAction<ExceptionList>) {
      state.gridData = action.payload;
      state.isGridDataLoading = false;
    },
    requestExceptionsSummaryError() {
      return initExceptionsSummarySlice;
    },
  },
});

export const {
  requestExceptionsSummaryConfig,
  requestExceptionsSummaryGridData,
  receiveExceptionsSummaryConfig,
  receiveExceptionsSummaryGridData,
  requestExceptionsSummaryError,
} = exceptionsSummarySliceReducer.actions;
export default exceptionsSummarySliceReducer.reducer;
