import { CancelToken } from 'axios';

import { AppType } from 'src/types/Domain';
import { provideAppName } from 'src/utils/Domain/Perspective';
import Worker from 'src/worker';
import {
  OverTimeType,
  Pivot,
  ListDataOptions,
  BasicPivotItem,
  RegionItem,
  TimeSheetInfo,
  SubmitPayload,
  WorklistInfo,
  TOP_DOWN,
  TOP_DOWN_IDENTIFIER,
  FitViewOptions,
} from 'src/worker/pivotWorker.types';
import { CoarseEditPayload, GranularEditPayloadItem, PivotClient } from 'src/dao/pivotClient';
import { makePivotServiceCache, RequestEndpoint } from './pivotServiceCache';
import { PivotServiceCache } from './pivotServiceCache';

interface CacheHit {
  cacheData: Pivot;
  cacheHit: true;
  cacheHash: string;
}
interface CacheMiss {
  cacheHit: false;
  cacheData: {
    tree: [];
    flat: [];
  };
  cacheHash: null;
}
type CacheCheck = CacheHit | CacheMiss;

export interface CacheCheckResponse {
  cacheHash: string;
  cachePromise: Promise<CacheCheck>;
  dataPromise: Promise<Pivot>;
}

type ServiceFitViewOptions = {
  sortBy?: string;
  flowStatus?: string;
  cancelToken?: CancelToken;
  groupBy?: string;
  extraParams?: {
    [s: string]: string | undefined;
  };
};
// api/property
export type PivotService = {
  pivotCache: PivotServiceCache;
  clearPivotCache(): void;
  fitViewCacheCheck(defnId: string, options?: FitViewOptions, cancelToken?: CancelToken): Promise<CacheCheckResponse>;
  listFitViewData(
    defnId: string,
    _appName: AppType,
    options?: ServiceFitViewOptions,
    allowCache?: boolean
  ): Promise<Pivot>;
  deserialize(pivotQuery: string): Record<string, any>;
  listDataCacheCheck(defnId: string, options: ListDataOptions): Promise<CacheCheckResponse>;
  listData(defnId: string, _appName: AppType, options?: ListDataOptions, allowCache?: boolean): Promise<Pivot>;
  getHistoryStylePaneDetails(options?: ListDataOptions): Promise<BasicPivotItem[]>;
  getStyleReviewDetails(options?: ListDataOptions): Promise<BasicPivotItem[]>;
  getValidMembers(level: string): Promise<RegionItem[]>;
  getFlowSheetByIndex(memberIds: string[], start: string, end: string): Promise<Pivot>;
  getOverTimeData<T>(memberId: string, overtimeType: OverTimeType, defnId?: string): Promise<CacheCheckResponse>;
  getMappingTimeInfo(parentLevel?: string): Promise<TimeSheetInfo>;
  getWorklistData(): Promise<WorklistInfo[]>;
  submitFlowSheetPayload(payload: SubmitPayload): Promise<boolean>;
  submitPricingPayload(payload: SubmitPayload): Promise<boolean>;
  coarseEditSubmitData(payload: CoarseEditPayload): Promise<boolean>;
  granularEditSubmitData(payload: GranularEditPayloadItem[]): Promise<boolean>;
};

function maybeChangeDefnId(appName: AppType, defnId: string) {
  return appName !== TOP_DOWN ? defnId : TOP_DOWN_IDENTIFIER + defnId;
}

function generateCachePromise(pivotCache: PivotServiceCache, cacheHash: string) {
  const cacheCheck: CacheCheck = pivotCache.has(cacheHash)
    ? {
        cacheHit: true,
        cacheData: pivotCache.get(cacheHash) as Pivot,
        cacheHash: cacheHash,
      }
    : {
        cacheHit: false,
        cacheData: {
          tree: [],
          flat: [],
        },
        cacheHash: null,
      };

  return Promise.resolve(cacheCheck);
}

async function generatePivotDataPromise(
  dataPromiseLive: Promise<Pivot>,
  pivotCache: PivotServiceCache,
  cacheHash: string
) {
  let dataPromise: Promise<Pivot> = dataPromiseLive;

  if (pivotCache.isInflight(cacheHash)) {
    dataPromise = pivotCache.getInflightPromise(cacheHash) || Promise.resolve();
  } else {
    pivotCache.setInflightRequest(cacheHash, dataPromise);
    dataPromise
      .then((data) => {
        pivotCache.deleteInflightRequest(cacheHash);
        pivotCache.set(cacheHash, data);
        // TODO: we won't really need to return data here anymore with
        // newer solution of removing data from each slice and only storing in cache
        // same for handleListDataPromise above
        return data;
      })
      .catch((error) => {
        // TODO: not sure what to do with errors in cache, just removes inflight and propagates error
        pivotCache.deleteInflightRequest(cacheHash);
        throw error;
      });
  }

  return dataPromise;
}

export function makePivotService(pivotClient: PivotClient): PivotService {
  // create web worker instance
  const pWorker = new Worker();
  const pivotCache = makePivotServiceCache();

  return {
    pivotCache, // warning: globally accessible through the service container
    clearPivotCache() {
      pivotCache.clear();
    },
    deserialize(pivotQuery: string) {
      return pivotClient.objectDeserializer.deserialize(pivotQuery);
    },
    listDataCacheCheck(unchangedDefnId: string, inputs: ListDataOptions): Promise<CacheCheckResponse> {
      return provideAppName(async (appName: AppType) => {
        const defnId = maybeChangeDefnId(appName, unchangedDefnId);
        const cacheHash = PivotServiceCache.hash({ endpoint: RequestEndpoint.listData, defnId, inputs });
        const dataPromiseLive = pWorker.getListData(appName, defnId, inputs);
        return {
          cacheHash,
          cachePromise: generateCachePromise(pivotCache, cacheHash),
          dataPromise: generatePivotDataPromise(dataPromiseLive, pivotCache, cacheHash),
        };
      });
    },
    fitViewCacheCheck(
      unchangedDefnId: string,
      inputs: FitViewOptions,
      cancelToken: CancelToken
    ): Promise<CacheCheckResponse> {
      return provideAppName(async (appName: AppType) => {
        const defnId = maybeChangeDefnId(appName, unchangedDefnId);
        const cacheHash = PivotServiceCache.hash({
          endpoint: RequestEndpoint.fitView,
          defnId,
          inputs,
        });
        const dataPromiseLive = pWorker.getListFitViewData(appName, defnId, { ...inputs, cancelToken });

        return {
          cacheHash,
          cachePromise: generateCachePromise(pivotCache, cacheHash),
          dataPromise: generatePivotDataPromise(dataPromiseLive, pivotCache, cacheHash),
        };
      });
    },
    listFitViewData(
      unchangedDefnId: string,
      _appName: AppType,
      options: ServiceFitViewOptions = {},
      allowCache = false
    ) {
      return provideAppName(async (appName) => {
        const defnId = maybeChangeDefnId(appName, unchangedDefnId);
        const { sortBy, groupBy = '', flowStatus, cancelToken, extraParams } = options;
        const sendOpts = {
          groupBy,
          sortBy,
          flowStatus,
          cancelToken,
          ...extraParams,
        };

        const cacheHash = PivotServiceCache.hash({ endpoint: RequestEndpoint.fitView, defnId, inputs: sendOpts });

        if (allowCache === true && pivotCache.has(cacheHash)) {
          return Promise.resolve(pivotCache.get(cacheHash));
        } else if (pivotCache.isInflight(cacheHash)) {
          return await pivotCache.getInflightPromise(cacheHash);
        }

        const prom = pWorker.getListFitViewData(appName, defnId, options);
        pivotCache.setInflightRequest(cacheHash, prom);
        const result = await prom;
        pivotCache.inflightRequestMap.delete(cacheHash);
        pivotCache.set(cacheHash, result);
        return result;
      });
    },
    listData(unchangedDefnId: string, _appName: AppType, options: ListDataOptions, allowCache = false) {
      return provideAppName(async (appName) => {
        const defnId = maybeChangeDefnId(appName, unchangedDefnId);
        const cacheHash = PivotServiceCache.hash({ endpoint: RequestEndpoint.listData, defnId, inputs: options });

        if (allowCache === true && pivotCache.has(cacheHash)) {
          return Promise.resolve(pivotCache.get(cacheHash));
        } else if (pivotCache.isInflight(cacheHash)) {
          return await pivotCache.getInflightPromise(cacheHash);
        }

        const prom = pWorker.getListData(appName, defnId, options);
        pivotCache.setInflightRequest(cacheHash, prom);
        const result = await prom;
        pivotCache.inflightRequestMap.delete(cacheHash);
        pivotCache.set(cacheHash, result);
        return result;
      });
    },
    getHistoryStylePaneDetails(options?: ListDataOptions) {
      return provideAppName(async (appName) => {
        const unchangedDefnId = 'HistoryStyleReview';
        const defnId = maybeChangeDefnId(appName, unchangedDefnId);
        const listData = await pWorker.getListData(appName, defnId, options);
        return listData.flat;
      });
    },
    getStyleReviewDetails(options?: ListDataOptions) {
      return provideAppName(async (appName) => {
        const unchangedDefnId = 'StyleChannelReview';
        const defnId = maybeChangeDefnId(appName, unchangedDefnId);
        const listData = await pWorker.getListData(appName, defnId, options);
        return listData.flat;
      });
    },
    getValidMembers(level: string) {
      return provideAppName(async (appName) => {
        return await pWorker.getValidMembers({ appName, level });
      });
    },
    getFlowSheetByIndex(
      memberIds: string[],
      start: string,
      end: string,
      defnId = 'FlowSheetStyleAll',
      aggBy = 'level:stylecolor'
    ) {
      return provideAppName(async (appName) => {
        const queryParams = {
          appName,
          memberIds,
          aggBy,
          defnId,
          start,
          end,
          sortBy: 'product,time',
        };
        return await pWorker.getFlowSheetByIndex(queryParams);
      });
    },
    getOverTimeData(
      memberId: string,
      overtimeType: OverTimeType,
      overrideDefnId?: string
    ): Promise<CacheCheckResponse> {
      return provideAppName(async (appName) => {
        let unchangedDefnId = '';
        if (!overrideDefnId) {
          unchangedDefnId = overtimeType === OverTimeType.flowsheet ? 'FlowSheetStyle' : 'PricingOvertime';
        } else if (overrideDefnId) {
          unchangedDefnId = overrideDefnId;
        }
        const aggBy = 'level:stylecolor';
        const defnId = maybeChangeDefnId(appName, unchangedDefnId);
        const queryParams = {
          appName,
          memberId,
          aggBy,
          defnId,
          sortBy: 'stylecolor',
        };
        const endpoint = OverTimeType.flowsheet ? 'flowsheet' : 'pricing';
        const cacheHash = PivotServiceCache.hash({ endpoint, defnId, inputs: queryParams });
        const dataPromiseLive = pWorker.getOverTimeData(queryParams, overtimeType);
        return {
          cacheHash,
          cachePromise: generateCachePromise(pivotCache, cacheHash),
          dataPromise: generatePivotDataPromise(dataPromiseLive, pivotCache, cacheHash),
        };
      });
    },
    getMappingTimeInfo(parentLevel = 'month') {
      return provideAppName(async (appName) => {
        const queryParams = {
          appName,
          parentLevel,
        };

        return await pWorker.getTimeMapping(queryParams);
      });
    },
    getWorklistData() {
      return provideAppName(async (_appName) => {
        return await pWorker.getWorklistData();
      });
    },
    submitFlowSheetPayload(payload: SubmitPayload) {
      return provideAppName((appName) => {
        return pivotClient.flowSheetSubmitData(payload, {
          appName,
        });
      });
    },
    submitPricingPayload(payload: SubmitPayload) {
      return provideAppName((appName) => {
        return pivotClient.pricingSubmitData(payload, {
          appName,
        });
      });
    },
    coarseEditSubmitData(payload: CoarseEditPayload): Promise<boolean> {
      return pivotClient.coarseEditSubmitData(payload);
    },
    granularEditSubmitData(payload: GranularEditPayloadItem[]): Promise<boolean> {
      return pivotClient.granularEditSubmitData(payload);
    },
  };
}
