import { forEach, isNil, has } from 'lodash';
import {
  ClientActionHandlersConfig,
  ClientActionHandler,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { CLASS_ID, STYLE_NAME, STYLE_DESCRIPTION, CLASS_NAME, SUBCLASS_NAME } from 'src/utils/Domain/Constants';
import { CellEditingStartedEvent, ColDef, ColGroupDef } from 'ag-grid-community';
import { BasicItem } from 'src/types/Scope';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';

export function parseActions(config: ClientActionHandlersConfig): ClientActionHandler {
  const retObj: ClientActionHandler = {};

  forEach(config, (actionValue, _action) => {
    // at this point the name of the action doesn't really matter only the clientHandler name and value (string[])
    forEach(actionValue, (attributesToHandle, clientHandler) => {
      retObj[clientHandler] = attributesToHandle;
    });
  });

  return retObj;
}

export function isGroupColDef(colDef: ColDef | ColGroupDef): colDef is ColGroupDef {
  return has(colDef, 'groupId');
}

export function updateWithClientHandler(field: string, clientHandlers: ClientActionHandler | undefined): boolean {
  if (isNil(clientHandlers)) {
    return false;
  }

  let handleWithClientHandler = false;
  forEach(clientHandlers, (attributesToHandle, _clientHandler) => {
    if (attributesToHandle.indexOf(field) >= 0) {
      handleWithClientHandler = true;
    }
  });

  return handleWithClientHandler;
}

export function replaceExtraProps(str: string): string {
  switch (str) {
    case STYLE_NAME:
      return 'name';
    case STYLE_DESCRIPTION:
      return 'description';
    case CLASS_ID:
      return 'class';
    case CLASS_NAME:
      return 'ccclassname';
    case SUBCLASS_NAME:
      return 'ccpowerdrivername';
    default:
      return str
        .replace('attribute:', '')
        .replace('member:', '')
        .replace(':id', '')
        .replace(':name', '');
  }
}

export function calculateColumnWidth(dataIndex: string): number {
  switch (dataIndex) {
    case 'popoverTrigger':
      return 60;
    case 'attribute:ccstylecolorcreatedate:id':
      return 215;
    default:
      return 200;
  }
}

export function resetAsyncValidationData(event: CellEditingStartedEvent) {
  const { node } = event;
  if (has(node.data, 'invalid:style')) {
    const resetData = { ...node.data };
    delete resetData['invalid:style'];
    node.setData(resetData);
  }
}

export const floorsetDataToDropdown = (floorsetData: BasicItem): TenantConfigViewItem => {
  return {
    ...floorsetData,
    dataIndex: floorsetData.id,
    text: floorsetData.name,
  };
};

export const parseFloorsetDropdownData = (obj: BasicItem[]) => {
  // Currently, floorset returns data that needs to be mutated (id => dataIndex and name => text) for subheader
  return obj.map(floorsetDataToDropdown);
};
