import { AxiosInstance } from 'axios';
import { TenantConfig } from './bindings.types';
import { BoundTab, BoundTenant, BoundPerspective } from './bindings.types';
import { bindTenant, bindPerspectives } from './bindings.utils';
import { API_URL_PREFIX } from 'src/utils/Domain/Constants';
import QueryString from 'query-string';
import { validateConfDefn } from './codecs/confdefn';

const appConfigPath = '/api/uidefn/uiconfig';
const fingerPath = '/api/uidefn/config/fingerprint';

export interface IsDelayed {
  whenDone(): Promise<void>;
}

export interface BoundTabs {
  defaultPathSlot: string;
  tabs: BoundTab[];
}

export interface HasPerspectives {
  getPerspectives(): BoundPerspective[];
}

export interface HasBindings {
  getBindings(perspective: BoundPerspective): BoundTenant;
}

export interface HasTimeout {
  getTimeout(): number;
}

export type ConfigurationService = IsDelayed &
  HasPerspectives &
  HasBindings &
  HasTimeout & {
    getOrThrow(): TenantConfig;
  };

export const fetchFingerPrint = (axios: AxiosInstance) => {
  return () => {
    return axios.get(`${fingerPath}`).then((resp): string => {
      return resp.data;
    });
  };
};

export default (axios: AxiosInstance) => (appName: string): ConfigurationService => {
  let config: TenantConfig | undefined;
  let error: Error | undefined;
  // TODO: Figure out a better way of handling this.
  // Fetching UI config is the first thing that the application does, so we can mark successful login session here.
  const request = axios
    .get(`${appConfigPath}?appName=${appName}`)
    .then((res) => {
      const raw = res.data.data;
      return validateConfDefn(raw);
    })
    .then((tenant) => {
      config = tenant;
    })
    .catch((e) => {
      let error = e;
      if (e.response && e.response.status === 401) {
        const query = QueryString.stringify({ redirectTo: window.location.href });
        window.location.href = `${API_URL_PREFIX}/login?${query}`;
      } else {
        // config validation error occurred
        error = e.message;
      }
      return Promise.reject(error);
    });

  function assertReady(): TenantConfig {
    if (error) {
      throw error;
    }
    if (!config) {
      throw new Error('Configuration has not been received yet.');
    }
    return config;
  }

  function whenDone(): Promise<void> {
    return request.then<void>(() => undefined);
  }

  function getPerspectives(): BoundPerspective[] {
    return bindPerspectives(assertReady());
  }

  function getBindings(perspective: BoundPerspective): BoundTenant {
    const conf = assertReady();
    return bindTenant(perspective)(conf);
  }

  function getTimeout(): number {
    return assertReady().idleTimeout * 1000 * 60;
  }

  return {
    whenDone,
    getPerspectives,
    getBindings,
    getTimeout,
    getOrThrow: assertReady,
  };
};
