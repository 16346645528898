import React from 'react';
import _, { memoize, get } from 'lodash';
import fp from 'lodash/fp';
import { AutoSizer, Grid, GridCellProps } from 'react-virtualized';
import { Size } from 'react-virtualized/dist/es/AutoSizer';
import { classes } from 'typestyle';

import { Overlay } from 'src/common-ui';

import Renderer from 'src/utils/Domain/Renderer';
import Subheader from 'src/components/Subheader/Subheader.container';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { ReduxSlice as SubheaderSlice, SubheaderViewDefns } from 'src/components/Subheader/Subheader.slice';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { formatSummaries } from 'src/utils/Pivot/RollUp';
import { GetSummaryOutput } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';

import { CardViewGroup, CardViewItem } from './UIData.types';
import { PrintProps } from '../higherOrder/Print/Print';
import { StandardCard, StandardCardProps } from './StandardCard/StandardCard';
import { styles } from './StandardCardView.styles';
import { StyleDetailsPopoverProps } from '../AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { isNil } from 'lodash';
import { HINDSIGHTING, ASSORTMENT_BUILD } from 'src/utils/Domain/Constants';
import { ViewConfiguratorModalProps } from '../ViewConfiguratorModal/ViewConfiguratorModal';
import { FabType, FabHandlerInvocable } from 'src/components/higherOrder/withFab';

import { noop } from 'react-select/lib/utils';
import { BackgroundDataLoadingProps } from '../BackgroundDataLoading/BackgroundDataLoading';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
import { NO_GROUPS_TYPE } from './CardViewDataProcessor';
import { RenderedGridCard } from 'src/common-ui/components/CardsGrid/CardsGrid';

type Props = StateProps & DispatchProps & PrintProps & StyleDetailsPopoverProps & FabHandlerInvocable;

type SubheaderPicks = 'groupBy' | 'sortBy';

export type StateProps = {
  title: string;
  hideTitle?: boolean;
  groupedStyles: CardViewGroup[];
  config: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  subheaderViewDefns: SubheaderViewDefns;
  showCountLimit?: boolean;
  loaded: boolean;
  summary: GetSummaryOutput[];
  idProp: string;
  descProp: string;
  fabType: FabType;
  fabTooltip?: string;
  isFabDisabled: boolean;
  subheaderCustomEl?: JSX.Element | undefined;
  showPopover?: boolean;
  currentTab?: string;
  downloadLink?: string;
  fabDefn?: string,
} & Pick<SubheaderSlice, SubheaderPicks> &
  BackgroundDataLoadingProps;

export type DispatchProps = {
  onShowView(): void;
  onItemClicked(item: ContainerPayload, eventTarget?: HTMLElement): void;
  showStylePane(item: ContainerPayload): void;
  onConfigUpdate(config: TenantConfigViewData): void;
};

class ColumnGroupedView extends React.Component<Props> {
  state: {
    groupScroll: number[];
    groupCells: JSX.Element | undefined;
  };
  inCleanup!: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      groupScroll: [],
      groupCells: undefined,
    };
    this.renderCards = this.renderCards.bind(this);
    this.render = this.render.bind(this);
    this.verticalGroupRender = this.verticalGroupRender.bind(this);
    this.getGridItems = memoize(this.getGridItems);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  onFabClick = () => {
    // currently no views with this component require cart/worklist handling
    noop();
  };

  static buildGroupheaders = (groups: CardViewGroup[], summary: GetSummaryOutput[]) => {
    const widthValue = `${100 / groups.length}%`;

    return groups.map((group, i) => (
      <hgroup key={i} style={{ width: widthValue }} className={classes(styles.groupHeader, styles.colGroupHeader)}>
        <h1>{group.header}</h1>
        <h2>
          {fp
            .map((calc) => {
              const lol = summary.find((item) => item.label === calc.title);
              let percentageText = '';
              if (calc.includePercentage && lol) {
                percentageText = ' (' + Renderer.percent(calc.raw / lol.raw, '0.00') + ')';
              }
              return calc.title + ': ' + calc.value + percentageText;
            }, group.groupCalcs)
            .join(' | ') || '\u00A0'}
        </h2>
      </hgroup>
    ));
  };

  static getRowList = (numCards: number, groups: CardViewGroup[]) => {
    const rowCount = Math.max(...groups.map((group) => Math.ceil(group.items.length / numCards)));
    return fp.range(0, rowCount).map((ind): CardViewItem[] => {
      return fp.flatten(
        groups.map((group) => {
          return fp.range(0, numCards).map((i) => fp.get(numCards * ind + i, group.items));
        })
      );
    });
  };

  verticalGroupRender({
    groups,
    cardWidth,
    cardHeight,
  }: {
    groups: CardViewGroup[];
    cardWidth: number;
    cardHeight: number;
  }) {
    const SCROLLBARWIDTH = 21;
    const minimumWidth = groups.length * cardWidth + SCROLLBARWIDTH;
    const { summary } = this.props;
    const numCardsPerColRow = (totalWidth: number) => Math.floor(totalWidth / groups.length / cardWidth);

    const cellRenderer = (
      rowList: CardViewItem[][],
      numCardsPerCol: number,
      { columnIndex, rowIndex, key, style, isScrolling, isVisible }: GridCellProps
    ) => {
      const itemFound = !isNil(rowList[rowIndex]) && !isNil(rowList[rowIndex][columnIndex]);
      // Potentially renders an intentional blank card if no item data is found at the current row/column position
      const item = itemFound ? rowList[rowIndex][columnIndex] : ({ isBlankCard: true } as CardViewItem);
      const className =
        (columnIndex + 1) % numCardsPerCol === 0 && columnIndex !== numCardsPerCol * groups.length - 1
          ? classes(styles.colCard, styles.colRightCard)
          : styles.colCard;

      return (
        <RenderedGridCard
          key={key}
          style={style}
          classNameOverride={className}
          isScrolling={isScrolling}
          isVisible={isVisible}
          cardType={'StandardCard'}
          cardProps={this.generateCardProps(item)}
        />
      );
    };

    const autoSizerContent = (size: Size) => {
      const { width, height } = size;
      if (width === 0 || height === 0) {
        return <div>Something went wrong</div>;
      }
      const cardsPerColRow = numCardsPerColRow(width);
      const cellRendererWithRows = fp.curry(cellRenderer)(
        ColumnGroupedView.getRowList(cardsPerColRow, groups),
        cardsPerColRow
      );
      const columnCount = groups.length * cardsPerColRow;
      const rowCount = Math.max(...groups.map((group) => Math.ceil(group.items.length / cardsPerColRow)));
      const TOTAL_GRID_WIIDTH = cardWidth * columnCount;
      const GRID_AND_SCROLLBAR_WIDTH = TOTAL_GRID_WIIDTH + SCROLLBARWIDTH;
      const limitHeight = height - SUBHEADER_HEIGHT;

      return (
        <React.Fragment>
          <article
            className={styles.columnGrouping}
            style={{
              width: TOTAL_GRID_WIIDTH,
              marginLeft: (width - GRID_AND_SCROLLBAR_WIDTH) / 2,
            }}
          >
            <header className={styles.groupsHeader}>{ColumnGroupedView.buildGroupheaders(groups, summary)}</header>
            <Grid
              cellRenderer={(cellRendererArgs: GridCellProps) => cellRendererWithRows(cellRendererArgs)}
              columnCount={columnCount}
              columnWidth={cardWidth}
              height={limitHeight}
              rowCount={rowCount}
              rowHeight={cardHeight + 15}
              width={GRID_AND_SCROLLBAR_WIDTH}
              overscanRowCount={3}
            />
          </article>
        </React.Fragment>
      );
    };

    return (
      <article
        className={styles.colGroupCont}
        style={{
          // Minimum width ensures we can always fit at least *one* of each column on the screen.
          // The +1 is arbitrary, there needs to be at least *some* amount wider than the contents
          // of the autosizer to prevent a second overflow attempt.
          minWidth: minimumWidth + 1,
        }}
      >
        {/*
                TODO: Width is needed so that the groupHeaders
                do not disappear on especific rezise.
              */}
        <AutoSizer style={{ width: 1 }}>{autoSizerContent}</AutoSizer>
      </article>
    );
  }

  // FIXME: cut down on code duplication/consolidate renderCard and generateCardProps as needed
  renderCard = (styleItem: CardViewItem) => {
    const { showStylePane, idProp, descProp, currentTab } = this.props;
    const name = descProp === 'description' ? styleItem.name : styleItem.styleName;

    // FIXME: currentTab is always undefined below
    // Hindsighting TY vs LY, both columns use Hindsighting Style Pane
    // Assortment Build TY vs LY, TY uses Assortment Build Style Pane, LY uses Hindsighting
    const stylePaneKey =
      styleItem.key === 'LY' || styleItem.key === 'LAST YEAR' || IS_HINDSIGHTING(currentTab)
        ? HINDSIGHTING
        : ASSORTMENT_BUILD;

    return (
      <StandardCard
        key={styleItem[idProp]}
        id={styleItem[idProp]}
        name={name}
        styleName={styleItem.styleName}
        styleId={styleItem.styleId}
        stars={styleItem.stars}
        value={styleItem.value}
        valueRenderer={styleItem.valueRenderer}
        description={styleItem[descProp]}
        styleDescription={styleItem.description}
        imgSrc={styleItem.imgSrc}
        columns={styleItem.columns}
        onMoreInfoClick={showStylePane}
        stylePaneKey={stylePaneKey}
        onItemClick={this.props.onItemClicked}
        departmentId={styleItem.departmentId}
        swatchIds={styleItem.swatchIds}
      />
    );
  };

  generateCardProps = (styleItem: CardViewItem): StandardCardProps => {
    const { showStylePane, idProp, descProp, currentTab } = this.props;
    const name = descProp === 'description' ? styleItem.name : styleItem.styleName;

    // FIXME: currentTab is always undefined below
    // Hindsighting TY vs LY, both columns use Hindsighting Style Pane
    // Assortment Build TY vs LY, TY uses Assortment Build Style Pane, LY uses Hindsighting
    const stylePaneKey =
      styleItem.key === 'LY' || styleItem.key === 'LAST YEAR' || IS_HINDSIGHTING(currentTab)
        ? HINDSIGHTING
        : ASSORTMENT_BUILD;

    const cardProps: StandardCardProps = {
      ...styleItem,
      id: styleItem[idProp],
      name,
      description: styleItem[descProp],
      styleDescription: styleItem.description,
      imgSrc: styleItem.imgSrc,
      onMoreInfoClick: showStylePane,
      stylePaneKey: stylePaneKey,
      onItemClick: this.props.onItemClicked,
    };

    return cardProps;
  };

  renderCards(items: CardViewItem[]) {
    const cards = items.map((item) => {
      const cardProps = this.generateCardProps(item);
      return <StandardCard key={item[this.props.idProp]} {...cardProps} />;
    });
    return cards;
  }

  getGridItems(children: CardViewItem[]) {
    return children.map(this.generateCardProps);
  }

  render() {
    const {
      title,
      hideTitle,
      loaded,
      groupedStyles,
      config,
      subheaderViewDefns,
      summary,
      showCountLimit,
      isPrintMode = false,
      printWidth,
      subheaderCustomEl,
      unmodifiedViewDefn,
    } = this.props;

    const renderAsGroup =
      groupedStyles.length > 1 || (groupedStyles.length > 0 && groupedStyles[0].header !== NO_GROUPS_TYPE);
    const visibleViewItems = get(config, 'view', []).filter((x) => x.visible !== false);
    const width = StandardCard.calcCardWidth(visibleViewItems.length);
    let sizing;
    if (renderAsGroup) {
      if (!isPrintMode) {
        sizing = this.verticalGroupRender({
          groups: groupedStyles,
          cardWidth: width,
          cardHeight: StandardCard.calcCardHeight(),
        });
      } else {
        // columnGroupedView print mode
        if (!config || !config.view) {
          sizing = <div />; // bail out
        } else {
          const groups = groupedStyles;

          // card stuff
          const configColumns = config.view.length;
          const cardsPerRow = printWidth !== '16.5in' ? 1 : 3;
          const cardWidth = StandardCard.calcCardWidth(configColumns);
          const cardHeight = StandardCard.calcCardHeight();
          const columnGroupedWidth = cardWidth * (3 * cardsPerRow) + 4;

          const cellRenderer = (card: CardViewItem) => {
            const cardStyle = {
              display: 'inline-block',
              verticalAlign: 'top',
              minWidth: cardWidth,
              minHeight: cardHeight,
            };

            return (
              <div style={cardStyle} key={_.uniqueId()} className={styles.colCard}>
                {!_.isUndefined(card) ? this.renderCard(card) : <div />}
              </div>
            );
          };

          const cardGroupsContainer = () => (
            <div className={styles.cardGroupsContainer} data-qa="ColumnGroupedViewGroupedGrid">
              {groups.map((group, idx, array) => (
                /* eslint-disable-next-line */
                <div className={`group-container ${idx !== array.length - 1 ? 'border-right' : ''}`}>
                  {group.items.map((card) => cellRenderer(card))}
                </div>
              ))}
            </div>
          );

          sizing = (
            <article className={styles.columnGrouping} style={{ width: columnGroupedWidth }}>
              <header className={styles.groupsHeader}>{ColumnGroupedView.buildGroupheaders(groups, summary)}</header>
              {cardGroupsContainer()}
            </article>
          );
        }
      }
    }

    const viewConfigurator: ViewConfiguratorModalProps = {
      viewConfig: config as TenantConfigViewData,
      unmodifiedViewDefn,
      updateConfig: this.props.onConfigUpdate,
    };

    return (
      <div data-qa-component="ColumnGroupedView" className={isPrintMode ? styles.inPrintMode : styles.mainContainer}>
        <Subheader
          title={title}
          hideTitle={hideTitle}
          groupByDefn={subheaderViewDefns.groupBy}
          sortByDefn={subheaderViewDefns.sortBy}
          countLimitDefn={subheaderViewDefns.countLimit}
          pareDownDefn={subheaderViewDefns.pareDown}
          showFlowStatus={true}
          showCountLimit={showCountLimit}
          summary={formatSummaries(summary)}
          showSearch={true}
          customEl={isNil(subheaderCustomEl) ? undefined : subheaderCustomEl}
          viewConfigurator={viewConfigurator}
          downloadLink={this.props.downloadLink}
          viewDataState={this.props.viewDataState}
        />
        <Overlay type="loading" visible={!loaded} />
        {loaded ? sizing : null}
      </div>
    );
  }
}

export default ColumnGroupedView;
