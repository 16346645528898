/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { GridReadyEvent, GridApi, ColDef, ValueFormatterParams, RowNode, ColumnApi } from 'ag-grid-community';
import { isNil, isEmpty, partial, hasIn, findIndex, isEqual, flatten } from 'lodash';

import Button from '@material-ui/core/Button';
import Overlay from 'src/common-ui/components/Overlay/Overlay';

import * as styles from './AssortmentPublish.styles';
import { AssortmentPublishProps, AssortmentPublishState, AssortmentPublishSelection } from './AssortmentPublish.types';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { PassedProps as SubheaderProps } from 'src/components/Subheader/Subheader';
import Subheader from 'src/components/Subheader/Subheader.container';
import { filterData } from 'src/utils/Pivot/Filter';
import { ASSORTMENT, externalGridSearchFields } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Renderer } from 'src/utils/Domain/Renderer';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { DataGridProps } from 'src/common-ui/components/DataGrid/DataGrid';
import { CustomNoRowsOverlay } from 'src/components/ConfigurableGrid/ConfigurableGrid';
import { getFrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { toast } from 'react-toastify';
import { WorklistService } from 'src/services/Worklist/Worklist.service';

import container from 'src/ServiceContainer';
import { equals } from 'lodash/fp';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { getValidMembers } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.client';

class AssortmentPublish extends React.Component<AssortmentPublishProps, AssortmentPublishState> {
  state: AssortmentPublishState;
  gridApi!: GridApi;
  columnApi!: ColumnApi;

  constructor(props: AssortmentPublishProps) {
    super(props);

    this.state = {
      selectedItems: [],
      notificationModal: false,
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentWillUnmount() {
    this.props.onDestroy();
  }

  componentDidUpdate(prevProps: AssortmentPublishProps) {
    if (!isEqual(prevProps.viewDefns.grid, this.props.viewDefns.grid) && this.gridApi) {
      this.gridApi.setColumnDefs(this.createColumnDefs());
    }
    // existence of props.dataApi is used here to magically detect that we're in allocation grid,
    // and not the assortment publish
    if (!equals(prevProps.data, this.props.data) && this.props.dataApi != null) {
      // NOTE: we don't save this to redux
      this.setState({
        selectedItems: this.props.data.map(this.basicPivotItemToAssortmentPublishSelection),
      });
    }
  }

  createColumnDefs(): ColDef[] {
    const { viewDefns } = this.props;
    const gridDefn = viewDefns.grid as TenantConfigViewData;
    return gridDefn.view
      .filter((colDef) => !colDef.hidden && colDef.visible !== false)
      .map(
        (colDef: TenantConfigViewItem): ColDef => {
          let extraProps = {};

          if (colDef.dataIndex === 'checkboxHeader') {
            extraProps = {
              width: 60,
              headerCheckboxSelection: true,
              checkboxSelection: true,
              headerClass: styles.headerCheckbox,
            };
          }

          return {
            headerName: colDef.text || '',
            field: colDef.dataIndex,
            pinned: colDef.pinned || false,
            valueFormatter: partial(this.handleValueFormatting, colDef),
            cellRenderer: colDef.renderer,
            ...extraProps,
          };
        }
      );
  }

  handleValueFormatting = (colDef: TenantConfigViewItem, params: ValueFormatterParams) => {
    if (!isNil(colDef.renderer) && hasIn(Renderer, colDef.renderer)) {
      return Renderer.renderJustValue(params.value, colDef);
    }

    return params.value;
  };

  rowNodeToAssortmentPublishSelection = (node: RowNode): AssortmentPublishSelection => {
    return {
      product: node.data.id,
      time: node.data.time,
    };
  };
  basicPivotItemToAssortmentPublishSelection = (item: BasicPivotItem): AssortmentPublishSelection => {
    return {
      product: item.id,
      time: item.time,
    };
  };

  handleCheck = () => {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const unfilteredNodes: RowNode[] = [];

    // this api requries a callback for to get all the nodes
    this.gridApi.forEachNodeAfterFilter((n) => unfilteredNodes.push(n));

    const unfilteredSelectedNodes = selectedNodes.filter((n) => {
      // cross reference the selected and unfiltered nodes
      // users don't expect nodes that are filtered out to remain in their selection
      const idx = findIndex(unfilteredNodes, (nn) => {
        return nn.id === n.id;
      });
      return idx > -1;
    });

    if (this.props.onItemSelection) {
      const data: BasicPivotItem[] = unfilteredSelectedNodes.map((node) => {
        return node.data;
      });
      this.props.onItemSelection(data);
    }

    this.setState({
      selectedItems: unfilteredSelectedNodes.map(this.rowNodeToAssortmentPublishSelection),
    });
  };

  sendItems = async () => {
    const { selectedItems, actionType } = this.state;

    if (isEmpty(selectedItems)) {
      return;
    }

    const update = this.state.selectedItems.map((item) => {
      return {
        ...item,
        ['dc_publish']: actionType === 'publish' ? 1 : 0,
      };
    });

    if (this.props.submitPayload) {
      const payload = {
        keyField: ['product', 'time'],
        create: [],
        update,
        delete: [],
      };
      if (this.props.dataApi && this.props.dataApi.isListData && !isEmpty(this.props.data)) {
        // this is a new code path added for allocation
        // an arbitrary pivot needs to be called on publish completion,
        // then the worklist needs to be cleared
        // in this case, it is presumed that all items are checked
        try {
          const dataApi = this.props.dataApi;
          await container.pivotService.listData(dataApi.defnId, ASSORTMENT, dataApi.params);

          const itemIds = this.props.data.map((i) => i.product);
          const validSizeMembers = await Promise.all(
            this.props.data.map(async (item) => {
              const validMemberData = await getValidMembers({
                member: 'stylecolorsize',
                parent: item.id,
              });
              return validMemberData.map((sz: { id: string }) => {
                return { product: sz.id, location: item.location, time: item.time };
              });
            })
          );
          const sizeIds = flatten(validSizeMembers);

          // doomed
          const payload: CoarseEditPayload = {
            coordinates: sizeIds,
            holdback: null,
            ecom_reserve: null,
            store_reserve: null,
          };
          await container.pivotService.coarseEditSubmitData(payload);

          const worklistService = WorklistService();
          await worklistService.removeItemsFromWorklist(itemIds);
        } catch (e) {
          toast.error('An error occured publishing your assortment');
        } finally {
          toast(<div>Your allocations have been published, clearing your worklist now</div>, {
            position: toast.POSITION.TOP_RIGHT,
            type: 'info',
            toastId: 'clear-worklist',
          });
          this.setState({
            notificationModal: false,
            selectedItems: [],
          });
        }
        return;
      }
      this.props.submitPayload(payload).then(() => {
        this.props.updateAssortmentPlan();
        this.setState({
          notificationModal: false,
          selectedItems: [],
        });
      });
    }
  };

  renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={this.state.notificationModal}
        descriptionText={`Are you sure you wish to ${this.state.actionType}?`}
        onConfirm={() => {
          this.sendItems();
          this.gridApi.deselectAll();
        }}
        onCancel={() => {
          this.gridApi.deselectAll();
          this.setState({
            notificationModal: false,
          });
        }}
      />
    );
  };

  render() {
    if (this.props.isDataLoading || this.props.isConfigLoading) {
      return <Overlay type="loading" visible={true} />;
    }

    const {
      title,
      data,
      summary,
      subheader,
      subheaderErrorText,
      viewDefns,
      viewDataState,
      onUpdateConfig,
    } = this.props;
    const { selectedItems } = this.state;
    const viewConfiguratorProps = viewDefns.unmodifiedViewDefn && {
      unmodifiedViewDefn: viewDefns.unmodifiedViewDefn,
      viewConfig: viewDefns.grid,
      updateConfig: onUpdateConfig,
      isGrid: true,
      getColumnApi: () => {
        if (this.columnApi) {
          return this.columnApi;
        }
        return;
      },
    };
    const subheaderProps: SubheaderProps = {
      title,
      showFlowStatus: true,
      showSearch: true,
      errorCondition: subheaderErrorText,
      viewConfigurator: viewConfiguratorProps,
      viewDataState,
    };

    const frameworkComponents = getFrameworkComponents();

    const filteredData = isEmpty(data)
      ? []
      : filterData(data, subheader.search, externalGridSearchFields, subheader.flowStatus);

    const gridProps: DataGridProps = {
      loaded: true,
      className: 'publishGrid',
      data: filteredData,
      columnDefs: this.createColumnDefs(),
      onGridReady: (params: GridReadyEvent) => {
        if (!isNil(params.api) && !isNil(params.columnApi)) {
          this.gridApi = params.api;
          this.columnApi = params.columnApi;
        }
      },
      exportOptions: {
        fileName: title,
      },
      frameworkComponents: frameworkComponents,
      extraAgGridProps: {
        onFilterChanged: () => {
          this.handleCheck();
        },
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        icons: {
          checkboxChecked: '<i class="far fa-check-square" />',
          checkboxUnchecked: '<i class="far fa-square" />',
          checkboxIndeterminate: '<i class="far fa-circle" />',
          sortAscending: '<i class="fas fa-lg fa-sort-down"></i>',
          sortDescending: '<i class="fas fa-lg fa-sort-up"></i>',
          filter: '<i class="fas fa-filter"></i>',
        },
        onSelectionChanged: this.handleCheck,
      },
    };

    return (
      <div className={styles.viewContainer}>
        <Subheader {...subheaderProps} />
        <div className={styles.gridDetails}>
          <div>
            <span className={styles.gridCount}>Count: {gridProps.data.length}</span>
            <span className={styles.gridCount}>Selected: {selectedItems.length}</span>
          </div>
          <div>
            <span>{summary}</span>
          </div>
        </div>
        <div className={styles.gridWrapper}>
          {isEmpty(filteredData) ? <CustomNoRowsOverlay /> : <ExtendedDataGrid {...gridProps} />}
        </div>
        <div className={styles.actionsContainer}>
          {this.props.dataApi == null ? (
            <Button
              onClick={() =>
                this.setState({
                  actionType: 'unpublish',
                  notificationModal: true,
                })
              }
              disabled={isEmpty(selectedItems)}
              className={styles.actionButton}
              disableFocusRipple={true}
            >
              Unpublish
            </Button>
          ) : null}
          <Button
            onClick={() =>
              this.setState({
                actionType: 'publish',
                notificationModal: true,
              })
            }
            disabled={isEmpty(selectedItems)}
            className={styles.actionButton}
            disableFocusRipple={true}
          >
            Publish
          </Button>
        </div>
        {this.renderConfirmationModal()}
      </div>
    );
  }
}

export default makeScopeAndFilterSensitive(makePrintSensitive(AssortmentPublish));
