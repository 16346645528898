import { connect } from 'react-redux';
import { AnyAction as BaseAction } from 'redux';
import { isNil, get } from 'lodash';

import { OwnProps, AssortmentPublishStateToProps } from './AssortmentPublish.types';
import AssortmentPublish from './AssortmentPublish';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  assortmentPublishCleanup,
  updateSelections,
  fetchConfigs,
  fetchAssortmentPublishData,
  receivedAssortmentPublishConfig,
} from './AssortmentPublish.slice';
import { submitPayload } from '../FlowSheet/FlowSheetByStyle/FlowSheetByStyle.slice';
import { SubmitPayload, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { toSummaries, formatSummaries } from 'src/utils/Pivot/RollUp';
import { update } from 'src/services/lenses/Lenses.actions';
import { planTrackerLens } from 'src/services/lenses/lenses';
import { updateAssortmentPlan } from '../StyleEdit/StyleEdit.client';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { DefnProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import {
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';

export function getAssortmentPublishData(defns: DefnProps) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const { dataApi, model: modelDefn } = defns;
    const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

    const flowStatus = getState().subheader.flowStatus.join(',');
    // options don't need to aggBy because this view needs the specific configured value in dataApi.params
    const options: ExtraPivotOptions = { flowStatus };
    const finalOptions = organizeListDataOptions(options, dataApi);

    return dispatch(fetchAssortmentPublishData(finalModelDefn, finalOptions));
  };
}

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps) {
  const { defns } = ownProps;
  const factory = {
    async submitPayload(payload: SubmitPayload) {
      await dispatch(submitPayload(payload));
      factory.onRefetchData();
    },
    async updateAssortmentPlan() {
      const updatePlan = async () => {
        dispatch(
          update(
            planTrackerLens.set({
              assortmentPlanInProgress: true,
              shouldCheckAssortment: false,
              askForRefresh: false,
            }),
            'ASST_PLAN_STARTED'
          )
        );
        return await updateAssortmentPlan();
      };
      await updatePlan().then(() => {
        dispatch(
          update(
            planTrackerLens.set({
              assortmentPlanInProgress: false,
              shouldCheckAssortment: false,
              askForRefresh: true,
            }),
            'ASST_PLAN_ENDED'
          )
        );
      });
    },
    onShowView() {
      dispatch(
        async (_dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
          await fetchConfigs(defns)(_dispatch);
          factory.onRefetchData();
        }
      ); // internally fetches data
    },
    onRefetchData() {
      dispatch(getAssortmentPublishData(defns));
    },
    onItemSelection(data: BasicPivotItem[]) {
      dispatch(updateSelections(data));
    },
    onDestroy() {
      dispatch(assortmentPublishCleanup());
    },
    onUpdate() {
      dispatch(
        (_dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
          const viewDefns = getState().pages.assortmentBuild.assortmentPublish.viewDefns;
          const flowStatus = getState().subheader.flowStatus.join(',');
          const modelDefn = get(viewDefns, 'grid.dataApi.defnId');
          const aggBy = get(viewDefns, 'grid.dataApi.params.aggBy');
          return dispatch(fetchAssortmentPublishData(modelDefn, { aggBy, flowStatus }));
        }
      );
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(
        receivedAssortmentPublishConfig({
          grid: config,
        })
      );
    },
  };
  return factory;
}

function mapStateToProps(state: AppState, ownProps: OwnProps): AssortmentPublishStateToProps {
  const { title = 'Assortment Publish', keys } = ownProps;
  const { subheader } = state;
  const {
    viewDefns,
    configsLoading,
    viewDataState,
    selectedItemsData,
    favoritesList,
  } = state.pages.assortmentBuild.assortmentPublish;
  let formattedSummary = '';
  if (!isNil(viewDefns) && viewDefns.rollup) {
    const summary = toSummaries(selectedItemsData, viewDefns.rollup.view, keys.idProp);
    formattedSummary = formatSummaries(summary);
  }
  const data = getDataFromCache(state.pages.assortmentBuild.assortmentPublish)?.tree || [];
  return {
    title,
    isDataLoading: viewDataState === ViewDataState.regularDataLoading,
    isConfigLoading: configsLoading,
    data,
    viewDefns: viewDefns!,
    subheader,
    summary: formattedSummary,
    favoritesList,
    viewDataState,
  };
}

export default connect(mapStateToProps, dispatchToProps)(AssortmentPublish);
