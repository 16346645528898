import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/common-ui/theme';

export default {
  wrapper: style({
    width: '100%',
    height: '100%',
  }),
  cropContainer: style({
    height: 500,
    width: '100%',
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }),
  sliderContainer: style({
    width: '80%',
    position: 'relative',
    top: 40,
    margin: '0 auto',
    textAlign: 'center',
  }),
  sliderText: style({
    color: TEAL_PRIMARY,
    position: 'relative',
    top: -20,
  }),
};
