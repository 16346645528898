import { CancelToken } from 'axios';

export type AppType = 'Assortment' | 'TDAnalysis';
export const TOP_DOWN_IDENTIFIER = 'TD_';
export const TOP_DOWN: AppType = 'TDAnalysis';

export type BasicItem = {
  id: string;
  description: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type BasicPivotItem = BasicItem & {
  '@path': string;
  children: BasicPivotItem[];
  image: string;
  attributevaluerank: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type ObjectDeserializer = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deserialize(responseBody: string): Promise<any[]>;
};

export type PivotQuery = {
  defnId: string;
  appName: AppType;
  groupBy: string;
  sortBy: string;
  flowStatus: string;
  cancelToken?: CancelToken;
};
export type ListFitViewOptions = {
  [key: string]: string;
} & Omit<PivotQuery, 'defnId' | 'appName'>;

export type HistoryStylePaneQuery = {
  defnId: string;
  appName: AppType;
  memberId: string;
};

export const lookBackPeriodProp = 'ytd_key';
export type ListDataPivotQuery = {
  defnId: string;
  appName: AppType;
  sortBy?: string;
  aggBy?: string;
  floorset?: string;
  channel?: string;
  department?: string;
  flowStatus?: string;
  topMembers?: string;
  [lookBackPeriodProp]?: string;
  nestData?: boolean;
};

export type ValidMembersQuery = {
  appName: AppType;
  level: string;
  parent?: string;
};

export type RegionItem = BasicItem & {
  index: number;
  root: boolean;
  ordered: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dimension: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shortestPathToRoot: Record<string, any>;
  levelId: string;
};

export type AuthorizedMembersQuery = ValidMembersQuery & { parent: string };

export interface Response<T> {
  data: T;
  success: boolean;
  info: PayloadObject;
}

export enum OverTimeType {
  flowsheet,
  pricing,
}

export type FlowSheetByIndexQuery = {
  defnId: string;
  appName: AppType;
  memberIds: string[];
  start: string;
  end: string;
  //sortBy=product%2Ctime
};

export type OverTimeQuery = {
  defnId: string;
  appName: AppType;
  groupBy?: string;
  sortBy?: string;
  aggBy?: string;
  memberId: string;
};

export type TimeQuery = {
  appName: AppType;
  parentLevel: string;
};

export type TimeEntry = {
  id: string;
  indx: number;
  name: string;
  description: string;
  levelId: string;
  readOnly: boolean;
  children: TimeEntry[];
};

export interface TimeSheetInfo {
  entries: TimeEntry[];
  planCurrent: string;
}

export interface WorklistInfo {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id: string;
  type: string;
  product: string;
}

export type SubmitPayload = {
  keyField: string[];
  create: PayloadObject[];
  update: PayloadObject[];
  delete: PayloadObject[];
};

export type PayloadObject = {
  [key: string]: PayloadObject[] | PayloadObject | number | boolean | string | null;
};

export type TreePivot = BasicPivotItem[];
export type FlatPivot = BasicPivotItem[];

export type ListDataOptions = {
  ignoreAncestors?: boolean;
  sortBy?: string;
  flowStatus?: string;
  aggBy?: string;
  topMembers?: string;
  [lookBackPeriodProp]?: string;
  nestData?: boolean;
  floorset?: string;
  channel?: string;
  department?: string;
};

export type FitViewOptions = {
  groupBy?: string;
  sortBy?: string;
  flowStatus?: string;
};

export type Pivot = {
  flat: FlatPivot;
  tree: TreePivot;
};

export type BasicChildren = {
  children: BasicChildren[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
