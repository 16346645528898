import { RouteComponentProps } from 'react-router-dom';

import { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { CompanionListConfig } from 'src/components/CompanionList/CompanionList.types';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { Summary } from 'src/utils/Pivot/RollUp';
import { FlowStatus } from 'src/services/configuration/bindings.types';
import { StylePreviewFilters } from 'src/components/StylePreview/StylePreview.types';
import { ValidationLevel } from './StyleEdit.client';
import { ScrollTo } from 'src/common-ui/components/DataGrid/DataGrid';
import { StyleEditComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { dispatchToProps } from './StyleEdit.container';
import { z } from 'zod';
import { BackgroundDataLoadingProps } from 'src/components/BackgroundDataLoading/BackgroundDataLoading';
import { FabType } from 'src/components/higherOrder/withFab';

export type StyleEditConfig = {
  title: string;
  searchIndexes: string[];
  sectionsViewDefns: string[];
  companionView: CompanionListConfig;
};

export type StyleEditOwnProps = z.infer<typeof StyleEditComponentProps> & RouteComponentProps;

export type StyleEditValueProps = {
  title: string;
  isPrintMode: boolean;
  isLoading: boolean;
  config: StyleEditConfig | Record<string, never>;
  flowStatusOptions: FlowStatus;
  summary: Summary[];
  styles: BasicPivotItem[];
  redirectSelection: BasicPivotItem | undefined;
  searchKeys: string[];
  companionFilters: Pick<SubheaderSlice, 'search' | 'flowStatus'>;
  previewFilters: StylePreviewFilters;
  fabType: FabType;
} & BackgroundDataLoadingProps;

export type StyleEditDispatchProps = ReturnType<typeof dispatchToProps>;

export type StyleEditProps = StyleEditValueProps & StyleEditDispatchProps;

export type StyleEditState = {
  selectedCompanionItem: ListViewable | undefined;
  scrollTo: ScrollTo | undefined;
};

export type StyleEditStyleInfo = {
  id: string;
  description: string;
  imgUri: string;
  starRating: number;
};

// TODO: This should be moved to a more...generic location
export const ViewApiConfigZod = z.object({
  url: z.string(),
  params: z.optional(z.optional(z.record(z.string()))),
  headers: z.optional(z.optional(z.record(z.string()))),
  isListData: z.optional(z.nullable(z.literal(false))),
  clientHandler: z.optional(z.string()),
  clientHandlerParams: z.optional(z.string()),
});

export const ListDataApiZod = z.object({
  defnId: z.string(),
  params: z.optional(z.optional(z.record(z.string()))),
  isListData: z.literal(true),
});

export type ViewApiConfig = z.infer<typeof ViewApiConfigZod>;
export type ListDataConfig = z.infer<typeof ListDataApiZod>;
export const DataApiZod = z.union([ViewApiConfigZod, ListDataApiZod]);
export type DataApiConfig = z.infer<typeof DataApiZod>;

export type UIViewApiConfig = UIViewApiConfigv2 | UIViewApiConfigv1;

export type UIViewApiConfigv1 = ViewApiConfig & {
  type: 'viewdefnv1';
  // there are actually two types in here: client handled and old api style
  // "old api style" is actually getting deprecated.
};

export type UIViewApiConfigv2 = {
  type: 'viewdefnv2'; // allow migration
  defnId: string;
  allowCache: boolean;
};

export function isListDataConfig(config: ViewApiConfig | ListDataConfig): config is ListDataConfig {
  return (config as ListDataConfig).defnId !== undefined;
}

export type CustomInputAttributes = {
  whitelist: string;
  maxLength: number;
  validationLevel?: ValidationLevel;
  invertValidationResponse?: boolean;
  min?: number;
  max?: number;
  nullable?: boolean;
};

export type OverviewSection = {
  primary: {
    dataIndex: string;
    inputParams: CustomInputAttributes;
  };
  secondary: {
    dataIndex: string;
    inputParams: CustomInputAttributes;
  };
  image: {
    dataIndex: string;
  };
};

export type StyleEditViewSection = {
  text: string;
  componentType: 'column_form' | 'column_grid' | 'column_multi_grid';
  dataApi: ViewApiConfig | ListDataConfig;
  configApi: UIViewApiConfig;
  dependentsApi?: ViewApiConfig;
};

export type StyleEditViewMultiSection = {
  text: string;
  componentType: 'column_grid';
  config: any;
  data: any[];
};

export type StyleEditConfigItem = {
  dataIndex: string;
  xtype: string;
};

export type StyleEditAction = ViewApiConfig;

export type StyleEditSection = {
  xtype: string;
  actions?: StyleEditAction[];
  columns: StyleEditConfigItem[];
  text?: string;
};
