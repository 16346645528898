import { z } from 'zod';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Option } from 'src/components/Configure/ConfigureModal';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { get, cloneDeep, isNil } from 'lodash';
import { FlowStatus } from 'src/services/configuration/bindings.types';
import { FavoriteResponseItem } from './Favorites/FavoritesMenu';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ServiceContainer from 'src/ServiceContainer';
import { SubheaderDefnProps } from 'src/services/configuration/codecs/confdefnComponentProps';

export type SubheaderViewDefns = z.infer<typeof SubheaderDefnProps>;

export type SubheaderDefns = {
  groupBy: DropdownConfigViewData;
  sortBy: DropdownConfigViewData;
  countLimit: DropdownConfigViewData;
  pareDown: DropdownConfigViewData;
};

// FIXME: do we need to include extra props here if only manipulating groupByOptions?
export type OverLoadSubheaderProps = {
  title: 'Grid View' | 'Quick Snapshot';
  showFlowStatus: true;
  showSearch: true;
  groupByOptions?: DropdownConfigViewData;
};

export type SubheaderDropdownSlice = {
  defaultSelection: number;
  selection: number | null;
  options: TenantConfigViewItem[];
  refreshOnChange?: boolean;
};

export type DropdownConfigViewData = TenantConfigViewData & {
  hideEmptyRow?: boolean;
  refreshOnChange?: boolean;
};

export type GroupBySlice = SubheaderDropdownSlice;

export type SortBySlice = SubheaderDropdownSlice & {
  direction: 'asc' | 'desc';
};

export type PareDownSlice = {
  defaultSelections: TenantConfigViewItem[];
  selections: TenantConfigViewItem[];
  options: TenantConfigViewItem[];
};

export type ReduxSlice = {
  title?: string;
  showFlowStatus?: true;
  showSearch?: true;
  search: string;
  altSearch: string;
  groupBy: GroupBySlice;
  groupByDefn?: TenantConfigViewData;
  sortBy: SortBySlice;
  sortByDefn?: TenantConfigViewData;
  pareDown: PareDownSlice;
  pareDownDefn?: TenantConfigViewData;
  lookBackPeriod: string;
  countLimit?: number;
  countLimitOptions?: number[];
  countLimitDefault?: number;
  subheaderLoading: boolean;
  configureSelections?: Option[];
  flowStatus: number[];
  flowStatusConfig?: FlowStatus;
  altFlowStatus: number[];
  favoritesList?: FavoriteResponseItem[];
};
export type SubheaderSlice = ReduxSlice;
const emptyItem = {
  dataIndex: '',
  text: '',
  dimension: 'product',
};

export const initialGroupBy = {
  defaultSelection: 0,
  selection: null,
  options: [emptyItem],
};

export const initialSortBy = {
  defaultSelection: 0,
  selection: null,
  direction: 'desc' as const,
  options: [emptyItem],
};

export const initialPareDown = {
  defaultSelections: [],
  selections: [],
  options: [emptyItem],
};

const initialState: ReduxSlice = {
  search: '',
  altSearch: '',
  groupBy: initialGroupBy,
  sortBy: initialSortBy,
  pareDown: initialPareDown,
  lookBackPeriod: 'YTD',
  flowStatus: [],
  altFlowStatus: [],
  subheaderLoading: false,
  flowStatusConfig: {} as FlowStatus,
  favoritesList: [],
};

export const emptySubheaderState = cloneDeep(initialState);

export function _handleDropdownExtras(
  ddSlice: SubheaderDropdownSlice,
  viewDefn: DropdownConfigViewData,
  currentSelection: TenantConfigViewItem | null
) {
  ddSlice.options = viewDefn.view;
  if (viewDefn.hideEmptyRow !== true) {
    ddSlice.options.unshift(emptyItem);
  }
  if (viewDefn.default) {
    const defaultSelection = ddSlice.options.findIndex((opt) => opt.dataIndex === viewDefn.default);
    ddSlice.defaultSelection = defaultSelection;
  }
  let newSelection = -1;
  if (!isNil(currentSelection)) {
    newSelection = ddSlice.options.findIndex((opt) => opt.text === currentSelection.text);
  }
  if (newSelection >= 0) {
    ddSlice.selection = newSelection;
  } else {
    ddSlice.selection = null;
  }
  if (viewDefn.refreshOnChange) {
    ddSlice.refreshOnChange = viewDefn.refreshOnChange;
  }
  return ddSlice;
}

const subheaderSlice = createSlice({
  name: 'subheader',
  initialState: initialState,
  reducers: {
    requestViewDefns: (state, _action: PayloadAction<string[]>) => {
      // this action requires a payload, but it is used in various containers,
      // and isn't meant to go into state
      state.subheaderLoading = true;
    },
    receiveViewDefns: (state, action: PayloadAction<SubheaderDefns>) => {
      const defns = action.payload;
      let groupBy: GroupBySlice = cloneDeep(initialGroupBy);

      const groupByDefn = defns.groupBy;

      if (groupByDefn) {
        const curSel = get(state.groupBy.options, `[${state.groupBy.selection}]`);
        groupBy = {
          ...state.groupBy,
          ..._handleDropdownExtras(groupBy, groupByDefn, curSel),
        };
      }
      let sortBy: SortBySlice = cloneDeep(initialSortBy);

      if (defns.sortBy) {
        const curSel = get(state.sortBy.options, `[${state.sortBy.selection}]`);
        const sortByDefn = defns.sortBy;
        sortBy = {
          ...state.sortBy,
          direction: 'desc' as const,
          ..._handleDropdownExtras(sortBy, sortByDefn, curSel),
        };
      }

      let pareDown: PareDownSlice = cloneDeep(initialPareDown);

      if (defns.pareDown) {
        const defaultText = (defns.pareDown as any).default || [];
        const pareDownDefn = defns.pareDown;
        const selections = pareDownDefn.view.filter((x) => defaultText.indexOf(x.text) != -1);
        pareDown = {
          ...state.pareDown,
          selections,
          defaultSelections: selections,
          options: pareDownDefn.view,
        };
      }

      let countLimitOptions;
      let countLimit;

      if (defns.countLimit) {
        countLimitOptions = defns.countLimit.options;
        // If there is already a count limit selected and it's available in limit options, keep it
        if (state.countLimit && countLimitOptions && countLimitOptions.includes(state.countLimit)) {
          countLimit = state.countLimit;
        } else {
          countLimit = defns.countLimit.default as number;
        }
      }
      return {
        ...state,
        groupBy,
        sortBy,
        countLimit,
        countLimitOptions,
        countLimitDefault: defns.countLimit ? (defns.countLimit.default as number) : 0,
        subheaderLoading: false,
        groupByDefn: defns.groupBy,
        sortByDefn: defns.sortBy,
        pareDownDefn: defns.pareDown,
        pareDown,
      };
    },
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateAlternateSearch: (state, action: PayloadAction<string>) => {
      state.altSearch = action.payload;
    },
    updateConfigureSelections: (state, action: PayloadAction<Option[]>) => {
      state.configureSelections = action.payload;
    },
    updateFlowStatus: (state, action: PayloadAction<number[]>) => {
      state.flowStatus = action.payload;
    },
    updateFlowStatusConfig: (state, action: PayloadAction<FlowStatus>) => {
      state.flowStatusConfig = action.payload;
    },
    updateAlternateFlowStatus: (state, action: PayloadAction<number[]>) => {
      state.altFlowStatus = action.payload;
    },
    updateLookBackPeriod: (state, action: PayloadAction<string>) => {
      state.lookBackPeriod = action.payload;
    },
    updateGroupBy: (state, action: PayloadAction<number | null>) => {
      state.groupBy = {
        ...state.groupBy,
        selection: action.payload,
      };
    },
    // If we want to update groupBy but not have our epics know
    updateGroupByNoDataTrigger: (state, action: PayloadAction<number | null>) => {
      state.groupBy = {
        ...state.groupBy,
        selection: action.payload,
      };
    },
    updateSortBy: (state, action: PayloadAction<number | null>) => {
      state.sortBy = {
        ...state.sortBy,
        selection: action.payload,
      };
    },
    updatePareDown: (state, action: PayloadAction<TenantConfigViewItem[]>) => {
      state.pareDown = {
        ...state.pareDown,
        selections: action.payload,
      };
    },
    updateCountLimit: (state, action: PayloadAction<number>) => {
      state.countLimit = action.payload;
    },
    updateFavoritesList: (state, action: PayloadAction<FavoriteResponseItem[]>) => {
      state.favoritesList = action.payload;
    },
    updateSortByDirection: (state) => {
      const sortByDir: SortBySlice = initialSortBy;

      if (state.sortBy.direction === ('asc' as const)) {
        sortByDir.direction = 'desc' as const;
      } else {
        sortByDir.direction = 'asc' as const;
      }
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          direction: sortByDir.direction,
        },
      };
    },
    maybeUpdateSortByDirection: (state, action: PayloadAction<string>) => {
      const sortByDir: SortBySlice = state.sortBy;
      if (state.sortBy.direction !== action.payload) {
        if (state.sortBy.direction === ('asc' as const)) {
          sortByDir.direction = 'desc' as const;
        } else {
          sortByDir.direction = 'asc' as const;
        }
      }
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          direction: sortByDir.direction,
        },
      };
    },
    // TODO: The only logic that is needed in this action is the hideEmptyRow logic,
    // everything else (title, boolean values) are not relevant.
    // This should be moved to the receiveViewDefns action handler.
    // For more details see https://s5stratosdev.atlassian.net/browse/INT-1436
    overloadSubheader: (state, action: PayloadAction<OverLoadSubheaderProps>) => {
      const { groupByOptions, ...newProps } = action.payload;

      let options: TenantConfigViewItem[];
      if (groupByOptions != null) {
        options = groupByOptions?.hideEmptyRow ? [...groupByOptions?.view] : [emptyItem, ...groupByOptions.view];
      } else {
        options = [];
      }

      return {
        ...state,
        ...newProps,
        groupBy: {
          ...state.groupBy,
          options,
        },
      };
    },
    receiveError: (state, action: PayloadAction<string>) => {
      // mild side effects here
      ServiceContainer.loggingService.error(`An error occured in the subehader: ${action.payload}`);
      return initialState;
    },
    cleanUp: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActivePage, (state) => {
      state.configureSelections = undefined;
    });
  },
});

export const {
  requestViewDefns,
  receiveViewDefns,
  updateSearch,
  updateAlternateSearch,
  updateConfigureSelections,
  updateFlowStatus,
  updateFlowStatusConfig,
  updateAlternateFlowStatus,
  updateLookBackPeriod,
  updateGroupBy,
  updateSortBy,
  updatePareDown,
  updateCountLimit,
  updateFavoritesList,
  updateSortByDirection,
  maybeUpdateSortByDirection,
  overloadSubheader,
  receiveError,
  cleanUp,
  updateGroupByNoDataTrigger,
} = subheaderSlice.actions;

export default subheaderSlice.reducer;
