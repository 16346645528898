import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { createAction } from './ActionTyping';
import { BasicPivotItem, RegionItem } from 'src/worker/pivotWorker.types';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';

export const REQUEST_VIEW_CONFIG = 'MacroMix_REQUEST_VIEW_CONFIG';
export const requestViewConfig = createAction(REQUEST_VIEW_CONFIG);

// TODO: Hope something like (this)[https://github.com/Microsoft/TypeScript/pull/26349] gets
// accepted to allow inferring the action type. (Check in on typescript 3.1)
export const RECEIVE_VIEW_CONFIG = 'MacroMix_RECEIVE_VIEW_CONFIG';
export const receiveViewConfig = createAction<typeof RECEIVE_VIEW_CONFIG, TenantConfigViewData>(RECEIVE_VIEW_CONFIG);

export const RECEIVE_VIEW_CONFIG_ERROR = 'MacroMix_RECEIVE_VIEW_CONFIG_ERROR';
export const receiveViewConfigError = createAction(RECEIVE_VIEW_CONFIG_ERROR);

export const REQUEST_MIX_DATA = 'MacroMix_REQUEST_MIX_DATA';
export const requestMixData = createAction(REQUEST_MIX_DATA);
export const RECEIVE_MIX_DATA = 'MacroMix_RECEIVE_MIX_DATA';
export const receiveMixData = createAction<
  typeof RECEIVE_MIX_DATA,
  {
    listData: BasicPivotItem[];
    regions: RegionItem[];
  }
>(RECEIVE_MIX_DATA);

export const REQUEST_CHART_DATA = 'MacroMix_REQUEST_CHART_DATA';
export const requestMixChartData = createAction(REQUEST_CHART_DATA);
export const RECEIVE_CHART_DATA = 'MacroMix_RECEIVE_CHART_DATA';
export const receiveMixChartData = createAction<typeof RECEIVE_CHART_DATA, BasicPivotItem[]>(RECEIVE_CHART_DATA);

export const SELECT_BOX = 'MacroMix_SELECT_BOX';
export const selectBox = createAction<typeof SELECT_BOX, ExtendedPointObject>(SELECT_BOX);

export const RECEIVE_MIX_DATA_ERROR = 'MacroMix_RECEIVE_MIX_DATA_ERROR';
export const receiveMixDataError = createAction(RECEIVE_MIX_DATA_ERROR);

export const UPDATE_LEVELS = 'MacroMix_UPDATE_LEVELS';
export const updateLevels = createAction<typeof UPDATE_LEVELS, TenantConfigViewItem>(UPDATE_LEVELS);

export const CLEAR_CHART_DATA = 'MacroMix_CLEAR_CHART_DATA';
export const clearChartData = createAction<typeof CLEAR_CHART_DATA>(CLEAR_CHART_DATA);

export type Action =
  | ReturnType<typeof requestViewConfig>
  | ReturnType<typeof receiveViewConfig>
  | ReturnType<typeof receiveViewConfigError>
  | ReturnType<typeof requestMixData>
  | ReturnType<typeof receiveMixData>
  | ReturnType<typeof requestMixChartData>
  | ReturnType<typeof receiveMixChartData>
  | ReturnType<typeof selectBox>
  | ReturnType<typeof receiveMixDataError>
  | ReturnType<typeof updateLevels>
  | ReturnType<typeof clearChartData>;
