import React, { useCallback } from 'react';
import { Link, Route } from 'react-router-dom';
import { RouteLink } from './../../types/RouteLink';
import { navItemText } from './TopNavbar.styles';

export const TopNavLink = (
  props: RouteLink & {
    fastNavOpen: boolean;
    onFastNavToggle: (tabName: string) => void;
  }
) => {
  const { name, link, highlightPrefix, disabled, fastNavOpen, onFastNavToggle } = props;

  const toggleFastNav = useCallback(() => {
    onFastNavToggle(name);
  }, [name, onFastNavToggle]);

  const rendered = disabled ? (
    <span className={`nav-link disabled ${navItemText}`}>{name}</span>
  ) : (
    <React.Fragment>
      <Link to={link} className={`nav-link ${navItemText}`}>
        {name}
      </Link>
      <button className={`nav-item-action-button fa fa-caret-${fastNavOpen ? 'down' : 'up'}`} onClick={toggleFastNav} />
    </React.Fragment>
  );

  /* eslint-disable react/no-children-prop */
  return (
    <Route
      path={highlightPrefix}
      children={({ match }) => <div className={`nav-item${(match && ' bg-primary active') || ''}`}>{rendered}</div>}
    />
  );
};
