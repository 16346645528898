import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { MassEditConfig } from 'src/components/MassEdit/MassEdit';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { cacheCheckFetchPivotData } from '../StyleColorReview.slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewDataState } from 'src/types/Domain';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';

export type GridViewConfig = {
  grid: TenantConfigViewData;
  listSort: TenantConfigViewData;
  subheaderRollUp: TenantConfigViewData;
  listLevelBy?: TenantConfigViewData; // use for PricingOverTime companion view levelBy
  list: TenantConfigViewData;
  graphs?: TenantConfigViewData;
  summary?: TenantConfigViewData;
  massEdit?: MassEditConfig;
  unmodifiedViewDefn: TenantConfigViewData;
  identityPropsConfig?: GridViewIdentityPropsConfig;
};

type TenantResponse = {
  grid: TenantConfigViewData;
  graphs?: TenantConfigViewData;
  listSort?: TenantConfigViewData;
  subheaderGroup?: TenantConfigViewData;
  subheaderRollUp?: TenantConfigViewData;
  list?: TenantConfigViewData;
  massEdit?: MassEditConfig;
  unmodifiedViewDefn?: TenantConfigViewData;
};

export type GridViewIdentityPropsConfig = Pick<IdentityPropsConfig, 'id'>;

export type GridViewSlice = {
  viewDefns: GridViewConfig;
  isConfigLoading: boolean;
  viewDataState: ViewDataState;
  cacheHash: string | null;
};

const initialState: GridViewSlice = {
  cacheHash: null,
  isConfigLoading: false,
  viewDefns: {
    grid: {} as TenantConfigViewData,
    listSort: {} as TenantConfigViewData,
    listLevelBy: {} as TenantConfigViewData,
    subheaderRollUp: {} as TenantConfigViewData,
    list: {} as TenantConfigViewData,
    massEdit: {} as MassEditConfig,
    unmodifiedViewDefn: {} as TenantConfigViewData,
    identityPropsConfig: {} as GridViewIdentityPropsConfig,
  },
  viewDataState: ViewDataState.idle,
};

const gridViewReducer = createSlice({
  name: 'GridView',
  initialState,
  reducers: {
    requestGridViewConfig(state) {
      state.isConfigLoading = true;
    },
    receiveGridViewConfig(state, action: PayloadAction<GridViewConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveSomeGridViewConfig(state, action: PayloadAction<TenantResponse>) {
      state.isConfigLoading = false;
      state.viewDefns.grid = {
        ...state.viewDefns.grid,
        ...action.payload.grid,
      };
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    receiveError() {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestGridViewConfig,
  receiveGridViewConfig,
  receiveSomeGridViewConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
  cleanUp,
} = gridViewReducer.actions;

export function fetchGridViewData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData,
    true
  );
}

export default gridViewReducer.reducer;
