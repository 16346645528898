import React from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import Axios from 'src/services/axios';
import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FlowSheetGrid } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/FlowSheetGrid';
import {
  PricingGrid,
  PricingGridProps,
  ComponentTypeGridDispatchProps,
} from 'src/pages/AssortmentBuild/Pricing/PricingGrid';
import {
  fetchOverTimeData,
  receivePricingEvents,
  receivePricingOverTimeConfig,
  submitPayload as submitPricingPayload,
  cleanUp as cleanupPricing,
} from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';
import { PricingEventResponse } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.types';
import { ASSORTMENT, ASSORTMENT_BUILD_FILTER_WARNING } from 'src/utils/Domain/Constants';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { convertPivotItemsToRowData } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/util';
import { RowData, FlowSheetGridProps } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/types';
import { SubmitPayload } from 'src/worker/pivotWorker.types';
import {
  fetchOverTimeData as fetchFlowSheetOverTimeData,
  receiveFlowSheetByStyleConfig,
  submitPayload as submitFlowSheetPayload,
  cleanUp as cleanupFlowsheet,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/FlowSheetByStyle.slice';
import { dispatchUpdateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import Subheader from 'src/components/Subheader/Subheader.container';
import { AutoSizer } from 'react-virtualized';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';

type WrapperOwnProps = {
  selectedItemId: string;
  viewDefns: string[];
  model?: string;
};
const stub = {} as TenantConfigViewData;

export function mapStateToPropsPricing(state: AppState, ownProps: WrapperOwnProps): PricingGridProps {
  const { pages, scope, subheader } = state;
  const viewState = pages.assortmentBuild.pricing;
  const scopeStart = !isNil(scope.scope.start) ? scope.scope.start : undefined;
  const { events, viewDataStateOverTime, isConfigLoading, viewDefns } = viewState;

  const overTimeData = getUniqueDataFromCache(viewState, HashType.pricingOverTime)?.tree || [];
  const isLiveDataReady = viewDataStateOverTime === ViewDataState.regularDataReady;
  let rowData: RowData[] = [];
  if (isLiveDataReady && overTimeData && viewDefns && viewDefns.grid) {
    const requiredKeys = ['addoff', 'eo', 'eff_aur', 'corpaddoff', 'corpexcl', 'cccurp', 'ccdiscountpct'];
    rowData = convertPivotItemsToRowData(overTimeData, viewDefns.grid.view, requiredKeys, 'stylecolor', 'week');
  }

  return {
    loading: isConfigLoading || !isLiveDataReady,
    timeEntries: scope.timeInfo.entries,
    rowData,
    anchorField: scopeStart,
    events,
    rowHeight: viewDefns?.grid.main?.rowHeight,
    itemId: ownProps.selectedItemId || '',
    editable: isLiveDataReady,
    ...subheader,
  };
}

export function dispatchToPropsPricing(
  dispatch: AppThunkDispatch,
  ownProps: WrapperOwnProps
): ComponentTypeGridDispatchProps {
  return {
    onRenderGrid: async () => {
      const configResponse = await container.tenantConfigClient.getTenantViewDefns({
        defnIds: ownProps.viewDefns,
        appName: ASSORTMENT,
      });

      // have to remap views to view property for access in grid data formatting function
      const { views, ...remainingObj } = configResponse[0];
      configResponse[0] = {
        ...remainingObj,
        view: !isNil(views) ? views : [],
      };

      dispatch(
        receivePricingOverTimeConfig({
          grid: configResponse[0],
          listSort: stub,
          subheaderRollUp: stub,
          list: stub,
          unmodifiedViewDefn: stub,
        })
      );
      const eventResponse = await Axios.get<PricingEventResponse>(`/api/v2/events?appName=${ASSORTMENT}`);
      dispatch(receivePricingEvents(eventResponse.data.data));
      dispatch(fetchOverTimeData(ownProps.selectedItemId, ownProps.model));
    },
    onCompanionItemChange() {
      dispatch(fetchOverTimeData(ownProps.selectedItemId, ownProps.model));
    },
    submitPayload: (payload: SubmitPayload) => {
      dispatch(submitPricingPayload(payload));
    },
    onCleanup: () => {
      dispatch(cleanupPricing());
    },
  };
}

export function mapStateToPropsFlowSheet(state: AppState, ownProps: WrapperOwnProps): FlowSheetGridProps {
  const { pages, scope } = state;
  const viewState = pages.assortmentBuild.flowSheet;
  const scopeStart = !isNil(scope.scope.start) ? scope.scope.start : undefined;
  const { isConfigLoading, viewDefns } = viewState;

  const overTimeData = getUniqueDataFromCache(viewState, HashType.flowsheetOverTime)?.tree || [];
  const loaded = isDataLoaded(viewState.viewDataStateOverTime);
  const isLiveDataReady = viewState.viewDataStateOverTime === ViewDataState.regularDataReady;

  let rowData: RowData[] = [];
  if (loaded && overTimeData && viewDefns && viewDefns.grid) {
    rowData = convertPivotItemsToRowData(overTimeData, viewDefns.grid.view);
  }

  return {
    loading: isConfigLoading || !loaded,
    timeEntries: scope.timeInfo.entries,
    rowData,
    anchorField: scopeStart,
    rowHeight: viewDefns?.grid.main?.rowHeight,
    itemId: ownProps.selectedItemId || '',
    editable: isLiveDataReady,
  };
}

export function dispatchToPropsFlowSheet(
  dispatch: AppThunkDispatch,
  ownProps: WrapperOwnProps
): ComponentTypeGridDispatchProps {
  const dispatchHandlers = {
    onRenderGrid: async () => {
      const configResponse = await container.tenantConfigClient.getTenantViewDefns({
        defnIds: ownProps.viewDefns,
        appName: ASSORTMENT,
      });

      // have to remap views to view property for access in grid data formatting function
      const { views, ...remainingObj } = configResponse[0];
      configResponse[0] = {
        ...remainingObj,
        view: !isNil(views) ? views : [],
      };

      dispatch(
        receiveFlowSheetByStyleConfig({
          grid: configResponse[0],
          listSort: stub,
          subheaderRollUp: stub,
          list: stub,
          unmodifiedViewDefn: stub,
        })
      );
      dispatch(fetchFlowSheetOverTimeData(ownProps.selectedItemId, ownProps.model));
    },
    onCompanionItemChange() {
      dispatch(fetchFlowSheetOverTimeData(ownProps.selectedItemId, ownProps.model));
    },
    submitPayload: (payload: SubmitPayload) => {
      dispatch(submitFlowSheetPayload(payload, dispatchHandlers.updateAssortmentPlan));
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
    onCleanup: () => {
      dispatch(cleanupFlowsheet());
    },
  };

  return dispatchHandlers;
}

type GridWithSubheaderProps = PricingGridProps & {
  subheaderSummary?: string;
};
const WRAPPER_SUBHEADER_HEIGHT = 94;

const PricingGridWithSubheader = ({ subheaderSummary, ...restProps }: GridWithSubheaderProps) => {
  return (
    <React.Fragment>
      <AutoSizer style={{ height: '100%', width: '100%' }}>
        {({ height }) => {
          return (
            <React.Fragment>
              <Subheader
                errorCondition={ASSORTMENT_BUILD_FILTER_WARNING}
                // search is rendered in the companion view already
                showSearch={false}
                showFlowStatus={true}
                summary={subheaderSummary}
              />
              {/* Height needs to be explicit for grid.  */}
              <div style={{ height: height - WRAPPER_SUBHEADER_HEIGHT }}>
                <PricingGrid {...restProps} />
              </div>
            </React.Fragment>
          );
        }}
      </AutoSizer>
    </React.Fragment>
  );
};

const FlowSheetGridWithSubheader = ({ subheaderSummary, ...restProps }: GridWithSubheaderProps) => {
  return (
    <React.Fragment>
      <AutoSizer style={{ height: `100%`, width: '100%' }}>
        {({ height }) => {
          return (
            <React.Fragment>
              <Subheader
                errorCondition={ASSORTMENT_BUILD_FILTER_WARNING}
                // search is rendered in the companion view already
                showSearch={false}
                showFlowStatus={true}
                summary={subheaderSummary}
              />
              {/* Height needs to be explicit for grid.  */}
              <div style={{ height: height - WRAPPER_SUBHEADER_HEIGHT }}>
                <FlowSheetGrid {...restProps} />
              </div>
            </React.Fragment>
          );
        }}
      </AutoSizer>
    </React.Fragment>
  );
};

export const TabbedPricingGrid = connect(
  mapStateToPropsPricing,
  dispatchToPropsPricing
  // @ts-ignore
)(makeScopeAndFilterSensitive(makePopoverSensitive(PricingGridWithSubheader)));

export const TabbedFlowSheetGrid = connect(
  mapStateToPropsFlowSheet,
  dispatchToPropsFlowSheet
  // @ts-ignore
)(makeScopeAndFilterSensitive(makePopoverSensitive(FlowSheetGridWithSubheader)));
