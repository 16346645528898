import { cloneDeep } from 'lodash';
import { find } from 'lodash/fp';
import { Lens } from 'monocle-ts';
import { connect } from 'react-redux';
import { update } from 'src/services/lenses/Lenses.actions';
import {
  createTargetKey,
  getLYData,
  getMFPData,
  upsertTargetItem,
  getTargetItem,
} from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.client';

import { getTargetInitialInfo, TargetType, TargetVersion } from '../Criteria/Criteria.client';
import { CriteriaOption, CriteriaReduxSlice } from '../Criteria/Criteria.types';
import { seedOptions, seedPlan } from '../Criteria/Option.mocks';
import { PeriodFormReduxSlice } from '../DatePicking/PeriodForm.types';
import { TargetRequest } from '../TargetSetting/TargetSetting.types';
import { TargetCreation } from './TargetCreation';
import {
  FunctionProps,
  OwnProps,
  ValueProps,
  TargetSettingNewSlice,
  TargetCreationViewDefns,
} from './TargetCreation.types';
import container from 'src/ServiceContainer';
import { getRangeLists } from 'src/dao/scopeClient';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import _ from 'lodash';
import { FloorsetReduxSlice } from './FloorsetSelector';
import { AppDispatch, AppState, AppThunkDispatch } from 'src/store';
import serviceContainer from 'src/ServiceContainer';
import { scopeLens } from 'src/services/lenses/lenses';
import { Scope } from 'src/types/Scope';

function mapStateToProps(state: AppState, ownProps: OwnProps): ValueProps {
  const { seedPage } = ownProps.lens.get(state),
    targetReceiptLens = Lens.fromProp<TargetSettingNewSlice>()('targetReceipt'),
    targetSalesLens = Lens.fromProp<TargetSettingNewSlice>()('targetSales'),
    lyReceiptLens = Lens.fromProp<TargetSettingNewSlice>()('lyReceipt'),
    lySalesLens = Lens.fromProp<TargetSettingNewSlice>()('lySales'),
    topLevelCriteria = Lens.fromProp<TargetSettingNewSlice>()('topLevelCriteria'),
    targetLevelCriteria = Lens.fromProp<TargetSettingNewSlice>()('targetLevelCriteria'),
    startWeek = Lens.fromProp<PeriodFormReduxSlice>()('selectedStartWeek'),
    selections = Lens.fromProp<CriteriaReduxSlice>()('selections'),
    floorset = Lens.fromProp<TargetSettingNewSlice>()('targetSelectedFloorset');

  return {
    amount: seedPage.amount,
    showSeedWeeks: seedPage.weeks.loaded,
    targetReceiptStartWeek: ownProps.lens
      .compose(targetReceiptLens)
      .compose(startWeek)
      .get(state),
    targetSalesStartWeek: ownProps.lens
      .compose(targetSalesLens)
      .compose(startWeek)
      .get(state),
    lyReceiptStartWeek: ownProps.lens
      .compose(lyReceiptLens)
      .compose(startWeek)
      .get(state),
    lySalesStartWeek: ownProps.lens
      .compose(lySalesLens)
      .compose(startWeek)
      .get(state),
    topLevelSelections: ownProps.lens
      .compose(topLevelCriteria)
      .compose(selections)
      .get(state),
    targetLevelSelections: ownProps.lens
      .compose(targetLevelCriteria)
      .compose(selections)
      .get(state),
    selectedFloorset: ownProps.lens.compose(floorset).get(state),
  };
}

function getOptionsFromDataIndex(dataIndex: string, availOptions: { dataIndex: string; options: CriteriaOption[] }[]) {
  const options = find((option) => {
    return option.dataIndex === dataIndex;
  }, availOptions);
  if (options == null) {
    return [] as CriteriaOption[];
  } else {
    return options.options;
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): FunctionProps {
  const { lens } = ownProps;
  const client = container.tenantConfigClient;

  const factory = {
    onShowView: () => {
      dispatch(async (innerDispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const viewDefns =
          ownProps.targetType === 'Product' ? ['StrategyProductCriteria'] : ['StrategyLocationCriteria'];
        const { daysRangeList, daysPastRangeList, rangeList, pastRangeList } = await getRangeLists();
        const loadedViewConf = (
          await client.getTenantViewDefns<TargetCreationViewDefns>({
            defnIds: viewDefns,
            appName: ASSORTMENT,
          })
        )[0];
        const scopeProduct =
          scopeLens.compose(Lens.fromProps<Scope>()(['productMember'])).get(state).productMember || '';
        const floorsetConfig = await serviceContainer.scopeClient.getFloorsets(scopeProduct);
        const lyFloorsetConfig = await serviceContainer.scopeClient.getLyFloorsets(scopeProduct);

        const selectedDates = lens
          .compose(Lens.fromProps<TargetSettingNewSlice>()(['lySelectedDates', 'targetSelectedDates']))
          .get(state);
        let selectedTargetReceipt, selectedTargetSales, selectedLyReceipt, selectedLySales;
        if (selectedDates.targetSelectedDates.receipt) {
          selectedTargetReceipt = selectedDates.targetSelectedDates.receipt;
          selectedTargetSales = selectedDates.targetSelectedDates.sales;
        }
        if (selectedDates.lySelectedDates.receipt) {
          selectedLyReceipt = selectedDates.lySelectedDates.receipt;
          selectedLySales = selectedDates.lySelectedDates.sales;
        }

        innerDispatch(
          update(
            lens
              .compose(
                Lens.fromProps<TargetSettingNewSlice>()([
                  'targetReceipt',
                  'targetSales',
                  'lyReceipt',
                  'lySales',
                  'topLevelCriteria',
                  'targetLevelCriteria',
                  'apiRoot',
                  'lyFloorsetConfig',
                  'floorsetConfig',
                ])
              )
              .set({
                targetReceipt: {
                  loaded: true,
                  rangeList: rangeList,
                  daysRangeList,
                  selectedStartWeek: selectedTargetReceipt ? selectedTargetReceipt.start : undefined,
                  selectedEndWeek: selectedTargetReceipt ? selectedTargetReceipt.end : undefined,
                },
                targetSales: {
                  loaded: true,
                  rangeList: rangeList,
                  daysRangeList,
                  selectedStartWeek: selectedTargetSales ? selectedTargetSales.start : undefined,
                  selectedEndWeek: selectedTargetSales ? selectedTargetSales.end : undefined,
                },
                lyReceipt: {
                  loaded: true,
                  rangeList: pastRangeList,
                  daysRangeList: daysPastRangeList,
                  selectedStartWeek: selectedLyReceipt ? selectedLyReceipt.start : undefined,
                  selectedEndWeek: selectedLyReceipt ? selectedLyReceipt.end : undefined,
                },
                lySales: {
                  loaded: true,
                  rangeList: pastRangeList,
                  daysRangeList: daysPastRangeList,
                  selectedStartWeek: selectedLySales ? selectedLySales.start : undefined,
                  selectedEndWeek: selectedLySales ? selectedLySales.end : undefined,
                },
                topLevelCriteria: {
                  selections: [],
                  options: [loadedViewConf.topLevelOptions],
                },
                targetLevelCriteria: {
                  selections: [],
                  options: [loadedViewConf.targetLevelOptions],
                },
                apiRoot: loadedViewConf.apiRoot,
                lyFloorsetConfig,
                floorsetConfig,
              }),
            'Load Target Creation Data'
          )
        );
        innerDispatch(
          update(
            lens.compose(Lens.fromPath<TargetSettingNewSlice>()(['seedPage', 'criteria'])).set({
              selections: [],
              options: [seedPlan],
            })
          )
        );
      });
    },
    voidFloorsetDropdown: (floorsetLens: Lens<AppState, FloorsetReduxSlice>) => {
      dispatch(async (innerDispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const setterLens = floorsetLens.asSetter();
        const newSelectedFloorset: FloorsetReduxSlice = cloneDeep(floorsetLens.get(state));

        newSelectedFloorset.selectedFloorset = '';
        innerDispatch(update(setterLens.set(newSelectedFloorset)));
      });
    },
    getSeedOptions: (dataIndex: string) => {
      const setterLens = lens.compose(Lens.fromPath<TargetSettingNewSlice>()(['seedPage', 'weeks'])).asSetter();
      const prevDataLens = lens.compose(Lens.fromPath<TargetSettingNewSlice>()(['seedPage', 'weeks']));
      if (dataIndex === 'userdef') {
        dispatch((_innerDispatch: AppDispatch, getState: () => AppState) => {
          const state = getState();
          const { rangeList, daysRangeList } = prevDataLens.get(state);
          dispatch(
            update(
              setterLens.set({
                loaded: false,
                rangeList,
                daysRangeList,
              }),
              'TargetCreation Seed: Clearing week information.'
            )
          );
        });
      } else {
        dispatch(async (innerDispatch: AppDispatch, getState: () => AppState) => {
          const state = getState();
          let newSetup: PeriodFormReduxSlice;
          if (dataIndex === 'ly') {
            newSetup = cloneDeep(ownProps.lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lySales')).get(state));
          } else if (dataIndex === 'mfp') {
            newSetup = cloneDeep(
              ownProps.lens.compose(Lens.fromProp<TargetSettingNewSlice>()('targetSales')).get(state)
            );
          } else {
            return;
          }
          innerDispatch(update(setterLens.set(newSetup), 'TargetCreation Seed: Updating seed/week selection.'));
        });
      }
      return getOptionsFromDataIndex(dataIndex, seedOptions);
    },

    setConfigurations: (targetType: TargetType) => {
      dispatch((_innerDispatch: AppDispatch, getState: () => AppState) => {
        // TODO: here is where we execute our `create target` based on selections
        const state = getState();
        const newSlice = ownProps.lens.get(state);
        let setup: TargetRequest;
        const arraySelections: any = newSlice.targetLevelCriteria.selections;
        if (
          newSlice.targetReceipt.loaded &&
          newSlice.targetSales.loaded &&
          newSlice.lyReceipt.loaded &&
          newSlice.lySales.loaded
        ) {
          setup = {
            receiptStart: newSlice.targetReceipt.selectedStartWeek,
            receiptEnd: newSlice.targetReceipt.selectedEndWeek,
            salesStart: newSlice.targetSales.selectedStartWeek,
            salesEnd: newSlice.targetSales.selectedEndWeek,
            lyReceiptStart: newSlice.lyReceipt.selectedStartWeek,
            lyReceiptEnd: newSlice.lyReceipt.selectedEndWeek,
            lySalesStart: newSlice.lySales.selectedStartWeek,
            lySalesEnd: newSlice.lySales.selectedEndWeek,
            dimension: targetType.toLowerCase() as 'product' | 'location',
            topCriteria: newSlice.topLevelCriteria.selections[0].dataIndex,
            topCriteriaName: newSlice.topLevelCriteria.selections[0].text,
            topCriteriaValue: newSlice.topLevelCriteria.selections[1].dataIndex,
            topCriteriaValueName: newSlice.topLevelCriteria.selections[1].text,
            criteria: newSlice.targetLevelCriteria.selections[0].dataIndex,
            criteriaName: newSlice.targetLevelCriteria.selections[0].text,
            criteriaValues: arraySelections[1].map((it: CriteriaOption) => it.dataIndex),
            seedName: newSlice.seedPage.criteria.selections[0].text,
            seedBasisName: newSlice.seedPage.criteria.selections[1].text,
            criteriaValuesNames: arraySelections[1].map((it: CriteriaOption) => it.text),
            floorSet: newSlice.targetSelectedFloorset.selectedFloorset,
          };
          if (newSlice.seedPage.weeks.loaded) {
            setup.seedStart = newSlice.seedPage.weeks.selectedStartWeek;
            setup.seedEnd = newSlice.seedPage.weeks.selectedEndWeek;
          }
        }
        async function createTarget() {
          const key = createTargetKey({
            floorset: newSlice.targetSelectedFloorset,
            topLevel: newSlice.topLevelCriteria,
            targetLevel: newSlice.targetLevelCriteria,
          });
          // tslint:disable-next-line:max-line-length
          const targetApi = `${newSlice.apiRoot},${newSlice.targetLevelCriteria.selections[0].api}:${newSlice.targetLevelCriteria.selections[0].dataIndex}`;
          const initialTargetInfo = await getTargetInitialInfo(setup, targetType, targetApi);
          let seedValue;
          const seedVer = newSlice.seedPage.criteria.selections[0].dataIndex;
          if (seedVer === 'mfp') {
            seedValue = await getMFPData(setup, targetType);
          } else if (seedVer === 'ly') {
            seedValue = await getLYData(setup, targetType);
          } else {
            seedValue = newSlice.seedPage.amount;
          }
          await upsertTargetItem({
            type: targetType,
            version: 'WP',
            id: key,
            data: {
              rowData: initialTargetInfo.rowData,
              shouldInitialize: false,
              targetSetup: setup,
              seedValue,
            },
          });
          return key;
        }
        createTarget().then((resp) => {
          window.location.hash = `${window.location.hash}/${resp}/WP`;
        });
      });
    },
    updateAmount: (value: number | undefined) => {
      const amountSetter = ownProps.lens.compose(Lens.fromPath<TargetSettingNewSlice>()(['seedPage', 'amount']));
      dispatch(update(amountSetter.set(value), 'Updating Seed Amount'));
    },
    checkIfTargetExists: async (id: string, version: TargetVersion, type: TargetType): Promise<boolean | string> => {
      return getTargetItem({
        id: id,
        version,
        type,
      }).then((resp) => {
        if (_.isNil(resp)) {
          return false;
        } else {
          return 'Target already exists. Would you like to overwrite it?';
        }
      });
    },
  };
  return factory;
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetCreation);
