import * as React from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import styles from './ControlPopper.styles';

export type PopperControl = {
  onClick: () => void;
  icon: string;
  text: string;
  color: string;
};

export interface ControlPopperProps {
  anchorRef: Element;
  controls: PopperControl[];
  open: boolean;
}

export default function ControlPopper(props: ControlPopperProps) {
  const { open, controls, anchorRef } = props;

  const anchorElement = anchorRef;

  const renderedControls = controls.map((control, idx) => {
    return (
      <div key={idx} className={styles.control} onClick={control.onClick}>
        <div className={styles.controlItem}>
          <div className={styles.iconCircle} style={{ backgroundColor: 'transparent' }}>
            <i className={control.icon} />
          </div>
          <span className={styles.itemText}>{control.text}</span>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.container} data-qa="ControlPopper">
      <Popper open={open} anchorEl={anchorElement}>
        {({}) => {
          return (
            <Paper className={styles.paper}>
              <div className={styles.highlightArrow} style={{ display: open ? 'block' : 'none' }} />
              <div className={styles.highlightArrowInner} style={{ display: open ? 'block' : 'none' }} />
              {renderedControls}
            </Paper>
          );
        }}
      </Popper>
    </div>
  );
}
