import React from 'react';
import { SubmitPayload } from 'src/worker/pivotWorker.types';
import { Option } from 'src/components/Configure/ConfigureModal';
import { GridMeasureDefn, State } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/types';
import {
  companionDataParse,
  convertPivotItemsToRowData,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/util';
import { Props as CompanionProps, SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { companionStyles, listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import Subheader from 'src/components/Subheader/Subheader.container';
import CompanionListView from 'src/common-ui/components/CompanionListView/CompanionListView';
import { resolvePath } from 'src/cdn';
import { PricingGrid, PricingGridProps } from 'src/pages/AssortmentBuild/Pricing/PricingGrid';
import { isNil, forEach, findKey } from 'lodash';
import { MassEdit, MassEditSubmissionData } from 'src/components/MassEdit/MassEdit';
import { ASSORTMENT_BUILD_FILTER_WARNING, CACHED_DATA_EDITING_DISABLED_WARNING } from 'src/utils/Domain/Constants';
import { FabType } from 'src/components/higherOrder/withFab';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { Props, WeekData } from './PricingOverTime.types';
const noImage = resolvePath(noImagePath);

export class PricingOverTime extends React.Component<Props, State> {
  private _isUnMounted = false;
  protected pGrid!: PricingGrid | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      companionCollapsed: false,
      companionSortDirection: 'desc',
      configureIsOpen: false,
      companionData: [],
      uniqueItems: props.uniqueItems,
    };
  }

  static getDerivedStateFromProps(newProps: Props, currentState: State): State {
    const { uniqueItems, companionDataLookup, defaultCompanionSortField } = newProps;

    const {
      companionSortDirection,
      companionSortField = defaultCompanionSortField && defaultCompanionSortField.dataIndex,
      companionLevelField,
    } = currentState;

    if (uniqueItems !== currentState.uniqueItems) {
      const lookup = companionDataLookup;
      const items = uniqueItems;
      const direction = companionSortDirection;
      const field = companionSortField;
      const companionData = companionDataParse(items, lookup, direction, field, companionLevelField);
      return {
        ...currentState,
        companionData,
        companionSortField,
        uniqueItems,
      };
    }
    return {
      ...currentState,
      companionSortField,
      uniqueItems,
    };
  }

  componentDidMount() {
    if (this._isUnMounted) {
      return;
    }
    this.props.onShowView();
  }

  componentDidUpdate() {
    if (
      this.state.companionData.length &&
      (!this.state.selectedItem || isNil(findKey(this.state.companionData, { id: this.state.selectedItem })))
    ) {
      const selectedItem = this.state.companionData[0].id;
      if (this.props.onCompanionItemClick) {
        this.props.onCompanionItemClick(selectedItem);
      }
      this.setState({
        selectedItem: selectedItem,
      });
    }
  }

  updateConfigureSelections(selections: Option[]) {
    if (this.props.updateConfigureSelections) {
      this.props.updateConfigureSelections(selections);
    }
  }

  componentWillUnmount() {
    this._isUnMounted = true;
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }

  rebuildCompanionData() {
    const companionData = companionDataParse(
      this.state.uniqueItems,
      this.props.companionDataLookup,
      this.state.companionSortDirection,
      this.state.companionSortField,
      this.state.companionLevelField
    );

    this.setState(
      {
        companionData,
      },
      () => {
        const selectedIndex = companionData.findIndex((datum) => datum.id === this.state.selectedItem);
        if (this.state.companionLevelField === 'member:subclass' && selectedIndex === -1) {
          this.onCompanionItemClick(companionData[0].id);
        }
      }
    );
  }

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  /*  Start Companion List Events */

  onCompanionItemClick = (itemId: string) => {
    if (this._isUnMounted) {
      return;
    }
    if (this.props.onCompanionItemClick) {
      this.props.onCompanionItemClick(itemId);
    }
    this.setState({ selectedItem: itemId });
  };

  onCompanionDirectionChange = (newDir: 'asc' | 'desc') => {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionSortDirection: newDir }, () => {
      this.rebuildCompanionData();
    });
  };

  onCompanionSortChange = (option: SortOption) => {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionSortField: option.dataIndex }, () => {
      this.rebuildCompanionData();
    });
  };

  onCompanionLevelChange = (option: SortOption) => {
    if (this._isUnMounted) {
      return;
    }
    console.log('setting companionLevelField to:', option.dataIndex);
    this.setState({ companionLevelField: option.dataIndex }, () => {
      this.rebuildCompanionData();
    });
  };

  onCompanionCollapseToggle = (collapse: boolean) => {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionCollapsed: collapse });
  };

  /* End Companion List Events */

  getGridRowData = () => {
    const { isOverTimeDataLoaded, overTimeData, viewDefns } = this.props;

    if (isOverTimeDataLoaded && overTimeData && viewDefns && viewDefns.grid) {
      const requiredKeys = ['addoff', 'eo', 'eff_aur', 'corpaddoff', 'corpexcl', 'cccurp', 'ccdiscountpct'];
      return convertPivotItemsToRowData(
        overTimeData,
        viewDefns.grid.view as GridMeasureDefn[],
        requiredKeys,
        'stylecolor',
        'week'
      );
    }
    return [];
  };

  onGridValueChange = (payload: SubmitPayload) => {
    this.props.submitPayload(payload);
  };

  handleMassEditSubmission = (submission: MassEditSubmissionData) => {
    const { selectedModifier, selectedItems, selectedWeekList, modifierValue } = submission;

    if (!isNil(selectedModifier) && !isNil(selectedWeekList)) {
      // no null submission data expected, except for modifier value
      const updatedData: WeekData[] = [];

      forEach(selectedWeekList, (weekNumber) => {
        const updatedWeekData = selectedItems.map((item) => {
          return {
            [selectedModifier!]: modifierValue,
            product: item.value, // styleColor id
            time: weekNumber,
          };
        });

        updatedData.push(...updatedWeekData);
      });

      if (this.props.submitPayload) {
        this.props.submitPayload({
          keyField: ['product', 'time'],
          create: [],
          update: updatedData,
          delete: [],
        });
      }

      // update grid data
      if (this.props.onRefetchData) {
        // argument value is disregarded here, I just didn't to mergeProps onRefetchData type here
        this.props.onRefetchData('');
      }
    }
  };

  render() {
    if (!this.props.loaded) {
      return (
        <div className={listPairStyle}>
          <Overlay type="loading" visible={true} />
        </div>
      );
    }
    const {
      title,
      viewDefns,
      allowFrom,
      allowTo,
      rangeList,
      scopeStart,
      massEditData,
      isPrintMode,
      sortOptions,
      isOverTimeDataLoaded,
      isMassEditDataLoaded,
      isLiveDataReady,
      viewDataStateCompanion,
      viewDataStateOverTime,
      onItemClicked,
    } = this.props;
    const { massEdit: massEditConfig } = viewDefns!;

    const companionData = this.state.companionData;
    const selectedItem = this.state.selectedItem;
    const defaultSort = this.state.companionSortField;

    const defaultSelection = sortOptions.findIndex((option) => option.dataIndex === defaultSort);
    const selectedIndex = companionData.findIndex((datum) => datum.id === selectedItem);

    const levelOptions = this.props.viewDefns?.listLevelBy?.view;
    const defaultLevelSelection = 0;

    const companionProps: CompanionProps = {
      defaultSelection: defaultSelection,
      defaultLevelSelection,
      sortOptions,
      levelOptions,
      label: 'Count',
      selectedIndex: selectedIndex,
      className: companionStyles,
      data: companionData,
      noImageUrl: noImage,
      dataLookup: this.props.companionDataLookup,
      initialSortDirection: this.state.companionSortDirection,
      onListItemClicked: this.onCompanionItemClick,
      onChangeDirection: this.onCompanionDirectionChange,
      onSortSelection: this.onCompanionSortChange,
      onToggleCollapse: this.onCompanionCollapseToggle,
      onLevelSelection: this.onCompanionLevelChange,
    };

    const pricingGridProps: PricingGridProps = {
      timeEntries: this.props.timeInfo.entries,
      rowData: this.getGridRowData(),
      rowHeight: this.props.rowHeight,
      anchorField: this.props.scopeStart || this.props.timeInfo.planCurrent,
      events: this.props.events,
      editable: this.props.isLiveDataReady,
      onValueChange: this.onGridValueChange,
      onItemClicked,
    };

    const itemsDataIndex = !isNil(massEditConfig) ? massEditConfig.views[1].dataIndex : '';
    const editableItems =
      massEditData &&
      massEditData.flat.map((dataItem) => {
        const label = `${dataItem['name']}-${dataItem[itemsDataIndex]}`;
        return {
          value: dataItem['id'],
          label,
        };
      });

    const errorCondition = [ASSORTMENT_BUILD_FILTER_WARNING];
    if (!isLiveDataReady) {
      errorCondition.push(CACHED_DATA_EDITING_DISABLED_WARNING);
    }
    return (
      <div className={listPairStyle}>
        <Subheader
          title={title}
          errorCondition={ASSORTMENT_BUILD_FILTER_WARNING}
          showSearch={true}
          showFlowStatus={true}
          summary={this.props.subheaderSummary}
          viewDataState={[viewDataStateCompanion, viewDataStateOverTime]}
        />
        <div className="data-container">
          {!isPrintMode && <CompanionListView {...companionProps} />}
          {!isOverTimeDataLoaded ? (
            <Overlay type="loading" visible={true} />
          ) : (
            <React.Fragment>
              <MassEdit
                config={massEditConfig}
                allowFrom={allowFrom}
                allowTo={allowTo}
                scopeStart={scopeStart || ''}
                rangeList={rangeList}
                editableItems={editableItems}
                title={'Mass Copy Pricing Events'}
                handleSubmit={this.handleMassEditSubmission}
                dataLoading={!isMassEditDataLoaded}
              />
              <PricingGrid ref={(el) => (this.pGrid = el)} {...pricingGridProps} />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
