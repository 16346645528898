import {
  Action,
  RECEIVE_SCOPE_CONFIG,
  REQUEST_SCOPE_CONFIG,
  RECEIVE_FLOORSET_CONFIG,
  CHANGE_SCOPE_SELECTION,
  RECEIVE_SCOPE,
  REQUEST_SCOPE,
  SEND_SCOPE,
  REQUEST_FLOORSET_CONFIG,
  VALIDATE_ALL_SELECTIONS,
  RECEIVE_SCOPE_ERROR,
  CHANGE_SCOPE,
  CLOSE_SCOPE,
  CLEAR_FLOORSET,
  GET_PLAN_DATES,
  RECEIVE_QUICKSELECTS,
  RESET_SCOPE,
  RECEIVE_SCOPE_REFRESH_TRIGGER,
} from './ScopeSelector.actions';
import {
  DaysRangeListResponse,
  DimensionConfigResponse,
  DimensionHierTreeResponse,
  FloorsetConfigResponse,
  RangeItem,
  Scope,
  ScopeSelections,
  SelectionErrors,
} from 'src/types/Scope';
import { DimensionMap } from 'src/components/ScopeSelector/ScopeSelector.selectors';
import { QuickSelect } from 'src/dao/scopeClient';
import { TimeSheetInfo } from 'src/worker/pivotWorker.types';

export const initialScope = {
  productLevel: null,
  productMember: null,
  productMemberName: null,
  locationLevel: null,
  locationMember: null,
  locationMemberName: null,
  floorSet: null,
  historyFloorset: null,
  start: null,
  end: null,
  startSales: null,
  endSales: null,
  historyStart: null,
  historyEnd: null,
  valid: false,
};

export type ScopeSelectorSlice = {
  initialLoad: boolean;
  updatedAt?: number;
  scopeConfig: Record<string, any>;
  scope: Scope;
  scopeLoading: boolean;
  floorsetLoading: boolean;
  scopeSelected: boolean;
  scopeError: boolean | string;
  selections: ScopeSelections;
  selectionErrors: SelectionErrors;
  selectionsValid: boolean;
  dimensionConfig: DimensionConfigResponse | null;
  dimensionHierConfig: DimensionHierTreeResponse | null;
  productDimension: DimensionMap | null;
  locationDimension: DimensionMap | null;
  currentProductLevel?: string;
  currentLocationLevel?: string;
  currentAssortmentStartWeek?: string;
  currentAssortmentEndWeek?: string;
  currentFloorSet?: string;
  floorsetConfig: FloorsetConfigResponse;
  quickSelects: QuickSelect[];
  lyFloorsetConfig: FloorsetConfigResponse;
  rangeList: RangeItem[];
  rangeListExtended: RangeItem[];
  pastRangeList: RangeItem[];
  daysRangeList: DaysRangeListResponse;
  daysRangeListExtended: DaysRangeListResponse;
  daysPastRangeList: DaysRangeListResponse;
  planCurrent: any;
  planStart: any;
  planEnd: any;
  timeInfo: TimeSheetInfo;
};

/* eslint-disable @typescript-eslint/naming-convention */
const initialState: ScopeSelectorSlice = {
  initialLoad: true,
  updatedAt: undefined,
  dimensionConfig: null,
  dimensionHierConfig: null,
  selections: initialScope,
  selectionErrors: {},
  selectionsValid: false,
  productDimension: null,
  locationDimension: null,
  scopeLoading: false,
  floorsetLoading: false,
  scopeSelected: false,
  scopeError: false,
  scopeConfig: {},
  scope: initialScope,
  floorsetConfig: [],
  quickSelects: [],
  lyFloorsetConfig: [],
  rangeList: [],
  rangeListExtended: [],
  pastRangeList: [],
  daysRangeList: {
    start_date: {},
    end_date: {},
  },
  daysRangeListExtended: {
    start_date: {},
    end_date: {},
  },
  daysPastRangeList: {
    start_date: {},
    end_date: {},
  },
  planCurrent: null,
  planStart: null,
  planEnd: null,
  timeInfo: {
    entries: [],
    planCurrent: '',
  },
};
/* eslint-enable @typescript-eslint/naming-convention */

export default function scopeSelectorReducer(
  state: ScopeSelectorSlice = initialState,
  action: Action
): ScopeSelectorSlice {
  switch (action.type) {
    case CHANGE_SCOPE: {
      return {
        ...state,
        scopeSelected: false,
      };
    }
    case CLOSE_SCOPE: {
      return {
        ...state,
        scopeSelected: true,
      };
    }
    case REQUEST_SCOPE_CONFIG: {
      return {
        ...state,
        initialLoad: false,
        scopeLoading: true,
      };
    }
    case RECEIVE_SCOPE_CONFIG: {
      const { dimensionConfig, daysList, pastDaysList, pastRangeList, rangeList, timeInfo } = action.scopeConfig;
      const { product, location } = dimensionConfig;
      const { scope } = state;
      const initialLevelScopeSelection = {
        productLevel: product.levelInfos.length === 1 ? product.levelInfos[0].level.id : scope.productLevel,
        locationLevel: location.levelInfos.length === 1 ? location.levelInfos[0].level.id : scope.locationLevel,
      };

      return {
        ...state,
        scopeLoading: false,
        rangeList,
        pastRangeList,
        rangeListExtended: action.scopeConfig.rangeListExtended,
        daysRangeList: daysList,
        daysPastRangeList: pastDaysList,
        daysRangeListExtended: action.scopeConfig.daysListExtended,
        dimensionConfig: dimensionConfig,
        dimensionHierConfig: action.scopeConfig.dimensionHierConfig,
        timeInfo,
        selections: {
          ...state.selections,
          ...initialLevelScopeSelection,
        },
      };
    }

    case CHANGE_SCOPE_SELECTION: {
      return {
        ...state,
        selections: {
          ...state.selections,
          ...action.selections,
        },
      };
    }

    case REQUEST_FLOORSET_CONFIG: {
      return {
        ...state,
        floorsetLoading: true,
      };
    }

    case CLEAR_FLOORSET: {
      return {
        ...state,
        floorsetLoading: false,
        floorsetConfig: [],
        selections: {
          ...state.selections,
          floorSet: null,
          historyFloorset: null,
        },
      };
    }

    case RECEIVE_SCOPE_ERROR: {
      return {
        ...state,
        scopeLoading: false,
        scopeError: `There was an issue retreiving either scope or scope config.`,
      };
    }

    case RECEIVE_FLOORSET_CONFIG: {
      const { floorsetConfig } = action;
      return {
        ...state,
        floorsetLoading: false,
        floorsetConfig,
      };
    }

    case RECEIVE_QUICKSELECTS: {
      const { quickSelects } = action;
      return {
        ...state,
        quickSelects,
      };
    }

    case REQUEST_SCOPE: {
      return {
        ...state,
        scopeLoading: true,
        scopeSelected: false,
      };
    }

    case RECEIVE_SCOPE_REFRESH_TRIGGER:
    case RECEIVE_SCOPE: {
      const { scope, updatedAt } = action;
      const isScopeSelected = scope.valid;
      return {
        ...state,
        scopeSelected: isScopeSelected,
        initialLoad: false,
        scopeLoading: false,
        selections: scope,
        scope,
        updatedAt,
      };
    }

    case SEND_SCOPE: {
      return {
        ...state,
        scopeLoading: true,
      };
    }

    case VALIDATE_ALL_SELECTIONS: {
      const selectionErrors = {} as SelectionErrors;
      let selectionsValid = true;

      if (!state.selections.productLevel) {
        selectionErrors.productLevel = 'Required';
      }

      if (!state.selections.productMember) {
        selectionErrors.productMember = 'Required';
      }

      if (!state.selections.locationLevel) {
        selectionErrors.locationLevel = 'Required';
      }

      if (!state.selections.locationMember) {
        selectionErrors.locationMember = 'Required';
      }

      if (!state.selections.historyEnd) {
        selectionErrors.historyEnd = 'Required';
      }

      if (!state.selections.historyStart) {
        selectionErrors.historyStart = 'Required';
      }

      Object.keys(selectionErrors).forEach((selection) => {
        if (selectionErrors[selection]) {
          selectionsValid = false;
        }
      });

      return {
        ...state,
        selectionsValid,
        selectionErrors,
      };
    }

    case GET_PLAN_DATES: {
      const { planDates } = action;
      return {
        ...state,
        planCurrent: planDates.planCurrent,
        planStart: planDates.planStart,
        planEnd: planDates.planEnd,
      };
    }

    case RESET_SCOPE: {
      return initialState;
    }

    default:
      return state;
  }
}
