import { connect } from 'react-redux';

import { TOP_DOWN, LEVEL_PROD_ROOT, lookBackPeriodProp } from 'src/utils/Domain/Constants';
import container from 'src/ServiceContainer';
import Summary, { DispatchProps } from './Summary';
import selectAndProjectState from './Summary.selectors';

import requestSummaryConfigs, {
  Action,
  receiveSummaryConfigs,
  receiveSummaryData,
  receiveSummaryError,
  requestSummaryData,
} from './Summary.actions';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import { Scope } from 'src/types/Scope';
import { isNil } from 'lodash';
import { AppState, AppThunkDispatch } from 'src/store';

export const keyFinancialsDefn = 'HistoryYearlyTrendRecapSummaryKeyFinancials';
export const choiceProductivityDefn = 'HistoryYearlyTrendRecapSummaryChoiceProductivity';
export const trendAnalysisDefn = 'HistoryYearlyTrendRecapSummaryTrendAnalysis';
export const productMixAndTrendDefn = 'HistoryYearlyTrendRecapSummaryProductMixAndTrend';
export const globalRegionVolumeAndTrendDefn = 'HistoryYearlyTrendRecapSummaryGlobalRegionVolumeTrend';
const defaultAggByProp = 'defaultAggBy';
const modelId = 'modelId';

export function getTopMembers(scope: Scope) {
  return [scope.productMember!, scope.locationMember!].join(',');
}

function getFinacialData(dispatch: AppThunkDispatch, getState: () => AppState): Promise<Action> | void {
  const { summary } = getState().pages.hindsighting;
  const { flowStatus, lookBackPeriod } = getState().subheader;

  if (isNil(summary.viewDefns)) {
    return;
  }

  const globalRegionVolumeAndTrendViewDefault = summary.viewDefns.globalRegionVolumeAndTrend[defaultAggByProp] || '';
  const keyFinancialsViewSelection = summary.viewDefns.keyFinancials[defaultAggByProp];
  const globalRegionVolumeAndTrendDefnId = summary.viewDefns.globalRegionVolumeAndTrend[modelId] || '';
  const { pivotService } = container;
  dispatch(requestSummaryData());

  return Promise.all([
    pivotService.listData(keyFinancialsDefn, TOP_DOWN, {
      aggBy: keyFinancialsViewSelection,
      flowStatus: flowStatus.join(','),
      [lookBackPeriodProp]: lookBackPeriod,
      topMembers: getTopMembers(getState().scope.scope),
    }),
    pivotService.listData(choiceProductivityDefn, TOP_DOWN, {
      aggBy: LEVEL_PROD_ROOT,
      flowStatus: flowStatus.join(','),
      topMembers: getTopMembers(getState().scope.scope),
    }),
    pivotService.listData(trendAnalysisDefn, TOP_DOWN, {
      aggBy: 'level:month',
      [lookBackPeriodProp]: lookBackPeriod,
      topMembers: getTopMembers(getState().scope.scope),
    }),
    pivotService.listData(globalRegionVolumeAndTrendDefnId, TOP_DOWN, {
      flowStatus: flowStatus.join(','),
      aggBy: globalRegionVolumeAndTrendViewDefault,
      [lookBackPeriodProp]: lookBackPeriod,
      topMembers: getTopMembers(getState().scope.scope),
    }),
  ])
    .then(([keyFinancials, choiceProductivity, trendAnalysis, globalRegionVolumeAndTrend]) => {
      return dispatch(
        receiveSummaryData({
          keyFinancials: keyFinancials.tree,
          choiceProductivity: choiceProductivity.tree,
          trendAnalysis: trendAnalysis.tree,
          productMixAndTrend: [],
          globalRegionVolumeAndTrend: globalRegionVolumeAndTrend.tree,
        })
      );
    })
    .catch((e) => {
      return dispatch(receiveSummaryError('Network error, could not fetch config or data'));
    });
}

function dispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  const { tenantConfigClient } = container;

  return {
    onShowView() {
      const tenantParams = {
        defnIds: [
          keyFinancialsDefn,
          choiceProductivityDefn,
          trendAnalysisDefn,
          productMixAndTrendDefn,
          globalRegionVolumeAndTrendDefn,
        ],
        appName: TOP_DOWN,
      };
      dispatch(requestSummaryConfigs());

      Promise.all([tenantConfigClient.getTenantViewDefns(tenantParams)]).then(([configs]) => {
        dispatch(
          receiveSummaryConfigs({
            keyFinancials: configs[0],
            choiceProductivity: configs[1],
            trendAnalysis: configs[2],
            productMixAndTrend: configs[3],
            globalRegionVolumeAndTrend: configs[4],
          })
        );
        dispatch(getFinacialData);
      });
    },
    onRefetchData() {
      dispatch(getFinacialData);
    },
  };
}

// @ts-ignore
const scopeSensitiveComponent = makeScopeSensitive(Summary);
export default connect(selectAndProjectState, dispatchToProps)(scopeSensitiveComponent);
