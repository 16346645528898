import { defaultTo, flow, get, hasIn, isNil } from 'lodash';
import { connect, Dispatch } from 'react-redux';
import { AppState } from 'src/store';
import {
  ConfigurableGridColumnDef,
  ConfigurableGridDispatchProps,
  ConfigurableGridOwnProps,
  ConfigurableGridValueProps,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';

import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makePopoverSensitive } from '../AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import {
  resetGroupBySelection,
  setGroupBySelection,
  setFloorsetSelection,
  updateConfigurableGridConfig,
  refreshConfigurableGridData,
} from './ConfigurableGrid.slice';
import { bindActionCreators } from 'redux';
import { FabType, withFab } from '../higherOrder/withFab';
import { dispatchUpdateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ConfigurableGrid } from './ConfigurableGrid';
import {
  getViewDefnData,
  getGroupByDropdownProps,
  getFloorsetDropdownProps,
  getConfigurableGridData,
} from './ConfigurableGrid.selectors';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

function mapStateToProps(
  state: AppState,
  ownProps: ConfigurableGridOwnProps
): ConfigurableGridValueProps & ConfigurableGridOwnProps {
  const showFlow = defaultTo(ownProps.showFlowStatus, true);
  const {
    viewDefnState,
    gridDataState: viewDataState,
    viewDefn,
    unmodifiedViewDefn,
  } = state.pages.assortmentBuild.configurableGrid;
  const loaded = isViewDefnLoaded(viewDefnState) && isDataLoaded(viewDataState);

  const columnDefs = isNil(viewDefn) ? [] : (viewDefn.columns as ConfigurableGridColumnDef[]);
  const gridRowHeight: number = get(viewDefn, 'main.rowHeight', 30);
  const massEditConfig = hasIn(viewDefn, 'massEditConfig') ? viewDefn.massEditConfig : undefined;
  const updateCoordinateMap = hasIn(viewDefn, 'updateCoordinateMap') ? viewDefn.updateCoordinateMap : undefined;
  const salesAdjustmentConfig = isNil(viewDefn) ? {} : viewDefn.salesAdjustmentConfig;

  const { dependentCalcs, companionSortOptions, defaultCompanionSortField, clientActionHandlers } = getViewDefnData(
    state
  );
  const groupByDropdownProps = getGroupByDropdownProps(state);
  const floorsetDropdownProps = getFloorsetDropdownProps(state);
  const data: BasicPivotItem[] = getConfigurableGridData(state, showFlow);

  return {
    ...ownProps,
    flowStatus: showFlow ? state.subheader.flowStatus : [],
    showFlowStatus: showFlow,
    search: state.subheader.search || '',
    groupBySelection: state.pages.assortmentBuild.configurableGrid.groupBySelection,
    favoritesList: state.subheader.favoritesList || [],
    fabType: ownProps.fabType || FabType.none,
    loaded,
    columnDefs,
    massEditConfig,
    updateCoordinateMap,
    dependentCalcs,
    gridRowHeight,
    companionSortOptions,
    defaultCompanionSortField,
    unmodifiedViewDefn,
    configuratorViewDefn: viewDefn,
    clientActionHandlers,
    groupByDropdownProps,
    floorsetDropdownProps,
    data,
    topAttributesData: state.pages.assortmentBuild.configurableGrid.topAttributesData,
    salesAdjustmentConfig,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<AppState>,
  _ownProps: ConfigurableGridOwnProps
): ConfigurableGridDispatchProps {
  return {
    dispatch,
    ...bindActionCreators({ setGroupBySelection, resetGroupBySelection, setFloorsetSelection }, dispatch),
    onUpdateConfig: (config: any) => {
      dispatch(updateConfigurableGridConfig(config));
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
    onRefreshConfigurableGridData: () => {
      dispatch(refreshConfigurableGridData());
    },
  };
}

const sensitiveView = flow(() => ConfigurableGrid, withFab, makePrintSensitive, makePopoverSensitive)();
export default connect(mapStateToProps, mapDispatchToProps)(sensitiveView);
