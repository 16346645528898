import moment from 'moment';
import { Moment } from 'moment';
import DayPicker from 'react-day-picker/DayPicker';
import { DateToNameMap } from './RadioOptions';

export const getWeekDays = (weekStart: Date) => {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .toDate()
    );
  }
  return days;
};

export const getWeekRange = (date: Date | Moment | undefined) => {
  return {
    from: moment(date)
      .startOf('week')
      .toDate(),
    to: moment(date)
      .endOf('week')
      .toDate(),
  };
};

export const NthWeekOfTheYear = (weekNumber: number, startOfWeek: boolean): Date => {
  let date = moment()
    .startOf('year')
    .toDate();
  if (weekNumber >= 1 || weekNumber <= 53) {
    if (startOfWeek) {
      date = moment()
        .startOf('year')
        .add((weekNumber - 1) * 7 - 1, 'days')
        .toDate();
    } else {
      date = moment()
        .startOf('year')
        .add(weekNumber * 7 - 1, 'days')
        .toDate();
    }
  }
  return date;
};

export function getSelectedIndex(options: { selected: boolean }[]) {
  return options.findIndex((option) => option.selected);
}

export function getSunday(date: Date) {
  const sunday = new Date(date.getTime());
  sunday.setDate(date.getDate() - date.getDay());
  return sunday;
}

export function getWeekLabel(date: Date, validNames: DateToNameMap) {
  const validNamesObject = { ...validNames.start_date, ...validNames.end_date };
  const { from } = getWeekRange(date);
  const dateKey = moment(from).format('YYYY-MM-DD');
  return validNamesObject[dateKey] || dateKey;
}

// If subtracting a year off of the assortment day doesn't result in a Sunday
// get the closest one.
export const getClosestSunday = (date: Moment) => {
  switch (date.day()) {
    case 0:
    case 1:
    case 2:
      return date.day(0);
    case 3:
    case 4:
    case 5:
    case 6:
      return date.day(7);
  }
  return date;
};

export function getDateFromWeek(week: string, validDays: object): Date | undefined {
  const foundDateString = Object.keys(validDays).find((date) => validDays[date] === week);
  return foundDateString ? moment(foundDateString).toDate() : undefined;
}

export function isDayInRange(day: Date, allowFromDate: Date, allowToDate: Date) {
  return DayPicker.DateUtils.isDayInRange(day, { from: allowFromDate, to: allowToDate });
}

export function isDayBefore(day: Date, previousOptionDate: Date) {
  return DayPicker.DateUtils.isDayBefore(day, previousOptionDate);
}

export function isDayAfter(day: Date, allowFromDate: Date) {
  return DayPicker.DateUtils.isDayAfter(day!, allowFromDate!);
}

export function isOutsideViewport(element: HTMLElement, buffer: number) {
  const viewPortHeight = window.innerHeight || window.document.documentElement!.clientHeight;
  const viewPortWidth = window.innerWidth || window.document.documentElement!.clientWidth;
  const { bottom, right } = element.getBoundingClientRect();
  const out = {
    // Add top and left when/if needed.
    bottom: bottom - viewPortHeight + buffer > 0,
    right: right - viewPortWidth + buffer > 0,
  };
  return out;
}

export function pixelsToNumber(propertyKey: string, computedStyles: CSSStyleDeclaration) {
  const dayPickerCSSTop = computedStyles.getPropertyValue(propertyKey);
  const [computedCSSTop] = dayPickerCSSTop.split('px');
  return Number(computedCSSTop);
}
