import * as React from 'react';
import { Metric, DirectionalMetric } from '../../CommonTypes';
import { keyFinancialStyle } from './KeyFinancial.styles';
import { downArrowClass, upArrowClass } from '../../../../theme';

export type Props = {
  metrics: [Metric, DirectionalMetric, Metric];
  extraClasses: string;
};

export default function KeyFinancial(props: Props) {
  if (!Object.keys(props).length) {
    return null;
  }
  const { metrics, extraClasses } = props;

  const containerClasses = 'key-financial-item ' + keyFinancialStyle + (extraClasses ? ' ' + extraClasses : '');

  const [primary, diff, secondary] = metrics;

  const dirClasses = 'dir fa fas ' + (diff.direction === 'down' ? 'down ' + downArrowClass : 'up ' + upArrowClass);

  return (
    <div className={containerClasses}>
      <header>{primary.label}</header>
      <div className="bottom-container">
        <div className="primary">{primary.rendered}</div>
        <div className="right-container">
          <div className="diff-container">
            <i className={dirClasses} />
            <span className="diff">{diff.rendered}</span>
          </div>
          <div className="secondary">
            {secondary.label} {secondary.rendered}
          </div>
        </div>
      </div>
    </div>
  );
}
