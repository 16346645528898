import { connect } from 'react-redux';
import { isNil, flow } from 'lodash';
import fp from 'lodash/fp';

import ListGridPair from 'src/components/ListGridPair/ListGridPair';
import container from 'src/ServiceContainer';
import { ASSORTMENT, STYLE_ID, ID, HINDSIGHTING } from 'src/utils/Domain/Constants';
import { LeafType } from 'src/components/higherOrder/withLeafType';
import { StylePaneable } from 'src/components/HistoryStylePane/HistoryStylePane.types';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { getWorklist } from 'src/pages/Hindsighting/StyleColorReview/SharedData.slice';
import { OverLoadSubheaderProps, overloadSubheader } from 'src/components/Subheader/Subheader.slice';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import {
  cleanUp,
  receiveError,
  requestGridViewConfig,
  receiveGridViewConfig,
  receiveSomeGridViewConfig,
  fetchGridViewData,
} from './GridView.slice';
import { selectState } from './GridView.selectors';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import store, { AppState, AppThunkDispatch } from 'src/store';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { DefnProps, GridViewComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getAggBys, ExtraPivotOptions, organizeListDataOptions } from '../StyleColorReview.slice';

export type GridViewOwnProps = z.infer<typeof GridViewComponentProps>;

function getData(defns: DefnProps) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const { dataApi, model: modelDefn } = defns;
    const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

    const topMembers = getState().pages.assortmentBuild.worklist.selectedItem;
    const aggBy = getAggBys(getState().subheader, dataApi);
    const options: ExtraPivotOptions = !isNil(topMembers) ? { topMembers, aggBy } : { aggBy };
    const finalOptions = organizeListDataOptions(options, dataApi);

    dispatch(fetchGridViewData(finalModelDefn, finalOptions));
  };
}

export type GridViewDispatchProps = {
  onShowView(leafType: LeafType, defnIds: string[]): void;
  onRefetchData(): void;
  onDestroy(): void;
  openStylePane(stylePaneable: StylePaneable): void;
  onUpdateConfig(config: TenantConfigViewData): void;
  refetchWorklist(): void;
};

function retrieveGridIdentityProps(config: TenantConfigViewData & { identityProps?: IdentityPropsConfig }) {
  const { identityProps } = config;

  if (!isNil(identityProps)) {
    return identityProps;
  }

  // fallback to previously hardcoded values

  // GridView style review views are configured slightly different
  const [, , , section] = store.getState().perspective.activePage.split('/');
  const isStyleReviewSection = section === 'style-review';

  return isStyleReviewSection ? { id: STYLE_ID } : { id: ID };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: GridViewOwnProps): GridViewDispatchProps {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;
  const extraParams = !isNil(ownProps.topMembers) ? { topMembers: ownProps.topMembers } : {};

  return {
    onShowView() {
      dispatch(requestGridViewConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns[0],
            (resp as any)[defns.view.length],
            dispatch
          );
          resp[defns.view.length] = fp.head(resp)!;
          if (localConfig && localConfig.config) {
            resp[0] = localConfig.config;
          }
          return resp;
        })
        .then((resp: (TenantConfigViewData & { identityProps?: IdentityPropsConfig })[]) => {
          const identityPropsConfig = retrieveGridIdentityProps(resp[0]);

          dispatch(
            receiveGridViewConfig({
              grid: resp[0],
              listSort: resp[1],
              subheaderRollUp: resp[3],
              list: resp[4],
              unmodifiedViewDefn: fp.last(resp)!,
              identityPropsConfig,
            })
          );
          // we need to fetch data after favorites and config load,
          // because when calling the view on location dimension, it
          //  needs the aggBy param.  See INT-1311
          dispatch(getData(ownProps.defns));
          const subheaderProps: OverLoadSubheaderProps = {
            groupByOptions: resp[2],
            showFlowStatus: true,
            showSearch: true,
            title: 'Grid View',
          };
          dispatch(overloadSubheader(subheaderProps));
        })
        .catch(() => dispatch(receiveError()));
    },
    onRefetchData() {
      dispatch(getData(ownProps.defns));
    },
    refetchWorklist() {
      dispatch(getWorklist());
    },
    openStylePane(stylePaneable: StylePaneable) {
      // I'm sorry I wrote this. - Denver
      const isHindsighting = window.location.hash.indexOf(HINDSIGHTING) >= 0;
      dispatch(
        setRightContainerPayload({
          type: 'HistoryStylePane',
          isAssortmentBuild: !isHindsighting,
          ...stylePaneable,
        })
      );
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(
        receiveSomeGridViewConfig({
          grid: config,
        })
      );
    },
  };
}

const wrappedView = flow(
  () => ListGridPair,
  withFab,
  makePopoverSensitive,
  makePrintSensitive,
  makeScopeAndFilterSensitive
)();

export default connect(selectState, dispatchToProps)(wrappedView);
