import * as React from 'react';
import * as _ from 'lodash';
import Axios from 'src/services/axios';
import { reduce, find, sortBy, isNil, findIndex, defaultTo, get } from 'lodash';
import { isEmpty, sum } from 'lodash/fp';

import { ViewApiConfig } from '../../StyleEdit.types';
import { BasicItem } from 'src/types/Scope';
import { InputInteger } from 'src/common-ui/index';
import { InputIntegerClass, ReceiptStyles as styles, MUIStyles } from './ReceiptsAdjCalculator.styles';
import { addEventListenerForEditors } from './Editors.utils';
import ServiceContainer from 'src/ServiceContainer';
import CardActions from '@material-ui/core/CardActions';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';

import { ICellEditorParams } from 'ag-grid-community';

import { Overlay } from 'src/common-ui/index';
import { IS_PUBLISHED, IS_LOCKED } from 'src/utils/Domain/Constants';

export type ReceiptsAdjCalculatorProps = {
  dataApi: ViewApiConfig;
  configApi: ViewApiConfig;
  floorset?: string;
  isEditable: boolean;
} & ICellEditorParams;

export type ReceiptsAdjCalculatorState = {
  sizes: WeekSizes[];
  activeWeekIndex: number;
  saved: boolean;
  overlayWidth: number;
  overlayHeight: number;
  shouldSave: boolean;
  staticTotals: StaticTotals[];
};

export type StaticTotals = {
  final: number;
  system: number;
  weekNo: string;
};

export type WeekSizes = {
  weekNo: string;
  receipts: SizeDetail[];
  total?: SizeDetail;
};

export type SizeDetail = BasicItem & {
  system: number | undefined;
  userAdj: AggregateValue;
  onOrder: number | undefined;
  onOrderRevision: AggregateValue;
  final: number | undefined;
  lastPublished: number | undefined;
  sizeattribute?: string;
  default_size_profile: number;
};

export type ConfigSizeDetail = SizeDetail & {
  userAdj: number;
  onOrderRevision: number;
};

export type ClientSizeDetail = SizeDetail & {
  userAdj: AggregateValue;
  onOrderRevision: AggregateValue;
};

export type AggregateValue = {
  value: number | undefined;
  percentage: number;
};

export interface NumberFormatValues {
  floatValue: number;
  formattedValue: string;
  value: string;
}

interface ReceiptAdjData {
  channel: string;
  children: any[];
  dc_finalqty: number;
  dc_finrev: any;
  dc_last_pub: number;
  dc_onorder: number;
  dc_planqty: number;
  dc_publish: number;
  dc_useradj: any;
  default_size_profile: number;
  description: string;
  expanded: boolean;
  id: string;
  index: number;
  is_locked: number;
  leaf: boolean;
  'member:week:description': string;
  'member:week:id': string;
  'member:week:name': string;
  name: string;
  product: string;
  sizeattribute: string;
  stylecolor: string;
  week: string;
}

export default class TabbedReceiptsAdjCalculator extends React.Component<ReceiptsAdjCalculatorProps> {
  state: ReceiptsAdjCalculatorState;
  private readonly emptyTotalRow: SizeDetail = {
    id: 'Total',
    name: 'Total',
    system: undefined,
    userAdj: {
      value: undefined,
      percentage: 0.0,
    },
    onOrder: undefined,
    onOrderRevision: {
      value: undefined,
      percentage: 0.0,
    },
    final: undefined,
    lastPublished: 0,
    default_size_profile: 0,
  };

  constructor(props: ReceiptsAdjCalculatorProps) {
    super(props);
    this.state = {
      sizes: [],
      activeWeekIndex: 0,
      saved: false,
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
      shouldSave: true,
      staticTotals: [],
    };

    addEventListenerForEditors(this.props.eGridCell);
    window.addEventListener('resize', this.resizeOverlay.bind(this));
  }

  resizeOverlay() {
    this.setState({
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    });
  }

  isCancelAfterEnd() {
    return !this.state.shouldSave;
  }

  convertSizeDetailToServerData = (size: SizeDetail) => {
    return {
      dc_planqty: size.system,
      dc_useradj: size.userAdj.value,
      dc_onorder: size.onOrder,
      dc_finrev: size.onOrderRevision.value,
      dc_finalqty: size.final,
      dc_last_pub: size.lastPublished,
    };
  };

  componentDidMount() {
    const floorset = this.props.floorset;
    const dataParams = this.props.dataApi.params
      ? this.props.dataApi.params
      : {
          appName: 'assortment',
          productId: this.props.node.data['stylecolor'],
          timeId: floorset,
          defnId: 'ReceiptAdjustments',
        };
    Axios.get(this.props.dataApi.url, {
      params: dataParams,
    }).then(async (resp: { data: any }) => {
      const sizeData: any = await ServiceContainer.pivotService.deserialize(resp.data);
      const tempData: any[] = [];

      sortBy(sizeData, 'size_id').forEach((data: ReceiptAdjData) => {
        const keys = tempData.length > 0 ? tempData.map((item) => item.weekNo) : [];
        if (keys.indexOf(data.week) === -1) {
          tempData.push({
            weekNo: data.week,
            receipts: [data],
          });
        } else {
          find(tempData, ['weekNo', data.week]).receipts.push(data);
        }
      });

      // Convert server data to SizeDetail
      // default_size / total
      const finalData: WeekSizes[] = tempData.map((weekSize) => {
        return {
          weekNo: weekSize.weekNo,
          receipts: weekSize.receipts.map((receipt: ReceiptAdjData) => {
            return {
              system: receipt.dc_planqty,
              userAdj: {
                value: receipt.dc_useradj,
                percentage: undefined,
              },
              onOrder: receipt.dc_onorder,
              onOrderRevision: {
                value: receipt.dc_finrev, // TODO
                percentage: undefined,
              },
              final: receipt.dc_finalqty,
              lastPublished: receipt.dc_last_pub,
              ...receipt,
            };
          }),
        };
      });

      const finalFinalData = this.calculateInitialPercentages(finalData);

      const staticTotals = finalFinalData.map((weekSize: WeekSizes) => {
        const totalSystem = sum(weekSize.receipts.map((r) => r.system));
        const totalFinal = sum(weekSize.receipts.map((r) => r.final));

        return {
          final: totalFinal,
          system: totalSystem,
          weekNo: weekSize.weekNo,
        };
      });

      this.setState({
        sizes: finalFinalData,
        activeWeekIndex: 0,
        staticTotals,
      });
    });
  }

  calculateInitialPercentages = (sizes: WeekSizes[]) => {
    return sizes.map((weekSize: WeekSizes) => {
      const totalSystem = sum(weekSize.receipts.map((r) => r.system));
      const totalOnOrder = sum(weekSize.receipts.map((r) => r.onOrder));
      const totalProfiles = sum(weekSize.receipts.map((r) => r.default_size_profile));

      const newReceipts = weekSize.receipts.map((size) => {
        const systemVal = size.system || 0;
        const onOrderVal = size.onOrder || 0;
        // let percentageSystem: number = totalSystem !== 0 ? systemVal / totalSystem : size.default_size_profile;
        let percentageSystem;
        if (size.userAdj.percentage) {
          percentageSystem = size.userAdj.percentage;
        } else if (totalSystem !== 0) {
          percentageSystem = systemVal / totalSystem;
        } else if (totalProfiles > 0) {
          percentageSystem = size.default_size_profile / totalProfiles;
        } else {
          percentageSystem = 1 / weekSize.receipts.length;
        }

        let percentageOnOrder: number;
        if (size.onOrderRevision.percentage) {
          percentageOnOrder = size.onOrderRevision.percentage;
        } else if (totalOnOrder !== 0) {
          percentageOnOrder = onOrderVal / totalOnOrder;
        } else {
          percentageOnOrder = percentageSystem;
        }

        return {
          ...size,
          userAdj: {
            value: size.userAdj.value,
            percentage: percentageSystem,
          },
          onOrderRevision: {
            value: size.onOrderRevision.value,
            percentage: percentageOnOrder,
          },
        };
      });

      const initialTotal = this.calculateTotalFromSizes(newReceipts);
      return {
        weekNo: weekSize.weekNo,
        receipts: newReceipts,
        total: initialTotal,
      };
    });
  };

  getUrl = () => {
    return reduce(
      this.props.dataApi.params,
      (url, value, key) => url.replace(`:${key}`, value),
      this.props.dataApi.url
    );
  };

  getValue = () => {
    window.removeEventListener('resize', this.resizeOverlay);
    if (this.state.saved) {
      let onOrderAdjTtl = 0;
      let onOrderFinalTtl = 0;
      let shouldBeNullOnOrder = true;
      let rcptAdjTtl = 0;
      let rcptFinalTtl = 0;
      let shouldBeNullUserAdj = true;

      // Calculates all of the useradj and onorders to get the value for grid
      this.state.sizes.forEach((size) => {
        const total = size.total!;
        // Test to see if it should return 0 or null
        if (!isNil(total.userAdj.value)) {
          shouldBeNullUserAdj = false;
        }
        // Actually adds everything
        if (total.userAdj.value && total.userAdj.value > 0) {
          rcptAdjTtl += total.userAdj.value;
          rcptFinalTtl += total.userAdj.value;
        } else {
          if (total.system && total.system > 0) {
            rcptFinalTtl += total.system;
          }
        }

        // Onorder version of above code
        if (!isNil(total.onOrderRevision.value)) {
          shouldBeNullOnOrder = false;
        }
        if (total.onOrderRevision.value && total.onOrderRevision.value > 0) {
          onOrderAdjTtl += total.onOrderRevision.value;
          onOrderFinalTtl += total.onOrderRevision.value;
        } else {
          if (total.onOrder && total.onOrder > 0) {
            onOrderFinalTtl += total.onOrder;
          }
        }
      });
      return {
        onOrderRevision: onOrderAdjTtl === 0 && shouldBeNullOnOrder ? null : onOrderAdjTtl,
        onOrderFinal: onOrderFinalTtl,
        userAdjRevision: rcptAdjTtl === 0 && shouldBeNullUserAdj ? null : rcptAdjTtl,
        rcptFinal: rcptFinalTtl,
      };
    } else {
      let value = undefined;
      if (
        this.props.floorset &&
        this.props.node.data.extraData &&
        this.props.node.data.extraData[this.props.floorset]
      ) {
        value = this.props.node.data.extraData[this.props.floorset];
      }
      return value;
    }
  };

  isPopup = () => {
    return true;
  };

  calculateTotalFromSizes(sizes: SizeDetail[]): SizeDetail {
    const total = _.cloneDeep(this.emptyTotalRow);

    sizes.forEach((size) => {
      Object.keys(size).forEach((key: string) => {
        switch (key) {
          case 'userAdj':
          case 'onOrderRevision':
            const valueToAdd = Number.isFinite(size[key].value) ? size[key].value : undefined;
            const newTotal = !isNil(valueToAdd)
              ? Number.isFinite(total[key].value)
                ? total[key].value! + valueToAdd
                : valueToAdd
              : total[key].value;
            total[key].value = newTotal;
            total[key].percentage = 1;
            break;
          case 'system':
          case 'onOrder':
          case 'final':
          case 'lastPublished':
            const valueToAdd2 = size[key] ? size[key] : 0;
            const newTotal2 = total[key] ? total[key]! + valueToAdd2! : valueToAdd2;
            total[key] = newTotal2;
            break;
          default:
        }
      });
    });

    return total;
  }

  handleChangeReceipts = (sizeattribute: string, column: string, value: number | null) => {
    const weekSizes = _.cloneDeep(this.state.sizes);
    const receipts = weekSizes[this.state.activeWeekIndex].receipts;
    const sizeToChange: number = receipts.findIndex((size: SizeDetail) => {
      return size.sizeattribute === sizeattribute;
    });

    receipts[sizeToChange][column].value = value;
    const newTotalRow = this.calculateTotalFromSizes(receipts);

    receipts.forEach((size) => {
      const oldValue = size[column].value;
      const newValue = oldValue / newTotalRow[column].value;
      if (!Number.isFinite(newValue) && Number.isFinite(newTotalRow[column].value)) {
        size[column].percentage = 0;
        size[column].value = 0;
      }
      size[column].percentage = newValue;
    });

    weekSizes[this.state.activeWeekIndex].receipts = receipts;
    weekSizes[this.state.activeWeekIndex].total = newTotalRow;
    this.setState({
      sizes: weekSizes,
    });
  };

  handleChangeTotalRow = (column: string, newTotalValue: number | null) => {
    // clone the sizes up front so we can mutate them safely
    const weekSizes = _.cloneDeep(this.state.sizes);
    const activeWeek = weekSizes[this.state.activeWeekIndex];
    const receipts = activeWeek.receipts;

    if (newTotalValue === 0 || !Number.isFinite(newTotalValue)) {
      const init = this.calculateInitialPercentages(weekSizes);

      init[this.state.activeWeekIndex].receipts.map((x) => {
        x[column].value = newTotalValue;
        return x;
      });
      init[this.state.activeWeekIndex].total![column].value = newTotalValue;
      this.setState({
        sizes: init,
      });
      return;
    }

    type Percents = {
      percent: number; // warning: this can NaN
      index: number;
    };

    let currentTotal = 0;

    const percentageArray: Percents[] = [];
    receipts.forEach((size, index) => {
      // When a new total comes in, we need to re-calculate the size value of every size,
      // so we use the previous % contribution per size multiplied by the newTotal value to get the updated value.
      // Because this process runs for essentially all changes, float errors can be introduced, so we Math.Round()
      // and because units should be integers here, we then floor() them
      const newSizeValue = Math.floor(Math.round(size[column].percentage * newTotalValue!));
      currentTotal += newSizeValue || 0; // newSizeValue shouldn't be nullable here, this is for safety
      percentageArray.push({
        percent: size[column].percentage,
        index: index,
      });

      size[column].value = newSizeValue;
    });

    // To distribute the last couple items that might get lost, it takes the highest percentages and adds 1 to them
    percentageArray.sort((a, b) => b.percent - a.percent);
    percentageArray.forEach((percentObject) => {
      // Distribute to the highest percentages in cases where final total is less than intended total.
      if (newTotalValue && currentTotal < newTotalValue) {
        receipts[percentObject.index][column].value = receipts[percentObject.index][column].value + 1;
        currentTotal += 1;
        // Undistribute from the highest percentages in cases where final total is greater than intended total.
      } else if (newTotalValue && currentTotal > newTotalValue) {
        receipts[percentObject.index][column].value = receipts[percentObject.index][column].value - 1;
        currentTotal -= 1;
      }
    });

    // It shouldn't ever be possible to have a populated week *with* no total row. But I don't want to fight types.
    if (!isNil(activeWeek.total)) {
      activeWeek.total[column].value = newTotalValue;
    }
    this.setState({
      sizes: weekSizes,
    });
  };

  getFieldTotal = (field: keyof SizeDetail) => {
    const { sizes, activeWeekIndex } = this.state;
    const sizesOnOrderV = sizes[activeWeekIndex].receipts.map((r) => get(r, field));
    if (findIndex(sizesOnOrderV, (oo) => !isNil(oo)) < 0) {
      return null;
    }
    return sum(sizesOnOrderV);
  };

  getOnOrderTotal = () => {
    return this.getFieldTotal('onOrder');
  };

  getFieldValue = (field: string, receiptIndex: number) => {
    const { sizes, activeWeekIndex } = this.state;
    const value = sizes[activeWeekIndex].receipts[receiptIndex][field];
    return value;
  };

  getTotalValue = (field: string) => {
    const { staticTotals, activeWeekIndex } = this.state;
    return staticTotals[activeWeekIndex][field];
  };

  buildRow = (size: SizeDetail, index: number): JSX.Element => {
    const { isEditable } = this.props;
    const { sizeattribute } = size;
    const { system, userAdj, onOrder, onOrderRevision, final, lastPublished } = size;
    const onOrderTotal = this.getOnOrderTotal();
    const isPublished = this.getFieldValue(IS_PUBLISHED, index);
    const isLocked = this.getFieldValue(IS_LOCKED, index);
    const rowEditable: boolean = !isPublished && !isLocked && isEditable;
    const onOrderOverrideEditable = rowEditable && !isNil(onOrderTotal);
    const userAdjEditable = rowEditable && !onOrderOverrideEditable;
    
    let calculatedTotal = system;
    // We check against totals, because that's...how this all works
    if (!isNil(this.getFieldTotal('onOrderRevision'))) {
      calculatedTotal = defaultTo(onOrderRevision.value, 0);
    } else if (!isNil(this.getFieldTotal('onOrder'))) {
      calculatedTotal = defaultTo(onOrder, 0);
    } else if (!isNil(this.getFieldTotal('userAdj'))) {
      calculatedTotal = defaultTo(userAdj.value, 0);
    } else {
      calculatedTotal = defaultTo(system, 0);
    }
    const highlightTotal = calculatedTotal === defaultTo(system, 0) ? 'inherit' : '#ff0000';
    return (
      <div key={sizeattribute} className={styles.tableRow}>
        <div className={styles.tableCellBodyFirst}>{sizeattribute}</div>
        <div className={styles.tableCellBody}>{system}</div>
        <div className={userAdjEditable ? styles.tableCellInput : styles.tableCellInputNotEditable}>
          <InputInteger
            // @ts-ignore
            onChange={(value: number | null) => this.handleChangeReceipts(sizeattribute!, 'userAdj', value)}
            bypassDefaultHandler={true}
            nullable={true} // only the total row can be nulled directly
            editable={userAdjEditable}
            valid={true}
            value={userAdj.value === undefined ? null : userAdj.value}
            thousandSeparator={','}
            className={InputIntegerClass}
            key={'userAdj' + index}
          />
        </div>
        <div className={styles.tableCellBody}>{onOrder}</div>
        <div className={userAdjEditable ? styles.tableCellInput : styles.tableCellInputNotEditable}>
          <InputInteger
            // @ts-ignore
            onChange={(value: number | null) => this.handleChangeReceipts(sizeattribute!, 'onOrderRevision', value)}
            bypassDefaultHandler={true}
            nullable={true} // only the total row can be nulled directly
            editable={onOrderOverrideEditable}
            valid={true}
            value={onOrderRevision.value === undefined ? null : onOrderRevision.value}
            thousandSeparator={','}
            className={InputIntegerClass}
            key={'onOrder' + index}
          />
        </div>
        <div className={styles.tableCellBody} style={{ color: highlightTotal }}>
          {final}
        </div>
        <div className={styles.tableCellBody}>{lastPublished}</div>
      </div>
    );
  };

  buildTotalRow = (total: SizeDetail, index: number): JSX.Element => {
    const { isEditable } = this.props;
    const { id, name } = total;
    const { userAdj, onOrder, onOrderRevision, lastPublished } = total;
    const system = this.getTotalValue('system');
    const final = this.getTotalValue('final');
    const onOrderTotal = this.getOnOrderTotal();
    const isPublished = this.getFieldValue(IS_PUBLISHED, index);
    const isLocked = this.getFieldValue(IS_LOCKED, index);
    const rowEditable: boolean = !isPublished && !isLocked && isEditable;

    const onOrderOverrideEditable: boolean = rowEditable && !isNil(onOrderTotal);

    const userAdjEditable: boolean = rowEditable && !onOrderOverrideEditable;

    let calculatedTotal = system;
    if (userAdj.value && userAdj.value >= 0) {
      calculatedTotal = userAdj.value;
    }
    if (onOrder && onOrder > 0) {
      calculatedTotal = onOrder;
    }
    if (onOrderRevision.value && onOrderRevision.value >= 0) {
      calculatedTotal = onOrderRevision.value;
    }
    const highlightTotal = calculatedTotal === system ? 'inherit' : '#ff0000';

    return (
      <div key={id} className={styles.tableRow}>
        <div className={styles.tableCellTotalFirst}>{name}</div>
        <div className={styles.tableCellTotal}>{system}</div>
        <div className={userAdjEditable ? styles.tableCellInput : styles.tableCellInputNotEditable}>
          <InputInteger
            // @ts-ignore
            onChange={(value: number | null) => this.handleChangeTotalRow('userAdj', value)}
            bypassDefaultHandler={true}
            nullable={true}
            editable={userAdjEditable}
            valid={true}
            value={userAdj.value === undefined ? null : userAdj.value}
            thousandSeparator={','}
            className={InputIntegerClass}
            key={'userAdj' + index}
          />
        </div>
        <div className={styles.tableCellTotal}>{onOrder}</div>
        <div className={userAdjEditable ? styles.tableCellInput : styles.tableCellInputNotEditable}>
          <InputInteger
            // @ts-ignore
            onChange={(value: number | null) => this.handleChangeTotalRow('onOrderRevision', value)}
            bypassDefaultHandler={true}
            nullable={true}
            editable={onOrderOverrideEditable}
            valid={true}
            value={onOrderRevision.value === undefined ? null : onOrderRevision.value}
            thousandSeparator={','}
            className={InputIntegerClass}
            key={'onOrder' + index}
          />
        </div>
        <div className={styles.tableCellBody} style={{ color: highlightTotal }}>
          {final}
        </div>
        <div className={styles.tableCellTotal}>{lastPublished}</div>
      </div>
    );
  };

  onClickWeekTab = (weekIndex: number) => {
    this.setState({
      activeWeekIndex: weekIndex,
    });
  };

  bindEventListeners = (element: HTMLDivElement) => {
    if (element) {
      element.addEventListener('keydown', function(event: KeyboardEvent) {
        event.stopPropagation();
      });
    }
  };

  noDataJSX = () => {
    return (
      <div className={styles.overlay} style={{ width: this.state.overlayWidth, height: this.state.overlayHeight }}>
        <div data-qa="ag-popover" className={styles.modalContainerForNoData}>
          <div data-qa="ag-popover-arrow" className={styles.modalArrowStyle} />
          <div className={styles.cardHeader}>Receipts Override Calculator</div>
          <div className={styles.tabContainer} />
          <div className={styles.containerNoData} ref={this.bindEventListeners}>
            <Overlay type="loading" visible={true} fitParent={true} />
          </div>
          <div className={styles.actionsContainer}>
            <CardActions classes={MUIStyles.cardActions}>
              <RejectButton onClick={() => this.cancel()} />
            </CardActions>
          </div>
        </div>
      </div>
    );
  };

  save = () => {
    const serverData: any[] = [];
    this.state.sizes.forEach((size) => {
      size.receipts.forEach((receipt) => {
        serverData.push({
          ...receipt,
          ...this.convertSizeDetailToServerData(receipt),
        });
      });
    });

    const dataParams = this.props.dataApi.params
      ? this.props.dataApi.params
      : {
          appName: 'assortment',
          productId: this.props.node.data['stylecolor'],
          timeId: this.props.floorset,
          defnId: 'ReceiptAdjustments',
        };

    Axios.post(this.props.dataApi.url, serverData, {
      params: dataParams,
    });

    this.setState(
      {
        saved: true,
      },
      () => {
        this.props.stopEditing();
      }
    );
  };

  cancel = () => {
    this.setState(
      {
        shouldSave: false,
      },
      () => this.props.stopEditing()
    );
  };

  render() {
    if (!this.state.sizes || this.state.sizes.length === 0) {
      return this.noDataJSX();
    }

    const activeWeekIndex = this.state.activeWeekIndex;
    const receipts = this.state.sizes[activeWeekIndex].receipts;

    return (
      <div className={styles.overlay} style={{ width: this.state.overlayWidth, height: this.state.overlayHeight }}>
        <div data-qa="ag-popover" className={styles.modalContainer}>
          <div data-qa="ag-popover-arrow" className={styles.modalArrowStyle} />
          <div className={styles.cardHeader}>Receipts Override Calculator</div>
          <div className={styles.tabContainer}>
            {this.state.sizes.map((size, index) => {
              const tabClass = activeWeekIndex === index ? styles.tabButtonActive : styles.tabButton;
              return (
                <button className={tabClass} onClick={() => this.onClickWeekTab(index)} key={size.weekNo}>
                  {size.weekNo}
                </button>
              );
            })}
          </div>
          <div className={styles.container} ref={this.bindEventListeners}>
            <div className={styles.receiptContainer}>
              <div className={styles.headerSection}>
                <span className={styles.sectionText}>Color Details</span>
              </div>
              <div className={styles.tableSection}>
                <div className={styles.tableHeader}>
                  <div className={styles.tableRow}>
                    <div className={styles.tableCellHeaderFirst}>Sizes</div>
                    <div className={styles.tableCellHeader}>System</div>
                    <div className={styles.tableCellHeader}>Rec Override</div>
                    <div className={styles.tableCellHeader}>On Order</div>
                    <div className={styles.tableCellHeader}>OO Override</div>
                    <div className={styles.tableCellHeader}>Final</div>
                    <div className={styles.tableCellHeader}>Last Pub</div>
                  </div>
                </div>
                <div>{this.buildTotalRow(this.calculateTotalFromSizes(receipts), 0)}</div>
              </div>
            </div>
            <div className={styles.receiptContainer}>
              <div className={styles.headerSection}>
                <span className={styles.sectionText}>Size Details</span>
              </div>
              <div className={styles.tableSection}>
                <div className={styles.tableHeader}>
                  <div className={styles.tableRow}>
                    <div className={styles.tableCellHeaderFirst}>Sizes</div>
                    <div className={styles.tableCellHeader}>System</div>
                    <div className={styles.tableCellHeader}>Rec Override</div>
                    <div className={styles.tableCellHeader}>On Order</div>
                    <div className={styles.tableCellHeader}>OO Override</div>
                    <div className={styles.tableCellHeader}>Final</div>
                    <div className={styles.tableCellHeader}>Last Pub</div>
                  </div>
                </div>
                <div>
                  {isEmpty(receipts) && <div className={styles.tableRow} />}
                  {!isEmpty(receipts) && receipts.map(this.buildRow)}
                  {!isEmpty(receipts) && this.buildTotalRow(this.calculateTotalFromSizes(receipts), 0)}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actionsContainer}>
            <CardActions classes={MUIStyles.cardActions}>
              {this.props.isEditable && <AcceptButton onClick={() => this.save()} />}
              <RejectButton onClick={() => this.cancel()} />
            </CardActions>
          </div>
        </div>
      </div>
    );
  }
}
