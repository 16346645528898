import { style } from 'typestyle';
import { TEAL_PRIMARY, smallFont } from 'src/utils/Style/Theme';

const viewConfiguratorStyles = style({
  width: 500,
  padding: 20,
  $nest: {
    '.reset-confirmation': {
      display: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    '.reset-center': {
      zIndex: 100,
      border: '1px solid black',
      height: 600,
      width: 500,
      position: 'absolute',
      left: '50%',
      top: '40%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
    },
    '.columns-title-grid': {
      width: '20%',
      float: 'left',
    },
    '.columns-inner': {
      width: '100%',
      height: 50,
      textAlign: 'right',
      fontWeight: 'bold',
      paddingRight: 20,
    },
    '.defaults-title-grid': {
      marginTop: 470,
      textAlign: 'right',
      paddingRight: 20,
      fontWeight: 'bold',
    },
    '.columns-grid': {
      width: '100%',
      float: 'right',
      height: 500,
      overflowY: 'scroll',
      overflowX: 'hidden',
      $nest: {
        '.columns-title-container': {
          width: '100%',
          height: 40,
          fontWeight: 'bold',
          display: 'flex',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 2,
        },
        '.columns-columns': {
          paddingLeft: 103,
          paddingTop: 5,
        },
      },
    },
    '.defaults-grid': {
      display: 'flex',
      width: '80%',
      float: 'right',
      height: 80,
      padding: 20,
      marginTop: 5,
      $nest: {
        label: {
          fontSize: '0.9rem',
        },
        '.group-by-dropdown': {
          width: 160,
          fontSize: '.95rem',
        },
        '.sort-by-dropdown': {
          width: 160,
          fontSize: '.95rem',
        },
        '& > div:nth-child(2)': {
          marginLeft: 20,
        },
      },
    },
    '.footer': {
      width: '75%',
      bottom: 0,
      height: 43,
      position: 'absolute',
    },
    '.reset-button': {
      float: 'right',
      width: 80,
      height: 35,
      color: 'black',
      backgroundColor: '#d6d6d6',
      $nest: {
        '&.primary': {
          color: 'white',
          backgroundColor: TEAL_PRIMARY,
        },
        '&:disabled': {
          color: 'grey',
          backgroundColor: '#d6d6d6',
        },
      },
      borderStyle: 'none',
      cursor: 'pointer',
      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
      borderRadius: 2,
      letterSpacing: 0.5,
    },
    '.group-by-dropdown': {
      marginRight: 40,
    },
    '.columns-checkbox': {
      width: '15%',
      textAlign: 'center',
      paddingTop: 1,
      marginLeft: 'auto',
    },
    '.title-columns': {
      marginLeft: 'auto',
    },
    '.title-pin': {
      textAlign: 'center',
      paddingTop: 5,
      paddingRight: 30,
      display: 'inline-block',
    },
    '.title-show': {
      marginRight: 20,
      textAlign: 'center',
      display: 'inline-block',
      paddingTop: 5,
    },
    '.item-text.metric-style': {
      paddingTop: 0,
    },
    '.item-text': {
      paddingLeft: 10,
      paddingTop: 13,
      width: '60%',
      textAlign: 'center',
      marginRight: 50,
    },
    '.configure-button': {
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: '0 none',
      display: 'flex',
      marginLeft: '1rem',
      marginTop: 20,
      $nest: {
        '&:hover': {
          cursor: 'pointer',
        },
        '> span': {
          fontSize: smallFont,
          marginLeft: '0.3rem',
        },
      },
    },
  },
});

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  height: 50,
  background: isDragging ? 'lightgreen' : 'rgba(0,0,0,.05)',
  display: 'flex',
  marginBottom: 10,
  marginLeft: 20,
  marginRight: 20,
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean, length: number) => ({
  // background: isDraggingOver ? 'lightblue' : 'inherit',
  width: '100%',
  height: 50 * length,
});

export default viewConfiguratorStyles;
