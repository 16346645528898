import React from 'react';
import { useState, useEffect } from 'react';
import { modal } from 'src/components/ConfirmationModal/ConfirmationModal.styles';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { TextField } from '@material-ui/core';
import cuid from 'cuid';
import { BasicItem } from 'src/types/Scope';

type FavoritesSaveModalProps = {
  isOpen: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onSave: (favMeta: BasicItem) => void;
  nameValidator: (name: string) => boolean;
};

export const FavoritesSaveModal = (props: FavoritesSaveModalProps) => {
  const inputRef = React.useRef<HTMLInputElement>();
  const { onSave, onClose, isOpen, nameValidator } = props;
  const [saveName, setSaveName] = useState<string | null>(null); // may need to inject props here
  const [nameValid, setNameValid] = useState<boolean>(false);

  useEffect(() => {
    // some weird async issue with the input ref :(
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!saveName) {
      setNameValid(false);
    } else {
      setNameValid(nameValidator(saveName));
    }
  }, [saveName]);

  return (
    <Modal isOpen={props.isOpen} toggle={onClose} size={'md'} className={modal}>
      <ModalHeader>
        <span className="left-container">
          <i className="fas fa-save icon" />
          Save New Favorite
        </span>
        <span className="right-container">
          <i className="far fa-times" onClick={onClose} />
        </span>
      </ModalHeader>
      <ModalBody>
        <p className="instructions">Favorite name:</p>
        <TextField
          id="outlined-basic"
          label="name"
          variant="outlined"
          error={!nameValid}
          inputRef={inputRef}
          onChange={(e) => setSaveName(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              if (saveName && nameValidator(saveName)) {
                onSave({ id: cuid(), name: saveName });
              }
            }
          }}
        />
        <footer>
          <button
            disabled={!nameValid}
            onClick={(_evt) => {
              if (saveName && nameValidator(saveName)) {
                onSave({ id: cuid(), name: saveName });
              }
            }}
            className="apply"
          >
            <i className="far fa-check" />
            Save
          </button>
          <button onClick={onClose} className="reset">
            <i className="fas fa-ban" />
            Cancel
          </button>
        </footer>
      </ModalBody>
    </Modal>
  );
};
