import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateGroupBy } from 'src/components/Subheader/Subheader.slice';
import { fetchSummaryViewData, cleanUp } from './SummaryView.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/inputIsNotNullOrUndefined';
import { isNil } from 'lodash';
import {
  ConfDefnComponentType,
  SummaryViewComponent,
  maybeGetComponentProps,
  isSameComponentType,
  isWorklistActive,
} from 'src/services/configuration/codecs/confdefnComponents';
import { updateSelectedItem } from 'src/pages/AssortmentBuild/Worklist/Worklist.slice';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getAggBys, ExtraPivotOptions, organizeListDataOptions } from '../StyleColorReview.slice';
import { RECEIVE_SCOPE_REFRESH_TRIGGER } from 'src/components/ScopeSelector/ScopeSelector.actions';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';

export const summaryViewLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      RECEIVE_SCOPE_REFRESH_TRIGGER,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      setActiveSubPage.type,
      updateSelectedItem.type,
      updateGroupBy.type
    ),
    map(() => maybeGetComponentProps<SummaryViewComponent>(state$.value, ConfDefnComponentType.summaryView)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns, topMembers }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const aggBy = getAggBys(state$.value.subheader, dataApi);
      const options: ExtraPivotOptions =
        isWorklistActive(state$.value) && !isNil(topMembers) ? { topMembers, aggBy } : { aggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      if (isSameComponentType<SummaryViewComponent>(state$.value, ConfDefnComponentType.summaryView)) {
        console.info('navigation to same component type detected, cleaning up redux state before data load');
        return of(cleanUp(), fetchSummaryViewData(finalModelDefn, finalOptions));
      }

      return of(fetchSummaryViewData(finalModelDefn, finalOptions));
    })
  );
};
