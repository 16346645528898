import React from 'react';
import _ from 'lodash';
import { Renderer } from 'src/utils/Domain/Renderer';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { toSummaries } from 'src/utils/Pivot/RollUp';
import { Options, TooltipFormatterContextObject, SeriesPieOptions, Series } from 'highcharts';
import { baseConfig } from './ProductMix';
import HighchartsReact from 'highcharts-react-official';

const LEGEND_HEIGHT = 80;

interface SeriesWithCenter extends Series {
  // [centerX, centerY, size, innerSize]
  // taken from highcharts docs at
  // https://github.com/highcharts/highcharts/blob/master/ts/Series/CenteredUtilities.ts#L118
  // private interface, but seemingly
  // This api is distinct from the PieOptions.center property
  center: [number, number, number, number];
}

export type RenderProps = {
  data: BasicPivotItem[];
  totals: BasicPivotItem;
  view: TenantConfigViewItem;
  config: Options;
};

export class PieRender extends React.Component<RenderProps> {
  constructor(props: RenderProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: RenderProps) {
    return !_.isEqual(this.props.totals, nextProps.totals) || !_.isEqual(this.props.data, nextProps.data);
  }

  render() {
    const { data, view, config } = this.props;
    const inConfig = _.cloneDeep(config || baseConfig);
    inConfig.legend = {
      verticalAlign: 'bottom',
      layout: 'horizontal',
      enabled: true,
      maxHeight: LEGEND_HEIGHT,
    };
    inConfig.tooltip = {
      formatter: function(this: TooltipFormatterContextObject) {
        const y = this.point.negative ? -this.point.y! : this.point.y;
        const percentage = this.point.negative ? -this.point.percentage : this.point.percentage;
        const percent = Renderer.percent(percentage / 100);
        const value = Renderer.renderJustValue(y, this.point.view);
        return `<small>
          ${this.point.name}
          </small><br />
          ${this.series.name}: <b>${percent} | ${value}</b>`;
      },
    };
    inConfig.responsive = {
      rules: [
        {
          condition: {
            maxWidth: 1000,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                size: '90%',
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 460,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                size: '80%',
              },
            },
          },
        },
      ],
    };
    const metrics = _.map(view.view, (v) => {
      return {
        title: v.text,
        data: _.map(data, (d) => ({
          name: d.name,
          y: Math.abs(d[v.dataIndex]),
          view: v,
          negative: d[v.dataIndex] < 0 ? true : false,
        })),
        info: _.map(v.view, (subView) => {
          // toSummaries takes formulas from the viewdefn and sums them up
          const summarize = toSummaries(data, [subView])[0];
          const info = `${summarize.label}: ${summarize.rendered}`;
          return info;
        }),
      };
    });

    inConfig.chart!.events = {
      // This uses an internal highcharts api to render arbitrary text in the chart
      // after the chart has rendered
      // this exists because some of the options either dont exist (pietitle),
      // or are awkward to do in the normal api (label several pies separately)
      render: function(this: Highcharts.Chart) {
        // the below is for the text inside the pie circles
        this.series.forEach((s, i) => {
          const ser = s as SeriesWithCenter;
          if (metrics[i]) {
            if (this['label' + i]) {
              this['label' + i].destroy();
            }
            this['label' + i] = this.renderer
              .label(metrics[i].info.join('<br>'), 0, 0)
              .css({
                color: '#000',
              })
              .attr({
                padding: 8,
                r: 5,
                zIndex: 6,
              })
              .add();
            //center the label
            this['label' + i].translate(
              ser.center[0] + this.plotLeft - this['label' + i].getBBox().width / 2,
              ser.center[1] - 18
            );

            // this is for all the pie titles
            if (view && view.view) {
              if (this['title' + i]) {
                this['title' + i].destroy();
              }
              this['title' + i] = this.renderer
                .label(`<h5>${view.view[i].text}</h5>`, 0, 0, undefined, undefined, undefined, true)
                .css({
                  color: '#000',
                })
                .attr({
                  padding: 8,
                  r: 5,
                  zIndex: 6,
                })
                .add();
              //center the label
              this['title' + i].translate(
                ser.center[0] + this.plotLeft - this['title' + i].getBBox().width / 2,
                ser.center[1] - this.plotHeight / 2
              );
            }
          }
        });
      },
    };

    const pies: SeriesPieOptions[] = _.map(metrics, (res, ind) => {
      return {
        type: 'pie',
        name: 'Legend Dummy Series',
        visible: true,
        label: { enabled: false },
        dataLabels: {
          enabled: false,
        },
        data: res.data,
        innerSize: '70%',
        center: [`${16.5 + 33 * ind}%`],
      };
    });

    // dummy series to show the legend, does not have real data
    pies.push({
      type: 'pie',
      visible: true,
      size: '0%',
      align: 'right',
      dataLabels: { enabled: false },
      verticalAlign: 'bottom',
      showInLegend: true,
      data: _.map(data, (d) => ({ name: d.name, y: 0 })),
    });

    const chartComponent = (
      <HighchartsReact
        options={{ ...inConfig, series: pies }}
        containerProps={{
          style: { width: '100%', height: '100%' },
        }}
      />
    );

    return chartComponent;
  }
}
