import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import { Divider } from '@material-ui/core';
import { z } from 'zod';
import { isNil } from 'lodash';

import { TrendSummary, TrendSummaryConfig } from 'src/components/TrendSummary/TrendSummary';
import { TrendDetails } from 'src/components/TrendDetails/TrendDetails';
import { ASSORTMENT, lookBackPeriodProp } from 'src/utils/Domain/Constants';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { SubheaderViewDefns } from 'src/components/Subheader/Subheader.slice';
import { requestTrendsConfig, receiveTrendsConfig } from './QuickTrends.slice';
import container from 'src/ServiceContainer';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { QuickTrendsComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { Overlay } from 'src/common-ui';
import Subheader from 'src/components/Subheader/Subheader.container';
import { ViewDataState } from 'src/types/Domain';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { quickTrendsStyles } from './QuickTrends.styles';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { getAggBys } from '../../StyleColorReview/StyleColorReview.slice';

export type QuickTrendsOwnProps = z.infer<typeof QuickTrendsComponentProps>;

export type QuickTrendsStateProps = {
  hasAggBys: boolean;
  loaded: boolean;
  trendsSummaryDataState: ViewDataState;
  subheaderViewDefns: SubheaderViewDefns;
  summaryConfig: TrendSummaryConfig | null;
  detailsConfig: TenantConfigViewData | null;
  summaryData: BasicPivotItem[];
};

export type QuickTrendsDispatchProps = {
  onShowView: () => void;
};

export type QuickTrendsProps = QuickTrendsDispatchProps & QuickTrendsStateProps;

function mapStateToProps(state: AppState, ownProps: QuickTrendsOwnProps): QuickTrendsStateProps {
  const { defns } = ownProps;
  const {
    viewDefnState,
    summaryViewDefn,
    detailsViewDefn,
    trendsSummaryDataState,
  } = state.pages.hindsighting.quickTrends;
  const loaded = isViewDefnLoaded(viewDefnState) && isDataLoaded(trendsSummaryDataState);
  const hasAggBys = getAggBys(state.subheader, undefined, { includeBottomLevel: false }) !== '';

  const summaryData = getUniqueDataFromCache(state.pages.hindsighting.quickTrends, HashType.trendsSummary)?.tree || [];
  const lookBackPredicate = makelookBackPredicate(state.subheader.lookBackPeriod);
  const filteredSummaryData = cascadingFilter(summaryData, lookBackPredicate);

  return {
    loaded,
    hasAggBys,
    summaryData: filteredSummaryData,
    summaryConfig: summaryViewDefn,
    detailsConfig: detailsViewDefn,
    trendsSummaryDataState,
    subheaderViewDefns: defns.subheader,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, { defns }: QuickTrendsOwnProps): QuickTrendsDispatchProps {
  return {
    onShowView: async () => {
      dispatch(requestTrendsConfig());
      const [summaryViewDefn, detailsViewDefn] = await container.tenantConfigClient.getTenantViewDefns<
        Array<TrendSummaryConfig | TenantConfigViewData>
      >({
        defnIds: defns.view,
        appName: ASSORTMENT,
      });
      dispatch(
        receiveTrendsConfig({
          summaryViewDefn: (summaryViewDefn as unknown) as TrendSummaryConfig,
          detailsViewDefn: (detailsViewDefn as unknown) as TenantConfigViewData,
        })
      );
    },
  };
}

const QuickTrends = ({
  onShowView,
  trendsSummaryDataState,
  summaryConfig,
  detailsConfig,
  loaded,
  hasAggBys,
  subheaderViewDefns,
  summaryData,
}: QuickTrendsProps) => {
  useEffect(() => {
    onShowView();
  }, [onShowView]);

  const configs = useMemo(() => {
    return !loaded || isNil(summaryConfig) || isNil(detailsConfig)
      ? undefined
      : {
          summaryConfig,
          detailsConfig,
        };
  }, [loaded, summaryConfig, detailsConfig]);

  let childData: BasicPivotItem[] = [];
  if (!isNil(summaryData) && summaryData.length > 0) {
    childData = (hasAggBys) ? summaryData[0].children : summaryData;
  }
  return (
    <div className={quickTrendsStyles.root}>
      <Subheader
        title={''}
        hideTitle={false}
        showFlowStatus={true}
        showLookBackPeriod={true}
        groupByDefn={subheaderViewDefns.groupBy}
        viewDataState={[trendsSummaryDataState]}
      />
      <section className={quickTrendsStyles.container}>
        {!configs ? (
          <Overlay type="loading" visible={!loaded} fitParent={true} />
        ) : (
          <React.Fragment>
            <div className={quickTrendsStyles.summary}>
              <TrendSummary config={configs.summaryConfig} data={summaryData} />
            </div>
            <Divider flexItem={true} orientation={'vertical'} />
            <div className={quickTrendsStyles.details}>
              <TrendDetails config={configs.detailsConfig} data={childData} />
            </div>
          </React.Fragment>
        )}
      </section>
    </div>
  );
};

export default connect(mapStateToProps, dispatchToProps)(QuickTrends);
