import * as fp from 'lodash/fp';
import { connect } from 'react-redux';

import container from 'src/ServiceContainer';
import { TenantConfigViewItem, TenantConfigViewData, isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { reorderWithSortBy } from 'src/pages/Hindsighting/CategoryRecap/Productivity/Productivity.selectors';
import { requestViewDefn, receiveViewDefn, receiveError } from './Productivity.slice';
import { Productivity, ValueProps, FunctionProps } from './Productivity';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { ProductivityComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { cloneDeep, flow } from 'lodash';
import { z } from 'zod';
import { AppState, AppThunkDispatch } from 'src/store';
import { isDataLoaded } from 'src/services/pivotServiceCache';

type ProductivityOwnProps = z.infer<typeof ProductivityComponentProps>;

function mapStateToProps(state: AppState, ownProps: ProductivityOwnProps): ValueProps {
  const { title = 'Productivity', defns } = ownProps;
  const viewState = state.pages.hindsighting.productivity;
  const { viewDefnState, viewDataState, viewDefn, unmodifiedViewDefn } = viewState;

  const chartConfig: TenantConfigViewItem = fp.get('viewDefn.view[0]', viewState)
    ? viewState.viewDefn.view[0]
    : { dataIndex: '__', text: '__' };
  const carouselView: TenantConfigViewItem[] = fp.get('viewDefn.view[1].view', viewState)
    ? viewState.viewDefn.view[1].view || [] // Necessary to appease typesystem.
    : [];

  const loaded = isViewDefnLoaded(viewDefnState) && isDataLoaded(viewDataState);
  const data = reorderWithSortBy(state);

  return {
    title,
    groupByViewDefnStr: defns.subheader.groupBy,
    sortByViewDefnStr: defns.subheader.sortBy,
    loaded,
    chartConfig,
    carouselView,
    data,
    groupBy: state.subheader.groupBy,
    sortBy: state.subheader.sortBy,
    flowStatus: state.subheader.flowStatus,
    viewDefn,
    unmodifiedViewDefn,
    viewDataState,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: ProductivityOwnProps): FunctionProps {
  const { defns } = ownProps;
  return {
    onShowView() {
      dispatch(requestViewDefn());
      container.tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: 'Assortment',
        })
        .then(async (resp) => {
          const unmodifiedViewDefn = cloneDeep(resp[0]);
          const localConfig: FavoriteListItemStorage | undefined = await getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch
          );
          if (localConfig && localConfig.config) {
            resp[0].view[1] = localConfig.config as any;
          }
          resp[1] = unmodifiedViewDefn;
          return resp;
        })
        .then((resp) => {
          dispatch(
            receiveViewDefn({
              viewDefn: resp[0],
              unmodifiedViewDefn: resp[1],
            })
          );
        })
        .catch((_error) => dispatch(receiveError()));
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(
        receiveViewDefn({
          viewDefn: config,
        })
      );
    },
  };
}

const sensitiveView = flow(() => Productivity)();

export default connect(mapStateToProps, dispatchToProps)(sensitiveView);
