import { ReportConfig } from 'src/dao/reportClient';

export type Action =
  | { type: 'REQUEST_REPORTING_CONFIG' }
  | { type: 'RECEIVE_REPORTING_CONFIG'; reportConfig: ReportConfig }
  | { type: 'RECEIVE_REPORTING_ERROR'; message: string }
  | { type: 'CLEAN_UP_REPORTING' };

export const REQUEST_REPORTING_CONFIG = 'REQUEST_REPORTING_CONFIG';
export function requestReportingConfig(): Action {
  return {
    type: REQUEST_REPORTING_CONFIG,
  };
}

export const RECEIVE_REPORTING_CONFIG = 'RECEIVE_REPORTING_CONFIG';
export function receiveReportingConfig(reportConfig: ReportConfig): Action {
  return {
    type: RECEIVE_REPORTING_CONFIG,
    reportConfig,
  };
}

export const RECEIVE_REPORTING_ERROR = 'RECEIVE_REPORTING_ERROR';
export function receiveError(message: string): Action {
  return {
    type: RECEIVE_REPORTING_ERROR,
    message,
  };
}

export const CLEAN_UP_REPORTING = 'CLEAN_UP_REPORTING';
export function cleanUp(): Action {
  return {
    type: CLEAN_UP_REPORTING,
  };
}
