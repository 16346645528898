import * as React from 'react';
import { Checkbox, CheckboxProps, MuiThemeProvider } from '@material-ui/core';
import { ICellRendererParams } from 'ag-grid-community';
import { muiTheme } from 'src/utils/Style/Theme';
import { style } from 'typestyle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
type Props = ICellRendererParams & {
  value?: boolean;
  onChange?: (checked: boolean, rowData?: any) => void;
  isEditable: boolean;
  allowIndeterminate?: boolean;
};

type State = {
  checked: boolean;
};

const checkStyle = style({
  $debugName: 's5-checkbox',
  width: '100%',
  textAlign: 'center',
});

export default class CheckboxCellRenderer extends React.Component<Props, State> {
  checkMap = {
    on: {
      checked: true,
      indeterminate: false,
    },
    off: {
      checked: false,
      indeterminate: false,
    },
    indeterminate: {
      checked: false,
      indeterminate: true,
    },
  };
  constructor(props: Props) {
    super(props);

    // for `allowIndeterminate` we assume/require the values are sent in
    // in valid boolean format.
    if (this.props.allowIndeterminate) {
      this.state = {
        checked: this.props.value,
      };
    } else {
      this.state = {
        checked: props.value ? true : false,
      };
    }
  }

  componentWillUnmount() {
    // Any destruction calls need to be done here, not in destroy()
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.checked);
    }

    if (this.props.isEditable) {
      if (this.props.setValue) {
        this.props.setValue(!this.props.value);
      }
    }
  };

  onClick = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  getValue() {
    return this.state.checked;
  }

  isPopup() {
    return false;
  }

  // this is how we determine indeterminance for standard checkboxes
  // if value is not true/false it's "indeterminant" (any other than
  // checked/notchecked essentially)
  getCheckSetting = (checked: unknown) => {
    if (checked === true) {
      return this.checkMap['on'];
    } else if (checked === false) {
      return this.checkMap['off'];
    } else {
      return this.checkMap['indeterminate'];
    }
  };

  render() {
    const { isEditable, allowIndeterminate } = this.props;
    const { checked } = this.state;
    let statusProps: Partial<CheckboxProps>;
    if (allowIndeterminate) {
      statusProps = this.getCheckSetting(checked);
    } else {
      statusProps = {
        checked: checked,
      };
    }

    return (
      <MuiThemeProvider theme={muiTheme}>
        {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleOutlineIcon />}
          className={checkStyle}
          onChange={this.handleChange}
          disabled={!isEditable}
          disableRipple={!isEditable}
          {...statusProps}
        />
      </MuiThemeProvider>
    );
  }
}
