import React from 'react';
import {
  sortBy,
  reduce,
  isNil,
  every,
  findIndex,
  keys,
  values,
  sum,
  orderBy,
  forEach,
  isEmpty,
  pickBy,
  concat,
  isNumber,
  uniq,
  get,
  defaults,
} from 'lodash';
import { produce } from 'immer';

import {
  ConfigurableDataConfig,
  FormattedSizingData,
  RenderSectionType,
  ConfigurableDataSectionRowTotal,
  ConfigurableDataSectionColumnConfigs,
  ConfigurableDataRules,
  ConfigurableDataSectionRowCellConfig,
  ConfigurableDataRuleEditDependencies,
  ConfigurableDataModalCellEditParams,
  ConfigurableDataModalClientHandler,
  ViewSizingData,
  ServerSizingData,
  ConfigurableDataColumn,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.types';
import { ConfigurableDataSection } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModalSections';
import { FINAL_QTY, LAST_PUBLISHED } from 'src/utils/Domain/Constants';
import math from 'mathjs';

export function formatDataForView(
  config: ConfigurableDataConfig,
  deserializedData: ServerSizingData[]
): FormattedSizingData {
  const { rowSortByDataIndex } = config;
  const sorted = sortBy(deserializedData, rowSortByDataIndex);

  return reduce(
    sorted,
    (acc, sizingData) => {
      const currentWeek = sizingData.week;
      return produce(acc, (draftData) => {
        if (!isNil(draftData[currentWeek])) {
          draftData[currentWeek].items.push(sizingData as ViewSizingData);
        } else {
          draftData[currentWeek] = { items: [sizingData as ViewSizingData] };
        }
      });
    },
    {} as FormattedSizingData
  );
}

export function formatDataForServer(serializedData: FormattedSizingData): ServerSizingData[] {
  return reduce(
    serializedData,
    (acc, weekData) => {
      const strippedData = weekData.items.map((item) => {
        return produce(item, (draftState) => {
          delete draftState.editPercentages;
        });
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return produce(acc, (draftState) => {
        draftState.push(...strippedData);
      });
    },
    [] as ServerSizingData[]
  );
}

type SimpleRecord = {
  [s: string]: unknown;
};
export function fetchEditables(
  serializedData: FormattedSizingData,
  columns: ConfigurableDataColumn[],
  keys: string[]
): SimpleRecord[] {
  const editableFields = columns.filter((c) => c.editable).map((c) => c.dataIndex);
  // Put in empty fields for all that are missing from data.
  const emptyFields = editableFields.reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {});
  return reduce(
    serializedData,
    (acc, weekData) => {
      const strippedData: SimpleRecord[] = weekData.items.map((item) => {
        return defaults(
          pickBy(item, (_v, key) => {
            return editableFields.indexOf(key) >= 0 || keys.indexOf(key) >= 0;
          }),
          emptyFields
        ) as SimpleRecord;
      });
      return concat(acc, strippedData);
    },
    [] as SimpleRecord[]
  );
}

function getNextColumnTotal(columnData: unknown, currentColumnTotal: number | null): number | null {
  let nextColumnTotal;

  if (isNil(columnData) || !isNumber(columnData)) {
    if (isNil(currentColumnTotal)) {
      // handles intial acc value and existing null value for total
      nextColumnTotal = null;
    } else {
      // prior items in week data had real values, disregard current value in total
      nextColumnTotal = currentColumnTotal;
    }
  } else {
    if (isNil(currentColumnTotal)) {
      // prior items in week data had no real values, take current columnData as new total
      nextColumnTotal = columnData;
    } else {
      // add real values together
      nextColumnTotal = currentColumnTotal + columnData;
    }
  }

  return nextColumnTotal;
}

export function calcTotalRow(dataIndexes: string[], data: ViewSizingData[]): ConfigurableDataSectionRowTotal {
  return reduce(
    data,
    (acc, itemData) => {
      return produce(acc, (draftObj) => {
        // update total for each dataIndex in row
        // we need to `uniq` this to prevent double/trippling in cases where
        // same prop is used in multiple places.
        uniq(dataIndexes).forEach((d) => {
          // for current row, get current dataIndex value
          const columnData = itemData[d];

          // get current total for dataIndex
          const currentColumnTotal = draftObj[d];
          const nextColumnTotal = getNextColumnTotal(columnData, currentColumnTotal);
          draftObj[d] = nextColumnTotal;
        });
      });
    },
    {} as Record<string, number | null>
  );
}

// renders size rows (if applicable) & total row for a section
export function renderItemsInSection(
  sectionType: RenderSectionType,
  modalEditsEnabled: boolean,
  config: ConfigurableDataConfig | null,
  data: ViewSizingData[],
  onCellEdit: (rowDataIndexValue: string, colDataIndex: string, value: number) => void
) {
  if (isNil(config)) {
    return null;
  }

  const { dataRules, dataSectionHeaders, columns } = config;
  const sectionConfig: ConfigurableDataSectionColumnConfigs = {
    modalEditsEnabled,
    dataRules: config.dataRules,
    columns,
    rowHeaderDataIndex: dataSectionHeaders.bottomRowsDataIndex,
    onCellEdit,
  };
  // get all dataIndexes that are not undefined for calculating totals
  const filteredDataIndexes = columns.map((c) => c.dataIndex).filter((c) => c);
  const totalRowData = calcTotalRow(filteredDataIndexes, data);
  let highlight = false;

  // specific to receipts only, highlight final column if any value doesn't match last published column
  if (dataRules.clientHandler === ConfigurableDataModalClientHandler.receipts) {
    highlight = totalRowData[FINAL_QTY] !== totalRowData[LAST_PUBLISHED];
  }

  switch (sectionType) {
    case RenderSectionType.top:
      return (
        <ConfigurableDataSection
          sectionHeader={dataSectionHeaders.top}
          sectionConfig={sectionConfig}
          totalRowData={totalRowData}
          highlight={highlight}
        />
      );
    case RenderSectionType.bottom:
      return (
        <ConfigurableDataSection
          sectionHeader={dataSectionHeaders.bottom}
          sectionConfig={sectionConfig}
          rowData={data}
          totalRowData={totalRowData}
          highlight={highlight}
        />
      );
    default:
      return null;
  }
}

// this requires passing consecutive checks in the array until the current cell dataIndex is reached
function testDependencyChecks(
  dataIndex: string,
  dependencies: ConfigurableDataRuleEditDependencies[],
  data: ViewSizingData | ConfigurableDataSectionRowTotal
): boolean {
  const dependencyIndex = findIndex(dependencies, (dependency) => {
    const dependent = keys(dependency)[0]; // each dependency has a single key/value pair
    return dependent === dataIndex;
  });

  return every(dependencies.slice(0, dependencyIndex + 1), (dependency, index) => {
    // this is not very flexible but matches logic in old receipts adj. calculator component
    const dependencyValue = values(dependency)[0];
    const firstTest = isNil(data[dependencyValue]);
    switch (index) {
      case 0:
        return firstTest;
      case 1:
        return !firstTest;
      default:
        return false;
    }
  });
}

export function isCellEditable(
  cellConfig: Omit<ConfigurableDataSectionRowCellConfig, 'rowHeaderDataIndexValue'>,
  dataRules: ConfigurableDataRules,
  data: ViewSizingData | ConfigurableDataSectionRowTotal
): boolean {
  const { dataIndex, editable } = cellConfig;
  if (!editable) {
    return false;
  }

  // only receiptsAdjustment modal has special column dependencies for editability
  // any other modal setup will allow edits without any additional logic
  if (!dataRules.edits.isColumnDependent) {
    return true;
  }

  const { edits } = dataRules;
  const { controlAttributes, dependencies } = edits;

  // test control attribute checks (each attribute has a value)
  // test dependency checks

  const passedControlAttributes = every(controlAttributes, (attr) => data[attr]);
  const passedDependencyChecks = testDependencyChecks(dataIndex, dependencies, data);
  return passedControlAttributes && passedDependencyChecks;
}

// calculates percentages for provided week's data
// take lookup columns (could be reference columns if initial calc or modified column if cell edit)
// total column(s) across entire week data
// maps over each week data item and determines lookup column(s) percentage(s)
function calculateWeekPercentages(
  lookupColumns: string[],
  weekItems: ViewSizingData[],
  referenceColumns?: Record<string, string>
): ViewSizingData[] {
  // total all lookup column(s), and if available referenceColumns for potential use in percentage calcs below
  const toLookup = isNil(referenceColumns) ? lookupColumns : lookupColumns.concat(values(referenceColumns));
  const lookupColumnTotals = reduce(
    toLookup,
    (acc, column) => {
      return produce(acc, (draftState) => {
        // filter values in the column column that have actual values and sum only those
        // otherwise "clear" out the total for the column column by setting to null
        const itemsWithValues = weekItems.filter((item) => !isNil(item[column]));
        if (isEmpty(itemsWithValues)) {
          draftState[column] = null;
        } else {
          draftState[column] = sum(itemsWithValues.map((item) => item[column]));
        }
      });
    },
    {}
  );

  // handle 0 total calc once, evenly distributes percentages among items in week
  const evenSplitPercentage = 1 / weekItems.length;

  return weekItems.map((weekItem) => {
    // calc each lookup percentage for the week
    const percentages = reduce(
      toLookup,
      (acc, column) => {
        let columnValue = weekItem[column] as number;
        let columnTotal = lookupColumnTotals[column];

        if (isNil(columnTotal) || columnTotal == 0) {
          if (isNil(referenceColumns)) {
            columnValue = 0;
          } else {
            // try to fall back to reference column value
            const ref = referenceColumns[column];
            columnValue = isNil(ref) ? 0 : (weekItem[ref] as number);
            columnTotal = lookupColumnTotals[ref];
          }
        }

        // takes even split if no total detected, otherwise calcs percentage from values percent of total

        const percentage = columnTotal === 0 || isNil(columnTotal) ? evenSplitPercentage : columnValue / columnTotal;

        return produce(acc, (draftState) => {
          draftState[column] = percentage;
        });
      },
      {} as Record<string, number>
    );

    return produce(weekItem, (draftItem) => {
      forEach(percentages, (percentage, column) => {
        if (draftItem.editPercentages) {
          draftItem.editPercentages[column] = percentage;
        } else {
          draftItem['editPercentages'] = {
            [column]: percentage,
          };
        }
      });
    });
  });
}

// calculates initial percentages across all weeks data
export function calculateInitialPercentages(
  config: ConfigurableDataConfig,
  data: FormattedSizingData
): FormattedSizingData {
  const editableColumns = config.columns.filter((column) => column.editable).map((column) => column.dataIndex);

  return reduce(
    data,
    (acc, weekData, week) => {
      return produce(acc, (draftState) => {
        draftState[week] = {
          items: calculateWeekPercentages(
            editableColumns,
            weekData.items,
            config.dataRules.percentage.referenceColumns
          ),
        };
      });
    },
    {} as FormattedSizingData
  );
}

function getCurrentTabKey(activeTabIndex: number, data: FormattedSizingData): string {
  const tabs = keys(data);
  const activeTabKey = tabs[activeTabIndex];
  return activeTabKey;
}

// find current week tab data
export function getCurrentWeekItems(activeTabIndex: number, data: FormattedSizingData): ViewSizingData[] {
  const activeTabKey = getCurrentTabKey(activeTabIndex, data);
  return data[activeTabKey].items;
}

// takes the value in the edited row and recalcs percentages
// for current row and all other rows under the modified column
export function modifyDataPercentages(params: ConfigurableDataModalCellEditParams): FormattedSizingData {
  const { rowDataIndexValue, colDataIndex, value, activeTabIndex, data, config } = params;
  const { bottomRowsDataIndex } = config.dataSectionHeaders;

  // find correct item (size row) to edit in week.items
  const activeWeekItems = getCurrentWeekItems(activeTabIndex, data);
  const indexToEdit = activeWeekItems.findIndex((item) => item[bottomRowsDataIndex] === rowDataIndexValue);

  // instead of just modifying the size row item,
  // iterate all items and if value is null, then take ref column value
  const modifiedWeekItems = produce(activeWeekItems, (draftItems) => {
    activeWeekItems.forEach((item, index) => {
      if (index === indexToEdit) {
        draftItems[indexToEdit][colDataIndex] = value;
      } else if (isNil(item[colDataIndex])) {
        const refColumn = config.dataRules.percentage.referenceColumns[colDataIndex];
        const refValue = item[refColumn];
        draftItems[index][colDataIndex] = refValue;
      }
    });
  });

  // re-total items for data and determine new percentages
  const activeTabKey = getCurrentTabKey(activeTabIndex, data);
  return produce(data, (draftData) => {
    draftData[activeTabKey].items = calculateWeekPercentages([colDataIndex], modifiedWeekItems);
  });
}

type Percents = {
  percent: number;
  valueIndex: number;
};
type DisaggregateData = {
  modifiedWeekItems: ViewSizingData[];
  percentages: Percents[];
  currentTotal: number | null;
};
const accumulator: DisaggregateData = {
  modifiedWeekItems: [],
  percentages: [],
  currentTotal: 0,
};

// takes the value in the total row and derives new values
// based on their existing percentages
export function disaggregateDataByPercentages(params: ConfigurableDataModalCellEditParams): FormattedSizingData {
  const { colDataIndex, value: totalValue, activeTabIndex, data, config } = params;

  // determine new values based on existing percentages in active week's items
  // storing percentages and total of modified items to potentially modify later in function
  const activeWeekItems = getCurrentWeekItems(activeTabIndex, data);
  const { modifiedWeekItems: tempWeekItems, percentages, currentTotal } = reduce(
    activeWeekItems,
    (acc: DisaggregateData, weekItem: ViewSizingData, index: number) => {
      let valuePercentage = weekItem.editPercentages![colDataIndex];

      // no edits made for current column yet so fallback to initial percentage by looking up initial percentage via reference columns
      if (isNil(valuePercentage)) {
        const colPercentMappingIndex = config.dataRules.percentage.referenceColumns[colDataIndex];
        valuePercentage = weekItem.editPercentages![colPercentMappingIndex];
      }

      const newValue = isNil(totalValue) ? null : Math.floor(valuePercentage * totalValue);
      const modifiedItem = produce(weekItem, (draftItem) => {
        draftItem[colDataIndex] = newValue;
      });

      return produce(acc, (draft) => {
        draft.modifiedWeekItems.push(modifiedItem);
        draft.percentages.push({ percent: valuePercentage, valueIndex: index });

        // newValue is null, do nothing
        // currentTotal is null, take newValue
        // both newValue and currentTotal present, add to total

        if (isNil(draft.currentTotal)) {
          draft.currentTotal = newValue;
        } else if (!isNil(newValue) && !isNil(draft.currentTotal)) {
          draft.currentTotal = draft.currentTotal + newValue;
        }
      });
    },
    accumulator
  );

  // Need to distribute additional amount (+1) to higher percentages if calc'd total is less than totalValue
  let modifiedTotal = currentTotal;
  const sortedPercentages = orderBy(percentages, ['percent'], ['desc']);
  const modifiedWeekItems = produce(tempWeekItems, (draftItems) => {
    forEach(sortedPercentages, ({ valueIndex }) => {
      if (isNil(currentTotal) || isNil(totalValue) || isNil(modifiedTotal)) {
        return false;
      } else if (modifiedTotal >= totalValue) {
        return false; // breaks loop early
      }

      (draftItems[valueIndex][colDataIndex] as number) += 1;
      modifiedTotal += 1;
      return true;
    });
  });

  const activeTabKey = getCurrentTabKey(activeTabIndex, data);
  return produce(data, (draftData) => {
    draftData[activeTabKey].items = modifiedWeekItems;
  });
}

type GridData = {
  value: string | number | null;
  cellDataIndex: string;
};

export function handleGetValue(
  config: ConfigurableDataConfig,
  editsSaved: boolean,
  data: FormattedSizingData,
  gridData: GridData
) {
  const { valueHandlerPropMappings } = config;

  if (!editsSaved) {
    // return intial value potentially with a mapped property value
    const { cellDataIndex } = gridData;
    const mappedProp = !isNil(valueHandlerPropMappings) ? valueHandlerPropMappings[cellDataIndex] : undefined;

    if (isNil(mappedProp)) {
      return;
    }

    return {
      [mappedProp]: gridData.value,
    };
  }

  // total editable rows
  const dataIndexes = config.columns.map((column) => column.dataIndex);

  const nextValues = reduce(
    data,
    (acc, weekData) => {
      return produce(acc, (draftObj) => {
        dataIndexes.forEach((d) => {
          const column = config.columns.find((i) => i.dataIndex === d);
          if (column == null || column.calculation == null) {
            draftObj[d] = sum(weekData.items.map((i) => i[d] || 0));
          } else {
            const c = math.parse(column.calculation);
            const result = sum(
              weekData.items.map((weekItem) => {
                // TODO: This needs to support nested calculation refs and properties
                // with colons.
                return c.eval({
                  ...weekItem,
                });
              })
            );
            draftObj[d] = result;
          }
        });
      });
    },
    {} as Record<string, number | null>
  );

  const convertedPropValues = reduce(
    dataIndexes,
    (acc, dataIndex) => {
      return produce(acc, (draftState) => {
        // Use mapped property if in config, otherwise return using assigned index from column defn
        // if (valueHandler[dataIndex] )
        const mappedDataIndex = get(valueHandlerPropMappings, dataIndex, dataIndex);

        const value = nextValues[dataIndex];
        draftState[mappedDataIndex] = value;
      });
    },
    {} as Record<string, number | null>
  );

  // at this point we need to include any null/undefined values in response
  // if undefined is included, merges/assignment will typically ignore property
  // null is coerced as number since the shape of output is altered and expects numbers only
  const mappedKeys = keys(convertedPropValues);
  const formattedValue = produce(convertedPropValues, (draftState) => {
    mappedKeys.forEach((d) => {
      const currentTotal = draftState[d];
      draftState[d] = isNil(currentTotal) ? ((null as unknown) as number) : currentTotal;
    });
  });

  return formattedValue;
}
