import React from 'react';
import { partial } from 'lodash/fp';
import { connect, Dispatch } from 'react-redux';

import { isEmpty, noop, flow } from 'lodash';

import { AppState, AppThunkDispatch } from 'src/store';
import ColumnGroupedView, {
  StateProps as FloorsetComparisonStateProps,
  DispatchProps,
} from 'src/components/StandardCardView/ColumnGroupedView';
import container from 'src/ServiceContainer';
import { generateGroupBy } from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.container';
import { getProcessedData } from './FloorsetComparison.selectors';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';

import { ASSORTMENT } from 'src/utils/Domain/Constants';
import SubheaderDropdown from 'src/components/Subheader/SubheaderDropdown';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FloorsetDropdownProps, FloorsetDropdownItem, FloorsetComparisonOwnProps } from './FloorsetComparison.types';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { withFab, FabType } from 'src/components/higherOrder/withFab';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import {
  receiveError,
  receiveTenantConfig,
  requestTenantConfig,
  setGroupingInfo,
  setSelectedFloorsetIndex,
} from './FloorsetComparison.slice';
import { BaseAction } from 'redux-actions';
import numbro from 'numbro';
import { isDataLoaded } from 'src/services/pivotServiceCache';

let onCustomElChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
function handleCustomElChange(dispatch: Dispatch<BaseAction>, event: React.ChangeEvent<HTMLSelectElement>) {
  const position = numbro(event.currentTarget.dataset.position).value();
  setTimeout(() => {
    dispatch(setSelectedFloorsetIndex(position));
  });
}

const FloorsetDropdown = (props: FloorsetDropdownProps) => {
  if (isEmpty(props.floorsets)) {
    return null;
  }

  const mappedFloorsets = props.floorsets.map((floorset: FloorsetDropdownItem) => {
    return {
      dataIndex: '', // here to satisfy options type for SubheaderDropdown
      text: floorset.id,
    };
  });

  return (
    <SubheaderDropdown
      label={'Floorsets'}
      options={mappedFloorsets}
      selection={props.selectedFloorsetIndex >= 0 ? props.selectedFloorsetIndex : 0} // default to first item
      handleChangeOnDropdown={onCustomElChange}
    />
  );
};

function mapStateToProps(state: AppState, ownProps: FloorsetComparisonOwnProps): FloorsetComparisonStateProps {
  const { floorsetComparison: viewState } = state.pages.assortmentBuild;
  const {
    title = 'Floorset Comparison',
    keys,
    defns,
    groupingInfo,
    fabType = FabType.none,
    showPopover,
    fabTooltip,
  } = ownProps;
  const groupedStyles = getProcessedData(state);
  const groupBy = generateGroupBy(groupingInfo.dataIndex);
  const floorsets = viewState.floorsets;
  const loaded = isViewDefnLoaded(viewState.viewDefnState) && isDataLoaded(viewState.viewDataState);

  return {
    title,
    loaded,
    config: viewState.viewDefn,
    showCountLimit: false,
    showPopover: showPopover,
    sortBy: state.subheader.sortBy,
    groupBy,
    subheaderViewDefns: defns.subheader,
    groupedStyles,
    summary: [], // Required for view component
    idProp: keys.idProp,
    descProp: keys.descProp || '',
    subheaderCustomEl: isEmpty(floorsets) ? (
      undefined
    ) : (
      <FloorsetDropdown floorsets={floorsets} selectedFloorsetIndex={viewState.selectedFloorsetIndex} />
    ),
    unmodifiedViewDefn: viewState.unmodifiedViewDefn,
    fabType,
    fabTooltip,
    isFabDisabled: false,
    viewDataState: viewState.viewDataState,
    fabDefn: defns.fab,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: FloorsetComparisonOwnProps): DispatchProps {
  const { tenantConfigClient } = container;
  const { defns, groupingInfo } = ownProps;

  // setup event handler with access to dispatch
  onCustomElChange = partial(handleCustomElChange, [dispatch]);

  return {
    onShowView() {
      dispatch(requestTenantConfig());
      dispatch(setGroupingInfo(groupingInfo));
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch
          );
          if (localConfig && localConfig.config) {
            resp[0] = localConfig.config;
          }
          dispatch(
            receiveTenantConfig({
              viewDefn: resp[0],
              calcViewDefn: resp[1],
              unmodifiedViewDefn: unmodifiedViewDefn,
            })
          );
        })
        .catch(() => dispatch(receiveError()));
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        receiveTenantConfig({
          viewDefn: config,
        })
      );
    },
    showStylePane: noop,
    onItemClicked: noop,
  };
}

function mergeProps(stateProps: FloorsetComparisonStateProps, dispatchProps: DispatchProps) {
  return {
    ...stateProps,
    ...dispatchProps,
  };
}

const wrappedView = flow(() => ColumnGroupedView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(wrappedView);
