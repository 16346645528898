import { TenantConfigViewData, ViewDefnState } from 'src/dao/tenantConfigClient';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ViewDataState } from 'src/types/Domain';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import serviceContainer from 'src/ServiceContainer';
import { cacheCheckFetchPivotData } from '../../StyleColorReview/StyleColorReview.slice';

export type ProductMixSlice = {
  viewDefn: TenantConfigViewData | null;
  cacheHash: string | null;
  viewDataState: ViewDataState;
  viewDefnState: ViewDefnState;
};

const initialState: ProductMixSlice = {
  viewDefn: null,
  cacheHash: null,
  viewDataState: ViewDataState.idle,
  viewDefnState: ViewDefnState.idle,
};

const productMixReducer = createSlice({
  name: 'ProductMix',
  initialState,
  reducers: {
    requestViewDefn(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveViewDefn(state, action: PayloadAction<TenantConfigViewData>) {
      state.viewDefnState = ViewDefnState.loaded;
      state.viewDefn = action.payload;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.regularDataLoading;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.cacheBackgroundDataLoading;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        const cacheInUse = state.viewDataState === ViewDataState.cacheBackgroundDataLoading;
        state.viewDataState = cacheInUse ? ViewDataState.cacheBackgroundDataReady : ViewDataState.regularDataReady;
      }
    },
    cleanUp() {
      return initialState;
    },
    receiveError() {
      return initialState;
    },
  },
});

export const {
  requestViewDefn,
  receiveViewDefn,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  cleanUp,
  receiveError,
} = productMixReducer.actions;

export function fetchProductMixData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData,
    true
  );
}

export default productMixReducer.reducer;
