import { isNil } from 'lodash';
import LRUMap from 'mnemonist/lru-map';
import hash from 'object-hash';
import ServiceContainer from 'src/ServiceContainer';
import { ViewDataState } from 'src/types/Domain';
import { FitViewOptions, ListDataOptions, Pivot } from 'src/worker/pivotWorker.types';

// SPIKE: style edit needs 6 requests per style preview call + 1 for the companion
// bumping this to 14 allows for two style edits to be in cache +1 buffer
const DEFAULT_CAPACITY = 14;

export type HasCache = {
  viewDataState: ViewDataState;
  cachedData: Pivot;
  liveData: Pivot;
};

type HasCacheHash = {
  cacheHash: string | null; // do we need a map<hash,dataState>?
};

/**
 * Used to distinguish which hash type to return from the cache.
 *
 * This is currently only used in Product Details because it is both a unique slice structure
 * and stores both a macro and grid cache. It is possible to be extended for other views as needed.
 */
export enum HashType {
  macro,
  grid,
  flowsheetCompanion,
  flowsheetOverTime,
  pricingCompanion,
  pricingOverTime,
  paretoSummaryData,
  paretoAnalysisData,
  chart,
  trendsSummary,
  trendsDetails,
}

// TODO: make more generic names that can be referenced via enum in getUniqueDataFromCache

type HasUniqueCacheHash = {
  cacheHashCompanion?: string | null;
  cacheHashOverTime?: string | null;
  // macro/grid for Product Details slice
  macroCacheHash?: string | null;
  gridCacheHash?: string | null;
  chartCacheHash?: string | null;
  summaryCacheHash?: string | null;
  analysisCacheHash?: string | null;
  // quick trends summary/details
  trendsSummaryCacheHash?: string | null;
  trendsDetailsCacheHash?: string | null;
};

export function getDataFromCache<T extends HasCacheHash>(slice: T): Pivot | undefined {
  const maybeCacheHash = slice.cacheHash;
  const cacheHasHash = maybeCacheHash ? ServiceContainer.pivotService.pivotCache.has(maybeCacheHash) : false;
  return maybeCacheHash && cacheHasHash ? ServiceContainer.pivotService.pivotCache.get(maybeCacheHash) : undefined;
}

/**
 * This is for views that require a slightly different structure for their view data state
 * (i.e. Pricing Over Time, Flow Sheet by Style, Product Details)
 *
 * @param {T extends HasUniqueCacheHash} slice - view with unique data state slice
 * @param {HashType} hashType - used for views whose slice contains multiple `HasUniqueCacheHash` values to distinguish which cache data to return
 * @returns {Pivot} pivot or undefined if not present in provided slice's cache
 */
export function getUniqueDataFromCache(slice: HasUniqueCacheHash, hashType: HashType): Pivot | undefined {
  let maybeCacheHash;

  if (
    slice.cacheHashCompanion &&
    (hashType === HashType.flowsheetCompanion || hashType === HashType.pricingCompanion)
  ) {
    maybeCacheHash = slice.cacheHashCompanion;
  } else if (
    slice.cacheHashOverTime &&
    (hashType === HashType.flowsheetOverTime || hashType === HashType.pricingOverTime)
  ) {
    maybeCacheHash = slice.cacheHashOverTime;
  } else if (slice.macroCacheHash && hashType === HashType.macro) {
    maybeCacheHash = slice.macroCacheHash;
  } else if (slice.gridCacheHash && hashType === HashType.grid) {
    maybeCacheHash = slice.gridCacheHash;
  } else if (slice.chartCacheHash && hashType === HashType.chart) {
    maybeCacheHash = slice.chartCacheHash;
  } else if (slice.summaryCacheHash && hashType === HashType.paretoSummaryData) {
    maybeCacheHash = slice.summaryCacheHash;
  } else if (slice.analysisCacheHash && hashType === HashType.paretoAnalysisData) {
    maybeCacheHash = slice.analysisCacheHash;
  } else if (slice.trendsSummaryCacheHash && hashType === HashType.trendsSummary) {
    maybeCacheHash = slice.trendsSummaryCacheHash;
  } else if (slice.trendsDetailsCacheHash && hashType === HashType.trendsDetails) {
    maybeCacheHash = slice.trendsDetailsCacheHash;
  }

  const cacheHasHash = maybeCacheHash ? ServiceContainer.pivotService.pivotCache.has(maybeCacheHash) : false;
  return maybeCacheHash && cacheHasHash ? ServiceContainer.pivotService.pivotCache.get(maybeCacheHash) : undefined;
}

/**
 * @deprecated Going forward, no longer want to store data in view slice. Use getDataFromCache instead.
 * Given a slice with live and cached data, it returns the proper data based off of the viewDataState
 *
 * @param {T extends HasCache} slice a view slice that contains liveData, cacheData and a viewDataState
 * @returns {Pivot} Unaltered pivot data
 */
export function getDataToRender<T extends HasCache>(slice: T): Pivot {
  let data = slice.liveData;

  if (
    slice.viewDataState === ViewDataState.regularDataReady ||
    slice.viewDataState === ViewDataState.cacheBackgroundDataReady
  ) {
    data = slice.liveData;
  } else if (slice.viewDataState === ViewDataState.cacheBackgroundDataLoading) {
    data = slice.cachedData;
  }

  return data;
}

/**
 * Given a viewDataState, it returns if it the data is loaded or not
 *
 * @param viewDataState The view data state enum
 * @returns boolean
 */
export function isDataLoaded(viewDataState: ViewDataState): boolean {
  return (
    viewDataState === ViewDataState.regularDataReady ||
    //viewDataState === ViewDataState.cacheBackgroundDataLoading ||
    viewDataState === ViewDataState.cacheBackgroundDataReady
  );
}

export enum RequestEndpoint {
  listData = 'listData',
  fitView = 'fitView',
}

type HashInputs = {
  endpoint: RequestEndpoint | string;
  defnId: string;
  inputs: ListDataOptions | FitViewOptions;
};

export class PivotServiceCache {
  cache = new LRUMap<string, Pivot>(DEFAULT_CAPACITY);
  inflightRequestMap = new Map<string, Promise<any>>();

  /**
   * Generates a hash from the provided inputs.
   * Objects are sorted before hashing by default
   */
  static hash({ endpoint, defnId, inputs }: HashInputs): string {
    return hash({
      endpoint,
      defnId,
      ...inputs,
    });
  }

  has(hash: string): boolean {
    return this.cache.has(hash);
  }

  isInflight(hash: string): boolean {
    return this.inflightRequestMap.has(hash);
  }

  get(hash: string) {
    return this.cache.get(hash);
  }

  getInflightPromise(hash: string) {
    return this.inflightRequestMap.get(hash);
  }

  set(hash: string, pivotData: Pivot) {
    const result = this.cache.setpop(hash, pivotData);

    if (!isNil(result) && result.evicted) {
      console.log('cache capacity reached, least recently used item dropped');
    } else {
      console.log(`pivot data added to cache, size is currently ${this.cache.size}`);
    }
  }

  setInflightRequest(hash: string, promise: Promise<any>) {
    this.inflightRequestMap.set(hash, promise);
  }

  deleteInflightRequest(hash: string) {
    // SPIKE: better to just delete on cache.set()?
    this.inflightRequestMap.delete(hash);
  }

  clear() {
    this.cache.clear();
    // SPIKE: should we cancel all the inflights here?
    this.inflightRequestMap.clear();
  }
}

export function makePivotServiceCache() {
  return new PivotServiceCache();
}
