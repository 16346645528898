import { isNil } from 'lodash';
import { HINDSIGHTING_PATH } from './Constants';

export const IS_HINDSIGHTING = (str?: string) => {
  if (isNil(str)) {
    // rather than error just fail check
    return false;
  }

  return str.localeCompare(HINDSIGHTING_PATH, undefined, { sensitivity: 'base' }) === 0;
};
