import React from 'react';
import { connect } from 'react-redux';
import { isNil, get, uniqBy, flow, isEmpty } from 'lodash';

import { Overlay } from 'src/common-ui/index';
import CompanionListView, {
  Props as CompanionProps,
  SortDirection,
} from 'src/common-ui/components/CompanionListView/CompanionListView';

import { listPairStyle, companionStyles } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import SubheaderContainer from 'src/components/Subheader/Subheader.container';
import { OvertimeView } from './OvertimeView';
import { BasicPivotItem, ListDataOptions } from 'src/worker/pivotWorker.types';
import ServiceContainer from 'src/ServiceContainer';
import { ASSORTMENT, ID, ASSORTMENT_BUILD_FILTER_ALL_WARNING } from 'src/utils/Domain/Constants';
import { CompanionOvertimeConfig } from './OvertimeView.types';
import container from 'src/ServiceContainer';
import { simpleByField } from 'src/utils/Pivot/Sort';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { AppState } from 'src/store';
import { getHeaderTextFromState } from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { resolvePath } from 'src/cdn';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { NestedOvertimeComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { CompanionDataLookup, parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';

const noImage = resolvePath(noImagePath);

type NestedStyleOvertimeOwnProps = z.infer<typeof NestedOvertimeComponentProps>;

export type NestedStyleOvertimeContainerProps = NestedStyleOvertimeOwnProps & {
  customHeader: string;
};

type AllStates = {
  aggBys: string[];
  loaded: boolean;
  data?: BasicPivotItem[];
  viewConfig?: CompanionOvertimeConfig;
  listConfig?: CompanionDataLookup;
  search?: string;
  companionView?: {
    selectedItemId: string;
    sortDirection: SortDirection;
    sortBy: string;
  };
  companionCollapsed?: boolean;
  companionListData?: BasicPivotItem[];
};
type State = AllStates;
class NestedStyleOvertimeContainer extends React.Component<NestedStyleOvertimeContainerProps, State> {
  constructor(props: NestedStyleOvertimeContainerProps) {
    super(props);
    this.state = {
      aggBys: ['style', 'stylecolor'],
      loaded: false,
    };
  }

  componentDidMount() {
    (async () => {
      await this.loadConfig();
      await this.loadCompanionView();
      await this.loadData();
    })();
  }

  async onRefetchData() {
    this.setState({
      loaded: false,
    });
    await this.loadCompanionView();
    await this.loadData();
  }

  loadConfig = async () => {
    const client = container.tenantConfigClient;
    const configResponse = await client.getTenantViewDefn<CompanionOvertimeConfig>({
      defnId: this.props.defns.view[0],
      appName: 'Assortment',
    });
    const [, ...moreDefns] = this.props.defns.view;
    const moreConfigResponses = await client.getTenantViewDefns({
      defnIds: moreDefns,
      appName: 'Assortment',
    });
    this.setState({
      viewConfig: configResponse,
      listConfig: parseCompanionListViewConfig(moreConfigResponses[0]),
    });
  };

  loadCompanionView = async () => {
    if (this.state.viewConfig && this.props.companionDataApi) {
      const { companionGrid } = this.state.viewConfig;
      const companionData = uniqBy(
        (
          await ServiceContainer.pivotService.listData(this.props.companionDataApi.params.defnId, ASSORTMENT, {
            ...this.props.companionDataApi.params,
          })
        ).flat,
        'id'
      ).filter((i) => i['member:class:id'] != null);

      const sortedData = simpleByField(companionData, companionGrid.sortConfig.defaultSelection, 'desc');

      const selectedItemId = !isEmpty(sortedData) ? sortedData[0][ID] : '';
      this.setState({
        companionListData: sortedData,
        companionView: {
          selectedItemId,
          sortDirection: 'desc',
          sortBy: companionGrid.sortConfig.defaultSelection,
        },
      });
    }
  };

  loadData = async () => {
    const { companionView, viewConfig } = this.state;
    if (isNil(companionView) || isNil(companionView.selectedItemId) || isNil(viewConfig) || isNil(this.props.dataApi)) {
      this.setState({
        ...this.state,
        loaded: true,
        data: [],
      });
      return;
    }
    const dataArgs: ListDataOptions = {
      ...this.props.dataApi.params,
      topMembers: companionView.selectedItemId,
    };
    const data = (await ServiceContainer.pivotService.listData(this.props.dataApi.params!.defnId, ASSORTMENT, dataArgs))
      .tree;

    this.setState({
      ...this.state,
      loaded: true,
      data,
    });
  };

  renderCompanionView = () => {
    if (
      isNil(this.state.companionListData) ||
      isNil(this.state.listConfig) ||
      isNil(this.state.companionView) ||
      isNil(this.state.viewConfig)
    ) {
      return null;
    }
    const companionData = companionDataParse(
      this.state.companionListData,
      this.state.listConfig,
      get(this.state.companionView, 'sortDirection') || 'desc',
      get(this.state.companionView, 'sortBy') || 'name'
    ).filter((i) => i.title !== '' && i.title != null);
    const { defaultSelection, options: sortOptions } = this.state.viewConfig.companionGrid.sortConfig;
    const defaultSelectionIndex = sortOptions.findIndex((option) => {
      return option.dataIndex === defaultSelection;
    });
    const currentSelectionInd = companionData.findIndex((option) => {
      return this.state.companionView && option.id === this.state.companionView.selectedItemId;
    });
    const companionProps: CompanionProps = {
      defaultSelection: defaultSelectionIndex >= 0 ? defaultSelectionIndex : 0,
      selectedIndex: currentSelectionInd >= 0 ? currentSelectionInd : 0,
      sortOptions,
      label: this.state.viewConfig.companionGrid.label,
      data: companionData,
      className: companionStyles,
      dataLookup: this.state.listConfig,
      onListItemClicked: (classId: string) => {
        if (this.state.companionView == null || this.state.companionView.selectedItemId === classId) {
          return;
        }
        this.setState(
          {
            companionView: {
              ...this.state.companionView,
              selectedItemId: classId,
            },
            loaded: false,
          },
          () => {
            this.loadData();
          }
        );
      },
      onChangeDirection: (direction) => {
        if (this.state.companionView == null || direction === this.state.companionView.sortDirection) {
          return;
        }
        this.setState({
          companionView: {
            ...this.state.companionView,
            sortDirection: direction,
          },
        });
      },
      onSortSelection: (sortOption) => {
        if (this.state.companionView == null || sortOption.dataIndex === this.state.companionView.sortBy) {
          return;
        }
        this.setState({
          companionView: {
            ...this.state.companionView,
            sortBy: sortOption.dataIndex,
          },
        });
      },
      onToggleCollapse: (isCollapsed) => {
        this.setState({
          companionCollapsed: isCollapsed,
        });
      },
      noImageUrl: noImage,
    };
    return <CompanionListView {...companionProps} />;
  };

  render() {
    const { title = 'Style Color Over Time' } = this.props;
    return (
      <div className={listPairStyle}>
        <SubheaderContainer
          title={title}
          showSearch={true}
          showFlowStatus={false}
          errorCondition={ASSORTMENT_BUILD_FILTER_ALL_WARNING}
          searchReturn={(newVal: string) => {
            this.setState({
              search: newVal,
            });
          }}
        />
        <div className={'data-container'}>
          {this.renderCompanionView()}
          {!isNil(this.state.viewConfig) ? (
            <React.Fragment>
              <OvertimeView
                viewConfig={this.state.viewConfig}
                rowHeight={get(this.state.viewConfig, 'main.rowHeight', 30)}
                aggBys={this.state.aggBys}
                treeData={this.state.data || []}
                search={this.state.search}
                companionOpen={!this.state.companionCollapsed}
                hasCompanion={true}
                exportOptions={{
                  fileName: title,
                  customHeader: this.props.customHeader,
                }}
              />
            </React.Fragment>
          ) : (
            <div />
          )}
        </div>
        <Overlay visible={!this.state.loaded} type={'loading'} qaKey={'overtime-loading'} />
      </div>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: NestedStyleOvertimeOwnProps): NestedStyleOvertimeContainerProps {
  const customHeader = getHeaderTextFromState(state, true);
  return {
    ...ownProps,
    customHeader,
  };
}

const sensitiveView = flow(() => NestedStyleOvertimeContainer, makeScopeAndFilterSensitive)();

export default connect(mapStateToProps)(sensitiveView);
