import * as React from 'react';
import styles from './Headerbar.styles';

export interface HeaderbarItemData {
  id?: string;
  text: string;
  iconClass: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export default function TopNavIconLink(props: HeaderbarItemData) {
  const { id, text, iconClass, onClick, style } = props;
  return (
    <span>
      <a
        id={id}
        className={styles.headerbarRightLink}
        href="javascript:void(null)"
        onClick={onClick}
        data-qa="HeaderbarItem"
        style={style}
      >
        {/*<i className="far fa-usd-circle" data-qa="HeaderbarReconIcon"/>*/}
        <i className={`${iconClass} ${styles.iconRightSpace}`} data-qa="HeaderbarItemIcon" />
        <span>{text}</span>
      </a>
    </span>
  );
}
