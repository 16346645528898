import { connect } from 'react-redux';
import { Lens, fromTraversable, Prism } from 'monocle-ts';
import { Traversable } from 'fp-ts/lib/Array';
import { isNil } from 'lodash';

import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  WorklistOwnProps,
  WorklistValueProps,
  WorklistDispatchProps,
  WorklistConfig,
} from 'src/pages/AssortmentBuild/Worklist/Worklist.types';
import {
  requestWorklistConfig,
  receiveWorklistConfig,
  WorklistSlice,
  updateSelectedItem,
  updateSelectedLevel,
  updateWorklistFlowStatus,
  updateWorklistSearch,
} from 'src/pages/AssortmentBuild/Worklist/Worklist.slice';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { worklistLens, subheaderLens } from 'src/services/lenses/lenses';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import Worklist from 'src/pages/AssortmentBuild/Worklist/Worklist';
import { updateSearch } from 'src/components/Subheader/Subheader.slice';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { update } from 'src/services/lenses/Lenses.actions';
import { getProcessedData } from './Worklist.selectors';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { dispatchUpdateAssortmentPlan } from '../StyleEdit/StyleEdit.client';
import { GroupByDefnColumn } from 'src/dao/tenantConfigClient';

export function mapStateToProps(state: AppState, ownProps: WorklistOwnProps): WorklistValueProps {
  const { title = 'Workflow', defns, fabType, showFlowStatus } = ownProps;
  const viewState = worklistLens.get(state);
  const subheader = subheaderLens.get(state);
  const { filteredStyles, treeStyles } = getProcessedData(state);
  const isLoading = viewState.isConfigLoading || !isDataLoaded(viewState.viewDataState);

  return {
    title,
    config: viewState.viewDefn,
    styles: filteredStyles,
    treeStyles,
    subheaderSlice: subheader,
    subheaderViewDefns: defns.subheader,
    isLoading,
    viewDataState: viewState.viewDataState,
    showLevel: ownProps.showLevel,
    fabType: fabType || FabType.none,
    selectedLevel: viewState.selectedLevel,
    flowStatus: viewState.worklistFlowStatus,
    flowStatusConfig: subheader.flowStatusConfig,
    showFlowStatus: showFlowStatus == true ? true : false,
  };
}

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: WorklistOwnProps): WorklistDispatchProps {
  const { defns } = ownProps;

  return {
    async onShowView() {
      // retrieve configs
      dispatch(requestWorklistConfig());
      const configs = await container.tenantConfigClient.getTenantViewDefns({
        defnIds: defns.view,
        appName: ASSORTMENT,
      });

      const [worklistConfig] = configs;
      if (worklistConfig) {
        dispatch(receiveWorklistConfig((worklistConfig as unknown) as WorklistConfig));
      }
    },
    updateSearchString(value: string) {
      dispatch(updateSearch(value));
    },
    updateCompanionSearchString(value: string) {
      dispatch(updateWorklistSearch(value));
    },
    selectItem(item: string) {
      dispatch(updateSelectedItem(item));
    },
    selectLevel(level: GroupByDefnColumn) {
      dispatch(updateSelectedLevel(level));
    },
    updateStyleItem(styleId: string, propToUpdate: string, propValue: string) {
      const updated = worklistLens
        // TODO: we have to be smarter about this with caching coming in.
        .compose(Lens.fromPath<WorklistSlice>()(['liveData', 'flat']))
        .composeTraversal(fromTraversable(Traversable)())
        .composePrism(Prism.fromPredicate((item) => item.id === styleId))
        .modify((item: BasicPivotItem) => {
          return {
            ...item,
            [propToUpdate]: propValue,
          };
        });

      dispatch(update(updated, 'Edit Styles loaded'));
    },
    updateAssortmentPlan: () => {
      dispatchUpdateAssortmentPlan(dispatch);
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateWorklistFlowStatus(values));
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(makePrintSensitive(withFab(Worklist)));
