const validValuesCache: {
  [url: string]: unknown[];
} = {};

export function getItemFromCache(url: string) {
  return validValuesCache[url];
}
export function addItemToCache(url: string, values: unknown[]) {
  validValuesCache[url] = values;
}

export function clearCache() {
  for (const prop in validValuesCache) {
    if (validValuesCache.hasOwnProperty(prop)) {
      delete validValuesCache[prop];
    }
  }
}
