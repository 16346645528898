import * as React from 'react';
import { FilterValue } from './Filters.interfaces';

import styles from './Filters.styles';

export default class FilterSectionItem extends React.PureComponent<FilterValue> {
  checkBoxRef: React.RefObject<HTMLInputElement>;

  constructor(props: FilterValue) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.checkBoxRef = React.createRef();
  }

  handleChange() {
    const { id, onToggleFilter, filterDefnId, selected } = this.props;

    if (onToggleFilter) {
      onToggleFilter(id, filterDefnId, !selected);
    }
  }

  render() {
    const { id, selected, disabled } = this.props;

    return (
      <div className="filter-item" data-qa-component="filter-item" data-qa-key={id}>
        <label className={disabled ? 'disabled' : undefined}>
          <input
            data-qa-action={'select-filter-section-item'}
            ref={this.checkBoxRef}
            type="checkbox"
            className={styles.filterCheckbox}
            checked={selected}
            onChange={this.handleChange}
            disabled={disabled && !selected}
          />
          {id}
        </label>
      </div>
    );
  }
}
