import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs';
import { AppEpic } from './store';
import { resetCartEpic } from 'src/pages/AssortmentBuild/AssortmentBuild.epics';
import {
  setActivePageComponentPropsEpic,
  setActivePageFilterSensitivityEpic,
} from 'src/pages/NavigationShell/NavigationShell.epics';
import { summaryViewLoad } from 'src/pages/Hindsighting/StyleColorReview/SummaryView/SummaryView.epics';
import { canvasViewLoad } from './pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.epics';
import { collectionViewLoad } from './pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.epics';
import { floorsetComparisonLoad } from './pages/AssortmentBuild/FloorsetComparison/FloorsetComparison.epics';
import { nestedViewLoad } from './pages/Hindsighting/HistoryGrid/NestedView/NestedView.epics';
import { listViewLoad } from './pages/Hindsighting/HistoryGrid/ListView/ListView.epics';
import {
  worklistCleanUp,
  worklistConfigLoaded,
  worklistCompanionLoad,
} from './pages/AssortmentBuild/Worklist/Worklist.epics';
import { productDetailsLoad } from './pages/Hindsighting/AggregateViews/ProductDetails/ProductDetails.epic';
import { nestedAttributeLoad } from './pages/Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.epic';
import { paretoAnalysisLoad } from './pages/Hindsighting/Performance/ParetoAnalysis/ParetoAnalysis.epic';
import { categorySummaryLoad } from './pages/Hindsighting/CategoryRecap/CategorySummary/CategorySummary.epic';
import { productMixLoad } from './pages/Hindsighting/CategoryRecap/ProductMix/ProductMix.epic';
import { productivityLoad } from './pages/Hindsighting/CategoryRecap/Productivity/Productivity.epic';
import { assortmentAddLoad } from './pages/AssortmentBuild/AssortmentAdd/AssortmentAddView.epic';
import { gridViewLoad } from './pages/Hindsighting/StyleColorReview/GridView/GridView.epics';
import { topPerformersLoad } from './pages/Hindsighting/MacroTrends/TopPerformers/TopPerformers.epics';
import { flowTypeLoad } from './pages/Hindsighting/StyleColorReview/FlowType/FlowType.epics';
import { topTyLyLoad } from './pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.epics';
import {
  enhancedOvertimeDataLoad,
  enhancedOvertimeConfigLoad,
} from 'src/components/EnhancedOvertime/EnhancedOvertime.epics';
import { quickTrendsLoad } from './pages/Hindsighting/MacroTrends/QuickTrends/QuickTrends.epics';
import { bulkImportViewLoad } from './pages/Reporting/BulkImport/BulkImport.epics';
import {
  configurableGridConfigLoad,
  configurableGridFloorsetLoad,
  configurableGridLoad,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.epics';

export const rootEpic: AppEpic = (action$, store$, dependencies) =>
  combineEpics(
    resetCartEpic,
    setActivePageComponentPropsEpic,
    setActivePageFilterSensitivityEpic,
    gridViewLoad,
    summaryViewLoad,
    canvasViewLoad,
    floorsetComparisonLoad,
    listViewLoad,
    nestedViewLoad,
    worklistCleanUp,
    worklistConfigLoaded,
    worklistCompanionLoad,
    productDetailsLoad,
    nestedAttributeLoad,
    paretoAnalysisLoad,
    categorySummaryLoad,
    productMixLoad,
    productivityLoad,
    assortmentAddLoad,
    collectionViewLoad,
    topPerformersLoad,
    flowTypeLoad,
    topTyLyLoad,
    enhancedOvertimeDataLoad,
    enhancedOvertimeConfigLoad,
    quickTrendsLoad,
    bulkImportViewLoad,
    configurableGridConfigLoad,
    configurableGridFloorsetLoad,
    configurableGridLoad
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      // final backstop error handler for epics
      console.error(error);
      return source;
    })
  );
