import React, { useCallback } from 'react';
import { Collapse } from 'reactstrap';

import { NavigationItem } from './NavigationItem';
import styles from 'src/components/Sidenav/Sidenav.styles';
import { BoundSection, BoundTab, BoundView, BoundPerspective } from 'src/services/configuration/bindings.types';

interface NavigationGroupProps {
  expandable?: boolean;
  expanded: boolean;
  activeViewFrom: boolean;
  perspective: BoundPerspective;
  tab: BoundTab;
  section: BoundSection;
  setFocusedGroup: (groupId: string) => void;
  onItemClick: () => void;
}

export function computeItemLink(
  perspective: BoundPerspective,
  tab: BoundTab,
  section: BoundSection,
  view: BoundView
): string {
  return '/' + [perspective.pathSlot, tab.pathSlot, section.pathSlot, view.pathSlot].join('/');
}

export const NavigationGroup = ({
  perspective,
  tab,
  section,
  expanded,
  activeViewFrom,
  expandable = true,
  setFocusedGroup,
  onItemClick,
}: NavigationGroupProps) => {
  const toggleGroup = useCallback(() => {
    setFocusedGroup(section.id);
  }, [section.id, setFocusedGroup]);

  const onNavigationItemClick = useCallback(() => onItemClick(), [onItemClick]);

  return (
    <div className={`${styles.title}`}>
      <div
        data-qa="NavigationGroupToggle"
        onClick={toggleGroup}
        style={{ cursor: expandable ? 'pointer' : 'default' }}
        className={styles.groupCollapser(expanded, activeViewFrom)}
      >
        <div className="col-1">
          <i className={section.icon} />
        </div>
        <div className="col-8">{section.name}</div>
        <div className="col-1" style={{ visibility: expandable ? 'visible' : 'hidden' }}>
          <i
            className={`fas fa-angle-down ${expanded ? styles.expandedCaret : styles.collapsedCaret}`}
            data-qa={`${section.id}-caret`}
          />
        </div>
      </div>
      <Collapse isOpen={expanded}>
        <ul className="nav flex-column">
          {section.boundViews
            .filter((view) => !view.hidden)
            .map((view) => (
              <NavigationItem
                name={view.name}
                icon={view.icon}
                link={computeItemLink(perspective, tab, section, view)}
                disabled={view.disabled}
                key={`${perspective.id}-${tab.id}-${section.id}-${view.id}`}
                qa={`${view.id}-link`}
                onClick={onNavigationItemClick}
              />
            ))}
        </ul>
      </Collapse>
    </div>
  );
};
