import { Dispatch, connect } from 'react-redux';
import { TargetList } from '../TargetList/TargetList';
import {
  ValueProps as TLValProps,
  FunctionProps as TLFuncProps,
  TargetListReduxSlice,
  OwnProps,
} from '../TargetList/TargetList.types';
import { update } from 'src/services/lenses/Lenses.actions';
import { Lens } from 'monocle-ts';
import { deleteTargetItem, getTargetList, TargetType } from '../Criteria/Criteria.client';
import { merge } from 'lodash';
import { capitalize, last, get } from 'lodash/fp';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { TargetSettingConfiguration, TargetSettingReduxSlice } from '../TargetSetting/TargetSetting.types';
import { getRangeLists } from 'src/dao/scopeClient';
import { Scope } from 'src/types/Scope';
import { ScopeSelectorSlice } from 'src/components/ScopeSelector/ScopeSelector.reducer';
import { targetListLens, scopeLens } from 'src/services/lenses/lenses';
import { AppState, AppThunkDispatch } from 'src/store';

function mapListStateToProps(state: AppState): TLValProps {
  const { rowData, viewConf } = targetListLens.get(state);
  return {
    rowData: rowData || [],
    config: viewConf || null,
  };
}

function getTargetType(): TargetType {
  const hashparams = window.location.hash.split('/');
  return capitalize(last(hashparams) || 'product') as TargetType;
}

async function updateScopeRanges(dispatch: Dispatch<AppState>) {
  const response = await getRangeLists();
  const rangeLens = Lens.fromProp<AppState>()('scope').compose(
    Lens.fromProps<ScopeSelectorSlice>()(['rangeList', 'pastRangeList', 'daysRangeList', 'daysPastRangeList'])
  );
  dispatch(update(rangeLens.set(response)));
}

function pullDateInformationFromScope(ownProps: OwnProps) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    // we're going to dispatch update our list stuff
    // TODO: This will go in "TargetCreation" ...obviously
    if (/*rangeList === []*/ true) {
      let state = getState();
      if (!get('scope.scope.valid', state)) {
        await updateScopeRanges(dispatch);
        state = getState();
      }
      const client = container.tenantConfigClient;
      const targetType = ownProps.type;
      const viewDefnName = targetType === 'Product' ? 'StrategyProductTargetList' : 'StrategyLocationTargetList';
      const viewConf = await client
        .getTenantViewDefns<TargetSettingConfiguration>({
          defnIds: [viewDefnName],
          appName: ASSORTMENT,
        })
        .then((resp) => {
          return resp[0];
        });
      const { rangeList, pastRangeList, daysRangeList, daysPastRangeList } = state.scope;
      getTargetList({
        type: targetType,
      }).then((resp) => {
        const tData = resp.filter((item) => {
          return item.version === 'WP';
        });

        const rowData = tData.map((item) => {
          return merge(item, item.data);
        });
        rowData.forEach((item) => {
          if (item.targetSetup && item.targetSetup.versions == null) {
            item.targetSetup.versions = [];
            resp.forEach((innerItem) => {
              if (innerItem.id === item.id) {
                item.targetSetup.versions.push(innerItem.version);
              }
            });
          }
        });
        dispatch(
          update(
            targetListLens.compose(Lens.fromProps<TargetListReduxSlice>()(['rowData', 'viewConf'])).set({
              rowData,
              viewConf,
            }),
            'Load TargetList Data'
          )
        );
      });
      return dispatch(
        update(
          targetListLens.modify(
            (targetList: TargetListReduxSlice): TargetListReduxSlice => {
              return {
                ...targetList,
                targetNew: {
                  ...targetList.targetNew,
                  productId: scopeLens.compose(Lens.fromProp<Scope>()('productMember')).get(state) || '',
                  locationId: scopeLens.compose(Lens.fromProp<Scope>()('locationMember')).get(state) || '',
                  targetReceipt: {
                    loaded: true,
                    rangeList: rangeList,
                    daysRangeList,
                  },
                  targetSales: {
                    loaded: true,
                    rangeList: rangeList,
                    daysRangeList,
                  },
                  lyReceipt: {
                    loaded: true,
                    rangeList: pastRangeList,
                    daysRangeList: daysPastRangeList,
                  },
                  lySales: {
                    loaded: true,
                    rangeList: pastRangeList,
                    daysRangeList: daysPastRangeList,
                  },
                },
              };
            }
          ),
          'Load in TargetCreation defaults from scope.'
        )
      );
    }
  };
}

function mapListDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): TLFuncProps {
  return {
    onShowView: () => {
      dispatch(pullDateInformationFromScope(ownProps));
    },
    onRefetchData: () => {
      const hash = window.location.hash.match(new RegExp(`^#${ownProps.match.url}/([A-x\\-]*)`));
      if (hash) {
        window.location.hash = hash[0];
      }
      dispatch(
        update(
          targetListLens.compose(Lens.fromProp<TargetListReduxSlice>()('rowData')).set([]),
          'Clearing state for reload.'
        )
      );
      dispatch(pullDateInformationFromScope(ownProps));
    },
    onRowAction: (action: string, selectedRow: TargetSettingReduxSlice) => {
      const resp = selectedRow.id;
      let version: string;
      switch (action) {
        case 'View OP':
          version = 'OP';
          break;
        case 'View RP':
          version = 'RP';
          break;
        case 'Edit WP':
          version = 'WP';
          break;
        case 'Remove':
          return deleteTargetItem({
            type: getTargetType(),
            id: resp,
          }).then(() => {
            return dispatch(pullDateInformationFromScope(ownProps));
          });
        default:
          return;
      }
      window.location.hash = `${window.location.hash}/${resp}/${version}`;
      return null;
    },
  };
}

export default connect(mapListStateToProps, mapListDispatchToProps)(TargetList);
