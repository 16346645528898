import * as fp from 'lodash/fp';
import { debounce, cloneDeep, isNil, isEqual, size } from 'lodash';
import { connect, Dispatch } from 'react-redux';
import QRCategorySummaryView from './QRCategorySummary';
import container from 'src/ServiceContainer';
import selectAndProjectState from './QRCategorySummary.selectors';
import { ASSORTMENT, LEVEL_PROD_ROOT } from 'src/utils/Domain/Constants';
import {
  Action,
  receiveQRCategorySummaryConfig,
  receiveQRCategorySummaryData,
  requestQRCategorySummaryConfigs,
  requestQRCategorySummaryData,
  receivePageError,
  cleanUp,
  receiveQRCategoryViewDefns,
} from './QRCategorySummary.actions';
import { AppState, AppThunkDispatch } from 'src/store';
import { StateProjection } from './QRCategorySummary.selectors';
import { WrappedThunkDispatch, wrappedDispatch } from 'src/utils/Redux/Dispatch';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { updateQRCategoryGroupBy, updateQRCategoryFloorset } from './QRCategorySummary.actions';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { fetchFloorsetsAndSelect } from 'src/pages/AssortmentBuild/FloorsetComparison/FloorsetComparison.slice';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { QuickReconConf } from '../ReconcileModal';

type QRCategorySummaryContainerProps = {
  floorsetApi: ViewApiConfig;
  quickReconConf: QuickReconConf;
};

function mergeProps(
  mappedState: StateProjection,
  // wrapped dispatch that has thunks instead of regular actions
  dispatchProps: WrappedThunkDispatch,
  ownProps: QRCategorySummaryContainerProps
) {
  const { dispatch } = dispatchProps;
  const { floorsetApi, quickReconConf } = ownProps;
  const { defns, configApi } = quickReconConf;
  async function getData(_dispatch: AppThunkDispatch, getState: () => AppState) {
    const floorsetCmpState = getState().pages.assortmentBuild.floorsetComparison;
    if (size(floorsetCmpState.floorsets) <= 0) {
      await dispatch(fetchFloorsetsAndSelect());
    }
    let selectedIndex = floorsetCmpState.selectedFloorsetIndex;
    if (isNil(selectedIndex) || selectedIndex < 0) {
      selectedIndex = 0;
    }
    if (size(floorsetCmpState.floorsets) <= 0) {
      return;
    }
    let floorsetId = '';
    floorsetId = floorsetCmpState.floorsets[selectedIndex].id;
    const { pivotService } = container;
    const { groupBy, floorset } = getState().quickReconcile;

    // we are not using flow status on QR modal
    dispatch(requestQRCategorySummaryData());

    const dataOptions: ListDataOptions = {
      aggBy: LEVEL_PROD_ROOT,
    };
    if (groupBy) {
      dataOptions.aggBy = `${dataOptions.aggBy},${getGroupBySelectedOptionProperty(groupBy, 'dataIndex')}`;
    }

    pivotService
      .listData(defns.model, ASSORTMENT, {
        ...dataOptions,
        topMembers: floorset || floorsetId,
      })
      .then((data) => {
        return dispatch(
          receiveQRCategorySummaryData({
            gridData: data.tree[0].children,
          })
        );
      })
      .catch(() => dispatch(receivePageError('get data error')));
  }

  async function maybeFetchData(_dispatch: Dispatch<AppState>, getState: () => AppState): Promise<Action | void> {
    const state = getState();
    const { floorsetUpdated, groupByUpdated } = state.quickReconcile;

    if (floorsetUpdated || groupByUpdated) {
      getData(dispatch, getState);
    }
  }
  const debouncedFetch = debounce(() => dispatch(maybeFetchData), 200);
  const { tenantConfigClient } = container;
  return {
    ...mappedState,
    groupByDefn: defns.subheader.groupBy,
    floorsetApi,
    configApi,
    onShowView() {
      const tenantParams = {
        defnIds: defns.view,
        appName: ASSORTMENT,
      };
      dispatch(requestQRCategorySummaryConfigs());

      Promise.all([tenantConfigClient.getTenantViewDefns(tenantParams)])
        .then(([configs]) => {
          const defaultGroupByConf = configs[0];
          const currentGroupBy = cloneDeep(mappedState.groupBy);
          dispatch(
            receiveQRCategoryViewDefns({
              groupBy: defaultGroupByConf,
            })
          );

          dispatch(
            receiveQRCategorySummaryConfig({
              groupByDefn: defaultGroupByConf,
              gridDefn: configs[1],
              floorsetDefn: configs[2],
            })
          );

          if (!isNil(currentGroupBy.selection)) {
            if (!isEqual(defaultGroupByConf.view, currentGroupBy.options)) {
              const currentGroupBySelection = currentGroupBy.options[currentGroupBy.selection];
              const newGroupByIndex = defaultGroupByConf.view.findIndex(
                (opt) => opt.dataIndex === currentGroupBySelection.dataIndex
              );
              if (newGroupByIndex >= 0) {
                dispatch(updateQRCategoryGroupBy(newGroupByIndex));
              }
            } else {
              dispatch(updateQRCategoryGroupBy(currentGroupBy.selection));
            }
          }
        })
        .catch(() => dispatch(receivePageError('get config error')));
    },
    onUpdateView() {
      // TODO fix this properly, subheader update + onShowView makes multiple calls shortly after mount
      debouncedFetch();
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    onRefetchData() {
      dispatch(getData);
    },
    onUpdateGroupBy(newGroup: number) {
      dispatch(updateQRCategoryGroupBy(newGroup));
    },
    onUpdateFloorset(newFloorset: string) {
      dispatch(updateQRCategoryFloorset(newFloorset));
    },
  };
}

const sensitiveView = fp.flow(() => QRCategorySummaryView, makePrintSensitive)();

export default connect(selectAndProjectState, wrappedDispatch, mergeProps)(sensitiveView);
