import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import Axios from 'src/services/axios';
import {
  Button,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent
} from '@material-ui/core';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { connect } from 'react-redux';

import { AppState, AppThunkDispatch } from 'src/store';
import { useGoBack, useGoForward, WizardActions, WizardSection } from './BulkImport.utils';
import { getNotices, uploadMutationTemplate } from './BulkImport.actions';
import { BulkImportLoadingState, BulkImportNotice } from './BulkImport.slice';
import { isEmpty, isNil, noop } from 'lodash';
import Subheader from 'src/components/Subheader/Subheader.container';
import { BULKIMPORT_API } from './BulkImport.client';
import { bulkImportStyles } from './BulkImport.styles';
import { toast } from 'react-toastify';
import type { Response } from 'src/worker/pivotWorker.types';
import * as qs from 'query-string';
import {makeUpdateAssortmentFabSensitive} from 'src/pages/AssortmentBuild/HigherOrder/UpdateAssortmentFab'

import { findIndex } from 'lodash';

import SubheaderDropdown, { SubheaderDropdownProps } from 'src/components/Subheader/SubheaderDropdown';
import {
  floorsetDataToDropdown,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.utils';
import { BasicItem } from 'src/types/Scope';

type BulkImportOwnProps = {};
type BulkImportValueProps = ReturnType<typeof mapStateToProps>;
type BulkImportDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type BulkImportProps = BulkImportValueProps & BulkImportDispatchProps & BulkImportOwnProps;

const mapStateToProps = (state: AppState) => {
  const { mutations, bulkImportLoadingState } = state.bulkImport;
  return {
    bulkImportLoadingState,
    mutations,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    dispatchedUploadMutationTemplate: (payload: { mutationId: string; formData: FormData }) =>
      dispatch(uploadMutationTemplate(payload)),
    dispatchedGetNotices: (runId: string) => dispatch(getNotices(runId)),
  };
};

const BulkImport = (props: BulkImportProps) => {
  const {
    dispatchedUploadMutationTemplate,
    bulkImportLoadingState,
    mutations,
  } = props;
  const uploadButtonRef = useRef<HTMLInputElement>(null);

  const [selectedMutation, setSelectedMutation] = useState<undefined | string>(mutations ? mutations[0].id : undefined);
  const [floorsetDropdown, setFloorsetDropdown] = useState<undefined | SubheaderDropdownProps>(undefined);
  const [expanded, setExpanded] = useState(0);
  const [notices, setNotices] = useState<undefined | BulkImportNotice>(undefined);

  // TODO: maybe move this to an epic?
  useEffect(() => {
    if (bulkImportLoadingState === BulkImportLoadingState.mutationsLoading) {
      setSelectedMutation(undefined);
      setFloorsetDropdown(undefined);
      setExpanded(0);
      setNotices(undefined);
    }
  }, [bulkImportLoadingState]);

  const handleChangeFloorsetDropdown = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (!floorsetDropdown) {
        return;
      }
      const newValue = event.target.value;
      const selectedFloorset = findIndex(floorsetDropdown.options, (option) => {
        return option.text === newValue;
      });
      setFloorsetDropdown({
        ...floorsetDropdown,
        selection: selectedFloorset,
      });
    },
    [floorsetDropdown]
  );

  const downloadUrl = useMemo(() => {
    if (isNil(selectedMutation) || isEmpty(mutations) || isNil(mutations)) return;
    const currentMutationConfig = mutations.find((mut) => mut.id === selectedMutation);
    if (!currentMutationConfig) return;
    const floorsetInDownloadUrl = currentMutationConfig.floorsetApi && floorsetDropdown && !isNil(floorsetDropdown.selection) && floorsetDropdown.selection !== -1;
    if (floorsetInDownloadUrl) {
      const params = { floorset: floorsetDropdown.options[floorsetDropdown.selection!].id }
      const stringifiedParams = qs.stringify(params);
      return `asst/${BULKIMPORT_API}/${selectedMutation}/template?${stringifiedParams}`
    }
    return `asst/${BULKIMPORT_API}/${selectedMutation}/template`;
  }, [selectedMutation, mutations, floorsetDropdown])

  useEffect(() => {
    // wrapped in an IIFE because useEffect should not return asynchronuously
    if (!floorsetDropdown) {
      (async () => {
        if (mutations && selectedMutation) {
          const currentMutationConfig = mutations.find((mut) => mut.id === selectedMutation);
          if (currentMutationConfig?.floorsetApi) {
            const { url, params: floorsetParams } = currentMutationConfig.floorsetApi;
            // retrieve floorset dropwdown data
            try {
              const floorsets = await Axios.get<Response<BasicItem[]>>(url, { params: floorsetParams }).then(
                (resp) => resp.data.data
              );
              setFloorsetDropdown({
                label: 'Floorset',
                defaultSelection: 0,
                selection: 0,
                handleChangeOnDropdown: handleChangeFloorsetDropdown,
                options: floorsets.map(floorsetDataToDropdown),
              });
            } catch (e) {
              toast.error('An error occured downloading the floorset information for this template');
              // something went wrong, so reset the user
              setSelectedMutation(undefined);
            }
          }
        }
      })();
    }
  }, [floorsetDropdown, handleChangeFloorsetDropdown, mutations, selectedMutation]);

  /* Stepper methods */
  const goForward = useGoForward(setExpanded, 2);
  const goBack = useGoBack(setExpanded);

  /* Dropdown methods*/
  const setSelectedMutationCallback = useCallback((_evt, value) => {
    setSelectedMutation(value.props.value);
  }, []);

  const handleChangeFileUpload = useCallback(
    async (evt: React.ChangeEvent<HTMLInputElement>) => {
      setNotices(undefined); // reset notices on upload
      const fileList = evt.target.files;
      if (!fileList || !selectedMutation) return;
      const excelFile = fileList[0];
      const formData = new FormData();

      formData.append('bulkimport-upload', excelFile);
      const mutationUpload = await dispatchedUploadMutationTemplate({ mutationId: selectedMutation, formData });
      if (uploadMutationTemplate.fulfilled.match(mutationUpload)) {
        const notices = mutationUpload.payload;
        setNotices(notices);
        setExpanded(1);
      }
      if (uploadMutationTemplate.rejected.match(mutationUpload)) {
        toast.error('An error occured fetching your upload feedback', {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    },
    [dispatchedUploadMutationTemplate, selectedMutation]
  );

  const handleOnClickClearFiles = useCallback(() => {
    // manually clear the file list when the button is clicked
    // so that if the user selects opens the input twice and selects the same file,
    // it will still trigger the onChange
    if (uploadButtonRef.current) {
      uploadButtonRef.current.value = '';
    }
  }, []);

  /* Grid methods */
  const gridApiRef = useCallback(
    (grid) => {
      if (grid && grid.api && notices) {
        try {
          grid.api.setRowData(
            'problems' in notices
              ? notices.problems.map((problem) => {
                return {
                  id: problem.rowId,
                  message: problem.message,
                };
              })
              : undefined // Dont put data in if there are no errors
          );
        } catch (error) {
          noop();
        }
        grid.api.hideOverlay();
      }
    },
    [notices]
  );
  const handleGridReady = useCallback((event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  }, []);

  return (
    <div className={bulkImportStyles}>
      <section className="header-content">
        <Subheader title={'Bulk Import'} />
      </section>
      <Stepper activeStep={expanded} className="bulk-import-stepper" orientation="vertical">
        <Step expanded={expanded === WizardSection.select}>
          <StepLabel className={'step-header'}>
            <Typography variant={'h6'}>Download or Upload a Template</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <div className="dropdown-group">
              <label className="dropdown-group-label">Select Template</label>
              <Select
                data-qa="bulk-import-mutation-select"
                labelId="select-mutation"
                className="dropdown"
                id="select-mutation"
                value={selectedMutation}
                onChange={setSelectedMutationCallback}
              >
                {mutations
                  ? mutations.map((mut) => (
                    <MenuItem key={mut.id} value={mut.id}>
                      {mut.name}
                    </MenuItem>
                  ))
                  : null}
              </Select>
              {floorsetDropdown ? <SubheaderDropdown {...floorsetDropdown} /> : null}
            </div>

            <React.Fragment>
              <Typography variant="h6" component="h6">
                Download a fresh template, or upload a completed one:
              </Typography>
              <Grid container alignItems="center">
                <Button
                  variant="contained"
                  color="secondary"
                  className="bulkimport-download"
                  disabled={bulkImportLoadingState !== BulkImportLoadingState.idle || !selectedMutation}
                  startIcon={<i className={'fa fa-cloud-download'} />}
                  href={downloadUrl ? downloadUrl : ' '}
                  download={downloadUrl ? downloadUrl : ' '}
                  onClick={handleOnClickClearFiles}
                >
                  Download
                </Button>
                <input
                  ref={uploadButtonRef}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="bulkimport-upload-btn"
                  multiple={false}
                  disabled={bulkImportLoadingState !== BulkImportLoadingState.idle || !selectedMutation}
                  onChange={handleChangeFileUpload}
                  onClick={handleOnClickClearFiles}
                  style={{ display: 'none' }}
                  type="file"
                />
                <Divider orientation="vertical" flexItem />
                <span>Upload a completed</span>
                <label htmlFor="bulkimport-upload-btn">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="bulkimport-upload"
                    disabled={bulkImportLoadingState !== BulkImportLoadingState.idle || !selectedMutation}
                    component="span"
                    startIcon={<i className={'fa fa-cloud-upload'} />}
                  >
                    Upload
                  </Button>
                </label>
              </Grid>
            </React.Fragment>
            <WizardActions
              section={WizardSection.select}
              onContinue={goForward}
              onGoBack={goBack}
              disabled={isNil(notices)}
            />
          </StepContent>
        </Step>
        <Step expanded={expanded === WizardSection.notices}>
          <StepLabel className={'step-header'}>
            <Typography variant={'h6'}>Review Upload</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>

              {/* If the notices contain errors, render a grid with messages, else render success */}
              {notices && 'problems' in notices ? (
              <section className="grid-container ag-theme-material" style={{ height: '500px' }}>
              <AgGridReact
                ref={gridApiRef}
                rowHeight={40}
                onGridReady={handleGridReady}
                suppressHorizontalScroll={true}
              >
                <AgGridColumn
                  field="id"
                  headerName="Row"
                  suppressMovable={true}
                  suppressMenu={true}
                    width={100}
                    suppressSizeToFit={true}
                  cellStyle={{ textAlign: 'left' }}
                />
                <AgGridColumn
                  field="message"
                  headerName="Message"
                  suppressMovable={true}
                  suppressMenu={true}
                  cellStyle={{ textAlign: 'left' }}
                />
              </AgGridReact></section>) : <Card variant="outlined" className="success">
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Success!
                  </Typography>
                  <Typography variant="h5" component="h2">
                    <strong>{notices?.rowsLoaded}</strong> rows imported
                  </Typography>
                  <Typography variant="body2" component="p">
                    Your data was successfully imported into the plan
                  </Typography>
                </CardContent>
              </Card>}
            <WizardActions section={WizardSection.notices} onContinue={goForward} onGoBack={goBack} disabled={false} />
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};

export default makeUpdateAssortmentFabSensitive(connect<BulkImportValueProps, BulkImportDispatchProps, BulkImportOwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(BulkImport));
