import { isArray } from 'lodash';
import * as React from 'react';
import { BottomBarContainer, LookBackContainer } from './BottomBar.styles';

type BottomBarProps = {
  errorCondition?: string | string[];
  showLookBackPeriod?: boolean;
  summary?: string;
  lookBackPeriod: string;
  lookBackPeriods: string[];
  updateLookBackPeriod: (period: string) => void;
};

export default function BottomBar(props: BottomBarProps) {
  const { errorCondition, lookBackPeriod, lookBackPeriods, showLookBackPeriod, summary, updateLookBackPeriod } = props;
  const summaryClasses: string[] = ['summary'];
  if (props.errorCondition && !props.summary) {
    summaryClasses.push('push-right');
  }

  let errorNotification;
  if (errorCondition && isArray(errorCondition)) {
    errorNotification = (
      <div>
        {errorCondition.map((x) => (
          <p key={x} className={'summary-error'} data-qa="summary-error-message">
            {x}
          </p>
        ))}
      </div>
    );
  } else if (errorCondition) {
    errorNotification = (
      <p className={'summary-error'} data-qa="summary-error-message">
        {errorCondition}
      </p>
    );
  }

  let possibleLookBackPeriodJsx;
  if (showLookBackPeriod) {
    possibleLookBackPeriodJsx = (
      <div className={LookBackContainer}>
        {lookBackPeriods.map((period: string, idx: number) => {
          const id = 'look-back-' + period;
          return (
            <div className="look-back" key={idx}>
              <span className="radio-group" onClick={() => updateLookBackPeriod(period)}>
                <input
                  type="radio"
                  id={id}
                  name="look-back-radio"
                  value={period}
                  defaultChecked={period.toLocaleLowerCase() === lookBackPeriod.toLocaleLowerCase()}
                />
                <label htmlFor={id}>{period}</label>
              </span>
            </div>
          );
        })}
      </div>
    );
    summaryClasses.push('push-right');
  }

  return (
    <div className={BottomBarContainer}>
      {possibleLookBackPeriodJsx}
      {(summary || errorNotification) && (
        <div className="summary">
          {summary && summary.length > 0 && (
            <dl className="summary-list">
              {summary
                .split(' | ')
                .map((metric) => metric.split(': '))
                .map(([term, description], index) => {
                  const count = index + 1;
                  return (
                    <React.Fragment key={term}>
                      <dt className="summary-term" data-qa={`summary-term-${count}`}>
                        {term}
                      </dt>
                      <dd className="summary-description" data-qa={`summary-description-${count}`}>
                        {description}
                      </dd>
                    </React.Fragment>
                  );
                })}
            </dl>
          )}
          {errorCondition && errorNotification}
        </div>
      )}
    </div>
  );
}
