import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { isNil } from 'lodash';

import { projectState, StateSelection } from 'src/components/ListGridPair/ListGridPair.selectors';
import { FabType } from 'src/components/higherOrder/withFab';
import { GridViewOwnProps } from './GridView.container';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';

// TODO: why is nothing using selectors and memoized?

function mapStateToProps(state: AppState, ownProps: GridViewOwnProps): StateSelection {
  const { gridView: viewState, sharedData } = state.pages.hindsighting.styleColorReview;
  const {
    defns,
    title = 'Grid View',
    allowWorklistFunctionality,
    allowExceptionMultiSelect,
    fabType = FabType.none,
    hideTitle,
    hideCompanion,
  } = ownProps;

  const treeData = getDataFromCache(viewState)?.tree || [];
  const loaded = !viewState.isConfigLoading && isDataLoaded(viewState.viewDataState);

  return {
    fabType,
    fabTooltip: ownProps.fabTooltip,
    isPrintMode: state.print.isPrintMode,
    identityField: viewState.viewDefns.identityPropsConfig?.id || '',
    loaded: loaded,
    treeData,
    worklistData: sharedData.worklist,
    subheaderState: state.subheader,
    title,
    subheaderViewDefns: defns.subheader,
    viewDefns: viewState.viewDefns,
    isConfigLoading: viewState.isConfigLoading,
    shouldFilterFlowStatus: true,
    allowWorklistFunctionality: allowWorklistFunctionality ? allowWorklistFunctionality : false,
    allowExceptionMultiSelect: !isNil(allowExceptionMultiSelect) ? allowExceptionMultiSelect : false,
    activeTab: state.perspective.activeTab,
    downloadLink: ownProps.subheader?.downloadLink,
    viewDataState: viewState.viewDataState,
    hideTitle,
    hideCompanion,
    fabDefn: defns.fab,
  };
}

export const selectState = createSelector(mapStateToProps, projectState);
