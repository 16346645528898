import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import {
  CompanionDataItem,
  getId,
  GridMeasureDefn,
  RowData,
  SortDirection,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle/types';
import { GridItem } from 'src/utils/Component/AgGrid/AgDataFormat';
import { CompanionDataLookup } from 'src/utils/Component/ListView';
import { simpleByField } from 'src/utils/Pivot/Sort';

import { find, isNil } from 'lodash';
import { isArray } from 'util';
import { STYLE_ID, STYLE_DESCRIPTION, CC_COLOR } from 'src/utils/Domain/Constants';

export function companionDataParse(
  uniqueItems: GridItem[],
  companionDataLookup: CompanionDataLookup,
  sortDirection: SortDirection,
  sortField?: string,
  levelField?: string
): CompanionDataItem[] {
  let companionData = uniqueItems;

  if (sortField) {
    companionData = simpleByField(companionData, sortField, sortDirection);
  }

  if (!companionData) {
    return [];
  }

  if (levelField) {
    if (levelField === 'member:subclass') {
      const groupedCompanionData: GridItem[] = [];
      companionData.forEach((style) => {
        const id = `${levelField}:id`;
        const item = { id: style[id] };
        const existingObj = find(groupedCompanionData, item);
        if (existingObj) {
          (existingObj as BasicPivotItem).children.push(style);
        } else {
          groupedCompanionData.push({
            id: style[`${levelField}:id`],
            description: style[`${levelField}:name`],
            name: style[`${levelField}:name`],
            '@path': '',
            image: style['attribute:img:id'],
            attributevaluerank: 0,
            [`${levelField}:id`]: style[`${levelField}:id`],
            [`${levelField}:name`]: style[`${levelField}:id`],
            [`${levelField}:description`]: style[`${levelField}:description`],
            'attribute:img:id': style['attribute:img:id'],
            'attribute:img:name': style['attribute:img:name'],
            children: [],
          });
        }
      });
      companionData = groupedCompanionData;

      companionDataLookup = {
        bodyId: `${levelField}:description`,
        displayTitle: `${levelField}:name`,
        imageUrlId: 'attribute:img:id',
        starsId: '0',
        titleId: `${levelField}:id`,
      };
    }
  }

  return companionData.map((datum) => ({
    title: companionDataLookup.displayTitle ? datum[companionDataLookup.displayTitle] : undefined,
    id: datum[companionDataLookup.titleId],
    name: datum[companionDataLookup.bodyId],
    stars: parseInt(datum[companionDataLookup.starsId || ''], 10) || 0,
    imageUri: datum[companionDataLookup.imageUrlId],
  }));
}

export function convertPivotItemsToRowData(
  flowItems: BasicPivotItem[],
  viewDefns: GridMeasureDefn[],
  requiredKeys: string[] = [
    'weekadjslsu',
    'ispublishable',
    'dc_publish',
    'islocked',
    'dc_sysvrp',
    'dc_uservrp',
    'po_vrp',
  ],
  primaryField = 'product',
  secondaryField = 'time',
  extraField: string = STYLE_ID
): RowData[] {
  const items = {};
  const rowData: RowData[] = [];

  flowItems.forEach((item) => {
    let datumItem = items[item[primaryField]];
    if (!items[item[primaryField]]) {
      const styleDescription = !isNil(item[STYLE_DESCRIPTION]) ? item[STYLE_DESCRIPTION] : '';
      const ccColor = !isNil(item[CC_COLOR]) ? item[CC_COLOR] : '';
      const datumName = `${styleDescription}: ${ccColor}`;
      datumItem = items[item[primaryField]] = {
        id: item[primaryField],
        path: [item[primaryField]],
        groupId: item[primaryField],
        name: datumName,
        hidden: false,
        extraData: {},
        additionalId: item[extraField] || null,
      };
      rowData.push(datumItem);
      const requiredMeasures = {};
      requiredKeys.forEach((key) => (requiredMeasures[key] = 1));

      viewDefns.forEach((measure: GridMeasureDefn) => {
        const measureId = measure.dataIndex;
        let measureItem = datumItem[measureId];
        if (!measureItem) {
          measureItem = datumItem.extraData[measureId] = {
            id: getId(item[primaryField], measureId),
            path: [item[primaryField], measureId],
            groupId: item[primaryField],
            measureId: measureId,
            name: measure.text || measureId,
            extraData: {},
            editable: !!measure.editable,
            renderer: measure.renderer,
            hidden: !!measure['hidden'],
            inputType: measure.inputType,
            inputParams: {
              ...measure.inputParams,
            },
          };
          measureItem.extraData = {
            ...measureItem.extraData,
            calculation: measure.calculation,
            calcParamMap: measure.calcParamMap,
            valueTests: measure.valueTests,
          };
          rowData.push(measureItem);
          delete requiredMeasures[measureId];
        }
      });
      Object.keys(requiredMeasures).forEach((measureId) => {
        rowData.push({
          id: getId(item[primaryField], measureId),
          path: [item[primaryField], measureId],
          groupId: item[primaryField],
          measureId: measureId,
          extraData: {},
          hidden: true,
        });
      });
    }
    viewDefns.forEach((measure: GridMeasureDefn) => {
      const measureId = measure.dataIndex;
      const extraData = items[item[primaryField]].extraData[measureId].extraData;
      extraData[item[secondaryField]] = item[measureId];
      extraData[item[secondaryField] + '_editable'] = !!item['editable'];
      if (measure.dependentData && isArray(measure.dependentData)) {
        if (extraData[item[secondaryField] + '_dependentData'] === undefined) {
          extraData[item[secondaryField] + '_dependentData'] = {};
        }
        measure.dependentData.forEach((index) => {
          extraData[item[secondaryField] + '_dependentData'][index] = item[index];
        });
      }
    });
  });
  return rowData;
}
