import serviceContainer from 'src/ServiceContainer';
import { receivedVisualizeData, receivedVisualizeMacroData, requestVisualizeData } from './Visualize.slice';
import { ListDataConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { AppDispatch, AppState } from 'src/store';
import { WrapperOwnProps } from './Visualize.container';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { isNil } from 'lodash';

export function fetchVisualizeData(memberId: string, ownProps: WrapperOwnProps) {
  const service = serviceContainer.pivotService;
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const visState = getState().pages.assortmentBuild.visualize;
    const viewDefs = visState.vizViewDefns;
    if (isNil(viewDefs)) return;
    const dataApi = (viewDefs.graphs as any).dataApi as ListDataConfig;
    const processedDataApi = processApiParams(dataApi, ownProps.selectedItemPreviewData);
    dispatch(requestVisualizeData(memberId));
    return Promise.all([
      service.listData(processedDataApi.defnId, 'Assortment', processedDataApi.params),
      service.listData(ownProps.model ? ownProps.model : 'AssortmentBuildVisualizeMacro', ASSORTMENT, {
        topMembers: memberId,
      }),
    ]).then((visualizeData) => {
      dispatch(receivedVisualizeData(visualizeData[0]));
      dispatch(receivedVisualizeMacroData(visualizeData[1].flat));
    });
  };
}
