import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/inputIsNotNullOrUndefined';
import { ConfDefnComponentType, isOfComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { RECEIVE_SCOPE_REFRESH_TRIGGER } from 'src/components/ScopeSelector/ScopeSelector.actions';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { cleanUp } from './BulkImport.slice';
import { getMutations } from './BulkImport.actions';

export const bulkImportViewLoad: AppEpic = (action$) => {
  return action$.pipe(
    ofType(RECEIVE_SCOPE_REFRESH_TRIGGER, setActivePage.type, setActiveSubPage.type),
    map(() => isOfComponentType(ConfDefnComponentType.bulkImport, {})),
    filter(inputIsNotNullOrUndefined),
    mergeMap(() => {
      if (isOfComponentType(ConfDefnComponentType.bulkImport, {})) {
        console.info('navigation to same component type detected, cleaning up redux state before data load');
        return of(cleanUp(), getMutations());
      }

      return of(getMutations());
    })
  );
};
