import React from 'react';
import { Button, Icon, StyledComponentProps, withStyles } from '@material-ui/core';
import { classes, style } from 'typestyle';
import { isNil } from 'lodash';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const styles = () => {
  return {
    root: {
      height: '42px',
      padding: '0 0.5rem',
      textTransform: 'none',
      fontFamily: 'inherit',
    } as CSSProperties,
  };
};

const textIconStyle = style({
  marginLeft: '0.5rem',
});

// needs the fa-fw (fixed width) style that may be overwritten by root class
const iconStyle = style({
  width: '1.25em !important',
});

const rootNoText = style({
  padding: '0 0.5rem !important',
  minWidth: '40px !important',
});

/** Used as a normal button */
type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

/** Used as a download button acting as a link */
type ButtonLinkProps = {
  href?: string;
};
type BaseProps = StyledComponentProps & {
  iconClass: string;
  text?: string;
  buttonDataQa?: string;
  iconDataQa?: string;
  defaultsPresent?: boolean;
};
type Props = BaseProps & (ButtonProps | ButtonLinkProps);

const SubheaderQuickActionButton = ({
  text,
  defaultsPresent = true,
  iconClass,
  iconDataQa,
  buttonDataQa,
  ...restProps
}: Props) => {
  const buttonContents = (
    <React.Fragment>
      {text}
      <Icon
        className={classes(iconClass, 'fa-fw', isNil(text) ? iconStyle : textIconStyle)}
        data-qa={iconDataQa}
        fontSize="small"
      />
    </React.Fragment>
  );

  const buttonClasses = isNil(text) ? rootNoText : restProps.classes?.root;
  return 'href' in restProps ? (
    <Button
      className={buttonClasses}
      data-qa={buttonDataQa}
      size={'large'}
      color={defaultsPresent ? 'primary' : 'secondary'}
      href={restProps.href ? restProps.href : ''}
      target={restProps.href ? '_blank' : undefined}
      {...restProps}
    >
      {buttonContents}
    </Button>
  ) : (
    <Button
      className={buttonClasses}
      data-qa={buttonDataQa}
      size={'large'}
      color={defaultsPresent ? 'primary' : 'secondary'}
      {...restProps}
    >
      {buttonContents}
    </Button>
  );
};

export default withStyles(styles)(SubheaderQuickActionButton);
