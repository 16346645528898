import React, { Fragment, useEffect, useState } from 'react';
import ScopeSelectorDropdown from './../ScopeSelectorDropdown';
import { DispatchProps, StateProps } from './FloorsetSelectorForm.container';
import styles from '../ScopeSelector.styles';
import { QuickSelect } from 'src/dao/scopeClient';
import { find, flatten, isNil, reverse, sortBy } from 'lodash';
import container from 'src/ServiceContainer';

export type FloorsetProps = StateProps & DispatchProps;

function createQuickSelOptions(quickSelects: QuickSelect[]) {
  return flatten(
    reverse(sortBy(quickSelects, (c) => c.name)).map((sel) => {
      const children = sel.children.map((i) => ({ id: i.id, name: i.name }));
      return [
        {
          id: sel.id as string,
          name: '--' + sel.name,
        },
      ].concat(children);
    })
  );
}
// TODO: MAKE THE FLOORSET DATA PART OF LOCAL STATE
export default function FloorsetSelectorForm(props: FloorsetProps) {
  const { selectedProductMember, invalid, onChangeFloorset, onClickGetFloorset, region, selectedFloorset } = props;

  const [quickSelects, setQuickSelects] = useState([] as QuickSelect[]);
  const [selectedQS, setSelectedQS] = useState<null | string>(null);

  useEffect(() => {
    if (selectedProductMember != null) {
      // TODO: don't send below!
      container.scopeClient.getQuickSelects(selectedProductMember, region).then((response) => {
        setQuickSelects(response);
      });
      onClickGetFloorset(selectedProductMember);
      setSelectedQS(null);
    }
  }, [onClickGetFloorset, region, selectedProductMember]);

  useEffect(() => {
    // if a null selected floorset comes through, reset selected QS
    // we null the selected floorset at certain times to indicate that the selection is no longer valid
    // so reset QA when that happens also
    if (isNil(selectedFloorset)) {
      setSelectedQS(null);
    }
  }, [quickSelects, selectedFloorset, selectedQS]);

  function handleFloorsetChange(id: string): void {
    if (['quick_selects', 'floorsets'].indexOf(id) < 0) {
      // TODO: send actual data instead of idea to container
      const selection = find(flatten(quickSelects.map((i) => i.children)), (i) => i.id === id);
      if (selection) {
        setSelectedQS(id);
        onChangeFloorset(selection);
      }
    }
  }

  return (
    <Fragment>
      <ScopeSelectorDropdown
        options={createQuickSelOptions(quickSelects)}
        placeholder={'Select Floorset'}
        selection={selectedQS || undefined}
        invalid={invalid}
        locked={props.selectedProductMember === null}
        handleChangeOnDropdown={handleFloorsetChange}
        qa="dropdown-assortmentfloorset"
        applyClass={styles.scopeSelectorDropdownSmall}
      />
    </Fragment>
  );
}
