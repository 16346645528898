import { connect, Dispatch } from 'react-redux';
import { changeScope } from '../ScopeSelector/ScopeSelector.actions';
import Headerbar from 'src/components/Headerbar/Headerbar';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import selectAndProjectState from './Headerbar.selector';
import {
  flushSelectionOverridesStarted,
  receiveFilterState,
  updateSelectionOverrides,
  updateFilterContainsInvalid,
  isLoading,
} from 'src/components/FilterPanel/FilterPanel.slice';
import serviceContainer from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { getSectionContext, perspectiveToSmallName } from 'src/utils/Domain/Perspective';
import { find, isNil, get as ldGet, flatMap, assign } from 'lodash';
import { FilterGroup } from 'src/types/Scope';
import { Action } from 'redux';
import { SelectionOverride } from 'src/common-ui/components/Filters/Filters';
import { setSessionFilters } from '../RestoreSession/RestoreSession.utils';

function valueFirstPopulated(keys: string[], obj: Record<string, any>) {
  const lowerCaseKeys = keys.map((x) => x.toLowerCase());

  const poppedKey = find(lowerCaseKeys, (k) => !isNil(ldGet(obj, k)));
  if (poppedKey != null) {
    return ldGet(obj, poppedKey);
  } else {
    return null;
  }
}

export function refreshFilterSelections(bypassRefresh = false) {
  return (dispatch: AppThunkDispatch, get: () => AppState) => {
    const state = get();
    const perspectiveId = state.perspective!.selected!.id;
    const appName = state.perspective.selected!.value;
    const section = getSectionContext();

    dispatch(isLoading(true));
    serviceContainer.filterClient.getFullFilters({ section, appName }).then((serverFilters) => {
      const refreshResult = serverFilters;
      const newFilterSelections: SelectionOverride[] = [];
      const filterKeys = [
        perspectiveToSmallName(perspectiveId) + 'history',
        perspectiveToSmallName(perspectiveId) + 'assortment',
      ];
      const containsInvalid = false;
      if (refreshResult) {
        // Backend sometimes choose arbitrary key for filter contain (ignores "history": true)
        const moduleFilters = valueFirstPopulated(filterKeys, refreshResult.filters);
        if (moduleFilters != null) {
          moduleFilters.forEach((filterType: FilterGroup) => {
            // Find filter section (ie. Print Pattern)
            filterType.filters.forEach((fil) => {
              fil.filterValues
                .filter((f) => f.selected === true)
                .forEach((filVal) => {
                  newFilterSelections.push({
                    id: filVal.id,
                    value: true,
                    filterDefnId: filVal.filterDefnId,
                  });
                });
            });
          });
        }
        dispatch(updateSelectionOverrides(newFilterSelections));
        // TODO: I'm not sure if bypassRefresh logic is okay, only here to make sure the same screen doesn't reload when opening FilterPanel
        // if new filters are different then previous, refresh grid & apply selections
        dispatch(
          receiveFilterState({ filters: refreshResult.filters, updatedAt: bypassRefresh ? undefined : Date.now() })
        );
        const selectionsToSave = newFilterSelections.map((override) => assign({}, override, { value: true }));
        setSessionFilters(selectionsToSave);
        dispatch(updateFilterContainsInvalid(containsInvalid));
      }
    });
  };
}

export function refreshFilterSelectionsOnly(dispatch: Dispatch<AppState>, get: () => AppState): Promise<Action> | void {
  dispatch(flushSelectionOverridesStarted());
  const appName = get().perspective.selected!.value;

  serviceContainer.filterClient.getOnlyFilterSelections(appName).then((serverFilterSelections) => {
    // this is where filter selections can be initially loaded, when the panel
    // is opened and no filters happen to be present for some reason
    // dispatch(receiveFilterState(data.filters[filterKey], 0));
    const selectionIdsToFilterSelections = flatMap(serverFilterSelections, (f) => {
      return flatMap(f.filterSet, (fs) => {
        return {
          filterDefnId: f.filterId,
          id: fs,
          value: true,
        };
      });
    });
    dispatch(updateSelectionOverrides(selectionIdsToFilterSelections));
  });
}

function dispatchToProps(dispatch: AppThunkDispatch) {
  return {
    onToggleScopeSelector() {
      dispatch(changeScope());
    },
    onOpenFilters() {
      dispatch(setRightContainerPayload({ type: 'FilterPanel' }));
      dispatch(flushSelectionOverridesStarted());
      dispatch(refreshFilterSelections(true));
    },
  };
}
export type DispatchProps = ReturnType<typeof dispatchToProps>;

export default connect(selectAndProjectState, dispatchToProps)(makePrintSensitive(Headerbar));
