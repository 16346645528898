export const SET_PRINT_MODE = 'SET_PRINT_MODE';
export const SET_IS_VIEW_PRINTABLE = 'SET_IS_VIEW_PRINTABLE';

export type SetPrintModeAction = {
  type: string;
  payload: {
    isPrintMode: boolean;
    width: string;
    height: string;
  };
};

export type IsViewPrintable = {
  type: string;
  payload: {
    isViewPrintable: boolean;
  };
};

export type Action = SetPrintModeAction | IsViewPrintable;

export function setPrintMode(isPrintMode: boolean, width: number, height: number): Action {
  return {
    type: SET_PRINT_MODE,
    payload: {
      isPrintMode,
      width: `${width}in`,
      height: `${height}in`,
    },
  };
}

export function setIsViewPrintable(isViewPrintable: boolean): Action {
  return {
    type: SET_IS_VIEW_PRINTABLE,
    payload: {
      isViewPrintable,
    },
  };
}
