import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { ReduxSlice as FilterSlice } from 'src/components/FilterPanel/FilterPanel.container';
import { ScopeSelectorSlice } from 'src/components/ScopeSelector/ScopeSelector.reducer';
import {
  ASSORTMENT_BUILD_PATH,
  ASSORTMENT_STRATEGY_PATH,
  ASSORTMENT_ANALYSIS_PATH,
  RECONCILIATION_PATH,
  TOP_DOWN_URI,
  ALLOCATION_PATH,
} from '../../utils/Domain/Constants';
import { Lens } from 'monocle-ts';
import { RightContainerSlice } from '../RightContainer/RightContainer.slice';
import { isEmpty, isNil } from 'lodash';
import { QuickReconConf } from '../Reconcilation/ReconcileModal';
import { PrintSize } from '../higherOrder/Print/Print';
import { tenantTabHasPathSlot } from 'src/services/configuration/bindings.types';

export type StateSelection = ScopeSelectorSlice & {
  filters: FilterSlice;
  activeTab: string;
  isTopDownView: boolean;
  cartCount: number;
  rightContainer: RightContainerSlice;
  isViewPrintable: boolean;
  hideAssortmentCart: boolean;
  quickReconConf?: QuickReconConf;
  printSizes: PrintSize[];
};

export type StateProjection = StateSelection & {
  scopeStartKey: string;
  scopeEndKey: string;
  scopeRangesKey: string;
};
function getCartCount(state: AppState) {
  return Lens.fromPath<AppState>()(['pages', 'assortmentBuild', 'cart', 'cartItems']).get(state) || [];
}
function selectState(state: AppState): StateSelection {
  const { scope, print, filters, perspective, rightContainer, appConfig } = state;
  const isTopDownView = !isNil(perspective.selected) ? perspective.selected.pathSlot === TOP_DOWN_URI : false;
  const assortmentBuildTab = appConfig.tenantConfig.tabs.find((tab) => tab.id == 'AssortmentBuild');
  // We get the active tab from the url, so we need to remove the dash to get the id. Not the best way to do this.

  const activeTab = appConfig.tenantConfig.tabs.find(
    (tab) => tenantTabHasPathSlot(tab) && perspective.activeTab == tab.pathSlot
  );

  const quickReconConf = !isNil(activeTab) && tenantTabHasPathSlot(activeTab) ? activeTab.quickReconConf : undefined;
  return {
    ...scope,
    isViewPrintable: print.isViewPrintable,
    cartCount: getCartCount(state).length,
    filters: filters,
    activeTab: perspective.activeTab,
    quickReconConf: !isEmpty(quickReconConf) ? quickReconConf : undefined,
    isTopDownView,
    rightContainer: rightContainer,
    hideAssortmentCart:
      !isNil(assortmentBuildTab) && !isNil(assortmentBuildTab?.disabled) ? assortmentBuildTab.disabled : true,
    printSizes: state.appConfig.tenantConfig.printSizes,
  };
}

function getScopeFromTab(tab: string) {
  let start = 'historyStart';
  let end = 'historyEnd';
  let scopeRangesKey = 'pastRangeList';

  if (
    tab === ASSORTMENT_BUILD_PATH ||
    tab === ASSORTMENT_STRATEGY_PATH ||
    tab === ASSORTMENT_ANALYSIS_PATH ||
    tab === RECONCILIATION_PATH ||
    tab === ALLOCATION_PATH
  ) {
    start = 'start';
    end = 'end';
    scopeRangesKey = 'rangeList';
  }
  return {
    scopeStartKey: start,
    scopeEndKey: end,
    scopeRangesKey,
  };
}

export function projectState(stateSelection: StateSelection) {
  const scopeKeys = getScopeFromTab(stateSelection.activeTab);

  return {
    ...stateSelection,
    ...scopeKeys,
  };
}

export default createSelector(selectState, projectState);
