import * as React from 'react';

export class ErrorBoundary<T = Record<string, any>> extends React.Component<T, { hasError: boolean }> {
  constructor(props: T) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    this.setState({ hasError: true });
    console.error(error.message);
    console.error(error.stack);
  }
  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
