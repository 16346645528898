import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { SortBySlice, GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { CompiledExpression, compile } from 'seshat';
import { debounce, isNil, last, omit } from 'lodash';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { filterAndSortPivotItems, filterData, FilterSortType } from 'src/utils/Pivot/Filter';
import { externalGridSearchFields, ID } from 'src/utils/Domain/Constants';
import { aggByToDataIndex } from 'src/utils/Domain/AggBy';
import { buildGroupsFromFlatData } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import serviceContainer from 'src/ServiceContainer';
import { errorToLoggingPayload } from 'src/services/loggingService';

export const GROUP_KEY = 'group';
export const GROUP_DISPLAY_NAME = 'Group';
export const GroupHeaderKey = '$$GroupHeader';

export type GroupedItems = {
  [key: string]: BasicPivotItem[];
};

export type GridItem = BasicPivotItem & {
  [GroupHeaderKey]?: boolean;
  group?: string[];
};

export type AgFlatResult = {
  treeColumnDefinition: ColDef;
  agFlatTree: GridItem[];
};

const defaultTreeColDef: ColDef = {
  headerName: GROUP_DISPLAY_NAME,
  field: GROUP_KEY,
  valueFormatter: (params: ValueFormatterParams): string => {
    return last(params.value) || '';
  },
  pinned: 'left',
  minWidth: 200,
};

export function generateOrderedGroups(flatData: BasicPivotItem[], groupBy: GroupBySlice) {
  const groups = buildGroupsFromFlatData(flatData, groupBy);
  const newGroupsObj = {};
  groups.forEach((group) => (newGroupsObj[group.header] = group.items));
  return newGroupsObj;
}

export function groupedToAgFlatTree(
  grouped: GroupedItems,
  groupKey: string,
  search: string,
  sortBy: SortBySlice | FilterSortType,
  flowStatus: number[],
  configViewItems: TenantConfigViewItem[],
  identityField: string = ID,
  showField?: boolean,
  enableGroupCheckbox = false
): AgFlatResult {
  let agFlattenedStyles: GridItem[] = [];
  const formulaLookup = new Map<string, CompiledExpression>();

  for (const configViewItem of configViewItems) {
    const maybeFormula = configViewItem.formula;

    if (maybeFormula) {
      formulaLookup.set(configViewItem.dataIndex, compile(maybeFormula));
    }
  }

  for (const groupValue of Object.keys(grouped)) {
    const header = { group: [groupValue], id: groupValue } as GridItem;

    const styles = filterAndSortPivotItems(
      search,
      sortBy,
      externalGridSearchFields,
      flowStatus,
      grouped[groupValue]
    ).map((style) => {
      const styleData = showField
        ? {
            ...style,
            [GROUP_KEY]: [groupValue, style[identityField]],
          }
        : {
            ...omit(style, groupKey),
            [GROUP_KEY]: [groupValue, style[identityField]],
          };
      return styleData;
    });

    if (!styles.length) {
      continue;
    }

    configViewItems
      .map((item) => item.dataIndex)
      .forEach((colId) => {
        const maybeFormula = formulaLookup.get(colId);

        if (maybeFormula) {
          try {
            const aggregation = maybeFormula.execute(styles);

            if (!isNil(aggregation)) {
              header[colId] = aggregation;
            }
            header[GroupHeaderKey] = true;
          } catch (error) {
            debounce(() => {
              serviceContainer.loggingService.error(errorToLoggingPayload(error));
            }, 1000);
          }
        }
      });

    agFlattenedStyles.push(header);
    agFlattenedStyles = (agFlattenedStyles as any).concat(styles);
  }

  // If groups are numbers, sort them
  let agFlattenedStylesFinal = agFlattenedStyles.slice(0);
  const groups = agFlattenedStyles.filter((style) => {
    if (Object.keys(style).length === 1 && style.group) {
      return true;
    }
    return false;
  });
  const areGroupsNumbers = agFlattenedStyles
    .filter((style) => {
      if (Object.keys(style).length === 1 && style.group) {
        return true;
      }
      return false;
    })
    .map((style) => {
      if (Object.keys(style).length === 1 && style.group && style.group[0]) {
        if (Number(style.group[0]) !== NaN) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    });
  if (areGroupsNumbers.every((b) => b)) {
    const sortedGroups = groups.sort((a, b) => Number(a.group![0]) - Number(b.group![0]));
    agFlattenedStylesFinal = sortedGroups.concat(agFlattenedStyles.filter((style) => Object.keys(style).length > 1));
  }

  return {
    treeColumnDefinition: defaultTreeColDef,
    agFlatTree: agFlattenedStylesFinal,
  };
}

const delim = '/';

function backtrackPrune(path: string, pruneCounter: Map<string, number>, acc: Map<string, GridItem>) {
  const ancestorChildCount = pruneCounter.get(path)!;
  const nextCount = ancestorChildCount - 1;
  pruneCounter.set(path, nextCount);

  if (nextCount <= 0) {
    acc.delete(path);
    const nextPath = path.split(delim);
    nextPath.pop();

    if (nextPath.length) {
      backtrackPrune(nextPath.join(delim), pruneCounter, acc);
    }
  }
}

function recurDown(
  node: BasicPivotItem,
  parentPath: string[],
  keys: string[],
  depth: number,
  search: string,
  flowStatus: number[],
  pruneCounter: Map<string, number>,
  acc: Map<string, GridItem>
) {
  const nextToken: string = node[keys[depth]];
  const path = [...parentPath, nextToken];
  const pathString = path.join(delim);
  node[GROUP_KEY] = path;

  if (depth < keys.length - 1) {
    node[GroupHeaderKey] = true;
  }
  acc.set(pathString, node);
  const { children } = node;

  if (children && children.length) {
    const shouldFilterLeaves = depth === keys.length - 2;

    const filteredChildren = shouldFilterLeaves
      ? filterData(children, search, externalGridSearchFields, flowStatus)
      : children;

    const childCount = filteredChildren.length;

    if (childCount) {
      pruneCounter.set(pathString, childCount);

      for (const child of filteredChildren) {
        recurDown(child, path, keys, depth + 1, search, flowStatus, pruneCounter, acc);
      }
    } else if (shouldFilterLeaves) {
      acc.delete(pathString);

      // Filtering has to backtrack up the tree to clear out all
      // the node that may have had their descendents filter down to 0
      backtrackPrune(parentPath.join(delim), pruneCounter, acc);
    }
  }
}

export function listDataTreeToAgFlatTree(
  aggBys: string[],
  search: string,
  flowStatus: number[],
  maybeTreeData: BasicPivotItem[],
  aggByNameOverride = false
): AgFlatResult {
  // Assert that if the first child doesn't have children,
  const isTreeData = maybeTreeData.length && maybeTreeData.some((item) => Boolean(item.children.length));

  if (isTreeData) {
    const accumulator = new Map<string, GridItem>();
    const pruneCounter = new Map<string, number>();
    const keys = aggBys.map((aggBy) => aggByToDataIndex(aggBy, aggByNameOverride));

    for (const topNode of maybeTreeData) {
      recurDown(topNode, [], keys, 0, search, flowStatus, pruneCounter, accumulator);
    }

    return {
      treeColumnDefinition: defaultTreeColDef,
      agFlatTree: [...accumulator.values()],
    };
  } else {
    const filtered = filterData(maybeTreeData, search, externalGridSearchFields, flowStatus);
    filtered.forEach((item) => (item[GROUP_KEY] = [item.id]));

    return {
      treeColumnDefinition: defaultTreeColDef,
      agFlatTree: filtered,
    };
  }
}
