interface ActionInt<T extends string> {
  type: T;
}
interface ActionPayloadInt<AName extends string, P> extends ActionInt<AName> {
  payload: P;
}

type ActionFn<T extends string> = () => ActionInt<T>;
type ActionPayloadFn<T extends string, P> = (_payload: P) => ActionPayloadInt<T, P>;

export function createAction<AName extends string>(type: AName): ActionFn<AName>;
export function createAction<AName extends string, P>(type: AName): ActionPayloadFn<AName, P>;
export function createAction<AName extends string, P>(type: AName) {
  return (payload?: P) =>
    payload
      ? {
          type,
          payload,
        }
      : { type };
}
