import { AppType } from 'src/types/Domain';
import store, { AppState } from 'src/store';
import { Perspective } from 'src/services/configuration/bindings.types';
import { TOP_DOWN_PERSPECTIVE, BOTTOM_UP_PERSPECTIVE } from 'src/utils/Domain/Constants';
import { isNil } from 'lodash';

export function provideAppName<T>(f: (_appName: AppType) => Promise<T>) {
  const perspective = store.getState().perspective.selected;
  if (!perspective) {
    return Promise.reject(new Error('You have no perspective selected.'));
  }
  return f(perspective.value);
}

export type ProvideAppContextArgs = {
  appName: AppType;
  fingerprint?: string;
};
export function provideAppContext<T>(f: (context: ProvideAppContextArgs) => Promise<T>) {
  const state = store.getState();
  const perspective = state.perspective.selected;
  const fingerprint = state.appConfig.configFingerprint;
  if (!perspective) {
    return Promise.reject(new Error('You have no perspective selected.'));
  }
  return f({
    appName: perspective.value,
    fingerprint,
  });
}

export function perspectiveToSmallName(perspective: Perspective) {
  switch (perspective) {
    case TOP_DOWN_PERSPECTIVE:
      return 'td';
    case BOTTOM_UP_PERSPECTIVE:
      return 'bu';
    default:
      throw new Error(`Counldn't parse perspective "${perspective}"`);
  }
}

export function isHistoryTabContext(): boolean {
  const state = store.getState();
  const tabContexts = state.appConfig.tenantConfig.context;
  const currentTab = state.perspective.activeTab || '';

  // converts current tab to same structure/casing as tabContexts
  const normalizedTab = currentTab.replace('-', '').toLowerCase();
  const isHistory = tabContexts.hindsighting.tabs.find((context) => context.toLowerCase() === normalizedTab);

  return !isNil(isHistory);
}

export function getSectionContext() {
  const state: AppState = store.getState();
  const context = state.appConfig.tenantConfig.context;
  const activeTab = state.perspective.activeTab.toLowerCase().replace('-', '');
  const contextIndex = Object.values(context)
    .map((a) => a.tabs.map((b) => b.toLowerCase()))
    .findIndex((x) => x.indexOf(activeTab) > -1);
  return contextIndex > -1 ? Object.keys(context)[contextIndex] : '';
}

export function getSectionContextObj() {
  const state: AppState = store.getState();
  const context = state.appConfig.tenantConfig.context;
  const activeTab = state.perspective.activeTab.toLowerCase().replace('-', '');
  const contextIndex = Object.values(context)
    .map((a) => a.tabs.map((b) => b.toLowerCase()))
    .findIndex((x) => x.indexOf(activeTab) > -1);
  return contextIndex > -1 ? Object.values(context)[contextIndex] : '';
}
