import * as React from 'react';
import Button from '@material-ui/core/Button';
import LoggingService from 'src/services/loggingService';

interface ErrorBoundaryState {
  hasError: boolean;
}
interface ErrorBoundaryProps {
  children: React.ReactElement[] | React.ReactElement;
  serviceEnv: { loggingService: LoggingService };
  errorTitle?: string;
  errorMessage?: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }
  private static defaultProps = {
    errorTitle: 'Something went wrong with the application.',
    errorMessage:
      'If this continues to happen, let support know about the issue.  You may refresh the page and try again:',
  };

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true });

    window.console.error(error, info);
    this.props.serviceEnv.loggingService.error('An error occued in the grid', error.stack);
  }

  private retryClick = () => {
    window.location.reload();
  };

  public render() {
    if (this.state.hasError) {
      const { errorTitle, errorMessage } = this.props;
      return (
        <div className={'error-display'}>
          <h1 className={'error-title'}>{errorTitle}</h1>
          <p>{errorMessage}</p>
          <Button onClick={this.retryClick} title={'Refresh'} />
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
