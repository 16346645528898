import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, Button } from 'reactstrap';
import { dropdownToggle, dropdownMenu, button } from './SplitButton.styles';
import { MenuItemDef } from 'ag-grid-community';
import { noop } from 'lodash';
import { Tooltip, CircularProgress } from '@material-ui/core';

type Props = {
  text: string;
  icon: string;
  isLoading: boolean;
  isDisabled: boolean;
  onButtonClick: (() => void) | undefined;
  menuItems: MenuItemDef[];
};

const SplitButton = ({ text, icon, isDisabled, isLoading, onButtonClick, menuItems }: Props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <Button className={button} color="primary" disabled={isDisabled} onClick={onButtonClick}>
        {isLoading ? (
          <CircularProgress size={20} color={'inherit'} disableShrink={true} />
        ) : (
          <i className={icon as string} />
        )}
        <span>{text}</span>
      </Button>
      <DropdownToggle className={dropdownToggle} caret split color="primary" disabled={isDisabled} />
      <DropdownMenu className={dropdownMenu}>
        {menuItems.map((menuItem, index) => {
          const { name, action = noop, icon, tooltip = '' } = menuItem;
          const handleClick = () => {
            toggle();
            action();
          };

          return (
            <Tooltip key={`${name}-${index}`} title={tooltip} placement="left">
              <div onClick={handleClick}>
                <i className={icon as string} />
                <span>{name}</span>
              </div>
            </Tooltip>
          );
        })}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default SplitButton;
