/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment';
import { isNumber, noop, isNil, isObject, isString, isArray, has } from 'lodash/fp';
import { DaysRangeListResponse } from 'src/types/Scope';
import { getWeekLabel, getDateFromWeek } from 'src/common-ui/components/WeekRange/WeekRangePicker.utils';
import { MathJsStatic } from 'mathjs';

export type GetDataCalculation = {
  rowNodeFound: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

export type ParamedCalc = {
  eval: string | string[];
  params?: { [s: string]: string };
};

function isInvalidNumber(val: unknown) {
  return (isNumber(val) && (isNaN(val) || !isFinite(val))) || isNil(val);
}

export const importDefaultFunctions = (math: MathJsStatic) => {
  const defaultFn = (value: number, defaultVal: number) => {
    if (isInvalidNumber(value)) {
      return defaultVal;
    }
    return value;
  };

  math.import(
    {
      default: defaultFn,
    },
    { silent: true, override: true }
  );
};

export const importDateFunctions = (math: MathJsStatic, mergedRangeList: DaysRangeListResponse) => {
  const s5week = (weekString: string) => {
    const date =
      getDateFromWeek(weekString, mergedRangeList.start_date) || getDateFromWeek(weekString, mergedRangeList.end_date);
    if (date) {
      return date;
    }
    return;
  };
  const weeks = (value: number) => {
    return value * 7;
  };
  const days = (value: number) => {
    return value;
  };
  const daysToWeeks = (value: number) => {
    return value / 7;
  };
  const add = math.typed('add', {
    'Date, number': function(a: Date, b: number) {
      return getWeekLabel(
        moment(a)
          .hours(0)
          .add(b, 'days')
          .toDate(),
        mergedRangeList
      );
    },
    'number, Date': function(a: number, b: Date) {
      return getWeekLabel(
        moment(b)
          .hours(0)
          .add(a, 'days')
          .toDate(),
        mergedRangeList
      );
    },
  });
  const subtract = math.typed('subtract', {
    'Date, number': function(a: Date, b: number) {
      return getWeekLabel(
        moment(a)
          .hours(0)
          .subtract(b, 'days')
          .toDate(),
        mergedRangeList
      );
    },
    'number, Date': function(a: number, b: Date) {
      // Technically this shouldn't work at all. "7 - 2018-W28" doesn't really make sense
      return getWeekLabel(
        moment(b)
          .hours(0)
          .subtract(a, 'days')
          .toDate(),
        mergedRangeList
      );
    },
    'Date, Date': function(a: Date, b: Date) {
      const daysBetween = moment(a)
        .hours(0)
        .diff(moment(b).hours(0), 'days');
      return daysBetween;
    },
    'number, number': function(a: number, b: number) {
      return a - b;
    },
  });

  math.import(
    {
      add: add,
      subtract: subtract,
      s5week: s5week,
      weeks: weeks,
      days: days,
      daysToWeeks,
    },
    { silent: true, override: true }
  );
  return math;
};

export const getVarsFromCalcString = (math: MathJsStatic, calculation: string): string[] => {
  const returnVars: string[] = [];
  const calc = math.parse(calculation);
  calc
    .filter((node) => node.isSymbolNode)
    .forEach((node) => {
      const id = node.name || '';
      if (!has(id, math) && returnVars.indexOf(id) === -1) {
        returnVars.push(id);
      }
    });
  return returnVars;
};

const internalExecuteCalculation = (
  math: MathJsStatic,
  calculation: string | string[],
  getDataFromKey: (key: string) => GetDataCalculation,
  extraVars?: { [key: string]: string }
) => {
  const calcString: string = isArray(calculation) ? calculation.join('\n') : calculation;
  const calc = math.parse(calcString);

  const vars = {};
  // If calculation is an object and has params, use those, if not, lookup dataindexes
  if (extraVars) {
    Object.keys(extraVars).forEach((key) => {
      const getDataCalc = getDataFromKey(extraVars[key]);
      if (getDataCalc.rowNodeFound) {
        vars[key] = getDataCalc.data;
      } else {
        vars[key] = undefined;
      }
    });
  } else {
    calc
      .filter((node) => node.isSymbolNode)
      .forEach((node) => {
        const id = node.name || '';
        if (!has(id, vars) && !has(id, math)) {
          const getDataCalc = getDataFromKey(id);
          if (getDataCalc.rowNodeFound) {
            const data = getDataCalc.data;
            vars[id] = data;
          } else {
            // Does few unnecessary lookups if there is a variable that isn't a dataindex. Shouldn't affect performance
          }
        }
      });
  }

  let result;
  try {
    result = math.eval(calcString, {
      ...vars,
    });
    // Parses arrays that go through
    if (result.isResultSet) {
      const valueOf = result.valueOf();
      result = valueOf[valueOf.length - 1];
    }
  } catch (e) {
    noop();
  }
  if (isInvalidNumber(result)) {
    result = 0;
  }
  return result;
};

// Call this to do a calc. This just tells it how to parse it depending on the structure of the calculation value
export const executeCalculation = (
  math: MathJsStatic,
  calculation: string | ParamedCalc,
  getDataFromKey: (key: string) => GetDataCalculation
) => {
  if (isObject(calculation)) {
    const paramedCalc = calculation as ParamedCalc;
    if (paramedCalc.params) {
      return internalExecuteCalculation(math, paramedCalc.eval, getDataFromKey, paramedCalc.params);
    } else {
      return internalExecuteCalculation(math, paramedCalc.eval, getDataFromKey);
    }
  } else if (isString(calculation)) {
    return internalExecuteCalculation(math, calculation, getDataFromKey);
  }
};
