import React from 'react';
import moment from 'moment';

import WeekRangePicker from 'src/common-ui/components/WeekRange/WeekRangePicker';
import { WeekRange } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import styles from '../ScopeSelector.styles';
import { DispatchProps, StateProps } from './HindsightingPeriodForm.container';
import FloorsetSelectorForm from 'src/components/ScopeSelector/FloorSetSelectorForm/FloorsetSelectorForm.container';

type Props = StateProps & DispatchProps;

export default class HindsightingPeriodForm extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onNewWeeksSelected = this.onNewWeeksSelected.bind(this);
  }

  onNewWeeksSelected(range: WeekRange) {
    const { from, to } = range;
    const { daysRangeList, onChangeStartWeek, onChangeEndWeek } = this.props;
    const fromDate = moment(from).format('YYYY-MM-DD');
    const toDate = moment(to).format('YYYY-MM-DD');
    const fromId = daysRangeList.start_date[fromDate];
    const toId = daysRangeList.end_date[toDate];
    onChangeStartWeek(fromId);
    onChangeEndWeek(toId);
  }

  render() {
    const { rangeList, selectedEndWeek, selectedStartWeek, daysRangeList } = this.props;

    let allowFrom = '';
    let allowTo = '';
    if (Array.isArray(rangeList) && rangeList.length > 0) {
      // Makes sure values are in both rangeList and daysRangeList
      const startRangeList = rangeList.filter((x) => Object.values(daysRangeList.start_date).indexOf(x.id) !== -1);
      const endRangeList = rangeList.filter((x) => Object.values(daysRangeList.end_date).indexOf(x.id) !== -1);
      allowFrom = startRangeList[0].id;
      allowTo = endRangeList[endRangeList.length - 1].id;
    }
    const floorsetDropdown = <FloorsetSelectorForm region={'Hindsighting'} />;

    return (
      <div className={styles.scopeColumn}>
        {/* <div className={styles.theSpacer}>{UNICODE_NO_BREAK_SPACE}</div> */}
        {floorsetDropdown}
        <div className="picker" data-qa="datepicker-hindsightingperiod">
          <WeekRangePicker
            autoSelect={true}
            allowFrom={allowFrom}
            allowTo={allowTo}
            dateToNameMap={daysRangeList}
            initialSelectedFrom={selectedStartWeek || ''}
            initialSelectedTo={selectedEndWeek || ''}
            onNewWeeksSelected={this.onNewWeeksSelected}
            selectionOptions={[{ label: 'Start' }, { label: 'End' }]}
          />
        </div>
        <div className="text">Hindsighting Period</div>
      </div>
    );
  }
}
