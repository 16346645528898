import React, { useCallback } from 'react';
import { match } from 'react-router';
import { Link, Route } from 'react-router-dom';
import styles from 'src/components/Sidenav/Sidenav.styles';

export interface NavidationItemProps {
  name: string;
  link: string;
  icon: string;
  disabled: boolean;
  qa: string;
  onClick: () => void;
}

export const NavigationItem = ({ name, link, icon, disabled, qa, onClick }: NavidationItemProps) => {
  const onLinkClick = useCallback(() => onClick(), [onClick]);

  const content = (
    <div className="d-flex flex-row align-items-center">
      <div className="col-1 d-flex flex-column align-items-center">
        <i className={`${icon}`} />
      </div>
      <div className="col-11" style={{ fontSize: '.75rem' }}>
        {name}
      </div>
    </div>
  );

  const rendered = (routeMatch: match | null) =>
    disabled ? (
      <a className={styles.navLink(routeMatch)}>{content}</a>
    ) : (
      <Link to={`${link}`} className={styles.navLink(routeMatch)} data-qa={qa} onClick={onLinkClick}>
        {content}
      </Link>
    );

  /* eslint-disable react/no-children-prop */
  return (
    <Route
      path={link}
      children={(routeProps) => <li className="nav-item pb-0 pt-0">{rendered(routeProps.match)}</li>}
    />
  );
};
