import * as React from 'react';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { RouteLink } from 'src/types/RouteLink';
import styles, { searchMediaStyles } from './TopNavbar.styles';
import { Typeahead, Highlighter, TypeaheadMenuProps } from 'react-bootstrap-typeahead';
import { Redirect } from 'react-router';
import { join } from 'lodash';
import { mediumBreakpoint } from 'src/utils/Style/Theme';

export type TopNavSearchProps = {
  routeLinks: RouteLink[];
  onEnter: () => void;
  onLeave: () => void;
};

export interface TopNavSearchState {
  minLength: number;
  selectHintOnEnter: boolean;
  newPath: string;
  selectedOption: RouteLink | null;
}

const MINIMUM_CHARACTERS_REQUIRED = 0;

export default class TopNavSearch extends React.Component<TopNavSearchProps, TopNavSearchState> {
  constructor(props: TopNavSearchProps) {
    super(props);
    this.state = {
      minLength: MINIMUM_CHARACTERS_REQUIRED,
      selectHintOnEnter: true,
      newPath: '',
      selectedOption: null,
    };
  }

  handleInputChange = (_event: React.FormEvent<HTMLFormElement>) => {
    const { selectedOption } = this.state;
    if (selectedOption && selectedOption.link) {
      this.triggerNavigation(selectedOption.link);
    }
  };

  triggerNavigation = (path: string) => {
    if (path) {
      this.setState({
        newPath: path,
      });
      this.props.onLeave();
    }
  };

  renderMenuItemChildren = (option: RouteLink, props: TypeaheadMenuProps<RouteLink>) => {
    const breadcrumb = option.highlightPrefix.split('/').slice(2);
    return [
      <Highlighter key="name" search={props.text}>
        {option.name}
      </Highlighter>,
      <div key="breadcrumb">
        <small>{breadcrumb.length > 1 && join(breadcrumb, ' > ')}</small>
      </div>,
    ];
  };

  selectTypeahead = (selected: RouteLink[]) => {
    this.setState(
      {
        selectedOption: selected[0],
      },
      () => {
        const newSelectedOption = selected[0] || this.state.selectedOption;
        if (newSelectedOption) {
          this.triggerNavigation(newSelectedOption.link);
        }
      }
    );
  };

  render() {
    const { newPath } = this.state;
    const { routeLinks } = this.props;
    const realRoutes = routeLinks.filter((route) => !route.disabled);

    if (newPath) {
      this.setState({
        newPath: '',
        selectedOption: null,
      });
      return <Redirect to={newPath} />;
    }

    const addonClass = `navbar-brand ${styles.TopNavSearchIconContainer}`;

    return (
      <form onSubmit={this.handleInputChange} data-qa="TopNavSearch" className={searchMediaStyles}>
        <InputGroup>
          <InputGroupAddon addonType="prepend" className={addonClass}>
            <i className="fas fa-search" data-qa="TopNavSearchIcon" />
          </InputGroupAddon>
          <div className={styles.TopNavSearch} data-qa="TopNavSearchInput">
            <Typeahead
              {...this.state}
              id={'search'}
              labelKey="name"
              align={window.innerWidth < mediumBreakpoint ? 'right' : undefined}
              onChange={this.selectTypeahead}
              onFocus={() => this.props.onEnter()}
              onBlur={() => this.props.onLeave()}
              renderMenuItemChildren={this.renderMenuItemChildren}
              options={realRoutes}
              selectHintOnEnter={true}
              placeholder="Search actions, views, menu items..."
            />
            <Button type="submit" data-qa="TopNavSearchButton" hidden={true} />
          </div>
        </InputGroup>
      </form>
    );
  }
}
