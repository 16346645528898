import {
  TenantResponse,
  Action,
  REQUEST_ALL_DATA,
  REQUEST_TENANT_CONFIG,
  RECEIVE_TENANT_CONFIG,
  RECEIVE_ALL_DATA,
  CLEAN_UP,
  RECEIVE_DATA_ERROR,
  SET_SELECTED_ITEM,
} from './StoreGroupChart.actions';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export type ReduxSlice = {
  viewDefn: TenantConfigViewData;
  allData: TenantResponse[];
  configLoaded: boolean;
  allDataLoaded: boolean;
  selectedItem: BasicPivotItem | null;
};

const initialState: ReduxSlice = {
  viewDefn: {} as TenantConfigViewData,
  selectedItem: null,
  allData: [],
  configLoaded: false,
  allDataLoaded: false,
};

export default function reducer(state: ReduxSlice = initialState, action: Action): ReduxSlice {
  switch (action.type) {
    case SET_SELECTED_ITEM: {
      return {
        ...state,
        selectedItem: action.item,
      };
    }
    case REQUEST_TENANT_CONFIG: {
      return {
        ...state,
        configLoaded: false,
      };
    }
    case RECEIVE_TENANT_CONFIG: {
      return {
        ...state,
        viewDefn: action.config,
        configLoaded: true,
      };
    }
    case REQUEST_ALL_DATA: {
      return {
        ...state,
        allDataLoaded: false,
      };
    }
    case RECEIVE_ALL_DATA: {
      return {
        ...state,
        allData: action.allData,
        allDataLoaded: true,
      };
    }
    case RECEIVE_DATA_ERROR: {
      return {
        ...state,
        allData: [],
      };
    }
    case CLEAN_UP: {
      return initialState;
    }
    default:
      return state;
  }
}
