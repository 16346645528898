import { createSelector } from 'reselect';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import _ from 'lodash';
import 'highcharts/modules/map';

import { Props as KeyFinancialProps } from 'src/common-ui/components/Metrics/SimpleMetrics/KeyFinancial/KeyFinancial';
import { Props as ChoicePropductivityProps } from 'src/common-ui/components/Metrics/SimpleMetrics/ChoiceProductivity/ChoiceProductivity';
import { Props as FinancialProps } from 'src/common-ui/components/Metrics/SimpleMetrics/KeyFinancial/KeyFinancial';
import { GeoChartProps } from 'src/common-ui/components/Charts/GeoChart/GeoChart';

import { AppState } from 'src/store';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ReduxSlice as SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { Renderer } from 'src/utils/Domain/Renderer';
import { TenantConfigViewItem, BasicTenantConfigViewItem, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { identity } from 'fp-ts/lib/function';
import { ViewData, ViewDefns } from './Summary.actions';
import { geoMapStyles as geo } from './Summary.styles';
import { PassedProps as SubheaderProps } from 'src/components/Subheader/Subheader';
import { TenantConfig } from 'src/services/configuration/bindings.types';
import { mapNameToDefaultUri } from 'src/utils/Component/GeoChart';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { isNil, get } from 'lodash';

export type StateSelection = {
  areViewDefnsLoading: boolean;
  isViewDataLoading: boolean;
  subheader: SubheaderSlice;
  tenantConfig: TenantConfig;
  viewData: ViewData;
  viewDefns: ViewDefns;
};

export type LoadingProjection = {
  loaded: false;
};

export type LoadedProjection = {
  loaded: true;
  lookBackPeriod: string;
  flowStatus: SubheaderSlice['flowStatus'];
  choiceProductivity: ChoicePropductivityProps[];
  globalRegionVolAndTrend: GeoChartProps;
  keyFinancials: KeyFinancialProps[];
  productMixAndTrend: Highcharts.SeriesMapOptions;
  trendAnalysis: Highcharts.SeriesMapOptions;
};

export type StateProjection = (LoadingProjection | LoadedProjection) & {
  title: SubheaderProps['title'];
};

function selectState(state: AppState) {
  return {
    subheader: state.subheader,
    tenantConfig: state.appConfig.tenantConfig!,
    ...state.pages.hindsighting.summary,
  };
}

function defnToRenderer(defn: TenantConfigViewItem) {
  return defn.renderer ? Renderer[defn.renderer] : identity;
}

// TODO: Move to a shared folder because Category Summary is doing the exact same thing.
function viewItemToFinancialProps(viewItem: TenantConfigViewItem, data: BasicPivotItem): FinancialProps {
  const secondaryDefVariant = viewItem.view![0];
  const secondaryDefMetric = viewItem.view![1];

  const primaryRenderer = defnToRenderer(viewItem);
  const variantRenderer = defnToRenderer(secondaryDefVariant);
  const secondaryRenderer = defnToRenderer(secondaryDefMetric);

  const primaryValue = data && data[viewItem.dataIndex];
  const variantValue = data && data[secondaryDefVariant.dataIndex];
  const secondaryValue = data && data[secondaryDefMetric.dataIndex];
  const direction = variantValue > 0 ? 'up' : 'down';

  return {
    metrics: [
      { rendered: primaryRenderer(primaryValue), label: viewItem.text },
      { rendered: variantRenderer(variantValue), direction },
      { rendered: secondaryRenderer(secondaryValue), label: secondaryDefMetric.text },
    ],
    extraClasses: 'none',
  };
}

// TODO: TrendAnalysisProps and prodMixProps are almost identical, need refactor.
function trendAnalysisProps(view: TenantConfigViewItem[], rawData: BasicPivotItem[], yAxisLabel = 'Sales $') {
  const colors = ['#f0eab9', '#ffccbb'];
  const trendAnalysisConf = {
    title: { text: 'Trend Analysis' },
    chart: {
      type: 'area',
    },
    xAxis: {
      title: {
        text: 'Month',
      },
      categories: rawData.map((entry: BasicPivotItem) => entry.id),
    },
    yAxis: {
      title: {
        text: yAxisLabel,
      },
    },
    series: view.map((item: BasicTenantConfigViewItem, i: number) => ({
      name: item.text,
      data: rawData.map((entry: BasicPivotItem) => entry[item.dataIndex]),
      color: colors[i],
    })),
    credits: { enabled: false },
    tooltip: {
      formatter: function(this: { series: { name: string; index: number }; point: { y: number; category: string } }) {
        const index = this.series.index;
        const renderer = view[index].renderer;
        const value = renderer ? Renderer[renderer](this.point.y) : this.point.y;
        return `<span style="font-size: 10px">${this.point.category}</span><br/>${this.series.name}: <b>${value}</b>`;
      },
    },
  };
  return trendAnalysisConf;
}

function prodMixProps(view: TenantConfigViewItem[], rawData: BasicPivotItem[]) {
  const colors = ['#0071b1', '#ff8229'];
  const productMixConf = {
    title: { text: 'Product Mix And Trend' },
    chart: {
      type: 'bar',
    },
    xAxis: {
      title: { text: '' },
      categories: [...rawData].map((entry: BasicPivotItem) => entry.name),
    },
    yAxis: { visible: false },
    series: view.map((item: BasicTenantConfigViewItem, i: number) => ({
      name: item.text,
      data: rawData.map((entry: BasicPivotItem) => entry[item.dataIndex]),
      color: colors[i],
    })),
    credits: { enabled: false },
    tooltip: {
      formatter: function(this: { series: { name: string; index: number }; point: { y: number; category: string } }) {
        const index = this.series.index;
        const renderer = view[index].renderer;
        const value = renderer ? Renderer[renderer](this.point.y) : this.point.y;
        return `<span style="font-size: 10px">${this.point.category}</span><br/>${this.series.name}: <b>${value}</b>`;
      },
    },
  };
  return productMixConf;
}

function globalRegionProps(rawData: Record<string, any>[] | undefined, viewDefn: GeoTrendsConfigData): GeoChartProps {
  const trendDown: Record<string, any>[] = [];
  const trendZero: Record<string, any>[] = [];
  const trendUp: Record<string, any>[] = [];
  const trends = { '-1': trendDown, '0': trendZero, '1': trendUp };
  const defaultMapSeriesOptions: Highcharts.SeriesMapbubbleOptions = {
    maxSize: geo.bubbleMaxSize,
    minSize: geo.bubbleMinSize,
    type: 'mapbubble',
  };
  const latProp = viewDefn.geoLocationKeys![0] || '';
  const longProp = viewDefn.geoLocationKeys![1] || '';

  rawData &&
    // @ts-ignore
    rawData.reduce((trendsAcc: Record<string, any>, item: BasicPivotItem) => {
      const name = item!.store || item!.channel || item!.globalregion;
      const lat = parseFloat(item[latProp]);
      const lon = parseFloat(item[longProp]);
      const region = item.name || '';
      const zDataIndex = viewDefn.bubble ? viewDefn.bubble.dataIndex : 'slsr';
      if (!_.isNaN(lat) && !_.isNaN(lon)) {
        trendsAcc[item.trend].push({
          lat,
          lon,
          name,
          z: _.get(item, zDataIndex),
          departmentData: item.children,
          region,
        });
      } else {
        console.error(`Global Region: ${name} has an invalid latitude or longitude. Ignoring.`);
      }
      return trendsAcc;
    }, trends);

  return {
    geoConfig: {
      tooltip: {
        formatter: function(this: TooltipFormatterContextObject) {
          const renderer = viewDefn.bubble ? viewDefn.bubble.renderer : 'usMoneyNoCents';
          return `${this.point.region}<br/>${this.point.series.name}<br/>Sales: <b>${Renderer[renderer](
            this.point.z
          )}</b>`;
        },
      },
    },
    mapSeries: [
      {
        ...defaultMapSeriesOptions,
        name: 'Trending Up (> 10%)',
        data: trendUp,
        color: geo.trendUpColor,
      },
      {
        ...defaultMapSeriesOptions,
        data: trendDown,
        name: 'Trending Down (< 10%)',
        color: geo.trendDownColor,
      },
      {
        ...defaultMapSeriesOptions,
        data: trendZero,
        name: 'No Trend (+/- 0-10%)',
        color: geo.noTrendColor,
      },
    ],
    title: 'Global Region Volume and Trend',
    mapUri: (viewDefn.mapUri && mapNameToDefaultUri(viewDefn.mapUri)) || '',
  };
}

export function projectState(stateSelection: StateSelection) {
  const { areViewDefnsLoading, isViewDataLoading, viewData, viewDefns, subheader } = stateSelection;

  const commonProps = { title: 'Summary' };

  if (!areViewDefnsLoading && !isViewDataLoading && viewDefns) {
    const lookBackPredicate = makelookBackPredicate(subheader.lookBackPeriod);

    const keyFinancialsData = cascadingFilter(viewData.keyFinancials, lookBackPredicate);
    const choiceProductivityData = cascadingFilter(viewData.choiceProductivity, lookBackPredicate);
    const productMixAndTrendData = !isNil(get(keyFinancialsData, '0')) ? keyFinancialsData[0].children : [];
    const globalRegionVolumeAndTrendData = cascadingFilter(viewData.globalRegionVolumeAndTrend, lookBackPredicate);

    const keyFinancials = viewDefns.keyFinancials.view!.map((viewItem: TenantConfigViewItem) =>
      viewItemToFinancialProps(viewItem, get(keyFinancialsData, '0'))
    );
    const choiceProductivity = viewDefns.choiceProductivity.view!.map((viewItem: TenantConfigViewItem) =>
      viewItemToFinancialProps(viewItem, get(choiceProductivityData, '0'))
    );

    // we re-use the label for the first keyFinancial macro object as
    // the y-axis label of the Trend Analysis graph
    // it is possible that in the future these labels will need to diverge,
    // at which point the sharing of this label will need to be reworked
    const firstKeyFinancialsLabel = viewDefns.keyFinancials.view[0].text;

    const trendAnalysis = trendAnalysisProps(
      viewDefns.trendAnalysis.view!,
      viewData.trendAnalysis,
      firstKeyFinancialsLabel
    );
    const productMixAndTrend = prodMixProps(viewDefns.productMixAndTrend.view!, productMixAndTrendData);
    const globalRegionVolAndTrend = globalRegionProps(
      globalRegionVolumeAndTrendData,
      viewDefns.globalRegionVolumeAndTrend
    );

    return {
      ...commonProps,
      flowStatus: subheader.flowStatus,
      lookBackPeriod: subheader.lookBackPeriod,
      loaded: true,
      keyFinancials,
      choiceProductivity,
      trendAnalysis,
      productMixAndTrend,
      globalRegionVolAndTrend,
    };
  }
  return { ...commonProps, loaded: false };
}

// @ts-ignore
export default createSelector(selectState, projectState);
