import { ICellRendererParams } from 'ag-grid-community';
import * as React from 'react';
import { style } from 'typestyle';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

const styles = {
  container: style({
    width: '100%',
    height: '100%',
    textAlign: 'center',
  }),
  text: style({
    marginLeft: 15,
  }),
};

export type Params = {
  icon: string;
  value: string;
  dataQa?: string;
  onCellClicked?: (params: BasicPivotItem) => Record<string, any>;
} & ICellRendererParams;

export class IconCellRenderer extends React.Component<Params> {
  constructor(props: Params) {
    super(props);
  }

  handleClick = () => {
    const { data, onCellClicked } = this.props;

    if (onCellClicked) {
      onCellClicked(data);
    }
  };

  render() {
    const { value, icon, dataQa } = this.props;

    let iconClass = `fa fa-${icon}`;
    if (iconClass.startsWith('far') || iconClass.startsWith('fas') || iconClass.startsWith('fa')) {
      iconClass = icon;
    }

    return (
      <div data-qa={dataQa} className={styles.container} onClick={this.handleClick}>
        <i className={iconClass} />
        <span className={styles.text}>{value ? value : undefined}</span>
      </div>
    );
  }
}
