import React from 'react';
import cuid from 'cuid';
import { isNil } from 'lodash/fp';
import StarRatings from 'react-star-ratings';
import { default as styles, starDimension, starSpacing } from './StandardCard.styles';
import { Popover, PopoverBody } from 'reactstrap';
import {
  IMGWIDTH,
  TOPHEIGHT,
  COLHEIGHT,
  COLWIDTH,
  HORPADDING,
  BORDERCLASS,
  BOTTOMHEIGHT,
  BORDERWIDTH,
} from './StandardCard.styles';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import { ID } from 'src/utils/Domain/Constants';
import { WorklistIcon, WorklistType } from 'src/components/WorklistIcon/WorklistIcon';
import { getWorklistContextMenuItems, ContextMenuType } from 'src/components/WorklistContextMenu/WorklistContextMenu';
import { WorklistContextMenu } from 'src/components/WorklistContextMenu/WorklistContextMenuReact';
import { starGoldColor } from 'src/utils/Style/Theme';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
import Renderer from 'src/utils/Domain/Renderer';
import { CardViewCardColumn } from '../UIData.types';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';
import { classes } from 'typestyle';

/* eslint-disable @typescript-eslint/naming-convention */
export type StandardCardProps = {
  popoverTitle?: string;
  imgSrc: string;
  stars: number;
  id: string;
  name: string;
  styleName: string;
  styleId: string;
  styleColorName?: string;
  leafId?: string;
  description: string;
  styleDescription: string;
  value: number | string;
  valueRenderer: string;
  columns?: CardViewCardColumn[];
  departmentId?: string;
  swatchIds?: string[];
  onItemClick?: (item: ContainerPayload, eventTarget?: HTMLElement) => void;
  onMoreInfoClick?: (item: ContainerPayload) => void;
  isSelected?: boolean;
  stylePaneKey?: string;
  worklist_type?: WorklistType;
  allowWorklistFunctionality?: boolean;
  refetchWorklist?(): void;
  /** Setting to true renders a card (with no rendered image/hover styles) to fill in blank space in the cards Grid.
   *  This prevents a potential runtime error during card props lookup in ColumnGroupedView component */
  isBlankCard?: boolean;
};

type State = {
  popoverOpen: boolean;
  loadingImages: boolean;
  targetId: string;
};

export class StandardCard extends React.Component<StandardCardProps, State> {
  inCleanup: boolean;
  static calcCardWidth = (colItemCount: number) => {
    // Add .01 so if the number of items is 1 over the total items per col, it will create the extra column
    return (
      IMGWIDTH + 10 + (Math.ceil(colItemCount / Math.ceil(TOPHEIGHT / COLHEIGHT) + 0.01) || 1) * COLWIDTH + HORPADDING
    );
  };

  static calcCardHeight = () => {
    return TOPHEIGHT + BOTTOMHEIGHT + BORDERWIDTH * 2;
  };
  constructor(props: StandardCardProps) {
    super(props);
    this.renderPopper = this.renderPopper.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.render = this.render.bind(this);
    this.inCleanup = false;
    this.state = {
      popoverOpen: false,
      loadingImages: false,
      targetId: cuid(),
    };
  }

  onItemClick(event: React.ChangeEvent<HTMLSelectElement>) {
    if (!isNil(this.props.onItemClick)) {
      this.props.onItemClick(
        {
          type: IS_HINDSIGHTING(this.props.stylePaneKey) ? 'HistoryStylePane' : 'StyleDetails',
          id: this.props.leafId || this.props.id,
          parentId: this.props.styleId,
          styleColorName: this.props.styleColorName,
          isAssortmentBuild: IS_HINDSIGHTING(this.props.stylePaneKey) ? undefined : true,
        } as ContainerPayload,
        event.target
      );
    }
  }

  handleClick(event: React.SyntheticEvent<HTMLElement>) {
    if (this.props.onItemClick && !this.props.allowWorklistFunctionality) {
      this.props.onItemClick(
        {
          type: IS_HINDSIGHTING(this.props.stylePaneKey) ? 'HistoryStylePane' : 'StyleDetails',
          id: this.props.leafId || this.props.id,
          parentId: this.props.styleId,
          styleColorName: this.props.styleColorName,
          isAssortmentBuild: IS_HINDSIGHTING(this.props.stylePaneKey) ? undefined : true,
        } as ContainerPayload,
        event.target as HTMLElement
      );
    } else if (this.props.onItemClick && this.props.allowWorklistFunctionality) {
      this.togglePopover();
    }
  }

  togglePopover = () => {
    if (this.props.allowWorklistFunctionality) {
      this.setState(() => ({
        popoverOpen: !this.state.popoverOpen,
      }));
    }
  };

  onMoreInfoClick = (item: ContainerPayload) => {
    if (this.props.onMoreInfoClick) {
      this.props.onMoreInfoClick(item);
    }
    this.togglePopover();
  };

  componentWillUnmount() {
    this.inCleanup = true;
  }

  renderPopper() {
    const { popoverOpen } = this.state;
    const openStylePane = this.props.onItemClick!.bind(null, {
      type: IS_HINDSIGHTING(this.props.stylePaneKey) ? 'HistoryStylePane' : 'StyleDetails',
      id: this.props.leafId || this.props.id,
      parentId: this.props.styleId,
      isAssortmentBuild: IS_HINDSIGHTING(this.props.stylePaneKey) ? undefined : true,
    } as ContainerPayload);

    const popoverContext = (
      <WorklistContextMenu
        worklistContextMenuItems={getWorklistContextMenuItems(
          {
            identityField: ID,
            parentIdentityField: 'styleId',
            type: ContextMenuType.card,
            openStylePane,
            refetchWorklist: this.props.refetchWorklist,
            onPostAction: () => {
              this.setState({
                popoverOpen: false,
              });
            },
          },
          {
            node: { data: this.props },
          }
        )}
      />
    );

    return (
      <Popover
        target={this.state.targetId}
        isOpen={this.state.popoverOpen}
        placement="right"
        toggle={this.togglePopover}
        className={(!popoverOpen ? styles.popperClosed : '') + ' ' + styles.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        <PopoverHeader className={styles.popoverHeader}>{this.props.popoverTitle}</PopoverHeader>
        <PopoverBody>{popoverContext}</PopoverBody>
      </Popover>
    );
  }

  render() {
    const props = this.props;
    const {
      imgSrc,
      description,
      stars,
      value,
      valueRenderer,
      id,
      name,
      isSelected,
      worklist_type,
      isBlankCard,
    } = props;

    const columns = props.columns
      ? props.columns.map((column, index) => {
          const rendererFn = Renderer[column.renderer];
          const renderedValue = rendererFn ? rendererFn(column.value) : column.value;
          return (
            <div key={index} className={styles.colItem} data-qa={column.title.toLocaleLowerCase()}>
              <div className={BORDERCLASS} />
              {column.title}
              <br />
              {renderedValue}
            </div>
          );
        })
      : [];
    const finalSrc = imgSrc;
    const cardDisplay = (
      <div id={this.state.targetId} data-qa-action="CardClick" className={styles.infoContainer}>
        <CenteredImage src={finalSrc} width={IMGWIDTH} height={TOPHEIGHT} extraImgProps={{ isBlankCard }} />
        {columns.length > 0 ? (
          <div data-qa-component="CardColumns" className={styles.column}>
            {columns}
          </div>
        ) : null}
      </div>
    );
    let starsDisplay = <div />;
    if (!isNil(stars)) {
      starsDisplay = (
        <StarRatings
          rating={stars}
          numberOfStars={5}
          starDimension={starDimension}
          starSpacing={starSpacing}
          starRatedColor={starGoldColor}
        />
      );
    }

    const selectedIcon = isSelected ? (
      <React.Fragment>
        <div className={styles.cardOverlay} />
        <i className={`fas fa-check-circle ${styles.iconOverlay}`} />
      </React.Fragment>
    ) : null;

    const worklistIcon = !worklist_type ? null : <WorklistIcon icon={worklist_type} />;
    const rendererFn = Renderer[valueRenderer];
    const renderedValue = rendererFn ? rendererFn(value) : value;
    return (
      <div
        className={classes(styles.card, styles.getHoverStyle(props.isBlankCard || false))}
        style={{
          width: StandardCard.calcCardWidth(columns.length),
        }}
        data-qa-component="StandardCard"
        data-qa-action="OpenPopover"
        onClick={this.handleClick}
        data-qa-key={id}
      >
        <span className={styles.worklistCard}>{worklistIcon}</span>
        {selectedIcon}
        {cardDisplay}
        {this.props.allowWorklistFunctionality ? this.renderPopper() : null}
        <div className={styles.infoRow}>
          <div className={styles.stars}>{starsDisplay}</div>
          <div className={styles.value}>{renderedValue}</div>
        </div>
        <div>
          <div className={styles.description}>{name}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    );
  }
}
