import { isNaN, isNil, isNumber, has, reduce, flow, map } from 'lodash';
import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { IsColumnFuncParams, ValueSetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { Renderer } from 'src/utils/Domain/Renderer';
import { ColDefAccumulator } from './OvertimeView.types';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { style } from 'typestyle';

const tealBackgroundStyle = style({
  backgroundColor: 'rgba(220, 243, 241, .7)',
});
function createColumnDef({ id, header }: { id: string; header: string }): ColDef {
  return {
    field: id,
    headerName: header,
    valueFormatter: (params: ValueFormatterParams) => {
      if (has(params, 'data.formatter')) {
        const data = params.data[id];
        const finalData = !isNumber(data) || isNaN(data) ? 0 : data;
        if (params.node.data.formatter === 'rawValue') {
          return Renderer[params.data.formatter](data);
        }
        return Renderer[params.data.formatter](finalData);
      }
      if (params.value) {
        return params.value;
      }
    },
    cellClassRules: {
      [tealBackgroundStyle]: (params: any) => {
        return params.data != null && params.data.editable;
      },
    },
    valueGetter: (params: ValueGetterParams) => {
      if (params.node.group) {
        return '';
      } else {
        return params.node.data.formatter !== 'rawValue' ? Number.parseFloat(params.data[id]) : params.data[id];
      }
    },
    valueSetter: (params: ValueSetterParams) => {
      const data = params.node.data;
      data[params.column.getColId()] = params.newValue;
      return true;
    },
    editable: (params: IsColumnFuncParams) => {
      const parent = params.node.parent;
      if (isNil(parent)) {
        return false;
      }
      return params.node.data.editable;
    },
  };
}

export function generateColDefs(levels: string[], treeData: BasicPivotItem[]): ColDef[] {
  const [monthName, weekName] = levels;
  const accumulator: ColDefAccumulator = {};
  const columnDefs = flow(
    () => treeData,
    (tree) => {
      return reduce(
        tree,
        (acc, i) => {
          if (has(acc, i[monthName])) {
            acc[i[monthName]].push({ id: i.week, header: i[weekName] });
          } else {
            acc[i[monthName]] = [{ id: i.week, header: i[weekName] }];
          }
          return acc;
        },
        accumulator
      );
    },
    (monthObj) => {
      return map(monthObj, (weeks, month) => {
        if (isNil(weekName)) {
          return {
            headerName: month,
            ...createColumnDef({ id: month, header: month }),
          };
        } else {
          return {
            headerName: month,
            children: weeks.map(createColumnDef),
          };
        }
      });
    }
  )();

  return columnDefs;
}
