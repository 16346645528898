import {
  IsColumnFuncParams,
  ValueFormatterParams,
  SuppressKeyboardEventParams,
  ValueSetterParams,
  ValueGetterParams,
  BaseWithValueColDefParams,
  CellClassParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import {
  ColGroupDef,
  ColDef,
  CellClickedEvent,
  GridApi,
  GridReadyEvent,
  CellEditingStartedEvent,
  ColumnApi,
  CellValueChangedEvent,
  ICellRendererParams,
  ICellEditorParams,
} from 'ag-grid-community';

import { CompanionListView } from 'src/common-ui/index';
import { Props as CompanionProps, ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { DataGridProps } from 'src/common-ui/components/DataGrid/DataGrid';
import {
  mapValues,
  findIndex,
  isNil,
  memoize,
  parseInt,
  isEmpty,
  last,
  hasIn,
  flow,
  forEach,
  get,
  isEqual,
  concat,
  slice,
  omitBy,
  isBoolean,
  every,
  some,
} from 'lodash';
import React from 'react';
import { resolvePath } from 'src/cdn';

import { Overlay } from 'src/common-ui/index';
import {
  STYLE_ID,
  STYLE_COLOR_ID,
  LOCKED_AFTER_STYLE_SUBMIT,
  LOCKED_AFTER_COLOR_SUBMIT,
  STYLE_SUBMITTED_ATTR,
  COLOR_SUBMITTED_ATTR,
  IS_PUBLISHED,
  PUBLISHED_TEXT,
  UNPUBLISHED_TEXT,
  ATTR_GRADE,
  ATTR_CLIMATE,
  ATTR_MENSCAPACITY,
  ATTR_WOMENSCAPACITY,
  ATTR_SSG,
  ATTR_FUNDED,
  USERADJ,
  ONORDERREVISION,
  SLSUOVERRIDE,
  PARTIAL_PUBLISHED_TEXT,
  POPOVER_BLOCK_CODES,
  BLOCK_ENTER_EDITORS,
  STORE_COUNT,
} from 'src/utils/Domain/Constants';
import {
  companionStyles,
  gridListPairStyle,
  styles,
  extraRowContainerStyles,
  listPairStyle,
  gridNoDataOverlayStyles,
  gridContainerStyle,
  editableCell,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import {
  ConfigurableGridState,
  ConfigurableGridConfigItem,
  ConfigurableGridColumnDef,
  ConfigurableGridProps,
  TopAttributesConfig,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { SubheaderDropdownSlice, initialSortBy } from 'src/components/Subheader/Subheader.slice';
import { RangePickerEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/RangePickerEditor';
import TabbedReceiptsAdjCalculator from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TabbedReceiptsAdjCalculator';
import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import CheckboxCellRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/Checkbox';
import { ColorHeaderRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ColorHeaderRenderer';
import { IconCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/IconCellRenderer';

import { ImageCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ImageCellRenderer';
import { RangePickerRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RangePickerRenderer';
import { ValidSizesRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidSizesRenderer';
import Axios from 'src/services/axios';
import { map, reduce, isNumber, partial, isArray } from 'lodash/fp';
import { processApiParams, getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { RowNode } from 'ag-grid-community';
import Subheader from 'src/components/Subheader/Subheader.container';
import { PassedProps as SubheaderProps } from 'src/components/Subheader/Subheader';
import globalMath from 'mathjs';
import { executeCalculation, importDateFunctions } from 'src/utils/LibraryUtils/MathUtils';
import { groupedToAgFlatTree, generateOrderedGroups, AgFlatResult } from 'src/utils/Component/AgGrid/AgDataFormat';
import { flattenToLeaves } from 'src/utils/Pivot/Flatten';
import ArrowValueRenderer, {
  ArrowValueRendererProps,
  ARROWDIRECTIONS,
} from 'src/components/ArrowValueRenderer/ArrowValueRenderer';
import CustomGroupHeader from 'src/components/ConfigurableGrid/CustomGroupHeader';
import LifecycleParametersEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/LifecycleParametersEditor';
import SalesAdjustmentEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/SalesAdjustmentEditor';
import ServiceContainer from 'src/ServiceContainer';

import { style } from 'typestyle';
import { simpleByField } from 'src/utils/Pivot/Sort';
import Renderer from 'src/utils/Domain/Renderer';
import coalesce from 'src/utils/Functions/Coalesce';
import {
  TextValidationEditor,
  PENDING_VALIDATION_VALUE,
  TVE_InputCharacterWhitelist,
  PendingCellInfo,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationEditor';
import ValidValuesRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidValuesRenderer';
import { getMergedRangeLists } from 'src/dao/scopeClient';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';
import { parseObservers, ObservableGridProps, isObservedProp } from 'src/utils/Component/ObservervableGridProps';
import { isObject } from 'util';
import { updateStyleItem, getDependentsFromResp } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import TooltipRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/TooltipRenderer';
import { updateLifecycleParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import {
  calculateColumnWidth,
  updateWithClientHandler,
  replaceExtraProps,
  resetAsyncValidationData,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { BasicPivotItem, BasicItem } from 'src/worker/pivotWorker.types';
import { SeverityRenderer } from '../SeverityRenderer/SeverityRenderer';
import { StarPercentRenderer } from '../StarPercentRenderer/StarPercentRenderer';
import { StarEditor } from 'src/components/StarEditor/StarEditor';
import { StatusIconRenderer } from '../StatusIconRenderer/StatusIconRenderer';
import ConfigurableDataModal from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal';
import { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import HeatmapRenderer from '../HeatmapRenderer/HeatmapRenderer';
import CheckboxHeaderRenderer, { CheckboxHeaderRendererProps } from '../CheckboxHeaderRenderer/CheckboxHeaderRenderer';
import { MassEdit } from 'src/components/MassEdit/MassEditv2';
import { BasicItem as PivotBasicItem } from 'src/worker/pivotWorker.types';
import ValidValuesCheckBoxEditor from '../ValidValuesCheckboxEditor/ValidValuesCheckboxEditor';
import {
  ValidValuesCheckBoxEditorHeader,
  ValidValuesCheckBoxEditorHeaderProps,
} from '../ValidValuesCheckboxEditor/ValidValuesCheckboxEditorHeader';
import { logError } from 'src/services/loggingService';
import { FabType } from '../higherOrder/withFab';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { SelectorSubheaderDropdownProps } from './ConfigurableGrid.selectors';
import { ConfigurableGridGroupBySelection } from './ConfigurableGrid.slice';

const noImage = resolvePath(noImagePath);

type FrameworkComponents = any;
type NonFrameworkComponents = any;
type PostValue = any;
interface MassColumnUpdateParams {
  value: string;
  dataIndex: string;
  nodes: RowNode[];
}

export const CustomNoRowsOverlay = () => <div className={gridNoDataOverlayStyles}>No Data</div>;

type AsyncValidationErrorProps = {
  initial: string;
  invalidValue: string;
  invalidDescription?: string;
};

export const AsyncValidationErrorMessage = (props: AsyncValidationErrorProps) => {
  return (
    <div>
      <p>{`${props.invalidValue} is not available for use.`}</p>
      {props.invalidDescription && <p>{`${props.invalidDescription}`}</p>}
      <p>{`The value was reset to ${props.initial}.`}</p>
      <p>{`Please try with a different value.`}</p>
    </div>
  );
};

const nonFrameworkComponents: NonFrameworkComponents = {
  colorHeaderRenderer: ColorHeaderRenderer,
};

const frameworkComponents: FrameworkComponents = {
  imageCellRenderer: ImageCellRenderer,
  validValuesEditor: ValidValuesEditor,
  validValuesRenderer: ValidValuesRenderer,
  rangePickerRenderer: RangePickerRenderer,
  rangePickerEditor: RangePickerEditor,
  checkboxCellRenderer: CheckboxCellRenderer,
  receiptsAdjCalculator: TabbedReceiptsAdjCalculator,
  customGroupHeader: CustomGroupHeader,
  lifecycleParametersEditor: LifecycleParametersEditor,
  salesAdjustmentEditor: SalesAdjustmentEditor,
  validSizesRenderer: ValidSizesRenderer,
  iconCellRenderer: IconCellRenderer,
  textValidationEditor: TextValidationEditor,
  integerEditor: IntegerEditor,
  customNoRowsOverlay: CustomNoRowsOverlay,
  tooltipRenderer: TooltipRenderer,
  severityRenderer: SeverityRenderer,
  starPercentRenderer: StarPercentRenderer,
  starEditor: StarEditor,
  statusIconRenderer: StatusIconRenderer,
  configurableDataModal: ConfigurableDataModal,
  arrowValueRenderer: ArrowValueRenderer,
  heatmapRenderer: HeatmapRenderer,
  gridHeaderCheckbox: CheckboxHeaderRenderer,
  validValuesCheckbox: ValidValuesCheckBoxEditor,
  validValuesCheckboxHeader: ValidValuesCheckBoxEditorHeader,
};

export class ConfigurableGrid extends React.Component<ConfigurableGridProps, ConfigurableGridState> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  nonFrameworkComponents: NonFrameworkComponents;
  frameworkComponents: FrameworkComponents;
  selectPopupLeft!: number;
  selectPopupTop!: number;
  observers: ObservableGridProps = {};
  allowEnterList: (string | undefined)[] = [];
  math: globalMath.MathJsStatic = (globalMath as any).create();

  constructor(props: ConfigurableGridProps) {
    super(props);
    this.state = {
      gridRdyCnt: 0,
      selectedIndex: 0,
      companionCollapsed: false,
      companionSortDirection: 'desc',
      companionSortField: undefined,
      activeStyleColor: '',
      styleColorsEdited: [],
      treeColumnDefinition: undefined,
      massEditGridProcessing: false,
    };

    const aggregateColumn = (
      args: globalMath.MathNode[],
      _mathjs: globalMath.MathJsStatic,
      scope: { [s: string]: any }
    ) => {
      const expressionField = args.map((arg) => (arg.name ? arg.name : ''));

      if (expressionField.length < 1 || isNil(this.gridApi)) {
        return 0;
      }

      const field = expressionField[0];
      const colDef = this.props.columnDefs.find((col) => col.dataIndex === field);
      let aggType = get(colDef, 'aggregator', 'sum');
      if (aggType === 'eval') {
        console.warn("We don't currently support eval aggs in other aggs. Falling back to sum.");
        aggType = 'sum';
      }
      const fieldAggregation = this.math[aggType](scope[field]);

      return fieldAggregation;
    };

    // register AGG method with mathjs
    (aggregateColumn as any).rawArgs = true;
    this.math.import({ AGG: aggregateColumn }, { override: true });
  }

  async componentDidMount() {
    // setup math functions with date math handlers
    const mergedRangeList = await getMergedRangeLists();
    try {
      importDateFunctions(this.math, mergedRangeList);
    } catch (error) {
      console.error('error:', error);
    }
  }

  componentDidUpdate(prevProps: ConfigurableGridProps) {
    if (!isEqual(prevProps.favoritesList, this.props.favoritesList)) {
      const activeFavorite = this.props.favoritesList.find((x) => x.active === true);
      if (
        activeFavorite &&
        activeFavorite.jsonBlob &&
        activeFavorite.jsonBlob.companionData &&
        activeFavorite.jsonBlob.groupBySelection &&
        this.props.groupByDropdownProps
      ) {
        const compData = activeFavorite.jsonBlob.companionData;
        const nextSelectedIndex = activeFavorite.jsonBlob.groupBySelection || 0;
        const dropdownData: ConfigurableGridGroupBySelection = {
          selectedIndex: nextSelectedIndex,
          option: this.props.groupByDropdownProps.options[nextSelectedIndex],
        };

        this.setState(
          {
            companionSortField: compData.companionSortField,
            companionSortDirection: compData.companionSortDirection,
            companionCollapsed: compData.companionCollapsed,
          },
          () => {
            const selectedIndex = this.props.groupByDropdownProps?.selection;
            if (nextSelectedIndex !== selectedIndex) {
              this.props.setGroupBySelection(dropdownData);
            }
          }
        );
      } else {
        this.setState({
          companionSortField: this.props.defaultCompanionSortField,
          companionSortDirection: 'desc' as const,
          companionCollapsed: false,
        });
      }
    }
  }

  onUpdateConfig = (config: any) => {
    if (config.isDefault) {
      this.setState({
        companionSortDirection: 'desc' as const,
        companionCollapsed: false,
        companionSortField: this.props.defaultCompanionSortField,
      });
    }

    this.props.onUpdateConfig(config);

    if (!isNil(this.props.groupByDropdownProps)) {
      this.props.setGroupBySelection({
        selectedIndex: config.groupBySelection,
        option: this.props.groupByDropdownProps.options[config.groupBySelection],
      });
    }
  };

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  handlePendingCellUpdate(value: string, pendingCellInfo: PendingCellInfo) {
    if (!isNil(pendingCellInfo.validation) && !pendingCellInfo.validation.isValid) {
      const { invalidValue, initialValue } = pendingCellInfo.validation;
      const initial = isNil(initialValue) || isEmpty(initialValue) ? 'Empty' : initialValue;
      const message = <AsyncValidationErrorMessage initial={`"${initial}"`} invalidValue={`"${invalidValue}"`} />;
      toast.error(message, {
        autoClose: false,
        position: toast.POSITION.TOP_LEFT,
      });
    }

    let rowNode: RowNode | null = null;
    let updatedCellIndex = -1;

    this.gridApi.forEachNodeAfterFilter((node, index) => {
      if (node.id === pendingCellInfo.id) {
        rowNode = node;
        rowNode.data[pendingCellInfo.dataIndex] = value;
        updatedCellIndex = index;
      }
    });

    // reset grid scroll only after validation and data is updated or reset
    let gridScrollTo = this.state.gridScrollTo;
    if (value !== PENDING_VALIDATION_VALUE) {
      gridScrollTo = {
        eventId: Date.now(),
        where: {
          key: !isNil(gridScrollTo) ? gridScrollTo.where.key : `member:${this.props.identityField}:name`,
          value,
        },
      };
      const indexSplit = pendingCellInfo.dataIndex.split(':');
      if (!isNil(rowNode) && updatedCellIndex !== -1 && indexSplit.length === 3 && indexSplit[1] === 'style') {
        updateStyleItem({
          id: (rowNode as RowNode).data[this.props.identityField] || '',
          [indexSplit[2]]: value,
        });
      }
    }
  }

  checkAllBoxes = (dataIndex: string, checked: boolean) => {
    const itemsToUpdate: RowNode[] = [];

    // update row node data without triggering cellValueChanged handler
    this.gridApi.forEachNodeAfterFilter((rowNode) => {
      if (!isNil(rowNode.allChildrenCount) && rowNode.allChildrenCount > 0) {
        // skip nodes that are group nodes.
        return;
      }
      rowNode.data[dataIndex] = checked;
      itemsToUpdate.push(rowNode.data);
    });

    // run ag-grid transaction, then post changes via mass edit api wrapper
    this.gridApi.updateRowData({ update: itemsToUpdate });

    const updateParams: MassColumnUpdateParams = {
      dataIndex,
      nodes: itemsToUpdate,
      // the values are stored in the grid data as strings
      value: checked ? 'true' : '',
    };

    this.setState(
      {
        massEditGridProcessing: true,
      },
      () => {
        this.submitMassColumnUpdate(updateParams).then(() => {
          this.setState({
            massEditGridProcessing: false,
          });
        });
      }
    );
  };

  createColumnDef = (colInfo: ConfigurableGridConfigItem) => {
    // setup observers if applicable
    this.observers = parseObservers(this.observers, colInfo);
    const floorset = this.getActiveFloorset();
    const tealBackgroundStyle = style({
      backgroundColor: 'rgba(220, 243, 241, .7)',
    });
    function isEditable(params: IsColumnFuncParams) {
      let editable = !!colInfo.editable;
      const styleSubmitted = !isNil(params.data[STYLE_SUBMITTED_ATTR]);
      const colorSubmitted =
        !isNil(params.data[COLOR_SUBMITTED_ATTR]) && params.data[COLOR_SUBMITTED_ATTR] !== 'Undefined';

      if (params.node.aggData) {
        editable = false;
      } else if (LOCKED_AFTER_STYLE_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
        return colInfo.editable && !styleSubmitted;
      } else if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
        return colInfo.editable && !colorSubmitted;
      }
      if (params.node.allChildrenCount && params.node.allChildrenCount > 0) {
        return !!colInfo.cascadeGroupSelection;
      }
      if (colInfo.inputType === 'checkbox' || colInfo.renderer === 'starEditor') {
        // the checkbox renderer handles both rendering and editing together, and does not need to be marked as editable in the editable callback
        return false;
      }

      return editable;
    }

    const calculatedWidth = colInfo.width || calculateColumnWidth(colInfo.dataIndex);
    let headerInfo: {
      component?: string;
      params?: ValidValuesCheckBoxEditorHeaderProps | CheckboxHeaderRendererProps;
    } = {
      component: undefined,
      params: undefined,
    };
    if (colInfo.inputType === 'checkbox') {
      // console.log(this.state.data);
      headerInfo = {
        component: 'gridHeaderCheckbox',
        params: {
          onChange: this.checkAllBoxes.bind(this, colInfo.dataIndex),
          checkedStatus: 'indeterminate',
          isProcessing: this.state.massEditGridProcessing,
        },
      };
    } else if (colInfo.renderer === 'validValuesCheckbox') {
      const availableHeaders = colInfo.options ? colInfo.options.map((o) => o.text) : [];
      headerInfo = {
        component: 'validValuesCheckboxHeader',
        params: {
          availableHeaders,
        },
      };
    }
    return {
      width: calculatedWidth,
      headerName: colInfo.text,
      headerComponent: headerInfo.component,
      headerComponentParams: headerInfo.params,
      pinned: colInfo.pinned,
      renderer: colInfo.renderer,
      field: colInfo.dataIndex,
      inputParams: colInfo.inputParams,
      inputType: colInfo.inputType,
      hide: isNil(colInfo.hidden) ? false : true,
      suppressToolPanel: isNil(colInfo.hidden) ? false : true,
      calculation: colInfo.calculation,
      cellStyle: (params: CellClassParams) => {
        if (colInfo.renderer === 'backgroundFill') {
          return {
            'background-color': params.value,
            color: 'transparent',
            padding: 0,
          };
        }
        if (!isNil(colInfo.invalidDataIndex) && params.data[colInfo.invalidDataIndex] === true) {
          return { border: '1px solid #ff0000' };
        }

        return;
      },
      cellClass: colInfo.cellClass,
      cellClassRules: {
        [editableCell]: (params: IsColumnFuncParams) => {
          // popover is no longer configured to be editable, so need this to style icon correctly
          const editable = colInfo.dataIndex === 'popoverTrigger' || isEditable(params);
          return editable && !params.node.aggData ? editableCell : undefined;
        },
        [tealBackgroundStyle]: (params: IsColumnFuncParams) => {
          return !!colInfo.highlightColumn && !params.node.aggData ? tealBackgroundStyle : undefined;
        },
      },
      rowGroup: colInfo.rowGroup ? colInfo.rowGroup : false,
      editable: (params: IsColumnFuncParams) => {
        return isEditable(params);
      },
      // Only give comparator to columns that have one
      ...(colInfo.comparator &&
        colInfo.comparator.type && {
          comparator: (valueA: string | undefined, valueB: string | undefined) => {
            let compValue: number;
            if (isNil(valueA)) compValue = -1;
            else if (isNil(valueB)) compValue = 1;
            // we know it's not null above, but the check is "lost" as the function potentially escapes scope
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            switch (colInfo.comparator!.type) {
              case 'datetime':
                const [dateA, dateB] = [[valueA], [valueB]];

                if (colInfo.comparator && colInfo.comparator.options && colInfo.comparator.options.format) {
                  const comparatorFormat = colInfo.comparator.options.format;
                  dateA.push(comparatorFormat);
                  dateB.push(comparatorFormat);
                }

                const momentA = moment(...dateA);
                const momentB = moment(...dateB);

                if (momentA.isAfter(momentB)) {
                  compValue = 1;
                } else if (momentA.isBefore(momentB)) {
                  compValue = -1;
                } else {
                  compValue = 0;
                }
                break;
              case 'number':
                const numA = Number(valueA);
                const numB = Number(valueB);

                if (numA > numB) {
                  compValue = 1;
                } else if (numB > numA) {
                  compValue = -1;
                } else {
                  compValue = 0;
                }
                break;
              default:
                compValue = 0;
            }
            return compValue;
          },
        }),
      valueGetter: (params: ValueGetterParams) => {
        const calculation = (params.colDef as ConfigurableGridColumnDef).calculation;
        const field = params.colDef!.field!;
        const getDataFn = (key: string) => {
          const returnObj = {
            rowNodeFound: false,
            data: undefined,
          };
          returnObj.rowNodeFound = true;
          if (params.data[key]) {
            returnObj.data = params.data[key];
          } else {
            // If it can't find the key, try finding it with attribute appended
            returnObj.data = params.data['attribute:' + key + ':id'];
          }
          return returnObj;
        };

        if (calculation) {
          const newValue = executeCalculation(this.math, calculation, getDataFn);
          return newValue;
        }
        if (params.data && !isNil(params.data[field])) {
          return params.data[field];
        }
        if (
          params.data &&
          // @ts-ignore special s5 property
          params.colDef.renderer === 'checkbox' &&
          params.data[field] === ''
        ) {
          // special case for checkbox renderer, for if darwin sends empty string (which js treats as falsy), to avoid the falsy value getting converted to null below
          return false;
        }
        return null;
      },
      valueSetter: (params: ValueSetterParams) => {
        const { newValue, data, colDef, node } = params;
        const field = colDef.field;

        if (newValue && !isEmpty(newValue.storeData)) {
          // Saving for store eligibility
          const storeDataByFloorset = Array.isArray(newValue.storeData[floorset])
            ? newValue.storeData[floorset][0]
            : newValue.storeData[floorset];
          if (storeDataByFloorset) {
            data[ATTR_GRADE] = storeDataByFloorset['grade'];
            data[ATTR_CLIMATE] = storeDataByFloorset['strclimate'];
            data[ATTR_MENSCAPACITY] = storeDataByFloorset['strmenscapacity'];
            data[ATTR_WOMENSCAPACITY] = storeDataByFloorset['strwomenscapacity'];
            data[ATTR_SSG] = storeDataByFloorset['ssg:ids']
              ? storeDataByFloorset['ssg:ids']
              : storeDataByFloorset['ssg'];
            data[ATTR_FUNDED] = storeDataByFloorset['isfunded'];
            data[STORE_COUNT] = storeDataByFloorset[STORE_COUNT];
          }

          // Saving lifecycle
          if (!isEmpty(newValue.lifecycleData)) {
            const lifecycleParsedData = {};
            Object.keys(newValue.lifecycleData).forEach((key) => {
              // Lifecycle data doesn't have attribute in the dataindex so this tries to cover the bases
              lifecycleParsedData[key] = newValue.lifecycleData[key];
              lifecycleParsedData[`attribute:${key}:id`] = newValue.lifecycleData[key];
              lifecycleParsedData[`attribute:${key}:name`] = newValue.lifecycleData[key];
            });
            node.setData({
              ...data,
              ...lifecycleParsedData,
            });
          }
        } else if ((field === USERADJ || field === ONORDERREVISION) && newValue) {
          data[USERADJ] = newValue['userAdjRevision'];
          data[ONORDERREVISION] = newValue['onOrderRevision'];
        } else if (field === SLSUOVERRIDE && newValue) {
          data[field] = newValue;
        } else if (colInfo.inputType === 'configurableDataModal') {
          forEach(newValue, (value, key) => {
            data[key] = value;
          });
        } else if (field) {
          // if async validation, newValue will be 'PENDING'
          data[field] = newValue;

          const memberMatched = field.match(/member:([a-z]*):[a-z]*/);
          const isMemberUpdate = !isNil(memberMatched);

          if (isMemberUpdate) {
            const memberLevel = !isNil(memberMatched) ? memberMatched[1] : '';

            if (isObject(newValue)) {
              // ensure all dependentData for memberLevel is updated for dataApi lookups later
              data[`member:${memberLevel}:id`] = newValue.value;
              data[`member:${memberLevel}:name`] = newValue.label;
              data[`member:${memberLevel}:description`] = newValue.label;
            } else {
              const pendingCellInfo: PendingCellInfo = {
                id: params.node.id,
                dataIndex: !isNil(params.column) ? params.column.getColId() : '',
              };

              // this method handles correctly updating async cell updates
              // it will override the 'data[field] = newValue' value set above
              this.handlePendingCellUpdate(newValue, pendingCellInfo);
            }
          }
        }

        if (!isEmpty(newValue)) {
          // Log stylecolor as editted for planning
          if (this.state.styleColorsEdited.indexOf(data.stylecolor) === -1) {
            this.setState({
              styleColorsEdited: this.state.styleColorsEdited.concat(data.stylecolor),
            });
          }
        }

        return true;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.colDef.field === 'dc_publish') {
          switch (params.value) {
            case 2:
              return 'Published';
            case 1:
              return 'Partial';
            default:
              return '';
          }
        } else if (
          !isNil(colInfo.renderer) &&
          (hasIn(Renderer, colInfo.renderer) || colInfo.renderer === 'rendererByKey')
        ) {
          const rawValue = params.value;
          if (isNil(rawValue) || rawValue === NaN) return '';
          if (colInfo.renderer === 'rendererByKey' && colInfo.rendererKey) {
            const maybeRenderer = params.data[colInfo.rendererKey] as string;
            if (hasIn(Renderer, maybeRenderer)) {
              return Renderer[maybeRenderer](rawValue);
            }
          }
          return Renderer.renderJustValue(rawValue, colInfo);
        } else {
          // if the value goes down this path, it ends up in the default ag-grid renderer,
          // which aproximates params.value.toString()
          return params.value;
        }
      },
      cellEditorSelector: (params: ICellEditorParams): ComponentSelectorResult => {
        let row: RowNode;
        if (!params) {
          return (null as unknown) as ComponentSelectorResult;
        }
        if (params.node == null) {
          return (null as unknown) as ComponentSelectorResult;
        } else {
          row = params.node;
        }

        const styleColor = params.data['id'];
        // FIXME: see EAS-607
        let processedDataApi, processedConfigApi;
        if (colInfo.dataApi != null) {
          processedDataApi = processApiParams(colInfo.dataApi, row);
        }
        if (colInfo.configApi) {
          processedConfigApi = processApiParams(colInfo.configApi, row);
        }

        switch (colInfo.inputType) {
          case 'select':
            return {
              component: 'agRichSelect',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'lifecycleParameters':
            const headerSubtext = `
              ${params.data['name']} | ${params.data['description']}`;
            return {
              component: 'lifecycleParametersEditor',
              params: {
                dataApiLifecycle: {
                  ...colInfo.dataApiLifecycle,
                  params: {
                    appName: 'assortment',
                    product: styleColor,
                    ...get(colInfo.dataApiLifecycle, 'params', {}),
                  },
                },
                dataApiStore: {
                  ...colInfo.dataApiStore,
                  params: {
                    appName: 'assortment',
                    product: styleColor,
                    ...get(colInfo.dataApiStore, 'params', {}),
                  },
                },
                lifecycleConfig: {
                  ...colInfo.lifecycleConfig,
                  params: !isNil(colInfo.lifecycleConfig) ? { ...colInfo.lifecycleConfig.params } : {},
                },
                storeConfig: {
                  ...colInfo.storeConfig,
                  params: !isNil(colInfo.storeConfig) ? { ...colInfo.storeConfig.params } : {},
                },
                dependentsApi: {
                  ...colInfo.dependentsApi,
                },
                floorset: floorset,
                product: styleColor,
                headerSubtext,
              },
            };
          case 'salesAdjustment':
            return {
              component: 'salesAdjustmentEditor',
              params: {
                dataApi: {
                  ...colInfo.dataApi,
                },
                configData: this.props.salesAdjustmentConfig,
                floorset: this.getActiveFloorset(),
                isEditable: false,
              },
            };
          case 'receiptsAdjCalculator':
            return {
              component: 'receiptsAdjCalculator',
              params: {
                isEditable: false,
                dataApi: {
                  url: colInfo.dataApi.url,
                },
                floorset: this.getActiveFloorset(),
              },
            };
          case 'configurableDataModal': {
            const cellDataIndex = colInfo.dataIndex;
            return {
              component: 'configurableDataModal',
              params: {
                isEditable: colInfo.editable,
                configApi: {
                  url: colInfo.configApi.url,
                },
                floorset: this.getActiveFloorset(),
                cellDataIndex,
                renderTabs: colInfo.renderModalTabs,
              },
            };
          }
          case 'validValues':
          case 'validValuesMulti': {
            const multiSelect = colInfo.inputType === 'validValuesMulti' ? true : undefined;
            const dataQa = isNil(multiSelect) ? 'select-configurable-grid' : 'select-multi-configurable-grid';
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            // only return full object on member updates
            const returnSelectionObject = colInfo.dataIndex.match(/member:([a-z]*):[a-z]*/);
            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: processedConfigApi || processedDataApi,
                dataQa,
                multiSelect,
                asCsv: colInfo.asCsv,
                postArrayAsString: colInfo.postArrayAsString,
                allowEmptyOption,
                returnSelectionObject,
                ignoreCache: colInfo.ignoreCache,
                includeCurrent: colInfo.includeCurrent,
                concatOptionValues: colInfo.concatOptionValues,
              },
            };
          }
          case 'textValidator':
          case 'textValidatorAsync': {
            const inputParams = colInfo.inputParams;
            const whitelist: typeof TVE_InputCharacterWhitelist = get(
              TVE_InputCharacterWhitelist,
              inputParams.whitelist,
              TVE_InputCharacterWhitelist.alphaNumericSeparators
            );
            const pendingCellInfo: PendingCellInfo = {
              id: params.node.id,
              dataIndex: !isNil(params.column) ? params.column.getColId() : '',
            };
            return {
              component: 'textValidationEditor',
              params: {
                validateAsync: colInfo.inputType === 'textValidatorAsync',
                invalidDataIndex: colInfo.invalidDataIndex,
                ...inputParams,
                whitelist,
                pendingCellInfo,
                onValidated: this.handlePendingCellUpdate.bind(this), // will be invoked in promise context, so need to set context
              },
            };
          }
          case 'integer':
            const int = params.data[this.state.activeStyleColor];
            const percent = colInfo.renderer === 'percent';
            return {
              component: 'integerEditor',
              params: {
                passedInt: int,
                inputParams: { ...colInfo.inputParams, percent },
              },
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: true,
              },
            };
          default: {
            return {
              component: 'agTextCellEditor',
            };
          }
        }
      },
      cellRendererSelector: (params: ICellRendererParams): ComponentSelectorResult => {
        let row: RowNode;
        if (!params || params.node == null) {
          return (null as unknown) as ComponentSelectorResult;
        }
        if (params.node.aggData) {
          // first group modification in ConfigurableGrid. Checkbox is only current inline-renderer/editor
          // with group modification support.
          // When cascadeGroup, allow the field to be editable, then handle the result on change.
          if (colInfo.renderer === 'checkbox') {
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: colInfo.cascadeGroupSelection,
                // This treats the field as true|false|(null|undef). In null undef case, field shows "[-]"
                allowIndeterminate: true,
              },
            };
          }
          return (null as unknown) as ComponentSelectorResult;
        } else {
          row = params.node;
        }

        switch (colInfo.renderer) {
          case 'image':
            return {
              component: 'imageCellRenderer',
            };
          case 'validValuesCheckbox':
            const availableSelections = colInfo.options ? colInfo.options.map((c) => c.value) : [];
            return {
              component: 'validValuesCheckbox',
              params: {
                isEditable: true,
                availableSelections,
              },
            };
          case 'icon':
            let value = (params as BaseWithValueColDefParams).value;
            value = value && value[0] && value[0].value ? value[0].value.id : value;
            let icon = colInfo.rendererIcon;

            if (params.colDef!.field === 'attribute:cccolor:id') {
              const isLocked = params.data['is_locked'];
              if (isLocked === 1) {
                icon = colInfo.rendererIcon2!;
              }
            }
            if (params.colDef!.field === 'attribute:isfunded:id') {
              if (value === 1) {
                icon = colInfo.rendererIcon2!;
              }
              value = undefined;
            }

            const rendererParams = {
              icon,
              value,
            };

            if (colInfo.dataIndex === 'popoverTrigger') {
              rendererParams['onCellClicked'] = (item: BasicPivotItem) => {
                if (this.props.onItemClicked) {
                  this.props.onItemClicked(item);
                }
              };
              rendererParams['dataQa'] = 'StylePaneTrigger';
            }

            return {
              component: 'iconCellRenderer',
              params: rendererParams,
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: true,
              },
            };
          case 'range_picker':
            return {
              component: 'rangePickerRenderer',
              params: colInfo,
            };
          case 'validSizes':
            return {
              component: 'validSizesRenderer',
              params: {
                dataConfig: {
                  url: colInfo.dataApi.url,
                  params: mapValues(colInfo.dataApi.params, (_v, k) => {
                    return row[k];
                  }),
                  headers: colInfo.dataApi.headers,
                },
              },
            };
          case 'validValuesRenderer':
            // FIXME: EAS-607, fix configs to be consistent so we only target dataApi and not configApi.
            const api = isNil(colInfo.configApi) ? colInfo.dataApi : colInfo.configApi;
            const dataConfig = !isNil(api) ? processApiParams(api, row) : null;
            return {
              component: 'validValuesRenderer',
              params: {
                dataConfig,
              },
            };
          case 'tooltipRenderer':
            return {
              component: 'tooltipRenderer',
            };
          case 'severityRender':
            return {
              component: 'severityRender',
            };
          case 'starPercentRenderer':
            return {
              component: 'starPercentRenderer',
            };
          case 'starEditor':
            return {
              component: 'starEditor',
            };
          case 'statusIconRenderer':
            return {
              component: 'statusIconRenderer',
            };
          default:
            return (null as unknown) as ComponentSelectorResult;
        }
      },
    };
  };

  generateCompanionViewData = (data: BasicPivotItem[]) => {
    const { identityField } = this.props;
    const companionSortField = this.state.companionSortField || '';
    const companionSortDirection = this.state.companionSortDirection;
    const removedGroupData = data.filter((dat) => {
      // Groups only have one attribute in the object: group and id
      return Object.keys(dat).length > 2;
    });

    const sortedData = simpleByField(removedGroupData, companionSortField, companionSortDirection);
    const compData = sortedData.map((d) => {
      const name = d[`member:${identityField}:name`] ? d[`member:${identityField}:name`] : d.name;
      const description = d[`member:${identityField}:description`]
        ? d[`member:${identityField}:description`]
        : d.description;
      return {
        id: name,
        name: description,
        'member:style:id': d['member:style:id'],
        'member:style:name': d['member:style:name'],
        'member:stylecolor:id': d['member:stylecolor:id'],
        'member:stylecolor:name': d['member:stylecolor:name'],
        stars: d['compositeattributeband'] ? parseInt(d['compositeattributeband'], 10) : 0,
        imageUri: d['attribute:img:id'],
      };
    });

    return compData;
  };

  renderCompanionView = (data: BasicPivotItem[]) => {
    const { identityField } = this.props;
    const { selectedIndex, companionScrollTo } = this.state;
    const compData = this.generateCompanionViewData(data);
    const sortSelection = this.props.companionSortOptions.findIndex((option) => {
      return option.dataIndex === this.state.companionSortField;
    });

    if (compData) {
      const companionProps: CompanionProps = {
        defaultSelection: sortSelection,
        sortOptions: this.props.companionSortOptions,
        label: 'Count',
        selectedIndex,
        className: companionStyles,
        data: compData,
        noImageUrl: noImage,
        scrollTo: companionScrollTo,
        initialSortDirection: this.state.companionSortDirection,
        isCollapsed: this.state.companionCollapsed,
        onListItemClicked: (identityValue: string) => {
          // TODO: make this logic reusable so it can be invoked during handlePendingCellUpdate if needed
          function findIndexComp(dataKey: string) {
            return compData.findIndex((datum) => {
              const fieldFound = datum[dataKey] ? datum[dataKey] : datum.name;
              return fieldFound === identityValue;
            });
          }
          let key = identityField === 'id' ? 'id' : `member:${identityField}:id`;
          let index = findIndexComp(key);

          key = identityField === 'id' ? 'name' : `member:${identityField}:name`;
          index = index === -1 ? findIndexComp(key) : index;

          const newState = {
            gridScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
          };
          this.setState(newState);
        },
        onChangeDirection: (direction) => {
          this.setState({
            companionSortDirection: direction,
          });
        },
        onSortSelection: (selection) => {
          this.setState({
            companionSortField: selection.dataIndex,
          });
        },
        onToggleCollapse: (isCollapsed) => {
          this.setState({ companionCollapsed: isCollapsed });
        },
      };
      return <CompanionListView {...companionProps} />;
    } else {
      return <div />;
    }
  };

  createGroupedColumns = (columnDefs: ConfigurableGridConfigItem[]) => {
    const groupedColDefs: (ColDef | ColGroupDef)[] = [];
    const colDefs = columnDefs.map(this.createColumnDef).map((col: any) => {
      col.suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        if (params.colDef.field && BLOCK_ENTER_EDITORS.includes(col.inputType)) {
          if (params.editing && POPOVER_BLOCK_CODES.includes(params.event.code)) {
            return true;
          }
        }
        return false;
      };
      return col;
    });

    const sizeColumnIndex = findIndex(colDefs, (def) => def.renderer === 'size_array');
    // TODO: how do I get sizing info from validSizes api in this screen
    const sizingColumnDefs = ['L', 'M', 'S', 'XL', 'XS', 'XXS', 'XS'].map((size, idx, arr) =>
      this.createSizeColDefs(size, idx, arr)
    );
    const finalColumnDefs =
      sizeColumnIndex >= 0
        ? concat(slice(colDefs, 0, sizeColumnIndex), sizingColumnDefs, slice(colDefs, sizeColumnIndex + 1))
        : colDefs;

    let groupTemp: ColDef[] = [];
    function isLastInGroup(defs: ConfigurableGridConfigItem[], start: number, key: string): boolean {
      const def = defs[start];
      if (!def) return true;
      if (def.hidden || def.visible === false) {
        return isLastInGroup(defs, start + 1, key);
      }
      if (def.groupingKey != key) {
        return true;
      }
      return false;
    }
    columnDefs.forEach((colDef, index) => {
      if (colDef.hidden || colDef.visible === false) {
        return;
      }
      if (!colDef.groupingKey) {
        // don't push heatmapRenderer columns into group since they are handled separately
        if (finalColumnDefs[index].cellRenderer != 'heatmapRenderer') {
          groupedColDefs.push(finalColumnDefs[index]);
        }
      } else {
        if (colDef.renderer && colDef.renderer === 'size_array') {
          // I believe this code path is defunct and has been replaced by 'size_array_configurable'
          // make sure all size columns are under this group
          groupedColDefs.push({
            headerName: colDef.groupingKey,
            children: finalColumnDefs.slice(index, sizingColumnDefs.length + 2),
            headerGroupComponent: 'customGroupHeader',
          });
          groupTemp = [];
        } else if (colDef.renderer && colDef.renderer === 'size_array_configurable') {
          // make sure all size columns are under this group
          groupedColDefs.push({
            headerName: colDef.text,
            children: colDef.columns!.map((size, idx, arr) => {
              return this.createSizeColDefs(
                size.id!,
                idx,
                arr.map((s) => s.id!),
                colDef.dataIndex
              );
            }),
            headerGroupComponent: 'customGroupHeader',
          });
          groupTemp = [];
        } else {
          groupTemp.push(finalColumnDefs[index]);

          if (isLastInGroup(columnDefs, index + 1, colDef.groupingKey)) {
            groupedColDefs.push({
              headerName: colDef.groupingKey,
              children: groupTemp.slice(0),
              headerGroupComponent: 'customGroupHeader',
            });
            groupTemp = [];
          }
        }
      }
    });
    return groupedColDefs;
  };

  createSizeColDefs = (size: string, index: number, sizes: string[], dataIndex = 'heatmap') => {
    return {
      field: size,
      colId: `sizeHeatMap_${size}`,
      headerName: size,
      width: 100,
      cellClass: 'size-heatmap-cell',
      cellRenderer: 'heatmapRenderer',
      cellRendererParams: {
        sizeArrayIndex: index,
        dataIndex,
        valueAsCssColor: dataIndex !== 'heatmap',
      },
      sizes,
    };
  };

  handleChangeGroupByDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { groupByDropdownProps, setGroupBySelection } = this.props;
    if (isNil(groupByDropdownProps)) {
      return;
    }

    const newValue = event.currentTarget.textContent;
    const valueIndex = findIndex(groupByDropdownProps.options, (option) => {
      return newValue !== null && option.text.substr(0, 16) === newValue.substr(0, 16);
    });

    setGroupBySelection({
      selectedIndex: valueIndex,
      option: groupByDropdownProps.options[valueIndex],
    });
  };

  isValidGroupBy = (groupByDropdown: SelectorSubheaderDropdownProps, selectedIndex: number) => {
    // check for selectionIndex in groupByDropdown.options first
    const selectedOption = groupByDropdown.options[selectedIndex];

    // selection index exceeds available groupBy options for current view
    if (isNil(selectedOption)) {
      return false;
    }

    // if an index is found, validate that it (option value) matches the actual groupBySelection.option value
    const foundItem =
      findIndex(groupByDropdown.options, (option) => option.text === this.props.groupBySelection?.option.text) !== -1;
    return foundItem;
  };

  getCorrectGroupByIndex = (groupByDropdown: SelectorSubheaderDropdownProps) => {
    const selectedOption = this.props.groupBySelection?.option;
    return findIndex(groupByDropdown.options, (option) => option.text === selectedOption?.text);
  };

  getGroupedData = (data: BasicPivotItem[]): AgFlatResult | undefined => {
    const { flowStatus, identityField, groupByDropdownProps, groupBySelection, search } = this.props;

    if (isNil(groupByDropdownProps)) {
      return;
    }

    let selectionIndex = groupBySelection ? groupBySelection.selectedIndex : -1;

    if (!this.isValidGroupBy(groupByDropdownProps, selectionIndex)) {
      // if not valid, reset to default selection or first item in groupBy
      // this was to fix groupBys set on a different view that is not available on the current view
      selectionIndex = groupByDropdownProps.defaultSelection ? groupByDropdownProps.defaultSelection : 0;
      console.info(
        `Invalid groupBy selection detected.\nResetting groupBy selection from previously selected ${groupByDropdownProps.selection} to ${selectionIndex}`
      );

      // set new selection in state so rest of component catches the change
      this.props.resetGroupBySelection();
    } else {
      // if valid, make sure correct index is selected for current options
      selectionIndex = this.getCorrectGroupByIndex(groupByDropdownProps);
    }

    const selectedGroupBy = groupByDropdownProps.options[selectionIndex];

    if (groupByDropdownProps && !isNil(groupByDropdownProps.selection) && selectedGroupBy.dataIndex && data) {
      const getFlat = memoize(() => flattenToLeaves(data));
      const groupByKey = selectedGroupBy.dataIndex;
      const result = groupedToAgFlatTree(
        generateOrderedGroups(getFlat(), groupByDropdownProps as SubheaderDropdownSlice),
        groupByKey,
        search,
        initialSortBy,
        this.props.showFlowStatus && flowStatus ? flowStatus : [],
        groupByDropdownProps.options,
        identityField === 'id' ? 'name' : `member:${identityField}:name`,
        true
      );

      return result;
    }

    return;
  };

  refreshGrid = () => {
    if (this.gridApi) {
      this.gridApi.redrawRows();
    }
  };

  handleChangeFloorsetDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { floorsetDropdownProps, setFloorsetSelection } = this.props;
    if (isNil(floorsetDropdownProps)) {
      return;
    }

    const newValue = event.currentTarget.textContent;
    const valueIndex = findIndex(floorsetDropdownProps.options, (option) => {
      return option.text === newValue;
    });
    const selection = floorsetDropdownProps.options[valueIndex];
    setFloorsetSelection(selection);
  };

  generatePublishedText = (data: BasicPivotItem[]) => {
    if (isNil(data)) {
      return [];
    }

    let publishedTotal = 0;
    let unpublishedTotal = 0;
    let partialTotal = 0;
    let noReceiptsTotal = 0;
    data.forEach((item: BasicPivotItem) => {
      if (Object.keys(item).length > 3) {
        // Groups show up in data. Makes it not count them
        const isPublished = item[IS_PUBLISHED];

        switch (isPublished) {
          case PUBLISHED_TEXT:
            publishedTotal += 1;
            break;
          case UNPUBLISHED_TEXT:
            unpublishedTotal += 1;
            break;
          case PARTIAL_PUBLISHED_TEXT:
            partialTotal += 1;
            break;
          default:
            // per CA, let the rest fall into no receipts
            noReceiptsTotal += 1;
            break;
        }
      }
    });

    return [
      `Published: ${publishedTotal}`,
      `Planned: ${unpublishedTotal}`,
      `Partial: ${partialTotal}`,
      `No Receipts: ${noReceiptsTotal}`,
    ];
  };

  getArrowFromValue = (value: number) => {
    // Math.sign return 1 for positive, 0 for 0, and -1 for negative
    switch (Math.sign(value)) {
      case 1:
      case 0:
        return ARROWDIRECTIONS.UP;
      case -1:
      default:
        return ARROWDIRECTIONS.DOWN;
    }
  };

  getActiveFloorset = (): string => {
    const { floorsetDropdownProps } = this.props;

    if (isNil(floorsetDropdownProps)) {
      return '';
    }

    const selection = floorsetDropdownProps.selection || 0;
    return floorsetDropdownProps.options[selection].dataIndex;
  };

  onSaveClick = () => {
    const { styleColorsEdited } = this.state;

    Axios.get(this.props.planningApi.url, {
      params: {
        ...this.props.planningApi.params,
        products: styleColorsEdited,
      },
    });
  };

  getRowNodeValues = (dataIndex: string, nodes: RowNode[]) => {
    const nodeValues = nodes.map((node) => {
      let value;

      if (node.data[dataIndex]) {
        value = node.data[dataIndex];
      } else {
        // try to strip down index to field only
        const fieldOnly = dataIndex.split(':')[1];
        value = node.data[fieldOnly] || 0;
      }

      return value;
    });

    return nodeValues;
  };

  groupRowAggNodes = (nodes: RowNode[]) => {
    if (nodes.length === 0) {
      return;
    }
    let colApi = this.columnApi;
    if (isNil(colApi)) {
      colApi = (nodes[0] as any).columnApi;
    }
    // get and store all aggregator or aggregatorFunction values from column configs
    const aggTypes = {};
    const aggResults = {};
    const columns = colApi.getAllColumns();

    columns.forEach((column) => {
      const colId: string = column.getColId();
      const configColumn = this.props.columnDefs.find((def) => {
        return def.dataIndex === colId;
      });

      if (!isNil(configColumn)) {
        const { dataIndex, aggregator, aggregatorFunction } = configColumn;

        // We have special logic for checkbox "aggregation" (through indeterminance)
        if (configColumn.renderer === 'checkbox') {
          aggTypes[dataIndex] = 'checkbox';
          return;
        }
        if (isNil(aggregator) && isNil(aggregatorFunction)) {
          return;
        }
        if (aggregator === 'eval') {
          aggTypes[dataIndex] = aggregatorFunction;
        } else {
          aggTypes[dataIndex] = aggregator || aggregatorFunction;
        }
      }
    });

    forEach(aggTypes, (value: string, key) => {
      // handle normal aggregations
      const nodeValues = this.getRowNodeValues(key, nodes);

      switch (value) {
        case 'sum':
        case 'min':
        case 'max': {
          const result = this.math[value](nodeValues);
          aggResults[key] = result;
          break;
        }
        case 'count': {
          const count = this.math.size(nodeValues);
          aggResults[key] = count;
          break;
        }
        case 'avg': {
          const avg = this.math.mean(nodeValues);
          aggResults[key] = avg;
          break;
        }
        case 'checkbox': {
          const allThere = every(nodeValues, (v) => (v ? true : false));
          const someThere = some(nodeValues, (v) => (v ? true : false));
          // For indeterminance, when only *some* are "selected", we set to the unknown "null"
          aggResults[key] = allThere ? true : someThere ? null : false;
          break;
        }
        default: {
          // handle custom aggregations
          const column = colApi.getColumn(key);
          const colId: string = column.getColId();
          const configColumn = this.props.columnDefs.find((def) => {
            return def.dataIndex === colId;
          });

          if (!isNil(configColumn) && !isNil(configColumn.aggregatorFunction)) {
            // parse and get expression dataIndices to calculate
            const parsedExpression = this.math.parse(configColumn.aggregatorFunction);
            const expressionNames = flow(
              () => parsedExpression.filter((node) => node.isSymbolNode && node.name !== 'AGG'),
              map((node) => node.name || '_')
            )();

            const aggregationHandler = partial(this.handleCustomAggregation, [
              nodes,
              parsedExpression,
              expressionNames,
            ]);
            aggResults[key] = aggregationHandler(value);
          }
        }
      }
    });

    return aggResults;
  };

  handleCustomAggregation = (
    nodes: RowNode[],
    parsedExpression: globalMath.MathNode,
    expressionNames: string[],
    _aggregatorFunction: string
  ) => {
    // get values for expression
    const exprValues = flow(
      () => expressionNames,
      reduce((acc, id = '') => {
        acc[id] = coalesce(this.getRowNodeValues(id, nodes), []);
        return acc;
      }, {})
    )();

    let result;
    try {
      result = parsedExpression.eval({ ...exprValues });
    } catch (error) {
      console.error('error calculating aggregation:', error);
    }

    if ((isNumber(result) && (isNaN(result) || !isFinite(result))) || isNil(result)) {
      result = 0;
    }

    return result;
  };

  getPostObject = (field: string, value: PostValue, data: BasicPivotItem, parentData: string[] = []) => {
    const { isStyleColorEdit } = this.props;
    // ccseason is a style attribute
    const id = isStyleColorEdit && field !== 'ccseason' ? data[STYLE_COLOR_ID] : data[STYLE_ID];

    if (isEmpty(parentData)) {
      // regular attribute

      let val = !isNil(value) ? value : '';
      if (val === true) {
        // these checks specificaly guard against a nil value being sent back as a zero-length string
        // and can instead be returned as the string 'true' or 'false'
        val = 'true';
      } else if (val === false) {
        val = '';
      }

      return {
        id,
        [field]: val,
      };
    }

    return {
      id,
      parent: parentData,
    };
  };

  getSelectedRows = (): PivotBasicItem[] => {
    if (this.gridApi == null) return [];
    const selectedNodes: RowNode[] = this.gridApi.getSelectedNodes();
    const floorsetId = this.getActiveFloorset();
    return selectedNodes
      .filter((n) => {
        return isNil(n.allChildrenCount) || n.allChildrenCount <= 0;
      })
      .map((n) => {
        const rowData = n.data;
        rowData.floorset = floorsetId;
        return rowData;
      });
  };

  getAssociatedConfigCol = (colDef: ColDef) => {
    const configedColumn = this.props.columnDefs.find((col) => {
      return col.dataIndex === colDef.field;
    });
    return configedColumn;
  };

  columnUsesGenericUpdate = (colDef: ColDef) => {
    return this.getAssociatedConfigCol(colDef)?.useMassEditUpdate === true;
  };

  submitMassColumnUpdate = async (params: MassColumnUpdateParams) => {
    let coordinates = [];
    const massEditConfig = this.props.massEditConfig;

    if (isNil(massEditConfig)) {
      logError(
        `Cannot update ${get(params, 'dataIndex', '')} without massedit configured in useMassEditUpdate mode.`,
        null
      );
      return;
    }

    coordinates = params.nodes.map((rowData) =>
      omitBy(
        mapValues(massEditConfig.coordinateMap, (v) => {
          const value = rowData[v];
          return value;
        }),
        isNil
      )
    );
    const pKey = params.dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    await ServiceContainer.pivotService.coarseEditSubmitData({
      coordinates,
      [pKey]: params.value,
    });
  };

  submitGenericMassUpdate = async ({ colDef, node }: CellValueChangedEvent) => {
    const configedColumn = this.getAssociatedConfigCol(colDef);
    if (this.props.massEditConfig == null || configedColumn == null) {
      logError(
        `Cannot update ${colDef.colId}. Somehow set to generic update without updateCoordinateMap property.`,
        null
      );
      return;
    }
    const coordMap = this.props.massEditConfig.coordinateMap;
    const field = configedColumn.dataIndex;
    const value = node.data[field];
    let calcValue = value;
    if (isBoolean(value)) {
      calcValue = value ? 'true' : '';
    }
    let coordVals: {
      [k: string]: string;
    }[];
    // If we are cascading, we handle that here. Otherwise, we fall back to assuming we only mess
    // we row as is.
    if (node.allChildrenCount && node.allChildrenCount > 0 && configedColumn.cascadeGroupSelection) {
      if (isNil(value)) return;
      const itemsToUpdate: RowNode[] = [];

      // update row node data without triggering cellValueChanged handler
      node.childrenAfterFilter.forEach((rowNode) => {
        if (!isNil(rowNode.allChildrenCount) && rowNode.allChildrenCount > 0) {
          // skip nodes that are group nodes.
          return;
        }
        rowNode.data[field] = value;
        itemsToUpdate.push(rowNode.data);
      });

      this.gridApi.updateRowData({ update: itemsToUpdate });
      // we need to redraw the rows as there's no state modifications causing a whole render loop.
      this.gridApi.redrawRows();
      // Generate a set of coordinates for each row (filter out nils for the set only having a subset of avail props)
      coordVals = itemsToUpdate.map((item) => {
        return omitBy(
          mapValues(coordMap, (v) => item[v]),
          isNil
        );
      });
    } else {
      coordVals = [
        omitBy(
          mapValues(coordMap, (v) => node.data[v]),
          isNil
        ),
      ];
    }

    const pKey = configedColumn.dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    const payload = {
      coordinates: coordVals,
      [pKey]: calcValue,
    };
    await ServiceContainer.pivotService.coarseEditSubmitData(payload);
  };

  submitGenericUpdate = async ({ colDef, value, node }: CellValueChangedEvent) => {
    const configedColumn = this.getAssociatedConfigCol(colDef);
    if (this.props.updateCoordinateMap == null || configedColumn == null) {
      logError(`Cannot update ${colDef.colId} without massedit configured in useMassEditUpdate mode.`, null);
      return;
    }
    // This fun mess removes that silly wrapped colon stuff (eg: attribute:<x>:id)
    // This is the more concise version: .replace(/(?:^.*?:)?([^:]*)(?::.*)?/, '$1')
    const key = configedColumn.dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    const coordMap = this.props.updateCoordinateMap;
    const coordVals = omitBy(
      mapValues(coordMap, (v) => node.data[v]),
      isNil
    );
    const payload: GranularEditPayloadItem = {
      coordinates: coordVals,
    };
    // These being top level is...a bit annoying.
    if (configedColumn.dataIndex.indexOf('member:subclass') >= 0) {
      // we need to do it this way because the config is stupid :/
      // there is an attribute:subclass which is **different** than member:subclass -,-
      payload.parent = value;
    } else {
      let calcValue = value;
      if (isBoolean(value)) {
        calcValue = value ? 'true' : '';
      }
      payload[key] = calcValue;
    }
    await ServiceContainer.pivotService.granularEditSubmitData([payload]);
    // await fetch new value set & merge
  };

  render() {
    const {
      title,
      identityField,
      showFlowStatus,
      massEditConfig,
      configuratorViewDefn,
      unmodifiedViewDefn,
      defaultCompanionSortField,
      groupByDropdownProps,
      floorsetDropdownProps,
      topAttributesData,
    } = this.props;
    const { gridScrollTo } = this.state;
    let data = this.props.data;

    const viewConfigurator = this.onUpdateConfig &&
      configuratorViewDefn &&
      unmodifiedViewDefn && {
        viewConfig: configuratorViewDefn,
        unmodifiedViewDefn: unmodifiedViewDefn,
        updateConfig: this.onUpdateConfig,
        isGrid: true,
        companionData: {
          companionSortDirection: this.state.companionSortDirection,
          companionCollapsed: this.state.companionCollapsed,
          companionSortField: this.state.companionSortField,
        },
        defaultCompanionData: {
          companionSortDirection: 'desc' as const,
          companionCollapsed: false,
          companionSortField: defaultCompanionSortField,
        },
      };

    let treeColumnDefinition = this.state.treeColumnDefinition;

    // inject event handlers into dropdown props
    const extraDropdowns: SubheaderDropdownProps[] =
      !isNil(groupByDropdownProps) && !isNil(floorsetDropdownProps)
        ? [
            { ...groupByDropdownProps, handleChangeOnDropdown: this.handleChangeGroupByDropdown },
            { ...floorsetDropdownProps, handleChangeOnDropdown: this.handleChangeFloorsetDropdown },
          ]
        : [];

    const subheaderProps: SubheaderProps = {
      title: title || '',
      showFlowStatus,
      summary: '',
      showSearch: true,
      extraDropdowns,
      downloadLink: this.props.subheader?.downloadLink,
      errorCondition: this.props.subheaderErrorText,
      viewConfigurator,
      favoritesSaveOverride: {
        groupBySelection: groupByDropdownProps?.selection as number | undefined,
      },
    };

    if (data && data[0]) {
      data[0].floorset = this.getActiveFloorset();
    }

    const sortedData = this.getGroupedData(data);
    if (sortedData) {
      data = sortedData.agFlatTree;
      treeColumnDefinition = {
        ...sortedData.treeColumnDefinition,
        valueFormatter: (params: ValueFormatterParams) => {
          // if params.data exists, this formats a normal cell
          // otherwise, this formats the values that show up when you filter/funnel
          if (params.data) {
            if (isArray(params.value) && params.value.length === 1) {
              return last(params.value);
            } else {
              // Idea here is the base value is likely already rendered in another column.
              return '_';
            }
          } else {
            return params.value;
          }
        },

        filterValueGetter: (params: ValueGetterParams) => {
          return isArray(params.data.group) ? params.data.group[0] : params.data.group;
        },
      };
    }

    const groupedColumnDefs = this.props.columnDefs ? this.createGroupedColumns(this.props.columnDefs) : [];
    // Added to prevent valueGetters from other tree col defs to be added (intereferes with valueFormatter)
    if (treeColumnDefinition && treeColumnDefinition.valueFormatter) {
      treeColumnDefinition.valueGetter = undefined;
    }

    if (massEditConfig != null) {
      if (treeColumnDefinition != null) {
        treeColumnDefinition.checkboxSelection = true;
      } else if (groupedColumnDefs.length > 0) {
        groupedColumnDefs.unshift({
          checkboxSelection: true,
          headerName: '',
          width: 40,
          pinned: true,
        });
      }
    }
    const gridOptions: DataGridProps = {
      data,
      isPrintMode: false,
      columnDefs: groupedColumnDefs,
      treeColumnDefinition: treeColumnDefinition,
      className: gridListPairStyle,
      loaded: true,
      scrollTo: gridScrollTo,
      singleClickEdit: true,
      rowHeight: this.props.gridRowHeight,
      onGridReady: (params: GridReadyEvent) => {
        if (params.api) {
          this.gridApi = params.api;
        }

        if (params.columnApi) {
          this.columnApi = params.columnApi;
        }
        this.setState({ gridRdyCnt: this.state.gridRdyCnt + 1 }); // force a re-render to attach events to the grid
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event && event.data && !event.data.$$GroupHeader) {
          const key = identityField === 'id' ? 'stylecolor' : `member:${identityField}:id`;
          const identityValue = event.data[key];
          const companionData = this.generateCompanionViewData(this.props.data);

          const index = companionData
            ? companionData.findIndex((datum: ListViewable) => {
                const fieldFound = datum[key] ? datum[key] : datum.name;
                return fieldFound === identityValue;
              })
            : event.data[key];

          this.setState({
            companionScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
            activeStyleColor: event.data['stylecolor'],
          });
        }
      },
      onCellEditingStarted: (event: CellEditingStartedEvent) => {
        const popupEditor = document.querySelector('.ag-popup-editor') as HTMLElement;
        if (popupEditor) {
          // Lock scrolling
          const stopOverflowArr = [
            document.querySelector('.ag-center-cols-viewport') as HTMLElement,
            document.querySelector('.ag-body-viewport') as HTMLElement,
          ];

          stopOverflowArr.forEach((html) => {
            html.style.overflow = 'hidden';
          });
        }

        // TODO: store invalid dataIndices in state for quick access within method, can loop and clear each one
        // clear invalid data here so invalid styles are cleared
        resetAsyncValidationData(event);
      },
      extraAgGridProps: {
        loadingOverlayComponent: 'customNoRowsOverlay',
        getRowNodeId: (rowData: BasicItem) => rowData.id,
        groupRowAggNodes: this.groupRowAggNodes,
        suppressColumnVirtualisation: true, // styling gets broken when this is on
        onCellValueChanged: async (params: CellValueChangedEvent) => {
          const { colDef, data: eventData, node, api } = params;
          let { value } = params;
          // Unlock scrolling
          const stopOverflowArr = [
            document.querySelector('.ag-center-cols-viewport') as HTMLElement,
            document.querySelector('.ag-body-viewport') as HTMLElement,
          ];
          stopOverflowArr.forEach((html) => {
            html.style.overflow = '';
          });

          // NOTE: it's going to get a bit worse before it gets better here. I'm sorry.
          // This *should* completely replace all that chiz below once we have the single target
          // endpoint and the willpower. - Mark :/
          if (this.columnUsesGenericUpdate(colDef)) {
            this.submitGenericMassUpdate(params);
            return;
          }

          const { columnDefs, clientActionHandlers, dependentCalcs } = this.props;
          const { activeStyleColor } = this.state;
          const field = colDef.field!;
          const fieldConfig = columnDefs.find((item) => item.dataIndex === field);
          const editorsToSkip = ['lifecycleParameters', 'salesAdjustment', 'receiptsAdjCalculator'];
          const observers = this.observers[field];

          // Support header cascading updates
          // TODO: Swap this out for new "coarse" or "granular" update mechanism
          if (node.allChildrenCount && node.allChildrenCount > 0 && fieldConfig && fieldConfig.cascadeGroupSelection) {
            if (isNil(value)) return;
            const itemsToUpdate: any[] = [],
              upserts: any[] = [];
            forEach(node.childrenAfterGroup, (childNode: RowNode) => {
              const data = childNode.data;
              data[field] = value;
              itemsToUpdate.push(data);
              const trimmedField = field.indexOf(':') != -1 ? field.split(':')[1] : field;
              upserts.push({
                product: childNode.id,
                [trimmedField]: value,
                location: data['channel'],
              });
            });

            api?.updateRowData({ update: itemsToUpdate });
            await Axios.post<undefined, any>('api/attribute/upsertAll', upserts, {
              params: {
                appName: 'Assortment',
              },
            });

            api?.redrawRows();
            return;
          }

          // FIXME: This is once again a "worse before it gets better" sort of deal
          // We are only using new endpoint where config is set.
          if (this.props.updateCoordinateMap != null) {
            // FIXME: I don't handle observers. I'll...get to it.
            this.submitGenericUpdate(params);
            return;
          }
          // Support posting lifecycle attributes from the grid
          if (fieldConfig && fieldConfig.dataApiLifecycle && !fieldConfig.lifecycleConfig) {
            if (isNil(value)) return;
            const trimmedField = field.indexOf(':') != -1 ? field.split(':')[1] : field;
            const finalData = {
              product: node.id,
              attributes: {
                [trimmedField]: value,
              },
            };

            Axios.post(fieldConfig.dataApiLifecycle.url, finalData, {
              params: {
                appName: 'Assortment',
              },
            });
            return;
          }

          if (fieldConfig && updateWithClientHandler(field, clientActionHandlers)) {
            const strippedField = field
              .replace('attribute:', '')
              .replace(':id', '')
              .replace(':name', '');
            updateLifecycleParams(activeStyleColor, { [strippedField]: value });
            // at some point may need to allow observed logic to run before returning but for now it's okay
            return;
          } else if (fieldConfig && editorsToSkip.indexOf(fieldConfig.inputType as string) >= 0) {
            return; // don't save edits from these columns
          } else if (fieldConfig && fieldConfig.valueType === 'number') {
            value = parseFloat(value);
          } else if (
            fieldConfig &&
            fieldConfig.inputType === 'textValidatorAsync' &&
            value === PENDING_VALIDATION_VALUE
          ) {
            return; // skip posting unvalidated values
          } else if (fieldConfig && fieldConfig.inputType === 'integer') {
            value = String(value); // server expects a string value
          }

          // using config value to determine if posting style or stylecolor attribute update
          const strippedField = replaceExtraProps(field);
          const postObject = this.getPostObject(strippedField, value, eventData);

          // format array types to string lists where necessary, don't want to format asCsv data here
          if (!isNil(fieldConfig) && fieldConfig.postArrayAsString) {
            const arrayAsString = postObject[strippedField].join(',');
            postObject[strippedField] = arrayAsString;
          }
          const scopedData = { ...eventData };

          // send hierarchy update to complete process
          if (field.indexOf('member:') >= 0) {
            const hierarchyData = Object.keys(scopedData)
              .filter((key) => key.match(/member:.*:id/) != null && [STYLE_ID, STYLE_COLOR_ID].indexOf(key) < 0)
              .map((key) => scopedData[key]);
            const hierarchyPostObj = this.getPostObject('', '', scopedData, hierarchyData);
            updateStyleItem(hierarchyPostObj);
          }

          // Look for dependentCalcs
          const calcKeys = Object.keys(dependentCalcs);
          if (calcKeys.length > 0 && scopedData) {
            calcKeys.forEach((key) => {
              let updateCalculation = false;
              const calcObj = dependentCalcs[key];
              const params = calcObj.params;
              if (params) {
                for (const p in params) {
                  if (params[p] === field || params[p] === field.replace(':id', ':name')) {
                    updateCalculation = true;
                  }
                }
              }
              if (updateCalculation) {
                const getDataFromKey = (key: string) => {
                  return {
                    rowNodeFound: true,
                    data: scopedData[key],
                  };
                };
                const newValue = executeCalculation(this.math, calcObj, getDataFromKey);
                // update postObject
                postObject[strippedField] = newValue;
              }
            });
          }

          updateStyleItem(postObject);

          // update observed and dependent props, then post hierarchy data as well
          // FIXME: We're handling a lot of setter logic in here that's probably duplicated in the separate
          // setValue. We're also doing some...special...things w/r/t how different endpoints schema for
          // the dropdowns are setup.
          if (!isNil(this.gridApi) && isObservedProp(this.observers, field)) {
            // update observed prop first
            observers.forEach(async (observer) => {
              const processedDataApi = processApiParams(observer.dataApi, scopedData);
              const dataUrl = getUrl(processedDataApi);
              const resp = await Axios.get(dataUrl);
              let respData = resp.data && resp.data.data ? resp.data.data : null;
              if (!isNil(respData) && !isArray(respData)) {
                respData = getDependentsFromResp(respData);
              }
              const rowNode = this.gridApi.getRowNode(scopedData.id);

              if (isNil(rowNode)) {
                return;
              }

              const memberMatched = observer.dataIndex.match(/member:([a-z]*):[a-z]*/);
              const isMemberUpdate = !isNil(memberMatched);
              let updatedValue = isMemberUpdate ? { label: '', value: '' } : '';

              if (!isNil(respData) && !isEmpty(respData[0])) {
                // Get current value of field
                const current = rowNode.data[observer.dataIndex];
                const result = respData.filter((option: { name: any }) => option.name === current);

                // Only update shown value if data is not in respData
                if (!respData.includes(current) && result.length <= 0) {
                  // select first item as selection if options are available
                  updatedValue = isMemberUpdate
                    ? {
                        label: respData[0].name,
                        value: respData[0].id,
                      }
                    : respData[0]; // assuming others always use dependent endpoint here...
                }
                // Otherwise, keep current value
                else {
                  if (result.length > 0) {
                    updatedValue = {
                      label: result[0].name,
                      value: result[0].id,
                    };
                  } else {
                    updatedValue = current;
                  }
                }
              }

              // setting the observer prop's new value will retrigger onCellValueChanged which will handle posting
              rowNode.setDataValue(observer.dataIndex, updatedValue);
              this.gridApi.refreshCells({
                rowNodes: [rowNode],
              });
            });
          }
        },
        onCellEditingStopped: () => {
          // TODO: replace this in all instances with suppressScrollWhenPopupsAreOpen
          // annd let the popup editors controll scroll suppression
          // Unlock scrolling
          const stopOverflowArr = [
            document.querySelector('.ag-center-cols-viewport') as HTMLElement,
            document.querySelector('.ag-body-viewport') as HTMLElement,
          ];
          stopOverflowArr.forEach((html) => {
            html.style.overflow = '';
          });
        },
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        onRowSelected: (event) => {
          const { node } = event;
          if (node.childrenAfterFilter != null && node.childrenAfterFilter.length > 0) {
            node.childrenAfterFilter.forEach((childNode) => {
              childNode.setSelected(node.isSelected());
            });
          }
          // TODO: How does one do this without retriggering :/
          // if (node.parent && node.parent.id !== 'ROOT_NODE_ID') {
          //   let selectionStatus = map((cNode: RowNode) => cNode.isSelected(), node.parent.childrenAfterFilter);
          //   if (every(selectionStatus)) {
          //     node.parent.setSelected(true);
          //   } else {
          //     node.parent.setSelected(false);
          //   }
          // }
        },
      },
    };

    const testArrowValue = (num: number) => {
      if (num > 100000 || num < -100000) {
        return '~';
      }
      if (!isNumber(num)) {
        return '';
      }
      return num;
    };

    let arrowValueGroup: ArrowValueRendererProps[] = [];
    if (topAttributesData && configuratorViewDefn && configuratorViewDefn.topAttributes) {
      arrowValueGroup = (configuratorViewDefn.topAttributes as TopAttributesConfig[]).map((config) => {
        let value = testArrowValue(topAttributesData[config.dataIndex]);
        const renderer = Renderer[config.renderer];
        value = renderer ? Renderer[config.renderer](value) : value;
        return {
          header: config.text,
          value: value.toString(),
          arrowDirection: this.getArrowFromValue(topAttributesData[config.dataIndex]),
        };
      });
    }

    const showPublishText = isNil(this.props.showPublishText) ? true : this.props.showPublishText;
    const extraRow = (
      <div style={{ display: 'flex' }}>
        {showPublishText && (
          <div className={styles.publishedContainer}>
            {this.generatePublishedText(data).map((text: string) => {
              return <span key={text}>{text}</span>;
            })}
          </div>
        )}
        <div className={styles.arrowGroup}>
          {arrowValueGroup.map((config) => {
            return <ArrowValueRenderer {...config} key={config.header} />;
          })}
        </div>
      </div>
    );

    const content = (
      <div className={extraRowContainerStyles}>
        {this.props.showExtraRow ? extraRow : undefined}
        <div className={gridContainerStyle}>
          <ExtendedDataGrid
            {...gridOptions}
            frameworkComponents={frameworkComponents}
            nonFrameworkComponents={nonFrameworkComponents}
          />
        </div>
      </div>
    );
    return (
      <div className={listPairStyle}>
        <Overlay type="loading" visible={!this.props.loaded} />
        <Subheader {...subheaderProps} />
        <div className="data-container">
          {massEditConfig != null ? (
            <MassEdit
              config={massEditConfig}
              title={massEditConfig.title}
              handleSubmit={this.props.onRefreshConfigurableGridData}
              getSelectedItems={() => this.getSelectedRows()}
              gridApi={this.gridApi}
              dataLoading={this.state.gridRdyCnt === 0}
            />
          ) : null}
          {this.props.hideCompanion === true ? null : this.renderCompanionView(data)}
          {this.props.loaded ? content : null}
        </div>
      </div>
    );
  }
}
