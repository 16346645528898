import { BasicChildren, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { Indexable } from 'src/types/Primitive';
import { noCaseExactMatch } from '../Primitive/String';

type ColumnConfig = {
  key: string;
  name: string;
  formatter?: string;
  editable?: boolean;
};

export type TreeRowData = {
  path: string[];
  id: string;
  name: string;
  [s: string]: unknown;
};

type GenerateOutputObjArgs = {
  child: BasicPivotItem;
  search: string;
  curPath: string[];
  metrics: ColumnConfig[];
  returnObj: any;
};

export function containsSearchOvertime(indexable: Indexable, search: string, keys: string[]) {
  if (search === '' || keys.length === 0) {
    return true;
  }
  for (const key of keys) {
    const searchee = indexable[key];

    if (searchee === undefined) {
      continue;
    }

    const searchInputs: string[] = [];
    if (search.indexOf(';') >= 0) {
      search.split(';').forEach((searchInput) => {
        if (searchInput.trim() !== '') searchInputs.push(searchInput.trim());
      });
      return searchInputs.some((input) => noCaseExactMatch(searchee, input));
    } else if (search.indexOf('&') >= 0) {
      search.split('&').forEach((searchInput) => {
        if (searchInput.trim() !== '') searchInputs.push(searchInput.trim());
      });
      return searchInputs.every((input) => noCaseExactMatch(searchee, input));
    } else if (noCaseExactMatch(searchee, search)) {
      return true;
    }
  }
  return false;
}

function generateOutputObj(args: GenerateOutputObjArgs): boolean {
  const childId = args.child.name || '<EMPTY>';
  let searchInChild = false;
  if (args.child.children != null && args.child.children.length > 0) {
    args.child.children.forEach((grandchild) => {
      const childAdded = generateOutputObj({
        ...args,
        child: grandchild,
        curPath: args.curPath.concat(childId),
      });
      searchInChild = searchInChild || childAdded;
    });
  }
  if (searchInChild || containsSearchOvertime(args.child, args.search, ['name', 'description'])) {
    args.metrics.forEach((metric) => {
      const path = args.curPath.concat([childId, metric.key]);
      args.returnObj[path.join('_')] = {
        path: path,
        id: metric.key,
        name: metric.name,
        formatter: metric.formatter,
        editable: metric.editable,
      };
    });
    return true;
  } else {
    return false;
  }
}

function addTimeDataToOutput(
  timeLevelTree: BasicChildren,
  curPath: string[],
  timeLevel: string,
  metrics: ColumnConfig[],
  returnObj: any
) {
  const childId = timeLevelTree.name || '<EMPTY>';
  let cancelled = false;
  metrics.every((metric) => {
    const path = curPath.concat([childId, metric.key]);
    if (returnObj[path.join('_')] == null) {
      cancelled = true;
      return false;
    } else {
      returnObj[path.join('_')][timeLevel] = timeLevelTree[metric.key];
      return true;
    }
  });
  if (!cancelled && timeLevelTree.children != null && timeLevelTree.children.length > 0) {
    timeLevelTree.children.forEach((grandchild) => {
      addTimeDataToOutput(grandchild, curPath.concat(childId), timeLevel, metrics, returnObj);
    });
  }
}

/**
 * This function handles returning the inverted pivot data, showing a configured time level across the columns
 * and metrics as the rows.
 *
 * The timeTree is aggregated by the pre-configured timeLevel(s) along with the user selected aggBys.
 *
 * @param timeTree contains the tree of data aggregated by time -> level [-> level]
 * @param aggBys selected aggBys
 * @param metrics the configuration for the grid metrics
 * @param metricsTimeLevel the tree level at which we want to retrieve metric values
 * @param search
 * @returns
 */
export function parseOverTimeTree(
  timeTree: BasicPivotItem[],
  aggBys: string[],
  metrics: ColumnConfig[],
  metricsTimeLevel: string,
  search = ''
): TreeRowData[] {
  // this is a placeholder object that contains the row structure with the key being the group path
  // and the values being the configured metrics for that group
  const returnObj: { [path: string]: TreeRowData } = {};

  // populate the row metric values for each group path in returnObj,
  // all time periods in timeTree follow this same output so only need to look at the first item
  timeTree[0].children.forEach((child) => {
    generateOutputObj({ child, search, curPath: [], metrics, returnObj });
  });

  // filling metric values for each column within each row
  timeTree.forEach((timeLevelTree) => {
    timeLevelTree.children.forEach((child) => {
      addTimeDataToOutput(child, [], child[metricsTimeLevel], metrics, returnObj);
    });
  });
  const sorted = Object.values(returnObj).sort((a, b) => a.path.length - b.path.length);
  return sorted;
}
