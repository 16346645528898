import { connect, Dispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { SelectionOverride, FilterSelection } from 'src/common-ui/components/Filters/Filters';

import serviceContainer from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { FilterGroup } from 'src/types/Scope';
import { computeFilterSelection } from 'src/utils/Filter/Filters';
import FilterPanel from './FilterPanel';

import {
  updateSelectionOverrides,
  flushSelectionOverridesStarted,
  receiveFilterState,
  makeUpdateLastSelection,
  receiveFilterStateAfterSelection,
  isLoading,
  rollbackSelectionOverrides,
  receiveFilterStateAfterSubmission,
} from './FilterPanel.slice';
import { Action as ReduxAction } from 'redux';
import { getSectionContext } from 'src/utils/Domain/Perspective';

export type FilterSelections = {
  id: string;
  filterDefnId: string;
};

export type ReduxSlice = {
  isFilterSensitive?: boolean;
  updatedAt?: number;
  state: FilterGroup[];
  isFlushing: boolean;
  isLoading: boolean;
  selectionOverrides: SelectionOverride[];
  lastSelections: FilterSelection[];
  lastPostedSelections: FilterSelection[];
  containsInvalid: boolean;
  selectionsChanged: boolean;
  lastOverridedSection: string;
};

export type StoreProps = ReduxSlice;
export type Props = DispatchProps & StoreProps;

export function submitFilterSelections() {
  return (dispatch: Dispatch<ReduxAction>, getState: () => AppState) => {
    const state = getState();
    const storeProps = state.filters;
    const perspective = state.perspective.selected!;
    const appName = perspective.value;
    const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
    const section = getSectionContext();
    if (!isEqual(filterSelections, storeProps.lastSelections)) {
      dispatch(flushSelectionOverridesStarted());
      serviceContainer.filterClient
        .setFilterSelections(filterSelections, {
          appName,
          section,
        })
        .then(() => {
          serviceContainer.filterClient
            .getFullFilters({
              appName,
              section,
            })
            .then((refreshResult) => {
              if (refreshResult) {
                dispatch(
                  receiveFilterStateAfterSubmission({
                    filters: refreshResult.filters,
                    updatedAt: Date.now(),
                  })
                );
                dispatch(makeUpdateLastSelection(filterSelections));
              }
            });
        });
    }
  };
}
export function getAvailableFilterSelections(sectionId: string) {
  return (dispatch: Dispatch<ReduxAction>, getState: () => AppState) => {
    const state = getState();
    const storeProps = state.filters;
    const perspective = state.perspective.selected!;
    const appName = perspective.value;
    const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
    const section = getSectionContext();
    dispatch(isLoading(true));
    serviceContainer.filterClient
      .getAvailableFilterSelections({
        appName,
        section,
        filterSelections,
        attributeId: sectionId,
      })
      .then((refreshResult) => {
        if (refreshResult) {
          dispatch(
            receiveFilterStateAfterSelection({
              filters: refreshResult.filters,
              postedSelections: filterSelections,
            })
          );
        }
      });
  };
}
export function getFilters() {
  return (dispatch: Dispatch<ReduxAction>, getState: () => AppState) => {
    const state = getState();
    const perspective = state.perspective.selected!;
    const appName = perspective.value;
    const section = getSectionContext();

    dispatch(flushSelectionOverridesStarted());
    dispatch(isLoading(true));
    serviceContainer.filterClient
      .getFullFilters({
        appName,
        section,
      })
      .then((result) => {
        if (result) {
          dispatch(receiveFilterState({ filters: result.filters, updatedAt: Date.now() }));
        }
      });
  };
}

export function getFilterSelections() {
  return (dispatch: Dispatch<ReduxAction>, getState: () => AppState) => {
    const state = getState();
    const perspective = state.perspective.selected!;
    const appName = perspective.value;
    serviceContainer.filterClient.getOnlyFilterSelections(appName).then((selections) => {
      dispatch(rollbackSelectionOverrides(selections));
    });
  };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    updateSelectionOverrides(overrides: SelectionOverride[]) {
      dispatch(updateSelectionOverrides(overrides));
    },
    submitSelectionOverrides() {
      dispatch(submitFilterSelections());
    },
    getAvailableFilterSelections(sectionId: string) {
      dispatch(getAvailableFilterSelections(sectionId));
    },
  };
};

const mapStateToProps = (state: AppState) => {
  const storeProps = state.filters;
  const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
  return {
    ...storeProps,
    selectionsChanged: !isEqual(filterSelections, storeProps.lastSelections),
  };
};

export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
