import { style } from 'typestyle';
import { viewWidth, rem, px } from 'csx';

const sectionPanel = style({
  display: 'flex',
  alignItems: 'center',
  $nest: {
    '& .panel-icon': {
      minWidth: px(20),
      marginRight: rem(1.5),
    },
  },
});

const sectionParameter = style({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: viewWidth(50),
  marginLeft: rem(6),
  marginTop: rem(0.5),
  marginBottom: rem(0.5),
  $nest: {
    '& .text-container': {
      display: 'flex',
      alignItems: 'baseline',
    },
    '& .text': {
      marginRight: rem(1),
    },
  },
});

export default {
  sectionPanel,
  sectionParameter,
};
