import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { projectState, StateSelection } from 'src/components/MacroGridPair/MacroGridPair.selectors';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ProductDetailsOwnProps } from './ProductDetails.container';

function selectState(state: AppState, ownProps: ProductDetailsOwnProps): StateSelection {
  const { title = 'Product Details' } = ownProps;
  const { productDetails: viewState } = state.pages.hindsighting.aggregateViews;
  const { macroDataState, gridDataState, viewDefns, isConfigLoading } = viewState;
  const macroData = getUniqueDataFromCache(viewState, HashType.macro)?.tree || [];
  const gridData = getUniqueDataFromCache(viewState, HashType.grid)?.tree || [];
  const loaded = !viewState.isConfigLoading && isDataLoaded(macroDataState) && isDataLoaded(gridDataState);

  return {
    title,
    loaded,
    subheaderState: state.subheader,
    macroData,
    gridData,
    viewDefns,
    isConfigLoading,
    viewDataState: [macroDataState, gridDataState],
  };
}

export const selectData = createSelector(selectState, projectState);
