import React from 'react';
import * as AgGrid from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  CellClassParams,
  ValueSetterParams,
  ValueParserParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { AxiosPromise } from 'axios';
import Axios from 'src/services/axios';
import { mapValues, isArray, isEqual, size, merge, isEmpty, get as getWithDefault } from 'lodash';
import { find, get, isNil, map, partial, set, without, toLower } from 'lodash/fp';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import {
  ColorHeaderParams,
  ColorHeaderRenderer,
  SwatchSelector,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ColorHeaderRenderer';
import { default as NonFrameWorkToS5Renderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/NonFrameWorkToS5Renderer';
import { RangePickerRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RangePickerRenderer';
import { default as RangePickerEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/RangePickerEditor.container';
import { ImageCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ImageCellRenderer';
import { IconCellRenderer } from './Renderers/IconCellRenderer';
import TooltipRenderer from './Renderers/TooltipRenderer';
import { PopoverSelect } from './Editors/PopoverSelect';
import { classes, style } from 'typestyle';
import { toast } from 'react-toastify';
import handleViewport from 'react-in-viewport';

import * as StyleEditSectionStyles from './StyleEditSection.styles';
import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import { ValidSizesRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidSizesRenderer';
import CheckboxCellRenderer from './Renderers/Checkbox';
import { processApiParams, getUrl, getClientHandler, getViewProm } from '../StyleEdit.utils';
import StyleEditAddColorButton from 'src/components/AddColorButton/AddColorButton';
import { Dialog, DialogTitle, DialogContent, Icon } from '@material-ui/core';
import { getAllColors } from '../../AssortmentAdd/Assortment.client';
import { updateLifecycleParams, removeStyleColor, getExistingColorsNotInAsst } from './StyleEditSection.client';
import { updateStyleItem } from '../StyleEdit.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import Renderer from 'src/utils/Domain/Renderer';
import {
  ADJUSTED_APS,
  CC_COLOR,
  SPECIAL_STORE_GROUP,
  RANGE_CODE,
  VALID_SIZES,
  LOCKED_AFTER_COLOR_SUBMIT,
  COLOR_SUBMITTED_ATTR,
  FUNDED,
} from 'src/utils/Domain/Constants';
import ValidValuesRenderer from './Renderers/ValidValuesRenderer';
import ModalLinkRenderer from './Renderers/ModalLinkRenderer';

import { default as ReactSelect } from 'react-select';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { AssortmentClient } from '../../Assortment.client';
import { getRangeLists } from 'src/dao/scopeClient';
import IntegerEditor from './Editors/IntegerEditor';
import * as globalMath from 'mathjs';
import { executeCalculation, importDateFunctions, getVarsFromCalcString } from 'src/utils/LibraryUtils/MathUtils';
import { noop } from 'react-select/lib/utils';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import {
  TextValidationEditor,
  TVE_InputCharacterWhitelist,
  PENDING_VALIDATION_VALUE,
  PendingCellInfo,
} from './Editors/TextValidationEditor';
import {
  DependentData,
  StyleEditSectionState,
  StyleEditSectionProps,
  GridTitleProps,
  NotInLifecycleRendererProps,
  StyleEditConfigColumn,
  ColorHeaderConfigColumn,
  MultiRangeEditors,
  AssortmentRulesResponse,
  TransposedColDef,
} from './StyleEditSection.types';
import ClearValueCellRenderer from './Renderers/ClearValue';
import {
  ExcelExportParams,
  ProcessCellForExportParams,
  ExcelCell,
  CsvExportParams,
  GridApi,
  ColumnApi,
} from 'ag-grid-community';
import { StyleEditSectionHeaderGrid } from './StyleEditSectionHeaderGrid';
import { setGridRowHeights } from './StyleEditSection.utils';
import { AsyncValidationErrorMessage } from 'src/components/ConfigurableGrid/ConfigurableGrid';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import LocalPivotServiceContext from 'src/components/StylePreview/PivotServiceContext';
import ServiceContainer from 'src/ServiceContainer';
import cuid from 'cuid';
import { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';

const NOT_FOUND = '__DATA__NOT__FOUND__';

function getDataFromKey(params: ValueGetterParams, key: string) {
  const returnObj = {
    rowNodeFound: false,
    data: undefined,
  };

  if (!isNil(params.api) && !isNil(params.api.getRowNode(key))) {
    const rowNode = params.api.getRowNode(key);
    returnObj.rowNodeFound = true;
    returnObj.data = params.api.getValue(params.colDef.field!, rowNode);
  }
  return returnObj;
}

const StyleEditGridTitle = (props: GridTitleProps) => {
  const { title } = props;

  return (
    <div className={StyleEditSectionStyles.sectionGridTitle}>
      <div className={'leftTitleContainer'}>
        <span>{title}</span>
      </div>
    </div>
  );
};

const NotInLifecycleRenderer = (props: NotInLifecycleRendererProps) => {
  return <div className={StyleEditSectionStyles.notInLifecycle}>{props.text}</div>;
};

type ObserverObject = {
  dataApi: ViewApiConfig;
};
export class StyleEditSection extends React.Component<StyleEditSectionProps, StyleEditSectionState> {
  static contextType = LocalPivotServiceContext;
  context!: React.ContextType<typeof LocalPivotServiceContext>;
  private _isMounted: boolean; // FIXME: is this necessary?
  savedCalcData: Record<string, any> = {};
  nonFrameworkComponents: any;
  frameworkComponents: any;
  dataQueue!: AxiosPromise<any>[];
  // gridApi, columnApi and headerApi need to initialize as undef
  // because in some rare cases the grid will call functions with api = null
  // and we need to guard against those cases crashing the view
  gridApi: AgGrid.GridApi | undefined = undefined;
  columnApi: AgGrid.ColumnApi | undefined = undefined;
  headerGridApi: AgGrid.GridApi | undefined = undefined;
  headerGridColumnApi: AgGrid.ColumnApi | undefined = undefined;
  math: globalMath.MathJsStatic = (globalMath as any).create();
  componentId = cuid();

  // single observed property can contain multiple observers
  observers: { [s: string]: { [s: string]: { [s: string]: ObserverObject } } } = {};
  stickySectionElement: any;

  constructor(props: StyleEditSectionProps) {
    super(props);
    this._isMounted = false;
    this.nonFrameworkComponents = {
      colorHeaderRenderer: ColorHeaderRenderer,
      nonFrameWorkToS5Renderer: NonFrameWorkToS5Renderer,
    };
    this.frameworkComponents = {
      iconCellRenderer: IconCellRenderer,
      imageCellRenderer: ImageCellRenderer,
      validValuesEditor: ValidValuesEditor,
      validValuesRenderer: ValidValuesRenderer,
      rangePickerRenderer: RangePickerRenderer,
      rangePickerEditor: RangePickerEditor,
      checkboxCellRenderer: CheckboxCellRenderer,
      popoverSelectEditor: PopoverSelect,
      validSizesRenderer: ValidSizesRenderer,
      notInLifecycle: NotInLifecycleRenderer,
      integerEditor: IntegerEditor,
      textValidationEditor: TextValidationEditor,
      clearValueRenderer: ClearValueCellRenderer,
      tooltipRenderer: TooltipRenderer,
      modalLinkRenderer: ModalLinkRenderer,
    };
    this.observers = {};
    this.state = {
      transposedDefs: null,
      colDefs: null,
      rowData: null,
      transposedData: null,
      disabledIds: [],
      addColorModalOpen: false,
      dependentData: null,
      isLoading: null,
      isAddingColor: false,
      submittedStyleColors: [],
      confirmationModalProps: {
        isOpen: false,
        descriptionText: '',
      },
      mergedRangeList: undefined,
    };
  }

  getComponentId = () => {
    return this.props.componentIdOverride ? this.props.componentIdOverride : this.componentId;
  };

  componentDidMount() {
    this.getSectionDataAndConfig();
    if (!isNil(this.props.parentId)) {
      getAllColors(this.props.parentId).then((colors) => {
        this.setState({
          availableColorsToAdd: colors,
        });
      });
    }
  }

  componentDidUpdate(prevProps: StyleEditSectionProps) {
    if (
      !isEqual(this.props.sortedStyleColorIds, prevProps.sortedStyleColorIds) &&
      !isNil(this.columnApi) &&
      !isNil(this.headerGridColumnApi)
    ) {
      this.reorderAndFilterColumns(this.columnApi);
      this.reorderAndFilterColumns(this.headerGridColumnApi);

      // only want to refresh on update in multisection since data is not updated
      if (!isNil(this.props.multiSectionData)) {
        if (!isNil(this.gridApi)) {
          this.gridApi.refreshCells();
        }
        if (!isNil(this.headerGridApi)) {
          this.headerGridApi.refreshCells();
        }
      }
    }
    if (!isEqual(this.props.parentId, prevProps.parentId)) {
      getAllColors(this.props.parentId).then((colors) => {
        this.setState({
          availableColorsToAdd: colors,
        });
      });
    }
    if (!isEqual(this.props.headerData, prevProps.headerData)) {
      if (this.gridApi && this.headerGridApi) {
        this.gridApi.redrawRows();
        this.headerGridApi.redrawRows();
        setGridRowHeights(this.state.colDefs, this.gridApi);
      }
    }

    if (!isNil(prevProps.styleId) && !isNil(this.props.styleId) && prevProps.styleId !== this.props.styleId) {
      this.getSectionDataAndConfig();
    }

    if (
      !isNil(prevProps.multiSectionData) &&
      !isNil(this.props.multiSectionData) &&
      !isEqual(prevProps.multiSectionData.data, this.props.multiSectionData.data)
    ) {
      this.handleMultiSection();
    }
    if (
      this.props.currentRefreshCount > prevProps.currentRefreshCount &&
      this.props.lastEditedSection != this.getComponentId()
    ) {
      this.getSectionDataAndConfig();
    }

    if (prevProps.expanded && !this.props.expanded) {
      // because sections never unmount anymore, this code repeats
      // what would happen on unmount, but instead when the section is un-expanded
      if (this.props.getHeaderElement) {
        this.props.getHeaderElement(undefined);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.getHeaderElement) {
      this.props.getHeaderElement(undefined);
    }
    this._isMounted = false;
  }

  getExportOptions = () => {
    const { sectionData, multiSectionData } = this.props;
    const title = isNil(multiSectionData) ? sectionData!.text : multiSectionData.text;
    const additionalExcelExports = {
      customHeader: title,
      excelRendererObj: Renderer.getAllExcelRenderers(),
    };
    return additionalExcelExports;
  };

  onCsvExport = () => {
    if (isNil(this.gridApi)) {
      // this shouldn't happen
      throw new Error('csv export called, but no gridapi was found');
    }
    let csvOptions: CsvExportParams = {
      columnGroups: true,
      processCellCallback: (params: ProcessCellForExportParams) => {
        const { column, node, value } = params;
        const colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[] = this.state.colDefs;
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === node!.id;
        });
        const colInfo = colDefs[rowIndex];
        const cellRenderer = colInfo.renderer;
        const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
        const renderFn = renderer ? Renderer[renderer] : undefined;

        return column.getColId() !== 'headerText' && renderFn ? renderFn(value) : value;
      },
      processHeaderCallback: (params: AgGrid.ProcessHeaderForExportParams) => {
        if (!this.state.rowData) return;
        const { column } = params;
        const id = column.getDefinition().headerName;
        const val: BasicPivotItem = this.state.rowData.find((data: BasicPivotItem) => {
          return data.id === id;
        });
        return get('attribute:cccolor:name', val) || '';
      },
    };

    const exportOptions = this.getExportOptions();
    csvOptions = {
      ...csvOptions,
      customHeader: exportOptions.customHeader + '\n',
    };

    this.gridApi.exportDataAsCsv(csvOptions);
  };

  onExcelExport = () => {
    if (isNil(this.gridApi)) {
      // this shouldn't happen
      throw new Error('xls export called, but no gridapi was found');
    }
    const exportOptions = this.getExportOptions();
    let excelOptions: ExcelExportParams = {
      columnGroups: true,
      sheetName: 'S5 Assortment Export',
      processCellCallback: (params: ProcessCellForExportParams) => {
        const { column, node, value } = params;
        const colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[] = this.state.colDefs;
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === node!.id;
        });
        const colInfo = colDefs[rowIndex];
        const cellRenderer = colInfo.renderer;
        const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
        const renderFn = renderer ? Renderer[renderer] : undefined;

        if (exportOptions && exportOptions.excelRendererObj && renderer !== '') {
          const excelFormat = exportOptions.excelRendererObj[renderer];
          if (excelFormat) {
            return value;
          }
        }
        return column.getColId() !== 'headerText' && renderFn ? renderFn(value) : value;
      },
      processHeaderCallback: (params: AgGrid.ProcessHeaderForExportParams) => {
        if (!this.state.rowData) return;
        const { column } = params;
        const id = column.getDefinition().headerName;
        const val: BasicPivotItem = this.state.rowData.find((data: BasicPivotItem) => {
          return data.id === id;
        });
        return get('attribute:cccolor:name', val) || '';
      },
    };
    const header: ExcelCell[][] = new Array(1);
    header.push([
      {
        data: {
          type: 'String',
          value: exportOptions.customHeader,
        },
        mergeAcross: 2,
      },
    ]);
    excelOptions = {
      ...excelOptions,
      customHeader: header,
      // fileName: (fileName && fileName + ' at ' + moment().format('YYYY-MM-DD hh|mmA')),
    };
    this.gridApi.exportDataAsExcel(excelOptions);
  };

  getSectionDataAndConfig() {
    this._isMounted = true;
    const { multiSectionData } = this.props;

    if (isNil(multiSectionData)) {
      this.handleStandardSection();
      // standard section makes and async call, at the end of which, loading is set to false
      // set it to true here to keep the component to toggle the overlay on, and it'll get toggled off
      // when the async inside handleStandardSection() returns
      this.setState({
        isLoading: this.props.sectionData ? this.props.sectionData.dataApi : null,
      });
    } else {
      this.handleMultiSection();
    }
  }

  handleStandardSection() {
    if (this.state.isLoading && isEqual(this.state.isLoading, this.props.sectionData!.dataApi)) return;
    const { sectionData } = this.props;
    const configApi = sectionData!.configApi;
    const dataApi = sectionData!.dataApi;
    const dependentsApi = sectionData!.dependentsApi;
    let queue: any[] = [];

    let dataProm: Promise<any>;
    if (dataApi.isListData) {
      dataProm = this.context.listData(dataApi.defnId, 'Assortment', dataApi.params).then((resp) => {
        return resp.flat;
      });
    } else {
      const dataUrl = getUrl(dataApi);
      dataProm = Axios.get(dataUrl).then((response) => {
        let rowData = response.data ? response.data : response;
        if (!isArray(rowData)) {
          rowData = rowData.data;
        }
        return rowData;
      });
    }

    dataProm = dataProm.then((data) => {
      return this.regulateData(data);
    });

    queue = [dataProm, getViewProm(configApi), getRangeLists()];

    if (dependentsApi) {
      queue = [...queue, Axios.get(dependentsApi.url)];
    }

    Promise.all(queue).then((response: any) => {
      if (!this._isMounted) {
        return;
      }

      const { daysRangeListExtended: daysRangeList, daysPastRangeList } = response[2];
      const mergedRangeList = {
        start_date: merge(daysRangeList.start_date, daysPastRangeList.start_date),
        end_date: merge(daysRangeList.end_date, daysPastRangeList.end_date),
      };
      importDateFunctions(this.math, mergedRangeList);
      this.setState({
        mergedRangeList,
      });

      let rowData = response[0];
      if (!isArray(rowData)) {
        rowData = rowData.data;
      }

      const config = maybeReturnNestData(response[1]);

      this.transposeRowsAndColumns(
        rowData,
        config.columns,
        config.actions,
        config.multiRangeEditors,
        dependentsApi ? response[3].data.data : null
      );

      if (this.props.onSectionDataLoaded) {
        this.props.onSectionDataLoaded();
      }
    });
  }

  handleMultiSection() {
    const { multiSectionData } = this.props;

    if (!isNil(multiSectionData)) {
      const { data: rowData, config } = multiSectionData;
      const modified = this.regulateData(rowData);

      if (isNil(modified) || isNil(config)) {
        return;
      }

      this.transposeRowsAndColumns(modified, config.columns, config.actions, config.multiRangeEditors);

      if (this.props.onSectionDataLoaded) {
        this.props.onSectionDataLoaded();
      }
    }
  }

  // handles filling in blank stylecolor data
  // to match the amount of stylecolor data for the style
  regulateData(data: any) {
    // currently only want to run this for 'Store Eligibility' which is the only with multiSectionData at this time
    if (isNil(this.props.multiSectionData)) {
      return data;
    }

    const { sortedStyleColorIds } = this.props;
    const modified = sortedStyleColorIds.map((styleColorId) => {
      const rowDataIndex = data.findIndex((item: any) => {
        return styleColorId === item.id || styleColorId === item.product;
      });

      return rowDataIndex < 0
        ? { id: styleColorId, [NOT_FOUND]: NOT_FOUND } // fill in with known empty data
        : data[rowDataIndex];
    });

    return modified;
  }

  addColor = () => {
    if (isNil(this.state.availableColorsToAdd)) {
      return;
    }
    this.setState({
      selectedNewColor: this.state.availableColorsToAdd[0],
      addColorModalOpen: true,
    });
  };

  removeColor(styleColorId: string) {
    const { sortedStyleColorIds, refresh } = this.props;
    const refreshCompanionView = sortedStyleColorIds.length === 1; // removing last style color from style

    removeStyleColor(styleColorId).then((resp: any) => {
      // for some reason the error handler is not invoked, so checking here for success
      const expectedErrorMsg = 'StyleColor is not removable from Assortment';
      if (!resp.data.success && resp.data.message === expectedErrorMsg) {
        toast.error(`This style color has already been published \ and cannot be removed from Assortment.`, {
          position: toast.POSITION.TOP_LEFT,
        });
      }

      if (refresh) {
        refresh(refreshCompanionView);
      }
    });
  }

  reorderAndFilterColumns(columnApi: AgGrid.ColumnApi) {
    if (isNil(columnApi) || !this.state.rowData || isNil(columnApi.getAllColumns())) {
      return;
    }

    const { sortedStyleColorIds } = this.props;
    const { rowData } = this.state;
    const allIds = rowData.map((data: any) => data.id);

    // hide all columns then show filtered columns | Also check if column count in grid is same or larger than new columns
    if (columnApi.getAllColumns().length - 1 >= sortedStyleColorIds.length) {
      columnApi.setColumnsVisible(allIds, false);
      columnApi.setColumnsVisible(sortedStyleColorIds, true);
      columnApi.moveColumns(sortedStyleColorIds, 0);
    }
  }

  private setDisabledId = (id: string): void => {
    const refreshCols = () => {
      if (this.gridApi) {
        this.gridApi.refreshCells({
          columns: [id],
          force: true,
        });
      }
    };

    if (!this.state.disabledIds.includes(id)) {
      this.setState(
        (prevState) => ({
          disabledIds: prevState.disabledIds.concat([id]),
        }),
        refreshCols
      );
    }
  };

  private removeDisabledId = (id: string): void => {
    const refreshCols = () => {
      if (this.gridApi) {
        this.gridApi.refreshCells({
          columns: [id],
          force: true,
        });
      }
    };

    if (this.state.disabledIds.includes(id)) {
      this.setState(
        (prevState) => ({
          disabledIds: without([id], prevState.disabledIds),
        }),
        refreshCols
      );
    }
  };

  private columnDisabled = (id: string): boolean => {
    return this.state.disabledIds.indexOf(id) >= 0;
  };

  private setDisabledIds(
    colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[],
    styleColor: BasicPivotItem,
    styleColorId: string
  ) {
    colDefs.forEach((col: StyleEditConfigColumn) => {
      if (col.controlsColumnDisable) {
        const disableColumn = col.controlColumnDisableValue === styleColor[col.dataIndex];
        if (disableColumn) {
          this.setDisabledId(styleColorId);
        } else {
          this.removeDisabledId(styleColorId);
        }
      }
    });
  }

  createColumn = (colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[], styleColor: BasicPivotItem): ColDef => {
    const inlineEditors = ['validSizes', 'checkbox'];
    const styleColorId = styleColor.id || (styleColor as any).product;

    const numRows = colDefs.length - 1; // 1 less because title
    function getNotInLifecycleText(numRow: number, rowIndex: number): string {
      let totalRows = numRow;
      if (totalRows % 2 === 0) {
        totalRows = totalRows - 1;
      }
      const tempNo = (totalRows - 3) / 2;
      const firstIndex = tempNo,
        secondIndex = tempNo + 1,
        thirdIndex = tempNo + 2;
      if (rowIndex === firstIndex) {
        return 'NOT IN';
      }
      if (rowIndex === secondIndex) {
        return 'LIFECYCLE';
      }
      if (rowIndex === thirdIndex) {
        return 'RANGE';
      }
      return '';
    }

    const notInLifecycleRange = (colInfo: StyleEditConfigColumn): boolean => {
      return styleColor[NOT_FOUND] && colInfo.dataIndex !== 'color_header';
    };

    const isInitialFundedFloorset = (colInfo: StyleEditConfigColumn): boolean => {
      return colInfo.dataIndex === FUNDED && styleColor['isFirstFloorset'];
    };

    const isEditable = (params: { node?: AgGrid.RowNode }) => {
      const rowIndex = colDefs.findIndex((def) => {
        return def.dataIndex === params.node!.id;
      });
      const colInfo = colDefs[rowIndex];
      if (colInfo == null) {
        return false;
      }

      if (colInfo) {
        if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
          return colInfo.editable && this.state.submittedStyleColors.indexOf(styleColorId) < 0;
        } else if (isInitialFundedFloorset(colInfo)) {
          return false;
        }

        return colInfo.editable;
      } else {
        return false;
      }
    };

    const isColumnDisabled = (colInfo: StyleEditConfigColumn) => {
      if (isNil(colInfo.renderer) || colInfo.renderer === 'color_header') {
        return false;
      }

      if (isInitialFundedFloorset(colInfo)) {
        return true;
      }

      if (isNil(colInfo.controlsColumnDisable)) {
        return this.columnDisabled(styleColorId);
      }

      // if prop exists and it's value is true (should be for funded only),
      // don't disable otherwise check if styleColorId is disabled
      return colInfo.controlsColumnDisable ? false : this.columnDisabled(styleColorId);
    };

    // check for and set disabled columns on load
    this.setDisabledIds(colDefs, styleColor, styleColorId);

    return {
      headerName: styleColorId,
      field: styleColorId,
      cellStyle: { borderRight: '1px solid #dee1e6' },
      cellClassRules: {
        [StyleEditSectionStyles.editableCell]: (params: CellClassParams) => {
          const rowIndex = colDefs.findIndex((def) => {
            return def.dataIndex === params.node!.id;
          });
          const colInfo = colDefs[rowIndex];
          if (isNil(colInfo) || notInLifecycleRange(colInfo) || isInitialFundedFloorset(colInfo)) {
            return false;
          }
          if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
            return colInfo.editable && this.state.submittedStyleColors.indexOf(styleColorId) < 0;
          }

          return colInfo.editable && !this.columnDisabled(styleColorId);
        },
        [StyleEditSectionStyles.cellDisabled]: (params: CellClassParams) => {
          const rowIndex = colDefs.findIndex((def) => {
            return def.dataIndex === params.node!.id;
          });
          const colInfo = colDefs[rowIndex];
          if (isNil(colInfo)) {
            return false;
          }

          return isColumnDisabled(colInfo);
        },
        [StyleEditSectionStyles.notInLifecycle]: (params: CellClassParams) => {
          const rowIndex = colDefs.findIndex((def) => {
            return def.dataIndex === params.node!.id;
          });
          const colInfo = colDefs[rowIndex];
          if (isNil(colInfo)) {
            return false;
          }

          return notInLifecycleRange(colInfo);
        },
      },
      suppressKeyboardEvent: () => false, // Note: if any popover components are configured for style edit, this will cause issues
      onCellValueChanged: (params: ValueSetterParams) => {
        this.handleCellValueChange(styleColorId, params, colDefs);
      },
      valueGetter: (params: AgGrid.ValueGetterParams) => {
        const { data } = params;
        const calculation = data.calculation;
        const getDataFn = partial(getDataFromKey, [params]);
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.node!.id;
        });
        const colInfo = colDefs[rowIndex];
        const colValue = params.data[styleColorId];

        if (
          colInfo.dataIndex !== 'color_header' &&
          (isNil(colInfo.editable) || colInfo.editable === false) &&
          isNil(colValue)
        ) {
          return null;
        }

        if (calculation) {
          if (params.data.onlyCalcOnParamChange) {
            // If dependent params of calculation have changed from original, process calcs
            let paramChangedCalc = false;
            const vars = getVarsFromCalcString(this.math, calculation);
            vars.forEach((v: string) => {
              const varValue = getDataFn(v).data;
              const columnField = params.colDef.field!;
              if (Object.keys(this.savedCalcData).indexOf(columnField) === -1) {
                this.savedCalcData[columnField] = {};
              }
              if (Object.keys(this.savedCalcData[columnField]).indexOf(v) === -1) {
                this.savedCalcData[columnField][v] = varValue;
              }
              if (!isEqual(varValue, this.savedCalcData[columnField][v])) {
                paramChangedCalc = true;
              }
            });
            if (!paramChangedCalc) {
              return params.data[styleColorId];
            }
          }
          const newValue = executeCalculation(this.math, calculation, getDataFn);
          return newValue;
        } else {
          const value = params.data[styleColorId];
          const isEditing = !isNil(params.api) && !isEmpty(params.api!.getEditingCells());

          if (!isNil(colInfo) && colInfo.renderer === 'percent') {
            const parsedValue = parseFloat(value);
            // if editing, show non-decimal value (0.11 => 11) otherwise percent renderer will format properly
            return isEditing ? parsedValue * 100 : parsedValue;
          }

          // If value is undefined or comes back as string "undefined", make it an empty string
          if (isNil(value) || toLower(value).trim() === 'undefined') {
            return '';
          }

          return value;
        }
      },
      valueParser: (params: ValueParserParams) => {
        const rowIndex = colDefs.findIndex((def) => def.dataIndex === params.node.id);
        const colInfo = colDefs[rowIndex];

        if (!isNil(colInfo) && colInfo.inputType === 'percent') {
          // reformat to decimal
          return params.newValue / 100;
        }

        return params.newValue;
      },
      valueSetter: (params: ValueSetterParams) => {
        this.handleSetValue(styleColorId, this.state.dependentData, params);
        return true;
      },
      editable: (params) => {
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.node!.id;
        });
        let isSubmitLocked = false;
        const colInfo = colDefs[rowIndex];
        if (isNil(colInfo) || notInLifecycleRange(colInfo) || isInitialFundedFloorset(colInfo)) {
          return false;
        }
        if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
          isSubmitLocked = this.state.submittedStyleColors.indexOf(styleColorId) >= 0;
        }

        const canEdit = !isNil(colInfo.editable) ? colInfo.editable : false;
        const isDisabled = isColumnDisabled(colInfo);
        const isInlineEditor = inlineEditors.indexOf(colInfo.renderer!) >= 0;

        return canEdit && !isDisabled && !isInlineEditor && !isSubmitLocked;
      },
      cellEditorSelector: (params: AgGrid.ICellEditorParams): ComponentSelectorResult => {
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.node!.id;
        });
        const colInfo = colDefs[rowIndex];
        if (colInfo == null) {
          return (null as unknown) as ComponentSelectorResult;
        }
        const inputParams = colInfo.inputParams;

        switch (colInfo.inputType) {
          case 'select':
            return {
              component: 'agRichSelect',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'multiRangeEditors':
            const { disableOverlap } = colInfo;
            const { multiRangeEditors } = this.state;
            const multiRangeDataIndices =
              !isNil(multiRangeEditors) && !isNil(colInfo.inputComponent)
                ? multiRangeEditors[colInfo.inputComponent]
                : {};
            return {
              component: 'rangePickerEditor',
              params: {
                values: map('value', colInfo.options),
                nodeKey: params.data.dataKey,
                makeModal: true,
                multiRangeDataIndices,
                disableOverlap,
                startWithPlanCurrent: true,
              },
            };
          case 'popoverSelect': {
            const { text: title, inputComponent } = colInfo;
            const processedDataApi = processApiParams(colInfo.dataApi, styleColor);

            return {
              component: 'popoverSelectEditor',
              params: {
                title,
                inputComponent,
                dataApi: processedDataApi,
                makeModal: inputComponent === 'ssgSelect',
              },
            };
          }
          case 'validValues':
          case 'validValuesMulti': {
            const multiSelect = colInfo.inputType === 'validValuesMulti' ? true : undefined;
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            let processedDataApi;
            let clientHandler;

            if (isNil(colInfo.dataApi.clientHandler)) {
              processedDataApi = processApiParams(colInfo.dataApi, styleColor);
            } else {
              const clientHandlerParams = this.props[colInfo.dataApi.clientHandlerParams!];
              clientHandler = getClientHandler(colInfo.dataApi.clientHandler!, clientHandlerParams);
            }

            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: processedDataApi,
                clientHandler,
                dataQa: isNil(multiSelect) ? 'select-style-edit-section' : 'select-multi-style-edit-section',
                multiSelect,
                asCsv: colInfo.asCsv,
                ignoreCache: colInfo.ignoreCache,
                allowEmptyOption,
                includeCurrent: colInfo.includeCurrent,
              },
            };
          }
          case 'validValuesNoServer': {
            const { dependentData } = this.state;
            return {
              component: 'validValuesEditor',
              params: {
                options: !isNil(dependentData) ? Object.keys(dependentData) : [],
                dataQa: 'select-no-server-editor',
              },
            };
          }
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'percent': // need a separate editor for percent to handle edit parsing
            return {
              component: 'agTextCellEditor',
            };
          case 'usMoney':
          case 'thousand':
          case 'usMoneyRounded':
          case 'useMoneyNoCents':
            return {
              component: 'nonFrameWorkToS5Renderer',
              params: {
                inputType: 'percent',
              },
            };
          case 'integer':
            const int = params.data[styleColorId];
            const percent = colInfo.renderer === 'percent';
            return {
              component: 'integerEditor',
              params: {
                passedInt: int,
                inputParams: { ...inputParams, percent },
              },
            };
          case 'textValidator':
          case 'textValidatorAsync': {
            const whitelist: typeof TVE_InputCharacterWhitelist = getWithDefault(
              TVE_InputCharacterWhitelist,
              inputParams.whitelist,
              TVE_InputCharacterWhitelist.alphaNumericSeparators
            );
            return {
              component: 'textValidationEditor',
              params: {
                validateAsync: colInfo.inputType === 'textValidatorAsync',
                ...inputParams,
                whitelist,
                onValidated: this.handlePendingCellUpdate.bind(this), // will be invoked in promise context, so need to set context
              },
            };
          }
          default:
            return {
              component: 'agTextCellEditor',
            };
        }
      },
      cellRendererSelector: (params: AgGrid.ICellRendererParams): ComponentSelectorResult => {
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.data.dataKey;
        });
        const colInfo = colDefs[rowIndex];
        if (colInfo == null) {
          return (null as unknown) as ComponentSelectorResult;
        }

        if (notInLifecycleRange(colInfo)) {
          return {
            component: 'notInLifecycle',
            params: {
              text: getNotInLifecycleText(numRows, rowIndex - 1),
            },
          };
        }

        switch (colInfo.renderer) {
          case 'image':
            return {
              component: 'imageCellRenderer',
              params: {
                showEditButton: true,
              },
            };
          case 'icon':
            return {
              component: 'iconCellRenderer',
              params: {
                icon: colInfo.rendererIcon,
              },
            };
          case 'color_header':
            const infoAsColorHeader = colInfo as ColorHeaderConfigColumn;
            const isRemovable = get([styleColorId, 'isremovable'], this.props.headerData) === true;
            const colorHeadParams: ColorHeaderParams = {
              $selector: () => {
                return mapValues(infoAsColorHeader.$selector, (value) => {
                  return this.props.headerData
                    ? get(`${styleColorId}.${value}`, this.props.headerData)
                    : styleColor[value];
                }) as SwatchSelector;
              },
              id: styleColorId,
              isRemovable, // handles enabling/disabling the 'Remove' button
              displayRemove: infoAsColorHeader.displayRemove, // handles rendering the 'Remove' button
              removeClicked: () => {
                this.setState({
                  confirmationModalProps: {
                    isOpen: true,
                    descriptionText: `Remove StyleColor ${styleColor.description}?`,
                    onConfirm: () => {
                      this.closeConfirmationModal();
                      this.removeColor(styleColorId);
                    },
                    onCancel: () => {
                      this.closeConfirmationModal();
                    },
                  },
                });
              },
            };
            return {
              component: 'colorHeaderRenderer',
              params: colorHeadParams,
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'range_picker':
            return {
              component: 'rangePickerRenderer',
            };
          case 'validSizes':
            const { dependentData } = this.state;
            if (!params.api) {
              // this block attempts to catch rare async errors that can occur when the section is
              // mounted and unmounted rapidly, causing a throw on params.api
              // if no api is found, dump the sections
              // see INT-1241
              this.props.refreshSections(null);
              return {
                component: 'nonFrameWorkToS5Renderer',
                params: {
                  renderer: colInfo.renderer,
                },
              };
            }
            const rangeCode = params.api.getRowNode(RANGE_CODE).data[styleColorId];
            const dependentSizes = !isNil(dependentData) ? dependentData[rangeCode] : null;
            const options = !isNil(dependentSizes) ? dependentSizes : params.data[styleColorId];
            return {
              component: 'validSizesRenderer',
              params: {
                selectedOptions: params.data[styleColorId],
                options,
                editable: isEditable(params),
              },
            };
          case 'validValuesRenderer':
            const dataConfig = processApiParams(colInfo.dataApi, styleColor);
            return {
              component: 'validValuesRenderer',
              params: {
                dataConfig,
              },
            };
          case 'clearValue':
            return {
              component: 'clearValueRenderer',
              params: {
                isDataTransposed: true,
              },
            };
          case 'modalLink':
            return {
              component: 'modalLinkRenderer',
              params: {
                displayValue: 'View Similar Items',
                isDataTransposed: true,
                modalParams: colInfo.inputParams,
                getValue: this.getOriginalValue,
              },
            };
          case 'tooltipRenderer':
            return {
              component: 'tooltipRenderer',
            };
          default:
            return {
              component: 'nonFrameWorkToS5Renderer',
              params: {
                renderer: colInfo.renderer,
              },
            };
        }
      },
    };
  };
  closeConfirmationModal = () => {
    this.setState({
      confirmationModalProps: {
        isOpen: false,
        descriptionText: '',
      },
    });
  };
  createRow = (rowData: any, colDef: any): TransposedColDef => {
    // setup observers if applicable
    const observed = get('observes', colDef); // get observed props from current colDef
    const observer = get('dataIndex', colDef);
    if (!isNil(observer) && !isNil(observed)) {
      if (isNil(this.observers[observed])) {
        this.observers[observed] = {
          [observer]: { dataApi: colDef.dataApi },
        };
      } else {
        const existingObservers = this.observers[observed];
        this.observers[observed] = {
          ...existingObservers,
          [observer]: { dataApi: colDef.dataApi },
        };
      }
    }

    const data = isArray(rowData) ? rowData : [rowData];
    return data.reduce(
      (acc: Record<string, any>, dataItem: any) => {
        const id = dataItem.id || dataItem.product;
        return set(id, dataItem[colDef.dataIndex], acc);
      },
      {
        headerText: colDef.text,
        dataKey: colDef.dataIndex,
        calculation: colDef.calculation,
        valueTests: colDef.valueTests,
        onlyCalcOnParamChange: colDef.onlyCalcOnParamChange,
      }
    );
  };

  getOriginalValue = (id: string, attrName: string) => {
    const { rowData } = this.state;
    return get(
      attrName,
      find((row) => row.id === id, rowData)
    );
  };

  transposeRowsAndColumns(
    rowData: any,
    colDefs: any,
    actions: any,
    multiRangeEditors: MultiRangeEditors,
    dependents: any = null
  ) {
    const newTransposed: TransposedColDef[] = colDefs.map(partial(this.createRow, [rowData]));
    const newColData: TransposedColDef[] = [
      {
        headerName: '',
        field: 'headerText',
        pinned: 'left',
      },
    ].concat(rowData.map(partial(this.createColumn, [colDefs])));
    const createdStyleColors = rowData
      .filter((i: BasicPivotItem) => !isNil(i[COLOR_SUBMITTED_ATTR]) && i[COLOR_SUBMITTED_ATTR] !== 'Undefined')
      .map((i: BasicPivotItem) => i.id);

    this.setState({
      submittedStyleColors: createdStyleColors,
      rowData,
      colDefs,
      actions,
      transposedData: newTransposed,
      transposedDefs: newColData,
      dependentData: dependents,
      isLoading: null,
      multiRangeEditors,
    });
  }

  handlePendingCellUpdate(value: string, pendingCellInfo: PendingCellInfo) {
    if (!isNil(pendingCellInfo.validation) && !pendingCellInfo.validation.isValid) {
      const { invalidValue, initialValue, invalidLevel } = pendingCellInfo.validation;
      const initial = isNil(initialValue) || isEmpty(initialValue) ? 'Empty' : initialValue;
      const invalidDescription = !isNil(invalidLevel)
        ? `"A value at the style color level was expected, but a ${invalidLevel} was received."`
        : undefined;
      const message = (
        <AsyncValidationErrorMessage
          initial={`"${initial}"`}
          invalidValue={`"${invalidValue}"`}
          invalidDescription={invalidDescription}
        />
      );
      toast.error(message, {
        autoClose: false,
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  handleCellValueChange = async (
    styleColorId: string,
    params: ValueSetterParams,
    colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[]
  ) => {
    const { colDef, data, api: gridApi, oldValue } = params;
    const newValue = params.newValue;
    const { field } = colDef; // store field (dataKey) of modified column to know which data field to modify
    const { dataKey } = data;
    const observers = this.observers[dataKey]; // get dataKey(s) of row to clear
    const rowIndex = colDefs.findIndex((def) => {
      return def.dataIndex === params.node!.id;
    });
    const colInfo = colDefs[rowIndex];

    // no need to update; validSizes updates won't pass this check, so always update validSizes
    if (isEqual(oldValue, newValue)) {
      return;
    } else if ((dataKey as string).toLowerCase().indexOf('validsizes') >= 0) {
      // FIXME: This is a mess, however, the server does not currently return arrays as arrays
      // so until that's fixed, we've got to do it this way :/
      if (isEqual(arrayStringToArray(oldValue), arrayStringToArray(newValue))) {
        return;
      }
    } else if (
      colInfo &&
      field &&
      colInfo.inputType === 'textValidatorAsync' &&
      newValue === PENDING_VALIDATION_VALUE
    ) {
      return; // skip posting unvalidated values
    }

    // clear dependentProp fields
    if (!isNil(observers) && !isNil(field)) {
      const styleColor: BasicPivotItem = {} as any;
      if (gridApi) {
        gridApi.forEachNode((rowNode: AgGrid.RowNode) => {
          styleColor[rowNode.data.dataKey] = rowNode.data[field];
        });
        gridApi.forEachNode(async (rowNode: AgGrid.RowNode) => {
          const observer = observers[rowNode.data.dataKey];

          if (isNil(observer)) {
            return;
          }

          if (observer.dataApi) {
            const api = observer.dataApi as any;
            const url = getUrl(processApiParams(api, styleColor));
            Axios.get(url).then((resp) => {
              if (resp.data && resp.data.data) {
                const respData = resp.data.data;
                const updatedValue =
                  typeof respData[params.newValue] === 'object'
                    ? respData[params.newValue][0]
                    : respData[params.newValue];
                rowNode.setDataValue(field, updatedValue);
              }
            });
          }
        });
      }
    }

    if (!isNil(this.state.actions) && !isNil(this.state.actions.patch)) {
      const { actions } = this.state;
      const { patch } = actions;

      switch (patch.clientHandler) {
        case 'planningParams':
        case 'lifecycleParams':
        case 'adjustments': {
          let updatedValue: string;
          if (isNil(newValue)) {
            updatedValue = '';
          } else if (newValue.weekNo) {
            updatedValue = newValue.weekNo;
          } else {
            updatedValue = newValue;
          }

          if (isNil(colInfo.inputType) || colInfo.inputType !== 'multiRangeEditors') {
            // Some attributes in lifecycle params were trying to post with the attribute: attatched
            const key = dataKey.split(':')[1] || dataKey.split(':')[0];
            updateLifecycleParams(styleColorId, { [key]: updatedValue })
              .then(() => {
                this.context.clearPivotCache();
                this.props.refreshSections(this.getComponentId());
              })
              .catch((e) => {
                toast.error('An error occured updating your lifecycle parameters');
                ServiceContainer.loggingService.error(
                  'An error occured updating the lifecycle parameters in Style edit lifecylce paramters',
                  e.stack
                );
              });
            break;
          }

          // need to calculate necessary fields to post
          // @ts-ignore
          const getDataFn = (partial(getDataFromKey, [params]) as unknown) as any; // REVERT THIS
          const calculatedFields = colDefs
            .filter((def) => !def.editable && def.calculation)
            .map((def) => {
              return {
                dataIndex: def.dataIndex,
                calculation: def.calculation,
                valueTests: def.valueTests,
              };
            });

          const updates = {};
          if (gridApi) {
            calculatedFields.forEach(({ dataIndex, calculation }) => {
              const calculated = executeCalculation(this.math, calculation!, getDataFn);
              updates[dataIndex] = calculated;
            });
          }

          const update = {
            [dataKey]: updatedValue,
            ...updates,
          };

          updateLifecycleParams(styleColorId, update)
            .then(() => {
              this.context.clearPivotCache();
              this.props.refreshSections(this.getComponentId());
            })
            .catch((e) => {
              toast.error('An error occured updating your lifecycle parameters');
              ServiceContainer.loggingService.error(
                'An error occured updating the lifecycle parameters in Style edit lifecylce paramters',
                e.stack
              );
            });
          break;
        }
        case 'colorOptions': {
          const strippedDataKey = dataKey
            .replace('attribute:', '')
            .replace(':id', '')
            .replace(':name', '');
          const updatedValue = {
            parent: [this.props.styleId],
            id: styleColorId,
            [strippedDataKey]: newValue,
          };

          // need to send cccolorid when cccolor is changed
          // FIXME: This shouldn't be done manually, however, dependents api is returning blank atm.
          if (dataKey === CC_COLOR) {
            updatedValue['cccolorid'] = size(newValue) > 0 ? newValue.split(' ')[0] : '';
          }

          updateStyleItem(updatedValue)
            .then(() => {
              this.context.clearPivotCache();
              this.props.refreshSections(this.getComponentId());
            })
            .catch((e) => {
              toast.error('An error occured updating your style');
              ServiceContainer.loggingService.error(
                'An error occured updating the style edit in styleeditsection',
                e.stack
              );
            });

          break;
        }
        case 'rangingParams': {
          const { propagateSelection, multiSectionIndex, multiSectionData, multiSectionLabel } = this.props;

          // handle forward propagation of data and sending update to server
          if (!isNil(propagateSelection) && !isNil(multiSectionIndex)) {
            const selection = {
              id: field,
              dataIndex: dataKey,
              newValue,
              oldValue,
            };

            // determine fields to calculate from config (exclude ssg)
            const toCalculate: string[] = multiSectionData!.config.propagatingIndices.filter(
              (dataIndex: string) => dataIndex !== SPECIAL_STORE_GROUP
            );
            if (toCalculate.indexOf(dataKey!) >= 0) {
              // if fields are blank, wait to calculate storeCount until fields are autofilled
              if (newValue.length === 0) {
                propagateSelection(multiSectionIndex, selection);
              } else if (toCalculate.indexOf(dataKey) >= 0 && !isNil(this.gridApi)) {
                const postData = toCalculate.map((dataIndex) => {
                  if (isNil(this.gridApi)) {
                    throw new Error('Grid api should always exists');
                  }
                  const rowNode = this.gridApi.getRowNode(dataIndex);
                  const rowNodeData = rowNode.data[field!];
                  return { [dataIndex]: rowNodeData };
                });
                AssortmentClient.calculateStoreCount(styleColorId, multiSectionLabel!, postData).then(
                  (calculatedStoreCount) => {
                    propagateSelection(multiSectionIndex, selection, calculatedStoreCount);
                  }
                );
              }
            } else {
              propagateSelection(multiSectionIndex, selection);
            }
          }

          break;
        }
        default:
          noop();
      }
    }
    if (colInfo.shouldRefresh === 'full') {
      this.props.refresh();
    } else if (colInfo.shouldRefresh === 'sections') {
      this.props.refreshSections(null);
    }
  };

  handleSetValue = (styleColorId: string, dependentData: DependentData | null, params: ValueSetterParams) => {
    const { data, newValue, api, colDef } = params;

    if (data.dataKey === SPECIAL_STORE_GROUP && !isNil(this.gridApi)) {
      // need to get and update store_count too when updating ssg
      const columnKey = colDef.field!;
      const storeCountNode = this.gridApi.getRowNode('store_count');
      storeCountNode.setDataValue(columnKey, newValue.store_count ? newValue.store_count : 0);

      // update ssg as usual
      data[styleColorId] = newValue.id;
      return true;
    }

    if (data.dataKey !== RANGE_CODE) {
      data[styleColorId] = newValue;
      return true;
    }

    if (api) {
      const rowNodeSizes = api.getRowNode(VALID_SIZES);
      if (newValue == null) {
        data[styleColorId] = newValue;
        rowNodeSizes.data[styleColorId] = [];
        return true;
      }
      const newValRange = newValue.value ? newValue.value : newValue;
      const newSizeRanges = !isNil(dependentData) ? [...dependentData[newValRange]] : [];

      // Set valid sizes and size range
      rowNodeSizes.data[styleColorId] = newSizeRanges;
      data[styleColorId] = newValRange;
      return true;
    } else {
      return false;
    }
  };

  handleHeaderGridReady = (headerGridApi: GridApi, headerGridColumnApi: ColumnApi) => {
    this.headerGridApi = headerGridApi;
    this.headerGridColumnApi = headerGridColumnApi;
    this.reorderAndFilterColumns(this.headerGridColumnApi);
    setGridRowHeights(this.state.colDefs, this.headerGridApi);
  };

  fetchAssortmentRules = (): Promise<AssortmentRulesResponse> => {
    return Promise.all([
      Axios.get('/api/assortment/default/lifecycleParams?appName=Assortment'),
      Axios.get('/api/assortment/default/rangingParams?appName=Assortment'),
    ]).then((resp) => {
      return {
        lifecycleParams: resp[0].data.data,
        rangingParams: resp[1].data.data,
      };
    });
  };

  renderAddColorModal = () => {
    const { addColor, existingStyleColors } = this.props;
    const { availableColorsToAdd, isAddingColor, addColorModalOpen, rowData, selectedNewColor } = this.state;
    const existingNotInAsst = getExistingColorsNotInAsst(existingStyleColors);

    if (isNil(availableColorsToAdd) || isNil(existingStyleColors)) {
      return null;
    }

    return (
      <Dialog open={addColorModalOpen} disableBackdropClick={false}>
        <DialogTitle>Add New Color</DialogTitle>
        <DialogContent>
          <div className={''} style={{ width: 200 }}>
            <Overlay type="loading" visible={isAddingColor} fitParent={true} />
            <ReactSelect
              className={style({ width: '100%', minWidth: '100%' })}
              options={availableColorsToAdd
                .concat(existingNotInAsst)
                .map((color) => {
                  const isExisting = existingNotInAsst.find((c) => color === c);
                  return {
                    value: color,
                    label: `${isExisting ? '*' : ''} ${color}`,
                    isExisting,
                  };
                })
                .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))}
              onChange={(event) => {
                if (event && !isArray(event)) {
                  this.setState({
                    selectedNewColor: event.value,
                  });
                }
              }}
              menuIsOpen={true}
              styles={{
                menu: (props) => ({
                  ...props,
                  position: 'relative',
                }),
                option: (props, state) => {
                  return {
                    ...props,
                    color: state.data.isExisting ? 'blue' : 'black',
                  };
                },
              }}
            />
            <div className={StyleEditSectionStyles.checkboxContainer}>
              <button
                className={StyleEditSectionStyles.iconButtonCancel}
                onClick={() =>
                  this.setState({
                    addColorModalOpen: false,
                  })
                }
              >
                <Icon className="fal fa-fw fa-2x fa-times" />
              </button>
              <button
                className={StyleEditSectionStyles.iconButtonSave}
                onClick={async () => {
                  if (rowData == null || rowData.length <= 0) {
                    this.setState({
                      addColorModalOpen: false,
                    });
                    return;
                  }
                  this.setState({
                    isAddingColor: true,
                  });
                  if (addColor) {
                    // update assortment rules
                    await this.fetchAssortmentRules();
                    await addColor(
                      selectedNewColor!,
                      rowData.find((r: BasicPivotItem) => r.id == this.props.sortedStyleColorIds[0])
                    );
                  }
                  this.setState({
                    addColorModalOpen: false,
                    isAddingColor: false,
                  });
                  this.getSectionDataAndConfig();
                }}
              >
                <Icon className="fal fa-2x fa-check" />
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    const { isLoading, transposedData } = this.state;
    if (!this.props.expanded) {
      // in the mounted but in un-expanded state, renderer nothing to save resources
      // note: this needs to be a real element, in order for the inViewport HOC to detect it's existence
      return <div></div>;
    }
    if (isLoading) {
      return <Overlay type="loading" visible={true} fitParent={true} qaKey="StyleEditSectionOverlay" />;
    }
    if (this.props.inViewport === false && this.props.enterCount === 0) {
      // this is a guard for MultiSection, in order to keep a large number of sections from rendering all at once
      // is uses a HOC to check if the component is in the viewport, otherwise we just render a blank space for it
      return <div style={{ height: '300px', width: '1px' }}></div>;
    }

    const { multiSectionData } = this.props;
    const { actions } = this.state;
    let addColorButton;

    if (!isNil(actions)) {
      if (actions.addColor) {
        addColorButton = <StyleEditAddColorButton onClick={this.addColor} />;
      }
    }

    const columnDefs = this.state.transposedDefs;
    const pinnedData = transposedData ? [transposedData[0]] : undefined;
    const rawData = transposedData ? transposedData.slice(1) : undefined;

    return (
      <React.Fragment>
        {!isNil(multiSectionData) && <StyleEditGridTitle title={multiSectionData.text} />}
        <div className={classes('ag-theme-material', 'data-grid', 'double-header', StyleEditSectionStyles.sectionGrid)}>
          <ConfirmationModal {...this.state.confirmationModalProps} />
          <AgGridReact
            rowData={rawData}
            columnDefs={columnDefs}
            singleClickEdit={true}
            components={this.nonFrameworkComponents}
            frameworkComponents={this.frameworkComponents}
            headerHeight={0}
            suppressBrowserResizeObserver={true}
            getRowNodeId={(data: any) => data.dataKey}
            getRowHeight={(params: any) => {
              return params.data.dataKey === 'color_header'
                ? StyleEditSectionStyles.colorHeaderRowHeight
                : StyleEditSectionStyles.defaultRowHeight;
            }}
            getContextMenuItems={() => {
              return [
                'expandAll',
                'contractAll',
                'copy',
                'resetColumns',
                {
                  name: 'CSV Export',
                  action: this.onCsvExport,
                },
                {
                  name: 'Excel Export',
                  action: this.onExcelExport,
                },
              ];
            }}
            onGridReady={(params: AgGrid.GridReadyEvent) => {
              if (params.api && params.columnApi) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;
                this.reorderAndFilterColumns(params.columnApi);
                setGridRowHeights(this.state.colDefs, this.gridApi);

                const headerGrid = (
                  <StyleEditSectionHeaderGrid
                    frameworkComponents={this.frameworkComponents}
                    nonFrameworkComponents={this.nonFrameworkComponents}
                    rowData={pinnedData}
                    columnDefs={columnDefs}
                    onHeaderGridReady={this.handleHeaderGridReady}
                  />
                );

                if (headerGrid && this.props.getHeaderElement) {
                  this.props.getHeaderElement(headerGrid);
                }
              }
            }}
            rowClassRules={{
              [StyleEditSectionStyles.editableRow]: (params: CellClassParams) => {
                return params.data.dataKey === ADJUSTED_APS;
              },
            }}
            domLayout={'autoHeight'}
          />
          {addColorButton}
          {this.renderAddColorModal()}
        </div>
      </React.Fragment>
    );
  }
}
export const ViewPortSensitiveStyleEditSection = handleViewport<StyleEditSectionProps>(StyleEditSection, {
  threshold: 0,
});
