import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ColGroupDef, ColDef, CellValueChangedEvent, RowNode } from 'ag-grid-community';
import { cloneDeep, isNil, forEach } from 'lodash';
import { GridItem } from 'src/utils/Component/AgGrid/AgDataFormat';
import { CompanionDataLookup } from 'src/utils/Component/ListView';
import { simpleByField } from 'src/utils/Pivot/Sort';
import { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { MasterDetailConfig } from 'src/utils/Component/AgGrid/AgConfigParse';

export const getLeafCount = (node: BasicPivotItem, leafCount = 0): number => {
  if (node && node.children && node.children.length > 0) {
    // Grid only shows unique items by id within a group
    // So to deal with id-less items, if there is atleast one item with no id add 1
    // Skip all id-less items in reduce
    const hasIdLessChild = node.children.some((child) => child.leaf && !child.id);
    if (hasIdLessChild) {
      leafCount++;
    }
    leafCount = node.children.reduce((sum: number, current: BasicPivotItem) => {
      // Skip id-less items
      if (!current.id && current.leaf) {
        return sum;
      }
      return current.leaf ? sum + 1 : getLeafCount(current, sum);
    }, leafCount);
  }
  return leafCount;
};

export function companionDataParse(
  uniqueItems: GridItem[],
  companionDataLookup: CompanionDataLookup,
  sortDirection: 'asc' | 'desc',
  sortField?: string
): ListViewable[] {
  let companionData = uniqueItems;

  if (sortField) {
    companionData = simpleByField(companionData, sortField, sortDirection);
  }

  const keys = Object.keys(companionDataLookup).filter((i) => {
    return ![
      'id',
      'title',
      'name',
      'stars',
      'imageUri',
      'noImageUrl',
      'index',
      'titleId',
      'bodyId',
      'starsId',
      'imageUrlId',
      'displayTitle',
    ].includes(i);
  });
  const data = companionData.map((datum) => {
    return {
      id: datum[companionDataLookup.titleId],
      name: datum[companionDataLookup.bodyId],
      stars: parseInt(datum[companionDataLookup.starsId || ''], 10),
      imageUri: datum[companionDataLookup.imageUrlId],
      ...(!isNil(companionDataLookup.displayTitle) && {
        title: datum[companionDataLookup.displayTitle],
      }),
    };
  });
  companionData.forEach((datum, i) => {
    keys.forEach((key) => {
      const value = companionDataLookup[key];
      data[i][key] = datum[value];
    });
  });
  return data;
}

function isColGroupDef(colDef: ColDef | ColGroupDef): colDef is ColGroupDef {
  return (colDef as ColGroupDef).children !== undefined;
}

export function setDataGridDefaultSortColDef(
  columnDefs: (ColDef | ColGroupDef)[],
  sortDirection: 'desc' | 'desc',
  sortField?: string,
  masterDetailConfig?: MasterDetailConfig | null
) {
  const formattedColumnDefs = cloneDeep(columnDefs);

  formattedColumnDefs.forEach((colDef: ColDef | ColGroupDef, index) => {
    // FIXME: master detail works for flat data only, need to handle for grouped data as well
    if (masterDetailConfig && index === 0) {
      colDef['cellRenderer'] = 'agGroupCellRenderer';
    }

    if (isColGroupDef(colDef)) {
      colDef.children.forEach((subColDef: ColDef) => {
        if (subColDef.field === sortField) {
          subColDef.sort = sortDirection;
        }
      });
    }

    if ((colDef as ColDef).field === sortField) {
      (colDef as ColDef).sort = sortDirection;
    }
  });
  return formattedColumnDefs;
}

export function disaggregateHeaderValue({ column, newValue, oldValue, node, api }: CellValueChangedEvent) {
  const dataIndex = column.getColId();
  const childrenCount = node.childrenAfterGroup.length;
  const parsedValue = parseInt(newValue);

  forEach(node.childrenAfterGroup, (node: RowNode) => {
    const percentage =
      isNil(oldValue) || oldValue === 0
        ? 1 / childrenCount // equal percentage among children
        : node.data[dataIndex] / oldValue;

    const updatedValue = parsedValue * percentage;

    node.setDataValue(dataIndex, updatedValue);
  });

  api?.redrawRows();
}

export function maybeModifyColDefs(
  colDefs: ColDef[],
  selectHandler: (node: RowNode, isChecked: boolean) => void,
  selectAllHandler: (isChecked: boolean) => void
) {
  const exceptionCheckbox = colDefs.find((colDef) => colDef.field === 'exceptionselect');

  if (isNil(exceptionCheckbox)) {
    return colDefs;
  }

  exceptionCheckbox.headerComponent = 'gridHeaderCheckbox';
  exceptionCheckbox.headerComponentParams = {
    ignoreHeaderText: true,
    onChange: selectAllHandler,
  };
  exceptionCheckbox.cellRendererParams = {
    isEditable: true,
    onChange: selectHandler,
  };

  return colDefs;
}
