import { connect, Dispatch } from 'react-redux';
import { Reporting } from 'src/pages/Reporting/Reporting';
import { AppState, AppThunkDispatch } from 'src/store';
import { requestReportingConfig, receiveReportingConfig, receiveError } from './Reporting.actions';
import serviceContainer from 'src/ServiceContainer';
import { ReportingSlice } from 'src/pages/Reporting/Reporting.reducer';
import { AppType } from 'src/types/Domain';
import { errorToLoggingPayload } from 'src/services/loggingService';

export type StateProps = {
  reporting: ReportingSlice;
  perspective?: AppType;
};

export type DispatchProps = {
  onShowView(): void;
};

function stateToProps(state: AppState): StateProps {
  return {
    reporting: state.pages.reporting,
    perspective: state.perspective.selected?.value,
  };
}

function fetchReportConfig() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState): Promise<void> => {
    const { selected } = getState().perspective;
    dispatch(requestReportingConfig());
    try {
      const reportConfig = await serviceContainer.reportClient.getReportConfig(selected!.value);
      dispatch(receiveReportingConfig(reportConfig));
    } catch (e) {
      receiveError(errorToLoggingPayload(errorToLoggingPayload(e)));
    }
  };
}

function dispatchToProps(dispatch: AppThunkDispatch) {
  return {
    onShowView() {
      dispatch(requestReportingConfig());
      dispatch(fetchReportConfig());
    },
  };
}

export default connect(stateToProps, dispatchToProps)(Reporting);
