import { connect } from 'react-redux';
import container from 'src/ServiceContainer';

import {
  requestConfig,
  receiveConfig,
  receivePageError,
  updateAggBys,
  receiveSomeNestedAttributeConfig,
} from 'src/pages/Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.slice';
import { Option } from 'src/components/Configure/ConfigureModal';
import { updateConfigureSelections } from 'src/components/Subheader/Subheader.slice';
import { getConfigureSelections } from 'src/components/Subheader/Subheader.selectors';

import MacroGridPair, {
  Props as MacroGridPairProps,
  StateProjection,
} from 'src/components/MacroGridPair/MacroGridPair';
import { AppState } from 'src/store';
import { selectAndProjectState } from 'src/pages/Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.selectors';
import { WrappedThunkDispatch, wrappedDispatch } from 'src/utils/Redux/Dispatch';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { defaultsToAggBys } from 'src/utils/Component/Configure';
import * as fp from 'lodash/fp';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/FavoritesMenu';
import { NestedAttributeComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';

export type NestedAttributeOwnProps = z.infer<typeof NestedAttributeComponentProps>;

function mapStateToProps(state: AppState, ownProps: NestedAttributeOwnProps): StateProjection {
  const projection = selectAndProjectState(state, ownProps);
  if (projection.loaded === true) {
    return {
      ...projection,
      configureSelections: getConfigureSelections(state),
    };
  } else {
    return projection;
  }
}

function mergeProps(
  mappedState: StateProjection,
  dispatchProps: WrappedThunkDispatch,
  ownProps: NestedAttributeOwnProps
): MacroGridPairProps {
  const { dispatch } = dispatchProps;
  // show configure by default
  const { defns, showConfigure = true, showFlowStatus, subheaderErrorText, fullHeight } = ownProps;

  if (!showConfigure && mappedState.loaded) {
    // WARNING: mutation here
    // listDataTreeToAgFlatTree inside MacroGridPair.selector always generated a treeColumnDefinition (a default one is always injected),
    // however, some views have aggBys but no configure.  In those cases, we need to remove the treeColumnDefinition
    // as the default aggBy is just something the pivot needs, and we don't need a AgGrid group column showing in the view,
    // whereas on views with aggBys and a configure modal, we will.
    //  We have to do this here in mergeProps, because the config for showing the configure button or not is in ownProps
    // see INT-1235 for more info
    // TODO: move this somewhere else so we don't have to mutate this object
    mappedState.treeColumnDefinition ? (mappedState.treeColumnDefinition = undefined) : null;
  }

  const mergedProps: MacroGridPairProps = {
    ...mappedState,
    subheaderErrorText,
    showFlowStatus,
    showConfigure,
    fullHeight,
    onShowView() {
      dispatch(requestConfig());

      const configPromise = container.tenantConfigClient.getTenantViewDefnsWithFavorites({
        defnIds: defns.view,
        appName: ASSORTMENT,
      });

      configPromise
        .then((config) => {
          const unmodifiedViewDefn = config[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (config as any)[defns.view.length],
            dispatch
          );
          if (localConfig && localConfig.config) {
            config[0] = localConfig.config;
            if (localConfig.configurationSelections) {
              config[2].defaultsOverride = localConfig.configurationSelections;
            }
          }
          dispatch(
            receiveConfig({
              grid: config[0],
              macro: config[1],
              configure: config[2],
              list: config[3],
              unmodifiedViewDefn,
            })
          );

          if (localConfig && localConfig.configurationSelections) {
            dispatch(updateAggBys(localConfig.configurationSelections.map((x) => x.dataIndex)));
          } else {
            const aggBysConfig = config[2];
            const aggByDefaults = defaultsToAggBys(aggBysConfig);
            dispatch(updateAggBys(aggByDefaults));
          }
        })
        .catch((_error) => {
          dispatch(receivePageError());
        });
    },
    updateConfigureSelections(selections: Option[]) {
      dispatch(updateConfigureSelections(selections));
    },
    onConfigAggregate(aggBys: string[]) {
      dispatch(updateAggBys(aggBys));
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(receiveSomeNestedAttributeConfig(config));
    },
  };
  return mergedProps;
}

const sensitiveNestedAttribute = fp.flow(() => MacroGridPair, makePrintSensitive)();
export default connect(mapStateToProps, wrappedDispatch, mergeProps)(sensitiveNestedAttribute);
