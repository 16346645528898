import { classes } from 'typestyle';
import React from 'react';
import { isEmpty } from 'lodash';
import { Overlay } from 'src/common-ui';
import BottomBar from './BottomBar';
import SubheaderDropdown, { SubheaderDropdownProps } from './SubheaderDropdown';
import { DispatchProps, StateProps } from './Subheader.container';
import { containerStyle, getFiltersStyle } from './Subheader.styles';
import ViewConfiguratorModal, {
  ViewConfiguratorModalProps,
} from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { BackgroundDataLoading, BackgroundDataLoadingProps } from '../BackgroundDataLoading/BackgroundDataLoading';
import { FavoriteListItemStorage } from './Favorites/FavoritesMenu';
import { Favorites } from './Favorites/Favorites';
import { SubheaderFilters } from './SubheaderFilters';
import { SortBySlice } from './Subheader.slice';
import SubheaderQuickActionButton from './SubheaderQuickActionButton';

export type ConfigureOptions =
  | {
      type: 'enabled';
      onConfigureClick(): void;
    }
  | {
      type: 'disabled';
    };

export type PassedProps = {
  title: string;
  hideTitle?: boolean;
  groupByDefn?: string;
  sortByDefn?: string;
  pareDownDefn?: string;
  countLimitDefn?: string;
  showLookBackPeriod?: boolean;
  showFlowStatus?: boolean;
  showCountLimit?: boolean;
  configureOptions?: ConfigureOptions;
  showSearch?: boolean;
  errorCondition?: string | string[];
  extraDropdowns?: SubheaderDropdownProps[];
  customEl?: JSX.Element;
  searchReturn?: (searchString: string) => void;
  showAlternateFlowStatus?: boolean;
  showAlternateSearch?: boolean;
  summary?: string; // bottom text
  viewConfigurator?: ViewConfiguration;
  downloadLink?: string;
  favoritesSaveOverride?: FavoriteListItemStorage;
  onChangeSort?: () => void;
  onChangeGroup?: () => void;
} & Partial<BackgroundDataLoadingProps>;

export type ViewConfiguration = ViewConfiguratorModalProps;

export type Props = StateProps & DispatchProps & PassedProps;

export type State = {
  loadingViewDefns: boolean;
  filtersVisible: boolean;
  filtersPopoverVisible: boolean;
};

function showSortArrow(sortBy: SortBySlice): boolean {
  const hasSortOptions = sortBy.options.length > 0;
  const hasEmptySortOption = hasSortOptions && sortBy.options[0].text === '';
  const isEmptyOptionSelected = hasEmptySortOption && sortBy.selection === 0;
  const hasEmptyOptionOnly = hasEmptySortOption && sortBy.options.length === 1;
  const shouldHideSortArrow = !hasSortOptions || hasEmptyOptionOnly || isEmptyOptionSelected;
  return !shouldHideSortArrow;
}

export default class Subheader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loadingViewDefns: false,
      filtersVisible: false,
      filtersPopoverVisible: false,
    };
  }

  componentDidMount() {
    const { getViewDefns, sortByDefn, groupByDefn, pareDownDefn, countLimitDefn } = this.props;
    const defns: (string | null)[] = [];
    defns.push(groupByDefn || null);
    defns.push(sortByDefn || null);
    defns.push(countLimitDefn || null);
    defns.push(pareDownDefn || null);
    getViewDefns(defns);
  }

  toggleFilters = () => {
    this.setState({
      filtersVisible: !this.state.filtersVisible,
    });
  };

  toggleFiltersPopover = () => {
    this.setState({
      filtersPopoverVisible: !this.state.filtersPopoverVisible,
    });
  };

  render() {
    const { subheader, configureOptions, extraDropdowns, viewConfigurator } = this.props;
    const { groupBy, sortBy, pareDown, countLimit, countLimitOptions } = subheader;

    const extraDropdownsElements =
      extraDropdowns && extraDropdowns.length > 0
        ? extraDropdowns.map((dropdown) => <SubheaderDropdown key={dropdown.label} {...dropdown} />)
        : [];

    // Filter inputs

    const flowStatusInputs = {
      showFlowStatus: this.props.showFlowStatus,
      flowStatus: subheader.flowStatus,
      flowStatusOptions: subheader.flowStatusConfig || this.props.flowStatusOptions,
      updateFlowStatus: this.props.updateFlowStatus,
    };
    const altFlowStatusInputs = {
      showAltFlowStatus: this.props.showAlternateFlowStatus,
      altFlowStatus: subheader.altFlowStatus,
      altFlowStatusOptions: subheader.flowStatusConfig || this.props.flowStatusOptions,
      updateAltFlowStatus: this.props.updateAltFlowStatus,
    };
    const searchInputs = {
      showSearch: this.props.showSearch,
      search: subheader.search,
      searchReturn: this.props.searchReturn,
      updateSearchString: this.props.updateSearchString,
    };
    const altSearchInputs = {
      showAltSearch: this.props.showAlternateSearch,
      altSearch: subheader.altSearch,
      searchReturn: this.props.searchReturn,
      updateAltSearchString: this.props.updateAltSearchString,
    };
    const pareDownInputs = {
      showPareDown: !(
        !subheader.pareDownDefn ||
        pareDown.options.length < 1 ||
        (pareDown.options.length === 1 && pareDown.options[0].text === '')
      ),
      selections: pareDown.selections,
      options: pareDown.options,
      setPareDownSelections: this.props.setPareDownSelections,
    };
    const groupByInputs = {
      showGroupBy: !(
        groupBy.options.length < 1 ||
        (groupBy.options.length === 1 && groupBy.options[0].text === '') ||
        !subheader.groupByDefn
      ),
      groupBy,
      onChangeGroup: this.props.onChangeGroup,
      setGroupBySelection: this.props.setGroupBySelection,
    };
    const sortByInputs = {
      showSortBy: !(
        !subheader.sortByDefn ||
        sortBy.options.length < 1 ||
        (sortBy.options.length === 1 && sortBy.options[0].text === '')
      ),
      sortBy,
      onChangeSort: this.props.onChangeSort,
      setSortBySelection: this.props.setSortBySelection,
    };
    const sortArrowInputs = {
      showSortArrow: showSortArrow(sortBy),
      onChangeSort: this.props.onChangeSort,
      setSortByDirection: this.props.setSortByDirection,
    };
    const countLimitInputs = {
      showCountLimit: this.props.showCountLimit,
      countLimit,
      countLimitOptions,
      setCountLimit: this.props.setCountLimit,
    };
    const showTitle = !this.props.hideTitle && !isEmpty(this.props.title);

    return (
      <div className={containerStyle} data-qa="Subheader">
        <Overlay type="loading" visible={subheader.subheaderLoading} />
        <div className="top-bar">
          <div className={classes(showTitle ? 'title-container' : 'hidden-title-container')}>
            {showTitle && <h1 className="title">{this.props.title}</h1>}
            <BackgroundDataLoading viewDataState={this.props.viewDataState} />
          </div>
          <div className="quick-actions">
            {configureOptions && configureOptions.type == 'enabled' && (
              <SubheaderQuickActionButton
                text={'Configure'}
                iconClass={'far fa-cog'}
                onClick={configureOptions.onConfigureClick}
              />
            )}
            {viewConfigurator && viewConfigurator.updateConfig && (
              <ViewConfiguratorModal subheaderTitle={this.props.title} {...viewConfigurator} />
            )}
            {viewConfigurator && (
              <Favorites
                viewConfigurator={viewConfigurator}
                favoritesList={subheader.favoritesList}
                favoritesSaveOverride={this.props.favoritesSaveOverride}
                getFavoritesList={this.props.getFavoritesList}
                setFavoritesList={this.props.setFavoritesList}
                subheaderValues={{
                  groupBy,
                  sortBy,
                  pareDown,
                  showCountLimit: this.props.showCountLimit,
                  countLimit,
                  countLimitOptions,
                  countLimitDefault: subheader.countLimitDefault,
                  groupByDefn: subheader.groupByDefn,
                  sortByDefn: subheader.sortByDefn,
                  pareDownDefn: subheader.pareDownDefn,
                }}
                subheaderHandlers={{
                  setGroupBySelection: this.props.setGroupBySelection,
                  setSortBySelection: this.props.setSortBySelection,
                  setSortByDirection: this.props.setSortByDirection,
                  setPareDownSelections: this.props.setPareDownSelections,
                  setCountLimit: this.props.setCountLimit,
                }}
              />
            )}
            {this.props.downloadLink && (
              <SubheaderQuickActionButton
                iconClass={'far fa-cloud-download'}
                iconDataQa="subheader-download-button"
                href={this.props.downloadLink}
              />
            )}
          </div>
          <section className={classes('filters', getFiltersStyle(true))}>
            <SubheaderFilters
              flowStatusInputs={flowStatusInputs}
              altFlowStatusInputs={altFlowStatusInputs}
              searchInputs={searchInputs}
              altSearchInputs={altSearchInputs}
              customElement={this.props.customEl}
              pareDownInputs={pareDownInputs}
              groupByInputs={groupByInputs}
              sortByInputs={sortByInputs}
              sortArrowInputs={sortArrowInputs}
              extraDropdownElements={extraDropdownsElements}
              countLimitInputs={countLimitInputs}
            />
          </section>
        </div>
        <BottomBar
          errorCondition={this.props.errorCondition}
          lookBackPeriod={subheader.lookBackPeriod}
          lookBackPeriods={this.props.lookBackPeriods}
          showLookBackPeriod={this.props.showLookBackPeriod}
          summary={this.props.summary}
          updateLookBackPeriod={this.props.updateLookBackPeriod}
        />
      </div>
    );
  }
}
