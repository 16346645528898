import { connect } from 'react-redux';

import container from 'src/ServiceContainer';
import {
  ParameterTogglesOwnProps,
  ParameterTogglesConfig,
} from 'src/pages/Allocation/ParameterToggles/ParameterToggles.types';
import { allocationParameterTogglesLens, scopeLens } from 'src/services/lenses/lenses';
import {
  requestParameterTogglesConfig,
  receiveParameterTogglesConfig,
} from 'src/pages/Allocation/ParameterToggles/ParemeterToggles.slice';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import ParameterToggles from 'src/pages/Allocation/ParameterToggles/ParameterToggles';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import { AppState, AppThunkDispatch } from 'src/store';
import { dispatchUpdateWorklistPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';

function mapStateToProps(state: AppState, _ownProps: ParameterTogglesOwnProps) {
  const scope = scopeLens.get(state);
  const parameterToggles = allocationParameterTogglesLens.get(state);
  const { isConfigLoading, isDataLoading, viewDefn /* data */ } = parameterToggles;

  return {
    isLoading: isConfigLoading || isDataLoading,
    config: viewDefn,
    scopeParams: {
      productMember: scope.productMember || '',
      locationMember: scope.locationMember || '',
    },
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: ParameterTogglesOwnProps) {
  return {
    async onShowView() {
      dispatch(requestParameterTogglesConfig());
      const config = await container.tenantConfigClient.getTenantViewDefn<ParameterTogglesConfig>({
        defnId: ownProps.defns.view[0],
        appName: ASSORTMENT,
      });

      dispatch(receiveParameterTogglesConfig(config));
    },
    onSubmitPlan(defnId: string, data: Record<string, boolean | string | number> | null) {
      dispatchUpdateWorklistPlan(dispatch, defnId, data);
    },
  };
}
export type ParameterTogglesDispatchProps = ReturnType<typeof dispatchToProps>;
export type ParameterTogglesValueProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, dispatchToProps)(makeScopeSensitive(ParameterToggles));
