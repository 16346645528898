import React from 'react';
import ReactDOM from 'react-dom';
import { isNil, isEmpty } from 'lodash';
import { ICellEditorParams } from 'ag-grid-community';

import { InputSuggest } from 'src/common-ui/index';
import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';

import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import {
  getSelectedValidValue,
  getSelectedValidValues,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RenderUtils';
import { ValueType } from 'react-select/lib/types';

export interface ValidValuesEditorProps extends ICellEditorParams {
  dataConfig: ViewApiConfig;
  multiSelect?: boolean;
  asCsv?: boolean;
  dataQa?: string;
  options?: string[];
  allowEmptyOption?: boolean;
  ignoreCache?: boolean;
  returnSelectionObject: boolean;
  concatOptionValues?: boolean;
  includeCurrent?: boolean;
  clientHandler?: (currentValue: string) => Promise<Suggestion[]>;
}

interface State {
  validValues: Suggestion[];
  loaded: boolean;
  selectedOption: Suggestion | undefined;
  selectedOptions: Suggestion[];
  useLabelAsValue: boolean; // tells getValue method property to return for actual value
}

export default class ValidValuesEditor extends React.Component<ValidValuesEditorProps, State> {
  state: State;
  inputRef!: InputSuggest | null;

  constructor(props: ValidValuesEditorProps) {
    super(props);
    this.state = {
      validValues: [],
      loaded: false,
      selectedOption: undefined,
      selectedOptions: [],
      useLabelAsValue: false,
    };
  }

  componentDidMount() {
    const {
      dataConfig,
      multiSelect,
      options,
      clientHandler,
      value,
      allowEmptyOption,
      concatOptionValues,
      ignoreCache,
      includeCurrent,
    } = this.props;

    if (!isNil(options)) {
      // validValuesNoServer editor type
      this.setupServerlessSelection();
      this.focus();
      return;
    } else if (!isNil(clientHandler)) {
      clientHandler(value).then((validValues: Suggestion[]) => {
        this.setupSelection(validValues);
      });
      return;
    }

    const url = getUrl(dataConfig);
    getValidValues(url, allowEmptyOption, concatOptionValues, ignoreCache)
      .then((validValues) => {
        if (includeCurrent) {
          validValues.unshift({
            value: this.getValue(),
            label: this.getValue(),
          });
        }
        this.setupSelection(validValues);

        if (isNil(multiSelect)) {
          this.focus();
        }
      })
      .catch((err) => window.console.log(err));
  }

  setupSelection(validValues: Suggestion[]) {
    const { value, multiSelect, asCsv } = this.props;
    const commonState = {
      validValues,
      loaded: true,
    };

    if (isNil(multiSelect) || multiSelect == false) {
      let useLabelAsValue = false;
      let selectedOption = !isNil(value) ? getSelectedValidValue(value, validValues) : undefined;

      if (isNil(selectedOption)) {
        selectedOption = getSelectedValidValue(value, validValues, true);
        useLabelAsValue = !isNil(selectedOption);
      }

      this.setState({
        ...commonState,
        selectedOption,
        useLabelAsValue,
      });
    } else {
      const selectedOptions = !isNil(value) ? getSelectedValidValues(value, validValues, asCsv) : [];

      this.setState({
        ...commonState,
        selectedOptions,
      });
    }
  }

  setupServerlessSelection() {
    const { options } = this.props;

    const validValues = options!.map((selection) => {
      return {
        label: selection,
        value: selection,
      };
    });

    this.setupSelection(validValues);
  }

  focus() {
    setTimeout(() => {
      if (this.inputRef == null) {
        return;
      }
      // eslint-disable-next-line react/no-find-dom-node
      const container = ReactDOM.findDOMNode(this.inputRef);
      if (container) {
        /* eslint-disable-next-line */
        // @ts-ignore
        container.querySelector('input[type="text"]').focus();
      }
    });
  }

  isPopup() {
    return true;
  }

  getValue() {
    const { value, multiSelect, returnSelectionObject } = this.props;
    const { loaded, selectedOption, selectedOptions, useLabelAsValue } = this.state;

    if (!loaded) {
      return value;
    }

    // single select validValues
    if (isNil(multiSelect)) {
      if (isNil(selectedOption)) {
        return value;
      } else if (!isNil(returnSelectionObject) && returnSelectionObject) {
        return selectedOption;
      }

      return !useLabelAsValue ? selectedOption.value : selectedOption.label;
    }

    // normal multiSelect validValues
    return !isEmpty(selectedOptions)
      ? selectedOptions.map((option: Suggestion) => (!useLabelAsValue ? option.value : option.label))
      : [];
  }

  onSelect = (selection: Suggestion) => {
    this.setState(
      {
        selectedOption: selection,
      },
      this.props.stopEditing
    );
  };

  onMultiSelect = (selections: ValueType<Suggestion>) => {
    const updated = [...(selections as Suggestion[])];
    this.setState({
      selectedOptions: updated,
    });
  };

  render() {
    const { multiSelect, dataQa } = this.props;
    const { validValues, selectedOption, selectedOptions } = this.state;

    // determine selection(s) and handler based on single or multi select
    const onSelectHandler = isNil(multiSelect) ? this.onSelect : undefined;
    const onMultiSelectHandler = isNil(multiSelect) ? undefined : this.onMultiSelect;
    const selected = isNil(multiSelect) ? selectedOption : selectedOptions;

    return (
      <InputSuggest
        ref={(ref) => (this.inputRef = ref)}
        validValues={validValues}
        multiSelect={multiSelect}
        selected={selected}
        onSelect={onSelectHandler}
        onMultiSelect={onMultiSelectHandler as any}
        alwaysShowMenu={true}
        dataQa={dataQa}
      />
    );
  }
}
