import { classes, style, media, keyframes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { important } from 'csx';

export const HEADERBAR_HEIGHT = 28;
const NAVBAR_RIGHT_MIN_WIDTH = 175;
const NAVBAR_HORIZONTAL_PADDING = 16;
const spinnerFrames = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});
const headerbarStyle = style(
  media(
    { type: 'print' },
    {
      $nest: {
        '.navbar-left': {
          // all the other stuff except the scope text is hidden in print mode
          maxWidth: '100%',
        },
      },
    }
  ),
  {
    $debugName: 'headerbarStyle',
    padding: `5px ${NAVBAR_HORIZONTAL_PADDING}px`,
    backgroundColor: '#EFF0F0',
    fontSize: '12px',
    height: HEADERBAR_HEIGHT,
    $nest: {
      '.nav': {
        flexDirection: 'row',
      },
      '.navbar-nav': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      '#headerbar-print > i': {
        paddingRight: 5,
      },
      '#headerbar-reconcile > i': {
        paddingRight: 5,
      },
      '.navbar-left': {
        // max the width of the scope text on small screens so it doesn't overflow to the next line
        // +1 so it doesn't overflow the line
        maxWidth: `calc(100% - ${NAVBAR_RIGHT_MIN_WIDTH + 1}px)`,
      },
      '.navbar-right': {
        minWidth: NAVBAR_RIGHT_MIN_WIDTH,
      },
    },
  }
);

const headerbarRightLink = style(
  media(
    {
      type: 'print',
    },
    {
      display: 'none',
    }
  ),
  {
    $debugName: 'headerbarRightLink',
    display: 'inline-block',
    color: '#3A3A3E',
    paddingLeft: '10px',
    $nest: {
      '&.disabled:hover': {
        color: 'gray',
      },
      '&.clickable': {
        cursor: 'pointer',
      },
      '&.disabled': {
        color: important('gray'),
        cursor: 'not-allowed',
      },
    },
  }
);

const filterPopover = style({
  $nest: {
    '.popover-body': {
      maxHeight: `calc(100vh - 150px)`,
      paddingLeft: 0,
      minWidth: 175,
      overflow: 'auto',
      $nest: {
        ul: {
          marginBottom: 'auto',
        },
      },
    },
  },
});

const iconRightSpace = style({
  $debugName: 'iconRightSpace',
  paddingRight: '5px',
});

const selectorDropDown = style({
  listStyleType: 'none',
  width: '100%',
  margin: 0,
  paddingLeft: 0,
  $nest: {
    li: {
      width: '100%',
      height: 30,
      padding: 5,
      cursor: 'pointer',
      $nest: {
        '&:hover': {
          background: '#eee',
        },
      },
    },
  },
});

const smallSpinner = style({
  display: 'inline-block',
  width: 12,
  height: 12,
  $nest: {
    '&::after': {
      content: '" "',
      display: 'block',
      width: 10,
      height: 10,
      margin: '0px',
      border: '2px solid #898989',
      borderRadius: '50%',
      animationName: spinnerFrames,
      animationDuration: '1.2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      borderColor: '#898989 transparent #898989 transparent',
    },
  },
});

export function buildFilterIconClass(shouldFiltersBeEnabled: boolean) {
  let possibleClass;
  if (shouldFiltersBeEnabled) {
    possibleClass = style({
      $debugName: 'activeFilter',
      color: TEAL_PRIMARY,
    });
  }
  return classes('fas fa-filter', iconRightSpace, possibleClass);
}

const marqueAnimation = keyframes({
  '0%': { left: '0%' },
  '20%': { left: '0%' },
  '100%': { left: '-40%' },
});

const marqueLi = style({
  width: 130,
  overflow: 'hidden',
  $nest: {
    a: {
      width: 200,
      position: 'relative',
      animationName: marqueAnimation,
      animationDuration: '6s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      overflow: 'hidden',
      $nest: {
        span: {
          width: '100%',
        },
      },
    },
  },
});

export default {
  headerbarStyle,
  headerbarRightLink,
  filterPopover,
  iconRightSpace,
  selectorDropDown,
  smallSpinner,
  marqueLi,
};
