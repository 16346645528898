import { Dispatch } from 'react-redux';
import {
  updateGroupByNoDataTrigger,
  updateSortBy,
  maybeUpdateSortByDirection,
  updatePareDown,
  updateFavoritesList,
  updateCountLimit,
  ReduxSlice as SubheaderSlice,
} from '../Subheader/Subheader.slice';
import { AppState } from 'src/store';
import store from 'src/store';
import { TOP_DOWN, TOP_DOWN_IDENTIFIER } from 'src/utils/Domain/Constants';
import { FavoriteListItemStorage, FavoriteResponseItem } from '../Subheader/Favorites/FavoritesMenu';
import { isNumber } from 'lodash';

const updateSubheader = (config: FavoriteListItemStorage, subheader: SubheaderSlice, dispatch?: Dispatch<AppState>) => {
  if (dispatch) {
    if (isNumber(config.groupBySelection) && config.groupBySelection >= 0) {
      if (config.groupByDataIndexSelection) {
        const selection = subheader.groupBy.options.findIndex((x) => x.dataIndex === config.groupByDataIndexSelection);
        dispatch(updateGroupByNoDataTrigger(selection));
      } else {
        dispatch(updateGroupByNoDataTrigger(config.groupBySelection));
      }
    }
    if (isNumber(config.sortBySelection) && config.sortBySelection >= 0) {
      if (config.sortByDataIndexSelection) {
        const selection = subheader.sortBy.options.findIndex((x) => x.dataIndex === config.sortByDataIndexSelection);
        dispatch(updateSortBy(selection));
      } else {
        dispatch(updateSortBy(config.sortBySelection));
      }
    }
    if (config.sortByDirection) {
      dispatch(maybeUpdateSortByDirection(config.sortByDirection));
    }
    if (config.pareDownSelections) {
      dispatch(updatePareDown(config.pareDownSelections));
    }
    if (isNumber(config.limitSelection) && config.limitSelection >= 0) {
      dispatch(updateCountLimit(config.limitSelection));
    }
  }
};

export const updateGroupSortViaLocal = (defnId: string | null, dispatch: Dispatch<AppState>) => {
  const config = defnId !== null ? localStorage.getItem(defnId) : undefined;
  const subheaderState = store.getState().subheader;
  if (config) {
    const localConfig: FavoriteListItemStorage = JSON.parse(config);
    updateSubheader(localConfig, subheaderState, dispatch);
  }
};

export const getLocalConfig = (
  defnStr: string | null,
  favoritesList: FavoriteResponseItem[],
  dispatch?: Dispatch<AppState>
) => {
  const state = store.getState();
  const perspective = state.perspective.selected;
  const subheaderState = state.subheader;
  if (!perspective) {
    return;
  }
  let defnId = defnStr;
  if (perspective.value === TOP_DOWN) {
    defnId = TOP_DOWN_IDENTIFIER + defnId;
  }

  const activeFavorite = favoritesList.find((x) => x.active);

  if (dispatch) {
    dispatch(updateFavoritesList(favoritesList));
  }
  if (activeFavorite && activeFavorite.jsonBlob) {
    const localConfig = activeFavorite.jsonBlob;
    updateSubheader(localConfig, subheaderState, dispatch);
    return localConfig;
  }
  return;
};
