import * as React from 'react';
import * as AgGrid from 'ag-grid-community';

import { parseOverTimeTree, TreeRowData } from 'src/utils/Tree/OvertimeTreeHandler';
import { get, isNil, isEqual, isEmpty, last } from 'lodash';
import AgGridHeaderBreakClass from 'src/utils/Style/AgGridThemeBreakHeaders';
import { classes, style } from 'typestyle';
import {
  makeGridContainerStyle,
  gridListPairStyle,
  makeGridContainerStyleSpecWidth,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { OvertimeConfig } from './OvertimeView.types';
import { px } from 'csx';
import { ExportOptions } from 'src/common-ui/components/DataGrid/DataGrid';
import { GetMainMenuItemsParams, ColumnResizedEvent } from 'ag-grid-community';
import { CustomNoRowsOverlay } from 'src/components/ConfigurableGrid/ConfigurableGrid';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { generateColDefs } from './OvertimeView.utils';

type Props = {
  aggBys: string[];
  treeData: BasicPivotItem[];
  viewConfig: OvertimeConfig;
  search?: string;
  companionOpen?: boolean;
  hasCompanion?: boolean;
  exportOptions?: ExportOptions | undefined;
  rowHeight?: number;
};

type State = {
  columnDefs?: AgGrid.ColDef[];
  rowData?: TreeRowData[];
  aggBys?: string[];
  metrics?: string[];
  defaultColDef: AgGrid.ColDef;
  groupDefaultExpanded: -1;
  getDataPath: (data: TreeRowData) => string[];
  getRowNodeId: (data: TreeRowData) => string;
  autoGroupColumnDef: AgGrid.ColDef;
  components: unknown;
};

const removeCheckMargin = style({
  $nest: {
    '.ag-group-checkbox': {
      display: 'none',
    },
  },
});

const bottomHeader = style({
  $nest: {
    '.ag-header-cell > :not(.group-child).ag-cell-label-container': {
      top: 0,
      padding: px(15),
    },
    '.ag-header-cell-label > .ag-header-icon': {
      height: 'auto',
    },
  },
});

function getMenuItems(params: GetMainMenuItemsParams) {
  return params.defaultItems.filter((item) => item !== 'autoSizeAll');
}

export class OvertimeView extends React.Component<Props, State> {
  gridApi: AgGrid.GridApi | null | undefined;
  gridColumnApi: AgGrid.ColumnApi | null | undefined;
  constructor(props: Props) {
    super(props);
    this.state = {
      defaultColDef: {
        sortable: false,
        resizable: true,
        filter: true,
        width: 150,
      },
      components: {},
      groupDefaultExpanded: -1,
      getDataPath: function(data: TreeRowData) {
        return data.path;
      },
      getRowNodeId: function(data: TreeRowData): string {
        return data.path.join(' ');
      },
      // TODO: This should probably come from viewdefn >.>
      autoGroupColumnDef: {
        headerName: 'Name',
        width: 250,
        field: 'name',
        pinned: true,
        cellRendererParams: {
          checkbox: false,
          suppressCount: true,
        },
      },
    };
  }

  parseTreeData = async () => {
    const { aggBys, viewConfig, treeData, search } = this.props;
    const metrics = viewConfig.columns.map((i) => ({
      key: i.dataIndex,
      name: i.text,
      formatter: i.renderer,
      editable: i.editable,
    }));

    if (isNil(aggBys) || isNil(metrics)) {
      return;
    }

    const colDefLevels: string[] = get(viewConfig, 'timeLevels.colDef', ['month', 'member:week:description']);
    const dataLevels: string[] = get(viewConfig, 'timeLevels.data', ['month', 'week']);
    // the tree level at which to retrieve metric values
    const metricsTimeLevel = last(dataLevels) ?? '';
    const columnDefs = generateColDefs(colDefLevels, treeData);
    const rowData = parseOverTimeTree(treeData, aggBys, metrics, metricsTimeLevel, search);
    this.setState({
      rowData,
      columnDefs,
      aggBys,
    });
  };

  componentDidMount() {
    if (!isEmpty(this.props.treeData)) {
      this.parseTreeData();
    }
  }

  componentDidUpdate(oldProps: Props) {
    if (!isEqual(oldProps, this.props) && !isEmpty(this.props.treeData)) {
      this.parseTreeData();
    }
  }

  onGridReady = (params: AgGrid.GridReadyEvent) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  render() {
    if (isNil(this.state.rowData)) {
      return (
        <div style={{ margin: '0 auto' }}>
          <CustomNoRowsOverlay />
        </div>
      );
    }

    const containerClass = this.props.hasCompanion
      ? makeGridContainerStyle(!this.props.companionOpen)
      : makeGridContainerStyleSpecWidth(0);
    const groupBolding = style({
      $nest: {
        '.ag-row-group': {
          fontWeight: 'bold',
        },
      },
    });
    return (
      <div
        id="myGrid"
        className={classes(
          'ag-theme-material',
          'data-grid',
          'double-header',
          AgGridHeaderBreakClass,
          removeCheckMargin,
          containerClass,
          bottomHeader,
          groupBolding
        )}
      >
        <div className={gridListPairStyle}>
          <ExtendedDataGrid
            columnDefs={this.state.columnDefs!}
            data={this.state.rowData}
            rowHeight={this.props.rowHeight}
            onGridReady={this.onGridReady}
            loaded={true}
            exportOptions={{
              customHeader: !isNil(this.props.exportOptions) ? this.props.exportOptions.customHeader : '',
              fileName: !isNil(this.props.exportOptions) ? this.props.exportOptions.fileName : '',
            }}
            extraAgGridProps={{
              defaultColDef: this.state.defaultColDef,
              treeData: true,
              animateRows: true,
              groupDefaultExpanded: this.state.groupDefaultExpanded,
              getDataPath: this.state.getDataPath,
              getRowNodeId: this.state.getRowNodeId,
              autoGroupColumnDef: this.state.autoGroupColumnDef,
              getMainMenuItems: getMenuItems,
              suppressMovableColumns: true,
              onColumnResized: (event: ColumnResizedEvent) => {
                if (event.finished && event.api) {
                  event.api.redrawRows();
                }
              },
            }}
          />
        </div>
      </div>
    );
  }
}
