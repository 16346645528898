import { keyframes, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';

const openPos = { left: `calc(100% - 25rem)` };
const closedPos = { left: '101%' };

const slideIn = keyframes({
  $debugName: 'slideIn',
  from: closedPos,
  to: openPos,
});

const slideOut = keyframes({
  $debugName: 'slideOut',
  from: openPos,
  to: closedPos,
});

export function rightContainer(dataPresent: boolean) {
  let options: NestedCSSProperties = {
    $debugName: 'rightContainer',
    position: 'fixed',
    top: 40,
    zIndex: 100,
    left: '100%',
    backgroundColor: 'white',
    height: '96%',
    borderLeft: '3px solid #ddd',
    width: '25rem',
    animationDuration: '.4s',
  };
  if (dataPresent) {
    options = {
      ...options,
      ...openPos,
      animationName: slideIn,
    };
  }
  if (!dataPresent) {
    options = {
      ...options,
      ...closedPos,
      animationName: slideOut,
    };
  }
  return style(options);
}

const stylePane = style({
  $debugName: 'stylePane',
  height: '100%',
  overflow: 'auto',
});

const toggleStateIcon = style({
  $debugName: 'togglePane',
  marginTop: `calc(50vh - 16px)`,
});

const rightContainerCloseIcon = style({
  $debugName: 'rightContainerCloseIcon',
  marginRight: 10,
  $nest: {
    i: {
      cursor: 'pointer',
    },
  },
});

function styleHeaderSpacer(isStyleInfo: boolean) {
  return style({
    height: isStyleInfo ? '85px' : '50px',
  });
}

function styleHeaderContainer(isStyleInfo: boolean) {
  return style({
    $debugName: 'rightContainerActionsContainer',
    width: '23.9rem',
    position: 'fixed',
    display: 'flex',
    height: isStyleInfo ? 'auto' : '50px',
    justifyContent: isStyleInfo ? 'space-between' : 'flex-end',
    background: '#ffffff',
    paddingLeft: '1.1rem',
    paddingTop: '20px',
    zIndex: isStyleInfo ? 'initial' : 1,
  });
}

const headerText = style({
  fontWeight: 'bold',
  $nest: {
    '& .title': {
      fontSize: '20px',
      wordBreak: 'break-all',
    },
    '& .description': {
      fontSize: '12px',
      wordBreak: 'break-all',
    },
  },
});

const headerActions = style({
  display: 'flex',
  alignItems: 'baseline',
});

export default {
  styleHeaderContainer,
  styleHeaderSpacer,
  rightContainer,
  stylePane,
  toggleStateIcon,
  rightContainerCloseIcon,
  headerText,
  headerActions,
};
