import React, { ReactElement } from 'react';

import { DataGrid, Overlay } from 'src/common-ui';
import { DataGridProps, Gridable } from 'src/common-ui/components/DataGrid/DataGrid';
import SubheaderDropdown from 'src/components/Subheader/SubheaderDropdown';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { summaryContainer, summarySubheader, summaryContent } from './QRCategorySummary.styles';
import { StateProjection } from './QRCategorySummary.selectors';
import { SubheaderDropdownProps } from '../../Subheader/SubheaderDropdown';
import Axios from 'src/services/axios';
import { CancelTokenSource, default as AxiosStatic } from 'axios';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { RegionItem } from 'src/worker/pivotWorker.types';
import { DataApiConfig } from 'src/services/configuration/codecs/confdefnView';

export type PerformanceDetails = {
  columnDefs: ColDef[];
  data: Gridable[];
};

type QRCategorySummaryProps = {
  groupByDefn: string;
  configApi: DataApiConfig;
  floorsetApi: DataApiConfig;
  subheaderCustomEl: JSX.Element | undefined;
};

type FloorsetViewItem = TenantConfigViewItem & {
  id: string;
};

export type FunctionProps = {
  onShowView(): void;
  onUpdateView(): void;
  onRefetchData(): void;
  onDestroy(): void;
  onUpdateGroupBy(newGroup: number): void;
  onUpdateFloorset(newFloorset: string): void;
};

export type Props = StateProjection & QRCategorySummaryProps & FunctionProps;

export type State = {
  subheaderDropdowns: SubheaderDropdownProps[];
  floorsetDropdownData: FloorsetViewItem[];
  filterLoaded: boolean;
  selectedGroupBy: number;
  selectedFloorset: number;
};

export default class QRCategorySummary extends React.Component<Props, State> {
  canceler: CancelTokenSource;
  constructor(props: Props) {
    super(props);
    this.canceler = AxiosStatic.CancelToken.source();
    this.state = {
      subheaderDropdowns: [],
      floorsetDropdownData: [],
      filterLoaded: false,
      selectedGroupBy: 1,
      selectedFloorset: 0,
    };
  }

  componentDidMount() {
    this.onRefetchData();
    this.props.onShowView();
  }

  componentDidUpdate() {
    this.props.onUpdateView();
  }

  componentWillUnmount() {
    this.props.onDestroy();
  }

  async onRefetchData() {
    this.setState({
      filterLoaded: false,
    });

    const { onUpdateGroupBy, onUpdateFloorset } = this.props;
    const reqs1 = [
      Axios.get(this.props.configApi.url, {
        // UNUSED: headers: this.props.quickReconConf.configApi.headers,
        params: this.props.configApi.params,
        cancelToken: this.canceler.token,
      }),
      Axios.get(this.props.floorsetApi.url, {
        headers: this.props.floorsetApi.headers,
        params: this.props.floorsetApi.params,
        cancelToken: this.canceler.token,
      }),
    ];

    Promise.all(reqs1).then((response) => {
      this.setState(
        {
          subheaderDropdowns: response[0].data.data.subheaderDropdowns,
          floorsetDropdownData: response[1].data.data.map((data: RegionItem) => ({ ...data, text: data.description })),
          filterLoaded: true,
          selectedGroupBy: response[0].data.data.subheaderDropdowns[0].value,
          selectedFloorset: 0,
        },
        () => {
          onUpdateGroupBy(0);
          onUpdateFloorset(this.state.floorsetDropdownData[0].id);
        }
      );
    });
  }

  handleGroupByClickDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectedGroupBy: +event.target.value,
    });

    const { onUpdateGroupBy, groupBy } = this.props;
    if (+event.target.value !== groupBy.selection) {
      onUpdateGroupBy(+event.target.value);
    }
  };

  handleFloorsetClickDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { floorsetDropdownData } = this.state;
    const index = +event.target.value;
    let selectedFloorset;
    if (floorsetDropdownData[index]) {
      selectedFloorset = floorsetDropdownData[index].id;
    } else {
      selectedFloorset = floorsetDropdownData[0].id;
    }

    this.setState({
      selectedFloorset: index,
    });

    const { onUpdateFloorset, floorset } = this.props;

    if (selectedFloorset !== floorset) {
      onUpdateFloorset(selectedFloorset);
    }
  };

  render() {
    let mainContent: ReactElement | null = null;
    let groupByDropDown: ReactElement | null = null;
    let floorsetDropDown: ReactElement | null = null;
    const { groupBy, floorsetEnabled } = this.props;

    if (this.state.filterLoaded) {
      groupByDropDown = (
        <SubheaderDropdown
          defaultSelection={0}
          selection={this.state.selectedGroupBy}
          options={groupBy.options}
          label={'Group By: '}
          handleChangeOnDropdown={this.handleGroupByClickDropdown}
        />
      );

      floorsetDropDown = floorsetEnabled ? (
        <SubheaderDropdown
          defaultSelection={0}
          selection={this.state.selectedFloorset}
          options={this.state.floorsetDropdownData}
          label={'Floorset: '}
          handleChangeOnDropdown={this.handleFloorsetClickDropdown}
        />
      ) : null;
    }

    if (this.props.loaded) {
      const { gridData, colDefs, frameworkComponents, isPrintMode } = this.props;

      const gridOptions: DataGridProps = {
        isPrintMode,
        columnDefs: colDefs,
        frameworkComponents,
        data: gridData,
        loaded: true,
        rowHeight: 30,
        treeColumnDefinition: undefined,
        className: 'summaryGrid',
      };

      mainContent = <DataGrid {...gridOptions} />;
    }

    return (
      <div className={summaryContainer}>
        <Overlay type="loading" visible={!!this.props.isViewDataLoading} />
        <div className={summarySubheader}>
          <div className="dropdown">{floorsetDropDown}</div>
          <div className="dropdown">{groupByDropDown}</div>
        </div>
        <div className={summaryContent}>{mainContent}</div>
      </div>
    );
  }
}
