import { style } from 'typestyle';
import { negativeTrend, positiveTrend, fadedNeutral, primaryTextColor } from '../../../../theme';

export const keyFinancialStyle = style({
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 300,
  color: primaryTextColor,
  $nest: {
    '> header': {
      alignSelf: 'flex-start',
      fontSize: '0.8rem',
      fontWeight: 600,
      padding: '20px 0 0 20px',
    },
    '.bottom-container': {
      flex: 1,
      display: 'flex',
    },
    '.right-container': {
      paddingRight: 20,
    },
    '.primary': {
      flex: 1,
      fontSize: '1.5rem',
      paddingLeft: 20,
    },
    '.dir': {
      fontSize: '1.2rem',
      margin: '0 0.3rem 0 0',
    },
    '.up': {
      color: positiveTrend,
    },
    '.down': {
      color: negativeTrend,
    },
    '.diff': {
      fontSize: '1.2rem',
    },
    '.secondary': {
      fontSize: '0.6rem',
      textAlign: 'end',
      color: fadedNeutral,
      fontWeight: 500,
    },
  },
});
