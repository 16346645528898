import { style, media } from 'typestyle';
import { borderHighlightColor, TEAL_PRIMARY } from 'src/utils/Style/Theme';
export const IMGWIDTH = 150;
export const COLWIDTH = 38;
export const COLHEIGHT = 33;
export const TOPHEIGHT = IMGWIDTH * 1.5;
export const BOTTOMHEIGHT = 67; // based on fontSize,
export const HORPADDING = 18;
export const FONTCOLOR = '#464646';
export const BORDERCLASS = 'column-border';
export const BORDERWIDTH = 1;
export const starDimension = '1rem';
export const starSpacing = '0.1rem';
const popoverHeader = 'popover-header';

export default {
  card: style(
    media(
      { type: 'print' },
      {
        position: 'static',
      }
    ),
    {
      height: '100%',
      display: 'inline-flex',
      justifyContent: 'space-evenly',
      position: 'relative',
      fontSize: '.6em',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingRight: HORPADDING / 2,
      paddingLeft: HORPADDING / 2,
      border: '1px solid transparent',
      $nest: {
        '& i.cardSelectedState': {
          position: 'absolute',
          top: 30,
          left: 25,
          fontSize: '1rem',
          color: '#8e8e8e',
        },
        '& .worklist-icon': {
          float: 'right',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 4,
          opacity: 0.8,
        },
      },
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
    }
  ),
  getHoverStyle: (isBlankCard: boolean) => {
    if (isBlankCard) {
      return style({});
    }
    return style({
      $nest: {
        '&:hover': {
          borderWidth: BORDERWIDTH,
          borderColor: borderHighlightColor,
          borderStyle: 'solid',
        },
      },
    });
  },
  cardWithPopper: style({
    cursor: 'pointer',
  }),

  infoRow: style({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignContent: 'space-around',
  }),

  stars: style({
    flex: 2,
  }),

  value: style({
    flex: 1,
    textAlign: 'right',
    fontWeight: 'bold',
  }),

  description: style({
    textAlign: 'left',
    width: '100%',
    fontWeight: 600,
    maxHeight: '4.2em',
    lineHeight: '1.4em',
    overflow: 'hidden',
  }),

  column: style({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: TOPHEIGHT,
    paddingLeft: 10,
    textAlign: 'center',
  }),

  colItem: style({
    height: 37,
    width: COLWIDTH,
    fontSize: '.5rem',
    position: 'relative',
    color: FONTCOLOR,
    overflow: 'hidden',
    $nest: {
      [`& > .${BORDERCLASS}`]: {
        position: 'absolute',
        left: 0,
        height: TOPHEIGHT,
        top: 0,
        width: '100%',
      },
      [`&:nth-child(6n + 1) > ${BORDERCLASS}`]: {
        borderRight: '1px dotted #ddd',
      },
      [`&:first-of-type > ${BORDERCLASS}`]: {
        borderLeft: '1px dotted #ddd',
      },
    },
  }),

  infoContainer: style({
    display: 'flex',
    width: '100%',
    height: '100%',
  }),
  popper: style(
    {
      maxWidth: 1000,
      borderRadius: 10,
      $nest: {
        [`.${popoverHeader}`]: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      },
      zIndex: 9999,
      visibility: 'visible',
    },
    media(
      { minWidth: 700 },
      {
        $nest: {
          '& > [role=document]': {
            width: 500,
          },
        },
      }
    )
  ),
  popperClosed: style({
    pointerEvents: 'none',
  }),
  popoverHeader,
  cardOverlay: style({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'white',
    opacity: 0.4,
    zIndex: 1,
    pointerEvents: 'none',
  }),
  iconOverlay: style({
    position: 'absolute',
    fontSize: '3rem',
    color: TEAL_PRIMARY,
    top: 80,
    left: 55,
    zIndex: 2,
    background: 'white',
    borderRadius: 60,
  }),
  worklistCard: style({
    position: 'absolute',
    top: 0,
    left: 5,
  }),
};
