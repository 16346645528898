import Axios from 'src/services/axios';
import FullAxios from 'axios';
import { CriteriaReduxSlice, LYTargetData } from './Criteria.types';
import { FloorsetReduxSlice } from '../TargetCreation/FloorsetSelector';
import { capitalize, isString, isEqual, get, isNil, set } from 'lodash/fp';
import {
  TargetRequest,
  TargetSettingConfiguration,
  TargetSettingConfigColumn,
} from '../TargetSetting/TargetSetting.types';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { prefixRequestInterceptor } from 'src/services/axios/prefixRequests';
import { omitBy, omit } from 'lodash';
import moment from 'moment';

const appName = 'Assortment';
const validMembersUrl = '/api/member/authorizedMembers';
const validAttributesUrl = '/api/attribute/getAllValidAttributes';
const getHierarchyUrl = '/api/member/getParents';

type getValidMembersArgs = {
  member: string;
  parent?: string;
};
export type validAttributesParams = {
  member: string;
  topLevel: string | null;
  topLevelId: string | null;
};

export async function getValidAttributes(args: validAttributesParams) {
  const { member, topLevel, topLevelId } = args;
  return Axios.get(validAttributesUrl, {
    params: {
      appName,
      attributeId: member,
      topMemberLevel: topLevel,
      topMember: topLevelId,
    },
  }).then((resp) => {
    return resp.data.data.map((item: string) => {
      return {
        name: item,
        id: item,
        description: item,
      };
    });
  });
}
export async function getHierarchy(dimension: string, bottomLevel?: string): Promise<string[]> {
  const params: Record<string, any> = {
    dimensionId: dimension,
    bottomLevel: bottomLevel,
  };
  return (await Axios.get<{ data: string[] }>(getHierarchyUrl, { params: omitBy(params, isNil) })).data.data;
}
export async function getTargetSettingViewConf(targetType: TargetType) {
  const client = container.tenantConfigClient;
  const confName = targetType === 'Product' ? 'StrategyProductTargetSetting' : 'StrategyLocationTargetSetting';
  const viewConf = (
    await client.getTenantViewDefns<TargetSettingConfiguration>({
      defnIds: [confName],
      appName: ASSORTMENT,
    })
  )[0];
  return viewConf;
}

function omitRequest(req: TargetRequest) {
  const omittedKeys = ['floorSet', 'lastUpdated'];
  return omitBy(req, (_value, key) => {
    return key.indexOf('Name') >= 0 || omittedKeys.indexOf(key) >= 0;
  });
}

export async function getTargetInitialInfo(setup: TargetRequest, targetType: TargetType, aggBy: string) {
  const viewConf = await getTargetSettingViewConf(targetType);
  const rowData: LYTargetData[] = await Axios.post(
    '/api/assortment/getLYTargetData',
    {
      ...omitRequest(setup),
      aggBy: `${aggBy}`,
    },
    {
      params: {
        appName: 'Assortment',
        defnId: viewConf.model,
      },
    }
  ).then((resp): LYTargetData[] => {
    const balance = {
      ...omit(get('[0]', resp.data.data), 'children'),
      name: 'Balance',
      description: 'Miscellaneous',
    };
    return [...get('[0].children', resp.data.data), balance];
  });
  // rowData = Mocks.RowDataFactory.createConfRowData(20);

  rowData.forEach((row: LYTargetData) => {
    viewConf.grid.columns.forEach((column: TargetSettingConfigColumn) => {
      if (column.initial) {
        row[column.dataIndex] = row[column.initial];
      }
    });
  });
  return {
    viewConf: viewConf,
    rowData,
  };
}
export async function getMFPData(setup: TargetRequest, targetType: TargetType): Promise<number | null> {
  const viewConf = await getTargetSettingViewConf(targetType);
  const rowData = await Axios.post(
    '/api/assortment/getLYTargetData',
    {
      ...omitRequest(setup),
      aggBy: 'level:prodrootlevel',
    },
    {
      params: {
        appName: 'Assortment',
        defnId: viewConf.seedModels.mfp,
      },
    }
  ).then((resp): number => {
    return get('[0].mfp_ttlslsr', resp.data.data);
  });
  return rowData as number;
}
export async function getLYData(setup: TargetRequest, targetType: TargetType): Promise<number | null> {
  const viewConf = await getTargetSettingViewConf(targetType);
  const rowData: number = await Axios.post(
    '/api/assortment/getLYTargetData',
    {
      ...omitRequest(setup),
      aggBy: 'level:prodrootlevel',
    },
    {
      params: {
        appName: 'Assortment',
        defnId: viewConf.seedModels.ly,
      },
    }
  ).then((resp): number => {
    return get('[0].ly_ttlslsr', resp.data.data);
  });
  return rowData as number;
}
export async function getValidMembers(args: getValidMembersArgs) {
  const { member, parent } = args;
  return Axios.get(validMembersUrl, {
    params: {
      appName,
      level: member,
      parent,
    },
  }).then((resp) => {
    return resp.data.data;
  });
}

type TargetSettingRespItem = {
  id: string;
  type: TargetType;
  version: TargetVersion;
  data: {
    type: 'jsonb';
    value: LYTargetData;
  };
};
const targetSetting = '/api/targetSetting';
export type TargetType = 'Location' | 'Product';
export type TargetVersion = 'OP' | 'WP' | 'RP';
type GetTargetListArgs = {
  type: TargetType;
  version?: TargetVersion;
};
export async function getTargetList(args: GetTargetListArgs) {
  const { type, version } = args;
  return Axios.get<{ data: TargetSettingRespItem[] }>(`${targetSetting}/${capitalize(type)}`).then((resp) => {
    return resp.data.data
      .map((item: TargetSettingRespItem) => {
        const data = isString(item.data.value) ? JSON.parse(item.data.value) : item.data.value;

        return {
          id: item.id,
          type: item.type,
          version: item.version,
          data: data,
        };
      })
      .filter((it) => (version != null ? it.version === version : true));
  });
}

type GetTargetItemArgs = {
  type: TargetType;
  version: TargetVersion;
  id: string;
  skipErrorToast?: boolean;
};
type TargetResultsType = {
  data: { data: TargetSettingRespItem };
};
export async function getTargetItem(args: GetTargetItemArgs) {
  const { type, version, id, skipErrorToast } = args;
  let reqClient;
  if (skipErrorToast) {
    reqClient = FullAxios.create({});
    reqClient.interceptors.request.use(prefixRequestInterceptor);
  } else {
    reqClient = Axios;
  }
  return reqClient
    .get(`${targetSetting}/${capitalize(type)}/${id}/${version}`)
    .then(({ data: par }: TargetResultsType) => {
      if (isEqual(par.data, []) || isEqual(par.data, {})) {
        return null;
      }
      const item = par.data;
      const data = isString(item.data.value) ? JSON.parse(item.data.value) : item.data.value;

      return {
        id: item,
        type: item.type,
        version: item.version,
        data: data,
      };
    });
}
type UpsertTargetItemArgs = GetTargetItemArgs & {
  // tslint:disable-next-line:no-any
  data: any;
};
export async function upsertTargetItem(args: UpsertTargetItemArgs) {
  const { type, version, id } = args;
  return Axios.post(
    `${targetSetting}/${capitalize(type)}/${id}/${version}`,
    set('targetSetup.lastUpdated', moment().format('YYYY-MM-DD HH:mm:ss'), args.data),
    {
      headers: {
        'Content-Type': 'text/plain',
      },
      responseType: 'text',
    }
  );
}

type DeleteTargetItem = {
  id: string;
  type: TargetType;
};
export async function deleteTargetItem(args: DeleteTargetItem) {
  const { type, id } = args;
  return Axios.delete(`${targetSetting}/${capitalize(type)}/${id}`);
}

type createTargetKeyArgs = {
  floorset: FloorsetReduxSlice;
  topLevel: CriteriaReduxSlice;
  targetLevel: CriteriaReduxSlice;
};
export function createTargetKey(args: createTargetKeyArgs) {
  const { floorset, topLevel, targetLevel } = args;
  return `${topLevel.selections[0].dataIndex}_${topLevel.selections[1].dataIndex}_${targetLevel.selections[0].dataIndex}_${floorset.selectedFloorset}`;
}
