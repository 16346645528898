import { z } from 'zod';
import {
  AssortmentAddViewComponentProps,
  AssortmentMatrixComponentProps,
  AssortmentPublishComponentProps,
  CanvasViewComponentProps,
  CategorySummaryComponentProps,
  CollectionViewComponentProps,
  ColumnGroupedViewComponentProps,
  EnhancedOvertimeComponentProps,
  GridViewComponentProps,
  HistoryGridComponentProps,
  NestedAttributeComponentProps,
  NestedOvertimeComponentProps,
  ParameterTogglesComponentProps,
  PerformanceComponentProps,
  PricingAndFlowSheetComponentProps,
  ProductDetailsComponentProps,
  ProductivityComponentProps,
  ProductMixComponentProps,
  QuickTrendsComponentProps,
  SizeEligibilityListGridComponentProps,
  StyleEditComponentProps,
  SummaryViewComponentProps,
  TargetListComponentProps,
  TopPerformersComponentProps,
  WorklistComponentProps,
} from './confdefnComponentProps';
import { BaseSectionView } from './confdefnView';
import { ProplessComponents } from './literals';
import { isNil } from 'lodash';
import { AppState } from 'src/store';
import { isWorklistTabWithComponentProps } from 'src/pages/AssortmentBuild/Worklist/Worklist.types';

const ProplessComponent = BaseSectionView.merge(
  z.object({
    component: ProplessComponents,
    // propless components are given an empty object to make the types easier
    // SPIKE: move this to BaseSectionView?
    componentProps: z.object({}).default({}),
  })
);

const CollectionViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('CollectionView'),
    componentProps: CollectionViewComponentProps,
  })
);
export type CollectionViewComponent = z.infer<typeof CollectionViewComponent>;

const CanvasViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('CanvasView'),
    componentProps: CanvasViewComponentProps,
  })
);
export type CanvasViewComponent = z.infer<typeof CanvasViewComponent>;

const SummaryViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('SummaryView'),
    componentProps: SummaryViewComponentProps,
  })
);
export type SummaryViewComponent = z.infer<typeof SummaryViewComponent>;

const GridViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('GridView'),
    componentProps: GridViewComponentProps,
  })
);
export type GridViewComponent = z.infer<typeof GridViewComponent>;

const FlowTypeComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('FlowType'),
    componentProps: ColumnGroupedViewComponentProps,
  })
);
export type FlowTypeComponent = z.infer<typeof FlowTypeComponent>;

const QuickTrendsComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('QuickTrends'),
    componentProps: QuickTrendsComponentProps,
  })
);
export type QuickTrendsComponent = z.infer<typeof QuickTrendsComponent>;

const TopTyLyComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('TopTYvsLY'),
    componentProps: ColumnGroupedViewComponentProps,
  })
);
export type TopTyLyComponent = z.infer<typeof TopTyLyComponent>;

const CategorySummaryComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('CategorySummary'),
    componentProps: CategorySummaryComponentProps,
  })
);
export type CategorySummaryComponent = z.infer<typeof CategorySummaryComponent>;

const ProductMixComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ProductMix'),
    componentProps: ProductMixComponentProps,
  })
);
export type ProductMixComponent = z.infer<typeof ProductMixComponent>;

const ProductivityComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('Productivity'),
    componentProps: ProductivityComponentProps,
  })
);
export type ProductivityComponent = z.infer<typeof ProductivityComponent>;

const NestedAttributeComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('NestedAttribute'),
    componentProps: NestedAttributeComponentProps,
  })
);
export type NestedAttributeComponent = z.infer<typeof NestedAttributeComponent>;

const ProductDetailsComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ProductDetails'),
    componentProps: ProductDetailsComponentProps,
  })
);
export type ProductDetailsComponent = z.infer<typeof ProductDetailsComponent>;

const WorklistComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('Worklist'),
    componentProps: WorklistComponentProps,
  })
);
export type WorklistComponent = z.infer<typeof WorklistComponent>;

const FloorsetComparisonComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('FloorsetComparison'),
    componentProps: ColumnGroupedViewComponentProps,
  })
);
export type FloorsetComparisonComponent = z.infer<typeof FloorsetComparisonComponent>;

const StyleEditComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('StyleEdit'),
    componentProps: StyleEditComponentProps,
  })
);
export type StyleEditComponent = z.infer<typeof StyleEditComponent>;

const PricingComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('PricingOverTime'),
    componentProps: PricingAndFlowSheetComponentProps,
  })
);
export type PricingComponent = z.infer<typeof PricingComponent>;

const FlowSheetComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('FlowSheetByStyle'),
    componentProps: PricingAndFlowSheetComponentProps,
  })
);
export type FlowSheetComponent = z.infer<typeof FlowSheetComponent>;

const StyleAttributesComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('StyleAttributes'),
    componentProps: AssortmentMatrixComponentProps,
  })
);
export type StyleAttributesComponent = z.infer<typeof StyleAttributesComponent>;

const StyleColorAttributesComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('StyleColorAttributes'),
    componentProps: AssortmentMatrixComponentProps,
  })
);
export type StyleColorAttributesComponent = z.infer<typeof StyleColorAttributesComponent>;

const LineByFloorsetComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('LinePlanByFloorset'),
    componentProps: AssortmentMatrixComponentProps,
  })
);
export type LineByFloorsetComponent = z.infer<typeof LineByFloorsetComponent>;

const AssortmentAddViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('AssortmentAddView'),
    componentProps: AssortmentAddViewComponentProps,
  })
);
export type AssortmentAddViewComponent = z.infer<typeof AssortmentAddViewComponent>;

const EnhancedOvertimeComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('EnhancedOvertime'),
    componentProps: EnhancedOvertimeComponentProps,
  })
);
export type EnhancedOvertimeComponent = z.infer<typeof EnhancedOvertimeComponent>;

const NestedOvertimeComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('NestedOvertime'),
    componentProps: NestedOvertimeComponentProps,
  })
);
export type NestedOvertimeComponent = z.infer<typeof NestedOvertimeComponent>;

const NestedStyleOvertimeComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('NestedStyleOvertime'),
    componentProps: NestedOvertimeComponentProps,
  })
);
export type NestedStyleOvertimeComponent = z.infer<typeof NestedStyleOvertimeComponent>;

const AssortmentPublishComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('AssortmentPublish'),
    componentProps: AssortmentPublishComponentProps,
  })
);
export type AssortmentPublishComponent = z.infer<typeof AssortmentPublishComponent>;

const TargetListComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('TargetList'),
    componentProps: TargetListComponentProps,
    // FIXME: this needs to be moved into componentProps
    allowNesting: z.boolean(),
  })
);
export type TargetListComponent = z.infer<typeof TargetListComponent>;

const SizeEligibilityListGridComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('SizeEligibilityListGrid'),
    componentProps: SizeEligibilityListGridComponentProps,
  })
);
export type SizeEligibilityListGridComponent = z.infer<typeof SizeEligibilityListGridComponent>;

const ParameterTogglesComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ParameterToggles'),
    componentProps: ParameterTogglesComponentProps,
  })
);
export type ParameterTogglesComponent = z.infer<typeof ParameterTogglesComponent>;

const ParetoSummaryComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ParetoSummary'),
    componentProps: PerformanceComponentProps,
  })
);
export type ParetoSummaryComponent = z.infer<typeof ParetoSummaryComponent>;

const ParetoDetailsComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ParetoDetails'),
    componentProps: PerformanceComponentProps,
  })
);
export type ParetoDetailsComponent = z.infer<typeof ParetoDetailsComponent>;

const TopPerformersComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('TopPerformers'),
    componentProps: TopPerformersComponentProps,
  })
);
export type TopPerformersComponent = z.infer<typeof TopPerformersComponent>;

const NestedViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('NestedView'),
    componentProps: HistoryGridComponentProps,
  })
);
export type NestedViewComponent = z.infer<typeof NestedViewComponent>;

const ListViewComponent = BaseSectionView.merge(
  z.object({
    component: z.literal('ListView'),
    componentProps: HistoryGridComponentProps,
  })
);
export type ListViewComponent = z.infer<typeof ListViewComponent>;

// TODO: would like to intersect RouteComponentProps here instead of at each individual componets OwnProp declaration
// see this issue: https://github.com/colinhacks/zod/issues/53
// const RoutePropsSchema = toZod(RouteComponentProps);
// then .merge/.and(RoutPropsSchema)
export const SectionView = z.union([
  ProplessComponent,
  CollectionViewComponent,
  CanvasViewComponent,
  SummaryViewComponent,
  GridViewComponent,
  FlowTypeComponent,
  TopTyLyComponent,
  CategorySummaryComponent,
  ProductMixComponent,
  ProductivityComponent,
  NestedAttributeComponent,
  ProductDetailsComponent,
  WorklistComponent,
  FloorsetComparisonComponent,
  StyleEditComponent,
  PricingComponent,
  FlowSheetComponent,
  StyleAttributesComponent,
  StyleColorAttributesComponent,
  LineByFloorsetComponent,
  AssortmentAddViewComponent,
  NestedOvertimeComponent,
  NestedStyleOvertimeComponent,
  AssortmentPublishComponent,
  TargetListComponent,
  SizeEligibilityListGridComponent,
  ParameterTogglesComponent,
  ParetoDetailsComponent,
  ParetoSummaryComponent,
  TopPerformersComponent,
  NestedViewComponent,
  ListViewComponent,
  EnhancedOvertimeComponent,
  QuickTrendsComponent,
]);
export type SectionViewConf = z.infer<typeof SectionView>;

/**
 * Used to determine the name value of the desired component if used in `ofComponentType`.
 * The string value needs to map directly to the `component` property value in the component validator
 */
export enum ConfDefnComponentType {
  collectionView = 'CollectionView',
  canvasView = 'CanvasView',
  summaryView = 'SummaryView',
  gridView = 'GridView',
  flowType = 'FlowType',
  topTyLy = 'TopTYvsLY',
  categorySummary = 'CategorySummary',
  productMix = 'ProductMix',
  productivity = 'Productivity',
  nestedAttribute = 'NestedAttribute',
  productDetails = 'ProductDetails',
  worklist = 'Worklist',
  floorsetComparison = 'FloorsetComparison',
  styleEdit = 'StyleEdit',
  pricing = 'Pricing',
  flowSheet = 'FlowSheet',
  styleAttributes = 'StyleAttributes',
  styleColorAttributes = 'StyleColorAttributes',
  linePlanByFloorset = 'LinePlanByFloorset',
  assortmentAddView = 'AssortmentAddView',
  nestedOvertime = 'NestedOvertime',
  nestedStyleOverTime = 'NestedStyleOvertime',
  assortmentPublish = 'AssortmentPublish',
  targetList = 'TargetList',
  sizeEligibility = 'SizeEligibilityListGrid',
  parameterToggles = 'ParameterToggles',
  paretoSummary = 'ParetoSummary',
  paretoDetails = 'ParetoDetails',
  topPerformers = 'TopPerformers',
  nestedView = 'NestedView',
  listView = 'ListView',
  visualize = 'Visualize',
  enhancedOvertime = 'EnhancedOvertime',
  quickTrends = 'QuickTrends',
  bulkImport = 'BulkImport',
  configurableGrid = 'ConfigurableGrid',
}

function isOfType<T>(confType: ConfDefnComponentType, sectionView: T | undefined): sectionView is T {
  return !isNil(sectionView) && 'component' in sectionView && sectionView['component'] === confType;
}

/**
 * A partially dynamic helper method that removes the need for individual type predicates for each component above.
 * This is mainly used in epics to narrow down the types of the componentProps (a.k.a ownProps).
 *
 * e.g. isComponentType(ConfDefnComponentType.canvasView)
 *
 * @param {ConfDefnComponentType} componentType - the mapped name of the component type to verify.
 * @param {any} conf - the conf of the activePage or activeSubPage; the type is really a `SectionViewConf`
 *
 * @returns boolean
 */
export function isOfComponentType<T>(type: ConfDefnComponentType, conf: any): conf is T {
  return isOfType<T>(type, conf);
}

export function maybeGetComponentProps<T extends SectionViewConf>(
  state: AppState,
  componentType: ConfDefnComponentType
): T['componentProps'] | null {
  const activePageConf = state.perspective.activePageConf;
  const activeSubPage = state.perspective.activeSubPage;
  const worklistConf = state.pages.assortmentBuild.worklist;

  if (isOfComponentType<T>(componentType, activePageConf)) {
    return activePageConf.componentProps;
  } else if (isOfComponentType<WorklistComponent>(ConfDefnComponentType.worklist, activePageConf)) {
    if (activeSubPage && worklistConf && worklistConf.viewDefn) {
      const tabConf = worklistConf.viewDefn.tabs.find((tab) => {
        return tab.componentType === activeSubPage;
      });
      if (tabConf != null && tabConf.componentType === componentType && isWorklistTabWithComponentProps(tabConf)) {
        return {
          ...tabConf.componentProps,
          topMembers: worklistConf.selectedItem,
        };
      }
    }
  }

  return null;
}

export function isWorklistActive(state: AppState): boolean {
  const activePageConf = state.perspective.activePageConf;
  return isOfComponentType<WorklistComponent>(ConfDefnComponentType.worklist, activePageConf);
}

/**
 * This only detects correctly when navigating from worklist to non worklist of the same component type
 *
 * i.e Worklist -> Summary View Tab to Summary View
 */
export function isSameComponentType<T extends SectionViewConf>(
  state: AppState,
  componentType: ConfDefnComponentType
): boolean {
  const activePageConf = state.perspective.activePageConf;
  const activeSubPage = state.perspective.activeSubPage;

  const isActivePage = isOfComponentType<T>(componentType, activePageConf);
  const isActiveSubPage = activeSubPage === componentType;

  return isActivePage && isActiveSubPage;
}
