import * as React from 'react';
import { DispatchProps, StateProps } from './Reporting.container';
import { ReportConfig, Report } from 'src/dao/reportClient';
import { reportingStyle } from 'src/pages/Reporting/Reporting.styles';
import { ReportCard } from 'src/components/Reporting/ReportCard';
import { Overlay } from 'src/common-ui/index';

type Props = DispatchProps & StateProps;

function parseReportConfig(reportConfig: ReportConfig) {
  const { reportGroups, reports } = reportConfig;
  const reportLookup = reports.reduce((acc, next) => {
    acc.set(next.id, next);
    return acc;
  }, new Map());

  return reportGroups.map((group) => {
    return {
      ...group,
      items: group.items.map((item) => reportLookup.get(item)),
    };
  });
}
const DEFAULT_PARAMS = 'DEFAULT_PARAMS';

export class Reporting extends React.Component<Props> {
  componentDidMount() {
    this.props.onShowView();
  }

  render() {
    const { reporting } = this.props;

    if (reporting.isLoading) {
      return <Overlay type="loading" visible={true} />;
    }
    const reportGroups = parseReportConfig(reporting.reportConfig);
    const reportClasses = 'reporting ' + reportingStyle;
    const findParams = (key: string) => {
      // this really only returns the default params
      // TODO make it return non-defaults if present
      if (key === DEFAULT_PARAMS) {
        return reporting.reportConfig.paramGroups[0].defaultParams;
      }
      return reporting.reportConfig.paramGroups.find((g) => g.id === key);
    };

    return (
      <div className={reportClasses}>
        {reportGroups.map((group, i) => {
          return (
            <div className="group" key={i}>
              <header>{group.name}</header>
              <div className="items">
                {group.items.map((item: Report, j) => {
                  item.params = findParams(item.paramGroup);
                  return <ReportCard key={j} {...item} perspective={this.props.perspective} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
