import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FavoritesButton,
  FavoritesList,
  FavoriteListItem,
  FavoriteListItemStorage,
  FavoriteResponseItem,
} from './FavoritesMenu';
import { FavoritesSaveModal } from './FavoritesSaveModal';
import { FavoritesDeleteModal } from './FavoritesDeleteModal';
import { FavoritesService, FavoritesServiceType } from 'src/services/favorites/Favorites.service';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { findIndex, isNil, isEmpty } from 'lodash';
import { clearFavoriteActives, deleteFavorite, postFavorite, setFavoriteActive } from './Favorites.client';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { DEFAULT_FAVORITE_ID } from './Favorites.constants';
import { GroupBySlice, PareDownSlice, SortBySlice } from '../Subheader.slice';
import { BasicItem } from 'src/types/Scope';
import { Validator } from 'src/utils/Validators/Validator';
import { provideAppName } from 'src/utils/Domain/Perspective';

type SubheaderControlHandlers = {
  setGroupBySelection(value: number | null): void;
  setSortBySelection(value: number | null): void;
  setSortByDirection(): void;
  setPareDownSelections?(value: TenantConfigViewItem[]): void;
  setCountLimit(value: number): void;
};

type SubheaderControlValues = {
  groupBy: GroupBySlice;
  sortBy: SortBySlice;
  pareDown: PareDownSlice;
  showCountLimit: boolean | undefined;
  countLimit: number | undefined;
  countLimitOptions: number[] | undefined;
  countLimitDefault: number | undefined;
  groupByDefn: TenantConfigViewData | undefined;
  sortByDefn: TenantConfigViewData | undefined;
  pareDownDefn: TenantConfigViewData | undefined;
};

type FavoritesProps = {
  viewConfigurator: ViewConfiguratorModalProps;
  favoritesList: FavoriteResponseItem[] | undefined;
  favoritesSaveOverride: FavoriteListItemStorage | undefined;
  getFavoritesList: (defnId: string) => void;
  setFavoritesList: (list: FavoriteResponseItem[]) => void;
  subheaderHandlers: SubheaderControlHandlers;
  subheaderValues: SubheaderControlValues;
};

export const Favorites = ({
  viewConfigurator,
  favoritesList = [],
  getFavoritesList,
  setFavoritesList,
  favoritesSaveOverride,
  subheaderHandlers,
  subheaderValues,
}: FavoritesProps) => {
  const favoritesService = useRef<FavoritesServiceType>();
  const favoritesButtonRef = useRef();

  const [listOpen, setListOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState<FavoriteListItem>({} as FavoriteListItem);

  // TODO: verify this behavior
  const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
  useEffect(() => {
    provideAppName(async (appName) => {
      if (!isEmpty(favId) && !isNil(favId)) {
        // If it is a TD favorite, try to find TD_ favorites to upload, but upload them without the TD_
        let favIdTemp = favId,
          localFavorites: FavoriteListItem[] = [];
        if (appName === 'TDAnalysis') {
          if (favId.indexOf('TD_') === -1) {
            favIdTemp = 'TD_' + favId;
            favoritesService.current = FavoritesService(favIdTemp);
            localFavorites = favoritesService.current.getFavorites();
          } else {
            favoritesService.current = FavoritesService(favId);
            localFavorites = favoritesService.current.getFavorites();
            favIdTemp = favId.replace('TD_', '');
          }
        } else {
          favoritesService.current = FavoritesService(favId);
          localFavorites = favoritesService.current.getFavorites();
        }

        if (localFavorites.length > 0) {
          const postPromises: Promise<any>[] = [];
          localFavorites.forEach((fav) => {
            postPromises.push(postFavorite(viewConfigurator, fav, false));

            if (favoritesService.current) {
              favoritesService.current.removeFavorite(fav.id);
            }
          });
          postPromises.push(clearFavoriteActives(favId));
          Promise.all(postPromises).then(() => {
            getFavoritesList(favId);
          });
        }
      }
    });
  }, [favId, getFavoritesList, viewConfigurator]);

  const onFavoritesClick = useCallback(
    (elem) => {
      favoritesButtonRef.current = elem;
      setListOpen(!listOpen);
    },
    [listOpen]
  );

  const onListClose = useCallback(() => setListOpen(false), []);

  const onListSave = useCallback(() => {
    setSaveModalOpen(true);
    setListOpen(false);
  }, []);

  const onListDelete = useCallback((favItem: FavoriteListItem) => {
    setListOpen(false);
    setDeleteModalOpen(true);
    setDeleteItem(favItem);
  }, []);

  const onApplyFavorite = useCallback(
    async (favItem: FavoriteListItem, key: string) => {
      const {
        setGroupBySelection,
        setSortBySelection,
        setSortByDirection,
        setPareDownSelections,
        setCountLimit,
      } = subheaderHandlers;
      const {
        pareDown,
        pareDownDefn,
        showCountLimit,
        countLimitDefault,
        countLimitOptions,
        sortBy,
        sortByDefn,
        groupBy,
        groupByDefn,
      } = subheaderValues;

      if (favoritesService.current) {
        favoritesService.current.applyFavorite(favItem);
      }

      if (favItem.config) {
        favItem.config.isDefault = favItem.id === DEFAULT_FAVORITE_ID;
      }
      if (favItem.id === DEFAULT_FAVORITE_ID) {
        // Check if applying 'Default View' and add page specific defaults
        if (pareDownDefn && pareDown && pareDown.defaultSelections) {
          favItem.pareDownSelections = pareDown.defaultSelections;
        }
        if (viewConfigurator.defaultConfigurationSelections) {
          favItem.configurationSelections = viewConfigurator.defaultConfigurationSelections;
        }
        if (countLimitDefault) {
          favItem.limitSelection = countLimitDefault;
        }
        if (sortByDefn && sortBy && sortBy.defaultSelection) {
          favItem.sortBySelection = sortBy.defaultSelection;
        }
        if (groupByDefn && groupBy && groupBy.defaultSelection) {
          favItem.groupBySelection = groupBy.defaultSelection;
        }
        const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
        await clearFavoriteActives(favId);
        // Cheating instead of getting new favorites again
        setFavoritesList(favoritesList.map((x) => ({ ...x, active: false })));
      } else {
        await setFavoriteActive(viewConfigurator, favItem);
        // Cheat the new active in, as the endpoint doesn't return anything but we know it worked
        const index = favoritesList.findIndex((x) => x.key == key);
        const newList = favoritesList.map((x, i) => ({ ...x, active: i == index }));
        setFavoritesList(newList);
      }

      if (viewConfigurator && viewConfigurator.updateConfig) {
        if (favoritesSaveOverride) {
          viewConfigurator.updateConfig({
            ...favItem.config,
            ...favItem,
          });
        } else {
          viewConfigurator.updateConfig(favItem.config);
        }
      }

      if (viewConfigurator && viewConfigurator.updateConfiguration && favItem.configurationSelections) {
        viewConfigurator.updateConfiguration(favItem.configurationSelections);
      }

      if (groupByDefn && !isEmpty(favItem.groupByDataIndexSelection)) {
        favItem.groupBySelection = findIndex(
          groupBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === favItem.groupByDataIndexSelection
        );
        setGroupBySelection(favItem.groupBySelection);
      } else if (groupByDefn && !isNil(favItem.groupBySelection) && favItem.groupBySelection > -1) {
        setGroupBySelection(favItem.groupBySelection);
      } else if (groupByDefn) {
        favItem.groupBySelection = findIndex(
          groupBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === groupByDefn.default
        );
        setGroupBySelection(favItem.groupBySelection != -1 ? favItem.groupBySelection : null);
      }

      if (sortByDefn && !isEmpty(favItem.sortByDataIndexSelection)) {
        favItem.sortBySelection = findIndex(
          sortBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === favItem.sortByDataIndexSelection
        );
        setSortBySelection(favItem.sortBySelection);
      } else if (sortByDefn && !isNil(favItem.sortBySelection) && favItem.sortBySelection > -1) {
        setSortBySelection(favItem.sortBySelection);
      } else if (sortByDefn) {
        favItem.sortBySelection = findIndex(
          sortBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === sortByDefn.default
        );
        setSortBySelection(favItem.sortBySelection != -1 ? favItem.sortBySelection : null);
      }

      if (!isNil(favItem.sortByDirection) && favItem.sortByDirection != sortBy.direction) {
        setSortByDirection();
      }

      if (pareDownDefn && favItem.pareDownSelections && setPareDownSelections) {
        setPareDownSelections(favItem.pareDownSelections);
      }

      if (showCountLimit) {
        if (favItem.limitSelection) {
          setCountLimit(favItem.limitSelection);
        } else {
          const defaultIndex = (countLimitOptions || []).findIndex((option) => option === countLimitDefault);
          setCountLimit((countLimitOptions || [])[defaultIndex]);
        }
      }

      setListOpen(false);
    },
    [favoritesList, favoritesSaveOverride, setFavoritesList, subheaderHandlers, subheaderValues, viewConfigurator]
  );

  const onSave = useCallback(
    async (item: BasicItem) => {
      const { sortBy, groupBy } = subheaderValues;
      let newFavItem: FavoriteListItem = {
        id: item.id,
        name: item.name,
        config: viewConfigurator.viewConfig,
        sortBySelection:
          subheaderValues.sortBy && !isNil(subheaderValues.sortBy.selection)
            ? subheaderValues.sortBy.selection
            : undefined,
        groupBySelection:
          subheaderValues.groupBy && !isNil(subheaderValues.groupBy.selection)
            ? subheaderValues.groupBy.selection
            : undefined,
        sortByDataIndexSelection: sortBy.selection ? sortBy.options[sortBy.selection].dataIndex : undefined,
        groupByDataIndexSelection: groupBy.selection
          ? groupBy.options[groupBy.selection]?.dataIndex || undefined
          : undefined,
        sortByDirection:
          subheaderValues.sortBy && subheaderValues.sortBy.direction ? subheaderValues.sortBy.direction : undefined,
        configurationSelections: viewConfigurator.configurationSelections,
        pareDownSelections: subheaderValues.pareDown && subheaderValues.pareDown.selections,
        limitSelection: subheaderValues.countLimit,
        companionData: viewConfigurator.companionData,
      };

      newFavItem = {
        ...newFavItem,
        ...favoritesSaveOverride,
      };

      await postFavorite(viewConfigurator, newFavItem, true);
      getFavoritesList(favId!);
      setSaveModalOpen(false);
    },
    [favId, favoritesSaveOverride, getFavoritesList, subheaderValues, viewConfigurator]
  );

  const onDelete = useCallback(
    async (fav: FavoriteListItem) => {
      const key = favoritesList.find((x) => x.favorite_name == fav.id)!.key;
      await deleteFavorite(key);
      getFavoritesList(favId!);
      setDeleteModalOpen(false);
    },
    [favId, favoritesList, getFavoritesList]
  );

  const isFavoriteActive = favoritesList.some((fav) => fav.active);

  return (
    <React.Fragment>
      <FavoritesButton isFavoriteActive={isFavoriteActive} onClick={onFavoritesClick} />
      {listOpen && (
        <FavoritesList
          unmodifiedViewDefn={viewConfigurator.unmodifiedViewDefn as TenantConfigViewData}
          defaultCompanionData={viewConfigurator.defaultCompanionData}
          favItems={favoritesList}
          onClose={onListClose}
          onClickSave={onListSave}
          onClickDelete={onListDelete}
          onApplyFavorite={onApplyFavorite}
          open={listOpen}
          anchorEl={favoritesButtonRef.current}
        />
      )}
      {saveModalOpen && (
        <FavoritesSaveModal
          isOpen={saveModalOpen}
          onSave={onSave}
          onClose={() => setSaveModalOpen(false)}
          nameValidator={Validator.name}
        />
      )}
      {deleteModalOpen && (
        <FavoritesDeleteModal
          fav={deleteItem}
          isOpen={deleteModalOpen}
          onDelete={onDelete}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};
