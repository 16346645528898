import { connect, Dispatch } from 'react-redux';
import Subheader from './Subheader';
import { AppState } from 'src/store';
import {
  cleanUp,
  updateSearch,
  requestViewDefns,
  receiveViewDefns,
  updateGroupBy,
  updateSortBy,
  updateFlowStatus,
  updateSortByDirection,
  updateLookBackPeriod,
  updateCountLimit,
  updateAlternateSearch,
  updateAlternateFlowStatus,
  updatePareDown,
  updateFavoritesList,
  receiveError,
} from './Subheader.slice';
import { ReduxSlice } from 'src/components/Subheader/Subheader.slice';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { TenantConfig } from 'src/services/configuration/bindings.types';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { FavoriteResponseItem } from './Favorites/FavoritesMenu';
import { BackgroundDataLoadingProps } from '../BackgroundDataLoading/BackgroundDataLoading';
import { getFavoriteByDefnId } from './Favorites/Favorites.client';

export type StateProps = {
  subheader: ReduxSlice;
  lookBackPeriods: TenantConfig['lookBackPeriods'];
  flowStatusOptions: TenantConfig['flowStatus'];
  hideTitle?: boolean;
} & BackgroundDataLoadingProps;

export type DispatchProps = {
  getViewDefns(defns: (string | null)[]): void;
  updateSearchString(value: string): void;
  updateAltSearchString(value: string): void;
  updateFlowStatus(values: number[]): void;
  updateAltFlowStatus(values: number[]): void;
  updateLookBackPeriod(value: string): void;
  setGroupBySelection(value: number | null): void;
  setSortBySelection(value: number | null): void;
  setSortByDirection(): void;
  setPareDownSelections(value: TenantConfigViewItem[]): void;
  setCountLimit(value: number): void;
  getFavoritesList(defnId: string): void;
  setFavoritesList(list: FavoriteResponseItem[]): void;
  onDestroy(): void;
};

export function mapStateToProps(state: AppState, ownProps: any): StateProps {
  return {
    subheader: state.subheader,
    lookBackPeriods: state.appConfig.tenantConfig.lookBackPeriods,
    flowStatusOptions: state.appConfig.tenantConfig.flowStatus,
    viewDataState: ownProps.viewDataState,
    hideTitle: ownProps.hideTitle,
  };
}

export function dispatchToProps<S>(dispatch: Dispatch<S>): DispatchProps {
  const client = container.tenantConfigClient;
  return {
    getViewDefns(defns: string[]) {
      dispatch(requestViewDefns(defns));
      client
        .getTenantViewDefns({
          defnIds: defns,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          dispatch(
            receiveViewDefns({
              groupBy: resp[0],
              sortBy: resp[1],
              countLimit: resp[2],
              pareDown: resp[3],
            })
          );
        })
        .catch((_error) => dispatch(receiveError("Couldn't load view definitions")));
    },
    updateSearchString(value: string) {
      dispatch(updateSearch(value));
    },
    updateAltSearchString(value: string) {
      dispatch(updateAlternateSearch(value));
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateFlowStatus(values));
    },
    updateAltFlowStatus(values: number[]) {
      dispatch(updateAlternateFlowStatus(values));
    },
    updateLookBackPeriod(value: string) {
      dispatch(updateLookBackPeriod(value));
    },
    setGroupBySelection(value: number | null) {
      dispatch(updateGroupBy(value));
    },
    setSortBySelection(value: number | null) {
      dispatch(updateSortBy(value));
    },
    setPareDownSelections(values: TenantConfigViewItem[]) {
      dispatch(updatePareDown(values));
    },
    setSortByDirection() {
      dispatch(updateSortByDirection());
    },
    setCountLimit(value: number) {
      dispatch(updateCountLimit(value));
    },
    setFavoritesList(list: FavoriteResponseItem[]) {
      dispatch(updateFavoritesList(list));
    },
    getFavoritesList(defnId: string) {
      getFavoriteByDefnId(defnId).then((resp) => {
        if (resp) dispatch(updateFavoritesList(resp.data.data as FavoriteResponseItem[]));
      });
    },
    onDestroy() {
      dispatch(cleanUp());
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(Subheader);
