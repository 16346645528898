import { AppState } from 'src/store';
import { createSelector, OutputSelector, OutputParametricSelector } from 'reselect';
import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { parseActions, parseFloorsetDropdownData } from './ConfigurableGrid.utils';
import { ClientActionHandler, ClientActionHandlersConfig, ConfigurableGridColumnDef } from './ConfigurableGrid.types';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import { cloneDeep, findIndex, isEmpty, isNil } from 'lodash';
import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { filterData } from 'src/utils/Pivot/Filter';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { ConfigurableGridGroupBySelection } from './ConfigurableGrid.slice';

export type SelectorSubheaderDropdownProps = Omit<SubheaderDropdownProps, 'handleChangeOnDropdown'>;

interface ViewDefnData {
  dependentCalcs: Record<string, any>;
  companionSortOptions: SortOption[];
  defaultCompanionSortField?: string;
  clientActionHandlers: ClientActionHandler;
}

type ViewDefnDataSelector = OutputSelector<
  AppState,
  ViewDefnData,
  (columnDefs: ConfigurableGridColumnDef[], sortConfig: any, actions: ClientActionHandlersConfig) => ViewDefnData
>;

type GroupByDropdownPropsSelector = OutputSelector<
  AppState,
  SelectorSubheaderDropdownProps | undefined,
  (
    groupBySelection: ConfigurableGridGroupBySelection | undefined,
    dropdownConfig: SelectorSubheaderDropdownProps | undefined
  ) => SelectorSubheaderDropdownProps | undefined
>;

type FloorsetDropdownPropsSelector = OutputSelector<
  AppState,
  SelectorSubheaderDropdownProps | undefined,
  (
    floorsets: BasicItem[],
    selectedFloorset: TenantConfigViewItem | undefined
  ) => SelectorSubheaderDropdownProps | undefined
>;

type GridDataSelector = OutputParametricSelector<
  AppState,
  boolean,
  BasicPivotItem[],
  (gridData: BasicPivotItem[], flowStatus: number[], showFlowStatus: boolean, search: string) => BasicPivotItem[]
>;

const getColumnDefs = (state: AppState): ConfigurableGridColumnDef[] | undefined => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.columnDefs;
};

const getCompanionSortConfig = (state: AppState) => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.companionGrid.sortConfig;
};

const getActions = (state: AppState): ClientActionHandlersConfig | undefined => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.actions;
};

const getGroupBySelection = (state: AppState) => {
  return state.pages.assortmentBuild.configurableGrid.groupBySelection;
};

const getGroupByDropdownConfig = (state: AppState): SelectorSubheaderDropdownProps | undefined => {
  return state.pages.assortmentBuild.configurableGrid.viewDefn?.subheaderDropdowns[0];
};

const getFloorsetData = (state: AppState): BasicItem[] => {
  return state.pages.assortmentBuild.configurableGrid.floorsetData;
};

const getSelectedFloorset = (state: AppState): TenantConfigViewItem | undefined => {
  return state.pages.assortmentBuild.configurableGrid.floorsetSelection;
};

const getGridData = (state: AppState): BasicPivotItem[] => {
  return state.pages.assortmentBuild.configurableGrid.gridData;
};

const getFlowStatus = (state: AppState): number[] => {
  return state.subheader.flowStatus;
};

const getSearch = (state: AppState): string => {
  return state.subheader.search;
};

const passShowFlowStatusParam = (state: AppState, showFlowStatus: boolean): boolean => {
  return showFlowStatus;
};

export const getViewDefnData: ViewDefnDataSelector = createSelector(
  getColumnDefs,
  getCompanionSortConfig,
  getActions,
  (columnDefs, sortConfig, actions) => {
    // generate dependent calc object
    const dependentCalcs = {};
    if (!isNil(columnDefs)) {
      columnDefs.forEach((colDef) => {
        if (colDef.dependentCalc) {
          dependentCalcs[colDef.dataIndex] = colDef.dependentCalc;
        }
      });
    }

    // generate companion view props
    const companionSortOptions = (sortConfig?.view || []) as SortOption[];
    const companionSortSelected: SortOption = companionSortOptions.find(
      (opt) => opt.dataIndex === sortConfig?.default
    ) ||
      companionSortOptions[0] || { dataIndex: 'id', text: 'ID' };
    const defaultCompanionSortField = companionSortSelected.dataIndex;

    // generate actionHandlers
    const clientActionHandlers = !isNil(actions) ? parseActions(actions) : {};
    return {
      dependentCalcs,
      companionSortOptions,
      defaultCompanionSortField,
      clientActionHandlers,
    };
  }
);

export const getGroupByDropdownProps: GroupByDropdownPropsSelector = createSelector(
  getGroupBySelection,
  getGroupByDropdownConfig,
  (groupBySelection, dropdownConfig) => {
    if (isNil(dropdownConfig)) {
      return;
    }

    const configCopy = cloneDeep(dropdownConfig);
    configCopy.selection = groupBySelection?.selectedIndex || dropdownConfig.defaultSelection;
    return configCopy;
  }
);

export const getFloorsetDropdownProps: FloorsetDropdownPropsSelector = createSelector(
  getFloorsetData,
  getSelectedFloorset,
  (floorsets, selectedFloorset) => {
    if (isEmpty(floorsets)) {
      return;
    }

    const options = parseFloorsetDropdownData(floorsets);
    const selection = isNil(selectedFloorset)
      ? 0
      : findIndex(options, (option) => option.text === selectedFloorset.text);

    const floorsetDropdownProps: SelectorSubheaderDropdownProps = {
      label: 'Floorset',
      defaultSelection: 0,
      selection,
      options,
    };

    return floorsetDropdownProps;
  }
);

export const getConfigurableGridData: GridDataSelector = createSelector(
  getGridData,
  getFlowStatus,
  passShowFlowStatusParam,
  getSearch,
  (gridData, flowStatus, showFlowStatus, search) => {
    const show = isNil(showFlowStatus) ? false : showFlowStatus;
    const finalFlowStatus = show ? flowStatus : [];
    return filterData(gridData, search, externalGridSearchFields, finalFlowStatus);
  }
);
