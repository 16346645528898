import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';

export type ViewDefns = {
  groupByDefn: TenantConfigViewData;
  gridDefn: TenantConfigViewData;
  floorsetDefn?: TenantConfigViewData;
};

export type DropdownConfigViewData = TenantConfigViewData & {
  hideEmptyRow?: boolean;
};

export type SubheaderDefns = {
  groupBy: DropdownConfigViewData;
  sortBy?: DropdownConfigViewData;
  countLimit?: DropdownConfigViewData;
};

export type ViewData = {
  gridData: BasicPivotItem[];
};

export type Action =
  | { type: 'REQUEST_QRCATEGORY_SUMMARY_CONFIGS' }
  | { type: 'RECEIVE_QRCATEGORY_SUMMARY_CONFIGS'; configs: ViewDefns }
  | { type: 'REQUEST_QRCATEGORY_SUMMARY_DATA' }
  | { type: 'RECEIVE_QRCATEGORY_SUMMARY_DATA'; data: ViewData }
  | { type: 'RECEIVE_QRCATEGORY_ERROR'; message: string }
  | { type: 'CLEAN_UP_QRCATEGORY_SUMMARY' }
  | { type: 'RECEIVE_QRCATEGORY_VIEW_DEFNS'; defns: SubheaderDefns }
  | { type: 'UPDATE_QRGROUP_BY'; groupBy: number }
  | { type: 'UPDATE_QRFLOORSET_BY'; floorset: string };

export const RECEIVE_QRCATEGORY_SUMMARY_ERROR = 'RECEIVE_QRCATEGORY_ERROR';
export function receivePageError(message: string): Action {
  return {
    type: RECEIVE_QRCATEGORY_SUMMARY_ERROR,
    message,
  };
}

export const CLEAN_UP_QRCATEGORY_SUMMARY = 'CLEAN_UP_QRCATEGORY_SUMMARY';
export function cleanUp(): Action {
  return {
    type: CLEAN_UP_QRCATEGORY_SUMMARY,
  };
}

export const REQUEST_QRCATEGORY_SUMMARY_CONFIGS = 'REQUEST_QRCATEGORY_SUMMARY_CONFIGS';
export function requestQRCategorySummaryConfigs(): Action {
  return {
    type: REQUEST_QRCATEGORY_SUMMARY_CONFIGS,
  };
}

export const RECEIVE_QRCATEGORY_SUMMARY_CONFIGS = 'RECEIVE_QRCATEGORY_SUMMARY_CONFIGS';
export function receiveQRCategorySummaryConfig(configs: ViewDefns): Action {
  return {
    type: RECEIVE_QRCATEGORY_SUMMARY_CONFIGS,
    configs,
  };
}

export const REQUEST_QRCATEGORY_SUMMARY_DATA = 'REQUEST_QRCATEGORY_SUMMARY_DATA';
export function requestQRCategorySummaryData(): Action {
  return {
    type: REQUEST_QRCATEGORY_SUMMARY_DATA,
  };
}

export const RECEIVE_QRCATEGORY_SUMMARY_DATA = 'RECEIVE_QRCATEGORY_SUMMARY_DATA';
export function receiveQRCategorySummaryData(data: ViewData): Action {
  return {
    type: RECEIVE_QRCATEGORY_SUMMARY_DATA,
    data,
  };
}
export const RECEIVE_QRCATEGORY_VIEW_DEFNS = 'RECEIVE_QRCATEGORY_VIEW_DEFNS';
export function receiveQRCategoryViewDefns(defns: SubheaderDefns): Action {
  return {
    type: RECEIVE_QRCATEGORY_VIEW_DEFNS,
    defns,
  };
}

export const UPDATE_QRGROUP_BY = 'UPDATE_QRGROUP_BY';
export function updateQRCategoryGroupBy(groupBy: number): Action {
  return {
    type: UPDATE_QRGROUP_BY,
    groupBy,
  };
}
export const UPDATE_QRFLOORSET_BY = 'UPDATE_QRFLOORSET_BY';
export function updateQRCategoryFloorset(floorset: string): Action {
  return {
    type: UPDATE_QRFLOORSET_BY,
    floorset,
  };
}
