import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateGroupBy } from 'src/components/Subheader/Subheader.slice';
import { fetchGridViewData } from './GridView.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/inputIsNotNullOrUndefined';
import { isNil } from 'lodash';
import {
  ConfDefnComponentType,
  GridViewComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { updateSelectedItem } from 'src/pages/AssortmentBuild/Worklist/Worklist.slice';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getAggBys, ExtraPivotOptions, organizeListDataOptions } from '../StyleColorReview.slice';

export const gridViewLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(updateGroupBy.type, updateSelectedItem.type),
    map(() => maybeGetComponentProps<GridViewComponent>(state$.value, ConfDefnComponentType.gridView)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns, topMembers }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const aggBy = getAggBys(state$.value.subheader, dataApi);
      const options: ExtraPivotOptions = !isNil(topMembers) ? { topMembers, aggBy } : { aggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      return of(fetchGridViewData(finalModelDefn, finalOptions));
    })
  );
};
