import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { BoundPerspective } from "src/services/configuration/bindings.types";
import { SectionViewConf } from "src/services/configuration/codecs/confdefnComponents";

export interface NavigationShellSlice {
  /** Active Perspective */
  selected: BoundPerspective | null;
  activeTab: string;
  activePage: string;
  /** For sections within a view that we want to hold navigation state for, such as opened accordions or tabs */
  activeSubPage: string,
  activePageConf?: SectionViewConf
}

const initialState: NavigationShellSlice = {
  selected: null,
  activeTab: '',
  activePage: '',
  activeSubPage: ''
};

const navigationShellReducer = createSlice({
  name: 'NavigationShell',
  initialState: initialState,
  reducers: {
    setActivePerspective: (state, action: PayloadAction<BoundPerspective>) => {
      state.selected = action.payload
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
    setActivePage: (state, action: PayloadAction<string>) => {
      state.activePage = action.payload
    },
    setActiveSubPage: (state, action: PayloadAction<string>) => {
      state.activeSubPage = action.payload
    },
    setActivePageComponentPropsConf: (state, action: PayloadAction<SectionViewConf | undefined>)=> {
      state.activePageConf = action.payload;
    }
  }
});

export const {
  setActivePerspective,
  setActiveTab,
  setActivePage,
  setActiveSubPage,
  setActivePageComponentPropsConf
} = navigationShellReducer.actions;

export default navigationShellReducer.reducer;