import Axios from 'src/services/axios';
import queryString from 'query-string';

import { AppType } from 'src/types/Domain';
import { ObjectDeserializer } from 'src/dao/objectDeserializer';
import { SubmitPayload } from 'src/worker/pivotWorker.types';

const listDataPath = '/api/pivot2/listData';
const flowSheetPath = '/api/v2/flowsheet';
const pricingPath = '/api/v2/overtime';
const coarseEditPath = '/api/property/update/coarse';
const granularEditPath = '/api/property/update/granular';
// replace MassEditPayload
export type CoarseEditPayload = {
  coordinates: {
    [s: string]: string;
  }[];
  [s: string]: unknown;
};
export type GranularEditPayloadItem = {
  coordinates: {
    [s: string]: string;
  };
  [s: string]: unknown;
};

export type PivotClient = {
  objectDeserializer: ObjectDeserializer;
  gridData(pivotQuery: Record<string, any>): Promise<Record<string, any>>;
  flowSheetSubmitData(payload: SubmitPayload, query: { appName: AppType }): Promise<boolean>;
  pricingSubmitData(payload: SubmitPayload, query: { appName: AppType }): Promise<boolean>;
  coarseEditSubmitData(payload: CoarseEditPayload): Promise<boolean>;
  granularEditSubmitData(payload: GranularEditPayloadItem[]): Promise<boolean>;
};

export function makePivotClient(objectDeserializer: ObjectDeserializer): PivotClient {
  const pivotClient: PivotClient = {
    gridData(pivotQuery: Record<string, any>) {
      const paramStr = queryString.stringify(pivotQuery);
      return Axios.get(`${listDataPath}?${paramStr}`).then((resp) => {
        return objectDeserializer.deserialize(resp.data);
      });
    },
    flowSheetSubmitData(payload: SubmitPayload, query: { appName: AppType }) {
      const paramStr = queryString.stringify(query);
      const url = `${flowSheetPath}?${paramStr}`;

      return Axios.post<{ success: boolean }>(url, payload).then((resp) => resp.data.success);
    },
    pricingSubmitData(payload: SubmitPayload, query: { appName: AppType }) {
      const paramStr = queryString.stringify(query);
      const url = `${pricingPath}?${paramStr}`;

      return Axios.post<{ success: boolean }>(url, payload).then((resp) => resp.data.success);
    },
    coarseEditSubmitData(payload: CoarseEditPayload) {
      const url = `${coarseEditPath}`;
      return Axios.post(url, payload).then(() => true);
    },
    granularEditSubmitData(payload: GranularEditPayloadItem[]) {
      const url = `${granularEditPath}`;
      return Axios.post(url, payload).then(() => true);
    },
    objectDeserializer: objectDeserializer,
  };
  return pivotClient;
}
