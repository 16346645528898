import * as React from 'react';
import { rgb } from 'csx';

const hasExceptions = 'hasexceptions';
const addedToWorklist = 'active';
const snoozedOneDay = 'snooze_1d';
const snoozedSevenDays = 'snooze_1w';
const reviewed = 'reviewed';
// FOR MOCK VIEW ONLY
const added = 'added';
const dropped = 'dropped';

const worklistIconMap = {
  [hasExceptions]: 'far fa-circle',
  [addedToWorklist]: 'far fa-check-circle',
  [snoozedOneDay]: 'fas fa-bed',
  [snoozedSevenDays]: 'fas fa-clock',
  [reviewed]: 'fal fa-clipboard-check',
  [added]: 'fas fa-plus-circle',
  [dropped]: 'fas fa-times-circle',
};
const worklistColorMap = {
  [hasExceptions]: rgb(255, 150, 141).toString(),
  [addedToWorklist]: rgb(255, 150, 141).toString(),
  [snoozedOneDay]: rgb(0, 162, 255).toString(),
  [snoozedSevenDays]: rgb(0, 162, 255).toString(),
  [reviewed]: rgb(29, 177, 0).toString(),
  [added]: rgb(120, 168, 147).toString(),
  [dropped]: rgb(183, 74, 77).toString(),
};
export const worklistStatusMap = {
  [addedToWorklist]: 'Active',
  [snoozedOneDay]: 'Snoozed 1d',
  [snoozedSevenDays]: 'Snoozed 1w',
  [reviewed]: 'Reviewed',
};

export type WorklistType = keyof typeof worklistIconMap;

export const WorklistIcon = (props: { icon: WorklistType }) => {
  return (
    <i
      className={`worklist-icon fa fa-2x fa-fw ${worklistIconMap[props.icon]}`}
      style={{ color: worklistColorMap[props.icon] }}
    />
  );
};
