import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import {
  fetchFloorsetData,
  fetchConfigurableGridData,
  fetchConfigurableGridConfigs,
  receiveFloorsetData,
  setGroupBySelection,
  setFloorsetSelection,
  cleanUp,
  refreshConfigurableGridData,
  requestConfigurableGridConfig,
  receiveConfigurableGridConfig,
} from './ConfigurableGrid.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/inputIsNotNullOrUndefined';
import { isNil } from 'lodash';
import {
  ConfDefnComponentType,
  StyleAttributesComponent,
  StyleColorAttributesComponent,
  LineByFloorsetComponent,
  maybeGetComponentProps,
  isSameComponentType,
} from 'src/services/configuration/codecs/confdefnComponents';
import { updateSelectedItem } from 'src/pages/AssortmentBuild/Worklist/Worklist.slice';
import { RECEIVE_SCOPE_REFRESH_TRIGGER } from 'src/components/ScopeSelector/ScopeSelector.actions';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { ConfigurableGridOwnProps } from './ConfigurableGrid.types';

export const configurableGridConfigLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(requestConfigurableGridConfig.type, setActivePage.type, setActiveSubPage.type),
    map(() => {
      return (
        maybeGetComponentProps<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        maybeGetComponentProps<StyleColorAttributesComponent>(
          state$.value,
          ConfDefnComponentType.styleColorAttributes
        ) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid)
      );
    }),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ configApi }) => {
      return of(fetchConfigurableGridConfigs(configApi));
    })
  );
};

export const configurableGridFloorsetLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      RECEIVE_SCOPE_REFRESH_TRIGGER,
      receiveFilterStateAfterSubmission.type,
      receiveConfigurableGridConfig.type,
      'LENS_ASST_PLAN_REFRESHING_PAGE'
    ),
    map(() => {
      return (
        maybeGetComponentProps<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        maybeGetComponentProps<StyleColorAttributesComponent>(
          state$.value,
          ConfDefnComponentType.styleColorAttributes
        ) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid)
      );
    }),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ floorsetApi }) => {
      const view = state$.value.pages.assortmentBuild.configurableGrid;

      if (isNil(view.viewDefn?.subheaderDropdowns)) {
        // config needs to be fetched before dropdown data;
        return of();
      }

      if (
        isSameComponentType<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        isSameComponentType<StyleColorAttributesComponent>(state$.value, ConfDefnComponentType.styleColorAttributes) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid)
      ) {
        console.info('navigation to same component type detected, cleaning up redux state before data load');
        return of(cleanUp(), fetchFloorsetData(floorsetApi));
      }

      return of(fetchFloorsetData(floorsetApi));
    })
  );
};

/**
 * This is separated out into it's own epic because it is dependent on the floorset data in the configurableGridFloorsetLoad epic.
 * It will be triggered for initial page load and scope/filter changes whenever the floorset data is loaded.
 *
 */
export const configurableGridLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      updateSelectedItem.type,
      receiveFloorsetData.type,
      setFloorsetSelection.type,
      refreshConfigurableGridData.type
    ),
    map(() => {
      return (
        maybeGetComponentProps<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        maybeGetComponentProps<StyleColorAttributesComponent>(
          state$.value,
          ConfDefnComponentType.styleColorAttributes
        ) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        maybeGetComponentProps<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid)
      );
    }),
    filter(inputIsNotNullOrUndefined),
    mergeMap((ownProps) => {
      if (
        isSameComponentType<StyleAttributesComponent>(state$.value, ConfDefnComponentType.styleAttributes) ||
        isSameComponentType<StyleColorAttributesComponent>(state$.value, ConfDefnComponentType.styleColorAttributes) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.linePlanByFloorset) ||
        isSameComponentType<LineByFloorsetComponent>(state$.value, ConfDefnComponentType.configurableGrid)
      ) {
        console.info('navigation to same component type detected, cleaning up redux state before data load');
        return of(cleanUp(), fetchConfigurableGridData(ownProps as ConfigurableGridOwnProps));
      }

      return of(fetchConfigurableGridData(ownProps as ConfigurableGridOwnProps));
    })
  );
};
