import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import numbro from 'numbro';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { FlowStatus } from 'src/services/configuration/bindings.types';
import { SortBySlice, SubheaderDropdownSlice } from './Subheader.slice';
import SubheaderCheckboxes from './SubheaderCheckboxes';
import SubheaderDropdownMulti from './SubheaderDropdownMulti';
import SubheaderDropdown from './SubheaderDropdown';
import SubheaderQuickActionButton from './SubheaderQuickActionButton';

export type FlowStatusInputs = {
  showFlowStatus: boolean | undefined;
  flowStatusOptions: FlowStatus;
  flowStatus: number[];
  updateFlowStatus: (values: number[]) => void;
};

export type AltFlowStatusInputs = {
  showAltFlowStatus: boolean | undefined;
  altFlowStatusOptions: FlowStatus;
  altFlowStatus: number[];
  updateAltFlowStatus: (values: number[]) => void;
};

export type SearchInputs = {
  showSearch: boolean | undefined;
  search: string;
  searchReturn: ((searchString: string) => void) | undefined;
  updateSearchString: (searchString: string) => void;
};

export type AltSearchInputs = {
  showAltSearch: boolean | undefined;
  altSearch: string;
  searchReturn: ((searchString: string) => void) | undefined;
  updateAltSearchString: (searchString: string) => void;
};

export type PareDownInputs = {
  showPareDown: boolean;
  selections: TenantConfigViewItem[];
  options: TenantConfigViewItem[];
  setPareDownSelections: (items: TenantConfigViewItem[]) => void;
};

export type GroupByInputs = {
  showGroupBy: boolean;
  groupBy: SubheaderDropdownSlice;
  onChangeGroup: (() => void) | undefined;
  setGroupBySelection: (value: number | null) => void;
};

export type SortByInputs = {
  showSortBy: boolean;
  sortBy: SortBySlice;
  onChangeSort: (() => void) | undefined;
  setSortBySelection: (value: number | null) => void;
};

export type SortArrowInputs = {
  showSortArrow: boolean;
  onChangeSort: (() => void) | undefined;
  setSortByDirection: () => void;
};

export type CountLimitInputs = {
  showCountLimit: boolean | undefined;
  countLimit: number | undefined;
  countLimitOptions: number[] | undefined;
  setCountLimit: (value: number) => void;
};

type SubheaderFiltersProps = {
  flowStatusInputs: FlowStatusInputs;
  altFlowStatusInputs: AltFlowStatusInputs;
  searchInputs: SearchInputs;
  altSearchInputs: AltSearchInputs;
  customElement: JSX.Element | undefined;
  pareDownInputs: PareDownInputs;
  groupByInputs: GroupByInputs;
  sortByInputs: SortByInputs;
  sortArrowInputs: SortArrowInputs;
  extraDropdownElements: JSX.Element[];
  countLimitInputs: CountLimitInputs;
};

export const SubheaderFilters = ({
  flowStatusInputs,
  altFlowStatusInputs,
  searchInputs,
  altSearchInputs,
  customElement,
  pareDownInputs,
  groupByInputs,
  sortByInputs,
  sortArrowInputs,
  extraDropdownElements,
  countLimitInputs,
}: SubheaderFiltersProps) => {
  const { showFlowStatus, flowStatusOptions, flowStatus, updateFlowStatus } = flowStatusInputs;
  const { showAltFlowStatus, altFlowStatusOptions, altFlowStatus, updateAltFlowStatus } = altFlowStatusInputs;
  const { showSearch, search, searchReturn, updateSearchString } = searchInputs;
  const { showAltSearch, altSearch, updateAltSearchString } = altSearchInputs;
  const { showPareDown, selections, options, setPareDownSelections } = pareDownInputs;
  const { showGroupBy, groupBy, onChangeGroup, setGroupBySelection } = groupByInputs;
  const { showSortBy, sortBy, onChangeSort, setSortBySelection } = sortByInputs;
  const { showSortArrow, setSortByDirection } = sortArrowInputs;
  const { showCountLimit, countLimit, countLimitOptions, setCountLimit } = countLimitInputs;

  const debounceUpdateSearch = debounce(updateSearchString, 200);
  const debounceUpdateAltSearch = debounce(updateAltSearchString, 200);

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (searchReturn) {
        searchReturn(event.currentTarget.value);
      }
      debounceUpdateSearch(event.currentTarget.value);
    },
    [searchReturn, debounceUpdateSearch]
  );

  const onAltSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (searchReturn) {
        searchReturn(event.currentTarget.value);
      }
      debounceUpdateAltSearch(event.currentTarget.value);
    },
    [searchReturn, debounceUpdateAltSearch]
  );

  const onGroupByChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const position = numbro(event.currentTarget.dataset.position);
      const currentSelection = groupBy.selection;
      if (position.value() !== currentSelection) {
        setGroupBySelection(position.value());
        if (onChangeGroup) {
          onChangeGroup();
        }
      }
    },
    [groupBy.selection, onChangeGroup, setGroupBySelection]
  );

  const onSortByChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const position = numbro(event.currentTarget.dataset.position);
      setSortBySelection(position.value());
      if (onChangeSort) {
        onChangeSort();
      }
    },
    [onChangeSort, setSortBySelection]
  );

  const onSortArrowChange = useCallback(() => {
    setSortByDirection();
    if (onChangeSort) {
      onChangeSort();
    }
  }, [onChangeSort, setSortByDirection]);

  const onCountLimitChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const position = numbro(event.currentTarget.dataset.position);
      setCountLimit((countLimitOptions || [])[position.value()]);
    },
    [countLimitOptions, setCountLimit]
  );

  return (
    <React.Fragment>
      <section className="flow-search-filters">
        {showFlowStatus && (
          <SubheaderCheckboxes
            flowStatusOptions={flowStatusOptions}
            flowStatus={flowStatus}
            handleValuesUpdate={updateFlowStatus}
            qaPrefix="subheader"
            show={showFlowStatus}
          />
        )}
        {showAltFlowStatus && (
          <SubheaderCheckboxes
            flowStatusOptions={altFlowStatusOptions}
            flowStatus={altFlowStatus}
            handleValuesUpdate={updateAltFlowStatus}
            qaPrefix="subheader"
            show={showAltFlowStatus}
          />
        )}
        <fieldset className="search">
          {showSearch && (
            <input
              aria-label="Search this view"
              className="form-control"
              data-qa="SubheaderSearchInput"
              defaultValue={search}
              onChange={onSearchChange}
              placeholder="Search this view..."
              type="text"
            />
          )}
          {showAltSearch && (
            <input
              aria-label="Search view"
              className="form-control"
              data-qa="SubheaderAltSearchInput"
              defaultValue={altSearch}
              onChange={onAltSearchChange}
              placeholder="Search view..."
              type="text"
            />
          )}
        </fieldset>
      </section>
      <section className="dropdown-filters">
        {/* The custom element is used to inject the floorset selector and range picker in certain views */}
        {customElement}
        {showPareDown && (
          <SubheaderDropdownMulti
            selections={selections}
            options={options}
            label={'Refine'}
            qaKey={'refine-pare-down'}
            handleChangeOnClick={setPareDownSelections}
          />
        )}
        {showGroupBy && (
          <SubheaderDropdown
            {...groupBy}
            label={'Group By'}
            qaKey={'Group By'}
            handleChangeOnDropdown={onGroupByChange}
          />
        )}
        {extraDropdownElements}
        {showCountLimit && (
          <SubheaderDropdown
            label={'Limit'}
            selection={(countLimitOptions || []).findIndex((option) => option === countLimit)}
            options={(countLimitOptions || []).map((i) => ({ dataIndex: i.toString(), text: i.toString() }))}
            handleChangeOnDropdown={onCountLimitChange}
          />
        )}
        {showSortBy && (
          <SubheaderDropdown {...sortBy} label={'Sort By'} qaKey={'Sort By'} handleChangeOnDropdown={onSortByChange} />
        )}
        {showSortArrow && (
          <SubheaderQuickActionButton
            iconClass={`far fa-arrow-${sortBy.direction === 'asc' ? 'down' : 'up'}`}
            defaultsPresent={false}
            onClick={onSortArrowChange}
          />
        )}
      </section>
    </React.Fragment>
  );
};
