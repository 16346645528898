export type Action =
  | {
      type: 'SET_FOCUSED_GROUP';
      groupId: string;
    }
  | {
      type: 'TOGGLE_COLLAPSE';
      collapse: boolean;
    };

export const SET_FOCUSED_GROUP_TYPE = 'SET_FOCUSED_GROUP';
export function setFocusedGroup(groupId: string): Action {
  return {
    type: 'SET_FOCUSED_GROUP',
    groupId,
  };
}

export function setCollapsed(collapse: boolean): Action {
  return {
    type: 'TOGGLE_COLLAPSE',
    collapse: collapse,
  };
}
