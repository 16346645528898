import {
  ADD_EXISTING_PATH,
  ADD_EXISTING_STYLECOLOR_PATH,
  ADD_SIMILAR_PATH,
  ADD_SIMILAR_STYLECOLOR_PATH,
} from 'src/utils/Domain/Constants';
import { noop } from 'lodash';

export function createDefaultButtons() {
  const productType = window.location.hash.indexOf('style-color') >= 0 ? 'StyleColor' : 'Style';
  const modalButtons = [
    {
      label: 'ADD SIMILAR FROM HISTORY',
      // Dear Denver,
      // I am sorry.
      // TODO: make this entire modal configurable
      // path: productType === 'Style' ? ADD_SIMILAR_PATH : ADD_SIMILAR_STYLECOLOR_PATH,
      path: ADD_SIMILAR_STYLECOLOR_PATH,
      onClick: () => {
        switch (productType) {
          case 'Style':
            // window.location.hash = ADD_SIMILAR_PATH;
            // return;
          case 'StyleColor':
            window.location.hash = ADD_SIMILAR_STYLECOLOR_PATH;
            return;
          default:
            return;
        }
      },
    },
    {
      label: 'ADD EXISTING FROM HISTORY',
      // Dear Mark,
      // I am sorry.
      // TODO: make this entire modal configurable
      path: null,
      onClick: () => {
        noop();
      },
      disabled: true,
    },
    {
      label: 'ADD FROM DESIGN',
      path: null,
      onClick: () => {
        noop();
      },
      disabled: true,
    },
    {
      label: 'ADD PLACEHOLDER',
      path: null,
      onClick: () => {
        noop();
      },
      disabled: true,
    },
  ];
  return modalButtons;
}

export function getDefaultTitle() {
  return window.location.hash.indexOf('style-color') >= 0 ? 'Add Style Color to Assortment' : 'Add Style to Assortment';
}

export default createDefaultButtons;
